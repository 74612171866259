import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Notification, NotificationEvent } from '../models';

@Injectable()
export class NotificationsService {
    private emitter: Subject<NotificationEvent> = new Subject<NotificationEvent>();

    set(notification: Notification, to: boolean) {
        notification.id =
            notification.override && notification.override.id
                ? notification.override.id
                : Math.random()
                      .toString(36)
                      .substring(3);
        notification.click = new EventEmitter<any>();
        this.emitter.next({ command: 'set', notification, add: to });
        return notification;
    }

    getChangeEmitter() {
        return this.emitter;
    }

    success(content: string, title: string = '', preventLastDuplicates: any = false, override?: any) {
        return this.set(
            {
                type: 'success',
                content,
                title,
                preventLastDuplicates,
                override,
            },
            true,
        );
    }

    error(content: string, title: string = '', preventLastDuplicates: any = false, override?: any) {
        return this.set(
            {
                type: 'error',
                content,
                title,
                preventLastDuplicates,
                override,
            },
            true,
        );
    }

    alert(content: string, title: string = '', preventLastDuplicates: any = false, override?: any) {
        return this.set(
            {
                type: 'alert',
                content,
                title,
                preventLastDuplicates,
                override,
            },
            true,
        );
    }

    info(content: string, title: string = '', preventLastDuplicates: any = false, override?: any) {
        return this.set(
            {
                type: 'info',
                content,
                title,
                preventLastDuplicates,
                override,
            },
            true,
        );
    }

    bare(content: string, title: string = '', preventLastDuplicates: any = false, override?: any) {
        return this.set(
            {
                type: 'bare',
                content,
                title,
                preventLastDuplicates,
                override,
            },
            true,
        );
    }

    create(type: string, content: string, title: string = '', preventLastDuplicates: any = false, override?: any) {
        return this.set(
            {
                type,
                content,
                title,
                preventLastDuplicates,
                override,
            },
            true,
        );
    }

    html(type: string, html: any, preventLastDuplicates: any = false, override?: any) {
        return this.set(
            {
                type,
                html,
                preventLastDuplicates,
                override,
            },
            true,
        );
    }

    remove(id?: string) {
        id ? this.emitter.next({ command: 'clean', id }) : this.emitter.next({ command: 'cleanAll' });
    }
}
