import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PublicApiV2CategoryDetailsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get category details
     * @param id Category ID
     * @returns Backend API response
     */
    getCategoryDetails(id: string): Observable<any> {
        return this.httpClient.get<any>(`category_details_public/${ id }/`);
    }
}
