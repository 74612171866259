import { Action } from '@ngrx/store';

import { type } from '@app/utils';

const ACTION_PREFIX = '[Nutrients]';

export const ActionTypes = {
    GET_NUTRIENTS_REQUEST: type(`${ACTION_PREFIX} Get Nutrients Request`),
    GET_NUTRIENTS_SUCCESS: type(`${ACTION_PREFIX} Get Nutrients Success`),
    GET_NUTRIENTS_ERROR: type(`${ACTION_PREFIX} Get Nutrients Error`),
    GET_NUTRIENT_REQUEST: type(`${ACTION_PREFIX} Get Nutrient Request`),
    GET_NUTRIENT_SUCCESS: type(`${ACTION_PREFIX} Get Nutrient Success`),
    GET_NUTRIENT_ERROR: type(`${ACTION_PREFIX} Get Nutrient Error`),
    NEW_NUTRIENT: type(`${ACTION_PREFIX} New Nutrient`),
    CREATE_NUTRIENT_REQUEST: type(`${ACTION_PREFIX} Create Nutrient Request`),
    CREATE_NUTRIENT_SUCCESS: type(`${ACTION_PREFIX} Create Nutrient Success`),
    CREATE_NUTRIENT_ERROR: type(`${ACTION_PREFIX} Create Nutrient Error`),
    SAVE_NUTRIENT_REQUEST: type(`${ACTION_PREFIX} Save Nutrient Request`),
    SAVE_NUTRIENT_SUCCESS: type(`${ACTION_PREFIX} Save Nutrient Success`),
    SAVE_NUTRIENT_ERROR: type(`${ACTION_PREFIX} Save Nutrient Error`),
    DELETE_NUTRIENT_REQUEST: type(`${ACTION_PREFIX} Delete Nutrient Request`),
    DELETE_NUTRIENT_SUCCESS: type(`${ACTION_PREFIX} Delete Nutrient Success`),
    DELETE_NUTRIENT_ERROR: type(`${ACTION_PREFIX} Delete Nutrient Error`),
    GET_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Options Request`),
    GET_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Options Success`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
    CLEAR_OPTIONS: type(`${ACTION_PREFIX} Clear Options`),
};

/**
 * Create 'Get Nutrients Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetNutrientsRequestAction implements Action {
    type = ActionTypes.GET_NUTRIENTS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Nutrients Succes' Action
 * @param payload Contains object with nutrients list and params
 */
export class GetNutrientsSuccessAction implements Action {
    type = ActionTypes.GET_NUTRIENTS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Nutrients Error' Action
 * @param payload Contains object with errors
 */
export class GetNutrientsErrorAction implements Action {
    type = ActionTypes.GET_NUTRIENTS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Nutrient Request' action
 * @param payload Contains nutrient id
 */
export class GetNutrientRequestAction implements Action {
    type = ActionTypes.GET_NUTRIENT_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Nutrient Success' action
 * @param payload Contains object with nutrient data
 */
export class GetNutrientSuccessAction implements Action {
    type = ActionTypes.GET_NUTRIENT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Nutrient Error' action
 * @param payload Contains object with errors
 */
export class GetNutrientErrorAction implements Action {
    type = ActionTypes.GET_NUTRIENT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Nutrient' action
 */
export class NewNutrientAction implements Action {
    type = ActionTypes.NEW_NUTRIENT;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Nutrient Request' action
 * @param payload Contains object with nutrient data
 */
export class CreateNutrientRequestAction implements Action {
    type = ActionTypes.CREATE_NUTRIENT_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Nutrient Success' action
 * @param payload Contains object with nutrient data
 */
export class CreateNutrientSuccessAction implements Action {
    type = ActionTypes.CREATE_NUTRIENT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Nutrient Error' action
 * @param payload Contains object with errors
 */
export class CreateNutrientErrorAction implements Action {
    type = ActionTypes.CREATE_NUTRIENT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Nutrient Request' action
 * @param payload Contains object with nutrient data
 */
export class SaveNutrientRequestAction implements Action {
    type = ActionTypes.SAVE_NUTRIENT_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Nutrient Success' action
 * @param payload Contains object with nutrient data
 */
export class SaveNutrientSuccessAction implements Action {
    type = ActionTypes.SAVE_NUTRIENT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Nutrient Error' action
 * @param payload Contains object with errors
 */
export class SaveNutrientErrorAction implements Action {
    type = ActionTypes.SAVE_NUTRIENT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Nutrient Request' action
 * @param payload Contains nutrient id
 */
export class DeleteNutrientRequestAction implements Action {
    type = ActionTypes.DELETE_NUTRIENT_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Nutrient Success' action
 */
export class DeleteNutrientSuccessAction implements Action {
    type = ActionTypes.DELETE_NUTRIENT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Nutrient Error' action
 * @param payload Contains object with errors
 */
export class DeleteNutrientErrorAction implements Action {
    type = ActionTypes.DELETE_NUTRIENT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetNutrientsRequestAction
    | GetNutrientsSuccessAction
    | GetNutrientsErrorAction
    | GetNutrientRequestAction
    | GetNutrientSuccessAction
    | GetNutrientErrorAction
    | NewNutrientAction
    | CreateNutrientRequestAction
    | CreateNutrientSuccessAction
    | CreateNutrientErrorAction
    | SaveNutrientRequestAction
    | SaveNutrientSuccessAction
    | SaveNutrientErrorAction
    | DeleteNutrientRequestAction
    | DeleteNutrientSuccessAction
    | DeleteNutrientErrorAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | ClearListAction
    | ClearManageAction
    | ClearOptionsAction;
