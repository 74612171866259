import { Component, Input } from '@angular/core';

@Component({
    selector: 'single-section',
    styleUrls: ['./single-section.component.scss'],
    templateUrl: './single-section.component.html',
})
export class SingleSectionComponent {
    @Input('section-title') title: string;
    @Input('section-url') url?: string;
    @Input('section-counter') counter?: number;
    @Input('section-invalid') invalid?: boolean;
    @Input('section-style') style?: string;
    @Input('section-large') large?: boolean;
    @Input('section-loading') loading?: boolean;
    @Input('section-unsaved-changes') unsavedChanges?: boolean;
    @Input('hasErrors') hasErrors: boolean;
    @Input() firstTab?: boolean;
    public active: boolean = false;
}
