import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as users from '@core/actions/users.actions';

import { NotificationsService } from '@core/services/notifications.service';
import { SectionGroupService } from '@core/services/section-group.service';
import { UsersService } from '@core/services/users.service';

import { TokenDeactivatePayload, TokenGeneratePayload, TokenGenerateResponse } from '@app/core/models';
import { AnyErrors } from '@core/actions/common.actions';
import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class UsersEffects {
    @Effect()
    createUserRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.CREATE_USER_REQUEST),
        map((action: users.CreateUserRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.usersService.createUser(payload.data).pipe(
                tap((response) => {
                    this.notificationsService.success(`New user created. Activation e-mail sent to ${response.email}`);
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    } else if (payload.redirectTo) {
                        this.router.navigate(payload.redirectTo, { queryParams: payload.queryParams });
                    }
                }),
                map((response) => new users.CreateUserSuccessAction(response)),
                catchError((error) => of(new users.CreateUserErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    deactivateApiTokenRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.DEACTIVATE_API_TOKEN_REQUEST),
        map((action: users.DeactivateApiTokenRequestAction) => action.payload),
        switchMap((payload: TokenDeactivatePayload) => {
            return this.usersService.deactivateApiToken(payload).pipe(
                tap(() => this.notificationsService.success('Api token deactivated successfully!')),
                map((response) => new users.DeactivateApiTokenSuccessAction()),
                catchError((error) => {
                    return of(new users.DeactivateApiTokenErrorAction(catchErrorJson(error, HTTPClientVer)));
                }),
            );
        }),
    );

    @Effect()
    generateApiTokenRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.GENERATE_API_TOKEN_REQUEST),
        map((action: users.GenerateApiTokenRequestAction) => action.payload),
        switchMap((payload: TokenGeneratePayload) => {
            return this.usersService.generateApiToken(payload).pipe(
                tap(() => this.notificationsService.success('Api token generated successfully!')),
                map((response) => new users.GenerateApiTokenSuccessAction(response as TokenGenerateResponse)),
                catchError((error) => {
                    return of(new users.GenerateApiTokenErrorAction(catchErrorJson(error, HTTPClientVer)));
                }),
            );
        }),
    );

    @Effect()
    hardLogoutRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.HARD_LOGOUT_REQUEST),
        map((action: users.HardLogoutRequestAction) => action.payload),
        switchMap((payload) => {
            return this.usersService.hardLogout(payload).pipe(
                tap(() => this.notificationsService.success('User logout successfully!')),
                map((response) => new users.HardLogoutSuccessAction({})),
                catchError((error) => {
                    return of(new users.HardLogoutErrorAction(catchErrorJson(error, HTTPClientVer)));
                }),
            );
        }),
    );

    @Effect()
    getOptionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap((payload) => {
            return this.usersService.getOptions().pipe(
                map((response) => new users.GetOptionsSuccessAction(response.actions.POST)),
                catchError((error) => of(new AnyErrors(error))),
            );
        }),
    );

    @Effect()
    getUserRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.GET_USER_REQUEST),
        map((action: users.GetUsersRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.usersService.getUser(payload).pipe(
                map((response) => new users.GetUserSuccessAction(response)),
                catchError((error) => of(new users.GetUserErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    getUsersRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.GET_USERS_REQUEST),
        map((action: users.GetUsersRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.usersService.getUsers(payload).pipe(
                map((response) => new users.GetUsersSuccessAction(response)),
                catchError((error) => of(new users.GetUsersErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    resendRegistrationEmailRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.RESEND_REGISTRATION_EMAIL_REQUEST),
        map((action: users.ResendRegistrationEmailRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.usersService.resendRegistrationEmail(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Activation e-mail has been re-sent to ${response.email}`);
                }),
                map((response) => new users.ResendRegistrationEmailSuccessAction(response)),
                catchError((error) =>
                    of(new users.ResendRegistrationEmailErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    saveUserRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.SAVE_USER_REQUEST),
        map((action: users.SaveUserRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.usersService.saveUser(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved user ${response.firstName} ${response.lastName}`);
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                map((response) => new users.SaveUserSuccessAction(response)),
                catchError((error) => of(new users.SaveUserErrorAction(catchErrorJson(error)))),
            );
        }),
    );

    @Effect()
    validateUserEmailRequest$: Observable<Action> = this.actions$.pipe(
        ofType(users.ActionTypes.VALIDATE_USER_EMAIL_REQUEST),
        map((action: users.ValidateUserEmailRequestAction) => action.payload),
        switchMap((payload) => {
            return this.usersService.validateUserEmail(payload).pipe(
                map((response) => new users.ValidateUserEmailSuccessAction(response)),
                catchError((error) => {
                    return of(new users.ValidateUserEmailErrorAction(catchErrorJson(error, HTTPClientVer)));
                }),
            );
        }),
    );

    constructor(
        private actions$: Actions,
        private notificationsService: NotificationsService,
        private router: Router,
        private sectionGroupService: SectionGroupService,
        private usersService: UsersService,
    ) {}
}
