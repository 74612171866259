import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs/operators';

import { FormBuilder, FormGroup } from '@angular/forms';

import { ColumnOptions } from '@core/models';
@Component({
    selector: 'table-relations',
    templateUrl: './table-relations.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableRelationsComponent implements OnInit, OnChanges {
    @Input() relationType: string;
    @Input() columnOptions: ColumnOptions[];
    @Input() relations: any;
    @Input() errors: any = {};
    @Input() isEditable: any = false;
    @Input() selectMode: boolean = false;

    @Output() removeProductRelation: EventEmitter<any> = new EventEmitter();
    @Output() select: EventEmitter<any> = new EventEmitter();

    public relationsForm: FormGroup;

    // Keep information about rows that was modified between previous and next save
    modified: any = {
        variants: [],
        cases: [],
        caseChildren: [],
    };

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.relationsForm = this.formBuilder.group(
            this.relations.reduce(
                (acc, relation) => ({
                    ...acc,
                    [relation.id]: false,
                }),
                {},
            ),
        );

        this.selectMode ? this.relationsForm.enable() : this.relationsForm.disable();

        this.relationsForm.valueChanges.pipe(filter((_) => this.relationsForm.dirty)).subscribe((change) => {
            /* tslint:disable:no-unused-variable */
            this.select.emit({
                [this.relationType]: Object.entries(change)
                    .filter(([key, value]) => value)
                    .map(([key]) => key),
            });
            /* tslint:enable:no-unused-variable */
        });
    }

    /**
     * Reset proper array in modified object after new errors received.
     * All errors should be visible.
     *
     * @param {any} changes
     * @memberof TableRelationsComponent
     */
    ngOnChanges(changes: any): void {
        if (changes.errors) {
            this.modified[this.relationType] = [];
        }

        if (changes.selectMode && this.relationsForm) {
            this.selectMode ? this.relationsForm.enable() : this.relationsForm.disable();
        }
    }

    /**
     * Get proper errors from errors object
     * @param {string} relationType Relation type
     * @param {number} index Index in array
     * @param {string} fieldKey Field key
     * @returns {any[]} Return array with errors
     */
    getErrors(relationType: string, index: number, fieldKey: string): any[] {
        // Firstly, check if modified object includes this index in a proper array,
        // positive response means that this error shouldn't be visible until get
        // new object with errors.
        // Secondly, check if this field has error.
        if (
            !this.modified[relationType].includes(index) &&
            this.errors.hasOwnProperty(relationType) &&
            this.errors[relationType].hasOwnProperty(index) &&
            this.errors[relationType][index].hasOwnProperty(fieldKey)
        ) {
            return this.errors[relationType][index][fieldKey];
        } else {
            return [];
        }
    }

    /**
     * Add index to the proper array in modified object and
     * emit remove product relation event.
     * @param {string} relationType Relation type
     * @param {number} index Index in array
     */
    removeFromTable(relationType: string, index: number): void {
        this.modified[relationType].push(index);

        this.removeProductRelation.emit({ relationType, index });
    }
}
