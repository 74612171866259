import * as utilsActions from '../actions/utils.actions';
import { CountriesList } from '../models/utils.models';

export interface State {
    countries: CountriesList;
}

export const initialState: State = {
    countries: {
        list: [],
        isFetching: true,
    },
};

export function reducer(state = initialState, action: utilsActions.Actions): State {
    switch (action.type) {
        case utilsActions.ActionTypes.GET_COUNTRIES_REQUEST: {
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isFetching: true,
                },
            };
        }

        case utilsActions.ActionTypes.GET_COUNTRIES_SUCCESS: {
            return {
                ...state,
                countries: {
                    list: [...action.payload],
                    isFetching: false,
                },
            };
        }

        case utilsActions.ActionTypes.GET_COUNTRIES_ERROR: {
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isFetching: false,
                },
            };
        }

        default: {
            return state;
        }
    }
}
export const getCountries = (state: State) => state.countries;
