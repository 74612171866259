import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs/operators';

import { Subscription } from 'rxjs';

import * as AutoScrollerFactory from 'dom-autoscroller';
import { DragulaService } from 'ng2-dragula';

/**
 * Drag'n'drop select component
 *
 * Managing select with drag and drop
 */
@Component({
    selector: 'dnd-select',
    templateUrl: './dnd-select.component.html',
    styleUrls: ['./dnd-select.component.scss'],
})
export class DndSelectComponent implements OnInit, OnDestroy {
    @Input() availableItems: any[] = [];
    @Input() selectedItems: any[] = [];
    @Input() errors: any = {};
    @Input() translations?: any;
    @Input() readonly: boolean = false;
    @Output() changeSelectedItems: EventEmitter<any> = new EventEmitter();

    dragSubscription: Subscription;
    dragendSubscription: Subscription;
    dropModelSubscription: Subscription;

    scroll: any;
    isDragging: boolean = false;

    defaultTranslations = {
        instructions: 'Select items from the list .',
        availableBar: 'Available items',
        selectedBar: 'Selected items',
        emptyList: 'Drag and drop item here',
    };

    txt: any;
    public bagName = 'dnd-select-bag';

    /**
     * Component constructor function
     */
    constructor(private dragulaService: DragulaService) {}

    /**
     * Initialize the component and subscribe observables
     */
    ngOnInit(): void {
        this.dragSubscription = this.dragulaService.drag.subscribe((value) => (this.isDragging = true));
        this.dragendSubscription = this.dragulaService.dragend.subscribe((value) => (this.isDragging = false));
        this.dropModelSubscription = this.dragulaService.dropModel
            .pipe(filter(([bagName]) => bagName === this.bagName))
            .subscribe((value) => this.caluculatePositions());

        this.scroll = AutoScrollerFactory(
            [
                document.querySelector('.dnd-select__available-list'),
                document.querySelector('.dnd-select__selected-list'),
            ],
            {
                margin: 20,
                maxSpeed: 10,
                scrollWhenOutside: true,
                autoScroll: () => {
                    // Only scroll when the pointer is down, and there is a child being dragged
                    return this.scroll.down && this.isDragging;
                },
            },
        );

        // Extend default translations with provided
        this.txt = {
            ...this.defaultTranslations,
            ...this.translations,
        };
    }

    /**
     * Calculate items' positions on the list
     */
    caluculatePositions() {
        this.selectedItems.map((selectedItem, index) => (selectedItem.position = index));
        this.changeSelectedItems.emit(this.selectedItems);
    }

    /**
     * Method for tacking loop by element ID in `*ngFor` directive
     * @param index Index of current item in loop
     * @param item Current item in loop
     * @returns Element ID of item
     */
    trackByFn(index: number, item: any): number {
        return item.elementId;
    }

    parseErrors(errors): any[] {
        let hasObjectErrors = false;
        if (!errors) {
            return [];
        }
        const parsedErrors = errors.reduce((array, error, index) => {
            const type = error.constructor.name;
            if (type === 'String') {
                array.push(error);
            }
            if (type === 'Object') {
                const objErrors = Object.entries(error).reduce((str, [key, value]: [string, any]) => {
                    const br = str.length ? '<br>' : '';
                    const valType = value.constructor.name;
                    if (valType === 'Array') {
                        return `${str}${br}${key} (no. ${index + 1}): ${value.join(', ')}`;
                    }
                    if (valType === 'String') {
                        return `${str}${br}${key} (no. ${index + 1}): ${value}`;
                    }
                }, '');

                if (objErrors.length) {
                    hasObjectErrors = true;
                    array.push(objErrors);
                }
            }
            if (type === 'Array') {
                array.push(error.join('<br>'));
            }
            return array;
        }, []);

        if (hasObjectErrors) {
            parsedErrors.push('Please contact BrandSync administrator for support.');
        }

        return parsedErrors;
    }

    /**
     * Return all selected items
     */
    clearAll() {
        this.selectedItems.forEach((item) => this.availableItems.push(item));
        this.selectedItems.splice(0, this.selectedItems.length);
        this.changeSelectedItems.emit(this.selectedItems);
    }

    /**
     * Destroy autoScroll events
     */
    ngOnDestroy() {
        this.dragSubscription.unsubscribe();
        this.dragendSubscription.unsubscribe();
        this.dropModelSubscription.unsubscribe();

        this.scroll.destroy();
    }
}
