import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class PublicApiAccessGuard extends BaseGuard {
    path = ['publicApi', 'access'];
    constructor(injector: Injector) {
        super(injector);
    }
}
