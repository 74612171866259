import * as newsActions from '../actions/news.actions';
import { NewsList } from '../models';

import { ActionWithPayload } from '@core/actions/common.actions';

export interface State {
    list: NewsList;
}

const initialState: State = {
    list: {
        data: {
            results: [],
            next: null,
            previous: null,
            count: null,
        },
        errors: [],
        isFetching: true,
        isSaving: false,
    },
};

export function reducer(state: State = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case newsActions.ActionTypes.GET_NEWS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: [],
                    isFetching: true,
                },
            };
        }

        case newsActions.ActionTypes.GET_NEWS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case newsActions.ActionTypes.GET_NEWS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case newsActions.ActionTypes.SAVE_NEWS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isSaving: true,
                    errors: [],
                },
            };
        }

        case newsActions.ActionTypes.SAVE_NEWS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isSaving: false,
                    data: {
                        ...state.list.data,
                        results: action.payload,
                    },
                },
            };
        }

        case newsActions.ActionTypes.SAVE_NEWS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }

        case newsActions.ActionTypes.UPLOAD_NEWS_IMAGES_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isSaving: true,
                },
            };
        }

        case newsActions.ActionTypes.UPLOAD_NEWS_IMAGES_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isSaving: false,
                },
            };
        }

        case newsActions.ActionTypes.UPLOAD_NEWS_IMAGES_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isSaving: false,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
