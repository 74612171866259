import * as brandsActions from '@core/actions/brands.actions';

import { Brand, DefaultList, DefaultManage } from '@core/models';

export interface State {
    manage: DefaultManage<Brand>;
    list: DefaultList<Brand>;
    prepareImport: DefaultManage<any>;
    importStatus: DefaultManage<any>;
}

const initialState: State = {
    manage: {
        data: {
            id: null,
            name: '',
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    list: {
        data: {
            results: [],
            previous: null,
            count: null,
            next: null,
            limit: null,
            offset: null,
        },
        errors: {},
        isFetching: true,
    },
    prepareImport: {
        data: {},
        errors: {},
        isFetching: false,
    },
    importStatus: {
        data: {},
        errors: {},
        isFetching: false,
    },
};

export function reducer(state = initialState, action: brandsActions.Actions): State {
    switch (action.type) {
        case brandsActions.ActionTypes.GET_BRANDS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case brandsActions.ActionTypes.GET_BRANDS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case brandsActions.ActionTypes.GET_BRANDS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case brandsActions.ActionTypes.CLEAR_BRANDS_DATA: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                    isFetching: false,
                },
            };
        }

        case brandsActions.ActionTypes.CREATE_BRAND_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                    errors: {},
                },
            };
        }

        case brandsActions.ActionTypes.CREATE_BRAND_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                },
            };
        }

        case brandsActions.ActionTypes.CREATE_BRAND_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }

        case brandsActions.ActionTypes.GET_BRAND_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case brandsActions.ActionTypes.GET_BRAND_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case brandsActions.ActionTypes.GET_BRAND_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case brandsActions.ActionTypes.CLEAR_BRAND_DATA: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case brandsActions.ActionTypes.SAVE_BRAND_REQUEST:
        case brandsActions.ActionTypes.DELETE_BRAND_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                    errors: {},
                },
            };
        }

        case brandsActions.ActionTypes.SAVE_BRAND_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case brandsActions.ActionTypes.SAVE_BRAND_ERROR:
        case brandsActions.ActionTypes.DELETE_BRAND_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }

        case brandsActions.ActionTypes.DELETE_BRAND_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                },
            };
        }

        case brandsActions.ActionTypes.PREPARE_BRANDS_IMPORT_REQUEST: {
            return {
                ...state,
                prepareImport: {
                    ...state.prepareImport,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case brandsActions.ActionTypes.PREPARE_BRANDS_IMPORT_SUCCESS: {
            return {
                ...state,
                prepareImport: {
                    ...state.prepareImport,
                    isSaving: false,
                    data: action.payload,
                },
            };
        }

        case brandsActions.ActionTypes.PREPARE_BRANDS_IMPORT_ERROR: {
            return {
                ...state,
                prepareImport: {
                    ...state.prepareImport,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }

        case brandsActions.ActionTypes.CLEAR_PREPARE_BRANDS_IMPORT: {
            return {
                ...state,
                prepareImport: {
                    ...initialState.prepareImport,
                },
            };
        }

        case brandsActions.ActionTypes.GET_BRANDS_IMPORT_STATUS_REQUEST: {
            return {
                ...state,
                importStatus: {
                    ...state.importStatus,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case brandsActions.ActionTypes.GET_BRANDS_IMPORT_STATUS_SUCCESS: {
            return {
                ...state,
                importStatus: {
                    ...state.importStatus,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case brandsActions.ActionTypes.GET_BRANDS_IMPORT_STATUS_ERROR: {
            return {
                ...state,
                importStatus: {
                    ...state.importStatus,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case brandsActions.ActionTypes.CLEAR_BRANDS_IMPORT_STATUS: {
            return {
                ...state,
                importStatus: {
                    ...initialState.importStatus,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getList = (state: State) => state.list;
export const getManage = (state: State) => state.manage;
export const prepareImport = (state: State) => state.prepareImport;
export const getImportStatus = (state: State) => state.importStatus;
