// Based on ngx-tooltip component - https://github.com/pleerock/ngx-tooltip

import { ComponentRef, Directive, HostListener, Injectable, Input, ViewContainerRef } from '@angular/core';
import { InjectionService } from '@core/services/injection.service';

import { TooltipContentComponent } from '../../components/tooltip/tooltip-content.component';

@Directive({
    selector: '[tooltipContent]',
})
export class TooltipContentDirective {
    @Input('tooltipContent') content: string | TooltipContentComponent;
    @Input() tooltipAppendToRoot: boolean = true;
    @Input() tooltipAnimation: boolean = true;
    @Input() tooltipDisabled: boolean = false;
    @Input() tooltipOrigin: 'edge' | 'middle' = 'edge';
    @Input() tooltipPlacement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
    @Input() tooltipTheme: 'purple' | 'white' = 'purple';
    @Input() tooltipWide: boolean = false;

    private tooltip: ComponentRef<TooltipContentComponent>;
    private visible: boolean;

    constructor(private viewContainerRef: ViewContainerRef, private injectionService: InjectionService) {}

    @HostListener('focusin')
    @HostListener('mouseenter')
    show(): void {
        if (this.tooltipDisabled || this.visible) {
            return;
        }

        this.visible = true;

        if (typeof this.content === 'string') {
            if (!this.visible) {
                return;
            }

            this.tooltip = this.injectionService.appendComponent(
                TooltipContentComponent,
                {
                    hostElement: this.viewContainerRef.element.nativeElement,
                    content: this.content as string,
                    placement: this.tooltipPlacement,
                    origin: this.tooltipOrigin,
                    theme: this.tooltipTheme,
                    animation: this.tooltipAnimation,
                    appendToRoot: this.tooltipAppendToRoot,
                    wide: this.tooltipWide,
                },
                this.viewContainerRef.element.nativeElement,
            );
        } else {
            const tooltip = this.content as TooltipContentComponent;
            tooltip.hostElement = this.viewContainerRef.element.nativeElement;
            tooltip.placement = this.tooltipPlacement;
            tooltip.animation = this.tooltipAnimation;
            tooltip.show();
        }
    }

    @HostListener('focusout')
    @HostListener('mouseleave')
    hide(): void {
        if (!this.visible) {
            return;
        }

        this.visible = false;

        if (this.tooltip) {
            this.tooltip.destroy();
        }

        if (this.content instanceof TooltipContentComponent) {
            (this.content as TooltipContentComponent).hide();
        }
    }
}
