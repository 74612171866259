import { Injector } from '@angular/core';

import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { CustomValidatorsService } from '@core/services/custom-validators.service';

import {
    AnyOf,
    AttributeData,
    IngredientAttribute,
    IngredientAttributeItem,
    ListedProductsListData,
    ProductCategory,
    TemplatelockedCoreAttr
} from '@core/models';

export type StatusValue = 's' | 'd' | 'l' | 'n' | 'p' | 'u';

export type StatusName = 'Discontinued' | 'Draft' | 'Live' | 'New' | 'Shared' | 'Updated' | 'Pending approval';

export interface ProductStatus {
    value: StatusValue;
    displayName: StatusName;
}

export type StatusObject = {
    [K in StatusValue]: StatusName;
};

export const excludedCountryOfManufacturing = ['Europe', 'World', 'Asia', 'North America', 'Africa', 'South America', 'Antarctica'];

export const MAX_TRADE_ITEM_DESCRIPTION_LENGTH = 300;
export const MAX_BRAND_NAME_LENGTH = 200;

export const PRODUCT_STATUSES: ProductStatus[] = [
    { value: 's', displayName: 'Discontinued' },
    { value: 'd', displayName: 'Draft' },
    { value: 'l', displayName: 'Live' },
    { value: 'n', displayName: 'New' },
    { value: 'p', displayName: 'Shared' },
    { value: 'u', displayName: 'Updated' },
];

export const PRODUCTS_LISTS_FILTER_KEYS_TABS: string[] = ['', 'isNew', 'isSoftDeleted'];

export enum GtinStatusValue {
    INVALID = 0,
    VALID,
}

export const objectDataInitialState = {
    data: {},
    errors: {},
    isFetching: true,
};

/**
 * Check if attribute has value
 *
 * @param type Attribute type
 * @param value Attribute value
 * @returns Returns true if attribute has value or false if not
 */
export function attributeHasValue(type: string, value: any): boolean {
    switch (type) {
        case 'textfield':
        case 'textarea':
        case 'regex':
        case 'number':
        case 'date':
        case 'gtin':
        case 'simple_select': {
            return value !== null && value !== '' ? true : false;
        }
        case 'multiple_select': {
            return value !== null && value.length ? true : false;
        }
        case 'price': {
            return value !== null && value.length > 0
                ? value.some(({ price }) => price.minAmount && price.maxAmount && price.currency)
                : false;
        }
        case 'boolean': {
            return value !== null ? true : false;
        }
        case 'nutrient': {
            return value && value.foodType !== null && value.nutritionalValues !== null;
        }
        case 'allergen': {
            // check if contain or may contain any allegrens
            return (
                value &&
                Object.entries(value)
                    .map((entry) => entry[1])
                    .some((allergen: any) => allergen.content !== 'd')
            );
        }
        case 'certificate': {
            return value !== null && value.constructor.name === 'Array' && value.length > 0;
        }
        case 'metric': {
            return value && value.unit !== null && value.value !== null;
        }
        case 'size_metric': {
            return value && value.some((item) => item.metricFamily && item.unit && (item.value || item.value === 0));
        }
        case 'pick_list': {
            return value && value.some((item) => item.id && item.value);
        }
        case 'choices': {
            return value && value.ingredients && value.ingredients.some((item) => item.ingredient);
        }
        case 'image':
        case 'video':
        case 'file': {
            return value && Object.values(value).some((val) => val[type] !== null);
        }
    }
}

/**
 * Generate validators based on information from attribute template
 *
 * @param attributeData Object with attribute data
 * @returns Array with validators that should be apply
 */
export function generateAttributeValidators(attributeData: AttributeData): ValidatorFn[] {
    const injector = Injector.create({ providers: [{ provide: CustomValidatorsService, deps: [] }] });
    const customValidatorsService: CustomValidatorsService = injector.get(CustomValidatorsService);

    const validators: ValidatorFn[] = [];

    // Required
    if (attributeData.isRequired) {
        validators.push(Validators.required);
    }

    // Min length
    if (typeof attributeData.validators.minLength === 'number' && !attributeData.properties.isWysiwyg) {
        // Don't set this validator for WYSIWYG because default minLength validator count HTML tags
        validators.push(Validators.minLength(attributeData.validators.minLength));
    }

    // Max length
    if (typeof attributeData.validators.maxLength === 'number' && !attributeData.properties.isWysiwyg) {
        // Don't set this validator for WYSIWYG because default minLength validator count HTML tags
        validators.push(Validators.maxLength(attributeData.validators.maxLength));
    }

    if (attributeData.type === 'number') {
        // Is floar or integer
        if (attributeData.validators.allowDecimals) {
            validators.push(customValidatorsService.isFloatNumber);
        } else {
            validators.push(customValidatorsService.isIntegerNumber);
        }

        // Min value
        if (attributeData.validators.minValue !== null) {
            validators.push(Validators.min(parseFloat(attributeData.validators.minValue)));
        }

        // Max value
        if (attributeData.validators.maxValue !== null) {
            validators.push(Validators.max(parseFloat(attributeData.validators.maxValue)));
        }
    }

    // GTIN validators
    if (attributeData.type === 'gtin') {
        validators.push(customValidatorsService.isValidBarcode);
    }

    return validators;
}

/**
 * Generate category leaf (breadcrumbs) based on product categoryDisplay field
 *
 * @param {ProductCategory} productCategory Object with category informations
 * @returns {string} category leaf
 */
export function generateCategoryLeaf(productCategory: ProductCategory): string {
    return productCategory.parents
        .map((parent) => parent.translations.en.name)
        .concat(productCategory.translations.en.name)
        .join(' > ');
}

/**
 * Generate form control for specific attribute
 *
 * @param {AttributeData} attributeData attribute template
 * @param {any} attributeValue value of attribute
 * @returns {FormGroup} form group
 */
export function generateAttributeControl(attributeData: AttributeData, attributeValue: any = null): FormGroup {
    const injector = Injector.create({ providers: [{ provide: CustomValidatorsService, deps: [] }] });
    const customValidatorsService: CustomValidatorsService = injector.get(CustomValidatorsService);

    const type = attributeData.type;
    let value: FormControl | FormArray | FormGroup;

    switch (type) {
        case 'price': {
            const initLength = (attributeValue && attributeValue.length) || 0;
            value = new FormArray([]);

            for (let i = 0; i < initLength; i++) {
                value.push(
                    new FormGroup({
                        price: new FormGroup({
                            minAmount: new FormControl(
                                null,
                                Validators.compose(generateAttributeValidators(attributeData)),
                            ),
                            maxAmount: new FormControl(
                                null,
                                Validators.compose(generateAttributeValidators(attributeData)),
                            ),
                            currency: new FormControl(
                                null,
                                Validators.compose(generateAttributeValidators(attributeData)),
                            ),
                        }),
                    }),
                );
            }
            break;
        }

        case 'nutrient': {
            const validators = Validators.compose(generateAttributeValidators(attributeData));

            value = new FormGroup({
                foodType: new FormControl('', validators),
                portionSize: new FormControl(null, validators),
                nutritionalValues: new FormGroup(
                    attributeData.nutrientAssignments.reduce((group, nutrient) => {
                        group[nutrient.id] = new FormGroup({
                            per100: new FormControl(null),
                            comparison: new FormControl('eq'),
                        });

                        return group;
                    }, {}),
                    customValidatorsService.getNutritionalValuesValidator(attributeData),
                ),
            });
            break;
        }

        case 'metric': {
            value = new FormGroup({
                unit: new FormControl(null),
                value: new FormControl(null, Validators.compose(generateAttributeValidators(attributeData))),
            });
            break;
        }

        case 'size_metric': {
            const data = attributeValue || [];

            value = new FormArray(
                data.map(
                    () =>
                        new FormGroup({
                            metricFamily: new FormControl(null, Validators.required),
                            value: new FormControl(
                                null,
                                Validators.compose([Validators.required, customValidatorsService.isFloatNumber]),
                            ),
                            unit: new FormControl(null, Validators.required),
                        }),
                ),
                Validators.compose(generateAttributeValidators(attributeData)),
            );
            break;
        }

        case 'image':
        case 'video':
        case 'file': {
            const max = {
                image: 'maxImages',
                video: 'maxVideos',
                file: 'maxFiles',
            };
            const length = attributeData.validators[max[type]];

            value = new FormGroup({});

            for (let i = 0; i < length; i++) {
                value.addControl(
                    `${i}`,
                    new FormGroup({
                        dateOfApproval: new FormControl(null),
                        approvalNumber: new FormControl(null),
                        [type]: new FormControl(null),
                    }),
                );
            }
            break;
        }

        // ingredients
        case 'choices': {
            const ingredientAttributeValue: IngredientAttribute = attributeValue || ({} as IngredientAttribute);
            const required = attributeData.isRequired ? [Validators.required] : [];
            const amountValidatrs = [...required, customValidatorsService.isFloatNumber];
            const extended = attributeData.validators.extended;

            value = new FormGroup({
                ingredients: new FormArray(
                    ingredientAttributeValue.ingredients.map(
                        ({ amount, ingredient, ingredientName, unit }) =>
                            new FormGroup({
                                ingredient: new FormControl(ingredient, required),
                                ingredientName: new FormControl(ingredientName),
                                ...(extended
                                    ? {
                                          amount: new FormControl(amount, amountValidatrs),
                                          unit: new FormControl(unit, required),
                                      }
                                    : {}),
                            } as AnyOf<IngredientAttributeItem>),
                    ),
                ),
                ...(extended
                    ? {
                          doseAmount: new FormControl(ingredientAttributeValue.doseAmount, amountValidatrs),
                          doseUnit: new FormControl(ingredientAttributeValue.doseUnit, required),
                      }
                    : {}),
            } as AnyOf<IngredientAttribute>);
            break;
        }

        case 'certificate':
        case 'multiple_select': {
            value = new FormControl([], Validators.compose(generateAttributeValidators(attributeData)));
            break;
        }

        case 'pick_list': {
            const data = attributeValue || [];

            value = new FormArray(
                data.map(
                    () =>
                        new FormGroup({
                            id: new FormControl(null),
                            value: new FormControl(null),
                        }),
                ),
                Validators.compose(generateAttributeValidators(attributeData)),
            );
            break;
        }

        default: {
            value = new FormControl(null, Validators.compose(generateAttributeValidators(attributeData)));
            break;
        }
    }

    return new FormGroup({
        value,
    });
}


export const markDisableCountries = (options: any) => {
    if (!options) return;
    const _countryList = options.countryOfOrigin.choices;

    const countryList = () => {
        return {
            choices: _countryList.map(country => {
            if (excludedCountryOfManufacturing.includes(country.displayName)) {
                return {...country, disabled: true};
            }
            return { ...country };
        })};
    };

    return {
        ...options,
        countryOfOrigin: Object.keys(_countryList).length ? countryList() : {},
    };

};

export const extendProductDetails = (productData: any) => {
    let shortTradeItemDescription = productData.tradeItemDescription;
    let shortBrandName = productData.brandName;

    if (productData.tradeItemDescription.length > MAX_TRADE_ITEM_DESCRIPTION_LENGTH) {
        shortTradeItemDescription = productData.tradeItemDescription.substring(0, MAX_TRADE_ITEM_DESCRIPTION_LENGTH) + "...";
    }

    if (productData.brandName.length > MAX_BRAND_NAME_LENGTH) {
        shortBrandName = productData.brandName.substring(0, MAX_BRAND_NAME_LENGTH) + "...";
    }

    return {
        ...productData,
        shortTradeItemDescription: shortTradeItemDescription,
        shortBrandName: shortBrandName,
    }
};

export const extendProductListDetails = (productData: ListedProductsListData) => {
    if (productData.results?.length) {

        productData.results.forEach((product) => {
            product.shortTradeItemDescription = product.tradeItemDescription;

                if (product.tradeItemDescription.length > MAX_TRADE_ITEM_DESCRIPTION_LENGTH) {
                    product.shortTradeItemDescription = product.tradeItemDescription.substring(0, MAX_TRADE_ITEM_DESCRIPTION_LENGTH) + "...";
                }
            });
    }

    return productData;
}

export function getLockedAttrValue(lockedattr: Array<TemplatelockedCoreAttr>, name: string) {
    let config = lockedattr.find(x => x.name === name)
    return config ? config.value : config;
}
