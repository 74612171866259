import * as paidPlansActions from '../actions/paid-plans.actions';
import { PaidPlanManage, PaidPlansList, PaidPlansOptions } from '../models';

import { ActionWithPayload } from '@core/actions/common.actions';

export interface State {
    list: PaidPlansList;
    manage: PaidPlanManage;
    options: PaidPlansOptions;
}

export const initialState: State = {
    list: {
        data: {
            results: [],
            next: null,
            previous: null,
            count: null,
            limit: null,
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            canAccessImagePlatform: false,
            canAccessPublicApi: false,
            canExportProducts: false,
            canImportProducts: false,
            canManageProductRequirements: false,
            id: null,
            isStaff: false,
            maxActiveUsers: null,
            maxPartners: null,
            maxProducts: null,
            maxSharedProducts: null,
            name: '',
            publicApiDailyRateLimit: null,
            role: null,
            throttlingLimitation: {},
            unlimitedActiveUsers: false,
            unlimitedPartners: false,
            unlimitedProducts: false,
            unlimitedPublicApiDailyRateLimit: false,
            unlimitedSharedProducts: false,
        },
        isFetching: true,
        isSaving: false,
        errors: {},
    },
    options: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state: State = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case paidPlansActions.ActionTypes.GET_PAID_PLANS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case paidPlansActions.ActionTypes.GET_PAID_PLANS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case paidPlansActions.ActionTypes.GET_PAID_PLANS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case paidPlansActions.ActionTypes.GET_PAID_PLAN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case paidPlansActions.ActionTypes.GET_PAID_PLAN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case paidPlansActions.ActionTypes.GET_PAID_PLAN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case paidPlansActions.ActionTypes.NEW_PAID_PLAN: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isFetching: false,
                },
            };
        }

        case paidPlansActions.ActionTypes.SAVE_PAID_PLAN_REQUEST:
        case paidPlansActions.ActionTypes.CREATE_PAID_PLAN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                    errors: {},
                },
            };
        }

        case paidPlansActions.ActionTypes.SAVE_PAID_PLAN_SUCCESS:
        case paidPlansActions.ActionTypes.CREATE_PAID_PLAN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    data: action.payload,
                },
            };
        }

        case paidPlansActions.ActionTypes.SAVE_PAID_PLAN_ERROR:
        case paidPlansActions.ActionTypes.CREATE_PAID_PLAN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }
        case paidPlansActions.ActionTypes.DELETE_PAID_PLAN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case paidPlansActions.ActionTypes.DELETE_PAID_PLAN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case paidPlansActions.ActionTypes.DELETE_PAID_PLAN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case paidPlansActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: true,
                },
            };
        }

        case paidPlansActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case paidPlansActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case paidPlansActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case paidPlansActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;

export const getManage = (state: State) => state.manage;

export const getOptions = (state: State) => state.options;
