import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Image Platform]';

export const ActionTypes = {
    SET_PRODUCTS: type(`${ACTION_PREFIX} Set Products`),
    GET_IMAGE_PREVIEW_REQUEST: type(`${ACTION_PREFIX} Get Image Preview Request`),
    GET_IMAGE_PREVIEW_SUCCESS: type(`${ACTION_PREFIX} Get Image Preview Success`),
    GET_IMAGE_PREVIEW_ERROR: type(`${ACTION_PREFIX} Get Image Preview Error`),
    GET_FILENAME_PREVIEW_REQUEST: type(`${ACTION_PREFIX} Get Filename Preview Request`),
    GET_FILENAME_PREVIEW_SUCCESS: type(`${ACTION_PREFIX} Get Filename Preview Success`),
    GET_FILENAME_PREVIEW_ERROR: type(`${ACTION_PREFIX} Get Filename Preview Error`),
    GENERATE_ZIP_REQUEST: type(`${ACTION_PREFIX} Generate Zip Request`),
    GENERATE_ZIP_SUCCESS: type(`${ACTION_PREFIX} Generate Zip Success`),
    GENERATE_ZIP_ERROR: type(`${ACTION_PREFIX} Generate Zip Error`),
    GET_ZIP_STATUS_REQUEST: type(`${ACTION_PREFIX} Get Zip Status Request`),
    GET_ZIP_STATUS_SUCCESS: type(`${ACTION_PREFIX} Get Zip Status Success`),
    GET_ZIP_STATUS_ERROR: type(`${ACTION_PREFIX} Get Zip Status Error`),
    GET_AVAILABLE_IMAGES_REQUEST: type(`${ACTION_PREFIX} Get Available Images Request`),
    GET_AVAILABLE_IMAGES_SUCCESS: type(`${ACTION_PREFIX} Get Available Images Success`),
    GET_AVAILABLE_IMAGES_ERROR: type(`${ACTION_PREFIX} Get Available Images Error`),
    VALIDATE_IMAGE_EXISTENCE_REQUEST: type(`${ACTION_PREFIX} Validate Image Existence Request`),
    VALIDATE_IMAGE_EXISTENCE_SUCCESS: type(`${ACTION_PREFIX} Validate Image Existence Success`),
    VALIDATE_IMAGE_EXISTENCE_ERROR: type(`${ACTION_PREFIX} Validate Image Existence Error`),
    GET_FILENAME_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Filename Options Request`),
    GET_FILENAME_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Filename Options Success`),
    CLEAR_PRODUCTS: type(`${ACTION_PREFIX} Clear Products`),
    CLEAR_IMAGE_PREVIEW: type(`${ACTION_PREFIX} Clear Image Preview`),
    CLEAR_FILENAME_PREVIEW: type(`${ACTION_PREFIX} Clear Filename Preview`),
    CLEAR_GENERATE_ZIP: type(`${ACTION_PREFIX} Clear Generate Zip`),
    CLEAR_ZIP_STATUS: type(`${ACTION_PREFIX} Clear Zip Status`),
    CLEAR_FILENAME_OPTIONS: type(`${ACTION_PREFIX} Clear Filename Options`),
    CLEAR_AVAILABLE_IMAGES: type(`${ACTION_PREFIX} Clear Available Images`),
    CLEAR_VALIDATE_IMAGE_EXISTENCE: type(`${ACTION_PREFIX} Clear Validate Image Existence`),
};

/**
 * Create 'Set Products' Action
 * @param payload Array of products
 */
export class SetProductsAction implements Action {
    type = ActionTypes.SET_PRODUCTS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Image Preview Request' Action
 * @param payload Contains object with image params
 */
export class GetImagePreviewRequestAction implements Action {
    type = ActionTypes.GET_IMAGE_PREVIEW_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Image Preview Success' Action
 * @param payload Contains object with image
 */
export class GetImagePreviewSuccessAction implements Action {
    type = ActionTypes.GET_IMAGE_PREVIEW_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Image Preview Error' Action
 * @param payload Contains object with errors
 */
export class GetImagePreviewErrorAction implements Action {
    type = ActionTypes.GET_IMAGE_PREVIEW_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Filename Preview Request' Action
 * @param payload Contains object with image params
 */
export class GetFilenamePreviewRequestAction implements Action {
    type = ActionTypes.GET_FILENAME_PREVIEW_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Filename Preview Success' Action
 * @param payload Contains object with filename preview
 */
export class GetFilenamePreviewSuccessAction implements Action {
    type = ActionTypes.GET_FILENAME_PREVIEW_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Filename Preview Error' Action
 * @param payload Contains object with errors
 */
export class GetFilenamePreviewErrorAction implements Action {
    type = ActionTypes.GET_FILENAME_PREVIEW_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Generate Zip Request' Action
 * @param payload Contains object with image params
 */
export class GenerateZipRequestAction implements Action {
    type = ActionTypes.GENERATE_ZIP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Generate Zip Success' Action
 * @param payload Contains object with celery task id
 */
export class GenerateZipSuccessAction implements Action {
    type = ActionTypes.GENERATE_ZIP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Generate Zip Error' Action
 * @param payload Contains object with errors
 */
export class GenerateZipErrorAction implements Action {
    type = ActionTypes.GENERATE_ZIP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Zip Status Request' Action
 * @param payload Contains celery task id
 */
export class GetZipStatusRequestAction implements Action {
    type = ActionTypes.GET_ZIP_STATUS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Zip Status Success' Action
 * @param payload Contains object with celery task info
 */
export class GetZipStatusSuccessAction implements Action {
    type = ActionTypes.GET_ZIP_STATUS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Zip Status Error' Action
 * @param payload Contains object with errors
 */
export class GetZipStatusErrorAction implements Action {
    type = ActionTypes.GET_ZIP_STATUS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Available Images Request' Action
 * @param payload Contains array of products
 */
export class GetAvailableImagesRequestAction implements Action {
    type = ActionTypes.GET_AVAILABLE_IMAGES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Available Images Success' Action
 * @param payload Contains object with available images
 */
export class GetAvailableImagesSuccessAction implements Action {
    type = ActionTypes.GET_AVAILABLE_IMAGES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Available Images Error' Action
 * @param payload Contains object with errors
 */
export class GetAvailableImagesErrorAction implements Action {
    type = ActionTypes.GET_AVAILABLE_IMAGES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Validate Image Existence Request' Action
 * @param payload Contains array of products and chosen images (base & dynamic)
 */
export class ValidateImageExistenceRequestAction implements Action {
    type = ActionTypes.VALIDATE_IMAGE_EXISTENCE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Validate Image Existence Success' Action
 * @param payload Contains success response data
 */
export class ValidateImageExistenceSuccessAction implements Action {
    type = ActionTypes.VALIDATE_IMAGE_EXISTENCE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Validate Image Existence Error' Action
 * @param payload Contains object with errors
 */
export class ValidateImageExistenceErrorAction implements Action {
    type = ActionTypes.VALIDATE_IMAGE_EXISTENCE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Filename Options Request' Action
 * @param payload
 */
export class GetFilenameOptionsRequestAction implements Action {
    type = ActionTypes.GET_FILENAME_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Filename Options Success' Action
 * @param payload Contains object with availabel tags
 */
export class GetFilenameOptionsSuccessAction implements Action {
    type = ActionTypes.GET_FILENAME_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Products' Action
 */
export class ClearProductsAction implements Action {
    type = ActionTypes.CLEAR_PRODUCTS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Image Preview' Action
 */
export class ClearImagePreviewAction implements Action {
    type = ActionTypes.CLEAR_IMAGE_PREVIEW;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Filename Preview' Action
 */
export class ClearFilenamePreviewAction implements Action {
    type = ActionTypes.CLEAR_FILENAME_PREVIEW;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Generate Zip' Action
 */
export class ClearGenerateZipAction implements Action {
    type = ActionTypes.CLEAR_GENERATE_ZIP;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Zip Status' Action
 */
export class ClearZipStatusAction implements Action {
    type = ActionTypes.CLEAR_ZIP_STATUS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Available Images' Action
 */
export class ClearAvailableImagesAction implements Action {
    type = ActionTypes.CLEAR_AVAILABLE_IMAGES;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Filename Options' Action
 */
export class ClearFilenameOptionsAction implements Action {
    type = ActionTypes.CLEAR_FILENAME_OPTIONS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Validate Image Existence' Action
 */
export class ClearValidateImageExistenceAction implements Action {
    type = ActionTypes.CLEAR_VALIDATE_IMAGE_EXISTENCE;

    constructor(public payload?: any) {}
}

export type Actions =
    | SetProductsAction
    | GetImagePreviewRequestAction
    | GetImagePreviewSuccessAction
    | GetImagePreviewErrorAction
    | GetFilenamePreviewRequestAction
    | GetFilenamePreviewSuccessAction
    | GetFilenamePreviewErrorAction
    | GenerateZipRequestAction
    | GenerateZipSuccessAction
    | GenerateZipErrorAction
    | GetZipStatusRequestAction
    | GetZipStatusSuccessAction
    | GetZipStatusErrorAction
    | GetAvailableImagesRequestAction
    | GetAvailableImagesSuccessAction
    | GetAvailableImagesErrorAction
    | ValidateImageExistenceRequestAction
    | ValidateImageExistenceSuccessAction
    | ValidateImageExistenceErrorAction
    | GetFilenameOptionsRequestAction
    | GetFilenameOptionsSuccessAction
    | ClearProductsAction
    | ClearImagePreviewAction
    | ClearFilenamePreviewAction
    | ClearGenerateZipAction
    | ClearZipStatusAction
    | ClearAvailableImagesAction
    | ClearFilenameOptionsAction
    | ClearValidateImageExistenceAction;
