import { Params } from '@angular/router';
import { EntitiesFrom, ProductCategory } from '@core/models';
import { saveAs } from 'file-saver';

const typeCache: { [label: string]: boolean } = {};

declare const window: any;

export function type<T>(label: T | ''): T {
    if (typeCache[label as string]) {
        throw new Error(`Action type "${label}" is not unique.`);
    }

    typeCache[label as string] = true;

    return label as T;
}

export function snakeToPascal(action: string): string {
    return action
        .split('_')
        .join(' ')
        .replace(/(\w)(\w*)/g, (word, letter, rest) => {
            return letter.toUpperCase() + rest.toLowerCase();
        });
}

export function snakeToCamelCase(value: string): string {
    return value
        .split('_')
        .map((chunk, index) => (index ? `${chunk.charAt(0).toUpperCase()}${chunk.substr(1)}` : chunk))
        .join('');
}

export function prepareActions<T extends string>(prefix: string, types: T[]): { [K in T]: string } {
    return types.reduce((actions: T[], action: string) => {
        return {
            ...actions,
            [action]: type(`${prefix} ${snakeToPascal(action)}`),
        };
    }, Object.create(null));
}

/**
 * Generate unique ID.
 * @returns Unique string ID
 */
export function generateUniqueId(): string {
    return 'xxxxxxxxxx'.replace(/[x]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/**
 * Check if object is empty - used to check if there is any error
 * @param object Object to be checked
 * @returns True if object is empty, otherway return false
 */
export function isEmptyObject(object): boolean {
    return !Object.keys(object).length;
}

/**
 * Determine if an array contains one or more item from another array.
 * @param haystack the array to serach.
 * @param array the array providing items to check for in the haystack.
 * @returns boolean
 */
export function haveCommonValues(haystack: any[], array: any[]): boolean {
    return array.some((item) => !!~haystack.indexOf(item));
}

/**
 * Get the value from fibonacci sequence for defined number
 * @param num defined number
 * @returns number from fibonacci sequence
 */
export function fibonacci(num: number): number {
    let a = 1;
    let b = 0;
    let temp;

    while (num >= 0) {
        temp = a;
        a = a + b;
        b = temp;
        num--;
    }

    return b;
}

/**
 * Get the query param from the url
 * @param name Query param name
 * @returns Query param value
 */
export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getUrlWithoutParams(url: string): string {
    return (
        url
            // remove query params after question mark
            .split('?')[0]
            // remove query params after encoded question mark (used for AWS Signature)
            .split('%3F')[0]
    );
}

/**
 * Get filename from the url
 * @param url Resource path
 */
export function getFilenameFromURL(url: any, withParams: boolean = false): string {
    url = typeof url === 'string' ? url.split('/').pop() : url.changingThisBreaksApplicationSecurity.split('/').pop();

    return withParams ? url : getUrlWithoutParams(url);
}

export function getFileExtenstionFromURL(url: string): string {
    return getUrlWithoutParams(url)
        .split('.')
        .pop();
}

export function removeUrlQueryParams(value: any): any {
    if (value === null || value.length === 0) {
        return value;
    } else if (typeof value === 'string' && !!~value.indexOf('http')) {
        return getUrlWithoutParams(value);
    } else if (value.constructor === Array) {
        return value.map(removeUrlQueryParams);
    } else if (value.constructor === Object) {
        return parseObjectValuesByFn(value, removeUrlQueryParams);
    } else {
        return value;
    }
}

export function parseObjectValuesByFn(value: object, fn): object {
    return Object.entries(value).reduce((acc: object, [key, value]: [string, any]) => {
        return {
            ...acc,
            [key]: fn(value),
        };
    }, {});
}

export function sortASC(a: string | number | boolean, b: string | number | boolean): number {
    return a < b ? -1 : a > b ? 1 : 0;
}

export function sortDESC(a: string | number | boolean, b: string | number | boolean): number {
    return a > b ? -1 : a < b ? 1 : 0;
}

export function naturalCompare(a: any = '', b: any = '') {
    const ax = [],
        bx = [];
    a.toString().replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        ax.push([$1 || Infinity, $2 || '']);
    });
    b.toString().replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
        const an = ax.shift();
        const bn = bx.shift();
        const nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
        if (nn) {
            return nn;
        }
    }

    return ax.length - bx.length;
}

/**
 * Removes params with empty strings or null values
 * @param params Object with params
 */
export function filterParams(params: any, excludeBoolean: boolean = false): any {
    return Object.entries(params).reduce((acc, [key, value]) => {
        return value === 0 || (!excludeBoolean && typeof value === 'boolean') || (value && !Array.isArray(value))
            ? { ...acc, [key]: value }
            : Array.isArray(value) && value.length
            ? { ...acc, [key]: value }
            : acc;
    }, {});
}

export function parseToQueryParams(params: Params): Params {
    return Object.entries(params).reduce((query, [key, value]: [string, any]) => {
        return {
            ...query,
            [key]: value && value.constructor === Array ? value.join(', ') : value,
        };
    }, {});
}

export function parseToQueryString(params: Params = {}): string {
    const queryString = Object.entries(params)
        .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
        .join('&');

    return queryString ? `?${queryString}` : '';
}

export function formatSize(maxSize: any, startMultiple: number = 0, si: boolean = true): string {
    const thresh = si ? 1000 : 1024;
    const units = si
        ? ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let multiple = startMultiple; // here we can start e.g. with MB
    let size = +maxSize;
    while (Math.abs(size) > 1000 && multiple < units.length - 1) {
        size = size / thresh;
        multiple++;
    }
    return `${Math.round(size * 100) / 100} ${units[multiple]}`;
}

export function createEntities<T>(array: T[], valueAsKey?: string): EntitiesFrom<T> {
    return array.reduce(
        (acc: EntitiesFrom<T>, step: T, index: number) => {
            const key = valueAsKey ? step[valueAsKey] : index;
            return {
                ...acc,
                [key]: step,
                ids: [...acc.ids, key],
            };
        },
        { ids: [] },
    ) as EntitiesFrom<T>;
}

export function buildFormData(object: any): FormData {
    const formData = new FormData();

    Object.entries(object).forEach(([key, value]: any) => {
        if (Array.isArray(value)) {
            value.forEach((arrayElem) => {
                formData.append(key, arrayElem);
            });
        } else {
            formData.append(key, value);
        }
    });

    return formData;
}

export function getValueByDotNotation<T>(path: string, object: any): T {
    return path.split('.').reduce((acc, key) => acc[key], object);
}

export function buildCategoriesTree(category: ProductCategory): string {
    return category.parents
        .map((parent) => parent.translations.en.name)
        .concat(category.translations.en.name)
        .join(' > ');
}

export function getNested(object, path, separator = '.') {
    try {
        return path
            .replace('[', separator)
            .replace(']', '')
            .split(separator)
            .reduce((obj, property) => {
                return obj[property];
            }, object);
    } catch (err) {
        return undefined;
    }
}

// TODO: Make it Enum from this when TypeScript will be upgraded to > 2.4.0
export const ComponentAnimationState = {
    VOID: '0',
    INIT: '1',
};

export function numericInputKeyDown(e: KeyboardEvent): boolean {
    const key = e.which || e.keyCode;
    if (
        !e.shiftKey &&
        // numbers
        ((key >= 48 && key <= 57) ||
            // Numeric keypad
            (key >= 96 && key <= 105) ||
            // allow: Ctrl+A
            (key === 65 && (e.ctrlKey || e.metaKey)) ||
            // allow: Ctrl+C
            (key === 67 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+V
            (key === 86 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+X
            (key === 88 && (e.ctrlKey || e.metaKey)) ||
            // allow: home, end, left, right
            (key >= 35 && key <= 39) ||
            // Backspace and Tab and Enter
            [8, 9, 13].includes(key) ||
            // Ins, Del, comma, dash, dot
            [45, 46, 188, 189, 190].includes(key))
    ) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
}

export class Deferred<T> {
    public promise: Promise<T>;
    public resolve: (value?: T | PromiseLike<T>) => void;
    public reject: (reason?: any) => void;

    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

/**
 * API converts search params from snake_case to camelCase, sended `company__in` key is converted to `company_in`
 * @param params search params
 */
export function convertWrongParsedQuery(params: Params): Params {
    return Object.entries(params).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key.replace(/^([a-z0-9]+)_([a-z0-9]+)$/, (match, p1, p2) => `${p1}__${p2}`)]: value,
        }),
        {},
    );
}

export function removeFieldsFromObject<T>(keys: Array<keyof T>, initialValue: T): Partial<T> {
    return (keys as string[]).reduce(
        (reduced: any, key: string) => {
            // tslint:disable-next-line:no-unused-variable
            const { [key]: removed, ...restParams } = reduced;
            return restParams;
        },
        { ...(initialValue as any) },
    );
}

export function getPropByPath(path: string, obj: any): any {
    return path.split('.').reduce((xs, prop) => (xs && xs[prop] ? xs[prop] : null), obj);
}

const scrollToXY = (x: number = 0, y: number = 0) => window.scrollTo(x, y);

/**
 * ~300px has menu-header & title
 */
export const scrollToBeginOfTheList = () => scrollToXY(0, 300);
export const scrollToTop = () => scrollToXY(0, 0);

export function convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize= 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
}

export function exportFile(state: any, fileName: string) {
    const blob = new Blob([JSON.stringify(state, null, 2)], { type: 'text/json' });
    saveAs(blob, fileName);
}
