import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromProductCategories from '../reducers/product-categories.reducer';

@Injectable()
export class ProductCategoriesSelectors {
    /**
     * Get state.productCategories
     */
    getProductCategoriesState() {
        return ($state: State): any => $state.productCategories;
    }

    /**
     * Get list from state.productCategories
     */
    getList() {
        return createSelector(
            this.getProductCategoriesState(),
            fromProductCategories.getList,
        );
    }

    /**
     * Get filtered from state.productCategories
     */
    getFiltered() {
        return createSelector(
            this.getProductCategoriesState(),
            fromProductCategories.getFiltered,
        );
    }
}
