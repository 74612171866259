import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromCompanyDomains from '../reducers/company-domains.reducer';

@Injectable()
export class CompanyDomainsSelectors {
    /**
     * Get state.companyDomains
     */
    getCompanyDomainsState() {
        return ($state: State): any => $state.companyDomains;
    }

    /**
     * Get list from state.companyDomains
     */
    getList() {
        return createSelector(
            this.getCompanyDomainsState(),
            fromCompanyDomains.getList,
        );
    }

    /**
     * Get manage from state.companyDomains
     */
    getManage() {
        return createSelector(
            this.getCompanyDomainsState(),
            fromCompanyDomains.getManage,
        );
    }

    /**
     * Get options from state.companyDomains
     */
    getOptions() {
        return createSelector(
            this.getCompanyDomainsState(),
            fromCompanyDomains.getOptions,
        );
    }
}
