import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BulkSelectOption, ProductId, ProductsList } from '@core/models';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BulkActionsComponent),
    multi: true,
};

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    selector: 'bulk-actions',
    templateUrl: './bulk-actions.component.html',
})
export class BulkActionsComponent implements ControlValueAccessor, OnChanges {
    @Input() actions: BulkSelectOption[];
    @Input() selectedItems: ProductId[] = [];
    @Input() productsList: ProductsList;
    @Input() type: string = 'select';
    @Input() buttonLoading: boolean = false;
    @Output() bulkActionExecute: EventEmitter<string> = new EventEmitter();

    @ViewChild('label') label: ElementRef;
    @ViewChild('list') list: ElementRef;

    public isOpen: boolean = true;
    public bulkActionBtnDisabled: boolean;
    private _value: any = '';
    private disabled: boolean = false;

    public selectedCount: number = 0;

    private onChange = (_: any) => {};
    private onTouched = () => {};

    get value(): any {
        return this._value;
    }

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
        this.shouldDisableButton();
    }

    writeValue(value: any) {
        if (value !== this._value) {
            this._value = value;
        }
    }

    registerOnChange(fn: (_: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    /**
     * Trigger function to check if button should be active or disabled
     *
     * @param changes Object with changes
     */
    ngOnChanges(changes: any): void {
        if (changes.actions) {
            this.value = '';
        }

        if (changes.selectedItems) {
            this.shouldDisableButton();
        }
    }

    /**
     * Check wheter the button should be disabled
     */
    shouldDisableButton(): void {
        const selectedAction = this.actions.find((action) => action.value === this._value);

        this.bulkActionBtnDisabled =
            !selectedAction ||
            (selectedAction.single && this.selectedItems.length !== 1) ||
            (!selectedAction.single && this.selectedItems.length === 0);
    }

    /**
     * Emit bulk action execute
     */
    executeBulkAction(): void {
        this.bulkActionExecute.emit(this._value);
    }

    /**
     * Hide/show menu items
     */
    toggleList(): void {
        this.isOpen = !this.isOpen;
    }

    onMenuSelect(actionType: string): void {
        this.bulkActionExecute.emit(actionType);
    }
}
