import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';

import { State } from '@core/reducers';

@Injectable()
export class RouterSelectors {
    /**
     * Get state
     */
    getRouterState() {
        return ($state: State): any => $state.routerState;
    }

    getRoutes() {
        return createSelector(
            this.getRouterState(),
            (currentRoute) => currentRoute,
        );
    }
}
