import * as attributesActions from '@core/actions/attributes.actions';
import {
    Attribute,
    AttributesImportStatus,
    AttributesImportTemplate,
    AttributesList,
    AttributesManage,
    AttributesOptions,
    AttributesPrepareImport,
} from '@core/models';

import * as actions from '@core/actions/listed-products.actions';
import {
    listInitialState,
    loadableState,
    objectDataInitialState,
    optionsInitialState,
    pipeHigherOrderReducers,
    resetState,
} from '@core/reducers/utils';

export interface State {
    importStatus: AttributesImportStatus;
    importTemplate: AttributesImportTemplate;
    list: AttributesList;
    manage: AttributesManage;
    options: AttributesOptions;
    prepareImport: AttributesPrepareImport;
}

export const attributeInitialState: Attribute = {
    assignedGroupsCount: null,
    attributeId: null,
    certificates: [],
    choices: [],
    defaultMetric: null,
    dosageMetricFamily: null,
    id: null,
    ingredientAmountMetricFamily: null,
    ingredientGroup: null,
    isLocalized: false,
    isLockedDown: false,
    isRequired: false,
    isLockedForValueChange: false,
    isUnique: false,
    metricFamily: null,
    nutrientAssignments: [],
    properties: {},
    scope: 'global',
    sizeTypeMetrics: null,
    translations: {},
    type: null,
    validators: {},
};

const manageInitialState = {
    data: attributeInitialState,
    errors: {},
    isFetching: true,
    isSaving: false,
    isSaved: false,
};

export const initialState: State = {
    importStatus: {
        ...objectDataInitialState,
        isFetching: false,
    },
    importTemplate: {
        ...objectDataInitialState,
        isFetching: false,
    },
    list: listInitialState,
    manage: manageInitialState,
    options: optionsInitialState,
    prepareImport: {
        ...objectDataInitialState,
        isFetching: false,
    },
};

export const baseReducer = (state: State = initialState, action: attributesActions.Actions): State => {
    switch (action.type) {
        case attributesActions.ActionTypes.NEW_ATTRIBUTE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    data: {
                        ...initialState.manage.data,
                        type: action.payload,
                    },
                    isFetching: false,
                },
            };
        }

        case attributesActions.ActionTypes.SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_SUCCESS: {
            const certificates = state.manage.data.certificates.map((certificate) => {
                if (certificate.position === action.payload.position) {
                    return {
                        ...certificate,
                        image: action.payload.image,
                    };
                }
                return certificate;
            });
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: {
                        ...state.manage.data,
                        certificates,
                    },
                    isSaving: false,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export function reducer(state: State = initialState, action: actions.Actions) {
    return pipeHigherOrderReducers(
        loadableState<State, attributesActions.Actions>(initialState, 'list', [
            attributesActions.ActionTypes.GET_ATTRIBUTES_REQUEST,
            attributesActions.ActionTypes.GET_ATTRIBUTES_SUCCESS,
            attributesActions.ActionTypes.GET_ATTRIBUTES_ERROR,
        ]),
        loadableState<State, attributesActions.Actions>(initialState, 'manage', [
            attributesActions.ActionTypes.GET_ATTRIBUTE_REQUEST,
            attributesActions.ActionTypes.GET_ATTRIBUTE_SUCCESS,
            attributesActions.ActionTypes.GET_ATTRIBUTE_ERROR,

            attributesActions.ActionTypes.CREATE_ATTRIBUTE_REQUEST,
            attributesActions.ActionTypes.CREATE_ATTRIBUTE_SUCCESS,
            attributesActions.ActionTypes.CREATE_ATTRIBUTE_ERROR,

            attributesActions.ActionTypes.SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_REQUEST,
            attributesActions.ActionTypes.SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_ERROR,

            attributesActions.ActionTypes.SAVE_ATTRIBUTE_REQUEST,
            attributesActions.ActionTypes.SAVE_ATTRIBUTE_SUCCESS,
            attributesActions.ActionTypes.SAVE_ATTRIBUTE_ERROR,

            attributesActions.ActionTypes.DELETE_ATTRIBUTE_REQUEST,
            attributesActions.ActionTypes.DELETE_ATTRIBUTE_SUCCESS,
            attributesActions.ActionTypes.DELETE_ATTRIBUTE_ERROR,

            attributesActions.ActionTypes.DELETE_ATTRIBUTES_REQUEST,
            attributesActions.ActionTypes.DELETE_ATTRIBUTES_SUCCESS,
            attributesActions.ActionTypes.DELETE_ATTRIBUTES_ERROR,
        ]),
        loadableState<State, attributesActions.Actions>(initialState, 'importTemplate', [
            attributesActions.ActionTypes.GET_IMPORT_TEMPLATE_REQUEST,
            attributesActions.ActionTypes.GET_IMPORT_TEMPLATE_SUCCESS,
            attributesActions.ActionTypes.GET_IMPORT_TEMPLATE_ERROR,
        ]),
        loadableState<State, attributesActions.Actions>(initialState, 'prepareImport', [
            attributesActions.ActionTypes.PREPARE_IMPORT_REQUEST,
            attributesActions.ActionTypes.PREPARE_IMPORT_SUCCESS,
            attributesActions.ActionTypes.PREPARE_IMPORT_ERROR,
        ]),
        loadableState<State, attributesActions.Actions>(initialState, 'importStatus', [
            attributesActions.ActionTypes.GET_IMPORT_STATUS_REQUEST,
            attributesActions.ActionTypes.GET_IMPORT_STATUS_SUCCESS,
            attributesActions.ActionTypes.GET_IMPORT_STATUS_ERROR,
        ]),
        loadableState<State, attributesActions.Actions>(initialState, 'options', [
            attributesActions.ActionTypes.GET_OPTIONS_REQUEST,
            attributesActions.ActionTypes.GET_OPTIONS_SUCCESS,
        ]),
        resetState<State>(initialState, [
            ['list', attributesActions.ActionTypes.CLEAR_LIST],
            ['manage', attributesActions.ActionTypes.CLEAR_MANAGE],
            ['options', attributesActions.ActionTypes.CLEAR_OPTIONS],
        ]),
    )(baseReducer)(state, action);
}

export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsFetching = (state: State) => state.manage.isFetching;
export const getManageIsSaving = (state: State) => state.manage.isSaving;

export const getImportTemplate = (state: State) => state.importTemplate;
export const getImportTemplateData = (state: State) => state.importTemplate.data;
export const getImportTemplateErrors = (state: State) => state.importTemplate.errors;
export const getImportTemplateIsFetching = (state: State) => state.importTemplate.isFetching;

export const getPrepareImport = (state: State) => state.prepareImport;
export const getPrepareImportData = (state: State) => state.prepareImport.data;
export const getPrepareImportErrors = (state: State) => state.prepareImport.errors;
export const getPrepareImportIsFetching = (state: State) => state.prepareImport.isFetching;

export const getImportStatus = (state: State) => state.importStatus;
export const getImportStatusData = (state: State) => state.importStatus.data;
export const getImportStatusErrors = (state: State) => state.importStatus.errors;
export const getImportStatusIsFetching = (state: State) => state.importStatus.isFetching;

export const getOptions = (state: State) => state.options;
export const getOptionsData = (state: State) => state.options.data;
export const getOptionsIsFetching = (state: State) => state.options.isFetching;
