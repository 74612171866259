import { Injectable, Injector } from '@angular/core';
import { BaseGuard } from './base.guards';

@Injectable()
export class MaintenanceGuard extends BaseGuard {
    path = ['currentUser', 'runMaintenanceTasks'];

    constructor(injector: Injector) {
        super(injector);
    }
}
