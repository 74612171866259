import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class AttributeGroupsReadGuard extends BaseGuard {
    path = ['attributeGroups', 'read'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class AttributeGroupsManageGuard extends BaseGuard {
    path = ['attributeGroups', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}
