import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { LocalUser, ProductCategoriesList, ProductCategory, SelectCategory } from '@core/models';

import { isFtaCompanySetInUser } from '@app/fta-config';
import { generateCategoryLeaf } from '@app/products/utils';
import { rowTransition } from '@core/animations/wrapper.animations';

@Component({
    selector: 'select-category',
    templateUrl: './select-category.component.html',
    styleUrls: ['./select-category.component.scss'],
    animations: [rowTransition],
})
export class SelectCategoryComponent implements OnChanges {
    @Input() categoriesList: ProductCategoriesList;
    @Input() filteredCategories: ProductCategoriesList;
    @Input() showPlaceholder: boolean = true;
    @Input() searchBox: boolean = false;
    @Input() autoExpand: boolean = false;
    @Input() addByButton: boolean = false;
    @Input() multiple: boolean = true;
    @Input() disableSelecting: boolean = true;
    @Input() allowClear: boolean = false;
    @Input() searchCategoryControl: FormControl = new FormControl('');
    @Input() selected: any;
    @Input() ftaPlaceholder?: boolean = false;
    @Output() getCategory: EventEmitter<any> = new EventEmitter();
    @Output() categorySelect: EventEmitter<any> = new EventEmitter();
    @Output() categoryDeselect: EventEmitter<any> = new EventEmitter();
    @Output() categorySearch: EventEmitter<any> = new EventEmitter();
    @Input() localUser?: LocalUser;
    @Input() allowCategoryChange?: boolean = true;
    @Input() readonly?: boolean = false;

    categories: any[] = [];
    filtered: any[] = [];
    canClear: boolean = false;
    editLast: boolean = false;
    lastCategoryID: number;
    lastUsedSelect: number = 0;
    lastSelectCategory: SelectCategory;

    filteredParents: any[] = [];
    filteredParentsIndex: number = 0;
    wasInitialized: boolean = false;

    get parsedCategories(): any {
        return this.categories.map((item) => {
            return {
                controlValue: item.control.value,
                optionsLength: item.options.length,
                firstOption: item.options.length ? item.options[0].displayName : null,
                results: item.results.length,
            };
        });
    }

    /**
     * Listen for changing of categoriesList in store.
     * @param value Object with changes
     */
    ngOnChanges(value: any): void {
        if (value.categoriesList && !value.categoriesList.currentValue.isFetching) {
            this.renderSelects(this.categoriesList.data.results);

            if (
                value.categoriesList.currentValue &&
                value.categoriesList.currentValue.data.results.length &&
                this.filteredParents.length &&
                this.autoExpand
            ) {
                this.renderFilteredCategorySelects();
            }
        }

        if (value.selected && value.selected.currentValue && !this.wasInitialized) {
            this.onFilteredCategorySelect(this.convertToOption(this.selected));
            this.wasInitialized = true;
        }

        if (value.filteredCategories && value.filteredCategories.currentValue) {
            this.filtered = this.filteredCategories.data.results.map((category) => {
                return {
                    displayName: generateCategoryLeaf(category),
                    value: category.id,
                    details: category,
                };
            });
        }
    }

    /**
     * Get current selected category id and emit for dispatch new category.
     * @param selectedOption Option object from ng-select component.
     * @param index
     */
    onCategorySelect(selectedOption: any, index: number = 0): void {
        this.lastCategoryID = selectedOption.value;
        this.lastUsedSelect = index + 1;
        this.getCategory.emit(selectedOption.value);

        this.lastSelectCategory = {
            id: selectedOption.value,
            name: selectedOption.displayName,
            parents: selectedOption.details.parents,
            isLeaf: selectedOption.details.isLeaf,
        };

        if (!this.addByButton) {
            this.updateEmit(this.lastSelectCategory);
        }
    }

    onFilteredCategorySelect(selectedOption: any): void {
        this.filteredParents = [...selectedOption.details.parents.map((item) => item.id), +selectedOption.value];

        if (this.autoExpand) {
            this.renderFilteredCategorySelects();
        } else {
            this.updateEmit({
                id: selectedOption.value,
                name: selectedOption.details.translations.en.name,
                parents: selectedOption.details.parents,
                isLeaf: selectedOption.isLeaf,
            });
        }
    }

    onFilteredCategoryDeselect(deselectedOption: any): void {
        this.categoryDeselect.emit({
            id: deselectedOption.value,
            name: deselectedOption.details.translations.en.name,
            parents: deselectedOption.details.parents,
            isLeaf: deselectedOption.isLeaf,
        });
    }

    renderFilteredCategorySelects(): void {
        if (!this.categories.length) {
            return;
        }

        this.lastCategoryID = this.filteredParents.shift();
        this.lastUsedSelect = this.filteredParentsIndex + 1;
        this.getCategory.emit(this.lastCategoryID);
        this.categories[this.filteredParentsIndex].control.setValue(this.lastCategoryID);
        this.filteredParentsIndex++;

        if (!this.filteredParents.length) {
            this.filteredParentsIndex = 0;
            const category = this.getCategoryById(this.lastCategoryID);
            this.updateEmit({
                id: category.id,
                name: category.translations.en.name,
                parents: category.parents,
                isLeaf: category.isLeaf,
            });
        }
    }

    /**
     * Create list of selected categories with FormControl and results.
     * @param results Array list of categories.
     */
    renderSelects(results: any): void {
        this.categories = this.categories.slice(0, this.lastUsedSelect);
        if (results.length) {
            this.categories.push({
                control: new FormControl(this.lastCategoryID),
                options: results.map((result) => this.convertToOption(result)),
                results,
            });
        }
    }

    /**
     * Clear category selection.
     */
    clearSelections(): void {
        this.categories = this.categories.slice(0, 1);
        this.categories[0].control.reset();
    }

    /**
     * Emit selected category and clear current categories selection
     */
    onSelectFromButton(): void {
        this.categorySelect.emit(this.lastSelectCategory);
        this.clearSelections();
    }

    /**
     * Emit for dispatch for search categories by term and do nothing more
     * @param term search term
     */
    onFindCategories(term: string): void {
        this.categorySearch.emit(term);
    }

    /**
     * Emit selected category to parent component
     * @param category data
     */
    private updateEmit(category: SelectCategory): void {
        this.categorySelect.emit(category);
    }

    /**
     * Convert ProductCategory object to object for ng-select options.
     * @param category ProductCategory object.
     * @returns ng-select option object
     */
    private convertToOption(category: ProductCategory): any {
        return {
            value: category.id,
            displayName: category.name || category.translations.en.name,
            details: category,
        };
    }

    private shouldShowSearch(): boolean {
        return !this.localUser || !isFtaCompanySetInUser(this.localUser) && this.allowCategoryChange;
    }

    /**
     * Finding category object by specified ID.
     * @param id ID of the object being searched.
     * @returns ProductCategory object
     */
    private getCategoryById(id: any): any {
        // step 1: Reduce categories levels into one array.
        // step 2: Find in reduced array for specified id.
        return this.categories.reduce((p, c) => p.concat(c.results), []).find((item) => item.id === parseInt(id));
    }
}
