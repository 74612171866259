import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

declare var ga;

@Injectable()
export class AppTitleService {
    private appName: string = 'BrandSync App';
    private title: string;
    private titleModel: any = {
        notificationsCount: 0,
        pageName: '',
        itemId: '',
        pageNumber: 0,
        appName: this.appName,
    };
    private defaultPart = {
        separatorBefore: false,
        before: false,
        after: false,
        value: false,
    };
    private onceTimer: any = null;

    constructor(private titleService: Title) {
        this.sendOnceFn = this.sendOnceFn.bind(this);
    }

    public set(options: any): void {
        this.titleModel = {
            ...this.titleModel,
            appName: this.appName,
            pageNumber: 0,
            itemId: '',
            ...options,
        };
        this.setTitle();
        this.sendOnce();
    }

    public setPageName(pageName: string): void {
        this.titleModel.pageName = pageName;
        this.setTitle();
    }

    public setItemId(itemId: string | number): void {
        this.titleModel.itemId = itemId;
        this.setTitle();
    }

    public setPageNumber(pageNumber: any): void {
        this.titleModel.pageNumber = parseInt(pageNumber);
        this.setTitle();
    }

    public addNotificationCount(): void {
        this.titleModel.notificationsCount++;
        this.setTitle();
    }

    public clearNotifications(): void {
        this.titleModel.notificationsCount = 0;
        this.setTitle();
    }

    private setTitle(): void {
        const titleArray = [
            {
                value: this.titleModel.notificationsCount,
                before: '(',
                after: ')',
            },
            {
                value: this.titleModel.pageName,
            },
            {
                value: this.titleModel.itemId,
                before: '(#',
                after: ')',
            },
            {
                value: this.titleModel.pageNumber,
                separatorBefore: '-',
                before: 'Page: ',
            },
            {
                value: this.titleModel.appName,
                separatorBefore: '|',
            },
        ];

        this.title = titleArray.reduce((lastTitle, titleItem) => {
            let title = '';
            const part = {
                ...this.defaultPart,
                ...titleItem,
            };

            part.separatorBefore = !lastTitle.length ? '' : part.separatorBefore;

            if (part.value && part.value.toString().length) {
                title += part.separatorBefore ? ` ${part.separatorBefore}` : '';
                title += !lastTitle.length ? '' : ' ';
                title += part.before ? part.before : '';
                title += part.value;
                title += part.after ? part.after : '';
            }
            return `${lastTitle}${title}`;
        }, '');

        this.titleService.setTitle(this.title);
    }

    sendOnce() {
        if (this.onceTimer !== null) {
            clearTimeout(this.onceTimer);
            this.onceTimer = null;
        }

        this.onceTimer = setTimeout(this.sendOnceFn, 1500);
    }

    sendOnceFn() {
        ga('send', 'pageview', location.pathname);
    }
}
