import { type } from '@app/utils';
import { CountriesGetPayload } from '@core/models';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Utils]';

export const ActionTypes = {
    GET_COUNTRIES_REQUEST: type(`${ACTION_PREFIX} Get Countries Request`),
    GET_COUNTRIES_SUCCESS: type(`${ACTION_PREFIX} Get Countries Success`),
    GET_COUNTRIES_ERROR: type(`${ACTION_PREFIX} Get Countries Error`),
};

/**
 * Create 'Get Countries Request' Action
 */
export class GetCountriesRequestAction implements Action {
    type = ActionTypes.GET_COUNTRIES_REQUEST;
    constructor(public payload?: CountriesGetPayload) {}
}

/**
 * Create 'Get Countries Success' Action
 * @param payload Contains countries list
 */
export class GetCountriesSuccessAction implements Action {
    type = ActionTypes.GET_COUNTRIES_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Countries Error' Action
 * @param payload Contains object with errors
 */
export class GetCountriesErrorAction implements Action {
    type = ActionTypes.GET_COUNTRIES_ERROR;
    constructor(public payload?: any) {}
}

export type Actions = GetCountriesRequestAction | GetCountriesSuccessAction | GetCountriesErrorAction;
