import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type AvailableSize = 'small' | 'normal' | 'medium' | 'large';
type AvailableColor = 'base' | 'primary' | 'secondary' | 'alert' | 'error';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'file-display',
    styleUrls: ['file-display.component.scss'],
    templateUrl: 'file-display.component.html',
})
export class FileDisplayComponent {
    @Input() fileUrl: string;
    @Input() noFileLabel: string = 'No file';
    @Input() iconSize: AvailableSize = 'normal';
    @Input() iconColor: AvailableColor = 'base';
    @Input() labelColor: AvailableColor = 'base';
    @Input() showLink: boolean = false;
    @Input() showFullUrl: boolean = false;
    @Input() openExternal: boolean = true;
    @Input() isDisabled: boolean = false;
}
