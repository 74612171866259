import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as refresh from '../reducers/refresh.reducer';

@Injectable()
export class RefreshSelectors {

  /**
   * Get state refresh
   */
  getState() {
    return ($state: State): any =>
      $state.refresh;
  }

  /**
  * Get requireRefresh from state.refresh
  */
  getRequireRefresh() {
    return createSelector(this.getState(), refresh.getIsRequireRefresh);
  }
}
