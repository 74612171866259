import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

import { BulkAction } from '@core/models';

export const ActionTypes = prepareActions('[Brands]', [
    'GET_BRANDS_REQUEST',
    'GET_BRANDS_SUCCESS',
    'GET_BRANDS_ERROR',
    'CLEAR_BRANDS_DATA',

    'CREATE_BRAND_REQUEST',
    'CREATE_BRAND_SUCCESS',
    'CREATE_BRAND_ERROR',

    'GET_BRAND_REQUEST',
    'GET_BRAND_SUCCESS',
    'GET_BRAND_ERROR',
    'CLEAR_BRAND_DATA',

    'SAVE_BRAND_REQUEST',
    'SAVE_BRAND_SUCCESS',
    'SAVE_BRAND_ERROR',

    'DELETE_BRAND_REQUEST',
    'DELETE_BRAND_SUCCESS',
    'DELETE_BRAND_ERROR',

    'PREPARE_BRANDS_IMPORT_REQUEST',
    'PREPARE_BRANDS_IMPORT_SUCCESS',
    'PREPARE_BRANDS_IMPORT_ERROR',
    'CLEAR_PREPARE_BRANDS_IMPORT',

    'GET_BRANDS_IMPORT_STATUS_REQUEST',
    'GET_BRANDS_IMPORT_STATUS_SUCCESS',
    'GET_BRANDS_IMPORT_STATUS_ERROR',
    'CLEAR_BRANDS_IMPORT_STATUS',
]);

/**
 * Create 'Get Brands Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetBrandsRequestAction implements Action {
    type = ActionTypes.GET_BRANDS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Brands Success' Action
 * @param payload Contains object with brands
 */
export class GetBrandsSuccessAction implements Action {
    type = ActionTypes.GET_BRANDS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Brands Error' Action
 * @param payload Contains object with errors
 */
export class GetBrandsErrorAction implements Action {
    type = ActionTypes.GET_BRANDS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Brands Data' Action
 * @param payload Contains object with default state
 */
export class ClearBrandsDataAction implements Action {
    type = ActionTypes.CLEAR_BRANDS_DATA;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Brand Request' Action
 * @param payload Contains object with brand object
 */
export class CreateBrandRequestAction implements Action {
    type = ActionTypes.CREATE_BRAND_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Brand Success' Action
 * @param payload Contains object with newly created brand
 */
export class CreateBrandSuccessAction implements Action {
    type = ActionTypes.CREATE_BRAND_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Brand Error' Action
 * @param payload Contains object with errors
 */
export class CreateBrandErrorAction implements Action {
    type = ActionTypes.CREATE_BRAND_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Brand Request' Action
 * @param payload Contains object with list brand id
 */
export class GetBrandRequestAction implements Action {
    type = ActionTypes.GET_BRAND_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Brand Success' Action
 * @param payload Contains object with brand
 */
export class GetBrandSuccessAction implements Action {
    type = ActionTypes.GET_BRAND_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Brand Error' Action
 * @param payload Contains object with errors
 */
export class GetBrandErrorAction implements Action {
    type = ActionTypes.GET_BRAND_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Brand Data' Action
 * @param payload Contains object with default state
 */
export class ClearBrandDataAction implements Action {
    type = ActionTypes.CLEAR_BRAND_DATA;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Brand Request' Action
 * @param payload Contains object with brand data
 */
export class SaveBrandRequestAction implements Action {
    type = ActionTypes.SAVE_BRAND_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Brand Success' Action
 * @param payload Contains object with brand
 */
export class SaveBrandSuccessAction implements Action {
    type = ActionTypes.SAVE_BRAND_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Brand Error' Action
 * @param payload Contains object with errors
 */
export class SaveBrandErrorAction implements Action {
    type = ActionTypes.SAVE_BRAND_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Prepare Brands Import Request' Action
 * @param payload Contains object with brands file
 */
export class PrepareBrandsImportRequestAction implements Action {
    type = ActionTypes.PREPARE_BRANDS_IMPORT_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Prepare Brands Import Success' Action
 */
export class PrepareBrandsImportSuccessAction implements Action {
    type = ActionTypes.PREPARE_BRANDS_IMPORT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Prepare Brands Import Error' Action
 * @param payload Contains object with errors
 */
export class PrepareBrandsImportErrorAction implements Action {
    type = ActionTypes.PREPARE_BRANDS_IMPORT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Prepare Brands Import' Action
 */
export class ClearPrepareBrandsImportAction implements Action {
    type = ActionTypes.CLEAR_PREPARE_BRANDS_IMPORT;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Brands Import Status Request' Action
 */
export class GetBrandsImportStatusRequestAction implements Action {
    type = ActionTypes.GET_BRANDS_IMPORT_STATUS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Brands Import Status Success' Action
 */
export class GetBrandsImportStatusSuccessAction implements Action {
    type = ActionTypes.GET_BRANDS_IMPORT_STATUS_SUCCESS;

    constructor(public payload: BulkAction) {}
}

/**
 * Create 'Get Brands Import Status Error' Action
 * @param payload Contains object with errors
 */
export class GetBrandsImportStatusErrorAction implements Action {
    type = ActionTypes.GET_BRANDS_IMPORT_STATUS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Brands Import Status' Action
 */
export class ClearBrandsImportStatusAction implements Action {
    type = ActionTypes.CLEAR_BRANDS_IMPORT_STATUS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Brand Request' Action
 * @param brandId Contains brand id
 */
export class DeleteBrandRequestAction implements Action {
    type = ActionTypes.DELETE_BRAND_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Brand Success' Action
 */
export class DeleteBrandSuccessAction implements Action {
    type = ActionTypes.DELETE_BRAND_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Brand Error' Action
 * @param payload Contains object with errors
 */
export class DeleteBrandErrorAction implements Action {
    type = ActionTypes.DELETE_BRAND_ERROR;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetBrandsRequestAction
    | GetBrandsSuccessAction
    | GetBrandsErrorAction
    | ClearBrandsDataAction
    | CreateBrandRequestAction
    | CreateBrandSuccessAction
    | CreateBrandErrorAction
    | GetBrandRequestAction
    | GetBrandSuccessAction
    | GetBrandErrorAction
    | ClearBrandDataAction
    | SaveBrandRequestAction
    | SaveBrandSuccessAction
    | SaveBrandErrorAction
    | PrepareBrandsImportRequestAction
    | PrepareBrandsImportSuccessAction
    | PrepareBrandsImportErrorAction
    | ClearPrepareBrandsImportAction
    | GetBrandsImportStatusRequestAction
    | GetBrandsImportStatusSuccessAction
    | GetBrandsImportStatusErrorAction
    | ClearBrandsImportStatusAction
    | DeleteBrandRequestAction
    | DeleteBrandSuccessAction
    | DeleteBrandErrorAction;
