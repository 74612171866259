import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';

@Component({
    selector: 'consent-form',
    templateUrl: './consent-form.component.html',
    styleUrls: ['./consent-form.component.scss'],
})
export class ConsentFormComponent implements OnInit {
    @Input() parentFormChanges: boolean;
    @Input() consentParts;
    @Output() consentFormConfirmed: EventEmitter<boolean[]> = new EventEmitter();

    form;

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            consentCheck: this.buildConsentCheck(),
        });
        if (this.consentParts) {
            this.consentFormConfirmed.emit(this.consentParts.map((elem) => elem.selected));
            this.form.valueChanges.subscribe((value) => {
                this.consentFormConfirmed.emit(value.consentCheck);
            });
        }
    }

    get consentCheck(): FormArray {
        return this.form.get('consentCheck') as FormArray;
    }

    buildConsentCheck() {
        if (this.consentParts) {
            const arr = this.consentParts.map((button) => {
                return this.fb.control(button.selected);
            });
            return this.fb.array(arr);
        }
    }

    clickCheckbox(index) {
        this.consentParts[index].selected = !this.consentParts[index].selected;
    }
}
