import * as imagePlatformActions from '../actions/image-platform.actions';
import {
    ImagePlatformAvailableImages,
    ImagePlatformFilenameOptions,
    ImagePlatformFilenamePreview,
    ImagePlatformGenerateZip,
    ImagePlatformImagePreview,
    ImagePlatformValidateImageExistence,
    ImagePlatformZipStatus,
} from '../models';

export interface State {
    products: number[];
    imagePreview: ImagePlatformImagePreview;
    filenamePreview: ImagePlatformFilenamePreview;
    generateZip: ImagePlatformGenerateZip;
    zipStatus: ImagePlatformZipStatus;
    availableImages: ImagePlatformAvailableImages;
    validateImageExistence: ImagePlatformValidateImageExistence;
    filenameOptions: ImagePlatformFilenameOptions;
}

const initialState: State = {
    products: [],
    imagePreview: {
        data: {},
        errors: {},
        isFetching: false,
    },
    filenamePreview: {
        data: {},
        errors: {},
        isFetching: false,
    },
    generateZip: {
        data: {},
        errors: {},
        isFetching: false,
    },
    zipStatus: {
        data: {},
        errors: {},
        isFetching: false,
    },
    availableImages: {
        data: {},
        errors: {},
        isFetching: true,
    },
    validateImageExistence: {
        data: {},
        errors: {},
        isFetching: false,
    },
    filenameOptions: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state: State = initialState, action: imagePlatformActions.Actions): State {
    switch (action.type) {
        case imagePlatformActions.ActionTypes.SET_PRODUCTS: {
            return {
                ...state,
                products: action.payload,
            };
        }

        case imagePlatformActions.ActionTypes.GET_IMAGE_PREVIEW_REQUEST: {
            return {
                ...state,
                imagePreview: {
                    ...state.imagePreview,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_IMAGE_PREVIEW_SUCCESS: {
            return {
                ...state,
                imagePreview: {
                    ...state.imagePreview,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_IMAGE_PREVIEW_ERROR: {
            return {
                ...state,
                imagePreview: {
                    ...state.imagePreview,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_FILENAME_PREVIEW_REQUEST: {
            return {
                ...state,
                filenamePreview: {
                    ...state.filenamePreview,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_FILENAME_PREVIEW_SUCCESS: {
            return {
                ...state,
                filenamePreview: {
                    ...state.filenamePreview,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_FILENAME_PREVIEW_ERROR: {
            return {
                ...state,
                filenamePreview: {
                    ...state.filenamePreview,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GENERATE_ZIP_REQUEST: {
            return {
                ...state,
                generateZip: {
                    ...state.generateZip,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GENERATE_ZIP_SUCCESS: {
            return {
                ...state,
                generateZip: {
                    ...state.generateZip,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GENERATE_ZIP_ERROR: {
            return {
                ...state,
                generateZip: {
                    ...state.generateZip,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_ZIP_STATUS_REQUEST: {
            return {
                ...state,
                zipStatus: {
                    ...state.zipStatus,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_ZIP_STATUS_SUCCESS: {
            return {
                ...state,
                zipStatus: {
                    ...state.zipStatus,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_ZIP_STATUS_ERROR: {
            return {
                ...state,
                zipStatus: {
                    ...state.zipStatus,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_AVAILABLE_IMAGES_REQUEST: {
            return {
                ...state,
                availableImages: {
                    ...state.availableImages,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_AVAILABLE_IMAGES_SUCCESS: {
            return {
                ...state,
                availableImages: {
                    ...state.availableImages,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_AVAILABLE_IMAGES_ERROR: {
            return {
                ...state,
                availableImages: {
                    ...state.availableImages,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.VALIDATE_IMAGE_EXISTENCE_REQUEST: {
            return {
                ...state,
                validateImageExistence: {
                    ...state.validateImageExistence,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case imagePlatformActions.ActionTypes.VALIDATE_IMAGE_EXISTENCE_SUCCESS: {
            return {
                ...state,
                validateImageExistence: {
                    ...state.validateImageExistence,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.VALIDATE_IMAGE_EXISTENCE_ERROR: {
            return {
                ...state,
                validateImageExistence: {
                    ...state.validateImageExistence,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_FILENAME_OPTIONS_REQUEST: {
            return {
                ...state,
                filenameOptions: {
                    ...state.filenameOptions,
                    isFetching: true,
                },
            };
        }

        case imagePlatformActions.ActionTypes.GET_FILENAME_OPTIONS_SUCCESS: {
            return {
                ...state,
                filenameOptions: {
                    ...state.filenameOptions,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case imagePlatformActions.ActionTypes.CLEAR_PRODUCTS: {
            return {
                ...state,
                products: [],
            };
        }

        case imagePlatformActions.ActionTypes.CLEAR_IMAGE_PREVIEW: {
            return {
                ...state,
                imagePreview: {
                    ...initialState.imagePreview,
                },
            };
        }

        case imagePlatformActions.ActionTypes.CLEAR_FILENAME_PREVIEW: {
            return {
                ...state,
                filenamePreview: {
                    ...initialState.filenamePreview,
                },
            };
        }

        case imagePlatformActions.ActionTypes.CLEAR_GENERATE_ZIP: {
            return {
                ...state,
                generateZip: {
                    ...initialState.generateZip,
                },
            };
        }

        case imagePlatformActions.ActionTypes.CLEAR_ZIP_STATUS: {
            return {
                ...state,
                zipStatus: {
                    ...initialState.zipStatus,
                },
            };
        }

        case imagePlatformActions.ActionTypes.CLEAR_AVAILABLE_IMAGES: {
            return {
                ...state,
                availableImages: {
                    ...initialState.availableImages,
                },
            };
        }

        case imagePlatformActions.ActionTypes.CLEAR_FILENAME_OPTIONS: {
            return {
                ...state,
                filenameOptions: {
                    ...initialState.filenameOptions,
                },
            };
        }

        case imagePlatformActions.ActionTypes.CLEAR_VALIDATE_IMAGE_EXISTENCE: {
            return {
                ...state,
                validateImageExistence: {
                    ...initialState.validateImageExistence,
                },
            };
        }

        default:
            return state;
    }
}

export const getProducts = (state: State) => state.products;

export const getImagePreview = (state: State) => state.imagePreview;
export const getImagePreviewData = (state: State) => state.imagePreview.data;
export const getImagePreviewErrors = (state: State) => state.imagePreview.errors;
export const getImagePreviewIsFetching = (state: State) => state.imagePreview.isFetching;

export const getFilenamePreview = (state: State) => state.filenamePreview;
export const getFilenamePreviewData = (state: State) => state.filenamePreview.data;
export const getFilenamePreviewErrors = (state: State) => state.filenamePreview.errors;
export const getFilenamePreviewIsFetching = (state: State) => state.filenamePreview.isFetching;

export const getGenerateZip = (state: State) => state.generateZip;
export const getGenerateZipData = (state: State) => state.generateZip.data;
export const getGenerateZipErrors = (state: State) => state.generateZip.errors;
export const getGenerateZipIsFetching = (state: State) => state.generateZip.isFetching;

export const getZipStatus = (state: State) => state.zipStatus;
export const getZipStatusData = (state: State) => state.zipStatus.data;
export const getZipStatusErrors = (state: State) => state.zipStatus.errors;
export const getZipStatusIsFetching = (state: State) => state.zipStatus.isFetching;

export const getAvailableImages = (state: State) => state.availableImages;
export const getAvailableImagesData = (state: State) => state.availableImages.data;
export const getAvailableImagesErrors = (state: State) => state.availableImages.errors;
export const getAvailableImagesIsFetching = (state: State) => state.availableImages.isFetching;

export const getValidateImageExistence = (state: State) => state.validateImageExistence;
export const getValidateImageExistenceData = (state: State) => state.validateImageExistence.data;
export const getValidateImageExistenceErrors = (state: State) => state.validateImageExistence.errors;
export const getValidateImageExistenceIsFetching = (state: State) => state.validateImageExistence.isFetching;

export const getFilenameOptions = (state: State) => state.filenameOptions;
export const getFilenameOptionsData = (state: State) => state.filenameOptions.data;
export const getFilenameOptionsIsFetching = (state: State) => state.filenameOptions.isFetching;
