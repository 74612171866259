<div class="pagination">
  <div class="pagination__offset">
    Page:

    <button
      class="pagination__btn btn btn--primary"
      [ngClass]="{'btn--disabled': currentPage === 1 }"
      (click)="selectPage(currentPage - 1)">
      &lt;
    </button>

    <input class="pagination__input input input--inline input--center" type="number" [(ngModel)]="pageInput">

    <button
      class="pagination__btn btn btn--primary"
      [ngClass]="{'btn--disabled': currentPage === totalPages }"
      (click)="selectPage(currentPage + 1)">
      &gt;
    </button>

    of {{ totalPages }} | {{ count ? count : '0' }} records
  </div>

  <span class="pagination__loader" [ngClass]="{'pagination__loader--show': isFetching}"></span>

  <span class="pagination__flex"></span>

  <div class="pagination__limit">
    <ng-select
      class="pagination__select"
      [options]="availableLimits"
      placeholder="-"
      [(ngModel)]="limit"
      noFilter="true"
      (selected)="onLimitSelectChange($event)">
    </ng-select>
  </div>
</div>
