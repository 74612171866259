import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as reducer from '@core/reducers/company-whitelisted-ips.reducer';

@Injectable()
export class CompanyWhitelistedIpsSelectors {
    /**
     * Get state.companyWhitelistedIps
     */
    getCompanyWhitelistedIpsState() {
        return ($state: State): any => $state.companyWhitelistedIps;
    }

    /**
     * Get list from state.companyWhitelistedIps
     */
    getList() {
        return createSelector(
            this.getCompanyWhitelistedIpsState(),
            reducer.getList,
        );
    }

    /**
     * Get manage from state.companyWhitelistedIps
     */
    getManage() {
        return createSelector(
            this.getCompanyWhitelistedIpsState(),
            reducer.getManage,
        );
    }
}
