export const PUBLIC_URLS = {
    LOGIN: 'login/',
    RESET_PASSWORD: 'reset_password/',
    RESET_PASSWORD_CONFIRM: 'reset_password/confirm/',
    ACTIVATE: 'activate/',
    NOT_FOUND: '/not-found',
    MAINTENANCE_NOTIFICATIONS: 'maintenance_notifications/',
};

export const PROTECTED_URLS = {
    ME: 'me/',
    LOGOUT: 'logout/',
    PARTNER_DATA_REQUIREMENTS: 'partner_data_requirements/',
    PARTNER_DATA_REQUIREMENTS_CREATE: 'partner_data_requirements/create_matching_presets/',
    PARTNER_DATA_REQUIREMENTS_STATS: 'partner_data_requirements/stats/',
    PARTNER_DATA_REQUIREMENTS_GET_NOTIFICATIONS: 'partner_data_requirements/get_notifications/',
    PARTNER_DATA_REQUIREMENTS_CLEAR_NOTIFICATIONS: 'partner_data_requirements/clear_notifications/',
    PARTNER_OVERVIEWS: 'overviews/',
    COMPANIES: 'companies/',
    COMPANY_DATA_AVAILABLE_ATTRIBUTES: 'available_attributes/',
    COMPANY_DATA_BULK_TOGGLE: 'bulk_toggle/',
    COMPANY_DATA_BULK_DELETE: 'bulk_delete/',
    COMPANY_DATA_STATS: 'stats/',
    COMPANY_DATA_REQUIREMENT_HISTORY_LOGS: 'data_requirement_history_logs/',
    COMPANY_DOMAINS: 'company_domains/',
    COMPANY_INTERNAL_DATA_TEMPLATE_IMPORT: 'internal_data_template_import/',
    COMPANY_INTERNAL_DATA_AUTOPOPULATION_XLS: 'internal_data_for_autopopulation_xls_template/',
    COMPANY_SHARING_PRESETS_AVAILABLE_ATTRIBUTED: 'available_attributes/',
    COMPANY_SHARING_PRESETS_BULK_TOGGLE: 'bulk_toggle/',
    COMPANY_SHARING_PRESETS_BULK_DELETE: 'bulk_delete/',
    COMPANY_SHARING_PRESETS_STATS: 'stats/',
    COMPANY_SHARING_PRESETS_PARTNER_REQUIREMENTS: 'partner_requirements/',
    COMPANY_SHARING_PRESETS_GET_NOTIFICATIONS: 'get_notifications/',
    COMPANY_SHARING_PRESETS_CLEAR_NOTIFICATIONS: 'clear_notifications/',
    COMPANY_SHARING_PRESETS_HISTORY_LOGS: 'sharing_preset_history_logs/',
    COMPANY_PARTNERS_SHARED_PRODUCTS_RAPORT: 'shared_products_report/',
    METRIC_FAMILIES: 'metric_families/',
    NUTRIENTS: 'nutrients/',
    INGREDIENT_GROUPS: 'ingredient_groups/',
    INGREDIENTS: 'ingredients/',
    MEDIA_PROFILES: 'media_profiles/',
    PUBLISHING_CHANNELS_AVAILABLE_IMAGES: 'publishing_channels/available_images/',
    PUBLISHING_CHANNELS_PREVIEWS: 'publishing_channels/preview/',
    IMAGE_CONFIGURATIONS: 'image_configurations/',
    IMAGE_PLATFORM_PREVIEW_IMAGE: 'image_platform/preview_image/',
    IMAGE_PLATFORM_PREVIEW_FILENAME: 'image_platform/preview_filename/',
    IMAGE_PLATFORM_GENERATE: 'image_platform/generate/',
    IMAGE_PLATFORM_STATUS: 'image_platform/status/',
    IMAGE_PLATFORM_AVAILABLE_IMAGES: 'image_platform/available_images/',
    IMAGE_PLATFORM_VALIDATE_IMAGE_EXISTENCE: 'image_platform/validate_image_existence/',
    BRANDS: 'brands/',
    BRANDS_UPLOAD: 'brands/upload/',
    BRANDS_STATUS: 'brands/status/',
    ATTRIBUTE_GROUPS: 'attribute_groups/',
    ATTRIBUTE_GROUPS_SIMPLE: 'attribute_groups/simple/',
    ATTRIBUTE_GROUPS_SIMPLE_ATTRIBUTES: 'attribute_groups/simple_attributes/',
    ATTRIBUTES: 'attributes/',
    ATTRIBUTES_BULK_DELETE: 'attributes/bulk_delete/',
    ATTRIBUTES_IMPORT: 'attributes/import/',
    ATTRIBUTES_IMPORT_STATUS: 'attributes/import_status/',
    ATTRIBUTE_CERTIFICATES: 'attribute_certificates/',
    EXPORT_HISTORY_LOGS_PRODUCT: 'product_export_history_logs/',
    EXPORT_HISTORY_LOGS_IMAGE_PLATFORM: 'image_platform_export_history_logs/',
    EXPORT_HISTORY_LOGS_PRODUCT_DATA: 'product_data_export_history_logs/',
    EXPORT_HISTORY_LOGS_PRODUCT_SHARING_HISTORY: 'product_sharing_history_export_history_logs/',
    EXPORT_HISTORY_LOGS_PRODUCT_MEDIA: 'product_media_export_history_logs/',
    IMPORT_HISTORY_LOGS_PRODUCT: 'product_import_history_logs/',
    USER_LOGS: 'user_logs/',
    USERS: 'users/',
    PUBLIC_API_TOKEN: 'public_api_token/',
    EMAIL_VALIDITY_CHECK: 'email_validity_check/',
    USER_GROUPS: 'groups/',
    UTILS_COUNTRIES: 'countries/',
    WORKFLOW_HISTORY_LOGS: 'workflow_history_logs/',
    WORKFLOW_HISTORY_LOGS_PRODUCT_WORKFLOW_IDS: 'workflow_history_logs/product_workflow_ids/',
    WORKFLOW_CONFIGURATIONS: 'workflow_configurations/',
    NEWS: 'news/',
    ONBOARDING_USER_ONBOARDING_LOGS: 'user_onboarding_logs/',
    UNFREEZE_PRODUCT: 'products/unfreeze',
    MAINTENANCE_TASKS: 'maintenance_tasks/',
    SUBACCOUNTS_MANAGING: 'profile_manage/',
    CW_FILE_LINK: 'export_files_download/',
    CHANGE_PROFILE: 'profiles/',
    SET_UAE_ID: 'set_uae_id/',
};
