export type Method = 'DELETE' | 'GET' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT';

export type InputType =
    | 'boolean'
    | 'date'
    | 'file'
    | 'number'
    | 'select'
    | 'string'
    | 'telephone'
    | 'textarea'
    | 'multiple';

export interface PublicApiCredentials {
    key: string;
}

export interface Endpoint {
    request: {
        url: string;
        params: any;
    };
    response: {
        ok: boolean;
        status: string;
        headers: {
            'content-language': string[];
            'content-type': string[];
        };
        body: any;
    };
    isFetching?: boolean;
}

export interface PublicApiData {
    productsParams: Endpoint;
    getProductParam: Endpoint;
    productCategoriesParams: Endpoint;
}

export enum PublicApiParamPosition {
    LEFT,
    RIGHT,
    CENTER,
}

export interface PublicApiParam {
    description?: string;
    multiple?: boolean;
    dynamicOptions?: boolean;
    options?: any[];
    param: string;
    position?: PublicApiParamPosition;
    type?: InputType;
}

export interface PublicApiObject {
    [key: string]: PublicApiParam[];
}
