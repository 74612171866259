import { CompanyIpsActions } from '@core/actions/company-whitelisted-ips.actions';
import { CompanyWhitelistedIpManage, CompanyWhitelistedIpsList } from '@core/models';

export interface State {
    list: CompanyWhitelistedIpsList;
    manage: CompanyWhitelistedIpManage;
}

export const initialState: State = {
    list: {
        data: {
            count: null,
            limit: null,
            next: null,
            offset: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            company: null,
            id: null,
            ip: null,
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
};

export function reducer(state: State = initialState, action: CompanyIpsActions.Actions): State {
    switch (action.type) {
        case CompanyIpsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.GET_ENTITY_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.GET_ENTITY_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                    isSaving: false,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.GET_ENTITY_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.GET_LIST_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.GET_LIST_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.GET_LIST_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.NEW_ENTITY: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: false,
                    isSaving: false,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.CREATE_ENTITY_ERROR:
        case CompanyIpsActions.ActionTypes.DELETE_ENTITY_ERROR:
        case CompanyIpsActions.ActionTypes.SAVE_ENTITY_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.CREATE_ENTITY_REQUEST:
        case CompanyIpsActions.ActionTypes.DELETE_ENTITY_REQUEST:
        case CompanyIpsActions.ActionTypes.SAVE_ENTITY_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.CREATE_ENTITY_SUCCESS:
        case CompanyIpsActions.ActionTypes.SAVE_ENTITY_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case CompanyIpsActions.ActionTypes.DELETE_ENTITY_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getManage = (state: State) => state.manage;
