import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limitText',
})
export class LimitTextPipe implements PipeTransform {
    transform(text: string, limit: number = 50): string {
        return text.length > limit ? `${text.substr(0, limit + 1)}...` : text;
    }
}
