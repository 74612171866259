import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Notification, Options } from '../../../core/models';
import { NotificationsService } from '../../../core/services/notifications.service';

/**
 * Notifications based on https://github.com/flauc/angular2-notifications
 */
@Component({
    selector: 'notifications-container',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'notifications-container.component.html',
    styleUrls: ['notifications-container.component.scss'],
})
export class NotificationsContainerComponent implements OnInit, OnDestroy {
    @Input() set options(opt: Options) {
        this.attachChanges(opt);
    }

    @Output() onCreate = new EventEmitter();
    @Output() onDestroy = new EventEmitter();

    public notifications: Notification[] = [];
    public position: ['top' | 'bottom', 'right' | 'left'] = ['bottom', 'right'];

    private lastNotificationCreated: Notification;
    private changeEmitterSubscription: Subscription;

    // Received values
    private lastOnBottom: boolean = true;
    private maxStack: number = 8; // 0 for not stack limit
    private preventLastDuplicates: any = false;
    private preventDuplicates: boolean = false;

    // Sent values
    public timeOut: number = 5000; // 0 for constant display
    public clickToClose: boolean = true;
    public showProgressBar: boolean = true;
    public pauseOnHover: boolean = true;
    public rtl: boolean = false;
    public animate: 'fromRight' | 'fromLeft' | 'rotate' | 'scale' = 'fromRight';

    /**
     * Component constructor function
     */
    constructor(private notificationsService: NotificationsService) {}

    /**
     * Subscribe for notifications change emitter subscription and handle proper action for each new item
     */
    ngOnInit(): void {
        this.changeEmitterSubscription = this.notificationsService.getChangeEmitter().subscribe((item) => {
            switch (item.command) {
                case 'cleanAll':
                    this.notifications = [];
                    break;

                case 'clean':
                    this.cleanSingle(item.id!);
                    break;

                case 'set':
                    item.add ? this.add(item.notification!) : this.defaultBehavior(item);
                    break;

                default:
                    this.defaultBehavior(item);
                    break;
            }
        });
    }

    /**
     * Default behavior on event
     * @param value Event value
     */
    defaultBehavior(value: any): void {
        this.notifications.splice(this.notifications.indexOf(value.notification), 1);
        this.onDestroy.emit(this.buildEmit(value.notification, false));
    }

    /**
     * Add the new notification to the notification array
     * @param item Notification object
     */
    add(item: Notification): void {
        item.createdOn = new Date();

        let toBlock: boolean = false;

        // this.preventDuplicates and this.preventLastDuplicates are global options
        // item.preventLastDuplicates is local option assign per item
        // Global options has higher priority than local
        if (this.preventDuplicates || this.preventLastDuplicates || item.preventLastDuplicates) {
            toBlock = this.block(item);
        }

        // Save this as the last created notification
        this.lastNotificationCreated = item;

        if (!toBlock) {
            // Check if the notification should be added at the start or the end of the array
            if (this.lastOnBottom) {
                if (this.notifications.length >= this.maxStack) {
                    this.notifications.splice(0, 1);
                }
                this.notifications.push(item);
            } else {
                if (this.notifications.length >= this.maxStack) {
                    this.notifications.splice(this.notifications.length - 1, 1);
                }
                this.notifications.splice(0, 0, item);
            }

            this.onCreate.emit(this.buildEmit(item, true));
        }
    }

    /**
     * Check if notifications should be prevented
     * @param item Notification object
     * @returns Flag with information if notification should be blocked or not
     */
    block(item: Notification): boolean {
        const toCheck = item.html ? this.checkHtml : this.checkStandard;

        // Global option - if true prevents duplicates of open notifications.
        if (this.preventDuplicates && this.notifications.length > 0) {
            for (let i = 0; i < this.notifications.length; i++) {
                if (toCheck(this.notifications[i], item)) {
                    return true;
                }
            }
        }

        // Global option - if set to "all" prevents duplicates of the latest
        // notification shown ( even if it isn't on screen any more ).
        // If set to "visible" only prevents duplicates of the last created
        // notification if the notification is currently visible.
        // Local option - if set to true prevents duplicates of the last created
        // notification if the notification is currently visible.
        if (this.preventLastDuplicates || item.preventLastDuplicates) {
            let comp: Notification;

            if (
                (this.preventLastDuplicates === 'visible' || item.preventLastDuplicates === 'visible') &&
                this.notifications.length > 0
            ) {
                if (this.lastOnBottom) {
                    comp = this.notifications[this.notifications.length - 1];
                } else {
                    comp = this.notifications[0];
                }
            } else if (
                (this.preventLastDuplicates === 'all' || item.preventLastDuplicates === 'all') &&
                this.lastNotificationCreated
            ) {
                comp = this.lastNotificationCreated;
            } else {
                return false;
            }

            return toCheck(comp, item);
        }

        return false;
    }

    /**
     * Check standard notification
     * @param checker Compare notification object
     * @param item Current notification object
     * @returns Flag with information if objects are equal or not
     */
    checkStandard(checker: Notification, item: Notification): boolean {
        return checker.type === item.type && checker.title === item.title && checker.content === item.content;
    }

    /**
     * Check HTML notification
     * @param checker Compare notification object
     * @param item Current notification object
     * @returns Flag with information if objects are equal or not
     */
    checkHtml(checker: Notification, item: Notification): boolean {
        return checker.html
            ? checker.type === item.type &&
                  checker.title === item.title &&
                  checker.content === item.content &&
                  checker.html === item.html
            : false;
    }

    /**
     * Attach all the changes received in the options object
     * @param options Object with override options
     */
    attachChanges(options: any): void {
        Object.keys(options).forEach((a) => {
            if (this.hasOwnProperty(a)) {
                (this as any)[a] = options[a];
            }
        });
    }

    /**
     * Attach all the changes received in the options object
     * @param notification Notification object
     * @param to Flag with information if object was added or not
     */
    buildEmit(notification: Notification, to: boolean) {
        const toEmit: Notification = {
            createdOn: notification.createdOn,
            type: notification.type,
            id: notification.id,
        };

        if (notification.html) {
            toEmit.html = notification.html;
        } else {
            toEmit.title = notification.title;
            toEmit.content = notification.content;
        }

        if (!to) {
            toEmit.destroyedOn = new Date();
        }

        return toEmit;
    }

    /**
     * Clean single notification
     * @param id Id of notification that should be removed
     */
    cleanSingle(id: string): void {
        let indexOfDelete: number = 0;
        let doDelete: boolean = false;

        this.notifications.forEach((notification, idx) => {
            if (notification.id === id) {
                indexOfDelete = idx;
                doDelete = true;
            }
        });

        if (doDelete) {
            this.notifications.splice(indexOfDelete, 1);
        }
    }

    /**
     * Unsubscribe for notifications change emitter subscriptions
     */
    ngOnDestroy(): void {
        if (this.changeEmitterSubscription) {
            this.changeEmitterSubscription.unsubscribe();
        }
    }
}
