import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'circle-score',
    styleUrls: ['./circle-score.component.scss'],
    templateUrl: './circle-score.component.html',
})
export class CircleScoreComponent implements OnInit {
    @Input() value: number;
    @Input() color: string;
    @Input() size?: number = 70;
    @Input() stroke?: number = 3;

    public radius: number;
    public normalizedRadius: number;
    public circumference: number;

    ngOnInit() {
        this.radius = this.size / 2;
        this.normalizedRadius = this.radius - this.stroke;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

    get strokeDashoffset() {
        return this.circumference - (this.value / 100) * this.circumference;
    }
}
