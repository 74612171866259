import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import * as fromRoot from '@core/reducers';
import { ProductsSelectors } from '@core/selectors/products.selectors';
import { takeUntil } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { environment } from '@env/environment';
import { Subject } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'target-files-modal',
    styleUrls: ['./target-files-modal.component.scss'],
    templateUrl: './target-files-modal.component.html',
})
export class TargetFilesModalComponent implements OnDestroy, OnInit {
    @Input() text: string =
        'For the large amounts of files we recommend to send the download link to your e-mail address.';
    @Output() sendLink: EventEmitter<any> = new EventEmitter();
    @Output() downloadFiles: EventEmitter<any> = new EventEmitter();

    private ngDestroy: Subject<never> = new Subject();
    public exportIsFetching$ = this.store.select(this.productsSelectors.getExportIsFetching());
    public isFetching: boolean;
    public directDownload: boolean = environment.enabledDirectDownload;

    constructor(
        private store: Store<fromRoot.State>,
        private productsSelectors: ProductsSelectors,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.exportIsFetching$.pipe(takeUntil(this.ngDestroy)).subscribe((isFetching) => {
            this.isFetching = isFetching;
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    sendLinkClick() {
        this.sendLink.emit();
    }

    downloadFilesClick() {
        this.downloadFiles.emit();
    }
}
