<div
  class="modal"
  [ngClass]="rootClass"
  [ngClass]="{
    'modal--isAnimated': isAnimated
  }"
>
  <div class="modal__wrapper" (click)="onBackgroundClick($event)">
    <div
      #modalBody
      [ngClass]="bodyClass"
      [style.transform]="getModalPosition"
      [style.min-width]="minWidth"
      class="modal__body"
    >
      <span
        class="modal__indicator"
        [ngClass]="{
          'modal__indicator--top': indicatorSet === 'top',
          'modal__indicator--bottom': indicatorSet === 'bottom'
        }"
        [style.left.px]="indicatorLeft"
      ></span>
      <a *ngIf="showCloseButton" class="modal__close icon-cross" (click)="onCloseButtonClick()"></a>
      <div
        *ngIf="modalTitle || modalDescription"
        class="modal__header"
      >
        <h3
          *ngIf="modalTitle"
          class="modal__title"
        >
          {{ modalTitle }}
        </h3>
        <p
          *ngIf="modalDescription"
          class="modal__description"
        >
          {{ modalDescription }}
        </p>
      </div>

      <div class="modal__content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="modal__dim" [ngClass]="{'modal__dim--scrolling': isScrolling}">
    <div class="modal__dim-side modal__dim-side--top" [ngStyle]="{'height': (highlightTarget?.top + highlightTarget?.unit) }"></div>
    <div class="modal__dim-side modal__dim-side--right" [ngStyle]="{'left': (highlightTarget?.right + highlightTarget?.unit) }"></div>
    <div class="modal__dim-side modal__dim-side--bottom" [ngStyle]="{'top': (highlightTarget?.bottom + highlightTarget?.unit) }"></div>
    <div class="modal__dim-side modal__dim-side--left" [ngStyle]="{'width': (highlightTarget?.left + highlightTarget?.unit) }"></div>
  </div>
</div>
