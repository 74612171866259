import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { CountriesGetPayload } from '@core/models';
import * as utilsActions from '../actions/utils.actions';
import { UtilsService } from '../services/utils.service';

import { catchErrorHttpClientJson } from './catch-error';

@Injectable()
export class UtilsEffects {
    constructor(private actions$: Actions, private utilsService: UtilsService) {}

    @Effect()
    getCountries$: Observable<Action> = this.actions$.pipe(
        ofType(utilsActions.ActionTypes.GET_COUNTRIES_REQUEST),
        map((action: utilsActions.GetCountriesRequestAction) => action.payload),
        switchMap((payload: CountriesGetPayload) => {
            return this.utilsService.getCountries(payload).pipe(
                map((response) => new utilsActions.GetCountriesSuccessAction(response)),
                catchError((error) => of(new utilsActions.GetCountriesErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );
}
