import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { TokenDeactivatePayload, TokenGeneratePayload } from '@app/core/models';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class UsersService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Create user
     * @param user Object with user data needed to create user
     * @returns Backend API response
     */
    createUser(user: any): Observable<any> {
        return this.httpClient.post<any>(PROTECTED_URLS.USERS, user);
    }

    deactivateApiToken(payload: TokenDeactivatePayload): Observable<any> {
        return this.httpClient.delete<any>(`${PROTECTED_URLS.PUBLIC_API_TOKEN}${payload.key}/`);
    }

    generateApiToken(payload: TokenGeneratePayload): Observable<any> {
        return this.httpClient.post<any>(`${PROTECTED_URLS.PUBLIC_API_TOKEN}`, payload);
    }

    /**
     * Hard logout
     * @param id User ID
     * @returns Backend API response
     */
    hardLogout(id: number): Observable<any> {
        return this.httpClient.post<any>(`${PROTECTED_URLS.USERS}${id}/hard_logout/`, null);
    }

    /**
     * Get options users request
     * @returns Backend API response
     */
    getOptions(): Observable<any> {
        return this.httpClient.options<any>(PROTECTED_URLS.USERS);
    }

    /**
     * Get user details
     * @param id User ID
     * @returns Backend API response
     */
    getUser(id: string): Observable<any> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.USERS}${id}/`);
    }

    /**
     * Get users list
     * @param params Object with list params
     * @returns Backend API response
     */
    getUsers(params?: any): Observable<any> {
        return this.httpClient.get<any>(PROTECTED_URLS.USERS, { params });
    }

    /**
     * Resend registration e-mail
     * @param id User ID
     * @returns Backend API response
     */
    resendRegistrationEmail(id: number): Observable<any> {
        return this.httpClient.post<any>(`${PROTECTED_URLS.USERS}${id}/resend_token/`, null);
    }

    /**
     * Save user
     * @param user Object with user ID and user data needed to save user
     * @returns Backend API response
     */
    saveUser(user: any): Observable<any> {
        return this.httpClient.patch(`${PROTECTED_URLS.USERS}${user.id}/`, user.data);
    }

    /**
     * Validate user email request
     * @returns Backend API response
     */
    validateUserEmail(data: any): Observable<any> {
        return this.httpClient.post<any>(PROTECTED_URLS.EMAIL_VALIDITY_CHECK, data);
    }

    editConnectionBetweenUsers(data: any) {
        return this.httpClient.patch<any>('profile_manage/', data);
    }
}
