import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { CompanyIpsActions } from '@core/actions/company-whitelisted-ips.actions';

import { CompanyWhitelistedIpsService } from '@core/services/company-whitelisted-ips.service';
import { ModalService } from '@core/services/modal.service';
import { NotificationsService } from '@core/services/notifications.service';

import { catchErrorJson } from './catch-error';

import {
    CompanyWhitelistedIp,
    CompanyWhitelistedIpsListPayload,
    WhitelistedIpCreatePayload,
    WhitelistedIpDeletePayload,
    WhitelistedIpId,
    WhitelistedIpPostPayload,
} from '@core/models';
import { HTTPClientVer } from '@core/utils/request.utils';

@Injectable()
export class CompanyWhitelistedIpsEffects {
    constructor(
        private actions$: Actions,
        private activatedRoute: ActivatedRoute,
        private companyWhitelistedIpsService: CompanyWhitelistedIpsService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    @Effect()
    getListRequest$: Observable<Action> = this.actions$.pipe(
        ofType(CompanyIpsActions.ActionTypes.GET_LIST_REQUEST),
        map((action: CompanyIpsActions.GetListRequest) => action.payload),
        switchMap((payload: CompanyWhitelistedIpsListPayload) => {
            return this.companyWhitelistedIpsService.getCompanyWhitelistedIps(payload).pipe(
                map((response) => new CompanyIpsActions.GetListSuccess(response as any)),
                catchError((error) => of(new CompanyIpsActions.GetListError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    getEntityRequest$: Observable<Action> = this.actions$.pipe(
        ofType(CompanyIpsActions.ActionTypes.GET_ENTITY_REQUEST),
        map((action: CompanyIpsActions.GetEntityRequest) => action.payload),
        switchMap((payload: WhitelistedIpId) => {
            return this.companyWhitelistedIpsService.getCompanyWhitelistedIp(payload).pipe(
                map((response) => new CompanyIpsActions.GetEntitySuccess(response as any)),
                catchError((error) => of(new CompanyIpsActions.GetEntityError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    saveEntityRequest$: Observable<Action> = this.actions$.pipe(
        ofType(CompanyIpsActions.ActionTypes.SAVE_ENTITY_REQUEST),
        map((action: CompanyIpsActions.SaveEntityRequest) => action.payload),
        switchMap((payload: WhitelistedIpPostPayload) => {
            return this.companyWhitelistedIpsService.saveCompanyWhitelistedIp(payload).pipe(
                tap((response) => {
                    const json: CompanyWhitelistedIp = response as any;
                    this.notificationsService.success(`Saved whitelisted IP ${json.ip}`);
                }),
                map((response) => new CompanyIpsActions.SaveEntitySuccess(response as any)),
                catchError((error) => of(new CompanyIpsActions.SaveEntityError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    createEntityRequest$: Observable<Action> = this.actions$.pipe(
        ofType(CompanyIpsActions.ActionTypes.CREATE_ENTITY_REQUEST),
        map((action: CompanyIpsActions.CreateEntityRequest) => action.payload),
        switchMap((payload: WhitelistedIpCreatePayload) => {
            return this.companyWhitelistedIpsService.createCompanyWhitelistedIp(payload).pipe(
                tap((response) => {
                    const json: CompanyWhitelistedIp = response as any;
                    this.notificationsService.success(`Added IP ${json.ip}`);
                }),
                tap(() => {
                    if (payload.redirectTo) {
                        this.router.navigate(payload.redirectTo, { queryParams: payload.queryParams });
                    }
                }),
                map((response) => new CompanyIpsActions.CreateEntitySuccess(response as any)),
                catchError((error) =>
                    of(new CompanyIpsActions.CreateEntityError(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    deleteEntityRequest$: Observable<Action> = this.actions$.pipe(
        ofType(CompanyIpsActions.ActionTypes.DELETE_ENTITY_REQUEST),
        map((action: CompanyIpsActions.DeleteEntityRequest) => action.payload),
        switchMap((payload: WhitelistedIpDeletePayload) => {
            return this.companyWhitelistedIpsService.deleteCompanyWhitelistedIp(payload.id).pipe(
                tap(
                    () => {
                        if (payload.redirectTo) {
                            this.router.navigate(payload.redirectTo, { queryParams: payload.queryParams });
                        }
                    },
                    () => this.modalService.close('delete-company-whitelisted-ip-modal'),
                ),
                tap(() => this.notificationsService.success(`Deleted whitelisted IP`)),
                map(() => new CompanyIpsActions.DeleteEntitySuccess()),
                catchError((error) =>
                    of(new CompanyIpsActions.DeleteEntityError(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );
}
