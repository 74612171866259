import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {
    transform(startDate: string, endDate: string): any {
        if (!startDate || !endDate) {
            return '-';
        }
        const start: any = moment.utc(startDate);
        const end: any = moment.utc(endDate);
        const diff = end.diff(start);
        if (diff < 0) {
            return '-';
        }

        const duration = moment.duration(diff);
        const hours = duration.asHours();
        const minutes = Math.round((hours - Math.floor(hours)) * 60);
        const padMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const timeDiff = `${Math.floor(hours)}:${padMinutes}`;

        return timeDiff === '0:00' ? '<0:01' : timeDiff;
    }
}
