import { fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutocompleteInputComponent),
    multi: true,
};
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    selector: 'autocomplete-input',
    styleUrls: ['autocomplete-input.component.scss'],
    templateUrl: './autocomplete-input.component.html',
})
export class AutocompleteInputComponent implements AfterViewInit, ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();

    @Input() label: string = '';
    @Input() required: boolean = false;
    @Input() errors: any[] = [];
    @Input() minLength: number = 0;
    @Input() results: string[] = [];
    @Input() isFetching: boolean = false;
    @Input() isDirty: boolean = false;
    @Input() readonly: boolean = false;

    @Output() inputChange: EventEmitter<string> = new EventEmitter();
    @Output() close: EventEmitter<null> = new EventEmitter();

    @ViewChild('input', { static: true }) public inputElement: ElementRef;
    @ViewChild('resultsContainer', { static: true }) public resultsElement: ElementRef;

    private onChange = (_: any) => {};
    private onTouched = () => {};

    public inputValue: string = '';
    public showResults: boolean = false;
    public top: number = 0;
    public selectedInputIndex: number = 0;
    public canScrollDown: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        const inputKeys = ['Enter', 'ArrowDown', 'ArrowUp'];

        observableFromEvent(this.inputElement.nativeElement, 'keyup')
            .pipe(
                filter((event: any) => !inputKeys.includes(event.key)),
                map((event: any) => event.target.value),
                filter((value: string) => value.length >= this.minLength),
                debounceTime(150),
                distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((value: string) => {
                this.inputChange.emit(value);
            });

        observableFromEvent(this.inputElement.nativeElement, 'keydown')
            .pipe(
                filter((event: any) => inputKeys.includes(event.key) && this.showResults && !!this.results.length),
                tap((event: any) => event.preventDefault()),
                map((event: any) => event.key),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((key: string) => {
                switch (key) {
                    case inputKeys[0]: {
                        this.value = this.results[this.selectedInputIndex];
                        this.selectedInputIndex = 0;
                        this.showResults = false;
                        break;
                    }

                    case inputKeys[1]: {
                        this.selectedInputIndex =
                            this.selectedInputIndex === this.results.length - 1 ? 0 : this.selectedInputIndex + 1;
                        break;
                    }

                    case inputKeys[2]: {
                        this.selectedInputIndex = !this.selectedInputIndex
                            ? this.results.length - 1
                            : this.selectedInputIndex - 1;
                        break;
                    }
                }

                const resultsEl = this.resultsElement.nativeElement;
                const elementOffset = resultsEl.querySelectorAll('li')[this.selectedInputIndex].offsetTop;
                resultsEl.scrollTop = elementOffset;

                this.changeDetectorRef.detectChanges();
            });

        observableFromEvent(this.resultsElement.nativeElement, 'scroll')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event: any) => {
                const resultsEl = this.resultsElement.nativeElement;
                this.canScrollDown = resultsEl.offsetHeight + resultsEl.scrollTop <= resultsEl.scrollHeight;
                this.changeDetectorRef.detectChanges();
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.top = this.inputElement.nativeElement.offsetHeight;

            this.isScrollable();
        });
    }

    ngOnChanges(change): void {
        if (change.isFetching && !change.isFetching.currentValue && this.isDirty) {
            this.showResults = true;
            this.selectedInputIndex = 0;

            setTimeout(() => {
                this.isScrollable();
            });
        }
    }

    get value(): any {
        return this.inputValue;
    }

    set value(v: any) {
        if (v !== this.inputValue) {
            this.inputValue = v;
            this.onChange(this.inputValue);
        }
    }

    writeValue(value: any): void {
        if (value !== this.inputValue) {
            this.inputValue = value;
        }
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public onWindowClick(event: any): void {
        if (this.showResults) {
            this.inputChange.emit('');
            this.selectedInputIndex = 0;
        }

        this.showResults =
            event.target.parentElement &&
            event.target.parentElement.className === this.resultsElement.nativeElement.className;
    }

    public onResultClick(result: string): void {
        this.showResults = false;
        this.value = result;

        this.inputChange.emit('');
    }

    private isScrollable(): void {
        if (this.showResults) {
            const divHeight = this.resultsElement.nativeElement.offsetHeight;
            const listHeight = this.resultsElement.nativeElement.querySelector('ul').offsetHeight;

            this.canScrollDown = divHeight < listHeight;
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
