import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
    transform(value: string, format: string = 'HH:mm'): string {
        if (!value || value === '') {
            return '';
        }
        return moment(value).format(format);
    }
}
