import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';

import { AuthHttpClientService } from '@core/auth_httpclient/auth.service';
import { AuthSelectors } from '../selectors/auth.selectors';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {
    constructor(
        private authService: AuthHttpClientService,
        private authSelectors: AuthSelectors,
        private store: Store<fromRoot.State>,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let allow: boolean;
        this.store
            .select(this.authSelectors.isAuthenticated)
            .pipe(take(1))
            .subscribe((isAuthenticated) => (allow = isAuthenticated));

        if (!allow && !this.authService.token) {
            const params = state.url !== '/' ? { next: state.url } : {};
            this.router.navigate(['login/'], { queryParams: params });
            return false;
        }
        return true;
    }
}
