<svg
  [attr.height]="size"
  [attr.width]="size"
  [ngClass]="{
    'circle-score--red': color === 'red'
  }"
  class="circle-score"
>
  <circle
    [attr.stroke-width]="stroke"
    [attr.r]="normalizedRadius"
    [attr.cx]="radius"
    [attr.cy]="radius"
    class="circle-score__bg"
  />
  <circle
    [attr.stroke-dasharray]="circumference + ' ' + circumference"
    [attr.stroke-width]="stroke"
    [attr.r]="normalizedRadius"
    [attr.cx]="radius"
    [attr.cy]="radius"
    [style.stroke-dashoffset]="strokeDashoffset"
    [attr.transform]="'rotate(-90, ' + radius + ', ' + radius + ')'"
    class="circle-score__score"
  />
  <text
    x="50%"
    y="50%"
    dy="7"
    class="circle-score__value"
  >
    {{ value }}%
  </text>
</svg>
