import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Company Domains]';

export const ActionTypes = {
    GET_COMPANY_DOMAINS_REQUEST: type(`${ACTION_PREFIX} Get Company Domains Request`),
    GET_COMPANY_DOMAINS_SUCCESS: type(`${ACTION_PREFIX} Get Company Domains Success`),
    GET_COMPANY_DOMAINS_ERROR: type(`${ACTION_PREFIX} Get Company Domains Error`),
    GET_COMPANY_DOMAIN_REQUEST: type(`${ACTION_PREFIX} Get Company Domain Request`),
    GET_COMPANY_DOMAIN_SUCCESS: type(`${ACTION_PREFIX} Get Company Domain Success`),
    GET_COMPANY_DOMAIN_ERROR: type(`${ACTION_PREFIX} Get Company Domain Error`),
    NEW_COMPANY_DOMAIN: type(`${ACTION_PREFIX} New Company Domain`),
    CREATE_COMPANY_DOMAIN_REQUEST: type(`${ACTION_PREFIX} Create Company Domain Request`),
    CREATE_COMPANY_DOMAIN_SUCCESS: type(`${ACTION_PREFIX} Create Company Domain Success`),
    CREATE_COMPANY_DOMAIN_ERROR: type(`${ACTION_PREFIX} Create Company Domain Error`),
    SAVE_COMPANY_DOMAIN_REQUEST: type(`${ACTION_PREFIX} Save Company Domain Request`),
    SAVE_COMPANY_DOMAIN_SUCCESS: type(`${ACTION_PREFIX} Save Company Domain Success`),
    SAVE_COMPANY_DOMAIN_ERROR: type(`${ACTION_PREFIX} Save Company Domain Error`),
    DELETE_COMPANY_DOMAIN_REQUEST: type(`${ACTION_PREFIX} Delete Company Domain Request`),
    DELETE_COMPANY_DOMAIN_SUCCESS: type(`${ACTION_PREFIX} Delete Company Domain Success`),
    DELETE_COMPANY_DOMAIN_ERROR: type(`${ACTION_PREFIX} Delete Company Domain Error`),
    GET_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Options Request`),
    GET_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Options Success`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
    CLEAR_OPTIONS: type(`${ACTION_PREFIX} Clear Options`),
    ANY_ERRORS: type(`${ACTION_PREFIX} any errors`),
};

/**
 * Create 'Get Company Domains Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetCompanyDomainsRequestAction implements Action {
    type = ActionTypes.GET_COMPANY_DOMAINS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Domains Succes' Action
 * @param payload Contains object with company domains list and params
 */
export class GetCompanyDomainsSuccessAction implements Action {
    type = ActionTypes.GET_COMPANY_DOMAINS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Domains Error' Action
 * @param payload Contains object with errors
 */
export class GetCompanyDomainsErrorAction implements Action {
    type = ActionTypes.GET_COMPANY_DOMAINS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Domain Request' Action
 * @param payload Contains company domain id
 */
export class GetCompanyDomainRequestAction implements Action {
    type = ActionTypes.GET_COMPANY_DOMAIN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Domain Succes' Action
 * @param payload Contains object with company domain data
 */
export class GetCompanyDomainSuccessAction implements Action {
    type = ActionTypes.GET_COMPANY_DOMAIN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Domain Error' Action
 * @param payload Contains object with errors
 */
export class GetCompanyDomainErrorAction implements Action {
    type = ActionTypes.GET_COMPANY_DOMAIN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Company Domain' Action
 * @param payload Contains company domain object
 */
export class NewCompanyDomainAction implements Action {
    type = ActionTypes.NEW_COMPANY_DOMAIN;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Domain Request' Action
 * @param payload Contains object with company domain data
 */
export class CreateCompanyDomainRequestAction implements Action {
    type = ActionTypes.CREATE_COMPANY_DOMAIN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Domain Succes' Action
 * @param payload Contains object with company domains list and params
 */
export class CreateCompanyDomainSuccessAction implements Action {
    type = ActionTypes.CREATE_COMPANY_DOMAIN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Domains Error' Action
 * @param payload Contains object with errors
 */
export class CreateCompanyDomainErrorAction implements Action {
    type = ActionTypes.CREATE_COMPANY_DOMAIN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Domain Request' Action
 * @param payload Contains object with company domain data
 */
export class SaveCompanyDomainRequestAction implements Action {
    type = ActionTypes.SAVE_COMPANY_DOMAIN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Domain Succes' Action
 * @param payload Contains object with company domain data
 */
export class SaveCompanyDomainSuccessAction implements Action {
    type = ActionTypes.SAVE_COMPANY_DOMAIN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Domain Error' Action
 * @param payload Contains object with errors
 */
export class SaveCompanyDomainErrorAction implements Action {
    type = ActionTypes.SAVE_COMPANY_DOMAIN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Domain Request' Action
 * @param payload Contains company domain id
 */
export class DeleteCompanyDomainRequestAction implements Action {
    type = ActionTypes.DELETE_COMPANY_DOMAIN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Domain Succes' Action
 */
export class DeleteCompanyDomainSuccessAction implements Action {
    type = ActionTypes.DELETE_COMPANY_DOMAIN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Domain Error' Action
 * @param payload Contains object with errors
 */
export class DeleteCompanyDomainErrorAction implements Action {
    type = ActionTypes.DELETE_COMPANY_DOMAIN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' Action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' Action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetCompanyDomainsRequestAction
    | GetCompanyDomainsSuccessAction
    | GetCompanyDomainsErrorAction
    | GetCompanyDomainRequestAction
    | GetCompanyDomainSuccessAction
    | GetCompanyDomainErrorAction
    | NewCompanyDomainAction
    | CreateCompanyDomainRequestAction
    | CreateCompanyDomainSuccessAction
    | CreateCompanyDomainErrorAction
    | DeleteCompanyDomainRequestAction
    | DeleteCompanyDomainSuccessAction
    | DeleteCompanyDomainErrorAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | ClearListAction
    | ClearOptionsAction
    | ClearManageAction;
