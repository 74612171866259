import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromUserGroups from '../reducers/user-groups.reducer';

@Injectable()
export class UserGroupsSelectors {
    /**
     * Get state.userGroups
     */
    getUserGroupsState() {
        return ($state: State): any => $state.userGroups;
    }

    /**
     * Get list from state.userGroups
     */
    getList() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getList,
        );
    }

    /**
     * Get list.data from state.userGroups
     */
    getListData() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getListData,
        );
    }

    /**
     * Get list.errors from state.userGroups
     */
    getListErrors() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.userGroups
     */
    getListIsFetching() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getListIsFetching,
        );
    }

    /**
     * Get manage from state.userGroups
     */
    getManage() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getManage,
        );
    }

    /**
     * Get manage.data from state.userGroups
     */
    getManageData() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getManageData,
        );
    }

    /**
     * Get manage.errors from state.userGroups
     */
    getManageErrors() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getManageErrors,
        );
    }

    /**
     * Get manage.isFetching from state.userGroups
     */
    getManageIsFetching() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getManageIsFetching,
        );
    }

    /**
     * Get manage.isSaving from state.userGroups
     */
    getManageIsSaving() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getManageIsSaving,
        );
    }

    /**
     * Get options from state.userGroups
     */
    getOptions() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getOptions,
        );
    }

    /**
     * Get options.data from state.userGroups
     */
    getOptionsData() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getOptionsData,
        );
    }

    /**
     * Get options.isFetching from state.userGroups
     */
    getOptionsIsFetching() {
        return createSelector(
            this.getUserGroupsState(),
            fromUserGroups.getOptionsIsFetching,
        );
    }
}
