import { EMPTY as empty, forkJoin as observableForkJoin, from, Observable, of, timer as observableTimer } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, concatMap, delay, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import * as fromRoot from '@core/reducers';

import * as listedProducts from '@core/actions/listed-products.actions';
import * as products from '@core/actions/products.actions';

import { ProductsSelectors } from '@core/selectors/products.selectors';

import { ModalService } from '@core/services/modal.service';
import { NotificationsService } from '@core/services/notifications.service';
import { ProductsService } from '@core/services/products.service';
import { SectionGroupService } from '@core/services/section-group.service';

import { catchErrorHttpClientJson, catchErrorJson } from './catch-error';

import {

    GetProductsListRequestPayload,
    Product,
    ProductBarcode,
    ProductId,
    ProductsChangesHistoryRequestPayload,
    ProductsExportPayload,
} from '@core/models';

import { isEmptyObject } from '@app/utils';

import {
    improvePayloadAfterCorrectResponse,
    isPricePayloadContainsFiles,
    mapJsonFilesToFormData,
    UploadFtaFilesResponse,
} from '@products/components/components-manage-product/product-price-info/product-price.models';
import { extendProductDetails, extendProductListDetails, markDisableCountries } from "@products/utils";

interface MediaPayload {
    dateOfApproval: string;
    approvalNumber: number;
    image?: string;
    video?: string;
    file?: string;
}

@Injectable()
export class ProductsEffects {
    constructor(
        private actions$: Actions,
        private activatedRoute: ActivatedRoute,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private productsSelectors: ProductsSelectors,
        private productsService: ProductsService,
        private router: Router,
        private sectionGroupService: SectionGroupService,
        private store: Store<fromRoot.State>,
    ) {}

    private exportCeleryId: number;
    private bulkCloneCeleryId: number;

    private changeView(payload: any): void {
        if (typeof payload.nextTab !== 'undefined') {
            this.sectionGroupService.nextSection(payload.nextTab);
        } else if (typeof payload.prevTab !== 'undefined') {
            this.sectionGroupService.nextSection(payload.prevTab);
        } else if (typeof payload.switchTab !== 'undefined') {
            this.sectionGroupService.selectSection(payload.switchTabName || '', payload.switchTab);
        } else if (payload.data.status && payload.data.status === 'l' && !payload.upload.count) {
            // If product was set to live, redirect to products list
            const queryParams = this.activatedRoute.snapshot.queryParams || {};
            this.router.navigate(['/products'], { queryParams });
        }
    }

    @Effect()
    getProductsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(products.ActionTypes.GET_PRODUCTS_LIST_REQUEST),
        map((action: products.GetProductsListRequestAction) => action.payload),
        switchMap((payload: GetProductsListRequestPayload) => {
            return this.productsService.getProducts(payload).pipe(
                mergeMap(response => {
                    const actions: products.Actions[] = [
                        new products.GetProductsListSuccessAction(extendProductListDetails(response)),
                    ];
                    if (payload.body && payload.body.searchId) {
                        actions.push(new products.SetSearchParamsAction(response.searchParams || {}));
                    }
                    return from(actions);
                }),
                catchError(error => of(
                    new products.GetProductsListErrorAction(catchErrorHttpClientJson(error))
                ))
            );
        }),
    );

            // TODO: check this
    @Effect()
    getProductRequest$: Observable<Action> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_PRODUCT_REQUEST),
        map((action: products.GetProductRequestAction) => action.payload),
        switchMap((payload) => {
            return this.productsService.getProduct(payload).pipe(
                map(response => new products.GetProductSuccessAction(extendProductDetails(response))),
                catchError(error => of(new products.GetProductErrorAction(catchErrorHttpClientJson(error)))),);
        }),
    );

    @Effect()
    createProductRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.CREATE_PRODUCT_REQUEST),
        map((action: products.CreateProductRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.createProduct(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Created product ${response.tradeItemDescription}`);
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                mergeMap((response) =>
                    from([
                        new products.CreateProductSuccessAction(response),
                        new products.SetNewProductsIdAction([response.id]),
                    ]),
                ),
                catchError((error) => of(new products.CreateProductErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    saveProductRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SAVE_PRODUCT_REQUEST),
        map((action: products.SaveProductRequestAction) => action.payload),
        switchMap((payload) => {
            return this.productsService.saveProduct(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved product ${response.tradeItemDescription}`);
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                map((response) => new products.SaveProductSuccessAction(extendProductDetails(response))),
                catchError((error) => of(new products.SaveProductErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    bulkSaveProductsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.BULK_SAVE_PRODUCTS_REQUEST),
        map((action: products.BulkSaveProductsRequestAction) => action.payload),
        mergeMap((payload) => {
            return observableForkJoin(
                payload.map((product) => {
                    let product$: Observable<any>;

                    if (!isEmptyObject(product.upload)) {
                        const formData = Object.entries(product.upload).reduce((data, [inputPath, file]) => {
                            data.append(inputPath, file);

                            return data;
                        }, new FormData());

                        product$ = this.productsService
                            .uploadProductFiles({
                                id: product.id,
                                upload: {
                                    formData,
                                },
                            })
                            .pipe(
                                mergeMap((response) => {
                                    const responseJson = response as Product;
                                    const groups = Object.keys(product.upload)
                                        // extract group and attribute IDs
                                        .map((path) => path.split('.').splice(2, 2))
                                        // create object with uniq attribute in specific group
                                        .reduce((acc, [groupId, attrId]) => {
                                            acc[groupId] = acc[groupId] ? [...acc[groupId], attrId] : [attrId];

                                            return acc;
                                        }, {});

                                    const mediaGroups = Object.entries(groups).reduce(
                                        (acc, [groupId, attributes]: any) => {
                                            return {
                                                ...acc,
                                                [groupId]: attributes.reduce(
                                                    (groupData, attrId) => {
                                                        const keys = Object.keys(
                                                            Object.values(groupData[attrId].value)[0],
                                                        );
                                                        const type = keys.includes('image')
                                                            ? 'image'
                                                            : keys.includes('video')
                                                            ? 'video'
                                                            : 'file';

                                                        groupData[attrId] = {
                                                            value: {
                                                                ...groupData[attrId].value,
                                                                ...product.data.values.groupAttributeValues[groupId][
                                                                    attrId
                                                                ].value,
                                                                ...Object.entries(
                                                                    responseJson.values.groupAttributeValues[groupId][
                                                                        attrId
                                                                    ].value,
                                                                ).reduce(
                                                                    (
                                                                        valueAcc: any,
                                                                        [key, value]: [string, MediaPayload],
                                                                    ) => {
                                                                        return {
                                                                            ...valueAcc,
                                                                            [key]: {
                                                                                ...groupData[attrId].value[key],
                                                                                ...product.data.values
                                                                                    .groupAttributeValues[groupId][
                                                                                    attrId
                                                                                ].value[key],
                                                                                [type]: value[type],
                                                                            },
                                                                        };
                                                                    },
                                                                    {},
                                                                ),
                                                            },
                                                        };
                                                        return groupData;
                                                    },
                                                    { ...product.data.values.groupAttributeValues[groupId] },
                                                ),
                                            };
                                        },
                                        { ...product.data.values.groupAttributeValues },
                                    );

                                    return this.productsService.saveProduct({
                                        ...product,
                                        upload: {},
                                        data: {
                                            ...product.data,
                                            values: {
                                                ...product.data.values,
                                                groupAttributeValues: {
                                                    ...mediaGroups,
                                                },
                                            },
                                        },
                                    });
                                }),
                            );
                    } else {
                        product$ = this.productsService.saveProduct(product);
                    }

                    return product$.pipe(
                        map(() => ({ errors: null, productId: product.id })),
                        catchError((response) => of({ errors: response, productId: product.id })),
                    );
                }),
            ).pipe(
                tap((responses) => {
                    const savedProducts = responses.filter((response) => !response.errors).length;

                    if (savedProducts) {
                        this.notificationsService.success(`Products saved (${savedProducts}/${payload.length})`);
                    }
                }),
                map((responses) => new products.BulkSaveProductsSuccessAction(responses)),
                catchError((error) => of(new products.BulkSaveProductsErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    saveProductDetailsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SAVE_PRODUCT_DETAILS_REQUEST),
        map((action: products.SaveProductDetailsRequestAction) => action.payload),
        switchMap((payloadWithFtaUpload: any) => {
            if (isPricePayloadContainsFiles(payloadWithFtaUpload)) {
                const formData = mapJsonFilesToFormData(payloadWithFtaUpload);
                return this.productsService.uploadReceiptFiles(payloadWithFtaUpload.id, formData).pipe(
                    tap(() => this.notificationsService.success(`Receipts were uploaded`)),
                    mergeMap((resp) => {
                        return from([
                            new products.SaveProductDetailsRequestAction(
                                improvePayloadAfterCorrectResponse(payloadWithFtaUpload, resp),
                            ),
                            new products.UploadProductReceiptsSuccessAction(),
                        ]);
                    }),
                    catchError((error) => of(new products.UploadProductFilesErrorAction(catchErrorHttpClientJson(error)))),
                );
            }
            const payload = improvePayloadAfterCorrectResponse(payloadWithFtaUpload, {
                officialLetter: null,
                receipts: [],
            } as UploadFtaFilesResponse) as any;
            if (payload.upload.count) {
                // first upload files, then save the data
                return this.productsService.uploadProductFiles(payload).pipe(
                    tap(() => {
                        const count = payload.upload.count;
                        this.notificationsService.success(
                            `${count} file${count > 1 ? 's' : ''} of product ${
                                count > 1 ? 'have' : 'has'
                            } been uploaded`,
                        );
                    }),
                    mergeMap((response) => {
                        const responseJson = response as Product;
                        const groups = {
                            ...Object.keys(payload.upload.data)
                                // filter for media attribiutes keys
                                .filter((path) => path.includes('.'))
                                // extract group and attribute IDs
                                .map((path) => path.split('.').splice(2, 2))
                                // create object with uniq attribute in specific group
                                .reduce((acc, [group, attr]) => {
                                    acc[group] = acc[group] ? [...acc[group], attr] : [attr];
                                    return acc;
                                }, {}),
                        };

                        const mediaGroups = Object.entries(groups).reduce(
                            (acc, [groupId, attributes]: any[]) => {
                                return {
                                    ...acc,
                                    [groupId]: attributes.reduce(
                                        (groupData, attrId) => {
                                            const keys = Object.keys(Object.values(groupData[attrId].value)[0]);
                                            const type = keys.includes('image')
                                                ? 'image'
                                                : keys.includes('video')
                                                ? 'video'
                                                : 'file';

                                            groupData[attrId] = {
                                                value: {
                                                    ...groupData[attrId].value,
                                                    ...payload.data.values.groupAttributeValues[groupId][attrId].value,
                                                    ...Object.entries(
                                                        responseJson.values.groupAttributeValues[groupId][attrId].value,
                                                    ).reduce((valueAcc: any, [key, value]: [string, MediaPayload]) => {
                                                        return {
                                                            ...valueAcc,
                                                            [key]: {
                                                                ...groupData[attrId].value[key],
                                                                ...payload.data.values.groupAttributeValues[groupId][
                                                                    attrId
                                                                ].value[key],
                                                                [type]: value[type],
                                                            },
                                                        };
                                                    }, {}),
                                                },
                                            };
                                            return groupData;
                                        },
                                        { ...payload.data.values.groupAttributeValues[groupId] },
                                    ),
                                };
                            },
                            { ...payload.data.values.groupAttributeValues },
                        );

                        const freshResponse: Product = {
                            ...responseJson,
                            values: {
                                ...responseJson.values,
                                groupAttributeValues: {
                                    ...responseJson.values.groupAttributeValues,
                                    ...mediaGroups,
                                },
                            },
                        };

                        const freshPayload = {
                            ...payload,
                            upload: {
                                count: 0,
                            },
                            data: {
                                ...payload.data,
                                values: {
                                    ...payload.data.values,
                                    groupAttributeValues: {
                                        ...mediaGroups,
                                    },
                                },
                            },
                        };

                        return from([
                            new products.UploadProductFilesSuccessAction(freshResponse),
                            new products.SaveProductDetailsRequestAction(freshPayload),
                        ]);
                    }),
                    catchError((error) => of(new products.UploadProductFilesErrorAction(catchErrorHttpClientJson(error)))),
                );
            } else {
                return this.productsService.saveProduct({ data: payload.data, id: payload.id }).pipe(
                    tap((response) => {
                        this.notificationsService.success(`Saved product ${response.tradeItemDescription} details`);

                        if (!payload.upload.count && !payload.delete.count) {
                            // If there are files to upload / remove, move the tab, section
                            // or route change on the upload / remove success
                            this.changeView(payload);
                        }
                    }),
                    mergeMap((response) => {
                        const actions: products.Actions[] = [new products.SaveProductDetailsSuccessAction(extendProductDetails(response))];
                        if (payload.upload.count) {
                            actions.push(new products.UploadProductFilesRequestAction(payload));
                        }
                        if (payload.delete.count) {
                            actions.push(new products.DeleteProductFilesRequestAction(payload));
                        }
                        return from(actions);
                    }),
                    catchError((error) =>
                        of(new products.SaveProductDetailsErrorAction(catchErrorHttpClientJson(error))),
                    ),
                );
            }
        }),
    );

    @Effect()
    saveProductRelationsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SAVE_PRODUCT_RELATIONS_REQUEST),
        map((action: products.SaveProductRelationsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.saveProduct(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved product relations ${response.tradeItemDescription}`);
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                map((response) => new products.SaveProductRelationsSuccessAction(response)),
                catchError((error) =>
                    of(new products.SaveProductRelationsErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    saveProductInternalDataRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SAVE_PRODUCT_INTERNAL_DATA_REQUEST),
        map((action: products.SaveProductInternalDataRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.saveProduct(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved product internal data ${response.tradeItemDescription}`);
                }),
                mergeMap((response) => {
                    const actions: products.Actions[] = [new products.SaveProductInternalDataSuccessAction(response)];
                    if (payload.upload.count) {
                        actions.push(new products.UploadProductFilesRequestAction(payload));
                    }
                    return from(actions);
                }),
                catchError((error) =>
                    of(new products.SaveProductInternalDataErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    uploadProductFilesRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.UPLOAD_PRODUCT_FILES_REQUEST),
        map((action: products.UploadProductFilesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.uploadProductFiles(payload).pipe(
                tap((response) => {
                    const count = payload.upload.count;
                    this.notificationsService.success(
                        `${ count } file${ count > 1 ? 's' : '' } of product ${ count > 1 ? 'have' : 'has' } been uploaded`,
                    );
                    if (!payload.delete.count) {
                        this.changeView(payload);
                    }
                }),
                mergeMap((response) => {
                    const actions: products.Actions[] = [new products.UploadProductFilesSuccessAction(response)];
                    if (payload.delete.count) {
                        actions.push(new products.DeleteProductFilesRequestAction(payload));
                    }
                    return from(actions);
                }),
                catchError((error) => of(new products.UploadProductFilesErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    deleteProductFilesRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.DELETE_PRODUCT_FILES_REQUEST),
        map((action: products.DeleteProductFilesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.deleteProductFiles(payload).pipe(
                tap(() => {
                    const count = payload.delete.count;
                    this.notificationsService.success(
                        `${count} file${count > 1 ? 's' : ''} of product ${count > 1 ? 'have' : 'has'} been deleted`,
                    );
                    this.changeView(payload);
                }),
                map((response) => new products.DeleteProductFilesSuccessAction(response)),
                catchError((error) => of(new products.DeleteProductFilesErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    shareProductsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SHARE_PRODUCTS_REQUEST),
        map((action) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.shareProducts(payload).pipe(
                tap((response) => {
                    if (response.success) {
                        const msg = response.isFtaSubtype ? 'Product is now Under Process' : 'Products shared succesfully';
                        this.notificationsService.success(msg);
                    }
                    if (response.warnings) {
                        response.warnings.forEach((warning) => {
                            this.notificationsService.alert(warning);
                        });
                    }
                }),
                mergeMap((response) => {
                    const actions: products.Actions[] = [new products.ShareProductsSuccessAction(response)];
                    return from(actions);
                }),
                catchError((error) => of(new products.ShareProductsErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    cloneProduct$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.CLONE_PRODUCT_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.cloneProduct(payload.id).pipe(
                tap(() => {
                    this.notificationsService.success('Product was successfully cloned.');
                    if (payload.redirectTo) {
                        const queryParams = this.activatedRoute.snapshot.queryParams || {};
                        this.router.navigate([payload.redirectTo], { queryParams });
                    }
                }),
                mergeMap((response) => {
                    if (payload.view === 'list') {
                        return from([
                            new products.CloneProductSuccessAction({ view: 'list' }),
                            new products.SetNewProductsIdAction([response.id]),
                            new products.ClearBulkCloneStatusAction(),
                        ]);
                    }
                    if (payload.view === 'preview') {
                        return of(new products.CloneProductSuccessAction({ view: 'preview' }));
                    }
                    return empty;
                }),
                catchError((error) => {
                    return of(
                        new products.CloneProductErrorAction({
                            // TODO: this place can cause problems BUT I don't know how to check it
                            ...catchErrorHttpClientJson(error),
                            view: payload.view,
                        }),
                    );
                }),
            );
        }),
    );

    @Effect()
    softDeleteProductRequestAction$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SOFT_DELETE_PRODUCT_REQUEST),
        map((action: products.SoftDeleteProductRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.softDeleteProduct(payload.id).pipe(
                tap(() => this.notificationsService.success(`Deleted product`)),
                tap(() => this.modalService.close('soft-delete-product-modal')),
                tap(
                    () => {
                        if (payload.redirectTo) {
                            const queryParams = payload.queryParams || {};
                            this.router.navigate([payload.redirectTo], { queryParams });
                        }
                    },
                    () => {
                        this.modalService.close('soft-delete-product-modal');
                    },
                ),
                map((response) => new products.SoftDeleteProductSuccessAction(response)),
                catchError((error) => of(new products.SoftDeleteProductErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    hardDeleteProductRequestAction$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.HARD_DELETE_PRODUCT_REQUEST),
        map((action: products.HardDeleteProductRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.hardDeleteProduct(payload.id).pipe(
                tap(
                    (response) => {
                        this.notificationsService.success(`Deleted product`);
                        if (payload.redirectTo) {
                            const queryParams = payload.queryParams || {};
                            this.router.navigate([payload.redirectTo], { queryParams });
                        }
                    },
                    (error) => {
                        this.modalService.close('hard-delete-product-modal');
                    },
                ),
                map((response) => new products.HardDeleteProductSuccessAction(response)),
                catchError((error) => of(new products.HardDeleteProductErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    softDeleteProductsRequestAction$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SOFT_DELETE_PRODUCTS_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.softDeleteProducts(payload.ids).pipe(
                tap(
                    () => {
                        this.notificationsService.success(`Deleted products`);
                        this.modalService.close('soft-delete-products-modal');
                    },
                    () => {
                        this.modalService.close('soft-delete-products-modal');
                    },
                ),
                mergeMap(() =>
                    from([
                        new products.SoftDeleteProductsSuccessAction(),
                    ]),
                ),
                catchError((error) => of(new products.SoftDeleteProductsErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    hardDeleteProductsRequestAction$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.HARD_DELETE_PRODUCTS_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.hardDeleteProducts(payload.ids).pipe(
                tap(
                    () => {
                        this.notificationsService.success(`Deleted products`);
                        this.modalService.close('hard-delete-products-modal');
                    },
                    () => this.modalService.close('hard-delete-products-modal'),
                ),
                mergeMap(() =>
                    from([
                        new products.HardDeleteProductsSuccessAction(),
                    ]),
                ),
                catchError((error) => of(new products.HardDeleteProductsErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    recoverProductRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.RECOVER_PRODUCT_REQUEST),
        map((action: products.RecoverProductRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.recoverProduct(payload.id).pipe(
                tap((response) =>
                    this.notificationsService.success(`Recovered product ${response.tradeItemDescription}`),
                ),
                map((response) => new products.RecoverProductSuccessAction(response)),
                catchError((error) => of(new products.RecoverProductErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    recoverProductsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.RECOVER_PRODUCTS_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.recoverProducts(payload.ids).pipe(
                tap(() => this.notificationsService.success('Recovered products')),
                map(() => new products.RecoverProductsSuccessAction()),
                catchError((error) => of(new products.RecoverProductsErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    setProductsToLiveRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SET_PRODUCTS_TO_LIVE_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.setProductsToLive(payload.ids).pipe(
                concatMap((results) => {
                    const resultList = Object.values(results.errors);
                    const errorCount = resultList.filter((result) => Object.keys(result).length).length;
                    const actions: products.Actions[] = [];
                    if (errorCount === resultList.length) {
                        this.notificationsService.error('Could not set any product to live');
                        actions.push(new products.SetProductsToLiveErrorAction(results));
                    } else if (errorCount > 0) {
                        this.notificationsService.alert('Some products could not be set to live');
                        actions.push(new products.SetProductsToLivePartialSuccessAction(results));
                    } else {
                        this.notificationsService.success('Successfully set all products to live');
                        actions.push(new products.SetProductsToLiveSuccessAction(results));
                    }
                    return from([...actions]);
                }),
                catchError((error) => of(new products.SetProductsToLiveErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    discontinueProductRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.DISCONTINUE_PRODUCT_REQUEST),
        map((action: products.DiscontinueProductRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.discontinueProduct(payload.id).pipe(
                tap((response) =>
                    this.notificationsService.success(
                        `Set product ${response.tradeItemDescription} status to discontinued`,
                    ),
                ),
                map((response) => new products.DiscontinueProductSuccessAction(response)),
                catchError((error) => of(new products.DiscontinueProductErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    discontinueProductsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.DISCONTINUE_PRODUCTS_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.discontinueProducts(payload.ids).pipe(
                tap(() => this.notificationsService.success('Discontinued products')),
                mergeMap(() =>
                    from([
                        new products.DiscontinueProductsSuccessAction(),
                        new products.SetNewProductsIdAction(payload.ids),
                    ]),
                ),
                catchError((error) => of(new products.DiscontinueProductsErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    setNewProductsId$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.SET_NEW_PRODUCTS_ID),
        switchMap(() => {
            return observableTimer(6000).pipe(
                mergeMap(() => {
                    return of(new products.ClearNewProductsIdAction());
                }),
            );
        }),
    );

    @Effect()
    revertDiscontinueProductRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.REVERT_DISCONTINUE_PRODUCT_REQUEST),
        map((action: products.RevertDiscontinueProductRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.revertDiscontinueProduct(payload.id).pipe(
                tap((response) =>
                    this.notificationsService.success(
                        `Reverted product ${response.tradeItemDescription} from discontinued status`,
                    ),
                ),
                map((response) => new products.RevertDiscontinueProductSuccessAction(response)),
                catchError((error) =>
                    of(new products.RevertDiscontinueProductErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    revertDiscontinueProductsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.REVERT_DISCONTINUE_PRODUCTS_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.revertDiscontinueProducts(payload.ids).pipe(
                tap(() => this.notificationsService.success('Reverted products')),
                mergeMap(() =>
                    from([
                        new products.RevertDiscontinueProductsSuccessAction(),
                        new products.SetNewProductsIdAction(payload.ids),
                    ]),
                ),
                catchError((error) =>
                    of(new products.RevertDiscontinueProductsErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getChangesHistoryRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_CHANGES_HISTORY_REQUEST),
        map((action: products.GetChangesHistoryRequestAction) => action.payload),
        switchMap((payload: ProductsChangesHistoryRequestPayload) =>
            this.productsService.getChangesHistory(payload.id, payload.payload).pipe(
                map((response) => new products.GetChangesHistorySuccessAction(response)),
                catchError((error) => of(new products.GetChangesHistoryErrorAction(catchErrorHttpClientJson(error)))),
            ),
        ),
    );

    @Effect()
    getSharesHistoryRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_SHARES_HISTORY_REQUEST),
        map((action: products.GetSharesHistoryRequestAction) => action.payload),
        switchMap((payload: any) =>
            this.productsService.getSharesHistory(payload).pipe(
                map((response) => new products.GetSharesHistorySuccessAction(response)),
                catchError((error) => of(new products.GetSharesHistoryErrorAction(catchErrorHttpClientJson(error)))),
            ),
        ),
    );

    @Effect()
    prepareLocalImportRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.PREPARE_LOCAL_IMPORT_REQUEST),
        map((action: products.PrepareLocalImportRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.prepareLocalProductsImport(payload).pipe(
                tap(() => this.modalService.open('import-process-in-background-modal')),
                map((response) => new products.PrepareLocalImportSuccessAction(response)),
                catchError((error) => of(new products.PrepareLocalImportErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    private exportProductsWithModalName = (payload: any, modalName: string) =>
        this.productsService.prepareProductsExport(payload).pipe(
            tap((response) => {
                // `sendMail` equal false means that we want to download zip directly.
                // `sendMail` equal true means that we want to send an email with a link to zip.
                if (!payload.sendMail) {
                    this.exportCeleryId = response.id;
                    this.modalService.open('export-products-modal');
                } else {
                    this.modalService.close(this.modalService.openedModal().id);
                    this.notificationsService.success('E-mail with download link was sent to you.');
                }
            }),
            mergeMap((response) => {
                const actions: products.Actions[] = [new products.PrepareProductsExportSuccessAction(response)];
                if (!payload.sendMail) {
                    // Automatically dispatch `GetZipStatusRequestAction` only when user download zip directly
                    actions.push(new products.GetExportStatusRequestAction(response.id));
                }
                return from(actions);
            }),
            catchError((error) => of(new products.PrepareProductsExportErrorAction(catchErrorHttpClientJson(error)))),
        );

    @Effect()
    prepareProductsExportRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.PREPARE_PRODUCTS_EXPORT_REQUEST),
        map((action: products.PrepareProductsExportRequestAction) => action.payload),
        switchMap((payload: any) => this.exportProductsWithModalName(payload, 'export-products-modal')),
    );

    @Effect()
    prepareProductsExportSharingHistoryRequest$: any = this.actions$.pipe(
        ofType<any>(products.ActionTypes.PREPARE_PRODUCTS_SHARING_HISTORY_EXPORT_REQUEST),
        map((action: products.PrepareProductsExportSharingHistoryRequestAction) => action.payload),
        switchMap((payload: ProductsExportPayload) => {
            return this.productsService.prepareProductsSharingHistoryExport(payload).pipe(
                tap((response) => {
                    if(!payload.sendMail) {
                        this.exportCeleryId = response.id;
                        this.modalService.open('export-products-modal');
                    } else {
                        this.modalService.close('target-export-sharing-history-modal'),
                        this.notificationsService.success('E-mail with download link was sent to you.');
                    }
                }),
                mergeMap((response) => {
                    const actions: products.Actions[] = [
                        new products.PrepareProductsExportSharingHistorySuccessAction(response),
                    ];
                    if (!payload.sendMail) {
                        actions.push(new products.GetExportStatusRequestAction(response.id));
                    }
                    return from(actions)
                }),
                catchError((error) => of(new products.PrepareProductsExportSharingHistoryErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    prepareProductMediasExportRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.PREPARE_PRODUCT_MEDIAS_EXPORT_REQUEST),
        map((action: products.PrepareProductMediasExportRequestAction) => action.payload),
        switchMap((payload: ProductsExportPayload) => {
            return this.productsService.prepareProductMediasExport(payload).pipe(
                tap((response) => {
                    // `sendMail` equal false means that we want to download zip directly.
                    // `sendMail` equal true means that we want to send an email with a link to zip.
                    if (!payload.sendMail) {
                        this.exportCeleryId = response.id;
                        this.modalService.open('export-products-modal');
                    } else {
                        this.modalService.close('target-export-medias-modal');
                        this.notificationsService.success('E-mail with download link was sent to you.');
                    }
                }),
                mergeMap((response) => {
                    const actions: products.Actions[] = [
                        new products.PrepareProductMediasExportSuccessAction(response),
                    ];
                    if (!payload.sendMail) {
                        // Automatically dispatch `GetZipStatusRequestAction` only when user download zip directly
                        actions.push(new products.GetExportStatusRequestAction(response.id));
                    }
                    return from(actions);
                }),
                catchError((error) =>
                    of(new products.PrepareProductMediasExportErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    prepareProductsMediasExportRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.PREPARE_PRODUCTS_MEDIAS_EXPORT_REQUEST),
        map((action: products.PrepareProductsMediasExportRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.prepareProductsMediasExport(payload).pipe(
                tap((response) => {
                    // `sendMail` equal false means that we want to download zip directly.
                    // `sendMail` equal true means that we want to send an email with a link to zip.
                    if (!payload.sendMail) {
                        this.exportCeleryId = response.id;
                        this.modalService.open('export-products-modal');
                    } else {
                        this.modalService.close('target-export-medias-modal');
                        this.notificationsService.success('E-mail with download link was sent to you.');
                    }
                }),
                mergeMap((response) => {
                    const actions: products.Actions[] = [
                        new products.PrepareProductsMediasExportSuccessAction(response),
                    ];
                    if (!payload.sendMail) {
                        // Automatically dispatch `GetZipStatusRequestAction` only when user download zip directly
                        actions.push(new products.GetExportStatusRequestAction(response.id));
                    }
                    return from(actions);
                }),
                catchError((error) =>
                    of(new products.PrepareProductsMediasExportErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    prepareProductsRejectionReasonsExportRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.PREPARE_PRODUCTS_REJECTION_REASONS_EXPORT_REQUEST),
        map((action: products.PrepareProductsRejectionReasonsExportRequestAction) => action.payload),
        switchMap((payload: ProductsExportPayload) => {
            return this.productsService.prepareProductsRejectionReasonsExport(payload).pipe(
                tap((response) => {
                    // `sendMail` equal false means that we want to download zip directly.
                    // `sendMail` equal true means that we want to send an email with a link to zip.
                    if (!payload.sendMail) {
                        this.exportCeleryId = response.id;
                        this.modalService.open('export-products-modal');
                    } else {
                        this.modalService.close('target-export-rejection-reasons-modal');
                        this.notificationsService.success('E-mail with download link was sent to you.');
                    }
                }),
                mergeMap((response) => {
                    const actions: products.Actions[] = [
                        new products.PrepareProductsRejectionReasonsExportSuccessAction(),
                    ];
                    if (!payload.sendMail) {
                        // Automatically dispatch `GetZipStatusRequestAction` only when user download zip directly
                        actions.push(new products.GetExportStatusRequestAction(response.id));
                    }
                    return from(actions);
                }),
                catchError((error) =>
                    of(new products.PrepareProductsRejectionReasonsExportErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    exportStatusRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_EXPORT_STATUS_REQUEST),
        map((action: products.GetExportStatusRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.getExportStatus(payload).pipe(
                tap(
                    (response) => {
                        if (response.status === 'SUCCESS') {
                            this.modalService.close('export-products-modal');
                            this.notificationsService.success('Products export success');
                            window.location.href = response.info.url;
                        } else if (response.status === 'FAILURE') {
                            this.modalService.close('export-products-modal');
                            this.notificationsService.error('Products export fail. Please try again later.');
                        }
                    },
                    (error) => {
                        this.modalService.close('export-products-modal');
                    },
                ),
                map((response) => new products.GetExportStatusSuccessAction(response)),
                catchError((error) => of(new products.GetExportStatusErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    exportStatusSuccess$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_EXPORT_STATUS_SUCCESS),
        map((action: products.GetExportStatusSuccessAction) => action.payload),
        delay(1000),
        filter(() => this.modalService.isOpened('export-products-modal')),
        filter((payload) => payload.status === 'PENDING' || payload.status === 'IN_PROGRESS'),
        map((payload) => {
            return new products.GetExportStatusRequestAction(this.exportCeleryId);
        }),
    );

    @Effect()
    getOptionsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_OPTIONS_REQUEST || listedProducts.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap(() =>
            this.productsService
                .getOptions()
                .pipe(map((productOptions) => new products.GetOptionsSuccessAction(markDisableCountries(productOptions)))),
        ),
    );

    @Effect()
    getProductOptionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(products.ActionTypes.GET_PRODUCT_OPTIONS_REQUEST),
        switchMap((action: products.GetProductOptionsRequestAction) =>
            this.productsService
                .getProductOptions(action.payload)
                .pipe(map((productOptions) => new products.GetProductOptionsSuccessAction(markDisableCountries(productOptions)))),
        ),
    );

    @Effect()
    acknowledgeProductUpdate$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.ACKNOWLEDGE_PRODUCT_UPDATE_REQUEST),
        map((action: products.AcknowledgeProductUpdateRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.acknowledgeProductUpdate(payload.id).pipe(
                mergeMap(() => {
                    return from([
                        new products.AcknowledgeProductUpdateSuccessAction(),
                        new products.GetProductRequestAction({
                            id: payload.id,
                            params: payload.params,
                        }),
                    ]);
                }),
                catchError((error) =>
                    of(new products.AcknowledgeProductUpdateErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    acknowledgeProductsUpdate$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.ACKNOWLEDGE_PRODUCTS_UPDATE_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.acknowledgeProductsUpdate(payload).pipe(
                tap(() => this.notificationsService.success('Products updates acknowledged')),
                mergeMap(() =>
                    from([
                        new products.AcknowledgeProductsUpdateSuccessAction(),
                        new products.SetNewProductsIdAction(payload.ids),
                    ]),
                ),
                catchError((error) =>
                    of(new products.AcknowledgeProductsUpdateErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    bulkCloneProductsRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.BULK_CLONE_REQUEST),
        map((action: products.BulkCloneRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productsService.bulkCloneProducts(payload).pipe(
                tap((response) => {
                    this.bulkCloneCeleryId = response.id;
                    this.modalService.open('bulk-clone-modal');
                }),
                mergeMap((response) => {
                    return from([
                        new products.BulkCloneSuccessAction(response),
                        new products.GetBulkCloneStatusRequestAction(response.id),
                    ]);
                }),
                catchError((error) => of(new products.BulkCloneErrorAction(catchErrorHttpClientJson(error)))),
            );
        }),
    );

    @Effect()
    bulkCloneStatusRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_BULK_CLONE_STATUS_REQUEST),
        map(action => action.payload),
        switchMap((payload: any) => {
            return this.productsService.getBulkCloneProductsStatus(payload).pipe(
                tap(
                    (response) => {
                        if (response.status === 'SUCCESS') {
                            this.notificationsService.success('Bulk clone products success');
                        } else if (response.status === 'FAILURE') {
                            this.modalService.close('bulk-clone-modal');
                            this.notificationsService.error('Bulk clone products. Please try again later.');
                        }
                    },
                    () => this.modalService.close('bulk-clone-modal'),
                ),
                mergeMap((response: any) => {
                    const actions: products.Actions[] = [new products.GetBulkCloneStatusSuccessAction(response)];

                    if (response.status === 'SUCCESS') {
                        actions.push(new products.SetNewProductsIdAction(response.info.clonedProducts));
                        this.modalService.close('bulk-clone-modal');
                    }

                    return from(actions);
                }),
                catchError((error) => {
                    return from([
                        of(new products.GetBulkCloneStatusErrorAction(catchErrorHttpClientJson(error))),
                        new products.ClearBulkCloneStatusAction(),
                    ]);
                }),
            );
        }),
    );

    @Effect()
    bulkCloneStatusSuccess$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_BULK_CLONE_STATUS_SUCCESS),
        map((action: products.GetBulkCloneStatusSuccessAction) => action.payload),
        delay(1000),
        filter(() => this.modalService.isOpened('bulk-clone-modal')),
        filter((payload) => payload.status === 'PENDING' || payload.status === 'IN_PROGRESS'),
        map(() => new products.GetBulkCloneStatusRequestAction(this.bulkCloneCeleryId)),
    );

    @Effect()
    getProductScoresRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_PRODUCT_SCORES_REQUEST),
        map((action: products.GetProductScoresRequestAction) => action.payload),
        switchMap((payload: ProductId) =>
            this.productsService.getProductScores(payload).pipe(
                map((scores) => new products.GetProductScoresSuccessAction(scores)),
                catchError((error) => of(new products.GetProductScoresErrorAction(catchErrorHttpClientJson(error)))),
            ),
        ),
    );

    @Effect()
    getProductReadinessReportRequest$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_READINESS_REPORT_REQUEST),
        map((action: products.GetReadinessReportRequestAction) => action.payload),
        switchMap((payload) =>
            this.productsService.getReadinessReport(payload).pipe(
                map((response) => new products.GetReadinessReportSuccessAction(response)),
                catchError((error) => of(new products.GetReadinessReportErrorAction(catchErrorHttpClientJson(error)))),
            ),
        ),
    );

    @Effect()
    downloadProductsBarcodeImagesRequest$: any = this.actions$.pipe(
        ofType<any>(products.ActionTypes.DOWNLOAD_BARCODE_IMAGES_REQUEST),
        map((action: products.DownloadBarcodeImagesRequestAction) => action.payload),
        switchMap((payload: ProductsExportPayload) => {
            return this.productsService.downloadBarcodeImages(payload).pipe(
                tap((response) => {
                    if (!payload.sendMail) {
                        this.exportCeleryId = response.id;
                        this.modalService.open('export-products-modal');
                    } else {
                        this.modalService.close('download-barcode-images-modal'),
                        this.notificationsService.success('E-mail with download link was sent to you.');
                    }
                }),
                mergeMap((response) => {
                    const actions: products.Actions[] = [
                        new products.DownloadBarcodeImagesSuccessAction(response),
                    ];
                    if (!payload.sendMail) {
                        actions.push(new products.GetExportStatusRequestAction(response.id));
                    }
                    return from(actions);
                }),
                catchError((error) => of(new products.DownloadBarcodeImagesErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getProductBarcodeImageRequest: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_BARCODE_REQUEST),
        map((action: products.GetBarcodeRequestAction) => action.payload),
        switchMap((payload: ProductBarcode) => {
            return this.productsService.getBarcodeService(payload).pipe(
                tap(() => this.modalService.close('download-barcode-image-modal')),
                map((response) => new  products.GetBarcodeSuccessAction(response)),
                catchError((error) =>
                    of(new products.GetBarcodeErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getProductAttributesDiffRequest$: Observable<Action> = this.actions$.pipe(
        ofType(products.ActionTypes.GET_PRODUCT_ATTRIBUTES_DIFF_REQUEST),
        map((action: products.GetProductAttributesDiffRequestAction) => action.payload),
        switchMap((payload) => {
          return this.productsService.getProductAttributesDiff(payload).pipe(
            map(response => new products.GetProductAttributesDiffSuccessAction(response)),
            catchError(error => of(new products.GetProductAttributesDiffErrorAction(catchErrorHttpClientJson(error)))),);
        }),
    );
}
