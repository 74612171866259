import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

declare const CKEDITOR: any;

@Directive({
    selector: '[charsCounter]',
})
export class CharsCounterDirective implements OnInit, OnDestroy {
    @Input() charsCounter: number;
    @Output() charsValid: EventEmitter<boolean> = new EventEmitter();

    private counterElement: HTMLElement;
    private ckeInstance: any;
    private interval: any;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        // If chars counter is not set, don't add counter
        if (!this.charsCounter) {
            return;
        }

        if (this.el.nativeElement.tagName === 'INPUT' || this.el.nativeElement.tagName === 'TEXTAREA') {
            // For Input or Textarea listen on keydown event on native element
            this.calculateLeftChars = this.calculateLeftChars.bind(this);
            this.el.nativeElement.addEventListener('keyup', this.calculateLeftChars);

            this.createHtml();
            this.calculateLeftChars();
        } else if (this.el.nativeElement.tagName === 'CKEDITOR') {
            // For CKEditor wait for iframe and iframeDocument, than listen on keydown on iframeDocument body
            this.interval = setInterval(() => {
                const iframe = this.el.nativeElement.querySelector('iframe');
                const iframeDocument = iframe
                    ? iframe.contentDocument || (iframe.contentWindow && iframe.contentWindow.document)
                    : null;
                const iframeBody = iframeDocument ? iframeDocument.querySelector('body') : null;

                if (iframe && iframeDocument && iframeBody) {
                    clearInterval(this.interval);

                    this.ckeInstance = CKEDITOR.instances[iframe.title.split(', ')[1]];

                    this.ckeInstance.on('key', () => {
                        setTimeout(this.calculateLeftChars.bind(this), 250);
                    });

                    this.createHtml();
                    this.calculateLeftChars();
                }
            }, 250);
        } else {
            console.error('Unsupported type of element. You can use chars counter on input, textarea or CKEditor.');
        }
    }

    createHtml(): void {
        const div = document.createElement('div');
        div.className = 'chars-counter';

        this.el.nativeElement.parentNode.appendChild(div);

        this.counterElement = div;
    }

    calculateLeftChars(): void {
        let leftChars: number;
        let currentLength: number;

        if (this.el.nativeElement.tagName === 'INPUT' || this.el.nativeElement.tagName === 'TEXTAREA') {
            currentLength = this.el.nativeElement.value.length;
        } else if (this.el.nativeElement.tagName === 'CKEDITOR') {
            // Whole logic for counting CKEditor text based on
            // https://github.com/w8tcha/CKEditor-WordCount-Plugin/blob/master/wordcount/plugin.js
            let normalizedText = this.ckeInstance.getData();
            normalizedText = normalizedText.replace(/(\r\n|\n|\r)/gm, '').replace(/&nbsp;/gi, ' ');
            normalizedText = this.strip(normalizedText).replace(/^([\t\r\n]*)$/, '');
            currentLength = normalizedText.length;
        }

        leftChars = this.charsCounter - currentLength;

        if (leftChars < 0) {
            this.counterElement.classList.add('chars-counter--invalid');
            this.charsValid.emit(false);
        } else {
            this.counterElement.classList.remove('chars-counter--invalid');
            this.charsValid.emit(true);
        }

        this.counterElement.innerText = leftChars.toString();
    }

    strip(html) {
        const tmp = document.createElement('div');

        // Add filter before strip
        html = this.filter(html);

        tmp.innerHTML = html;

        if (tmp.textContent === '' && typeof tmp.innerText === 'undefined') {
            return '';
        }

        return tmp.textContent || tmp.innerText;
    }

    filter(html) {
        const fragment = CKEDITOR.htmlParser.fragment.fromHtml(html);
        const writer = new CKEDITOR.htmlParser.basicWriter();

        fragment.writeHtml(writer);

        return writer.getHtml();
    }

    /**
     * Remove event listener for input and textarea. CKEditor handles that by itself.
     */
    ngOnDestroy(): void {
        if (!this.el.nativeElement) {
            return;
        }

        clearInterval(this.interval);

        if (this.el.nativeElement.tagName === 'INPUT' || this.el.nativeElement.tagName === 'TEXTAREA') {
            this.el.nativeElement.removeEventListener('keyup', this.calculateLeftChars);
        }
    }
}
