import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as authNgrxActions from '@core/actions/auth.actions';
import { fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Store as NgrxStore } from '@ngrx/store';

import { PROTECTED_URLS, PUBLIC_URLS } from '../../../urls.conf';

@Injectable()
export class AuthHttpClientService {
    private KEY_NAME = 'token';
    private store = localStorage;

    constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        protected ngrxStore: NgrxStore,
        ) {
        // TODO: this code broken cypress regression tests, will be fixed https://brandsync-uae.atlassian.net/browse/BRAN-2128
        // fromEvent(window, 'storage').subscribe((event: StorageEvent)  => {
        //     /**
        //      * After changing the token on an other tab in the same browser we have to "reload" page:
        //      *      * go to login page if token is null - logout action on some tab
        //      *      * go to product catalog - someone chane the profile
        //      */
        //     if (event.key === this.KEY_NAME) {
        //         if (event.newValue === null) {
        //             console.log('Redirecting to login page after removing token');
        //             this.router.navigate(['/login']);
        //         } else {
        //             console.log('Refreshing User data after changing profile');
        //             this.ngrxStore.dispatch(new authNgrxActions.GetLocalUserRequestAction());
        //             this.router.navigate(['/']);
        //         }
        //     }
        // });
    }

    get token(): string | null {
        return this.store.getItem(this.KEY_NAME);
    }

    login(formData) {
        let body;
        if (formData.code) {
            body = {code: formData.code };
        } else {
            body = {
                email: formData.email,
                password: formData.password,
            };
        }
        return this.httpClient
            .post<any>(PUBLIC_URLS.LOGIN, body)
            .pipe(
                tap((response) => {
                    this.saveToken(response.token);
                }),
            );
    }

    logout() {
        return this.httpClient.post<any>(PROTECTED_URLS.LOGOUT, {}).pipe(
            tap(() => {
                this.deleteToken();
            }),
        );
    }

    getLocalUser() {
        return this.httpClient.get<any>(PROTECTED_URLS.ME, {});
    }

    resetPassword(email: string) {
        return this.httpClient.post<any>(PUBLIC_URLS.RESET_PASSWORD, { email });
    }

    changeProfile(id: string) {
        return this.httpClient.post<any>(PROTECTED_URLS.CHANGE_PROFILE, { id });
    }

    newPassword(credentials: any) {
        return this.httpClient.post<any>(PUBLIC_URLS.RESET_PASSWORD_CONFIRM, credentials);
    }

    activateAccount(credentials: any) {
        return this.httpClient.post<any>(PUBLIC_URLS.ACTIVATE, credentials);
    }

    saveToken(token: string) {
        this.store.setItem(this.KEY_NAME, token);
    }

    deleteToken() {
        this.store.removeItem(this.KEY_NAME);
    }

    setItem(key: string, value: string) {
        this.store.setItem(key, value);
    }
    popItem(key: string) {
        const value = this.store.getItem(key);
        this.store.removeItem(key);
        return value;
    }
    getItem(key: string) {
        return this.store.getItem(key);
    }

    /**
     * Authorisation for SSO SAML
     */
    authSSOifPossible() {
        if (this.token) {
            window.location.href = '/sso_login' + window.location.search + `&token=${this.token}`;
        }
    }

    authSSOwithSAMLRequest(samlRequest) {
        window.location.href = `/sso_login${ samlRequest }&token=${ this.token }`;
    }
}
