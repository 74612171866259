import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'filename-creator',
    templateUrl: './filename-creator.component.html',
    styleUrls: ['./filename-creator.component.scss'],
})
export class FilenameCreatorComponent implements OnInit, OnDestroy {
    @Input() defaultTags: string[];
    @Input() availableTags: any[] = [];
    @Input() errors: any;
    @Output() filenameChange: EventEmitter<any> = new EventEmitter();

    private inputElement: HTMLElement;

    selectedTags: any[] = [];
    input: string = '';

    /**
     * Component constructor function
     */
    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.inputElement = this.el.nativeElement.querySelector('input');

        this.availableTags = this.availableTags.map((availableTag) => {
            return {
                displayName: availableTag,
                value: `{${availableTag}}`,
                isPredefined: true,
            };
        });

        this.defaultTags.forEach((defaultTag, index) => {
            const tag = {
                displayName: defaultTag,
                value: `{${defaultTag}}`,
                isPredefined: true,
            };

            this.addTag(tag, true);
        });

        this.el.nativeElement.addEventListener('click', (event) => this.focusInput(event));
    }

    /**
     * Focus input if user click directly on selected tags list
     *
     * @param event Click event object
     *
     */
    focusInput(event: any): void {
        const shouldFocus = event.target.className.indexOf('filename-creator__selected-tags') > -1;

        if (shouldFocus) {
            this.inputElement.focus();
        }
    }

    /**
     * Add tag, remove input value and emit change event
     *
     * @param tag Object that contains tag displayName, value and isPredefined flag
     * @param blockEmit Should block emit event
     */
    addTag(tag: any, blockEmit?: boolean): void {
        if (tag.isPredefined) {
            this.selectedTags.length
                ? this.selectedTags.push({ displayName: '_', value: '_', isPredefined: false })
                : null;
            this.availableTags = this.availableTags.filter((availableTag) => {
                return availableTag.value !== tag.value;
            });
        } else {
            this.input = '';
        }

        this.selectedTags.push(tag);

        if (!blockEmit) {
            this.filenameChange.emit(this.prepareOutput());
        }
    }

    /**
     * Remove tag emit change event
     *
     * @param tag Object that contains tag displayName, value and isPredefined flag
     * @param isPredefined Is predefined value
     */
    removeTag(index: number): void {
        if (this.selectedTags[index].isPredefined) {
            this.availableTags.push(this.selectedTags[index]);
        }

        this.selectedTags.splice(index, 1);
        this.filenameChange.emit(this.prepareOutput());
    }

    /**
     * Prepare value for output event
     *
     * @returns String with output value
     */
    prepareOutput(): string {
        return this.selectedTags.reduce((acc, selectedTag) => {
            return acc + selectedTag.value;
        }, '');
    }

    ngOnDestroy() {
        this.el.nativeElement.removeEventListener('click', (event) => this.focusInput(event));
    }
}
