import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Product Categories]';

export const ActionTypes = {
    GET_PRODUCT_CATEGORIES_REQUEST: type(`${ACTION_PREFIX} Get Product Categories Request`),
    GET_PRODUCT_CATEGORIES_SUCCESS: type(`${ACTION_PREFIX} Get Product Categories Success`),
    GET_PRODUCT_CATEGORIES_ERROR: type(`${ACTION_PREFIX} Get Product Categories Error`),
    FIND_PRODUCT_CATEGORIES_REQUEST: type(`${ACTION_PREFIX} Find Product Categories Request`),
    FIND_PRODUCT_CATEGORIES_SUCCESS: type(`${ACTION_PREFIX} Find Product Categories Success`),
    FIND_PRODUCT_CATEGORIES_ERROR: type(`${ACTION_PREFIX} Find Product Categories Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_FILTERED: type(`${ACTION_PREFIX} Clear Filtered`),
};

/**
 * Create 'Get Product Categories Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetProductCategoriesRequestAction implements Action {
    type = ActionTypes.GET_PRODUCT_CATEGORIES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Categories Succes' Action
 * @param payload Contains object with product categories list and params
 */
export class GetProductCategoriesSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Categories Error' Action
 * @param payload Contains object with errors
 */
export class GetProductCategoriesErrorAction implements Action {
    type = ActionTypes.GET_PRODUCT_CATEGORIES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Find Product Categories Request' Action
 * @param payload Contains object with list params (name and limit)
 */
export class FindProductCategoriesRequestAction implements Action {
    type = ActionTypes.FIND_PRODUCT_CATEGORIES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Find Product Categories Succes' Action
 * @param payload Contains object with product categories list and params
 */
export class FindProductCategoriesSuccessAction implements Action {
    type = ActionTypes.FIND_PRODUCT_CATEGORIES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Find Product Categories Error' Action
 * @param payload Contains object with errors
 */
export class FindProductCategoriesErrorAction implements Action {
    type = ActionTypes.FIND_PRODUCT_CATEGORIES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Filtered' Action
 */
export class ClearFilteredAction implements Action {
    type = ActionTypes.CLEAR_FILTERED;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetProductCategoriesRequestAction
    | GetProductCategoriesSuccessAction
    | GetProductCategoriesErrorAction
    | FindProductCategoriesRequestAction
    | FindProductCategoriesSuccessAction
    | FindProductCategoriesErrorAction
    | ClearListAction
    | ClearFilteredAction;
