import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    ExistingProvider,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Option, OptionValue } from './option';
import { OptionList, OptionListValue } from './option-list';
import { SelectDropdownComponent } from './select-dropdown.component';

import { fromEvent, Observable, Subject, Subscription } from 'rxjs';

import { SelectOption, SelectValue } from '@core/models';

import {
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgPlaceholderTemplateDirective,
} from './select-templates.directive';

export const SELECT_VALUE_ACCESSOR: ExistingProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectComponent),
    multi: true,
};

@Component({
    encapsulation: ViewEncapsulation.None,
    providers: [SELECT_VALUE_ACCESSOR],
    selector: 'ng-select',
    styleUrls: ['./select.component.scss'],
    templateUrl: './select.component.html',
})
export class SelectComponent implements AfterViewInit, ControlValueAccessor, OnChanges, OnInit, OnDestroy {
    @Input() options: SelectOption[];

    @Input() allowClear: boolean = false;
    @Input() disabled: boolean = false;
    @Input() readonly: boolean = false;
    @Input() highlightColor: string;
    @Input() highlightTextColor: string;
    @Input() multiple: boolean = false;
    @Input() noFilter: number = 0;
    @Input() filterFromStart: boolean = false;
    @Input() notFoundMsg: string = 'No results found';
    @Input() placeholder: string = 'Select';
    @Input() selectError: boolean = false;
    @Input() dynamicOptions: boolean = false;
    @Input() disableSelecting: boolean = false;
    @Input() classFluid: boolean = false;
    @Input() defaultMsg: string = 'Enter at least 3 characters to start the search';
    @Input() termLimit: number = 3;
    @Input() addon: 'left' | 'right';
    @Input() canLoadMore: boolean = false;
    @Input() keepCurrentSelected: boolean = false;
    @Input() distinctUntilchanged: boolean = true;

    @Output() opened: EventEmitter<null> = new EventEmitter<null>();
    @Output() closed: EventEmitter<null> = new EventEmitter<null>();
    @Output() selected: EventEmitter<any> = new EventEmitter<any>();
    @Output() deselected: EventEmitter<any> = new EventEmitter<any>();
    @Output() noOptionsFound: EventEmitter<null> = new EventEmitter<null>();
    @Output() getOptions: EventEmitter<any> = new EventEmitter<any>();
    @Output() loadMore: EventEmitter<any> = new EventEmitter<any>();
    @Output() optionListChange: EventEmitter<any> = new EventEmitter<any>();

    // TODO: check https://angular.io/guide/static-query-migration
    @ContentChild(NgOptionTemplateDirective, { read: TemplateRef }) optionTemplate: TemplateRef<any>;
    @ContentChild(NgLabelTemplateDirective, { read: TemplateRef }) labelTemplate: TemplateRef<any>;
    @ContentChild(NgPlaceholderTemplateDirective, { read: TemplateRef }) placeholderTemplate: TemplateRef<any>;

    @ViewChild('selection', { static: true }) selectionSpan: any;
    @ViewChild('dropdown') dropdown: SelectDropdownComponent;
    @ViewChild('filterInput') filterInput: ElementRef;

    private _value: OptionListValue = [];
    optionList: OptionList;
    public currentSelectedOption: Option | Option[];

    // Selection state variables.
    hasSelected: boolean = false;

    // View state variables.
    filterEnabled: boolean = true;
    filterInputWidth: number = 1;
    hasFocus: boolean = false;
    showAbove: boolean = false;
    isDisabled: boolean = false;
    isReadonly: boolean = false;
    isOpen: boolean = false;
    isFetching: boolean = false;
    placeholderView: string = '';

    selectContainerClicked: boolean = false;
    windowClick: Subscription;
    public ngDestroy: Subject<never> = new Subject();

    // Width and position for the dropdown container.
    width: number;
    top: number;
    left: number;

    // Changes from inputs (single and multiple)
    inputChange: Subject<any> = new Subject<any>();
    inputPristine: boolean = true;

    private onChange = (_: any) => {};
    private onTouched = () => {};

    constructor(private changeDetector: ChangeDetectorRef) {
        this.inputChange
            .pipe(
                debounceTime(300), // wait 300ms after the last event before emitting last event
                distinctUntilChanged((prev, next) => this.distinctUntilchanged ? prev === next : false),
            )
            .subscribe((term) => {
                if (term.length === 0 || term.length >= this.termLimit) {
                    this.getOptions.emit(term);
                    this.inputPristine = false;
                    this.isFetching = true;
                }
            });
    }

    /** Event handlers. **/

    // Angular lifecycle hooks.

    ngOnInit(): void {
        this.placeholderView = this.placeholder;
        this.isReadonly = this.readonly;
        this.updateFilterWidth();
    }

    ngAfterViewInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options) {
            const { previousValue, currentValue } = changes.options;

            if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
                this.updateOptionsList(changes.options.isFirstChange());
            }
        }

        if (changes.noFilter) {
            const numOptions: number = this.optionList.options.length;
            const minNumOptions: number = changes.noFilter.currentValue;
            this.filterEnabled = numOptions >= minNumOptions;
        }

        this.isReadonly = this.readonly;
        this.isFetching = false;
    }

    ngOnDestroy() {
        this.inputChange.unsubscribe();
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    // Window.

    onWindowResize() {
        this.updateWidth();
    }

    // Select container.

    onSelectContainerClick(event: any) {
        this.selectContainerClicked = true;
        this.toggleDropdown();
    }

    onSelectContainerFocus() {
        // this.onTouched();
    }

    onSelectContainerKeydown(event: any) {
        this.handleSelectContainerKeydown(event);
    }

    // Dropdown container.

    onDropdownOptionClicked(option: Option) {
        this.multiple ? this.toggleSelectOption(option) : this.selectOption(option);
    }

    onDropdownClose(focus: any) {
        this.closeDropdown(focus);
    }

    // Single filter input.

    onSingleFilterClick() {
        this.selectContainerClicked = true;
    }

    onSingleFilterInput(term: string) {
        if (this.dynamicOptions) {
            this.inputChange.next(term);
            return;
        }

        const toEmpty: boolean = this.optionList.filter(term, this.filterFromStart);
        if (toEmpty) {
            this.noOptionsFound.emit(null);
        }
    }

    onSingleFilterKeydown(event: any) {
        this.handleSingleFilterKeydown(event);
    }

    // Multiple filter input.

    onMultipleFilterInput(event: any) {
        this.updateFilterWidth();

        if (!this.isOpen) {
            this.openDropdown();
        }

        if (this.dynamicOptions) {
            this.inputChange.next(event.target.value);
            return;
        }

        const toEmpty: boolean = this.optionList.filter(event.target.value, this.filterFromStart);
        if (toEmpty) {
            this.noOptionsFound.emit(null);
        }
    }

    onMultipleFilterKeydown(event: any) {
        this.handleMultipleFilterKeydown(event);
    }

    // Single clear select.

    onClearSelectionClick(event: any) {
        event.stopPropagation();
        this.clearSelection();
        this.closeDropdown(true);
    }

    // Multiple deselect option.

    onDeselectOptionClick(option: Option, event: any) {
        event.stopPropagation();
        this.deselectOption(option);
    }

    get searchInputValue() {
        return this.filterInput ? this.filterInput.nativeElement.value : '';
    }

    /** API. **/

    // TODO fix issues with global click/key handler that closes the dropdown.
    open() {
        this.openDropdown();
    }

    close() {
        this.closeDropdown();
    }

    clear() {
        this.clearSelection();
    }

    select(value: OptionValue) {
        this.optionList.getOptionsByValue(value).forEach((option) => {
            this.selectOption(option);
        });
    }

    /** ControlValueAccessor interface methods. **/

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: (_: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    /** Value. **/

    get value(): SelectValue {
        let v = null;

        if (this.multiple) {
            v = this._value;
        } else if (typeof this._value[0] !== 'undefined') {
            v = this._value[0];
        }

        return v;
    }

    set value(v: SelectValue) {
        if (typeof v === 'undefined' || v === null || v === '') {
            v = [];
        } else if (typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean') {
            v = [v];
        } else if (!Array.isArray(v)) {
            throw new TypeError('The value must be a string, number, boolean or an array.');
        }

        if (!OptionList.equalValues(v, this._value) || this.dynamicOptions) {
            this.optionList.value = v;
            this.valueChanged();
        }
    }

    private valueChanged() {
        this._value = this.optionList.value;

        this.hasSelected = this._value.length > 0;
        this.placeholderView = this.hasSelected ? '' : this.placeholder;
        this.updateFilterWidth();

        this._rewriteCurrentSelected();

        this.onChange(this.value);
        this.onTouched();
    }

    private _rewriteCurrentSelected() {
        if (this.keepCurrentSelected) {
            const filtered = this.optionList.options?.filter(option => option.selected);
            this.currentSelectedOption = this.multiple ? filtered : filtered[0];
        }
    }

    /** Initialization. **/

    private updateOptionsList(firstTime: boolean) {
        const optionsArray = [];

        this.options.forEach((parentOption) => {
            optionsArray.push({
                ...parentOption,
                childrenIds: parentOption.children ? parentOption.children.map((child) => child.value) : [],
            });

            if (!parentOption.children) {
                return;
            }

            parentOption.children.forEach((childOption) => {
                optionsArray.push({
                    ...childOption,
                    isChild: true,
                });
            });
        });

        this.optionList = new OptionList(optionsArray);
        this.optionListChange.emit(optionsArray);
    }

    /** Dropdown. **/

    private toggleDropdown() {
        if (!this.isDisabled && !this.isReadonly) {
            this.isOpen ? this.closeDropdown(true) : this.openDropdown();
        }
    }

    private openDropdown() {
        if (!this.isOpen) {
            this.updateWidth();
            this.checkSpace();
            this.isOpen = true;
            if (this.multiple && this.filterEnabled) {
                this.filterInput.nativeElement.focus();
            }
            this.windowClick = fromEvent(window, 'click')
                .pipe(
                    takeUntil(this.ngDestroy),
                )
                .subscribe((event) => {
                    if (!this.selectContainerClicked) {
                        this.closeDropdown();
                    }
                    this.selectContainerClicked = false;
                }
            );
            this.opened.emit(null);
        }
    }

    private closeDropdown(focus: boolean = false) {
        if (this.isOpen) {
            if (this.windowClick) {
                this.windowClick.unsubscribe();
            }
            this.clearFilterInput();
            this.isOpen = false;
            if (focus) {
                this.focus();
            }
            this.closed.emit(null);
            this.onTouched();
            this.changeDetector.markForCheck();
        }
    }

    /** Select. **/

    private selectOption(option: Option) {
        if (!option.selected) {
            if (!this.disableSelecting) {
                this.optionList.select(option, this.multiple);
                this.valueChanged();
            }
            this.selected.emit(option.undecoratedCopy());
        }
    }

    private deselectOption(option: Option) {
        if (option.selected) {
            this.optionList.deselect(option);
            this.valueChanged();
            this.deselected.emit(option.undecoratedCopy());
            setTimeout(() => {
                if (this.multiple) {
                    this.optionList.highlight();
                    if (this.isOpen) {
                        this.dropdown.moveHighlightedIntoView();
                    }
                }
            });
        }
    }

    private clearSelection() {
        const selection: Option[] = this.optionList.selection;
        if (selection.length > 0) {
            this.optionList.clearSelection();
            this.valueChanged();

            if (selection.length === 1) {
                this.deselected.emit(selection[0].undecoratedCopy());
            } else {
                this.deselected.emit(
                    selection.map((option) => {
                        return option.undecoratedCopy();
                    }),
                );
            }
        }
    }

    private toggleSelectOption(option: Option) {
        option.selected ? this.deselectOption(option) : this.selectOption(option);
    }

    private selectHighlightedOption() {
        const option: Option = this.optionList.highlightedOption;
        if (option !== null && option.disabled == false) {
            this.selectOption(option);
            this.closeDropdown(true);
        }
    }

    private deselectLast() {
        const sel: Option[] = this.optionList.selection;

        if (sel.length > 0) {
            const option: Option = sel[sel.length - 1];
            this.deselectOption(option);
            this.setMultipleFilterInput(option.displayName + ' ');
        }
    }

    /** Filter. **/

    private clearFilterInput() {
        if (this.multiple && this.filterEnabled) {
            this.filterInput.nativeElement.value = '';
        } else {
            this.dropdown.clearFilterInput();
        }

        if (!this.options.length) {
            this.inputPristine = true;
        }
    }

    private setMultipleFilterInput(value: string) {
        if (this.filterEnabled) {
            this.filterInput.nativeElement.value = value;
        }
    }

    /** Keys. **/

    private KEYS: any = {
        BACKSPACE: 8,
        TAB: 9,
        ENTER: 13,
        ESC: 27,
        SPACE: 32,
        UP: 38,
        DOWN: 40,
    };

    private handleSelectContainerKeydown(event: any) {
        const key = event.which;

        if (this.isOpen) {
            if (key === this.KEYS.ESC || (key === this.KEYS.UP && event.altKey)) {
                this.closeDropdown(true);
            } else if (key === this.KEYS.TAB) {
                this.closeDropdown();
            } else if (key === this.KEYS.ENTER) {
                this.selectHighlightedOption();
            } else if (key === this.KEYS.UP) {
                this.optionList.highlightPreviousOption();
                this.dropdown.moveHighlightedIntoView();
                if (!this.filterEnabled) {
                    event.preventDefault();
                }
            } else if (key === this.KEYS.DOWN) {
                this.optionList.highlightNextOption();
                this.dropdown.moveHighlightedIntoView();
                if (!this.filterEnabled) {
                    event.preventDefault();
                }
            }
        } else {
            if (key === this.KEYS.ENTER || key === this.KEYS.SPACE || (key === this.KEYS.DOWN && event.altKey)) {
                /* FIREFOX HACK:
                 *
                 * The setTimeout is added to prevent the enter keydown event
                 * to be triggered for the filter input field, which causes
                 * the dropdown to be closed again.
                 */
                setTimeout(() => {
                    this.openDropdown();
                });
            }
        }
    }

    private handleMultipleFilterKeydown(event: any) {
        const key = event.which;

        if (key === this.KEYS.BACKSPACE) {
            if (this.hasSelected && this.filterEnabled && this.filterInput.nativeElement.value === '') {
                this.deselectLast();
            }
        }
    }

    private handleSingleFilterKeydown(event: any) {
        const key = event.which;

        if (
            key === this.KEYS.ESC ||
            key === this.KEYS.TAB ||
            key === this.KEYS.UP ||
            key === this.KEYS.DOWN ||
            key === this.KEYS.ENTER
        ) {
            this.handleSelectContainerKeydown(event);
        }
    }

    /** View. **/

    focus() {
        this.hasFocus = true;
        if (this.multiple && this.filterEnabled) {
            this.filterInput.nativeElement.focus();
        } else {
            this.selectionSpan.nativeElement.focus();
        }
    }

    blur() {
        this.hasFocus = false;
        this.selectionSpan.nativeElement.blur();
    }

    updateWidth() {
        this.width = this.selectionSpan.nativeElement.offsetWidth;
    }

    updatePosition() {
        const e = this.selectionSpan.nativeElement;
        this.left = e.offsetLeft;
        this.top = e.offsetTop + e.offsetHeight;
    }

    checkSpace() {
        const doc = document.documentElement;
        const span = this.selectionSpan.nativeElement;
        const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

        const spanBottom = this.getAbsoluteOffsetTop(span) + span.offsetHeight;
        const windowBottom = scrollTop + window.innerHeight;

        this.showAbove = windowBottom - spanBottom < 252;

        this.top = this.showAbove ? -span.offsetHeight : 0;
    }

    getAbsoluteOffsetTop(element: any): number {
        let scroll = element.offsetTop;
        let lastElement = element;
        while (lastElement.offsetParent && lastElement.offsetParent.nodeName !== 'BODY') {
            lastElement = lastElement.offsetParent;
            scroll += lastElement.offsetTop;
        }

        return scroll;
    }

    updateFilterWidth() {
        if (typeof this.filterInput !== 'undefined') {
            const value: string = this.filterInput.nativeElement.value;
            this.filterInputWidth = value.length === 0 ? 1 + this.placeholderView.length * 10 : 1 + value.length * 10;
        }
    }
}
