<div
  class="message"
  [ngClass]="{
    'message--positive': type === 'positive',
    'message--negative': type === 'negative',
    'message--warning': type === 'warning',
    'message--info': type === 'info',
    'message--primary': type === 'primary',
    'message--disabled': type === 'disabled',
    'message--small': size === 'small'
  }">

  <div
    class="message__content-container"
    [ngClass]="{'message__content-container--back': iconBack}">
    <span
      *ngIf="icon && !iconBack"
      class="message__icon-font icon"
      [ngClass]="icon">
    </span>

    <span
      *ngIf="notAvailable && !iconBack"
      class="message__not-available">
    </span>

    <div
      class="message__content"
      [ngClass]="{'message__content--limited': actionsList}">
      <h3
        *ngIf="title"
        class="message__title">
        {{ title }}
      </h3>

      <ng-content></ng-content>
    </div>

    <span
      *ngIf="notAvailable && iconBack"
      class="message__not-available message__not-available--back">
    </span>

    <span
      *ngIf="icon && iconBack"
      class="message__icon-font message__icon-back icon"
      [ngClass]="icon">
    </span>
  </div>

  <ng-template #customButtons>
    <ng-content select=".message__actions"></ng-content>
  </ng-template>

  <div
    *ngIf="actionsList && actionsList.length else customButtons"
    class="message__actions"
    [ngClass]="{
      'message__actions--single': actionsList.length === 1
    }"
    [ngStyle]="{ 'flex-basis': actionsBasis }">
    <a
      *ngFor="let action of actionsList"
      class="message__action btn"
      (click)="action.trigger()"
      [ngStyle]="{ 'width': action.width }"
      [ngClass]="{
        'btn--primary': action.type === 'primary',
        'btn--secondary': action.type === 'secondary',
        'btn--accent': action.type === 'accent',
        'btn--alert': action.type === 'alert',
        'btn--info btn--transparent': action.type === 'info',
        'btn--disabled': action.disabled || ((action.asyncDisabled) ? (action.asyncDisabled | async) : false)
      }">
      {{ action.text }}
    </a>
    <span
      *ngIf="tooltip"
      class="message__icon icon-ask"
      tooltipContent="{{ tooltip }}"
      tooltipPlacement="left-top"
      toltipOrigin="middle">
    </span>
  </div>
</div>
