import * as partnershipsActions from '../actions/partnerships.actions';
import { PartnershipManage, PartnershipsList } from '../models';

export interface State {
    list: PartnershipsList;
    manage: PartnershipManage;
}

const initialState: State = {
    list: {
        data: {
            results: [],
            next: null,
            previous: null,
            count: null,
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            id: null,
            isWorkflowActive: false,
            publisher: null,
            recipient: null,
            recipientId: null,
            workflowConfiguration: null,
        },
        isFetching: true,
        isSaving: false,
        errors: {},
    },
};

export function reducer(state: State = initialState, action: partnershipsActions.Actions): State {
    switch (action.type) {
        case partnershipsActions.ActionTypes.GET_PARTNERSHIPS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case partnershipsActions.ActionTypes.GET_PARTNERSHIPS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case partnershipsActions.ActionTypes.GET_PARTNERSHIPS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }
        case partnershipsActions.ActionTypes.GET_PARTNERSHIP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case partnershipsActions.ActionTypes.GET_PARTNERSHIP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case partnershipsActions.ActionTypes.GET_PARTNERSHIP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case partnershipsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getManage = (state: State) => state.manage;
