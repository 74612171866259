import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PROTECTED_URLS, PUBLIC_URLS } from 'urls.conf';

@Injectable()
export class AuxiliaryService {
    constructor(private httpClient: HttpClient) {}

    getMaintenanceNotifications(): Observable<any> {
        return this.httpClient.get(PUBLIC_URLS.MAINTENANCE_NOTIFICATIONS);
    }

    getMohapProductUpdatePreview(id: string): Observable<any> {
        return this.httpClient.get(`products/${id}/mohap_update_preview/`);
    }

    unfreezeProduct(productId, decision): Observable<any> {
        return this.httpClient.post(
            `products/${productId}/unfreeze/`,
            { decision }
        );
    }

    rollbackApproval(productId): Observable<any> {
        return this.httpClient.get(`products/${productId}/rollback/`);
    }

    downloadFileLinkCloudFrontSigned(uuid: string): Observable<any> {
        return this.httpClient.get(`${PROTECTED_URLS.CW_FILE_LINK}${uuid}/`);
    }
    setUaePassId(code: string): Observable<object> {
        return this.httpClient.post(
            `${PROTECTED_URLS.SET_UAE_ID}`,
            { code }
        );
    }

    getCompaniesToProductTransfer(productId: string) {
      return this.httpClient.get(`products/${productId}/companies_to_transfer/`);
    }

    transferProductToCompany(productId: string, companyId: string) {
      return this.httpClient.post(`products/${productId}/transfer_product/`, { company: companyId });
    }
}
