import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as authActions from '../../core/actions/auth.actions';

import * as fromRoot from '../../core/reducers';

@Injectable()
export class AuthService {
    lastActivity: number;
    loggedOutDueToInactivity: boolean = false;
    activityIntervalId: any;

    constructor(private store: Store<fromRoot.State>) {
        store.subscribe(() => this.refreshActivity());
    }

    refreshActivity(): void {
        this.lastActivity = Date.now();
    }

    /**
     * Checks if the last request was made more then 1 hour ago
     * if yes - dispatch log out action and set
     * inactivity flag to true
     */
    checkLastActivity(): void {
        if (Date.now() - this.lastActivity > 3600000) {
            this.store.dispatch(new authActions.SignOutAction());
            this.loggedOutDueToInactivity = true;
            clearInterval(this.activityIntervalId);
        }
    }

    /**
     * Sets interval to check user activity every 1min
     */
    startCheckingActivity(): void {
        clearInterval(this.activityIntervalId);
        this.activityIntervalId = setInterval(this.checkLastActivity.bind(this), 60000);
    }

    /**
     * Retrieves the authorization token from local storage
     * @returns authorization token
     */
    getTokenFromLocalStorage(): string {
        return localStorage.getItem('token');
    }
}
