import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class PaidPlansReadGuard extends BaseGuard {
    path = ['plans', 'read'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class PaidPlansManageGuard extends BaseGuard {
    path = ['plans', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}
