import { ListManageActions, ListManageActionTypes } from './products.list-manage.actions';
import { ListOperationsActions, ListOperationsActionTypes } from './products.list-operations.actions';
import { ManageActions, ManageActionTypes } from './products.manage.actions';
import { OperationsActions, OperationsActionTypes } from './products.operations.actions';
import { ScorecardsActions, ScorecardsActionTypes } from './products.scorecards.actions';
import { UtilsActions, UtilsActionTypes } from './products.utils.actions';

export const ActionTypes = {
    ...ListManageActionTypes,
    ...ListOperationsActionTypes,
    ...ManageActionTypes,
    ...OperationsActionTypes,
    ...ScorecardsActionTypes,
    ...UtilsActionTypes,
};

export * from './products.list-manage.actions';
export * from './products.list-operations.actions';
export * from './products.manage.actions';
export * from './products.operations.actions';
export * from './products.scorecards.actions';
export * from './products.utils.actions';

export type Actions =
    | ListManageActions
    | ListOperationsActions
    | ManageActions
    | OperationsActions
    | ScorecardsActions
    | UtilsActions;
