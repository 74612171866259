import * as userGroupsActions from '../actions/user-groups.actions';
import { UserGroupsList, UserGroupsManage, UserGroupsOptions } from '../models';

export interface State {
    manage: UserGroupsManage;
    list: UserGroupsList;
    options: UserGroupsOptions;
}

const initialState: State = {
    manage: {
        data: {
            id: null,
            company: null,
            name: '',
            isCustom: false,
            isStaff: false,
            permissions: {},
            canAccessAllCategories: false,
            productCategories: [],
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    list: {
        data: {
            results: [],
            previous: null,
            count: null,
            next: null,
        },
        errors: {},
        isFetching: true,
    },
    options: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: userGroupsActions.Actions): State {
    switch (action.type) {
        case userGroupsActions.ActionTypes.GET_USER_GROUPS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case userGroupsActions.ActionTypes.GET_USER_GROUPS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.GET_USER_GROUPS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.GET_USER_GROUP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                    isSaving: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.GET_USER_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.GET_USER_GROUP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.NEW_USER_GROUP: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: {
                        ...state.manage.data,
                        isCustom: true,
                        canAccessAllCategories: true,
                    },
                    errors: {},
                    isFetching: false,
                    isSaving: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.CREATE_USER_GROUP_REQUEST:
        case userGroupsActions.ActionTypes.SAVE_USER_GROUP_REQUEST:
        case userGroupsActions.ActionTypes.DELETE_USER_GROUP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case userGroupsActions.ActionTypes.CREATE_USER_GROUP_SUCCESS:
        case userGroupsActions.ActionTypes.SAVE_USER_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.CREATE_USER_GROUP_ERROR:
        case userGroupsActions.ActionTypes.SAVE_USER_GROUP_ERROR:
        case userGroupsActions.ActionTypes.DELETE_USER_GROUP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.DELETE_USER_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: {},
                    isFetching: true,
                },
            };
        }

        case userGroupsActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case userGroupsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case userGroupsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case userGroupsActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        default: {
            return state;
        }
    }
}
export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsFetching = (state: State) => state.manage.isFetching;
export const getManageIsSaving = (state: State) => state.manage.isSaving;

export const getOptions = (state: State) => state.options;
export const getOptionsData = (state: State) => state.options.data;
export const getOptionsIsFetching = (state: State) => state.options.isFetching;
