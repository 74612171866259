import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HTTPClientVer } from '@core/utils/request.utils';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { AnyErrors } from '@core/actions/common.actions';
import * as userGroups from '../actions/user-groups.actions';

import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';
import { UserGroupsService } from '../services/user-groups.service';

import { catchErrorJson } from './catch-error';

@Injectable()
export class UserGroupsEffects {
    constructor(
        private router: Router,
        private actions$: Actions,
        private userGroupsService: UserGroupsService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    @Effect()
    getGroupsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(userGroups.ActionTypes.GET_USER_GROUPS_REQUEST),
        map((action: userGroups.GetUserGroupsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.userGroupsService.getUserGroups(payload).pipe(
                map((response) => new userGroups.GetUserGroupsSuccessAction(response)),
                catchError((error) =>
                    of(new userGroups.GetUserGroupsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    getGroupRequest$: Observable<Action> = this.actions$.pipe(
        ofType(userGroups.ActionTypes.GET_USER_GROUP_REQUEST),
        map((action: userGroups.GetUserGroupsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.userGroupsService.getUserGroup(payload).pipe(
                map((response) => new userGroups.GetUserGroupSuccessAction(response)),
                catchError((error) => of(new userGroups.GetUserGroupErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    saveGroupRequest$: Observable<Action> = this.actions$.pipe(
        ofType(userGroups.ActionTypes.SAVE_USER_GROUP_REQUEST),
        map((action: userGroups.SaveUserGroupRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.userGroupsService.saveUserGroup(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved user role ${response.name}`);
                }),
                map((response) => new userGroups.SaveUserGroupSuccessAction(response)),
                catchError((error) =>
                    of(new userGroups.SaveUserGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    createGroupRequest$: Observable<Action> = this.actions$.pipe(
        ofType(userGroups.ActionTypes.CREATE_USER_GROUP_REQUEST),
        map((action: userGroups.CreateUserGroupRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.userGroupsService.createUserGroup(payload).pipe(
                tap(() => {
                    this.notificationsService.success(`New user role created.`);
                    if (payload.redirectTo) {
                        this.router.navigate(payload.redirectTo, {
                            queryParams: payload.queryParams,
                        });
                    }
                }),
                map((response) => new userGroups.CreateUserGroupSuccessAction(response)),
                catchError((error) =>
                    of(new userGroups.CreateUserGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    deleteGroupRequest$: Observable<Action> = this.actions$.pipe(
        ofType(userGroups.ActionTypes.DELETE_USER_GROUP_REQUEST),
        map((action: userGroups.DeleteUserGroupRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.userGroupsService.deleteUserGroup(payload.id).pipe(
                tap(
                    () => {
                        this.notificationsService.success(`Deleted user role`);
                        this.router.navigate(payload.redirectTo, {
                            queryParams: payload.queryParams,
                        });
                    },
                    () => {
                        this.modalService.close('delete-user-group-modal');
                    },
                ),
                map(() => new userGroups.DeleteUserGroupSuccessAction()),
                catchError((error) =>
                    of(new userGroups.DeleteUserGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    getOptionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(userGroups.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap(() => {
            return this.userGroupsService.getOptions().pipe(
                map((response) => new userGroups.GetOptionsSuccessAction(response.actions.POST)),
                catchError((error) => of(new AnyErrors(error))),
            );
        }),
    );
}
