import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Workflows]';

export const ActionTypes = {
    NEW_CONFIGURATION: type(`${ACTION_PREFIX} New Configuration`),
    CREATE_CONFIGURATION_REQUEST: type(`${ACTION_PREFIX} Create Configuration Request`),
    CREATE_CONFIGURATION_SUCCESS: type(`${ACTION_PREFIX} Create Configuration Success`),
    CREATE_CONFIGURATION_ERROR: type(`${ACTION_PREFIX} Create Configuration Error`),
    GET_CONFIGURATION_REQUEST: type(`${ACTION_PREFIX} Get Configuration Request`),
    GET_CONFIGURATION_SUCCESS: type(`${ACTION_PREFIX} Get Configuration Success`),
    GET_CONFIGURATION_ERROR: type(`${ACTION_PREFIX} Get Configuration Error`),
    SAVE_CONFIGURATION_REQUEST: type(`${ACTION_PREFIX} Save Configuration Request`),
    SAVE_CONFIGURATION_SUCCESS: type(`${ACTION_PREFIX} Save Configuration Success`),
    SAVE_CONFIGURATION_ERROR: type(`${ACTION_PREFIX} Save Configuration Error`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Configuration Manage`),
};

/**
 * Create 'New Configuration' Action
 * @param payload Contains configuration type
 */
export class NewConfigurationAction implements Action {
    type = ActionTypes.NEW_CONFIGURATION;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Configuration Request' Action
 * @param payload Contains object with configuration data
 */
export class CreateConfigurationRequestAction implements Action {
    type = ActionTypes.CREATE_CONFIGURATION_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Configuration Success' Action
 * @param payload Contains object with configuration data
 */
export class CreateConfigurationSuccessAction implements Action {
    type = ActionTypes.CREATE_CONFIGURATION_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Configuration Error' Action
 * @param payload Contains object with errors
 */
export class CreateConfigurationErrorAction implements Action {
    type = ActionTypes.CREATE_CONFIGURATION_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Configuration Request' Action
 * @param payload Contains configuration id
 */
export class GetConfigurationRequestAction implements Action {
    type = ActionTypes.GET_CONFIGURATION_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Configuration Success' Action
 * @param payload Contains object with configuration data
 */
export class GetConfigurationSuccessAction implements Action {
    type = ActionTypes.GET_CONFIGURATION_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Configuration Error' Action
 * @param payload Contains object with errors
 */
export class GetConfigurationErrorAction implements Action {
    type = ActionTypes.GET_CONFIGURATION_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Configuration Request' Action
 * @param payload Contains object with configuration data
 */
export class SaveConfigurationRequestAction implements Action {
    type = ActionTypes.SAVE_CONFIGURATION_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Configuration Success' Action
 * @param payload Contains object with configuration data
 */
export class SaveConfigurationSuccessAction implements Action {
    type = ActionTypes.SAVE_CONFIGURATION_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Configuration Error' Action
 * @param payload Contains object with errors
 */
export class SaveConfigurationErrorAction implements Action {
    type = ActionTypes.SAVE_CONFIGURATION_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

export type Actions =
    | NewConfigurationAction
    | CreateConfigurationRequestAction
    | CreateConfigurationSuccessAction
    | CreateConfigurationErrorAction
    | GetConfigurationRequestAction
    | GetConfigurationSuccessAction
    | GetConfigurationErrorAction
    | SaveConfigurationRequestAction
    | SaveConfigurationSuccessAction
    | SaveConfigurationErrorAction
    | ClearManageAction;
