<div
  class="switch"
  [ngClass]="{
    'switch--checked': checked,
    'switch--disabled': disabled,
    'switch--large': size === 'large',
    'switch--medium': size === 'medium',
    'switch--small': size === 'small'
  }"
>
  <span class="switch__label switch__label--positive">{{ positiveLabel }}</span>
  <span class="switch__label switch__label--negative">{{ negativeLabel }}</span>
  <span class="switch__slide"></span>
</div>
