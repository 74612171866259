import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as publicApi from '../actions/public-api.actions';

import { PublicApiService } from '../services/public-api.service';

import { Endpoint } from '@core/models';

@Injectable()
export class PublicApiEffects {
    constructor(private actions$: Actions, private publicApiService: PublicApiService) {}

    @Effect()
    getProductsData$: Observable<Action> = this.actions$.pipe(
        ofType(publicApi.ActionTypes.PRODUCTS_DATA_REQUEST),
        map((action: publicApi.ProductsDataRequestAction) => action.payload),
        switchMap((payload) => {
            return this.publicApiService.request(payload).pipe(
                map((response) => new publicApi.ProductsDataSuccessAction(this.parseResponse(response, payload))),
                catchError((error) => {
                    return of(new publicApi.ProductsDataErrorAction(this.parseResponse(error, payload)));
                }),
            );
        }),
    );

    @Effect()
    getProductData$: Observable<Action> = this.actions$.pipe(
        ofType(publicApi.ActionTypes.PRODUCT_DATA_REQUEST),
        map((action: publicApi.ProductDataRequestAction) => action.payload),
        switchMap((payload) => {
            return this.publicApiService.request(payload).pipe(
                map((response) => new publicApi.ProductDataSuccessAction(this.parseResponse(response, payload))),
                catchError((error) => {
                    return of(new publicApi.ProductDataErrorAction(this.parseResponse(error, payload)));
                }),
            );
        }),
    );

    @Effect()
    getProductCategories$: Observable<Action> = this.actions$.pipe(
        ofType(publicApi.ActionTypes.PRODUCT_CATEGORIES_REQUEST),
        map((action: publicApi.ProductCategoriesRequestAction) => action.payload),
        switchMap((payload) => {
            return this.publicApiService.request(payload).pipe(
                map((response) => new publicApi.ProductCategoriesSuccessAction(this.parseResponse(response, payload))),
                catchError((error) => {
                    return of(new publicApi.ProductCategoriesErrorAction(this.parseResponse(error, payload)));
                }),
            );
        }),
    );

    @Effect()
    getProductCategory$: Observable<Action> = this.actions$.pipe(
        ofType(publicApi.ActionTypes.PRODUCT_CATEGORY_REQUEST),
        map((action: publicApi.ProductCategoryRequestAction) => action.payload),
        switchMap((payload) => {
            return this.publicApiService.request(payload).pipe(
                map((response) => new publicApi.ProductCategorySuccessAction(this.parseResponse(response, payload))),
                catchError((error) => {
                    return of(new publicApi.ProductCategoryErrorAction(this.parseResponse(error, payload)));
                }),
            );
        }),
    );

    @Effect()
    getProductMediaProfiles$: Observable<Action> = this.actions$.pipe(
        ofType(publicApi.ActionTypes.PRODUCT_MEDIA_PROFILES_REQUEST),
        map((action: publicApi.ProductMediaProfilesRequestAction) => action.payload),
        switchMap((payload) => {
            return this.publicApiService.request(payload).pipe(
                map(
                    (response) =>
                        new publicApi.ProductMediaProfilesSuccessAction(this.parseResponse(response, payload)),
                ),
                catchError((error) => {
                    return of(new publicApi.ProductMediaProfilesErrorAction(this.parseResponse(error, payload)));
                }),
            );
        }),
    );

    parseResponse(response: any, request: any): Endpoint {
        return {
            request: {
                url: response.url,
                params: request.options.payload,
            },
            response: {
                ok: response.ok,
                status: `${response.status} ${response.statusText}`,
                headers: response.headers,
                body: response,
            },
        };
    }
}
