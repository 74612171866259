import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';

import { NotificationsService } from '@core/services/notifications.service';

import { formatSize, generateUniqueId, getFilenameFromURL } from '@app/utils';

import { UploadInputData } from '@core/models';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'file-upload',
    styleUrls: ['./file-upload.component.scss'],
    templateUrl: './file-upload.component.html',
})
export class FileUploadComponent implements OnInit {
    @Input() fileSrc: string;
    @Input() errors: any[];
    @Input() name: string;
    @Input() position: number;
    @Input() label: string;
    @Input() formats: string[];
    @Input() maxSize: number;
    @Input() type: string;
    @Input() isDisabled: boolean = false;
    @Input() isRequired: boolean = false;
    @Input() isPositionIndicatorVisible: boolean = true;
    @Output() changeFile: EventEmitter<UploadInputData> = new EventEmitter();
    @Output() removeFile: EventEmitter<string> = new EventEmitter();

    private blockName: string = 'file-upload';
    public filesToDrop: boolean = false;
    public inputId: string;

    @HostListener('dragenter', ['$event']) onDragEnter(event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.isDisabled) {
            return;
        }
        this.filesToDrop = true;
    }

    @HostListener('dragover', ['$event']) onDragOver(event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.isDisabled) {
            return;
        }
        event.dataTransfer.dropEffect = 'copy'; // say that is a copy
        this.filesToDrop = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.isDisabled) {
            return;
        }
        this.filesToDrop = false;
    }

    @HostListener('drop', ['$event']) onDrop(event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.isDisabled) {
            return;
        }
        this.filesToDrop = false;

        const files = event.dataTransfer.files;
        this.addFiles(files);
    }

    /**
     * Component constructor function
     */
    constructor(private el: ElementRef, private notificationsService: NotificationsService) {}

    public get fileName(): string {
        return this.fileSrc ? getFilenameFromURL(this.fileSrc) : '';
    }

    ngOnInit(): void {
        this.inputId = `${this.name}-${generateUniqueId()}`;
    }

    /**
     * Getting file and emit to Output.
     * @param event Change event from input
     */
    addFile(event: Event): void {
        this.filesToDrop = false;
        const { files } = event.currentTarget as HTMLInputElement;
        this.addFiles(files);
    }

    /**
     * Check files, show preview and emit data
     * @param files Files Object
     */
    addFiles(files: any): void {
        const [file] = files;
        if (file) {
            let allowedFormat;
            if (this.type === 'video') {
                // fix for problem with .avi mimetype (video/avi - video/x-msvideo)
                allowedFormat = !!~file.type.indexOf('video');
            } else {
                allowedFormat = this.formats ? !!~this.formats.indexOf(file.type) : true;
            }
            const allowedSize = this.maxSize ? this.getDividedFileSize(file.size, 2) <= this.maxSize : true;

            if (!allowedFormat || !allowedSize) {
                !allowedFormat && this.notificationsService.error('This type of file is not allowed.');
                !allowedSize &&
                    this.notificationsService.error(
                        `The size of file is too big. (max. ${formatSize(this.maxSize, 2)})`,
                    );
            } else {
                this.showPreview(file);
                this.changeFile.emit({
                    name: this.name,
                    position: this.position,
                    file,
                });
            }
        }
    }

    private getDividedFileSize(fileSize: number, iterations: number = 0, si: boolean = true): number {
        const thresh = si ? 1000 : 1024;
        while (iterations--) {
            fileSize /= thresh;
        }
        return fileSize;
    }

    public get formatsString(): string {
        return this.formats ? this.formats.join(', ') : '';
    }

    /**
     * Add preview of selected file.
     * @param file File object from input.
     */
    showPreview(file: File): void {
        const previewNode = this.el.nativeElement.querySelector(`.${this.blockName}__preview`);

        // Remove previous image and show placeholder
        const existingImg = this.el.nativeElement.querySelector(`.${this.blockName}__preview img`);
        if (existingImg) {
            previewNode.removeChild(existingImg);
        }
        previewNode.setAttribute('data-preview', file.name);
        previewNode.classList.remove(`${this.blockName}__preview--show`);

        // Hide placeholder
        previewNode.classList.add(`${this.blockName}__preview--show`);

        const imageType = /^image\//;

        if (imageType.test(file.type)) {
            previewNode.appendChild(this.showImagePreview(file));
        }
    }

    showImagePreview(file: File): any {
        // Create new image
        const img: any = document.createElement('img');
        img.classList.add(`${this.blockName}__preview-image`);
        img.file = file;

        // Set source for new image
        const reader = new FileReader();
        reader.onload = () => (img.src = URL.createObjectURL(file));
        reader.readAsDataURL(file);

        return img;
    }

    /**
     * Remove field from FormData and remove preview of file.
     * @param name Input name
     */
    onRemoveFile(name: string): void {
        const previewNode = this.el.nativeElement.querySelector(`.${this.blockName}__preview`);
        const existingImg = this.el.nativeElement.querySelector(`.${this.blockName}__preview img`);

        if (existingImg) {
            previewNode.removeChild(existingImg);
        }
        previewNode.classList.remove(`${this.blockName}__preview--show`);
        previewNode.removeAttribute('data-preview');
        this.el.nativeElement.querySelector(`.${this.blockName}__input`).value = '';
        this.errors = [];
        this.removeFile.emit(name);
    }
}
