<div class="progress-modal">
  <div class="progress-modal__progressbar">
    <span
      *ngIf="status !== 'PENDING' && status !== 'FAILURE'"
      class="progress-modal__progress"
      [ngStyle]="{ 'width': progress + '%'}">
    </span>
  </div>

  <div class="progress-modal__info microcopy microcopy--align-right">
    <span
      *ngIf="status"
      class="progress-modal__info-status">
      {{ status === 'PENDING' ? 'Pending' : '' }}
      {{ status === 'IN_PROGRESS' ? 'In progress' : '' }}
      {{ status === 'SUCCESS' ? 'Success' : '' }}
      {{ status === 'FAILURE' ? 'Failure' : '' }}
    </span>

    <span
      *ngIf="total !== 0 else spinner"
      class="progress-modal__info-products">
      {{ progress }}%
    </span>

    <ng-template #spinner>
      <span class="loader"></span>
    </ng-template>
  </div>
</div>
