import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'barcode-choice-modal',
    templateUrl: './barcode-choice-modal.component.html',
})
export class BarcodeChoiceModalComponent implements OnInit {
    @Output() emitValue: EventEmitter<string> = new EventEmitter();
    @Input() bulkVersion: boolean = false;

    barcodeChoiceFrom: FormGroup;

    typeOptions: any[] = [
        {
            displayName: 'GS1 Data Matrix',
            value: 'gs1-data-matrix',
        },
        {
            displayName: 'GS1-128',
            value: 'gs1-128',
        },
    ];

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.barcodeChoiceFrom = this.formBuilder.group({
            type: [this.typeOptions[0].value, Validators.required]
        });

        if (this.bulkVersion){
            // emit default value to set it in component variable
            this.emitValue.emit(this.barcodeChoiceFrom.value.type)

            // listen to form changes, only do it when
            // form doesnt have submit button (use for bulk action)
            this.barcodeChoiceFrom.valueChanges.subscribe(
                result => this.emitValue.emit(result.type)
            );
        }
    }

    onSubmit(value) {
        this.emitValue.emit(value)
    }
}
