import { Injectable, Injector } from '@angular/core';
import { SimpleUser } from '@core/models';
import { LocalUserGuard } from './localUser.guards';

@Injectable()
export class IsStaffGuard extends LocalUserGuard {
    constructor(injector: Injector) {
        super(injector);
    }

    checkUser(userData: SimpleUser): boolean {
        return !!userData.company.isStaff;
    }
}
