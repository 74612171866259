import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PasswordStrengthVariant, PasswordVariations } from '@core/models/auth.models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'password-strength-indicator',
    styleUrls: ['password-strength-indicator.component.scss'],
    templateUrl: './password-strength-indicator.component.html',
})
export class PasswordStrengthIndicatorComponent {
    @Input() data: string = '';

    public passwordStrengthVariant = PasswordStrengthVariant;
    public cases: string[] = Object.keys(this.testData);

    get testData(): PasswordVariations {
        return {
            length: /.{8}/.test(this.data),
            special: /[$&+,:;=?@#|'<>\.^*()%\!\-\#\\\/{}~`[\]]/.test(this.data),
            number: /\d+/.test(this.data),
            lowerCase: /[a-z]+/.test(this.data),
            upperCase: /[A-Z]+/.test(this.data),
        };
    }

    get score(): number {
        return Object.values(this.testData).filter((value) => value).length;
    }
}
