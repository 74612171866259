
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as products from '../actions/products.actions';
import * as refresh from '../actions/refresh.actions';

@Injectable()
export class RefreshEffects {
    constructor(
    private actions$: Actions,
  ) {}

    @Effect()
    requireRefreshProducts: Observable<Action> = this.actions$.pipe(
        ofType(
            products.ActionTypes.CLEAR_BULK_CLONE_STATUS,
            products.ActionTypes.SOFT_DELETE_PRODUCTS_ERROR,
            products.ActionTypes.SOFT_DELETE_PRODUCTS_SUCCESS,
            products.ActionTypes.HARD_DELETE_PRODUCTS_ERROR,
            products.ActionTypes.HARD_DELETE_PRODUCTS_SUCCESS,
            products.ActionTypes.RECOVER_PRODUCTS_ERROR,
            products.ActionTypes.RECOVER_PRODUCTS_SUCCESS,
            products.ActionTypes.DISCONTINUE_PRODUCTS_ERROR,
            products.ActionTypes.DISCONTINUE_PRODUCTS_SUCCESS,
            products.ActionTypes.REVERT_DISCONTINUE_PRODUCTS_ERROR,
            products.ActionTypes.REVERT_DISCONTINUE_PRODUCTS_SUCCESS,
            products.ActionTypes.SET_PRODUCTS_TO_LIVE_SUCCESS,
            products.ActionTypes.SHARE_PRODUCTS_ERROR,
            products.ActionTypes.SHARE_PRODUCTS_SUCCESS,
            products.ActionTypes.ACKNOWLEDGE_PRODUCTS_UPDATE_SUCCESS
        ),
        map(() => new refresh.SetRequireRefreshToTrue()),
    );

    @Effect()
    notRequireRefreshProducts: Observable<Action> = this.actions$.pipe(
        ofType(products.ActionTypes.GET_PRODUCTS_LIST_SUCCESS),
        map(() => new refresh.SetRequireRefreshToFalse()),
    );
}
