import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { AttributeData } from '@core/models';

/**
 * Checks whether the value is empty or not
 * @param value Form Control value
 * @returns
 */
function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

@Injectable()
export class CustomValidatorsService {
    /**
     * Checks whether the e-mail address meets all requirements
     * @param control Form Control object
     * @returns Validation state
     */
    validateEmail(control: FormControl): { [key: string]: any } {
        if (isEmptyInputValue(control.value)) {
            return null; // don't validate empty values to allow optional controls
        }

        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !EMAIL_REGEXP.test(control.value) ? { invalidEmail: true } : null;
    }

    /**
     * Checks whether the field contains only integer number
     * @param control Form Control object
     * @returns Validation state
     */
    isIntegerNumber(control: FormControl): { [key: string]: any } {
        if (isEmptyInputValue(control.value)) {
            return null; // don't validate empty values to allow optional controls
        }

        const NUMBERS_REGEXP = /^\d+$/;
        return !NUMBERS_REGEXP.test(control.value) ? { invalidIntegerNumber: true } : null;
    }

    /**
     * Checks whether the field contains only float number
     * @param control Form Control object
     * @returns Validation state
     */
    isFloatNumber(control: FormControl): { [key: string]: any } {
        if (isEmptyInputValue(control.value)) {
            return null; // don't validate empty values to allow optional controls
        }

        const NUMBERS_REGEXP = /^(?:[1-9]\d*|0)(?:\.\d+)?$/;
        return !NUMBERS_REGEXP.test(control.value) ? { invalidFloatNumber: true } : null;
    }

    /**
     * Checks whether password meets all requirements: min 8 chars, at least one spec char, one lowercase chat and one uppercase char
     * @param control Form Control object
     * @returns Validation state
     */
    validatePassword(control: FormControl): { [key: string]: any } {
        if (isEmptyInputValue(control.value)) {
            return null; // don't validate empty values to allow optional controls
        }

        /**
         * This regex will enforce these rules:
         * At least one upper case english letter, (?=.*?[A-Z])
         * At least one lower case english letter, (?=.*?[a-z])
         * At least one digit, (?=.*?[0-9])
         * At least one special character, (?=.*?[#?!@$%^&*-])
         * Minimum 8 in length .{8,} (with the anchors)
         */
        const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
        return !PASSWORD_REGEX.test(control.value) ? { invalidPassword: true } : null;
    }

    /**
     * Checks if array has empty value
     * @param control FormArray object
     * @returns Validation state
     */
    hasEmptyValues(control: FormArray): { [key: string]: any } {
        const invalid = !!control.value.filter((item) => !item.id).length;

        return invalid ? { hasEmptyValues: true } : null;
    }

    /**
     * Checks whether the value is lower than minimum value
     * @param minValue Minimum value
     * @returns Validator function that check if value is lower than min value
     */
    minValue(minValue: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (isEmptyInputValue(control.value)) {
                return null; // don't validate empty values to allow optional controls
            }

            const isValid = control.value >= minValue;
            return !isValid ? { invalidMinValue: { minValue, actualValue: control.value } } : null;
        };
    }

    /**
     * Checks whether the value is higher than maximum value
     * @param maxValue Maximum value
     * @returns Validator function that check if value is higer than max value
     */
    maxValue(maxValue: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (isEmptyInputValue(control.value)) {
                return null; // don't validate empty values to allow optional controls
            }

            const isValid = control.value <= maxValue;
            return !isValid ? { invalidMaxValue: { maxValue, actualValue: control.value } } : null;
        };
    }

    minMaxLength(options: { min?: number; max?: number }): ValidatorFn {
        const { min, max } = options;
        return (control: FormArray): { [key: string]: any } => {
            const currentLength = control.value.length;
            const minIsset = typeof min !== 'undefined';
            const maxIsset = typeof max !== 'undefined';
            const minLength = minIsset ? min : 0;
            const maxLength = maxIsset ? max : Infinity;
            const isLess = minIsset && currentLength < minLength;
            const isGreater = maxIsset && currentLength > maxLength;

            if (minIsset && maxIsset && min > max) {
                throw new Error('Validators: minMaxLength. Min value cannot be greater than max value.');
            }

            if (min < 0 || max < 0) {
                throw new Error('Validators: minMaxLength. Min and max value cannot be negative.');
            }

            return isLess || isGreater
                ? {
                      ...(isLess ? { minLength: { value: currentLength, limit: minLength } } : {}),
                      ...(isGreater ? { maxLength: { value: currentLength, limit: maxLength } } : {}),
                  }
                : null;
        };
    }

    /**
     * Checks whether the fields in a group are equal
     * @param fristControl FormControl object
     * @param secondControl FormControl object
     * @returns Validator function that compares control values and returns validation state
     */
    areEqual(firstControl: string, secondControl: string): ValidatorFn {
        return (group: FormGroup): { notEqual: boolean } | null => {
            return group.get(firstControl).value !== group.get(secondControl).value ? { notEqual: true } : null;
        };
    }

    /**
     * checksum calculation for GTIN-8, GTIN-12, GTIN-13, GTIN-14, and SSCC
     * based on http://www.gs1.org/barcodes/support/check_digit_calculator
     * @param control Form Control object
     * @returns Validation state
     */
    isValidBarcode(control: FormControl): { [key: string]: any } {
        const barcode = control.value;

        if (isEmptyInputValue(barcode)) {
            return null; // don't validate empty values to allow optional controls
        }

        const allowedLengths = [8, 12, 13, 14];

        // check length
        if (!allowedLengths.includes(barcode.length)) {
            return { unknownFormat: true };
        }

        // only digits are allowed
        if (!/^\d+$/.test(barcode)) {
            return { notOnlyDigits: true };
        }

        const lastDigit = Number(barcode.substring(barcode.length - 1));
        let checksum = 0;

        const arr = barcode
            .substring(0, barcode.length - 1)
            .split('')
            .reverse();

        let oddTotal = 0;
        let evenTotal = 0;

        for (let i = 0; i < arr.length; i++) {
            if (i % 2 === 0) {
                oddTotal += Number(arr[i]) * 3;
            } else {
                evenTotal += Number(arr[i]);
            }
        }

        checksum = (10 - ((evenTotal + oddTotal) % 10)) % 10;

        // checksum should be equal to last digit
        return checksum !== lastDigit ? { invalidChecksum: true } : null;
    }

    /**
     * Checks if array of values has minimum of them set to true
     * @param number Minimum values
     * @returns Validator function that check if array has minimum values set to true
     */
    minSelected(number: number): ValidatorFn {
        return (array: FormArray): { [key: string]: any } => {
            return array.value.filter((current) => current).length < number ? { minSelected: true } : null;
        };
    }

    hasOneValueValidator(errorKey: string): ValidatorFn {
        return (control: FormControl): ValidationErrors | null =>
            Array.isArray(control.value) && control.value.length > 1 ? { [errorKey]: true } : null;
    }

    getNutritionalValuesValidator(attributeData: AttributeData): ValidatorFn {
        return (groups: FormGroup): ValidationErrors =>
            !attributeData.isRequired ||
            (attributeData.isRequired &&
                Object.values(groups.controls).some((group: FormGroup) => group.controls.per100.value !== null))
                ? null
                : { noNutritionalValues: false };
    }
}
