import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PublicApiV2CategorySchemaService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get category schema
     * @param id Category ID
     * @param withValues true, if service have to return response with values for choices fields
     * @param example return filled example JSON
     * @returns Backend API response
     */
    getCategorySchema(id: string, withValues: boolean = false, example: boolean = false): Observable<any> {
        if (withValues) {
            return this.httpClient.get<any>(`category_schema_public/${id}/?values`);
        }
        if (example) {
            return this.httpClient.get<any>(`category_schema_public/${id}/?example`);
        }
        return this.httpClient.get<any>(`category_schema_public/${id}/`);
    }
}
