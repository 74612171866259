import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { isFtaCompanySetInUser } from '@app/fta-config';
import { ColumnOptions, InputChanges, LocalUser, Order, SortTableEvent, TableOptions } from '@core/models';

interface Inputs {
    tableOptions: TableOptions;
    columnsOptions: ColumnOptions[];
    bulkCheckboxChecked: boolean;
    isFetching: boolean;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'table-list',
    styleUrls: ['./table-list.component.scss'],
    templateUrl: './table-list.component.html',
})
export class TableListComponent implements OnInit, OnChanges {
    @Input() tableOptions: TableOptions;
    @Input() columnsOptions: ColumnOptions[];
    @Input() bulkCheckboxChecked: boolean;
    @Input() isFetching: boolean;
    @Input() localUser: LocalUser;
    @Output() bulkCheckboxClick: EventEmitter<boolean> = new EventEmitter();
    @Output() sortTableClick: EventEmitter<SortTableEvent> = new EventEmitter();

    public table: TableOptions = {};
    public columns: ColumnOptions[] = [];
    public ORDER: typeof Order = Order;

    private defaultTableOptions: TableOptions = {
        align: 'left',
        border: false,
        ellipsis: false,
        hover: false,
        smallText: false,
        sortBy: null,
        ordering: null,
        header: true,
    };

    private defaultColumnOptions: ColumnOptions = {
        align: 'left',
        displayName: '',
        ellipsis: false,
        isBulkCheckbox: false,
        zeroWidth: false,
        width: '100%',
        label: null,
        sortable: false,
    };

    public sorting = {
        sortBy: null,
        ordering: null,
    };

    ngOnInit(): void {
        this.updateSorting();
    }

    ngOnChanges(changes: InputChanges<Inputs>): void {
        this.table = {
            ...this.defaultTableOptions,
            ...this.tableOptions,
        };

        if (changes.columnsOptions) {
            this.columns = this.columnsOptions.map((columnOptions) => ({
                ...this.defaultColumnOptions,
                ...{
                    align: this.table.align,
                    ellipsis: this.table.ellipsis,
                },
                ...columnOptions,
                stickiness: Array.isArray(columnOptions.stickiness)
                    ? columnOptions.stickiness
                    : [columnOptions.stickiness],
            }));
        }

        if (changes.tableOptions) {
            this.updateSorting();
        }
    }

    updateSorting(): void {
        this.table = {
            ...this.defaultTableOptions,
            ...this.tableOptions,
        };

        this.sorting = {
            sortBy: this.table.sortBy,
            ordering: this.table.ordering || this.ORDER.ASCENDING,
        };
    }

    /**
     * Emit event with current checkbox state
     *
     * @param event Event object
     */
    clickBulkCheckbox(event: any): void {
        event.stopPropagation();
        this.bulkCheckboxClick.emit(event.target.checked);
    }

    onTableSortingChange(column: ColumnOptions): void {
        if (column.sortable) {
            this.sorting.ordering =
                this.sorting.sortBy === column.label ? this.changeCurrentOrder() : this.ORDER.ASCENDING;

            this.sorting.sortBy = column.label;
            this.sortTableClick.emit({
                ordering: this.getOrderingValue(column.label),
            });
        }
    }

    changeCurrentOrder(): number {
        switch (this.sorting.ordering) {
            case this.ORDER.DESCENDING: {
                return this.ORDER.NONE;
            }

            case this.ORDER.NONE: {
                return this.ORDER.ASCENDING;
            }

            case this.ORDER.ASCENDING: {
                return this.ORDER.DESCENDING;
            }
        }
    }

    getOrderingValue(label: string): string | null {
        switch (this.sorting.ordering) {
            case this.ORDER.ASCENDING: {
                return label;
            }

            case this.ORDER.DESCENDING: {
                return `-${label}`;
            }

            case this.ORDER.NONE: {
                return null;
            }
        }
    }

    setFtaHeader(name: string): string {
        if (this.localUser !== undefined) {
            if (name === 'Category' && isFtaCompanySetInUser(this.localUser)) {
                return 'Product Registration Type';
            }
        }
        return name;
    }
}
