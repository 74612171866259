import { Action } from '@ngrx/store';

import { prepareActions } from '@app/utils';

import {
    CeleryId,
    CeleryStatusResponse,
    CompanyId,
    GtinId,
    ListedProductsListData,
    ListedProductsListFilterParams,
    ListedProductsListType,
    ListedProductsSearchParams,
    ListedSearchParamsState,
    ProductId,
    SearchId,
} from '@core/models';

export const ActionTypes = prepareActions('[Listed Products]', [
    'INITIALIZE_LISTS_VIEW_START',
    'INITIALIZE_LISTS_VIEW_UPDATE',
    'INITIALIZE_LISTS_VIEW_END',

    'SET_SEARCH_ID',
    'CLEAR_SEARCH_ID',

    'SET_SEARCH_PARAMS',
    'REMOVE_SEARCH_PARAMS',
    'CLEAR_SEARCH_PARAMS',

    'UPDATE_SHARED_FURTHER_STATE',

    'GET_ALL_LISTED_PRODUCTS',
    'FETCH_ALL_LISTED_PRODUCTS',

    'GET_LISTED_PRODUCTS_REQUEST',
    'GET_LISTED_PRODUCTS_SUCCESS',
    'GET_LISTED_PRODUCTS_ERROR',
    'CLEAR_LIST',

    'GET_LISTED_PRODUCTS_COUNT_REQUEST',
    'GET_LISTED_PRODUCTS_COUNT_SUCCESS',
    'GET_LISTED_PRODUCTS_COUNT_ERROR',
    'CLEAR_COUNTERS',

    'GET_LISTED_PRODUCT_REQUEST',
    'GET_LISTED_PRODUCT_SUCCESS',
    'GET_LISTED_PRODUCT_ERROR',
    'CLEAR_MANAGE',

    'GET_OPTIONS_REQUEST',
    'GET_OPTIONS_SUCCESS',
    'CLEAR_OPTIONS',

    'GET_PRODUCT_OPTIONS_REQUEST',
    'GET_PRODUCT_OPTIONS_SUCCESS',
    'CLEAR_PRODUCT_OPTIONS',

    'GET_LISTED_PRODUCT_CHANGES_HISTORY_REQUEST',
    'GET_LISTED_PRODUCT_CHANGES_HISTORY_SUCCESS',
    'GET_LISTED_PRODUCT_CHANGES_HISTORY_ERROR',
    'CLEAR_CHANGES_HISTORY',

    'GET_SHARES_HISTORY_REQUEST',
    'GET_SHARES_HISTORY_SUCCESS',
    'GET_SHARES_HISTORY_ERROR',
    'CLEAR_SHARES_HISTORY',

    'GET_LISTED_PRODUCT_CHANGES_REQUEST',
    'GET_LISTED_PRODUCT_CHANGES_SUCCESS',
    'GET_LISTED_PRODUCT_CHANGES_ERROR',
    'CLEAR_PRODUCT_CHANGES',

    'GET_LISTED_PRODUCT_REVIEWS_REQUEST',
    'GET_LISTED_PRODUCT_REVIEWS_SUCCESS',
    'GET_LISTED_PRODUCT_REVIEWS_ERROR',
    'CLEAR_LISTED_PRODUCT_REVIEWS',

    'GET_LISTED_PRODUCT_SUMMARY_REQUEST',
    'GET_LISTED_PRODUCT_SUMMARY_SUCCESS',
    'GET_LISTED_PRODUCT_SUMMARY_ERROR',
    'CLEAR_LISTED_PRODUCT_SUMMARY',

    'GET_PRODUCT_INTERNAL_DATA_REQUEST',
    'GET_PRODUCT_INTERNAL_DATA_SUCCESS',
    'GET_PRODUCT_INTERNAL_DATA_ERROR',
    'CLEAR_PRODUCT_INTERNAL_DATA',
]);

export class InitializeListsViewStart implements Action {
    readonly type = ActionTypes.INITIALIZE_LISTS_VIEW_START;
    constructor(
        public payload: Array<{
            listType?: ListedProductsListType;
            request: Partial<ListedProductsSearchParams>;
        }>,
    ) {}
}
export class InitializeListsViewUpdate implements Action {
    readonly type = ActionTypes.INITIALIZE_LISTS_VIEW_UPDATE;
    constructor(public payload: ListedProductsListType) {}
}
export class InitializeListsViewEnd implements Action {
    readonly type = ActionTypes.INITIALIZE_LISTS_VIEW_END;
    constructor(public payload?: never) {}
}

export class UpdateSharedFurtherState implements Action {
    readonly type = ActionTypes.UPDATE_SHARED_FURTHER_STATE;
    constructor(public payload: boolean) {}
}

export class GetAllListedProducts implements Action {
    readonly type = ActionTypes.GET_ALL_LISTED_PRODUCTS;
    constructor(
        public payload: Array<{
            listType?: ListedProductsListType;
            request: Partial<ListedProductsSearchParams>;
        }>,
    ) {}
}
export class FetchAllListedProducts implements Action {
    readonly type = ActionTypes.FETCH_ALL_LISTED_PRODUCTS;
    constructor(public payload?: never) {}
}

export class GetListedProductsRequestAction implements Action {
    readonly type = ActionTypes.GET_LISTED_PRODUCTS_REQUEST;
    constructor(
        public payload: {
            listType?: ListedProductsListType;
            request: Partial<ListedProductsSearchParams>;
        },
    ) {}
}
export class GetListedProductsSuccessAction implements Action {
    readonly type = ActionTypes.GET_LISTED_PRODUCTS_SUCCESS;
    constructor(
        public payload: {
            listType?: ListedProductsListType;
            response: ListedProductsListData;
        },
    ) {}
}
export class GetListedProductsErrorAction implements Action {
    readonly type = ActionTypes.GET_LISTED_PRODUCTS_ERROR;
    constructor(
        public payload: {
            listType?: ListedProductsListType;
            response: { [key: string]: any };
        },
    ) {}
}
export class ClearListAction implements Action {
    readonly type = ActionTypes.CLEAR_LIST;
    constructor(public payload: ListedProductsListType) {}
}

export class SetSearchParams implements Action {
    readonly type = ActionTypes.SET_SEARCH_PARAMS;
    constructor(public payload: ListedSearchParamsState) {}
}
export class RemoveSearchParams implements Action {
    readonly type = ActionTypes.REMOVE_SEARCH_PARAMS;
    constructor(public payload: Array<keyof ListedProductsListFilterParams>) {}
}
export class ClearSearchParams implements Action {
    readonly type = ActionTypes.CLEAR_SEARCH_PARAMS;
    constructor(public payload?: never) {}
}

export class SetSearchId implements Action {
    readonly type = ActionTypes.SET_SEARCH_ID;
    constructor(public payload: SearchId) {}
}
export class ClearSearchId implements Action {
    readonly type = ActionTypes.CLEAR_SEARCH_ID;
    constructor(public payload?: never) {}
}

/**
 * Create 'Get Listed Product Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetListedProductsCountRequestAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCTS_COUNT_REQUEST;

    constructor(public payload?: any) {}
}
/**
 * Create 'Get Listed Product Success' Action
 * @param payload Contains object with product listing and params
 */
export class GetListedProductsCountSuccessAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCTS_COUNT_SUCCESS;

    constructor(public payload?: any) {}
}
/**
 * Create 'Get Listed Product Error' Action
 * @param payload Contains object with errors
 */
export class GetListedProductsCountErrorAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCTS_COUNT_ERROR;

    constructor(public payload?: any) {}
}
/**
 * Create 'Clear Counters' Action
 */
export class ClearCountersAction implements Action {
    type = ActionTypes.CLEAR_COUNTERS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Listed Product Request' Action
 */
export class GetListedProductRequestAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Listed Product Success' Action
 */
export class GetListedProductSuccessAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Listed Product Error' Action
 */
export class GetListedProductErrorAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' Action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' Action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Options Request' Action
 */
export class GetProductOptionsRequestAction implements Action {
    type = ActionTypes.GET_PRODUCT_OPTIONS_REQUEST;

    constructor(public payload?: ProductId) {}
}

/**
 * Create 'Get Product Options Success' Action
 */
export class GetProductOptionsSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCT_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Product Options' Action
 */
export class ClearProductOptionsAction implements Action {
    type = ActionTypes.CLEAR_PRODUCT_OPTIONS;

    constructor(public payload?: null) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Listed Product Changes History Request' Action
 */
export class GetListedProductChangesHistoryRequestAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_CHANGES_HISTORY_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Listed Product Changes History Success' Action
 * @param payload Contains object with product changes
 */
export class GetListedProductChangesHistorySuccessAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_CHANGES_HISTORY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Listed Product Changes History Error' Action
 * @param payload Contains object with errors
 */
export class GetListedProductChangesHistoryErrorAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_CHANGES_HISTORY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Changes History' Action
 */
export class ClearChangesHistoryAction implements Action {
    type = ActionTypes.CLEAR_CHANGES_HISTORY;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Shares History Request' Action
 */
export class GetSharesHistoryRequestAction implements Action {
    type = ActionTypes.GET_SHARES_HISTORY_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Shares History Success' Action
 * @param payload Contains object with product changes
 */
export class GetSharesHistorySuccessAction implements Action {
    type = ActionTypes.GET_SHARES_HISTORY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Shares History Error' Action
 * @param payload Contains object with errors
 */
export class GetSharesHistoryErrorAction implements Action {
    type = ActionTypes.GET_SHARES_HISTORY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Shares History' Action
 */
export class ClearSharesHistoryAction implements Action {
    type = ActionTypes.CLEAR_SHARES_HISTORY;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Product Changes' Action
 */
export class ClearProductChangesAction implements Action {
    type = ActionTypes.CLEAR_PRODUCT_CHANGES;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Reviews Request' Action
 */
export class GetListedProductReviewsRequestAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_REVIEWS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Reviews Success' Action
 * @param payload Contains object with product reviews
 */
export class GetListedProductReviewsSuccessAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_REVIEWS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Reviews Error' Action
 * @param payload Contains object with errors
 */
export class GetListedProductReviewsErrorAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_REVIEWS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Product Reviews' Action
 */
export class ClearListedProductReviewsAction implements Action {
    type = ActionTypes.CLEAR_LISTED_PRODUCT_REVIEWS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Summary Request' Action
 */
export class GetListedProductSummaryRequestAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_SUMMARY_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Summary Success' Action
 * @param payload Contains object with product summary
 */
export class GetListedProductSummarySuccessAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_SUMMARY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Summary Error' Action
 * @param payload Contains object with errors
 */
export class GetListedProductSummaryErrorAction implements Action {
    type = ActionTypes.GET_LISTED_PRODUCT_SUMMARY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Product Summary' Action
 */
export class ClearListedProductSummaryAction implements Action {
    type = ActionTypes.CLEAR_LISTED_PRODUCT_SUMMARY;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Internal Data Request' Action
 * @param payload GTIN string of requested product
 */
export class GetListedProductInternalDataRequestAction implements Action {
    type = ActionTypes.GET_PRODUCT_INTERNAL_DATA_REQUEST;

    constructor(
        public payload: {
            gtin: GtinId;
            company: CompanyId;
        },
    ) {}
}

/**
 * Create 'Get Product Internal Data Success' Action
 * @param payload Contains object with internal data
 */
export class GetListedProductInternalDataSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCT_INTERNAL_DATA_SUCCESS;

    constructor(public payload: any) {}
}

/**
 * Create 'Get Product Internal Data Error' Action
 * @param payload Contains object with errors
 */
export class GetListedProductInternalDataErrorAction implements Action {
    type = ActionTypes.GET_PRODUCT_INTERNAL_DATA_ERROR;

    constructor(public payload: any) {}
}

/**
 * Create 'Clear Product Internal Data' Action
 */
export class ClearListedProductInternalDataAction implements Action {
    type = ActionTypes.CLEAR_PRODUCT_INTERNAL_DATA;

    constructor(public payload?: any) {}
}

export type Actions =
    | InitializeListsViewStart
    | InitializeListsViewUpdate
    | InitializeListsViewEnd
    | SetSearchParams
    | RemoveSearchParams
    | ClearSearchParams
    | SetSearchId
    | ClearSearchId
    | UpdateSharedFurtherState
    | GetAllListedProducts
    | FetchAllListedProducts
    | GetListedProductsRequestAction
    | GetListedProductsSuccessAction
    | GetListedProductsErrorAction
    | GetListedProductRequestAction
    | GetListedProductSuccessAction
    | GetListedProductErrorAction
    | GetListedProductsCountRequestAction
    | GetListedProductsCountSuccessAction
    | GetListedProductsCountErrorAction
    | ClearCountersAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | GetProductOptionsRequestAction
    | GetProductOptionsSuccessAction
    | ClearProductOptionsAction
    | ClearManageAction
    | ClearListAction
    | ClearOptionsAction
    | GetListedProductChangesHistoryRequestAction
    | GetListedProductChangesHistorySuccessAction
    | GetListedProductChangesHistoryErrorAction
    | ClearChangesHistoryAction
    | GetSharesHistoryRequestAction
    | GetSharesHistorySuccessAction
    | GetSharesHistoryErrorAction
    | ClearSharesHistoryAction
    | GetListedProductReviewsRequestAction
    | GetListedProductReviewsSuccessAction
    | GetListedProductReviewsErrorAction
    | GetListedProductChangesHistoryRequestAction
    | ClearListedProductReviewsAction
    | GetListedProductSummaryRequestAction
    | GetListedProductSummarySuccessAction
    | GetListedProductSummaryErrorAction
    | ClearListedProductSummaryAction
    | GetListedProductInternalDataRequestAction
    | GetListedProductInternalDataSuccessAction
    | GetListedProductInternalDataErrorAction
    | ClearListedProductInternalDataAction;
