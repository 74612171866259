import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as fromAuth from '@core/reducers/auth.reducer';
import * as fromListedProducts from '@core/reducers/listed-products.reducer';
import * as fromWorkflowHistoryLogs from '@core/reducers/workflow-history-logs.reducer';

@Injectable()
export class WorkflowHistoryLogsSelectors {
    /**
     * Get state.workflows
     */
    getWorkflowState() {
        return ($state: State): any => $state.workflowHistoryLogs;
    }

    getListedProductState() {
        return ($state: State): any => $state.listedProducts;
    }

    getAuthState() {
        return ($state: State): any => $state.auth;
    }

    /**
     * Get list from state.workflowHistoryLogs
     */
    getHistoryList() {
        return createSelector(
            this.getWorkflowState(),
            fromWorkflowHistoryLogs.getList,
        );
    }

    getWorkflowIds() {
        return createSelector(
            this.getWorkflowState(),
            fromWorkflowHistoryLogs.getWorkflowIds,
        );
    }

    getSummary() {
        return createSelector(
            this.getListedProductState(),
            fromListedProducts.getListedProductSummary,
        );
    }

    getLocalUser() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getLocalUser,
        );
    }

    getModel() {
        return createSelector(
            this.getLocalUser(),
            this.getHistoryList(),
            this.getWorkflowIds(),
            this.getSummary(),
            (localUser, historyList, workflowIds, summary) => {
                return {
                    localUser,
                    historyList,
                    workflowIds,
                    summary,
                    isFetching: historyList.isFetching || summary.isFetching || workflowIds.isFetching,
                };
            },
        );
    }
}
