import * as usersActions from '../actions/users.actions';
import { UsersHardLogout, UsersList, UsersManage, UsersOptions } from '../models';

export interface State {
    manage: UsersManage;
    list: UsersList;
    hardLogout: UsersHardLogout;
    options: UsersOptions;
}

export const initialState: State = {
    manage: {
        data: {
            company: null,
            defaultLanguage: 'en',
            department: '',
            email: '',
            enableProductWorkflowNotification: false,
            firstName: '',
            group: null,
            id: null,
            isActive: false,
            isLoggedIn: false,
            isSuspended: false,
            lastName: '',
            modified: '',
            phoneNumber: null,
            publicApiKey: null,
            statusDisplay: '',
            title: '',
            uaePassId: '',
        },
        errors: {},
        isFetching: true,
        isSaving: false,
        apiKeyFetching: false,
    },
    list: {
        data: {
            results: [],
            previous: null,
            count: null,
            next: null,
        },
        errors: {},
        isFetching: true,
    },
    hardLogout: {
        errors: {},
        isFetching: false,
    },
    options: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: usersActions.Actions): State {
    switch (action.type) {
        case usersActions.ActionTypes.CLEAR_HARD_LOGOUT: {
            return {
                ...state,
                hardLogout: {
                    ...initialState.hardLogout,
                },
            };
        }

        case usersActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case usersActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case usersActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        case usersActions.ActionTypes.CREATE_USER_ERROR:
        case usersActions.ActionTypes.SAVE_USER_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case usersActions.ActionTypes.CREATE_USER_REQUEST:
        case usersActions.ActionTypes.SAVE_USER_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case usersActions.ActionTypes.CREATE_USER_SUCCESS:
        case usersActions.ActionTypes.SAVE_USER_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case usersActions.ActionTypes.DEACTIVATE_API_TOKEN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    apiKeyFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.DEACTIVATE_API_TOKEN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    apiKeyFetching: true,
                },
            };
        }

        case usersActions.ActionTypes.DEACTIVATE_API_TOKEN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: {
                        ...state.manage.data,
                        publicApiKey: null,
                    },
                    apiKeyFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.GENERATE_API_TOKEN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    apiKeyFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.GENERATE_API_TOKEN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    apiKeyFetching: true,
                },
            };
        }

        case usersActions.ActionTypes.GENERATE_API_TOKEN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: {
                        ...state.manage.data,
                        publicApiKey: action.payload.key,
                    },
                    apiKeyFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: {},
                    isFetching: true,
                },
            };
        }

        case usersActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.GET_USER_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.GET_USER_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                    isSaving: false,
                },
            };
        }

        case usersActions.ActionTypes.GET_USER_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.GET_USERS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.GET_USERS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case usersActions.ActionTypes.GET_USERS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.HARD_LOGOUT_ERROR: {
            return {
                ...state,
                hardLogout: {
                    ...state.hardLogout,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.HARD_LOGOUT_REQUEST: {
            return {
                ...state,
                hardLogout: {
                    ...state.hardLogout,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case usersActions.ActionTypes.HARD_LOGOUT_SUCCESS: {
            return {
                ...state,
                hardLogout: {
                    ...state.hardLogout,
                    isFetching: false,
                },
            };
        }

        case usersActions.ActionTypes.NEW_USER: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: false,
                    isSaving: false,
                },
            };
        }

        case usersActions.ActionTypes.RESEND_REGISTRATION_EMAIL_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case usersActions.ActionTypes.RESEND_REGISTRATION_EMAIL_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case usersActions.ActionTypes.RESEND_REGISTRATION_EMAIL_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                },
            };
        }

        case usersActions.ActionTypes.VALIDATE_USER_EMAIL_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                },
            };
        }

        case usersActions.ActionTypes.VALIDATE_USER_EMAIL_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {
                        ...state.manage.errors,
                        ...action.payload,
                    },
                },
            };
        }

        default: {
            return state;
        }
    }
}
export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsFetching = (state: State) => state.manage.isFetching;
export const getManageIsSaving = (state: State) => state.manage.isSaving;

export const getHardLogout = (state: State) => state.hardLogout;
export const getHardLogoutErrors = (state: State) => state.hardLogout.errors;
export const getHardLogoutIsFetching = (state: State) => state.hardLogout.isFetching;

export const getOptions = (state: State) => state.options;
export const getOptionsData = (state: State) => state.options.data;
export const getOptionsIsFetching = (state: State) => state.options.isFetching;
