
<div
  class="select"
  #selection
  [attr.tabindex]="disabled || readonly ? -1 : 0"
  [ngClass]="{
    'select--open': isOpen,
    'select--focus': hasFocus,
    'select--show-above': showAbove,
    'select--disabled': disabled,
    'select--readonly': readonly,
    'select--error': selectError,
    'select--searching': dynamicOptions,
    'select--fluid': classFluid,
    'select--addon': addon,
    'select--addon-left': addon === 'left',
    'select--addon-right': addon === 'right'
  }"
  (click)="onSelectContainerClick($event)"
  (focus)="onSelectContainerFocus()"
  (keydown)="onSelectContainerKeydown($event)"
  (window:resize)="onWindowResize()"
>
  <div
    *ngIf="!multiple"
    class="select__single"
  >
    <ng-container *ngIf="optionList">
      <div
        *ngIf="optionList.hasSelected()"
        class="select__value"
      >
        <ng-template #defaultLabelTemplate>
          {{ optionList.selection[0].displayName }}
        </ng-template>

        <ng-template
          [ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"
          [ngTemplateOutletContext]="{ option: optionList.selection[0] }"
        ></ng-template>
      </div>

      <div
        *ngIf="!optionList.hasSelected()"
        class="select__placeholder"
      >
        <ng-template #defaultPlaceholderTemplate>
          {{ placeholderView }}
        </ng-template>

        <ng-template
          [ngTemplateOutlet]="placeholderTemplate || defaultPlaceholderTemplate"
          [ngTemplateOutletContext]="{ placeholder: placeholderView }"
        ></ng-template>
      </div>
    </ng-container>

    <div
      class="select__clear-button"
      *ngIf="allowClear && hasSelected"
      (click)="onClearSelectionClick($event)"
    >
      <span class="select__clear-button-text">Clear</span>
    </div>

    <div
      class="select__toggle-button"
      [ngClass]="{'select__toggle-button--open': isOpen}"
    >
      <span class="select__toggle-button-text">Open/Close</span>
    </div>
  </div>

  <div
    *ngIf="multiple"
    class="select__multiple"
  >
    <div
      *ngFor="let option of optionList.selection"
      class="select__tag"
    >
      {{ option.displayName }}
      <span
        class="select__tag-close icon-cross"
        (click)="onDeselectOptionClick(option, $event)"
      ></span>
    </div>

    <div
      *ngIf="!value?.length && !searchInputValue?.length"
      class="select__placeholder select__placeholder--multiple"
    >
      {{ placeholderView }}
    </div>

    <input
      *ngIf="filterEnabled"
      #filterInput
      tabindex="-1"
      class="input select__multiple-input"
      [ngStyle]="{'width.px': filterInputWidth}"
      (input)="onMultipleFilterInput($event)"
      (keydown)="onMultipleFilterKeydown($event)"
    >

    <div
      class="select__toggle-button"
      [ngClass]="{ 'select__toggle-button--open': isOpen }"
    >
      <span class="select__toggle-button-text">Open/Close</span>
    </div>
  </div>
</div>

<select-dropdown
  *ngIf="isOpen"
  #dropdown
  [multiple]="multiple"
  [optionList]="optionList"
  [notFoundMsg]="notFoundMsg"
  [defaultMsg]="defaultMsg"
  [highlightColor]="highlightColor"
  [highlightTextColor]="highlightTextColor"
  [filterEnabled]="filterEnabled"
  [inputPristine]="inputPristine"
  [isFetching]="isFetching"
  [optionTemplate]="optionTemplate"
  [showAbove]="showAbove"
  [style.top.px]="top"
  [canLoadMore]="canLoadMore"
  (close)="onDropdownClose($event)"
  (optionClicked)="onDropdownOptionClicked($event)"
  (singleFilterClick)="onSingleFilterClick()"
  (singleFilterInput)="onSingleFilterInput($event)"
  (singleFilterKeydown)="onSingleFilterKeydown($event)"
  (loadMore)="loadMore.emit()"
></select-dropdown>
