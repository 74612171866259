import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { AnyErrors } from '@core/actions/common.actions';
import * as companyDomains from '../actions/company-domains.actions';

import { CompanyDomainsService } from '../services/company-domains.service';
import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class CompanyDomainsEffects {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private actions$: Actions,
        private companyDomainsService: CompanyDomainsService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
    ) {}

    @Effect()
    getCompanyDomainsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companyDomains.ActionTypes.GET_COMPANY_DOMAINS_REQUEST),
        map((action: companyDomains.GetCompanyDomainsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDomainsService.getCompanyDomains(payload).pipe(
                map((response) => new companyDomains.GetCompanyDomainsSuccessAction(response)),
                catchError((error) =>
                    of(new companyDomains.GetCompanyDomainsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    getCompanyDomainRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companyDomains.ActionTypes.GET_COMPANY_DOMAIN_REQUEST),
        map((action: companyDomains.GetCompanyDomainRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDomainsService.getCompanyDomain(payload).pipe(
                map((response) => new companyDomains.GetCompanyDomainSuccessAction(response)),
                catchError((error) =>
                    of(new companyDomains.GetCompanyDomainErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    saveCompanyDomainRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companyDomains.ActionTypes.SAVE_COMPANY_DOMAIN_REQUEST),
        map((action: companyDomains.SaveCompanyDomainRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDomainsService.saveCompanyDomain(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved domain ${(response as any).name}`);
                }),
                map((response) => new companyDomains.SaveCompanyDomainSuccessAction(response)),
                catchError((error) =>
                    of(new companyDomains.SaveCompanyDomainErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    createCompanyDomainRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companyDomains.ActionTypes.CREATE_COMPANY_DOMAIN_REQUEST),
        map((action: companyDomains.CreateCompanyDomainRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDomainsService.createCompanyDomain(payload).pipe(
                tap((response) => this.notificationsService.success(`Added domain ${(response as any).name}`)),
                tap(() => {
                    if (payload.redirectTo) {
                        this.router.navigate(payload.redirectTo, { queryParams: payload.queryParams });
                    }
                }),
                map((response) => new companyDomains.CreateCompanyDomainSuccessAction(response)),
                catchError((error) =>
                    of(new companyDomains.CreateCompanyDomainErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    deleteCompanyDomainRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companyDomains.ActionTypes.DELETE_COMPANY_DOMAIN_REQUEST),
        map((action: companyDomains.DeleteCompanyDomainRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDomainsService.deleteCompanyDomain(payload.id).pipe(
                tap(
                    () => {
                        if (payload.redirectTo) {
                            this.router.navigate(payload.redirectTo, { queryParams: payload.queryParams });
                        }
                    },
                    () => this.modalService.close('delete-company-domain-modal'),
                ),
                tap(() => this.notificationsService.success(`Deleted company domain`)),
                map(() => new companyDomains.DeleteCompanyDomainSuccessAction()),
                catchError((error) =>
                    of(new companyDomains.DeleteCompanyDomainErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    getOptionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companyDomains.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap((payload) => {
            return this.companyDomainsService.getOptions().pipe(
                map((response) => new companyDomains.GetOptionsSuccessAction((response as any).actions.POST)),
                catchError((error) => of(new AnyErrors(error))),
            );
        }),
    );
}
