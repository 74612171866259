<input
  placeholder=""
  autocorrect="off"
  autocapitalize="off"
  spellcheck="off"
  type="text"
  class="input input--fluid"
  [ngClass]="{'input--readonly': readonly}"
  [readonly]="readonly"
  #search>
