import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as actions from '@core/actions/company-internal-data.actions';

import { ModalService } from '@app/core/services/modal.service';
import { CompanyInternalDataService } from '@core/services/company-internal-data.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class CompanyInternalDataEffects {
    constructor(
        private actions$: Actions,
        private companyInternalDataService: CompanyInternalDataService,
        private modalService: ModalService,
    ) {}

    @Effect()
    getInternalImportTemplate$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.GET_IMPORT_TEMPLATE_REQUEST),
        map((action: actions.GetImportTemplateRequest) => action.payload),
        switchMap((payload) => {
            return this.companyInternalDataService.getCompanyInternalTemplate(payload).pipe(
                tap((response) => (window.location.href = (response as any).template)),
                map((response) => new actions.GetImportTemplateSuccess(response as any)),
                catchError((error) => of(new actions.GetImportTemplateError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    uploadImportFilesRequest$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.UPLOAD_IMPORT_FILES_REQUEST),
        map((action: actions.UploadImportFilesRequest) => action.payload),
        switchMap((payload) => {
            return this.companyInternalDataService.uploadInternalDataTemplate(payload.formData).pipe(
                tap(() => this.modalService.open(payload.modalId)),
                map((response) => new actions.UploadImportFilesSuccess(response as any)),
                catchError((error) => of(new actions.UploadImportFilesError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );
}
