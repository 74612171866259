import { Action } from '@ngrx/store';

import { prepareActions } from '@app/utils';
import {
    DataRequirementsHistoryLogsPayload,
    DataRequirementsSummary,
    GetDataRequirementsSummaryPayload,
} from '@core/models';

export const ActionTypes = prepareActions('[Company Data Requirements]', [
    'GET_AVAILABLE_ATTRIBUTES_REQUEST',
    'GET_AVAILABLE_ATTRIBUTES_SUCCESS',
    'GET_AVAILABLE_ATTRIBUTES_ERROR',
    'CLEAR_AVAILABLE_ATTRIBUTES',

    'GET_LIST_REQUEST',
    'GET_LIST_SUCCESS',
    'GET_LIST_ERROR',
    'CLEAR_LIST',

    'CREATE_REQUEST',
    'CREATE_SUCCESS',
    'CREATE_ERROR',

    'GET_REQUIREMENT_REQUEST',
    'GET_REQUIREMENT_SUCCESS',
    'GET_REQUIREMENT_ERROR',
    'CLEAR_REQUIREMENT',

    'UPDATE_REQUEST',
    'UPDATE_SUCCESS',
    'UPDATE_ERROR',

    'DELETE_REQUEST',
    'DELETE_SUCCESS',
    'DELETE_ERROR',

    'BULK_UPDATE_STATUS_REQUEST',
    'BULK_UPDATE_STATUS_SUCCESS',
    'BULK_UPDATE_STATUS_ERROR',

    'BULK_DELETE_REQUEST',
    'BULK_DELETE_SUCCESS',
    'BULK_DELETE_ERROR',

    'GET_SUMMARY_REQUEST',
    'GET_SUMMARY_SUCCESS',
    'GET_SUMMARY_ERROR',
    'CLEAR_SUMMARY',

    'GET_HISTORY_LOGS_REQUEST',
    'GET_HISTORY_LOGS_SUCCESS',
    'GET_HISTORY_LOGS_ERROR',
    'CLEAR_HISTORY_LOGS',
]);

/**
 * Create 'Get Available Attributes Request' Action
 */
export class GetAvailableAttributesRequestAction implements Action {
    type = ActionTypes.GET_AVAILABLE_ATTRIBUTES_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Available Attributes Success' Action
 */
export class GetAvailableAttributesSuccessAction implements Action {
    type = ActionTypes.GET_AVAILABLE_ATTRIBUTES_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Available Attributes Success' Action
 */
export class GetAvailableAttributesErrorAction implements Action {
    type = ActionTypes.GET_AVAILABLE_ATTRIBUTES_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Available Attributes' Action
 */
export class ClearAvailableAttributesAction implements Action {
    type = ActionTypes.CLEAR_AVAILABLE_ATTRIBUTES;
    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Data Requirements Request' Action
 */
export class CreateRequestAction implements Action {
    type = ActionTypes.CREATE_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Data Requirements Success' Action
 */
export class CreateSuccessAction implements Action {
    type = ActionTypes.CREATE_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Data Requirements Error' Action
 */
export class CreateErrorAction implements Action {
    type = ActionTypes.CREATE_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Data Requirements Request' Action
 */
export class GetListRequestAction implements Action {
    type = ActionTypes.GET_LIST_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Data Requirements Success' Action
 */
export class GetListSuccessAction implements Action {
    type = ActionTypes.GET_LIST_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Data Requirements Error' Action
 */
export class GetListErrorAction implements Action {
    type = ActionTypes.GET_LIST_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Data Requirements  Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Data Requirement Request' Action
 */
export class GetRequirementRequestAction implements Action {
    type = ActionTypes.GET_REQUIREMENT_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Data Requirement Success' Action
 */
export class GetRequirementSuccessAction implements Action {
    type = ActionTypes.GET_REQUIREMENT_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Data Requirement Error' Action
 */
export class GetRequirementErrorAction implements Action {
    type = ActionTypes.GET_REQUIREMENT_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Data Requirement' Action
 */
export class ClearRequirementAction implements Action {
    type = ActionTypes.CLEAR_REQUIREMENT;
    constructor(public payload?: any) {}
}

/**
 * Create 'Update Company Data Requirement Request' Action
 */
export class UpdateRequestAction implements Action {
    type = ActionTypes.UPDATE_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Update Company Data Requirement Success' Action
 */
export class UpdateSuccessAction implements Action {
    type = ActionTypes.UPDATE_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Update Company Data Requirement Error' Action
 */
export class UpdateErrorAction implements Action {
    type = ActionTypes.UPDATE_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Data Requirement Request' Action
 */
export class DeleteRequestAction implements Action {
    type = ActionTypes.DELETE_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Data Requirement Success' Action
 */
export class DeleteSuccessAction implements Action {
    type = ActionTypes.DELETE_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Data Requirement Error' Action
 */
export class DeleteErrorAction implements Action {
    type = ActionTypes.DELETE_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Update Company Data Requirement Status Request' Action
 */
export class BulkUpdateStatusRequestAction implements Action {
    type = ActionTypes.BULK_UPDATE_STATUS_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Update Company Data Requirement Status Success' Action
 */
export class BulkUpdateStatusSuccessAction implements Action {
    type = ActionTypes.BULK_UPDATE_STATUS_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Update Company Data Requirement Status Error' Action
 */
export class BulkUpdateStatusErrorAction implements Action {
    type = ActionTypes.BULK_UPDATE_STATUS_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Delete Company Data Requirement Request' Action
 */
export class BulkDeleteRequestAction implements Action {
    type = ActionTypes.BULK_DELETE_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Delete Company Data Requirement Success' Action
 */
export class BulkDeleteSuccessAction implements Action {
    type = ActionTypes.BULK_DELETE_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Delete Company Data Requirement Error' Action
 */
export class BulkDeleteErrorAction implements Action {
    type = ActionTypes.BULK_DELETE_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Data Requirements Summary Request' Action
 * @param payload Contains company field needed to send reqeust for data requirements stats
 */
export class GetSummaryRequest implements Action {
    type = ActionTypes.GET_SUMMARY_REQUEST;
    constructor(public payload: GetDataRequirementsSummaryPayload) {}
}

/**
 * Create 'Get Data Requirements Summary Success' Action
 * @param payload Contains DataRequirementsSummary object with stats for data requirements
 */
export class GetSummarySuccess implements Action {
    type = ActionTypes.GET_SUMMARY_SUCCESS;
    constructor(public payload: DataRequirementsSummary) {}
}

/**
 * Create 'Get Data Requirements Summary Error' Action
 * @param payload Contains errors object
 */
export class GetSummaryError implements Action {
    type = ActionTypes.GET_SUMMARY_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Clear Data Requirements Summary' Action
 * @param payload Unnecessary
 */
export class ClearSummary implements Action {
    type = ActionTypes.CLEAR_SUMMARY;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get History Logs Request' Action
 * @param payload Contains params (company, partner) needed to send reqeust for partner requirements
 */
export class GetHistoryLogsRequestAction implements Action {
    type = ActionTypes.GET_HISTORY_LOGS_REQUEST;
    constructor(public payload: DataRequirementsHistoryLogsPayload) {}
}

/**
 * Create 'Get History Logs Success' Action
 * @param payload Contains object with history logs for specific partner
 */
export class GetHistoryLogsSuccessAction implements Action {
    type = ActionTypes.GET_HISTORY_LOGS_SUCCESS;
    constructor(public payload: any) {}
}

/**
 * Create 'Get History Logs Error' Action
 * @param payload Contains errors object
 */
export class GetHistoryLogsErrorAction implements Action {
    type = ActionTypes.GET_HISTORY_LOGS_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Clear History Logs' Action
 * @param payload Unnecessary
 */
export class ClearHistoryLogs implements Action {
    type = ActionTypes.CLEAR_HISTORY_LOGS;
    constructor(public payload?: any) {}
}

export type Actions =
    | GetAvailableAttributesRequestAction
    | GetAvailableAttributesSuccessAction
    | GetAvailableAttributesErrorAction
    | ClearAvailableAttributesAction
    | GetListRequestAction
    | GetListSuccessAction
    | GetListErrorAction
    | ClearListAction
    | CreateRequestAction
    | CreateSuccessAction
    | CreateErrorAction
    | GetRequirementRequestAction
    | GetRequirementSuccessAction
    | GetRequirementErrorAction
    | ClearRequirementAction
    | UpdateRequestAction
    | UpdateSuccessAction
    | UpdateErrorAction
    | DeleteRequestAction
    | DeleteSuccessAction
    | DeleteErrorAction
    | BulkUpdateStatusRequestAction
    | BulkUpdateStatusSuccessAction
    | BulkUpdateStatusErrorAction
    | BulkDeleteRequestAction
    | BulkDeleteSuccessAction
    | BulkDeleteErrorAction
    | GetSummaryRequest
    | GetSummarySuccess
    | GetSummaryError
    | ClearSummary
    | GetHistoryLogsRequestAction
    | GetHistoryLogsSuccessAction
    | GetHistoryLogsErrorAction
    | ClearHistoryLogs;
