<label
  [for]="label"
  class="form__label label"
  [ngClass]="{'label--required': required}">
  {{ label }}
</label>
<!-- TODO: For refactoring. The component should be universal, it can be placed everywhere. It shouldn't define the layout in itself. -->
<div class="form__row">
  <div
    class="form__group form__group--cols-{{ columnWidth }}">
    <input
      *ngIf="type === 'text'"
      type="text"
      class="input input--fluid"
      [id]="label"
      [value]="value"
      [readonly]="readonly"
      (keyup)="onInputChange($event)"
      [placeholder]="placeholder"
      [ngClass]="{
        'input--readonly': readonly,
        'input--error': (!isValid && isTouched) || errors,
        'input--valid': isValid && isTouched && !errors
      }">

    <intl-tel
      *ngIf="type === 'telephone'"
      [readonly]="readonly"
      (onNumberChange)="onTelephoneChange($event)"
      [control]="control"
    ></intl-tel>

    <ul class="errors errors--inside">
      <li
        *ngIf="control?.hasError('gtinUniqueness') && control.touched"
        class="errors__single-error"
      >
        GTIN not unique in: {{ control.errors.gtinUniqueness }}
      </li>
      <li
        *ngIf="
          required &&
          !isValid &&
          isTouched &&
          (!value || !value.length)
        "
        class="errors__single-error">
        Field is required.
      </li>
      <li
        *ngIf="
          !control?.hasError('gtinUniqueness') &&
          !errors &&
          isTouched &&
          !isValid &&
          (value || value.length)
        "
        class="errors__single-error">
        {{ errorMessage || 'Field is not valid' }}
      </li>

      <ng-container *ngIf="errors">
        <li
          *ngFor="let error of errors"
          class="errors__single-error">
          {{ error }}
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="form__group form-input__message form__group--cols-3">
    <p
      *ngIf="
        !errors &&
        isTouched &&
        isValid &&
        value &&
        value.length
      "
      class="form-input__success">
      <span class="form-input__success-icon icon-success"></span>
      <span *ngIf="successMessage.length">
        {{ successMessage }}
      </span>
    </p>
    <span
      *ngIf="
        isTouched &&
        (!isValid || errors)
      "
      class="form-input__error-icon icon-cancel">
    </span>
  </div>
</div>
