import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class ProductsReadOwnGuard extends BaseGuard {
    path = ['products', 'readOwn'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class ProductsManageOwnGuard extends BaseGuard {
    path = ['products', 'manageOwn'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class ProductsManageGuard extends BaseGuard {
    path = ['products', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class ProductsAnnotateGuard extends BaseGuard {
    path = ['products', 'annotate'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class NotProductsAutomateAnnotateGuard extends BaseGuard {
    public isNegative = true;
    public path = ['products', 'automateAnnotate'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class ProductsImportGuard extends BaseGuard {
    path = ['products', 'import'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class ProductsExportGuard extends BaseGuard {
    path = ['products', 'export'];
    constructor(injector: Injector) {
        super(injector);
    }
}
