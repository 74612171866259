import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class NewsManageGuard extends BaseGuard {
    path = ['news', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}
