import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromAttributes from '../reducers/attributes.reducer';

@Injectable()
export class AttributesSelectors {
    /**
     * Get state.attributes
     */
    getAttributesState() {
        return ($state: State): any => $state.attributes;
    }

    /**
     * Get list from state.attributes
     */
    getList() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getList,
        );
    }

    /**
     * Get list.data from state.attributes
     */
    getListData() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getListData,
        );
    }

    /**
     * Get list.errors from state.attributes
     */
    getListErrors() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.attributes
     */
    getListIsFetching() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getListIsFetching,
        );
    }

    /**
     * Get manage from state.attributes
     */
    getManage() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getManage,
        );
    }

    /**
     * Get manage.data from state.attributes
     */
    getManageData() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getManageData,
        );
    }

    /**
     * Get manage.errors from state.attributes
     */
    getManageErrors() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getManageErrors,
        );
    }

    /**
     * Get manage.isFetching from state.attributes
     */
    getManageIsFetching() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getManageIsFetching,
        );
    }

    /**
     * Get manage.isSaving from state.attributes
     */
    getManageIsSaving() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getManageIsSaving,
        );
    }

    /**
     * Get importTemplate from attributes
     */
    getImportTemplate() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getImportTemplate,
        );
    }

    /**
     * Get importTemplate.data from attributes
     */
    getImportTemplateData() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getImportTemplateData,
        );
    }

    /**
     * Get importTemplate.errors from attributes
     */
    getImportTemplateErrors() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getImportTemplateErrors,
        );
    }

    /**
     * Get importTemplate.isFetching from attributes
     */
    getImportTemplateIsFetching() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getImportTemplateIsFetching,
        );
    }

    /**
     * Get prepareImport from attributes
     */
    getPrepareImport() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getPrepareImport,
        );
    }

    /**
     * Get prepareImport.data from attributes
     */
    getPrepareImportData() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getPrepareImportData,
        );
    }

    /**
     * Get prepareImport.errors from attributes
     */
    getPrepareImportErrors() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getPrepareImportErrors,
        );
    }

    /**
     * Get prepareImport.isFetching from attributes
     */
    getPrepareImportIsFetching() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getPrepareImportIsFetching,
        );
    }

    /**
     * Get importStatus from attributes
     */
    getImportStatus() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getImportStatus,
        );
    }

    /**
     * Get importStatus.data from attributes
     */
    getImportStatusData() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getImportStatusData,
        );
    }

    /**
     * Get importStatus.errors from attributes
     */
    getImportStatusErrors() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getImportStatusErrors,
        );
    }

    /**
     * Get importStatus.isFetching from attributes
     */
    getImportStatusIsFetching() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getImportStatusIsFetching,
        );
    }

    /**
     * Get options from state.attributes
     */
    getOptions() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getOptions,
        );
    }

    /**
     * Get options.data from state.attributes
     */
    getOptionsData() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getOptionsData,
        );
    }

    /**
     * Get options.isFetching from state.attributes
     */
    getOptionsIsFetching() {
        return createSelector(
            this.getAttributesState(),
            fromAttributes.getOptionsIsFetching,
        );
    }
}
