import { EMPTY as empty, forkJoin as observableForkJoin, from, Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, delay, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import * as listedProducts from '@core/actions/listed-products.actions';
import * as fromRoot from '@core/reducers';

import { AuthSelectors } from '@core/selectors/auth.selectors';
import { ListedProductsSelectors } from '@core/selectors/listed-products.selectors';

import { ListedProductsService } from '@core/services/listed-products.service';

import {
    CompanyRole,
    InitializeLists,
    ListedProductsListType,
    ListedProductsSearchParams,
    ListedProductsUI,
    SearchParamsActionSource,
} from '@core/models';

import { convertWrongParsedQuery, removeFieldsFromObject } from '@app/utils';

import { extendProductDetails, extendProductListDetails } from '@products/utils';

import { catchErrorHttpClientJson } from './catch-error';

@Injectable()
export class ListedProductsEffects {
    constructor(
        private actions$: Actions,
        private authSelectors: AuthSelectors,
        private listedProductsSelectors: ListedProductsSelectors,
        private listedProductsService: ListedProductsService,
        private router: Router,
        private store: Store<fromRoot.State>,
    ) {}

    @Effect()
    initializeListsViewStart$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.INITIALIZE_LISTS_VIEW_START),
        map((action: listedProducts.InitializeListsViewStart) => action.payload),
        switchMap((payload) => of(new listedProducts.GetAllListedProducts(payload))),
    );

    @Effect()
    getAllListedProducts$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_ALL_LISTED_PRODUCTS),
        map((action: listedProducts.GetAllListedProducts) => action.payload),
        mergeMap((listKeys) =>
            from(listKeys.map((payload) => new listedProducts.GetListedProductsRequestAction(payload))),
        ),
    );

    @Effect()
    fetchAllListedProducts$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.FETCH_ALL_LISTED_PRODUCTS),
        map((action: listedProducts.FetchAllListedProducts) => action.payload),
        withLatestFrom(
            this.store.select(this.authSelectors.getLocalUser()),
            this.store.select(this.listedProductsSelectors.getSearchParams()),
            this.store.select(this.listedProductsSelectors.getPageUIPart('sharedFurther')) as Observable<
                ListedProductsUI['sharedFurther']
            >,
            (payload, localUser, searchParams, sharedFurther) => ({
                payload,
                localUser,
                searchParams,
                sharedFurther,
            }),
        ),
        mergeMap(({ searchParams, localUser, sharedFurther }) => {
            const commonPayload = {
                ...searchParams.params,
                ...(localUser.data.company.role === CompanyRole.DISTRIBUTOR ? { sharedFurther } : {}),
                ...(this.router.url.includes('my-listings')
                    ? { showOnlyMyProducts: true }
                    : { showOnlyMyProducts: false }),
            };

            const payload: Array<{
                listType?: ListedProductsListType;
                request: Partial<ListedProductsSearchParams>;
            }> = [
                {
                    listType: 'list',
                    request: {
                        ...commonPayload,
                    },
                },
                {
                    listType: 'listApproved',
                    request: {
                        workflowStatus: 'a',
                        ...commonPayload,
                    },
                },
                {
                    listType: 'listRejected',
                    request: {
                        workflowStatus: 'r',
                        ...commonPayload,
                    },
                },
                {
                    listType: 'listPending',
                    request: {
                        workflowStatus: 'i',
                        ...commonPayload,
                    },
                },
                {
                    listType: 'listMy',
                    request: {
                        pendingMyApproval: true,
                        ...commonPayload,
                    },
                },
            ];
            return of(new listedProducts.GetAllListedProducts(payload));
        }),
        catchError((error) => of(new listedProducts.GetListedProductsErrorAction(catchErrorHttpClientJson(error)))),
    );

    @Effect()
    getListedProductsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_LISTED_PRODUCTS_REQUEST),
        map((action: listedProducts.GetListedProductsRequestAction) => action.payload),
        mergeMap(({ listType, request }) => {
            return this.listedProductsService.getListedProducts(request).pipe(
                withLatestFrom(this.store.select(this.listedProductsSelectors.getInitializeLists())),
                mergeMap(([response, initializeLists]: [any, InitializeLists]) => {
                    response = extendProductListDetails(response);
                    const actions: listedProducts.Actions[] = [
                        new listedProducts.GetListedProductsSuccessAction({
                            listType,
                            response,
                        }),
                    ];
                    const listTypes = [...initializeLists.listTypes];
                    const listTypesFetched = [...initializeLists.listTypesFetched];
                    if (listTypes.includes(listType) && !listTypesFetched.includes(listType)) {
                        listTypesFetched.push(listType);
                        actions.push(new listedProducts.InitializeListsViewUpdate(listType));
                    }

                    if (!initializeLists.allFetched && listTypes.every((type) => listTypesFetched.includes(type))) {
                        actions.push(new listedProducts.InitializeListsViewEnd());
                    }

                    if (listType === 'list') {
                        if (request.searchId) {
                            actions.push(
                                new listedProducts.SetSearchParams({
                                    actionSource: SearchParamsActionSource.INIT,
                                    params: removeFieldsFromObject(
                                        [
                                            'offset',
                                            'limit',
                                            'ordering',
                                            'sharedFurther',
                                            'savePreferences',
                                            'workflowStatus',
                                        ],
                                        convertWrongParsedQuery(response.searchParams),
                                    ),
                                }),
                            );
                        }
                        actions.push(new listedProducts.SetSearchId(response.searchId));
                    }

                    return from(actions);
                }),
                catchError((error) =>
                    of(
                        new listedProducts.GetListedProductsErrorAction({
                            listType,
                            response: catchErrorHttpClientJson(error),
                        }),
                    ),
                ),
            );
        }),
    );

    @Effect()
    getListedProductRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_LISTED_PRODUCT_REQUEST),
        map((action: listedProducts.GetListedProductRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.listedProductsService.getListedProduct(payload).pipe(
                map((response) => new listedProducts.GetListedProductSuccessAction(extendProductDetails(response))),
                catchError((error) =>
                    of(new listedProducts.GetListedProductErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getListedProductsCountRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_LISTED_PRODUCTS_COUNT_REQUEST),
        map((action: listedProducts.GetListedProductsRequestAction) => action.payload),
        mergeMap((payload: any) => {
            const defaultParams = {
                pendingMyApproval: null,
                workflowStatus: null,
                workflowType: (payload && payload.workflowType) || null,
                limit: 0,
                savePreferences: 0,
            };

            return observableForkJoin([
                this.listedProductsService.getListedProducts({
                    ...payload,
                    ...defaultParams,
                }),
                this.listedProductsService.getListedProducts({
                    ...payload,
                    ...defaultParams,
                    workflowStatus: 'i',
                }),
                this.listedProductsService.getListedProducts({
                    ...payload,
                    ...defaultParams,
                    workflowStatus: 'r',
                }),
                this.listedProductsService.getListedProducts({
                    ...payload,
                    ...defaultParams,
                    workflowStatus: 'a',
                }),
                this.listedProductsService.getListedProducts({
                    ...payload,
                    ...defaultParams,
                    pendingMyApproval: true,
                    showOnlyMyProducts: false,
                }),
            ]).pipe(
                map((response) => response.map((current) => current.count)),
                map(([all, pending, rejected, approved, my]) => {
                    return new listedProducts.GetListedProductsCountSuccessAction({
                        all,
                        pending,
                        rejected,
                        approved,
                        my,
                    });
                }),
                catchError((error) =>
                    of(new listedProducts.GetListedProductsCountErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getProductOptionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_PRODUCT_OPTIONS_REQUEST),
        map((action: listedProducts.GetProductOptionsRequestAction) => action.payload),
        switchMap((productId) => {
            return this.listedProductsService.getProductOptions(productId).pipe(
                map((productPayload) => new listedProducts.GetProductOptionsSuccessAction(productPayload)),
                catchError(() => empty),
            );
        }),
    );

    @Effect()
    getListedProductChangesHistoryRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_LISTED_PRODUCT_CHANGES_HISTORY_REQUEST),
        map((action: listedProducts.GetListedProductChangesHistoryRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.listedProductsService.getListedProductChanges(payload.id, payload.payload).pipe(
                map((response) => new listedProducts.GetListedProductChangesHistorySuccessAction(response)),
                catchError((error) =>
                    of(new listedProducts.GetListedProductChangesHistoryErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getSharesHistoryRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_SHARES_HISTORY_REQUEST),
        map((action: listedProducts.GetSharesHistoryRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.listedProductsService.getListedProductShares(payload).pipe(
                map((response) => new listedProducts.GetSharesHistorySuccessAction(response)),
                catchError((error) =>
                    of(new listedProducts.GetSharesHistoryErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getListedProductSummaryRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_LISTED_PRODUCT_SUMMARY_REQUEST),
        map((action: listedProducts.GetListedProductSummaryRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.listedProductsService.getListedProductSummary(payload).pipe(
                map((response) => new listedProducts.GetListedProductSummarySuccessAction(response)),
                catchError((error) =>
                    of(new listedProducts.GetListedProductSummaryErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getListedProductsInternalDataRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProducts.ActionTypes.GET_PRODUCT_INTERNAL_DATA_REQUEST),
        map((action: listedProducts.GetListedProductInternalDataRequestAction) => action.payload),
        switchMap((payload) => {
            return this.listedProductsService.getInternalDataPreview(payload).pipe(
                map((response) => new listedProducts.GetListedProductInternalDataSuccessAction(response)),
                catchError((error) =>
                    of(new listedProducts.GetListedProductInternalDataErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );
}
