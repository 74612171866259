import { Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'switch',
    styleUrls: ['./switch.component.scss'],
    templateUrl: './switch.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchComponent),
            multi: true,
        },
    ],
})
export class SwitchComponent implements ControlValueAccessor {
    private onTouchedCallback;
    private onChangeCallback;

    private waitForChangeCallback: boolean = false;

    public compChecked: boolean;
    private compDisabled: boolean;

    @Input() set checked(v: boolean | null) {
        this.compChecked = v === null ? false : v;
    }
    @Input() set disabled(v: boolean) {
        this.compDisabled = v !== false;
    }
    @Input() isReadonly: boolean = false;
    @Input() positiveLabel: string = 'Yes';
    @Input() negativeLabel: string = 'No';
    @Input() size: string = 'medium';
    @Output() change = new EventEmitter<boolean>();

    @HostListener('click') onToggle() {
        if (this.disabled) {
            return;
        }

        this.checked = !this.checked;
        this.change.emit(this.checked);
        if (typeof this.onChangeCallback === 'function') {
            this.onChangeCallback(this.checked);
        }
        if (typeof this.onTouchedCallback === 'function') {
            this.onTouchedCallback(this.checked);
        }
    }

    get checked() {
        return this.compChecked;
    }

    get disabled() {
        return this.compDisabled || this.isReadonly;
    }

    writeValue(value: boolean | null): void {
        if (value !== this.checked) {
            this.checked = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
        if (this.waitForChangeCallback) {
            this.waitForChangeCallback = false;
            this.onChangeCallback(this.compChecked);
        }
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}
