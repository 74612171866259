import * as authActions from '../actions/auth.actions';
import { LocalUser } from '../models';

import { ActivateAccount, NewPassword, SignIn, SignOut } from '../models';

export interface State {
    signIn: SignIn;
    signOut: SignOut;
    newPassword: NewPassword;
    activateAccount: ActivateAccount;
    isAuthenticated: boolean;
    localUser: LocalUser;
}

const initialBase = { errors: {}, isFetching: false };
const initialReadManageBase = { read: false, manage: false };

export const initialState: State = {
    signIn: { ...initialBase },
    signOut: { ...initialBase },
    newPassword: { ...initialBase },
    activateAccount: { ...initialBase },
    localUser: {
        data: {
            id: null,
            email: null,
            firstName: null,
            lastName: null,
            company: {
                canAnnotateInternalDataTemplate: false,
                canAnnotateProducts: false,
                currentPlan: null,
                hasActiveWorkflows: false,
                id: null,
                isStaff: false,
                name: null,
                role: null,
                subtype: null,
            },
        },
        profiles: [],
        permissions: {
            attributes: { ...initialReadManageBase, lock: false },
            attributeGroups: { ...initialReadManageBase },
            companies: { ...initialReadManageBase, readOwn: false, manageOwn: false },
            companyDomains: { ...initialReadManageBase },
            metrics: { ...initialReadManageBase },
            nutrients: { ...initialReadManageBase },
            plans: { ...initialReadManageBase },
            productCategories: { ...initialReadManageBase },
            productTemplates: { ...initialReadManageBase },
            products: {
                ...initialReadManageBase,
                annotate: false,
                automateAnnotate: false,
                deleteOwn: false,
                export: false,
                import: false,
                manageOwn: false,
                readOwn: false,
                share: false,
            },
            users: { ...initialReadManageBase, readOwn: false, manageOwn: false },
            groups: { ...initialReadManageBase, readOwn: false, manageOwn: false },
            userLogs: { read: false },
            currentUser: {
                displayConfiguration: false,
                displayMyListings: false,
                runMaintenanceTasks: false,
            },
            imagePlatform: { access: false },
            news: { manage: false },
            ingredients: { ...initialReadManageBase },
            workflows: { manage: false },
            publicApi: { access: false },
        },
        onboardingLogs: [],
        errors: {},
        isFetching: false,
    },
    isAuthenticated: false,
};

export function reducer(state = initialState, action: authActions.Actions): State {
    switch (action.type) {
        case authActions.ActionTypes.SIGN_IN_REQUEST: {
            return {
                ...state,
                signIn: {
                    ...state.signIn,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case authActions.ActionTypes.SIGN_IN_SUCCESS: {
            return {
                ...state,
                signIn: {
                    ...state.signIn,
                    isFetching: false,
                },
            };
        }

        case authActions.ActionTypes.SIGN_IN_ERROR: {
            return {
                ...state,
                signIn: {
                    ...state.signIn,
                    errors: (action as authActions.SignInErrorAction).payload,
                    isFetching: false,
                },
            };
        }

        case authActions.ActionTypes.NEW_PASSWORD_REQUEST: {
            return {
                ...state,
                newPassword: {
                    ...state.newPassword,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case authActions.ActionTypes.NEW_PASSWORD_SUCCESS: {
            return {
                ...state,
                newPassword: {
                    ...state.newPassword,
                    isFetching: false,
                },
            };
        }

        case authActions.ActionTypes.NEW_PASSWORD_ERROR: {
            return {
                ...state,
                newPassword: {
                    ...state.newPassword,
                    errors: (action as authActions.NewPasswordErrorAction).payload,
                    isFetching: false,
                },
            };
        }

        case authActions.ActionTypes.ACTIVATE_ACCOUNT_REQUEST: {
            return {
                ...state,
                activateAccount: {
                    ...state.activateAccount,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case authActions.ActionTypes.ACTIVATE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                activateAccount: {
                    ...state.activateAccount,
                    isFetching: false,
                },
            };
        }

        case authActions.ActionTypes.ACTIVATE_ACCOUNT_ERROR: {
            return {
                ...state,
                activateAccount: {
                    ...state.activateAccount,
                    errors: (action as authActions.ActivateAccountErrorAction).payload,
                    isFetching: false,
                },
            };
        }

        case authActions.ActionTypes.GET_LOCAL_USER_REQUEST: {
            return {
                ...state,
                localUser: {
                    ...state.localUser,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case authActions.ActionTypes.GET_LOCAL_USER_SUCCESS: {
            const payload = (action as authActions.GetLocalUserSuccessAction).payload;

            return {
                ...state,
                isAuthenticated: true,
                localUser: {
                    ...state.localUser,
                    data: {
                        id: payload.id,
                        email: payload.email,
                        firstName: payload.firstName,
                        lastName: payload.lastName,
                        company: { ...payload.company },
                    },
                    profiles: [ ...(payload.profiles || []) ],
                    permissions: { ...payload.permissions },
                    onboardingLogs: [...payload.onboardingLogs],
                    isFetching: false,
                },
            };
        }

        case authActions.ActionTypes.SAVE_ONBOARDING_LOG_SUCCESS: {
            return {
                ...state,
                localUser: {
                    ...state.localUser,
                    onboardingLogs: [
                        ...state.localUser.onboardingLogs,
                        (action as authActions.SaveOnboardingLogSuccess).payload,
                    ],
                },
            };
        }

        case authActions.ActionTypes.GET_LOCAL_USER_ERROR: {
            return {
                ...state,
                isAuthenticated: false,
                localUser: {
                    ...state.localUser,
                    errors: (action as authActions.GetLocalUserErrorAction).payload,
                    isFetching: false,
                },
            };
        }

        case authActions.ActionTypes.SIGN_OUT: {
            return {
                ...state,
                isAuthenticated: false,
                localUser: {
                    ...initialState.localUser,
                },
            };
        }

        case authActions.ActionTypes.CLEAR_SIGN_IN: {
            return {
                ...state,
                signIn: {
                    ...initialState.signIn,
                },
            };
        }

        case authActions.ActionTypes.CLEAR_SIGN_OUT: {
            return {
                ...state,
                signOut: {
                    ...initialState.signOut,
                },
            };
        }

        case authActions.ActionTypes.CLEAR_NEW_PASSWORD: {
            return {
                ...state,
                newPassword: {
                    ...initialState.newPassword,
                },
            };
        }

        case authActions.ActionTypes.CLEAR_ACTIVATE_ACCOUNT: {
            return {
                ...state,
                activateAccount: {
                    ...initialState.activateAccount,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getSignIn = (state: State) => state.signIn;
export const getSignInErrors = (state: State) => state.signIn.errors;

export const getNewPassword = (state: State) => state.newPassword;
export const getNewPasswordErrors = (state: State) => state.newPassword.errors;

export const getActivteAccount = (state: State) => state.activateAccount;
export const getActivteAccountErrors = (state: State) => state.activateAccount.errors;
export const getActivteAccountIsFetching = (state: State) => state.activateAccount.isFetching;

export const getLocalUser = (state: State) => state.localUser;
export const getLocalUserData = (state: State) => state.localUser.data;
export const getLocalUserPermissions = (state: State) => state.localUser.permissions;
export const getLocalUserErrors = (state: State) => state.localUser.errors;

export const getIsAuthenticated = (state: State) => state.isAuthenticated;
