import { Injector } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { SimpleUser } from '../models';
import * as fromRoot from '../reducers';
import { AuthSelectors } from '../selectors/auth.selectors';

export class LocalUserGuard implements CanActivate {
    router: Router;
    localUserData$: Observable<SimpleUser>;
    canActivate$: Observable<boolean>;
    path: string[] = [];

    constructor(private injector: Injector) {
        const store: Store<fromRoot.State> = injector.get(Store) as Store<fromRoot.State>;
        const authSelectors = injector.get(AuthSelectors);

        this.router = injector.get(Router);

        this.localUserData$ = store.select(authSelectors.getIsAuthenticated()).pipe(
            filter((isAuthenticated: boolean) => isAuthenticated),
            mergeMap(() => store.select(authSelectors.getLocalUserData())),
        );
    }

    checkUser(userData: SimpleUser): boolean {
        return !!userData.id;
    }

    redirectIfCantActivate(state: boolean): void {
        if (!state) {
            this.router.navigate(['/']);
        }
    }

    canActivate(): Observable<boolean> {
        return this.localUserData$.pipe(
            map(this.checkUser.bind(this)),
            tap(this.redirectIfCantActivate.bind(this)),
            map(Boolean),
        );
    }
}
