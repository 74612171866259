import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { SectionGroupEvent, SectionGroupUpdate } from '@core/models';

@Injectable()
export class SectionGroupService {
    private sectionGroups: SectionGroupEvent[] = [];
    private emitter: Subject<{ id: string; index: number }> = new Subject<{ id: string; index: number }>();

    /**
     * Add section group instance to the section groups list
     * @param section Sections instance
     */
    add(sectionGroup: SectionGroupEvent) {
        this.sectionGroups.push(sectionGroup);
    }

    /**
     * Update section group instance in the section groups list
     * @param id Section group id
     * @param config Object that can contains total sections, active section and url
     */
    update(id: string, config: SectionGroupUpdate = {}) {
        const selectedSectionGroup = this.sectionGroups.find((sectionGroup) => sectionGroup.id === id);

        Object.keys(config).forEach((key) => {
            selectedSectionGroup[key] = config[key];
        });
    }

    /**
     * Remove section group instance from the section groups list
     * @param id Sections id
     */
    remove(id: string) {
        this.sectionGroups = this.sectionGroups.filter((sectionGroup) => sectionGroup.id !== id);
    }

    /**
     * Return emitter
     * @returns emitter
     */
    getChangeEmitter() {
        return this.emitter;
    }

    /**
     * Set section active
     * @param id Sections id
     * @param index Selected section index
     */
    selectSection(id: string, index: number) {
        this.emitter.next({ id, index });
    }

    nextSection(id: string, carousel: boolean = false): void {
        const section = this.sectionGroups.find((sectionGroup) => sectionGroup.id === id);
        const index = section.activeSection + 1;
        const next = index >= section.totalSections ? (carousel ? 0 : section.totalSections) : index;
        this.selectSection(id, next);
    }

    prevSection(id: string, carousel: boolean = false) {
        const section = this.sectionGroups.find((sectionGroup) => sectionGroup.id === id);
        const index = section.activeSection - 1;
        const prev = index < 0 ? (carousel ? section.totalSections - 1 : 0) : index;
        this.selectSection(id, prev);
    }

    /**
     * Check if section group id is unique
     * @param id Section id
     * @returns Has unique id
     */
    hasUniqueId(id: string): boolean {
        return !this.sectionGroups.find((sectionGroup) => sectionGroup.id === id);
    }
}
