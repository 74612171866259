import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProductFormControlComponent),
    multi: true,
};

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    selector: 'product-form-control',
    styleUrls: ['./product-form-control.component.scss'],
    templateUrl: './product-form-control.component.html',
})
export class ProductFormControlComponent implements ControlValueAccessor {
    @Input() id: string = '';
    @Input() selectMode: boolean = false;

    @ViewChild('input', { static: true }) public inputElement: ElementRef;

    private onChange = (_: any) => {};
    private onTouched = () => {};

    public inputValue: any = {};

    get value(): any {
        return this.inputValue;
    }

    set value(v: any) {
        if (v !== this.inputValue) {
            this.inputValue = v;
            this.onChange(this.inputValue);
        }
    }

    writeValue(value: any): void {
        if (value !== this.inputValue) {
            this.inputValue = value;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.inputElement.nativeElement.disabled = isDisabled;
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}
