import { BulkAction } from '@core/models';

export type FetchType = 'REQUEST' | 'SUCCESS' | 'ERROR';
export type CrudType = 'GET' | 'CREATE' | 'SAVE' | 'DELETE';

export const FetchTypes: FetchType[] = ['REQUEST', 'SUCCESS', 'ERROR'];
export const CrudTypes: CrudType[] = ['GET', 'CREATE', 'SAVE', 'DELETE'];


export function getActionCrudType(type: string): CrudType {
    return CrudTypes.find((crudType) => type.toUpperCase().includes(crudType)) || 'GET';
}

export function getActionFetchype(type: string): FetchType {
    return FetchTypes.find((fetchType) => type.toUpperCase().endsWith(fetchType));
}

function updateStateBody<A>(crudType: CrudType, fetchType: FetchType, action: A): any {
    const isOptions = (action as any).type.toUpperCase().includes('OPTIONS');
    switch (fetchType) {
        case 'REQUEST': {
            return {
                ...(isOptions ? {} : { errors: {} }),
                ...(crudType === 'GET' ? { isFetching: true } : {}),
                ...(['CREATE', 'SAVE', 'DELETE'].includes(crudType) ? { isSaving: true, isSaved: false } : {}),
            };
        }

        case 'SUCCESS': {
            return {
                data: (action as any).payload,
                ...(crudType === 'GET' ? { isFetching: false } : {}),
                ...(['CREATE', 'SAVE', 'DELETE'].includes(crudType) ? { isSaving: false, isSaved: true } : {}),
            };
        }

        case 'ERROR': {
            return {
                errors: (action as any).payload,
                ...(crudType === 'GET' ? { isFetching: false } : {}),
                ...(['CREATE', 'SAVE', 'DELETE'].includes(crudType) ? { isSaving: false, isSaved: false } : {}),
            };
        }
    }
}

export const noopReducer = (state) => state;

export function loadableReducer<S, A>(state: S, stateKey: keyof S, action: A) {
    const crudType: CrudType = getActionCrudType((action as any).type);
    const fetchType: FetchType = getActionFetchype((action as any).type);
    return crudType && fetchType
        ? {
              ...(state as any),
              [stateKey]: {
                  ...(state as any)[stateKey],
                  ...updateStateBody<A>(crudType, fetchType, action),
              },
          }
        : {
              ...(state as any),
          };
}

export const resetState = <S>(initialState: S, states: Array<[keyof S, string]>) => (baseReducer) => (
    state: S = initialState,
    action,
) => {
    const match = states.find(([stateKey, actionType]) => actionType === action.type) || [];
    const [stateKey] = match;
    const newState = match.length
        ? {
              ...(state as any),
              [stateKey]: {
                  ...(initialState as any)[stateKey],
              },
          }
        : state;

    return baseReducer(newState, action);
};

export const pipeHigherOrderReducers = (...higherOrderReducers) => (baseReducer) =>
    higherOrderReducers.reduce((reducer, nextHigherOrderReducer) => nextHigherOrderReducer(reducer), baseReducer);

export function extracted<S, A>(initialState: S, actionsTypes: string[], baseReducer, stateKey: keyof S) {
    return function(state = initialState, action) {
        if (actionsTypes.includes(action.type)) {
            return baseReducer(loadableReducer<S, A>(state, stateKey, action), action);
        } else {
            return baseReducer(state, action);
        }
    };
}

export function loadableState<S, A>(initialState: S, stateKey: keyof S, actionsTypes?: string[]) {
    return function(baseReducer) {
        return extracted<S, A>(initialState, actionsTypes, baseReducer, stateKey);
    };
}

// !IMPORTANT We cannot set model to those consts
export const listInitialState = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
    },
    errors: {},
    isFetching: true,
};

export const optionsInitialState = {
    data: {},
    isFetching: true,
};

export const arrayDataInitialState = {
    data: [],
    errors: {},
    isFetching: true,
};

export const objectDataInitialState = {
    data: {},
    errors: {},
    isFetching: true,
};

export const statusInitialState: BulkAction = {
    status: null,
    info: {
        current: null,
        total: null,
    },
};
