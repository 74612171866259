import { Action } from '@ngrx/store';

import { prepareActions } from '@app/utils';

export const ListOperationsActionTypes = prepareActions('[Products List]', [
    'ACKNOWLEDGE_PRODUCTS_UPDATE_REQUEST',
    'ACKNOWLEDGE_PRODUCTS_UPDATE_SUCCESS',
    'ACKNOWLEDGE_PRODUCTS_UPDATE_ERROR',

    'BULK_CLONE_REQUEST',
    'BULK_CLONE_SUCCESS',
    'BULK_CLONE_ERROR',

    'GET_BULK_CLONE_STATUS_REQUEST',
    'GET_BULK_CLONE_STATUS_SUCCESS',
    'GET_BULK_CLONE_STATUS_ERROR',
    'CLEAR_BULK_CLONE_STATUS',

    'SOFT_DELETE_PRODUCTS_REQUEST',
    'SOFT_DELETE_PRODUCTS_SUCCESS',
    'SOFT_DELETE_PRODUCTS_ERROR',

    'HARD_DELETE_PRODUCTS_REQUEST',
    'HARD_DELETE_PRODUCTS_SUCCESS',
    'HARD_DELETE_PRODUCTS_ERROR',

    'RECOVER_PRODUCTS_REQUEST',
    'RECOVER_PRODUCTS_SUCCESS',
    'RECOVER_PRODUCTS_ERROR',

    'SHARE_PRODUCTS_REQUEST',
    'SHARE_PRODUCTS_SUCCESS',
    'SHARE_PRODUCTS_ERROR',
    'CLEAR_SHARE_PRODUCTS',

    'DISCONTINUE_PRODUCTS_REQUEST',
    'DISCONTINUE_PRODUCTS_SUCCESS',
    'DISCONTINUE_PRODUCTS_ERROR',

    'REVERT_DISCONTINUE_PRODUCTS_REQUEST',
    'REVERT_DISCONTINUE_PRODUCTS_SUCCESS',
    'REVERT_DISCONTINUE_PRODUCTS_ERROR',

    'SET_PRODUCTS_TO_LIVE_REQUEST',
    'SET_PRODUCTS_TO_LIVE_SUCCESS',
    'SET_PRODUCTS_TO_LIVE_PARTIAL_SUCCESS',
    'SET_PRODUCTS_TO_LIVE_ERROR',

    'DOWNLOAD_BARCODE_IMAGES_REQUEST',
    'DOWNLOAD_BARCODE_IMAGES_SUCCESS',
    'DOWNLOAD_BARCODE_IMAGES_ERROR',

    'SET_NEW_PRODUCTS_ID',
    'CLEAR_NEW_PRODUCTS_ID',
]);

export class AcknowledgeProductsUpdateRequestAction implements Action {
    readonly type = ListOperationsActionTypes.ACKNOWLEDGE_PRODUCTS_UPDATE_REQUEST;
    constructor(public payload?: any) {}
}
export class AcknowledgeProductsUpdateSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.ACKNOWLEDGE_PRODUCTS_UPDATE_SUCCESS;
    constructor(public payload?: any) {}
}
export class AcknowledgeProductsUpdateErrorAction implements Action {
    readonly type = ListOperationsActionTypes.ACKNOWLEDGE_PRODUCTS_UPDATE_ERROR;
    constructor(public payload?: any) {}
}

export class BulkCloneRequestAction implements Action {
    readonly type = ListOperationsActionTypes.BULK_CLONE_REQUEST;
    constructor(public payload?: any) {}
}
export class BulkCloneSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.BULK_CLONE_SUCCESS;
    constructor(public payload?: any) {}
}
export class BulkCloneErrorAction implements Action {
    readonly type = ListOperationsActionTypes.BULK_CLONE_ERROR;
    constructor(public payload?: any) {}
}

export class GetBulkCloneStatusRequestAction implements Action {
    readonly type = ListOperationsActionTypes.GET_BULK_CLONE_STATUS_REQUEST;
    constructor(public payload?: any) {}
}
export class GetBulkCloneStatusSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.GET_BULK_CLONE_STATUS_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetBulkCloneStatusErrorAction implements Action {
    readonly type = ListOperationsActionTypes.GET_BULK_CLONE_STATUS_ERROR;
    constructor(public payload?: any) {}
}
export class ClearBulkCloneStatusAction implements Action {
    readonly type = ListOperationsActionTypes.CLEAR_BULK_CLONE_STATUS;
    constructor(public payload?: any) {}
}

export class SoftDeleteProductsRequestAction implements Action {
    readonly type = ListOperationsActionTypes.SOFT_DELETE_PRODUCTS_REQUEST;
    constructor(public payload?: any) {}
}
export class SoftDeleteProductsSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.SOFT_DELETE_PRODUCTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class SoftDeleteProductsErrorAction implements Action {
    readonly type = ListOperationsActionTypes.SOFT_DELETE_PRODUCTS_ERROR;
    constructor(public payload?: any) {}
}

export class HardDeleteProductsRequestAction implements Action {
    readonly type = ListOperationsActionTypes.HARD_DELETE_PRODUCTS_REQUEST;
    constructor(public payload?: any) {}
}
export class HardDeleteProductsSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.HARD_DELETE_PRODUCTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class HardDeleteProductsErrorAction implements Action {
    readonly type = ListOperationsActionTypes.HARD_DELETE_PRODUCTS_ERROR;
    constructor(public payload?: any) {}
}

export class RecoverProductsRequestAction implements Action {
    readonly type = ListOperationsActionTypes.RECOVER_PRODUCTS_REQUEST;
    constructor(public payload?: any) {}
}
export class RecoverProductsSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.RECOVER_PRODUCTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class RecoverProductsErrorAction implements Action {
    readonly type = ListOperationsActionTypes.RECOVER_PRODUCTS_ERROR;
    constructor(public payload?: any) {}
}

export class ShareProductsRequestAction implements Action {
    readonly type = ListOperationsActionTypes.SHARE_PRODUCTS_REQUEST;
    constructor(public payload?: any) {}
}
export class ShareProductsSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.SHARE_PRODUCTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class ShareProductsErrorAction implements Action {
    readonly type = ListOperationsActionTypes.SHARE_PRODUCTS_ERROR;
    constructor(public payload?: any) {}
}
export class ClearShareProductsAction implements Action {
    readonly type = ListOperationsActionTypes.CLEAR_SHARE_PRODUCTS;
    constructor(public payload?: any) {}
}

export class DiscontinueProductsRequestAction implements Action {
    readonly type = ListOperationsActionTypes.DISCONTINUE_PRODUCTS_REQUEST;
    constructor(public payload?: any) {}
}
export class DiscontinueProductsSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.DISCONTINUE_PRODUCTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class DiscontinueProductsErrorAction implements Action {
    readonly type = ListOperationsActionTypes.DISCONTINUE_PRODUCTS_ERROR;
    constructor(public payload?: any) {}
}

export class RevertDiscontinueProductsRequestAction implements Action {
    readonly type = ListOperationsActionTypes.REVERT_DISCONTINUE_PRODUCTS_REQUEST;
    constructor(public payload?: any) {}
}
export class RevertDiscontinueProductsSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.REVERT_DISCONTINUE_PRODUCTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class RevertDiscontinueProductsErrorAction implements Action {
    readonly type = ListOperationsActionTypes.REVERT_DISCONTINUE_PRODUCTS_ERROR;
    constructor(public payload?: any) {}
}

export class SetProductsToLiveRequestAction implements Action {
    readonly type = ListOperationsActionTypes.SET_PRODUCTS_TO_LIVE_REQUEST;
    constructor(public payload?: any) {}
}
export class SetProductsToLiveSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.SET_PRODUCTS_TO_LIVE_SUCCESS;
    constructor(public payload?: any) {}
}
export class SetProductsToLivePartialSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.SET_PRODUCTS_TO_LIVE_PARTIAL_SUCCESS;
    constructor(public payload?: any) {}
}
export class SetProductsToLiveErrorAction implements Action {
    readonly type = ListOperationsActionTypes.SET_PRODUCTS_TO_LIVE_ERROR;
    constructor(public payload?: any) {}
}

export class DownloadBarcodeImagesRequestAction implements Action {
    readonly type = ListOperationsActionTypes.DOWNLOAD_BARCODE_IMAGES_REQUEST;
    constructor(public payload?: any) {}
}

export class DownloadBarcodeImagesSuccessAction implements Action {
    readonly type = ListOperationsActionTypes.DOWNLOAD_BARCODE_IMAGES_SUCCESS;
    constructor(public payload?: any) {}
}

export class DownloadBarcodeImagesErrorAction implements Action {
    readonly type = ListOperationsActionTypes.DOWNLOAD_BARCODE_IMAGES_ERROR;
    constructor(public payload?: any) {}
}

export class SetNewProductsIdAction implements Action {
    readonly type = ListOperationsActionTypes.SET_NEW_PRODUCTS_ID;
    constructor(public payload?: any) {}
}
export class ClearNewProductsIdAction implements Action {
    readonly type = ListOperationsActionTypes.CLEAR_NEW_PRODUCTS_ID;
    constructor(public payload?: any) {}
}

export type ListOperationsActions =
    | AcknowledgeProductsUpdateRequestAction
    | AcknowledgeProductsUpdateSuccessAction
    | AcknowledgeProductsUpdateErrorAction
    | BulkCloneRequestAction
    | BulkCloneSuccessAction
    | BulkCloneErrorAction
    | GetBulkCloneStatusRequestAction
    | GetBulkCloneStatusSuccessAction
    | GetBulkCloneStatusErrorAction
    | ClearBulkCloneStatusAction
    | SoftDeleteProductsRequestAction
    | SoftDeleteProductsSuccessAction
    | SoftDeleteProductsErrorAction
    | HardDeleteProductsRequestAction
    | HardDeleteProductsSuccessAction
    | HardDeleteProductsErrorAction
    | RecoverProductsRequestAction
    | RecoverProductsSuccessAction
    | RecoverProductsErrorAction
    | ShareProductsRequestAction
    | ShareProductsSuccessAction
    | ShareProductsErrorAction
    | ClearShareProductsAction
    | DiscontinueProductsRequestAction
    | DiscontinueProductsSuccessAction
    | DiscontinueProductsErrorAction
    | RevertDiscontinueProductsRequestAction
    | RevertDiscontinueProductsSuccessAction
    | RevertDiscontinueProductsErrorAction
    | SetProductsToLiveRequestAction
    | SetProductsToLiveSuccessAction
    | SetProductsToLivePartialSuccessAction
    | SetProductsToLiveErrorAction
    | DownloadBarcodeImagesRequestAction
    | DownloadBarcodeImagesSuccessAction
    | DownloadBarcodeImagesErrorAction
    | SetNewProductsIdAction
    | ClearNewProductsIdAction;
