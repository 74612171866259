import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Partnerships]';

export const ActionTypes = {
    GET_PARTNERSHIPS_REQUEST: type(`${ACTION_PREFIX} Get Partnerships Request`),
    GET_PARTNERSHIPS_SUCCESS: type(`${ACTION_PREFIX} Get Partnerships Success`),
    GET_PARTNERSHIPS_ERROR: type(`${ACTION_PREFIX} Get Partnerships Error`),
    GET_PARTNERSHIP_REQUEST: type(`${ACTION_PREFIX} Get Partnership Request`),
    GET_PARTNERSHIP_SUCCESS: type(`${ACTION_PREFIX} Get Partnership Success`),
    GET_PARTNERSHIP_ERROR: type(`${ACTION_PREFIX} Get Partnership Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
};

/**
 * Create 'Get Partnerships Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetPartnershipsRequestAction implements Action {
    type = ActionTypes.GET_PARTNERSHIPS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partnerships Succes' Action
 * @param payload Contains object with partnerships list and params
 */
export class GetPartnershipsSuccessAction implements Action {
    type = ActionTypes.GET_PARTNERSHIPS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partnerships Error' Action
 * @param payload Contains object with errors
 */
export class GetPartnershipsErrorAction implements Action {
    type = ActionTypes.GET_PARTNERSHIPS_ERROR;

    constructor(public payload?: any) {}
}
/**
 * Create 'Get Partnership Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetPartnershipRequestAction implements Action {
    type = ActionTypes.GET_PARTNERSHIP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partnership Succes' Action
 * @param payload Contains object with partnership list and params
 */
export class GetPartnershipSuccessAction implements Action {
    type = ActionTypes.GET_PARTNERSHIP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partnership Error' Action
 * @param payload Contains object with errors
 */
export class GetPartnershipErrorAction implements Action {
    type = ActionTypes.GET_PARTNERSHIP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetPartnershipsRequestAction
    | GetPartnershipsSuccessAction
    | GetPartnershipsErrorAction
    | GetPartnershipRequestAction
    | GetPartnershipSuccessAction
    | GetPartnershipErrorAction
    | ClearListAction;
