import { type } from '@app/utils';
import { Action } from '@ngrx/store';

import { GetIngredientsRequestPayload, IngredientsListData } from '@core/models';

const ACTION_PREFIX = '[Ingredients]';

export const ActionTypes = {
    GET_INGREDIENTS_REQUEST: type(`${ACTION_PREFIX} Get Ingredients Request`),
    GET_INGREDIENTS_SUCCESS: type(`${ACTION_PREFIX} Get Ingredients Success`),
    GET_INGREDIENTS_ERROR: type(`${ACTION_PREFIX} Get Ingredients Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
};

/**
 * Create 'Get Ingredient Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetIngredientsRequestAction implements Action {
    type = ActionTypes.GET_INGREDIENTS_REQUEST;

    constructor(public payload: GetIngredientsRequestPayload) {}
}

/**
 * Create 'Get Ingredient Succes' Action
 * @param payload Contains object with ingredient list and params
 */
export class GetIngredientsSuccessAction implements Action {
    type = ActionTypes.GET_INGREDIENTS_SUCCESS;

    constructor(public payload: IngredientsListData) {}
}

/**
 * Create 'Get Ingredient Error' Action
 * @param payload Contains object with errors
 */
export class GetIngredientsErrorAction implements Action {
    type = ActionTypes.GET_INGREDIENTS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetIngredientsRequestAction
    | GetIngredientsSuccessAction
    | GetIngredientsErrorAction
    | ClearListAction;
