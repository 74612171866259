import { CompanyId, ProductId, UserId } from '@core/models';

export interface FilteredProductChangeRequest {
    attributeId?: number;
    category?: string;
    categoryDisplay?: string;
    data?: any;
    groupId?: number;
    label?: string;
    name: string;
    nameDisplay?: string;
}

export interface ProductChangeRequestData {
    overview: string[];
    media: string[];
    values: any[];
    variants: string[];
    cases: string[];
    caseChildren: string[];
}

export type ProductChangeRequestId = number;

export interface ProductChangeRequest extends ProductChangeRequestPayload {
    id: ProductChangeRequestId;
    user: UserId;
}

export interface ProductChangeRequestPayload {
    product: ProductId;
    company: CompanyId;
    items: SingleChangeRequest[];
}

export interface SingleChangeRequest {
    type: ChangeRequestType;
    relatedObjectName: string;
    attributeGroupId: number;
    content: string;
    attrachments: any[];
}

export enum ChangeRequestType {
    FIELD,
    ATTRIBUTE,
    MEDIA,
    GENERAL,
}

export interface GetChangeRequestPayload {
    groupId: number;
    attributeId: number;
}
