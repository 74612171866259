<div
  class="select-category"
  [ngClass]="{
    'select-category--loading': categoriesList && categoriesList.isFetching,
    'select-category--add-button': addByButton
  }">
  <div
    *ngIf="searchBox"
    [ngClass]="{'search-box--loading': filteredCategories.isFetching}"
    class="search-box">
    <ng-select
      *ngIf="shouldShowSearch()"
      class="select-category__select"
      [placeholder]="'Enter at least 3 characters to start searching'"
      [options]="filtered"
      [formControl]="searchCategoryControl"
      [dynamicOptions]="true"
      [multiple]="multiple"
      [disableSelecting]="disableSelecting"
      [readonly]="readonly"
      [allowClear]="allowClear"
      (getOptions)="onFindCategories($event)"
      (selected)="onFilteredCategorySelect($event)">
    </ng-select>
  </div>

  <div
    *ngFor="let category of categories; let i = index;"
    [@rowTransition]="'1'"
    class="select-category__item">
    <ng-select
      class="select-category__select"
      [placeholder]="showPlaceholder && !ftaPlaceholder ? 'Select category' : ftaPlaceholder ? 'Select registration type' : ''"
      [options]="category.options"
      [formControl]="category.control"
      [readonly]="!allowCategoryChange || readonly"
      (selected)="onCategorySelect($event, i)">
    </ng-select>
  </div>

  <p
    *ngIf="addByButton"
    class="select-category__actions">
    <button
      (click)="onSelectFromButton()"
      type="button"
      class="select-category__add-new btn btn--secondary btn--small"
      [ngClass]="{'btn--disabled': !categories[0]?.control.value}"
      [disabled]="!categories[0]?.control.value">
      Add selected category
    </button>
  </p>
</div>
