import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
    @Input() text: string = 'Are you sure?';
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() confirm: EventEmitter<any> = new EventEmitter();

    cancelClick() {
        this.cancel.emit();
    }

    confirmClick() {
        this.confirm.emit();
    }
}
