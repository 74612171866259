import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'chips',
    templateUrl: './chips.component.html',
    styleUrls: ['chips.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipsComponent),
            multi: true,
        },
    ],
})
export class ChipsComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() defaultTags: string[] = [];
    @Input() availableTags: any[] = [];
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    private onTouchedCallback = (v: any) => {};
    private onChangeCallback = (v: any) => {};

    private inputElement: HTMLElement;

    selectedTags: any[] = [];
    input: string = '';

    /**
     * Component constructor function
     */
    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.inputElement = this.el.nativeElement.querySelector('input');

        this.el.nativeElement.addEventListener('click', (event) => this.focusInput(event));
    }

    writeValue(obj: any): void {
        this.input = obj;
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    /**
     * Focus input if user click directly on selected tags list
     *
     * @param event Click event object
     *
     */
    focusInput(event: any): void {
        const shouldFocus = event.target.className.indexOf('chips__selected-tags') > -1;

        if (shouldFocus) {
            this.inputElement.focus();
        }
    }

    /**
     * Add tag, remove input value and emit change event
     *
     * @param tag Object that contains tag displayName, value and isPredefined flag
     * @param blockEmit Should block emit event
     */
    addTag(tag: any, blockEmit?: boolean): void {
        this.selectedTags.push(tag);

        if (!blockEmit) {
            this.onChange.emit(this.selectedTags);
        }

        this.input = '';
    }

    /**
     * Remove tag emit change event
     *
     * @param tag Object that contains tag displayName, value and isPredefined flag
     * @param isPredefined Is predefined value
     */
    removeTag(index: number): void {
        this.selectedTags.splice(index, 1);
        this.onChange.emit(this.selectedTags);

        this.input = '';
    }

    ngOnDestroy() {
        this.el.nativeElement.removeEventListener('click', (event) => this.focusInput(event));
    }
}
