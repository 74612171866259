import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromNews from '../reducers/news.reducer';

@Injectable()
export class NewsSelectors {
    /**
     * Get state.news
     */
    getNewsState() {
        return ($state: State): any => $state.news;
    }

    /**
     * Get list from state.news
     */
    getList() {
        return createSelector(
            this.getNewsState(),
            fromNews.getList,
        );
    }
}
