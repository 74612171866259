import { CompanyPaidPlan, DefaultList, DefaultManage, DefaultOptions, UserStatus, UserStatusValue } from '@core/models';

export type CompanyId = string;
export type CompanyRoleType = 'p' | 'r' | 'd';
export type CompanySubtype = 'healthcare' | 'sharjah' | 'fta';

// TODO: String Enum (TypeScript 2.6)
export const CompanyRole = {
    PROVIDER: 'p',
    RECIPIENT: 'r',
    DISTRIBUTOR: 'd',
};

export type CompanyRoleName = 'Recipient' | 'Distributor' | 'Provider';

/**
 * Slice of a state for companies manage
 *
 * @export
 * @interface CompaniesManage
 */
export interface CompaniesManage extends DefaultManage<Company> {}

/**
 * Slice of a state for companies list
 *
 * @export
 * @interface CompaniesList
 */
export interface CompaniesList extends DefaultList<SimpleCompany> {}

/**
 * Slice of a state for companies options
 *
 * @export
 * @interface CompaniesOptions
 */
export interface CompaniesOptions extends DefaultOptions<any> {}

/**
 * Company model containing fields to show in Company management page
 *
 * @export
 * @interface Company
 */
export interface Company {
    address: string;
    canAnnotateInternalDataTemplate: boolean;
    canAnnotateProducts: boolean;
    city: string;
    companyNumber: string;
    companyType: number;
    country: string;
    created: string;
    currentPlan: CompanyPaidPlan;
    email: string;
    employmentSize: number;
    faxNumber: string;
    gln?: string;
    id: CompanyId;
    internalDataTemplateFile: string;
    internalProductTemplate: InternalProductTemplate[];
    isStaff: boolean;
    logo: string;
    name: string;
    partners: CompanyPartner[];
    phoneNumber: string;
    plan: string;
    planExpirationDate: string;
    postalCode: string;
    postOfficeBox: string;
    productRangeSize: number;
    role: CompanyRoleType;
    status: string;
    subtype: CompanySubtype;
    vatId: string;
    website: string;
}

export interface CompanyPartner {
    approvedProductsCount: number;
    company: CompanyId;
    created: string;
    gln?: string;
    hasDataReqAccess?: boolean;
    id: number; // ID of partnership, not a company ID
    logo: string;
    logoMedium: string;
    name: string;
    position: number;
    role: CompanyRoleType;
    subtype: CompanySubtype;
}

/**
 * Company model containing fields to show in Company listing
 *
 * @export
 * @interface SimpleCompany
 */
export interface SimpleCompany {
    approvedProductsCount?: number;
    company?: CompanyId;
    created: string;
    gln?: string;
    hasDataReqAccess?: boolean;
    id: CompanyId;
    logo?: string;
    logoMedium?: string;
    name: string;
    plan: string;
    planExpirationDate?: string;
    position?: number;
    role: CompanyRoleType;
    status: string;
    subtype: CompanySubtype;
}

/**
 * User Company model containing just few fields necessary to show in User model
 *
 * @export
 * @interface UserCompany
 */
export interface UserCompany {
    canAnnotateInternalDataTemplate: boolean;
    canAnnotateProducts: boolean;
    currentPlan: number;
    hasActiveWorkflows: boolean;
    id: CompanyId;
    isStaff: boolean;
    name: string;
    role: CompanyRoleType;
    subtype: CompanySubtype;
}

export interface SimpleUserCompany {
    avatar?: string;
    email: string;
    firstName: string;
    group?: string;
    id: CompanyId;
    isActive: boolean;
    isLoggedIn: boolean;
    isSuspended: boolean;
    lastName: string;
    modified: string;
    statusDisplay: UserStatus;
}

export interface UsersCompanyFilter {
    simpleQ: string;
    status: UserStatusValue;
    groups: string;
    modified__gte: string;
    modified__lte: string;
}

/**
 * Internal Product Template model
 *
 * @export
 * @interface InternalProductTemplate
 */

export type InternalProductTemplateId = string;
export interface InternalProductTemplate {
    choices: any[];
    id: InternalProductTemplateId;
    label: string;
    type: string;
}

/**
 * Information about product's company required during product edition
 *
 * @export
 * @interface ProductCompanyData
 */
export interface ProductCompanyData {
    canAnnotateInternalDataTemplate?: boolean;
    canAnnotateProducts?: boolean;
    internalProductTemplate?: InternalProductTemplate[];
    name: string;
    partners: CompanyPartner[];
    role: CompanyRoleType;
    subtype?: string;
    vatId?: string;
    companyType?: string;
}

export interface SharedInternalData<T> {
    company: string;
    companyId: CompanyId;
    internalData: any;
    internalDataTemplate: T[];
}

