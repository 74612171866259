import { Pipe, PipeTransform } from '@angular/core';
import { formatSize } from '@app/utils';

@Pipe({
    name: 'formatSize',
})
export class FormatSizePipe implements PipeTransform {
    transform(value: string, multiple: number = 0, si: boolean = true): string {
        if (!value || value === '') {
            return '';
        }
        return formatSize(value, multiple, si);
    }
}
