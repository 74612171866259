import { prepareActions } from '@app/utils';
import { CompanyId, ImportInternalTemplate } from '@core/models';
import { Action } from '@ngrx/store';

export const ActionTypes = prepareActions('[Company Internal Data]', [
    'GET_IMPORT_TEMPLATE_REQUEST',
    'GET_IMPORT_TEMPLATE_SUCCESS',
    'GET_IMPORT_TEMPLATE_ERROR',
    'CLEAR_IMPORT_TEMPLATE',

    'UPLOAD_IMPORT_FILES_REQUEST',
    'UPLOAD_IMPORT_FILES_SUCCESS',
    'UPLOAD_IMPORT_FILES_ERROR',
]);

/**
 * Create 'Get Import Template Request' Action
 * @param payload Contains company ID
 */
export class GetImportTemplateRequest implements Action {
    type = ActionTypes.GET_IMPORT_TEMPLATE_REQUEST;
    constructor(public payload: CompanyId) {}
}

/**
 * Create 'Get Import Template Success' Action
 * @param payload Contains object with template field with file url
 */
export class GetImportTemplateSuccess implements Action {
    type = ActionTypes.GET_IMPORT_TEMPLATE_SUCCESS;
    constructor(public payload: ImportInternalTemplate) {}
}

/**
 * Create 'Get Import Template Error' Action
 * @param payload Contains response with error object
 */
export class GetImportTemplateError implements Action {
    type = ActionTypes.GET_IMPORT_TEMPLATE_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Clear Import Template' Action
 */
export class ClearImportTemplate implements Action {
    type = ActionTypes.CLEAR_IMPORT_TEMPLATE;
    constructor(public payload?: any) {}
}

/**
 * Create 'Upload Import Files Request' Action
 * @param payload FormData with company, internalDataFile, internalMediasFile fields
 */
export class UploadImportFilesRequest implements Action {
    type = ActionTypes.UPLOAD_IMPORT_FILES_REQUEST;
    constructor(
        public payload: {
            formData: FormData;
            modalId: string;
        },
    ) {}
}

/**
 * Create 'Upload Import Files Success' Action
 * @param payload Object with template field that contains file url
 */
export class UploadImportFilesSuccess implements Action {
    type = ActionTypes.UPLOAD_IMPORT_FILES_SUCCESS;
    constructor(public payload: ImportInternalTemplate) {}
}

/**
 * Create 'Upload Import Files Error' Action
 * @param payload Contains response with error object
 */
export class UploadImportFilesError implements Action {
    type = ActionTypes.UPLOAD_IMPORT_FILES_ERROR;
    constructor(public payload: any) {}
}

export type Actions =
    | GetImportTemplateRequest
    | GetImportTemplateSuccess
    | GetImportTemplateError
    | ClearImportTemplate
    | UploadImportFilesRequest
    | UploadImportFilesSuccess
    | UploadImportFilesError;
