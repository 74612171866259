import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromCompanyPaidPlans from '../reducers/company-paid-plans.reducer';

@Injectable()
export class CompanyPaidPlansSelectors {
    /**
     * Get state.companyPaidPlans
     */
    getCompanyPaidPlansState() {
        return ($state: State): any => $state.companyPaidPlans;
    }

    /**
     * Get list from state.companyPaidPlans
     */
    getList() {
        return createSelector(
            this.getCompanyPaidPlansState(),
            fromCompanyPaidPlans.getList,
        );
    }

    /**
     * Get manage from state.companyPaidPlans
     */
    getManage() {
        return createSelector(
            this.getCompanyPaidPlansState(),
            fromCompanyPaidPlans.getManage,
        );
    }

    /**
     * Get options from state.companyPaidPlans
     */
    getOptions() {
        return createSelector(
            this.getCompanyPaidPlansState(),
            fromCompanyPaidPlans.getOptions,
        );
    }
}
