import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class CompanyDomainsReadGuard extends BaseGuard {
    path = ['companyDomains', 'read'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class CompanyDomainsManageGuard extends BaseGuard {
    path = ['companyDomains', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}
