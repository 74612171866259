import { Deferred } from '@app/utils';

import { OptionValue } from '@shared/components/ng-select/option';
import { OptionListValue } from '@shared/components/ng-select/option-list';

/**
 * Slice of a state for countries list
 *
 * @export
 * @interface CountriesList
 */
export interface CountriesList {
    list: SingleCountry[];
    isFetching: boolean;
}

/**
 * Keep information about single contry
 *
 * @export
 * @interface SingleCountry
 */
export interface SingleCountry {
    countryCode: string;
    name: string;
}

export interface PenultimateRoute {
    url: string;
    params: any;
}

export interface SelectOption {
    displayName: string;
    value: SelectValue;
    details?: any;
    disabled?: boolean;
    children?: SelectOption[];
}

export type SelectValue = OptionValue | OptionListValue;

export interface BulkSelectOption extends SelectOption {
    single?: boolean;
}

export interface FilteringParams {
    savePreferences?: 1 | 0;
    limit?: number;
    offset?: number;
    [key: string]: any;
}

export type ViewMode = 'create' | 'edit';

export interface SelectConfig {
    addon: 'left' | 'right';
    allowClear: boolean;
    classFluid: boolean;
    defaultMsg: string;
    disabled: boolean;
    disableSelecting: boolean;
    dynamicOptions: boolean;
    filterFromStart: boolean;
    highlightColor: string;
    highlightTextColor: string;
    multiple: boolean;
    noFilter: number;
    notFoundMsg: string;
    placeholder: string;
    readonly: boolean;
    selectError: string;
    termLimit: number;
}

// ? Wanted to use this enum in progressing imports, but:
// ? Type '"PENDING"' is not assignable to type 'CeleryStatus'.
// ? Fixed in TypeScript 2.6
// export enum CeleryStatus {
//   PENDING = 'PENDING',
//   IN_PROGRESS = 'IN_PROGRESS',
//   SUCCESS = 'SUCCESS',
//   FAILURE = 'FAILURE',
// };

export const CeleryStatus: { [key in CeleryStatusType]: CeleryStatusType } = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
};

export type CeleryStatusType = 'PENDING' | 'IN_PROGRESS' | 'SUCCESS' | 'FAILURE';

export type CeleryId = string;

export interface BaseBulkActionInfo {
    current?: number;
    total?: number;
}

export interface BulkAction {
    status: CeleryStatusType;
    info: BaseBulkActionInfo;
}

export interface CeleryIdResponse {
    id: CeleryId;
}

export interface CeleryStatusResponse {
    info: any;
    status: CeleryStatusType;
}

export interface ModalInstance {
    id: string;
    isOpened: boolean;
    modalTitle?: string;
    deferred?: Deferred<boolean>;
}

export type FormStatusType = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

export const FormStatus: { [key in FormStatusType]: FormStatusType } = {
    VALID: 'VALID',
    INVALID: 'INVALID',
    PENDING: 'PENDING',
    DISABLED: 'DISABLED',
};

export interface CountriesGetPayload {
    isCountry: boolean;
}
