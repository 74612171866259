import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-section-wrapper',
    templateUrl: 'form-section-wrapper.component.html',
})
export class FormSectionWrapper {
    @Input() sectionTitle: string;
    @Input() errors: string[];
    @Input() errorsCols: number = 12;
}
