import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromAttributeGroups from '../reducers/attribute-groups.reducer';

@Injectable()
export class AttributeGroupsSelectors {
    /**
     * Get state.attributeGroups
     */
    getAttributeGroupsState() {
        return ($state: State): any => $state.attributeGroups;
    }

    /**
     * Get list from state.attributeGroups
     */
    getList() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getList,
        );
    }

    /**
     * Get list.data from state.attributeGroups
     */
    getListData() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getListData,
        );
    }

    /**
     * Get list.errors from state.attributeGroups
     */
    getListErrors() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.attributeGroups
     */
    getListIsFetching() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getListIsFetching,
        );
    }

    /**
     * Get manage from state.attributeGroups
     */
    getManage() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getManage,
        );
    }

    /**
     * Get manage.data from state.attributeGroups
     */
    getManageData() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getManageData,
        );
    }

    /**
     * Get manage.errors from state.attributeGroups
     */
    getManageErrors() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getManageErrors,
        );
    }

    /**
     * Get manage.isFetching from state.attributeGroups
     */
    getManageIsFetching() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getManageIsFetching,
        );
    }

    /**
     * Get manage.isSaving from state.attributeGroups
     */
    getManageIsSaving() {
        return createSelector(
            this.getAttributeGroupsState(),
            fromAttributeGroups.getManageIsSaving,
        );
    }
}
