import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ProductCategoriesService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get product categories list
     * @param params Object with list params
     * @returns Backend API response
     */
    getProductCategories(params: any = {}): Observable<any> {
        return this.httpClient.get<any>('product_categories/', { params });
    }

    /**
     * Get import template request
     * @param data Object with config data needed to get a category template
     * @returns Backend API response
     */
    getImportTemplate(data: any): Observable<any> {
        return this.httpClient.get<any>(`product_categories/${data.id}/${data.mode}_template/`);
    }
}
