import { HostListener, Injectable, Directive } from '@angular/core';

import { CanDeactivate } from '@angular/router';

import { AuthService } from '@core/services/auth.service';
import { ModalService } from '@core/services/modal.service';

export const UnsavedChangesModalId = 'unsaved-changes';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<UnsavedChangesProtectionAbstract> {
    private readonly modalId = UnsavedChangesModalId;

    constructor(private authService: AuthService, private modalService: ModalService) {}

    canDeactivate(component: UnsavedChangesProtectionAbstract): Promise<boolean> {
        return this.authService.getTokenFromLocalStorage() && component.hasUnsavedChanges()
            ? this.modalService.open(this.modalId)
            : Promise.resolve(true);
    }
}

/**
 * It protect unsaved changes from being lose
 *
 * @abstract
 */
@Directive()
export abstract class UnsavedChangesProtectionAbstract {
    abstract hasUnsavedChanges();

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.hasUnsavedChanges()) {
            $event.returnValue = 'Are you sure?';
        }
    }
}
