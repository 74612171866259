import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type,
} from '@angular/core';

/**
 * Injection service is a helper to append components
 * dynamically to a known location in the DOM, most
 * noteably for dialogs/tooltips appending to body.
 *
 * @export
 * @class InjectionService
 */
@Injectable()
export class InjectionService {
    private _container: HTMLElement;

    constructor(
        private applicationRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
    ) {}

    /**
     * Gets the root view container to inject the component to.
     *
     * @returns {ComponentRef<any>}
     *
     * @memberOf InjectionService
     */
    getRootViewContainer(): HTMLElement {
        if (this._container) {
            return this._container;
        }

        const components: HTMLElement[] = this.applicationRef.components as any;

        if (components.length) {
            return components[0];
        }

        throw new Error('View Container not found! ngUpgrade needs to manually set this via setRootViewContainer.');
    }

    /**
     * Overrides the default root view container. This is useful for
     * things like ngUpgrade that doesn't have a ApplicationRef root.
     *
     * @param {any} container
     *
     * @memberOf InjectionService
     */
    setRootViewContainer(container): void {
        this._container = container;
    }

    /**
     * Gets the html element for a component ref.
     *
     * @param {ComponentRef<any>} componentRef
     * @returns {HTMLElement}
     *
     * @memberOf InjectionService
     */
    getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
        return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    }

    /**
     * Gets the root component container html element.
     *
     * @param element: HTMLElement,
     * @returns {HTMLElement}
     *
     * @memberOf InjectionService
     */
    getRootViewContainerNode(element: HTMLElement): HTMLElement {
        const searchAppRootInParent = _elem => (
            // according to changes in the ng-engine from version 9 (especially in the ComponentRef_ class)
            // we have to take the rootElement in a different way
            _elem.nodeName === 'APP-ROOT' ? _elem : searchAppRootInParent(_elem.parentNode as Element)
        );

        if (!this._container) {
            this.setRootViewContainer(searchAppRootInParent(element))
        }
        return this.getRootViewContainer();
    }

    /**
     * Projects the inputs onto the component
     *
     * @param {ComponentRef<any>} component
     * @param {*} options
     * @returns {ComponentRef<any>}
     *
     * @memberOf InjectionService
     */
    projectComponentInputs(component: ComponentRef<any>, options: any): ComponentRef<any> {
        if (options) {
            const props = Object.getOwnPropertyNames(options);
            for (const prop of props) {
                component.instance[prop] = options[prop];
            }
        }

        return component;
    }

    /**
     * Appends a component to a adjacent location
     *
     * @template T
     * @param {Type<T>} componentClass
     * @param {*} [options={}]
     * @param {HTMLElement} element
     * @returns {ComponentRef<any>}
     *
     * @memberOf InjectionService
     */
    appendComponent<T>(
        componentClass: Type<T>,
        options: any = {},
        element: HTMLElement,
    ): ComponentRef<any> {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
        const componentRef = componentFactory.create(this.injector);
        const appRef: any = this.applicationRef;
        const componentRootNode = this.getComponentRootNode(componentRef);

        // project the options passed to the component instance
        this.projectComponentInputs(componentRef, options);

        appRef.attachView(componentRef.hostView);

        componentRef.onDestroy(() => {
            appRef.detachView(componentRef.hostView);
        });

        if (options.appendToRoot) {
            const rootElement = this.getRootViewContainerNode(element);
            rootElement.appendChild(componentRootNode);
        } else {
            element.appendChild(componentRootNode);
        }

        return componentRef;
    }
}
