import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductDetailsFormsInfo, UploadInputData } from '@core/models';

export const keyInPayload = 'ftaPrices';

export interface PriceEntry {
    net: number;
    gross: number;
    currency?: string | null;
    retailerName?: string | null;
    receiptCopy?: string;
}

export interface FtaPricesInterface {
    averageSuggestedRetailPrice: string;
    officialLetter?: string;
    isProductNew: boolean;
    uploadData?: FtaUploadDataInterface;
    productPriceList: PriceEntry[];
}

export interface FtaUploadDataInterface {
    officialLetter: UploadInputData;
    receiptsData: UploadInputData[];
}

export interface ReceiptFileAddInterface {
    index: number;
    uploadData: UploadInputData;
}

export interface ReceiptFileRemoveInterface {
    index: number;
}

export interface UploadFtaFilesResponse {
    officialLetter: string;
    receipts: string[];
}

/**
 * Custom validator for FormControl to protect against entering a value of 0
 * in all available standards
 * @param c - form control to check
 */
const validateZero = (c: FormControl) => (String(c.value)[0] === '0' && Number(c.value) === 0) ? { validateZero: { valid: false }} : null;

export class PriceGroupFactory {
    constructor(private formBuilder: FormBuilder) {}

    getControls(defaultData, isProductNew): FormGroup {
        const controlsConfig = {
            net: [(defaultData.net || null), [Validators.required, validateZero, ]],
            currency: [defaultData.currency || 'AED', [Validators.required]],
            retailerName: [isProductNew ? null : defaultData.retailerName || null],
            receiptCopy: [defaultData.receiptCopy || null],
        };

        return this.formBuilder.group(controlsConfig);
    }
}

export class SimplifiedPriceGroupFactory {
    constructor(private formBuilder: FormBuilder) {}

    getControls(defaultData): FormGroup {
        return this.formBuilder.group({
            net: [(defaultData.net || null), [Validators.required, validateZero, ]],
            currency: [defaultData.currency || 'AED', [Validators.required]],
        });
    }
}

export function factoryPriceGroup(defaultData, isProductNew, simplified = false) {
    if (simplified) {
        return new SimplifiedPriceGroupFactory(new FormBuilder()).getControls(defaultData);
    }
    return new PriceGroupFactory(new FormBuilder()).getControls(defaultData, isProductNew);
}

/**
 * Auxiliary function for checking if payload contains
 * some receipt copy or official letter
 *
 * @param payload - full payload sent between components
 * @return true or false
 * @rtype boolean
 */
export const isPricePayloadContainsFiles = (payload: ProductDetailsFormsInfo) => {
    const ftaPayload = payload.data[keyInPayload] as FtaPricesInterface;

    if (
        !ftaPayload ||
        !Object.keys(ftaPayload).length ||
        !ftaPayload.uploadData ||
        !Object.keys(ftaPayload.uploadData).length
    ) {
        return false;
    }

    if (ftaPayload.uploadData.officialLetter) {
        return true;
    }

    for (const receipt of ftaPayload.uploadData.receiptsData) {
        if (receipt) {
            return true;
        }
    }

    return false;
};

/**
 * Function for moving files from json to FormData
 *
 * According to existing mechanism the files are put to json's payload as a Blob data.
 * Before upload them to backend they have to be moved to FormData and then uploaded.
 *
 * @param payload - full payload sent between components and modules as a full product data
 * @return FormData structure which will be send to server
 */
export const mapJsonFilesToFormData = (payload: ProductDetailsFormsInfo) => {
    const _uploadData = payload.data.ftaPrices.uploadData;

    const formData = new FormData();
    if (_uploadData.officialLetter) {
        formData.append('officialLetter', _uploadData.officialLetter.file);
    }
    _uploadData.receiptsData.map((uploadItem: UploadInputData, index) => uploadItem ? formData.append(index, uploadItem.file) : null);

    return formData;
};

/**
 * After correct response, there has to be done:
 * - removed unused payload contains files
 * - put generated by backend links to receipts copies fields
 *
 * Payload is not-configurable variable type so there is no possibility to change oryginal value.
 * In order to that there is generated and returned new payload's value.
 *
 * @param payload - full payload sent between components and modules as a full product data
 * @param response - server's response from endpoint responsible for upload
 * @return improved paylod contains full product data except for fta's prices files
 */
export const improvePayloadAfterCorrectResponse = (
    payload: ProductDetailsFormsInfo,
    response: UploadFtaFilesResponse,
) => {
    const _ftaPrices = payload.data.ftaPrices || {};

    const ftaPayload = () => {
        if (!_ftaPrices.productPriceList) {
            _ftaPrices.productPriceList = [];
        }

        return {
            isProductNew: _ftaPrices.isProductNew,
            officialLetter: response.officialLetter || _ftaPrices.officialLetter,
            averageSuggestedRetailPrice: _ftaPrices.averageSuggestedRetailPrice,
            productPriceList: _ftaPrices.productPriceList.map((item: PriceEntry, index) => {
                return {
                    ...item,
                    receiptCopy: response.receipts[index] || _ftaPrices.productPriceList[index].receiptCopy,
                } as PriceEntry;
            }),
        };
    };

    return {
        ...payload,
        data: {
            ...payload.data,
            ftaPrices: Object.keys(_ftaPrices).length ? ftaPayload() : {},
        },
    };
};
