import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Ingredient Groups]';

export const ActionTypes = {
    GET_INGREDIENT_GROUPS_REQUEST: type(`${ACTION_PREFIX} Get Ingredient Groups Request`),
    GET_INGREDIENT_GROUPS_SUCCESS: type(`${ACTION_PREFIX} Get Ingredient Groups Success`),
    GET_INGREDIENT_GROUPS_ERROR: type(`${ACTION_PREFIX} Get Ingredient Groups Error`),
    GET_INGREDIENT_GROUP_REQUEST: type(`${ACTION_PREFIX} Get Ingredient Group Request`),
    GET_INGREDIENT_GROUP_SUCCESS: type(`${ACTION_PREFIX} Get Ingredient Group Success`),
    GET_INGREDIENT_GROUP_ERROR: type(`${ACTION_PREFIX} Get Ingredient Group Error`),
    NEW_INGREDIENT_GROUP: type(`${ACTION_PREFIX} New Ingredient Group`),
    CREATE_INGREDIENT_GROUP_REQUEST: type(`${ACTION_PREFIX} Create Ingredient Group Request`),
    CREATE_INGREDIENT_GROUP_SUCCESS: type(`${ACTION_PREFIX} Create Ingredient Group Success`),
    CREATE_INGREDIENT_GROUP_ERROR: type(`${ACTION_PREFIX} Create Ingredient Group Error`),
    SAVE_INGREDIENT_GROUP_REQUEST: type(`${ACTION_PREFIX} Save Ingredient Group Request`),
    SAVE_INGREDIENT_GROUP_SUCCESS: type(`${ACTION_PREFIX} Save Ingredient Group Success`),
    SAVE_INGREDIENT_GROUP_ERROR: type(`${ACTION_PREFIX} Save Ingredient Group Error`),
    DELETE_INGREDIENT_GROUP_REQUEST: type(`${ACTION_PREFIX} Delete Ingredient Group Request`),
    DELETE_INGREDIENT_GROUP_SUCCESS: type(`${ACTION_PREFIX} Delete Ingredient Group Success`),
    DELETE_INGREDIENT_GROUP_ERROR: type(`${ACTION_PREFIX} Delete Ingredient Group Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
};

/**
 * Create 'Get Ingredient Groups Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetIngredientGroupsRequestAction implements Action {
    type = ActionTypes.GET_INGREDIENT_GROUPS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Ingredient Groups Succes' Action
 * @param payload Contains object with ingredient groups list and params
 */
export class GetIngredientGroupsSuccessAction implements Action {
    type = ActionTypes.GET_INGREDIENT_GROUPS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Ingredient Groups Error' Action
 * @param payload Contains object with errors
 */
export class GetIngredientGroupsErrorAction implements Action {
    type = ActionTypes.GET_INGREDIENT_GROUPS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Ingredient Group Request' Action
 * @param payload Contains ingredient group id
 */
export class GetIngredientGroupRequestAction implements Action {
    type = ActionTypes.GET_INGREDIENT_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Ingredient Group Success' Action
 * @param payload Contains object with ingredient group data
 */
export class GetIngredientGroupSuccessAction implements Action {
    type = ActionTypes.GET_INGREDIENT_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Ingredient Group Error' Action
 * @param payload Contains object with errors
 */
export class GetIngredientGroupErrorAction implements Action {
    type = ActionTypes.GET_INGREDIENT_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Ingredient Group' Action
 */
export class NewIngredientGroupAction implements Action {
    type = ActionTypes.NEW_INGREDIENT_GROUP;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Ingredient Group Request' Action
 * @param payload Contains object with ingredient data
 */
export class CreateIngredientGroupRequestAction implements Action {
    type = ActionTypes.CREATE_INGREDIENT_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Ingredient Group Succes' Action
 * @param payload Contains object with ingredient data
 */
export class CreateIngredientGroupSuccessAction implements Action {
    type = ActionTypes.CREATE_INGREDIENT_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Ingredient Group Error' Action
 * @param payload Contains object with errors
 */
export class CreateIngredientGroupErrorAction implements Action {
    type = ActionTypes.CREATE_INGREDIENT_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Ingredient Group Request' Action
 * @param payload Contains object with ingredient group data
 */
export class SaveIngredientGroupRequestAction implements Action {
    type = ActionTypes.SAVE_INGREDIENT_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Ingredient Group Succes' Action
 * @param payload Contains object with ingredient data
 */
export class SaveIngredientGroupSuccessAction implements Action {
    type = ActionTypes.SAVE_INGREDIENT_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Ingredient Error' Action
 * @param payload Contains object with errors
 */
export class SaveIngredientGroupErrorAction implements Action {
    type = ActionTypes.SAVE_INGREDIENT_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Ingredient Group Request' Action
 * @param payload Contains ingeredient group id
 */
export class DeleteIngredientGroupRequestAction implements Action {
    type = ActionTypes.DELETE_INGREDIENT_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Ingredient Group Succes' Action
 */
export class DeleteIngredientGroupSuccessAction implements Action {
    type = ActionTypes.DELETE_INGREDIENT_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Ingredient Group Error' Action
 * @param payload Contains object with errors
 */
export class DeleteIngredientGroupErrorAction implements Action {
    type = ActionTypes.DELETE_INGREDIENT_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetIngredientGroupsRequestAction
    | GetIngredientGroupsSuccessAction
    | GetIngredientGroupsErrorAction
    | GetIngredientGroupRequestAction
    | GetIngredientGroupSuccessAction
    | GetIngredientGroupErrorAction
    | NewIngredientGroupAction
    | CreateIngredientGroupRequestAction
    | CreateIngredientGroupSuccessAction
    | CreateIngredientGroupErrorAction
    | SaveIngredientGroupRequestAction
    | SaveIngredientGroupSuccessAction
    | SaveIngredientGroupErrorAction
    | DeleteIngredientGroupRequestAction
    | DeleteIngredientGroupSuccessAction
    | DeleteIngredientGroupErrorAction
    | ClearListAction
    | ClearManageAction;
