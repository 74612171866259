import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as fromMediaProfiles from '@core/reducers/media-profiles.reducer';

@Injectable()
export class MediaProfilesSelectors {
    /**
     * Get state.mediaProfiles
     */
    getMediaProfilesState() {
        return ($state: State): any => $state.mediaProfiles;
    }

    /**
     * Get list from state.list
     */
    getList() {
        return createSelector(
            this.getMediaProfilesState(),
            fromMediaProfiles.getList,
        );
    }

    /**
     * Get counters from state.manage
     */
    getManage() {
        return createSelector(
            this.getMediaProfilesState(),
            fromMediaProfiles.getManage,
        );
    }

    /**
     * Get manage from state.options
     */
    getOptions() {
        return createSelector(
            this.getMediaProfilesState(),
            fromMediaProfiles.getOptions,
        );
    }

    /**
     * Get availableImages from state.availableImages
     */
    getAvailableImages() {
        return createSelector(
            this.getMediaProfilesState(),
            fromMediaProfiles.getAvailableImages,
        );
    }

    /**
     * Get sampleImagePreview from state
     */
    getSampleImagePreview() {
        return createSelector(
            this.getMediaProfilesState(),
            fromMediaProfiles.getSampleImagePreview,
        );
    }
}
