import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromIngredients from '../reducers/ingredients.reducer';

@Injectable()
export class IngredientsSelectors {
    /**
     * Get state.ingredients
     */
    getIngredientsState() {
        return ($state: State): any => $state.ingredients;
    }

    /**
     * Get list from state.ingredients
     */
    getList() {
        return createSelector(
            this.getIngredientsState(),
            fromIngredients.getList,
        );
    }

    /**
     * Get list.data from state.ingredients
     */
    getListData() {
        return createSelector(
            this.getIngredientsState(),
            fromIngredients.getListData,
        );
    }

    /**
     * Get list.errors from state.ingredients
     */
    getListErrors() {
        return createSelector(
            this.getIngredientsState(),
            fromIngredients.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.ingredients
     */
    getListIsFetching() {
        return createSelector(
            this.getIngredientsState(),
            fromIngredients.getListIsFetching,
        );
    }
}
