import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, mergeMap, delay, tap, map, switchMap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';

import * as authActions from '@core/actions/auth.actions';
import * as actions from '@core/actions/onboarding.actions';

import { OnboardingLogId, OnboardingLogPayload } from '@core/models';
import { ModalService } from '@core/services/modal.service';
import { OnboardingService } from '@core/services/onboarding.service';

const MODAL_ID: string = 'onboarding-guide';
const DELAY_TO_SHOW: number = 300;
const CLOSING_TIME: number = 300;

@Injectable()
export class OnboardingEffects {
    constructor(
        private actions$: Actions,
        private modalService: ModalService,
        private onBoardingService: OnboardingService,
        private router: Router,
    ) {}

    @Effect({
        dispatch: false,
    })
    openTour$: Observable<any> = this.actions$.pipe(
        ofType(actions.ActionTypes.TOUR_OPEN),
        delay(DELAY_TO_SHOW),
        tap(() => this.modalService.open(MODAL_ID)),
    );

    @Effect()
    skipNowTour$: Observable<any> = this.actions$.pipe(
        ofType(actions.ActionTypes.TOUR_NOT_NOW),
        tap(() => this.modalService.close(MODAL_ID)),
        delay(CLOSING_TIME),
        mergeMap(() => of(new actions.ClearTour())),
    );

    @Effect()
    skipTour$: Observable<any> = this.actions$.pipe(
        ofType(actions.ActionTypes.TOUR_SKIP),
        map((action: actions.SkipTour) => action.payload),
        tap(() => this.modalService.close(MODAL_ID)),
        delay(CLOSING_TIME),
        switchMap((activeTour) => {
            const payload: OnboardingLogPayload = {
                isSkipped: true,
                onboardingId: activeTour.onboardingId,
            };
            return this.onBoardingService.updateOrSave(activeTour.id, payload).pipe(
                mergeMap((response: any) => from([
                        new authActions.SaveOnboardingLogSuccess(response),
                        new actions.ClearTour()
                    ]),
                ),
                catchError(() => of(new actions.ClearTour())),
            );
        }),
    );

    @Effect()
    finishTour$: Observable<any> = this.actions$.pipe(
        ofType(actions.ActionTypes.TOUR_FINISH),
        map((action: actions.SkipTour) => action.payload),
        tap(() => this.modalService.close(MODAL_ID)),
        delay(CLOSING_TIME),
        switchMap((activeTour) => {
            const payload: OnboardingLogPayload = {
                isCompleted: true,
                isSkipped: false,
                onboardingId: activeTour.onboardingId,
            };
            return this.onBoardingService.updateOrSave(activeTour.id, payload).pipe(
                mergeMap((response: any) => from([
                        new authActions.SaveOnboardingLogSuccess(response),
                        new actions.ClearTour()
                    ]),
                ),
                catchError(() => of(new actions.ClearTour())),
            );
        }),
    );

    @Effect({
        dispatch: false,
    })
    openVisitedTour$: Observable<any> = this.actions$.pipe(
        ofType(actions.ActionTypes.TOUR_OPEN_VISITED),
        map((action: actions.OpenVisitedTour) => action.payload),
        tap((rule) => this.router.navigate(rule.redirect)),
    );
}
