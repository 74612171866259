import { Action } from '@ngrx/store';

import { type } from '@app/utils';

const ACTION_PREFIX = '[Paid Plans]';

export const ActionTypes = {
    GET_PAID_PLANS_REQUEST: type(`${ACTION_PREFIX} Get Paid Plans Request`),
    GET_PAID_PLANS_SUCCESS: type(`${ACTION_PREFIX} Get Paid Plans Success`),
    GET_PAID_PLANS_ERROR: type(`${ACTION_PREFIX} Get Paid Plans Error`),
    GET_PAID_PLAN_REQUEST: type(`${ACTION_PREFIX} Get Paid Plan Request`),
    GET_PAID_PLAN_SUCCESS: type(`${ACTION_PREFIX} Get Paid Plan Success`),
    GET_PAID_PLAN_ERROR: type(`${ACTION_PREFIX} Get Paid Plan Error`),
    NEW_PAID_PLAN: type(`${ACTION_PREFIX} New Paid Plan`),
    CREATE_PAID_PLAN_REQUEST: type(`${ACTION_PREFIX} Create Paid Plan Request`),
    CREATE_PAID_PLAN_SUCCESS: type(`${ACTION_PREFIX} Create Paid Plan Success`),
    CREATE_PAID_PLAN_ERROR: type(`${ACTION_PREFIX} Create Paid Plan Error`),
    SAVE_PAID_PLAN_REQUEST: type(`${ACTION_PREFIX} Save Paid Plan Request`),
    SAVE_PAID_PLAN_SUCCESS: type(`${ACTION_PREFIX} Save Paid Plan Success`),
    SAVE_PAID_PLAN_ERROR: type(`${ACTION_PREFIX} Save Paid Plan Error`),
    DELETE_PAID_PLAN_REQUEST: type(`${ACTION_PREFIX} Delete Paid Plan Request`),
    DELETE_PAID_PLAN_SUCCESS: type(`${ACTION_PREFIX} Delete Paid Plan Success`),
    DELETE_PAID_PLAN_ERROR: type(`${ACTION_PREFIX} Delete Paid Plan Error`),
    GET_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Options Request`),
    GET_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Options Success`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
    CLEAR_OPTIONS: type(`${ACTION_PREFIX} Clear Options`),
};

/**
 * Create 'Get Paid Plans Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetPaidPlansRequestAction implements Action {
    type = ActionTypes.GET_PAID_PLANS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Paid Plans Succes' Action
 * @param payload Contains object with paid plans list and params
 */
export class GetPaidPlansSuccessAction implements Action {
    type = ActionTypes.GET_PAID_PLANS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Paid Plans Error' Action
 * @param payload Contains object with errors
 */
export class GetPaidPlansErrorAction implements Action {
    type = ActionTypes.GET_PAID_PLANS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Paid Plan Request' action
 * @param payload Contains paid plan id
 */
export class GetPaidPlanRequestAction implements Action {
    type = ActionTypes.GET_PAID_PLAN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Paid Plan Success' action
 * @param payload Contains object with paid plan data
 */
export class GetPaidPlanSuccessAction implements Action {
    type = ActionTypes.GET_PAID_PLAN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Paid Plan Error' action
 * @param payload Contains object with errors
 */
export class GetPaidPlanErrorAction implements Action {
    type = ActionTypes.GET_PAID_PLAN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Paid Plan' action
 */
export class NewPaidPlanAction implements Action {
    type = ActionTypes.NEW_PAID_PLAN;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Paid Plan Request' action
 * @param payload Contains object with paid plan data
 */
export class CreatePaidPlanRequestAction implements Action {
    type = ActionTypes.CREATE_PAID_PLAN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Paid Plan Success' action
 * @param payload Contains object with paid plan data
 */
export class CreatePaidPlanSuccessAction implements Action {
    type = ActionTypes.CREATE_PAID_PLAN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Paid Plan Error' action
 * @param payload Contains object with errors
 */
export class CreatePaidPlanErrorAction implements Action {
    type = ActionTypes.CREATE_PAID_PLAN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Paid Plan Request' action
 * @param payload Contains object with paid plan data
 */
export class SavePaidPlanRequestAction implements Action {
    type = ActionTypes.SAVE_PAID_PLAN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Paid Plan Success' action
 * @param payload Contains object with paid plan data
 */
export class SavePaidPlanSuccessAction implements Action {
    type = ActionTypes.SAVE_PAID_PLAN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Paid Plan Error' action
 * @param payload Contains object with errors
 */
export class SavePaidPlanErrorAction implements Action {
    type = ActionTypes.SAVE_PAID_PLAN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Paid Plan Request' action
 * @param payload Contains paid plan id
 */
export class DeletePaidPlanRequestAction implements Action {
    type = ActionTypes.DELETE_PAID_PLAN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Paid Plan Success' action
 */
export class DeletePaidPlanSuccessAction implements Action {
    type = ActionTypes.DELETE_PAID_PLAN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Paid Plan Error' action
 * @param payload Contains object with errors
 */
export class DeletePaidPlanErrorAction implements Action {
    type = ActionTypes.DELETE_PAID_PLAN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetPaidPlansRequestAction
    | GetPaidPlansSuccessAction
    | GetPaidPlansErrorAction
    | GetPaidPlanRequestAction
    | GetPaidPlanSuccessAction
    | GetPaidPlanErrorAction
    | NewPaidPlanAction
    | CreatePaidPlanRequestAction
    | CreatePaidPlanSuccessAction
    | CreatePaidPlanErrorAction
    | SavePaidPlanRequestAction
    | SavePaidPlanSuccessAction
    | SavePaidPlanErrorAction
    | DeletePaidPlanRequestAction
    | DeletePaidPlanSuccessAction
    | DeletePaidPlanErrorAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | ClearListAction
    | ClearManageAction
    | ClearOptionsAction;
