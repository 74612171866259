<div
  class="datepicker"
  [ngStyle]="{'width': opts.fillWidth ? '100%' : null, 'border': opts.inline ? 'none' : null}">
  <div
    *ngIf="!opts.inline"
    class="datepicker__input-wrapper">
    <input
      class="datepicker__input input input--fluid"
      [ngClass]="{
        'datepicker__input--with-clear': selectionDayTxt.length > 0 && opts.showClearDateBtn,
        'datepicker__input--invalid': invalidDate && opts.indicateInvalidDate,
        'input--error': opts.externalErrors,
        'input--readonly': opts.componentDisabled || readonly
      }"
      placeholder="{{ placeholder }}"
      type="text"
      aria-label="Calendar input field"
      [attr.maxlength]="opts.textDateFormat.length"
      (keyup)="userDateInput($event)"
      [value]="selectionDayTxt"
      (blur)="lostFocusInput($event)"
      [disabled]="opts.componentDisabled || readonly"
      [readonly]="!opts.editableDateField"
      [required]="opts.inputValueRequired"
      (click)="handleInputClick($event)">

    <a
      class="datepicker__clear-btn"
      aria-label="Clear Date"
      *ngIf="selectionDayTxt.length > 0 && opts.showClearDateBtn"
      (click)="removeBtnClicked()"
      [ngClass]="{'datepicker__clear-btn--disabled': opts.componentDisabled || readonly}">
    </a>

    <a
      class="datepicker__calendar-btn"
      aria-label="Set date"
      (click)="openBtnClicked()"
      [ngClass]="{'datepicker__calendar-btn--disabled': opts.componentDisabled || readonly}">
    </a>
  </div>

  <div
    *ngIf="showSelector || opts.inline"
    @slideDown
    class="datepicker__selector"
    [ngStyle]="{'bottom': getSelectorTopPosition()}"
    [ngClass]="{
      'datepicker__selector--inline': opts.inline,
      'datepicker__selector--align-right': opts.alignSelectorRight,
      'datepicker__selector--arrow': opts.showSelectorArrow && !opts.inline,
      'datepicker__selector--arrow-left': opts.showSelectorArrow && !opts.alignSelectorRight && !opts.inline,
      'datepicker__selector--arrow-right': opts.showSelectorArrow && opts.alignSelectorRight && !opts.inline,
      'datepicker__selector--static': opts.static
    }"
    tabindex="0">
    <table class="datepicker__header">
      <tr class="datepicker__header-row">
        <td class="datepicker__header-cell">
          <div style="float:left">
            <div class="datepicker__header-btn-cell">
              <button
                type="button"
                aria-label="Previous Month"
                class="datepicker__header-btn datepicker__header-btn--prev icon-chevron"
                (click)="prevMonth()"
                [disabled]="prevMonthDisabled"
                [ngClass]="{'datepicker__header-btn--enabled': !prevMonthDisabled, 'datepicker__header-btn--disabled': prevMonthDisabled}">
              </button>
            </div>

            <div class="datepicker__header-month-txt">
              <input
                type="text"
                *ngIf="editMonth"
                class="datepicker__header-month-input"
                maxlength="10"
                [value]="visibleMonth.monthTxt"
                (keyup)="userMonthInput($event)"
                (click)="$event.stopPropagation()"
                [ngClass]="{'datepicker__header-month-input--invalid': invalidMonth}">

              <button
                class="datepicker__header-label-btn"
                type="button"
                [ngClass]="{'datepicker__header-label-btn--month-label': opts.editableMonthAndYear}"
                *ngIf="!editMonth"
                (click)="opts.editableMonthAndYear && editMonthClicked($event)"
                tabindex="{{ opts.editableMonthAndYear ? '0' : '-1' }}">
                {{ visibleMonth.monthTxt }}
              </button>
            </div>

            <div
              class="datepicker__header-btn-cell">
              <button
                type="button"
                aria-label="Next Month"
                class="datepicker__header-btn datepicker__header-btn--next icon-chevron"
                [ngClass]="{
                  'datepicker__header-btn--enabled': !nextMonthDisabled,
                  'datepicker__header-btn--disabled': nextMonthDisabled
                }"
                [disabled]="nextMonthDisabled"
                (click)="nextMonth()">
              </button>
            </div>
          </div>
        </td>

        <td class="datepicker__header-cell" *ngIf="opts.showTodayBtn">
          <button
            type="button"
            class="datepicker__header-today-btn"
            [ngClass]="{
              'datepicker__header-today-btn--enabled': !disableTodayBtn,
              'datepicker__header-today-btn--disabled': disableTodayBtn
            }"
            [disabled]="disableTodayBtn"
            (click)="todayClicked()">
            {{ opts.todayBtnTxt }}
          </button>
        </td>

        <td class="datepicker__header-cell">
          <div style="float:right">
            <div class="datepicker__header-btn-cell">
              <button
                type="button"
                aria-label="Previous Year"
                class="datepicker__header-btn datepicker__header-btn--prev icon-chevron"
                [ngClass]="{
                  'datepicker__header-btn--enabled': !prevYearDisabled,
                  'datepicker__header-btn--disabled': prevYearDisabled
                }"
                [disabled]="prevYearDisabled"
                (click)="prevYear()">
              </button>
            </div>

            <div class="datepicker__header-year-txt">
              <input
                *ngIf="editYear"
                type="text"
                maxlength="4"
                class="datepicker__header-year-input"
                [ngClass]="{'datepicker__header-year-input--invalid': invalidYear}"
                [value]="visibleMonth.year"
                (keyup)="userYearInput($event)"
                (click)="$event.stopPropagation()">

              <button
                *ngIf="!editYear"
                type="button"
                class="datepicker__header-label-btn"
                [ngClass]="{'datepicker__header-label-btn--year-label': opts.editableMonthAndYear}"
                (click)="opts.editableMonthAndYear && editYearClicked($event)"
                tabindex="{{ opts.editableMonthAndYear ? '0' : '-1' }}">
                {{ visibleMonth.year }}
              </button>
            </div>

            <div
              class="datepicker__header-btn-cell">
              <button
                type="button"
                aria-label="Next Year"
                class="datepicker__header-btn datepicker__header-btn--next icon-chevron"
                [ngClass]="{
                  'datepicker__header-btn--enabled': !nextYearDisabled,
                  'datepicker__header-btn--disabled': nextYearDisabled
                }"
                [disabled]="nextYearDisabled"
                (click)="nextYear()">
              </button>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table class="datepicker__cal">
      <thead class="datepicker__cal-head">
        <tr class="datepicker__cal-row">
          <th
            *ngIf="opts.showWeekNumbers && opts.firstDayOfWeek==='mo'"
            class="datepicker__cal-head-cell datepicker__cal-head-cell--br">
            #
          </th>

          <th
            *ngFor="let d of weekDays"
            class="datepicker__cal-head-cell"
            scope="col">
            {{ d }}
          </th>
        </tr>
      </thead>

      <tbody class="datepicker__cal-body">
        <tr class="datepicker__cal-row" *ngFor="let w of dates">
          <td
            *ngIf="opts.showWeekNumbers && opts.firstDayOfWeek==='mo'"
            class="datepicker__cal-body-cell datepicker__cal-body-cell--br">
            {{ w.weekNbr }}
          </td>

          <td
            *ngFor="let d of w.week"
            class="datepicker__cal-body-cell"
            [ngClass]="{
              'datepicker__cal-body-cell--curr-month': d.cmo === CURR_MONTH && !d.disabled,
              'datepicker__cal-body-cell--selected-day': selectedDate.day === d.dateObj.day && selectedDate.month === d.dateObj.month && selectedDate.year === d.dateObj.year && d.cmo === CURR_MONTH,
              'datepicker__cal-body-cell--disabled': d.disabled,
              'tablesingleday': d.cmo === CURR_MONTH && !d.disabled
            }"
            (click)="!d.disabled && cellClicked(d); $event.stopPropagation()"
            (keydown)="cellKeyDown($event, d)"
            tabindex="0">
            <div
              class="datepicker__cal-body-div"
              [ngClass]="{
                'datepicker__cal-body-div--prev-month': d.cmo === PREV_MONTH,
                'datepicker__cal-body-div--curr-month': d.cmo === CURR_MONTH,
                'datepicker__cal-body-div--next-month': d.cmo === NEXT_MONTH,
                'datepicker__cal-body-div--sunday': d.dayNbr === 0 && opts.sunHighlight
              }">
              <span
                class="datepicker__cal-body-span"
                [ngClass]="{
                  'datepicker__cal-body-span--curr-day': d.currDay && opts.markCurrentDay,
                  'datepicker__cal-body-span--sunday-dim': opts.sunHighlight && d.dayNbr === 0 && (d.cmo===PREV_MONTH || d.cmo===NEXT_MONTH || d.disabled)
                }">
                {{ d.dateObj.day }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
