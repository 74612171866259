import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { HTTPClientVer } from '@core/utils/request.utils';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as imageConfigurations from '../actions/image-configurations.actions';
import { ImageConfigurationsService } from '../services/image-configurations.service';
import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';
import { catchErrorJson } from './catch-error';

@Injectable()
export class ImageConfigurationsEffects {
    @Effect()
    getImageConfigurationsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(imageConfigurations.ActionTypes.GET_IMAGE_CONFIGURATIONS_REQUEST),
        map((action: imageConfigurations.GetImageConfigurationsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imageConfigurationsService.getImageConfigurations(payload).pipe(
                map((response) => new imageConfigurations.GetImageConfigurationsSuccessAction(response)),
                catchError((error) =>
                    of(new imageConfigurations.GetImageConfigurationsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    createImageConfigurationRequest$: Observable<Action> = this.actions$.pipe(
        ofType(imageConfigurations.ActionTypes.CREATE_IMAGE_CONFIGURATION_REQUEST),
        map((action: imageConfigurations.CreateImageConfigurationRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imageConfigurationsService.createImageConfiguration(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Created image configuration ${response.name}`);
                    this.modalService.close('create-image-configuration-modal');
                }),
                map((response) => new imageConfigurations.CreateImageConfigurationSuccessAction(response)),
                catchError((error) =>
                    of(
                        new imageConfigurations.CreateImageConfigurationErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    deleteImageConfigurationRequest$: Observable<Action> = this.actions$.pipe(
        ofType(imageConfigurations.ActionTypes.DELETE_IMAGE_CONFIGURATION_REQUEST),
        map((action: imageConfigurations.DeleteImageConfigurationRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imageConfigurationsService.deleteImageConfiguration(payload).pipe(
                tap(
                    () => {
                        this.notificationsService.success(`Deleted image configuration`);
                        this.modalService.close('delete-image-configuration-modal');
                    },
                    () => {
                        this.modalService.close('delete-image-configuration-modal');
                    },
                ),
                map(() => new imageConfigurations.DeleteImageConfigurationSuccessAction()),
                catchError((error) =>
                    of(
                        new imageConfigurations.DeleteImageConfigurationErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    getOptionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(imageConfigurations.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap(() => {
            return this.imageConfigurationsService
                .getOptions()
                .pipe(map((response) => new imageConfigurations.GetOptionsSuccessAction(response.actions.POST)));
        }),
    );

    constructor(
        private actions$: Actions,
        private imageConfigurationsService: ImageConfigurationsService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}
}
