import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Companies]';

export const ActionTypes = {
    GET_COMPANIES_REQUEST: type(`${ACTION_PREFIX} Get Companies Request`),
    GET_COMPANIES_SUCCESS: type(`${ACTION_PREFIX} Get Companies Success`),
    GET_COMPANIES_ERROR: type(`${ACTION_PREFIX} Get Companies Error`),
    GET_COMPANY_REQUEST: type(`${ACTION_PREFIX} Get Company Request`),
    GET_COMPANY_SUCCESS: type(`${ACTION_PREFIX} Get Company Success`),
    GET_COMPANY_ERROR: type(`${ACTION_PREFIX} Get Company Error`),
    NEW_COMPANY: type(`${ACTION_PREFIX} New Company`),
    CREATE_COMPANY_REQUEST: type(`${ACTION_PREFIX} Create Company Request`),
    CREATE_COMPANY_SUCCESS: type(`${ACTION_PREFIX} Create Company Success`),
    CREATE_COMPANY_ERROR: type(`${ACTION_PREFIX} Create Company Error`),
    UPLOAD_COMPANY_LOGO_REQUEST: type(`${ACTION_PREFIX} Upload Company Logo Request`),
    UPLOAD_COMPANY_LOGO_SUCCESS: type(`${ACTION_PREFIX} Upload Company Logo Success`),
    UPLOAD_COMPANY_LOGO_ERROR: type(`${ACTION_PREFIX} Upload Company Logo Error`),
    SAVE_COMPANY_REQUEST: type(`${ACTION_PREFIX} Save Company Request`),
    SAVE_COMPANY_SUCCESS: type(`${ACTION_PREFIX} Save Company Success`),
    SAVE_COMPANY_ERROR: type(`${ACTION_PREFIX} Save Company Error`),
    DELETE_COMPANY_REQUEST: type(`${ACTION_PREFIX} Delete Company Request`),
    DELETE_COMPANY_SUCCESS: type(`${ACTION_PREFIX} Delete Company Success`),
    DELETE_COMPANY_ERROR: type(`${ACTION_PREFIX} Delete Company Error`),
    GET_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Options Request`),
    GET_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Options Success`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
    CLEAR_OPTIONS: type(`${ACTION_PREFIX} Clear Options`),
};

/**
 * Create 'Get Companies Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetCompaniesRequestAction implements Action {
    type = ActionTypes.GET_COMPANIES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Companies Succes' Action
 * @param payload Contains object with companies list and params
 */
export class GetCompaniesSuccessAction implements Action {
    type = ActionTypes.GET_COMPANIES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Companies Error' Action
 * @param payload Contains object with errors
 */
export class GetCompaniesErrorAction implements Action {
    type = ActionTypes.GET_COMPANIES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Request' Action
 * @param payload Contains company id
 */
export class GetCompanyRequestAction implements Action {
    type = ActionTypes.GET_COMPANY_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Success' Action
 * @param payload Contains object with company data
 */
export class GetCompanySuccessAction implements Action {
    type = ActionTypes.GET_COMPANY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Error' Action
 * @param payload Contains object with errors
 */
export class GetCompanyErrorAction implements Action {
    type = ActionTypes.GET_COMPANY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Company' Action
 */
export class NewCompanyAction implements Action {
    type = ActionTypes.NEW_COMPANY;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Request' Action
 * @param payload Contains object with company data
 */
export class CreateCompanyRequestAction implements Action {
    type = ActionTypes.CREATE_COMPANY_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Succes' Action
 * @param payload Contains object with company data
 */
export class CreateCompanySuccessAction implements Action {
    type = ActionTypes.CREATE_COMPANY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Error' Action
 * @param payload Contains object with errors
 */
export class CreateCompanyErrorAction implements Action {
    type = ActionTypes.CREATE_COMPANY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Upload Company Logo Request' Action
 * @param payload Contains object with logo data
 */
export class UploadCompanyLogoRequestAction implements Action {
    type = ActionTypes.UPLOAD_COMPANY_LOGO_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Upload Company Logo Succes' Action
 * @param payload Contains object with company data
 */
export class UploadCompanyLogoSuccessAction implements Action {
    type = ActionTypes.UPLOAD_COMPANY_LOGO_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Upload Company Logo Error' Action
 * @param payload Contains object with errors
 */
export class UploadCompanyLogoErrorAction implements Action {
    type = ActionTypes.UPLOAD_COMPANY_LOGO_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Request' Action
 * @param payload Contains object with errors
 */
export class SaveCompanyRequestAction implements Action {
    type = ActionTypes.SAVE_COMPANY_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Success' Action
 * @param payload Contains object with errors
 */
export class SaveCompanySuccessAction implements Action {
    type = ActionTypes.SAVE_COMPANY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Error' Action
 * @param payload Contains object with errors
 */
export class SaveCompanyErrorAction implements Action {
    type = ActionTypes.SAVE_COMPANY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Request' Action
 * @param payload Contains company id
 */
export class DeleteCompanyRequestAction implements Action {
    type = ActionTypes.DELETE_COMPANY_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Succes' Action
 */
export class DeleteCompanySuccessAction implements Action {
    type = ActionTypes.DELETE_COMPANY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Error' Action
 * @param payload Contains object with errors
 */
export class DeleteCompanyErrorAction implements Action {
    type = ActionTypes.DELETE_COMPANY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' Action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' Action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetCompaniesRequestAction
    | GetCompaniesSuccessAction
    | GetCompaniesErrorAction
    | GetCompanyRequestAction
    | GetCompanySuccessAction
    | GetCompanyErrorAction
    | NewCompanyAction
    | CreateCompanyRequestAction
    | CreateCompanySuccessAction
    | CreateCompanyErrorAction
    | UploadCompanyLogoRequestAction
    | UploadCompanyLogoSuccessAction
    | UploadCompanyLogoErrorAction
    | SaveCompanyRequestAction
    | SaveCompanySuccessAction
    | SaveCompanyErrorAction
    | DeleteCompanyRequestAction
    | DeleteCompanySuccessAction
    | DeleteCompanyErrorAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | ClearListAction
    | ClearManageAction
    | ClearOptionsAction;
