import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class UsersReadOwnGuard extends BaseGuard {
    path = ['users', 'readOwn'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class UsersManageOwnGuard extends BaseGuard {
    path = ['users', 'manageOwn'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class UsersManageGuard extends BaseGuard {
    path = ['users', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}
