import * as companyPaidPlansActions from '../actions/company-paid-plans.actions';
import { CompanyPaidPlanManage, CompanyPaidPlansList, CompanyPaidPlansOptions } from '../models';

import { ActionWithPayload } from '@core/actions/common.actions';

export interface State {
    list: CompanyPaidPlansList;
    manage: CompanyPaidPlanManage;
    options: CompanyPaidPlansOptions;
}

const initialState: State = {
    list: {
        data: {
            results: [],
            next: null,
            previous: null,
            count: null,
            limit: null,
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            basePlan: null,
            basePlanData: {
                canAccessImagePlatform: false,
                canAccessPublicApi: false,
                canExportProducts: false,
                canImportProducts: false,
                canManageProductRequirements: false,
                id: null,
                isStaff: false,
                maxActiveUsers: null,
                maxPartners: null,
                maxProducts: null,
                maxSharedProducts: null,
                name: '',
                publicApiDailyRateLimit: null,
                role: null,
                throttlingLimitation: {},
                unlimitedActiveUsers: false,
                unlimitedPartners: false,
                unlimitedProducts: false,
                unlimitedPublicApiDailyRateLimit: false,
                unlimitedSharedProducts: null,
            },
            canAccessImagePlatform: false,
            canAccessPublicApi: false,
            canExportProducts: false,
            canImportProducts: false,
            canManageProductRequirements: false,
            combinedPlanLimits: {
                canAccessImagePlatform: false,
                canAccessPublicApi: false,
                canExportProducts: false,
                canImportProducts: false,
                canManageProductRequirements: false,
                maxActiveUsers: null,
                maxPartners: null,
                maxProducts: null,
                maxSharedProducts: null,
                publicApiDailyRateLimit: null,
                unlimitedActiveUsers: false,
                unlimitedPartners: false,
                unlimitedProducts: false,
                unlimitedPublicApiDailyRateLimit: false,
                unlimitedSharedProducts: null,
            },
            company: null,
            expiryDate: '',
            id: null,
            isActive: false,
            isCustomized: false,
            maxActiveUsers: null,
            maxPartners: null,
            maxProducts: null,
            maxSharedProducts: null,
            noExpiryDate: false,
            publicApiDailyRateLimit: null,
            startDate: '',
            throttlingLimitation: {},
            unlimitedActiveUsers: false,
            unlimitedPartners: false,
            unlimitedProducts: false,
            unlimitedPublicApiDailyRateLimit: false,
            unlimitedSharedProducts: null,
        },
        isFetching: true,
        isSaving: false,
        errors: {},
    },
    options: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state: State = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case companyPaidPlansActions.ActionTypes.GET_COMPANY_PAID_PLANS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.GET_COMPANY_PAID_PLANS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.GET_COMPANY_PAID_PLANS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.GET_COMPANY_PAID_PLAN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.GET_COMPANY_PAID_PLAN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.GET_COMPANY_PAID_PLAN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.NEW_COMPANY_PAID_PLAN: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isFetching: false,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.SAVE_COMPANY_PAID_PLAN_REQUEST:
        case companyPaidPlansActions.ActionTypes.CREATE_COMPANY_PAID_PLAN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                    errors: {},
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.SAVE_COMPANY_PAID_PLAN_SUCCESS:
        case companyPaidPlansActions.ActionTypes.CREATE_COMPANY_PAID_PLAN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    data: action.payload,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.SAVE_COMPANY_PAID_PLAN_ERROR:
        case companyPaidPlansActions.ActionTypes.CREATE_COMPANY_PAID_PLAN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }
        case companyPaidPlansActions.ActionTypes.DELETE_COMPANY_PAID_PLAN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.DELETE_COMPANY_PAID_PLAN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.DELETE_COMPANY_PAID_PLAN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: true,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case companyPaidPlansActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;

export const getManage = (state: State) => state.manage;

export const getOptions = (state: State) => state.options;
