import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as products from '@core/actions/products.actions';
import * as productCategories from '../actions/product-categories.actions';

import { ProductCategoriesService } from '../services/product-categories.service';

import { catchErrorHttpClientJson } from './catch-error';

@Injectable()
export class ProductCategoriesEffects {
    constructor(private actions$: Actions, private productCategoriesService: ProductCategoriesService) {}

    @Effect()
    getProductCategoriesRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productCategories.ActionTypes.GET_PRODUCT_CATEGORIES_REQUEST),
        map((action: productCategories.GetProductCategoriesRequestAction) => action.payload),
        switchMap((payload: any) =>
            this.productCategoriesService.getProductCategories(payload).pipe(
                map((response) => new productCategories.GetProductCategoriesSuccessAction(response)),
                catchError((error) =>
                    of(new productCategories.GetProductCategoriesErrorAction(catchErrorHttpClientJson(error))),
                ),
            )
        ),
    );

    @Effect()
    findProductCategoriesRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productCategories.ActionTypes.FIND_PRODUCT_CATEGORIES_REQUEST),
        map((action: productCategories.FindProductCategoriesRequestAction) => action.payload),
        switchMap((payload: any) =>
            this.productCategoriesService.getProductCategories(payload).pipe(
                map((response) => new productCategories.FindProductCategoriesSuccessAction(response)),
                catchError((error) => of(
                    new productCategories.FindProductCategoriesErrorAction(catchErrorHttpClientJson(error))
                    ),
                ),
            )
        ),
    );

    @Effect()
    getImportTemplate$: Observable<any> = this.actions$.pipe(
        ofType<any>(products.ActionTypes.GET_IMPORT_TEMPLATE_REQUEST),
        map((action: products.GetImportTemplateRequestAction) => action.payload),
        switchMap((payload: any) =>
            this.productCategoriesService.getImportTemplate(payload).pipe(
                tap((response) => (window.location.href = response.template)),
                map((response) => new products.GetImportTemplateSuccessAction(response)),
                catchError((error) => of(new products.GetImportTemplateErrorAction(catchErrorHttpClientJson(error)))),
            ),
        ),
    );
}
