import { Action } from '@ngrx/store';
import { type } from '@app/utils';

const REFRESH_PREFIX = '[Refresh]';

export const ActionTypes = {
  SET_REQUIRE_REFRESH_TRUE: type(`${REFRESH_PREFIX} Set Refresh To True`),
  SET_REQUIRE_REFRESH_FALSE: type(`${REFRESH_PREFIX} Set Refresh To False`),
};

/**
 * Create 'Set RequireRefresh to True' Action
 */
export class SetRequireRefreshToTrue implements Action {
  type = ActionTypes.SET_REQUIRE_REFRESH_TRUE;

  constructor(public payload?: any) {}
}

/**
 * Create 'Set RequireRefresh to False' Action
 */
export class SetRequireRefreshToFalse implements Action {
  type = ActionTypes.SET_REQUIRE_REFRESH_FALSE;

  constructor(public payload?: any) {}
}

export type Actions
  = SetRequireRefreshToTrue
  | SetRequireRefreshToFalse;
