import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromIngredientGroups from '../reducers/ingredient-groups.reducer';

@Injectable()
export class IngredientGroupsSelectors {
    /**
     * Get state.ingredientGroups
     */
    getIngredientGroupsState() {
        return ($state: State): any => $state.ingredientGroups;
    }

    /**
     * Get list from state.ingredientGroups
     */
    getList() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getList,
        );
    }

    /**
     * Get list.data from state.ingredientGroups
     */
    getListData() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getListData,
        );
    }

    /**
     * Get list.errors from state.ingredientGroups
     */
    getListErrors() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.ingredientGroups
     */
    getListIsFetching() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getListIsFetching,
        );
    }

    /**
     * Get manage from state.ingredientGroups
     */
    getManage() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getManage,
        );
    }

    /**
     * Get manage.data from state.ingredientGroups
     */
    getManageData() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getManageData,
        );
    }

    /**
     * Get manage.errors from state.ingredientGroups
     */
    getManageErrors() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getManageErrors,
        );
    }

    /**
     * Get manage.isFetching from state.ingredientGroups
     */
    getManageIsFetching() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getManageIsFetching,
        );
    }

    /**
     * Get manage.isSaving from state.ingredientGroups
     */
    getManageIsSaving() {
        return createSelector(
            this.getIngredientGroupsState(),
            fromIngredientGroups.getManageIsSaving,
        );
    }
}
