import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragulaModule } from 'ng2-dragula';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPaginationModule } from 'ngx-pagination';

import { FormSectionWrapper } from './components';
import { AddressAutocompleteComponent } from './components/address-autocomplete/address-autocomplete.component';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { BarcodeChoiceModalComponent } from './components/barcode-choice-modal/barcode-choice-modal.component';
import { BulkActionsComponent } from './components/bulk-actions/bulk-actions.component';
import { ChannelScoreComponent } from './components/channel-score/channel-score.component';
import { ChipsComponent } from './components/chips/chips.component';
import { CircleScoreComponent } from './components/circle-score/circle-score.component';
import { CompletenessScoreComponent } from './components/completeness-score/completeness-score.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DataSummaryBoxComponent } from './components/data-summary-box/data-summary-box.component';
import { DisplayCountryComponent } from './components/display-country/display-country.component';
import { DndSelectComponent } from './components/dnd-select/dnd-select.component';
import { FileDisplayComponent } from './components/file-display/file-display.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FilenameCreatorComponent } from './components/filename-creator/filename-creator.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { HistoryLogsFiltersComponent } from './components/history-logs-filters/history-logs-filters.component';
import { InactivityLogoutModalComponent } from './components/inactivity-logout-modal/inactivity-logout-modal.component';
import { IntlTelComponent } from './components/intl-tel/intl-tel.component';
import { MessageComponent } from './components/message/message.component';
import { ModalComponent } from './components/modal/modal.component';
import { MyDatePickerComponent } from './components/my-date-picker/my-date-picker.component';
import { SelectDropdownComponent } from './components/ng-select/select-dropdown.component';
import { SelectComponent } from './components/ng-select/select.component';
import { CKEditorComponent } from './components/ng2-ckeditor/ckeditor.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PasswordStrengthIndicatorComponent } from './components/password-strength-indicator/password-strength-indicator.component';
import { ProductFormControlComponent } from './components/product-form-control/product-form-control.component';
import { ProductsAttributesDiffModalComponent } from './components/products-attributes-diff-modal/products-attributes-diff-modal.component';
import { ProgressModalComponent } from './components/progress-modal/progress-modal.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SectionGroupComponent } from './components/section-group/section-group.component';
import { SingleSectionComponent } from './components/section-group/single-section.component';
import { StepsSectionGroupComponent } from './components/section-group/steps-section-group.component';
import { TabsSectionGroupComponent } from './components/section-group/tabs-section-group.component';
import { SelectCategoryComponent } from './components/select-category/select-category.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TableListComponent } from './components/table-list/table-list.component';
import { TableRelationsComponent } from './components/table-relations/table-relations.component';
import { TargetFilesModalComponent } from './components/target-files-modal/target-files-modal.component';
import { TooltipContentComponent } from './components/tooltip/tooltip-content.component';

import {
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgPlaceholderTemplateDirective,
} from './components/ng-select/select-templates.directive';
import { CKButtonDirective } from './components/ng2-ckeditor/ckbutton.directive';
import { CKGroupDirective } from './components/ng2-ckeditor/ckgroup.directive';
import { CharsCounterDirective } from './directives/chars-counter/chars-counter.directive';
import { CommaToDotDirective } from './directives/comma-to-dot/comma-to-dot.directive';
import { InputNumberDirective } from './directives/input-number/input-number.directive';
import { TooltipContentDirective } from './directives/tooltip/tooltip-content.directive';

import { CountUpModule } from 'ngx-countup';
import { ConsentFormComponent } from './components/consent-form/consent-form.component';
import { CompanyTypeTranslationsPipe } from './pipes/company-type-translations/company-type-translations.pipe';
import { DateFormatPipe } from './pipes/date-format/date-format.pipe';
import { FileSizeFormatPipe } from './pipes/file-size-format/file-size-format.pipe';
import { FormatSizePipe } from './pipes/format-size/format-size.pipe';
import { HtmlEscapePipe, PartialHtmlEscapePipe } from './pipes/html-escape/html-escape.pipe';
import { LimitTextPipe } from './pipes/limit-text/limit-text.pipe';
import { ReadFilenamePipe } from './pipes/read-filename/read-filename.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SortPipe } from './pipes/sort/sort.pipe';
import { TimeDifferencePipe } from './pipes/time-difference/time-difference.pipe';
import { TimeFormatPipe } from './pipes/time-format/time-format.pipe';
import { TranslationsFilterPipe } from './pipes/translations-filter/translations-filter.pipe';
import { UniqueFilterPipe } from './pipes/unique-filter/unique-filter.pipe';

import { CustomerNameService } from './services/customer.service';
import { KnowledgeBaseService } from './services/knowledgeBaseService';
import { UaePassButtonComponent } from './components/uae-pass-button/uae-pass-button.component';

const MODULES = [
    CommonModule,
    RouterModule,
    FormsModule,
    DragulaModule,
    ReactiveFormsModule,
    DragulaModule,
    ColorPickerModule,
    NgxPaginationModule,
    ClipboardModule,
];

const COMPONENTS = [
    AddressAutocompleteComponent,
    AutocompleteInputComponent,
    BarcodeChoiceModalComponent,
    BulkActionsComponent,
    ChannelScoreComponent,
    ChipsComponent,
    CircleScoreComponent,
    CKEditorComponent,
    ConfirmationModalComponent,
    CompletenessScoreComponent,
    DataSummaryBoxComponent,
    DisplayCountryComponent,
    DndSelectComponent,
    FileDisplayComponent,
    FilenameCreatorComponent,
    FileUploadComponent,
    FormInputComponent,
    FormSectionWrapper,
    FormSelectComponent,
    HistoryLogsFiltersComponent,
    InactivityLogoutModalComponent,
    IntlTelComponent,
    MessageComponent,
    ModalComponent,
    MyDatePickerComponent,
    PaginationComponent,
    PasswordStrengthIndicatorComponent,
    ProductFormControlComponent,
    ProductsAttributesDiffModalComponent,
    ProgressModalComponent,
    SearchInputComponent,
    SectionGroupComponent,
    SelectCategoryComponent,
    SelectComponent,
    SelectDropdownComponent,
    SingleSectionComponent,
    StepsSectionGroupComponent,
    SwitchComponent,
    TableListComponent,
    TableRelationsComponent,
    TabsSectionGroupComponent,
    TargetFilesModalComponent,
    TooltipContentComponent,
    UaePassButtonComponent,
    ConsentFormComponent,
];

const DIRECTIVES = [
    CKButtonDirective,
    CKGroupDirective,

    CharsCounterDirective,

    InputNumberDirective,

    TooltipContentDirective,
    CommaToDotDirective,

    NgOptionTemplateDirective,
    NgLabelTemplateDirective,
    NgPlaceholderTemplateDirective,
];

const PIPES = [
    CompanyTypeTranslationsPipe,
    DateFormatPipe,
    FileSizeFormatPipe,
    FormatSizePipe,
    HtmlEscapePipe,
    LimitTextPipe,
    PartialHtmlEscapePipe,
    ReadFilenamePipe,
    SafeHtmlPipe,
    SortPipe,
    TimeDifferencePipe,
    TimeFormatPipe,
    TranslationsFilterPipe,
    UniqueFilterPipe,
];

@NgModule({
    imports: [MODULES, CountUpModule],
    declarations: [COMPONENTS, DIRECTIVES, PIPES],
    exports: [MODULES, COMPONENTS, DIRECTIVES, PIPES],
    providers: [CustomerNameService, KnowledgeBaseService],
})
export class SharedModule {}
