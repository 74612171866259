import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromImagePlatform from '../reducers/image-platform.reducer';

@Injectable()
export class ImagePlatformSelectors {
    /**
     * Get state.imagePlatform
     */
    getImagePlatformState() {
        return ($state: State): any => $state.imagePlatform;
    }

    /**
     * Get products from state.imagePlatform
     */
    getProducts() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getProducts,
        );
    }

    /**
     * Get imagePreview from state.imagePlatform
     */
    getImagePreview() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getImagePreview,
        );
    }

    /**
     * Get imagePreview.data from state.imagePlatform
     */
    getImagePreviewData() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getImagePreviewData,
        );
    }

    /**
     *  Get imagePreview.errors from state.imagePlatform
     */
    getImagePreviewErrors() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getImagePreviewErrors,
        );
    }

    /**
     * Get imagePreview.isFetching from state.imagePlatform
     */
    getImagePreviewIsFetching() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getImagePreviewIsFetching,
        );
    }

    /**
     * Get filenamePreview from state.imagePlatform
     */
    getFilenamePreview() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getFilenamePreview,
        );
    }

    /**
     * Get filenamePreview.data from state.imagePlatform
     */
    getFilenamePreviewData() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getFilenamePreviewData,
        );
    }

    /**
     *  Get filenamePreview.errors from state.imagePlatform
     */
    getFilenamePreviewErrors() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getFilenamePreviewErrors,
        );
    }

    /**
     * Get filenamePreview.isFetching from state.imagePlatform
     */
    getFilenamePreviewIsFetching() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getFilenamePreviewIsFetching,
        );
    }

    /**
     * Get generateZip from state.imagePlatform
     */
    getGenerateZip() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getGenerateZip,
        );
    }

    /**
     * Get generateZip.data from state.imagePlatform
     */
    getGenerateZipData() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getGenerateZipData,
        );
    }

    /**
     *  Get generateZip.errors from state.imagePlatform
     */
    getGenerateZipErrors() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getGenerateZipErrors,
        );
    }

    /**
     * Get generateZip.isFetching from state.imagePlatform
     */
    getGenerateZipIsFetching() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getGenerateZipIsFetching,
        );
    }

    /**
     * Get zipStatus from state.imagePlatform
     */
    getZipStatus() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getZipStatus,
        );
    }

    /**
     * Get zipStatus.data from state.imagePlatform
     */
    getZipStatusData() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getZipStatusData,
        );
    }

    /**
     *  Get zipStatus.errors from state.imagePlatform
     */
    getZipStatusErrors() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getZipStatusErrors,
        );
    }

    /**
     * Get zipStatus.isFetching from state.imagePlatform
     */
    getZipStatusIsFetching() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getZipStatusIsFetching,
        );
    }

    /**
     * Get availableImages from state.imagePlatform
     */
    getAvailableImages() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getAvailableImages,
        );
    }

    /**
     * Get availableImages.data from state.imagePlatform
     */
    getAvailableImagesData() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getAvailableImagesData,
        );
    }

    /**
     *  Get availableImages.errors from state.imagePlatform
     */
    getAvailableImagesErrors() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getAvailableImagesErrors,
        );
    }

    /**
     * Get availableImages.isFetching from state.imagePlatform
     */
    getAvailableImagesIsFetching() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getAvailableImagesIsFetching,
        );
    }

    /**
     * Get selectImages from state.imagePlatform
     */
    getValidateImageExistence() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getValidateImageExistence,
        );
    }

    /**
     * Get selectImages.data from state.imagePlatform
     */
    getValidateImageExistenceData() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getValidateImageExistenceData,
        );
    }

    /**
     *  Get selectImages.errors from state.imagePlatform
     */
    getValidateImageExistenceErrors() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getValidateImageExistenceErrors,
        );
    }

    /**
     * Get selectImages.isFetching from state.imagePlatform
     */
    getValidateImageExistenceIsFetching() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getValidateImageExistenceIsFetching,
        );
    }

    /**
     * Get filenameOptions from state.imagePlatform
     */
    getFilenameOptions() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getFilenameOptions,
        );
    }

    /**
     * Get filenameOptions.data from state.imagePlatform
     */
    getFilenameOptionsData() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getFilenameOptionsData,
        );
    }

    /**
     * Get filenameOptions.isFetching from state.imagePlatform
     */
    getFilenameOptionsIsFetching() {
        return createSelector(
            this.getImagePlatformState(),
            fromImagePlatform.getFilenameOptionsIsFetching,
        );
    }
}
