import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'tooltip-content',
    templateUrl: 'tooltip-content.component.html',
})
export class TooltipContentComponent implements AfterViewInit {
    @Input() hostElement: HTMLElement;
    @Input() content: string;
    @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
    @Input() origin: 'edge' | 'middle' = 'edge';
    @Input() theme: 'purple' | 'white' = 'white';
    @Input() animation: boolean = true;
    @Input() appendToRoot: boolean = true;
    @Input() wide: boolean = false;
    @Input() fixedPosition: boolean = false;

    top: number = -100000;
    left: number = -100000;
    isIn: boolean = false;
    isFade: boolean = false;
    isWide: boolean = false;
    isPositioning: boolean = true;

    private tooltip: HTMLElement;

    constructor(private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.tooltip = this.elementRef.nativeElement.children[0];

        this.setOptions();

        this.isPositioning = false;

        if (this.content) {
            this.show();
        }

        this.changeDetectorRef.detectChanges();
    }

    setOptions() {
        this.isIn = true;
        this.isWide = this.wide;
    }

    show(): void {
        if (!this.hostElement || !this.tooltip) {
            return;
        }

        const setPosition = () => {
            const p = this.positionElements(
                this.hostElement,
                this.tooltip,
                this.placement,
                this.origin,
                this.appendToRoot,
            );
            this.top = p.top;
            this.left = p.left;

            this.setOptions();

            if (this.animation) {
                this.isFade = true;
            }
        };

        this.placement = this.setTooltipPosition();

        setPosition();
        // Fix bug with wrong targetEl size on first show
        // setTimeout(setPosition.bind(this));
    }

    hide(): void {
        this.top = -100000;
        this.left = -100000;

        if (this.animation) {
            this.isFade = false;
        }
    }

    private positionElements(
        hostEl: HTMLElement,
        targetEl: HTMLElement,
        positionStr: string,
        originStr: string,
        appendToRoot: boolean,
    ): { top: number; left: number } {
        const positionStrParts = positionStr.split('-');
        const pos0 = positionStrParts[0];
        const pos1 = positionStrParts[1] || 'center';
        const hostElPos = appendToRoot ? this.offset(hostEl) : this.position(hostEl);
        const targetElWidth = targetEl.offsetWidth;
        const targetElHeight = targetEl.offsetHeight;
        const arrowDimension = 18; // Sum of arrow offset from tooltip edge (10px) and half of an arrow base border (8px)

        const calculatePosition: any = {
            top(): number {
                return hostElPos.top - targetElHeight;
            },
            bottom(): number {
                return hostElPos.top + hostElPos.height;
            },
            left(): number {
                return hostElPos.left - targetElWidth;
            },
            right(): number {
                return hostElPos.left + hostElPos.width;
            },
        };

        const calculateAlign = function(position: string, align: string, origin: string): number {
            if (position === 'top' || position === 'bottom') {
                if (align === 'left') {
                    if (origin === 'edge') {
                        return hostElPos.left;
                    } else if (origin === 'middle') {
                        return hostElPos.left + hostElPos.width / 2 - arrowDimension;
                    }
                } else if (align === 'right') {
                    if (origin === 'edge') {
                        return hostElPos.left + hostElPos.width - targetElWidth;
                    } else if (origin === 'middle') {
                        return hostElPos.left + hostElPos.width / 2 - targetElWidth + arrowDimension;
                    }
                } else {
                    return hostElPos.left + hostElPos.width / 2 - targetElWidth / 2;
                }
            } else if (position === 'right' || position === 'left') {
                if (align === 'top') {
                    if (origin === 'edge') {
                        return hostElPos.top;
                    } else if (origin === 'middle') {
                        return hostElPos.top + hostElPos.height / 2 - arrowDimension;
                    }
                } else if (align === 'bottom') {
                    if (origin === 'edge') {
                        return hostElPos.top + hostElPos.height - targetElHeight;
                    } else if (origin === 'middle') {
                        return hostElPos.top + hostElPos.height / 2 - targetElHeight + arrowDimension;
                    }
                } else {
                    return hostElPos.top + hostElPos.height / 2 - targetElHeight / 2;
                }
            }
        };

        let targetElPos: { top: number; left: number };

        switch (pos0) {
            case 'right':
                targetElPos = {
                    top: calculateAlign(pos0, pos1, originStr),
                    left: calculatePosition[pos0](),
                };
                break;

            case 'left':
                targetElPos = {
                    top: calculateAlign(pos0, pos1, originStr),
                    left: calculatePosition[pos0](),
                };
                break;

            case 'bottom':
                targetElPos = {
                    top: calculatePosition[pos0](),
                    left: calculateAlign(pos0, pos1, originStr),
                };
                break;

            default:
                targetElPos = {
                    top: calculatePosition[pos0](),
                    left: calculateAlign(pos0, pos1, originStr),
                };
                break;
        }

        return targetElPos;
    }

    private position(nativeEl: HTMLElement): { width: number; height: number; top: number; left: number } {
        let offsetParentBCR = { top: 0, left: 0 };
        const elBCR = this.offset(nativeEl);
        const offsetParentEl = this.parentOffsetEl(nativeEl);
        if (offsetParentEl !== window.document) {
            offsetParentBCR = this.offset(offsetParentEl);
            offsetParentBCR.top += offsetParentEl.clientTop - offsetParentEl.scrollTop;
            offsetParentBCR.left += offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
        }

        const boundingClientRect = nativeEl.getBoundingClientRect();
        return {
            width: boundingClientRect.width || nativeEl.offsetWidth,
            height: boundingClientRect.height || nativeEl.offsetHeight,
            top: elBCR.top - offsetParentBCR.top,
            left: elBCR.left - offsetParentBCR.left,
        };
    }

    private offset(nativeEl: any): { width: number; height: number; top: number; left: number } {
        const boundingClientRect = nativeEl.getBoundingClientRect();
        return {
            width: boundingClientRect.width || nativeEl.offsetWidth,
            height: boundingClientRect.height || nativeEl.offsetHeight,
            top: boundingClientRect.top + (window.pageYOffset || window.document.documentElement.scrollTop),
            left: boundingClientRect.left + (window.pageXOffset || window.document.documentElement.scrollLeft),
        };
    }

    private getStyle(nativeEl: HTMLElement, cssProp: string): string {
        if ((nativeEl as any).currentStyle) {
            return (nativeEl as any).currentStyle[cssProp]; // IE
        }

        if (window.getComputedStyle) {
            return (window.getComputedStyle(nativeEl) as any)[cssProp];
        }

        // finally try and get inline style
        return (nativeEl.style as any)[cssProp];
    }

    private isStaticPositioned(nativeEl: HTMLElement): boolean {
        return (this.getStyle(nativeEl, 'position') || 'static') === 'static';
    }

    private parentOffsetEl(nativeEl: HTMLElement): any {
        let offsetParent: any = nativeEl.offsetParent || window.document;
        while (offsetParent && offsetParent !== window.document && this.isStaticPositioned(offsetParent)) {
            offsetParent = offsetParent.offsetParent;
        }

        return offsetParent || window.document;
    }

    private setTooltipPosition(): 'top' | 'bottom' | 'left' | 'right' {
        const hostElPos = this.hostElement.getBoundingClientRect();

        const windowYOffset = window.pageYOffset || window.document.documentElement.scrollTop;

        switch (this.placement) {
            case 'top': {
                const isTooltipContentVisible = this.fixedPosition
                    ? hostElPos.top < this.tooltip.offsetHeight
                    : hostElPos.top > this.tooltip.offsetHeight + windowYOffset;

                if (isTooltipContentVisible) {
                    return 'bottom';
                }
                break;
            }

            case 'bottom': {
                const isTooltipContentVisible = this.fixedPosition
                    ? hostElPos.top + this.tooltip.offsetHeight >= window.innerHeight
                    : this.hostElement.offsetTop > windowYOffset + this.hostElement.offsetHeight;

                if (isTooltipContentVisible) {
                    return 'top';
                }
                break;
            }

            case 'left': {
                if (this.hostElement.offsetLeft - this.tooltip.offsetWidth < 0) {
                    return 'right';
                }
                break;
            }

            case 'right': {
                if (
                    this.tooltip.offsetWidth + this.hostElement.offsetWidth + this.hostElement.offsetLeft >
                    window.document.documentElement.offsetWidth
                ) {
                    return 'left';
                }
                break;
            }
        }

        return this.placement;
    }
}
