<div (window:click)="onWindowClick($event)">
  <label
    *ngIf="label"
    [for]="label"
    class="form__label label"
    [ngClass]="{'label--required': required}">
    {{ label }}
  </label>

  <div class="autocomplete-input__container">
    <div class="autocomplete-input__input-container">
      <input
        #input
        type="text"
        class="select select--fluid autocomplete-input__input"
        [(ngModel)]="value"
        [ngClass]="{
          'select--error': (errors && errors.length) || (required && isDirty && !value.length),
          'select--open': showResults,
          'select--readonly': readonly
        }"
        [attr.disabled]="readonly ? readonly : null"
        >
      <div
        *ngIf="isFetching && showResults"
        class="loader autocomplete-input__loader">
      </div>
    </div>

    <div
      #resultsContainer
      class="autocomplete-input__dropdown-container select__dropdown select__dropdown--autocomplete"
      [ngClass]="{
        'autocomplete-input__dropdown-container--hidden': !showResults,
        'autocomplete-input__dropdown-container--scrollable': canScrollDown
      }"
      [style.top.px]="top">
      <ul
        *ngIf="showResults"
        class="autocomplete-input__results">
        <li
          *ngFor="let result of results; let i = index;"
          class="autocomplete-input__result select__dropdown-item"
          [ngClass]="{
            'select__dropdown-item--selected': i === selectedInputIndex
          }"
          (click)="onResultClick(result)">
          {{ result }}
        </li>
      </ul>
    </div>
  </div>

  <ul class="errors errors--inside">
    <li
      *ngIf="required && isDirty && !value.length && !errors?.length"
      class="errors__single-error">
      Field is required.
    </li>
    <li
      *ngFor="let error of errors"
      class="errors__single-error">
      {{ error }}
    </li>
  </ul>
</div>
