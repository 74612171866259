import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

import { GetProductsListRequestPayload, ProductsSearchParams, SearchId } from '@core/models';

export const ListManageActionTypes = prepareActions('[Products List]', [
    'GET_OPTIONS_REQUEST',
    'GET_OPTIONS_SUCCESS',
    'CLEAR_OPTIONS',

    'GET_ALL_LISTS_PRODUCTS_REQUEST',

    'CLEAR_LIST',

    'GET_PRODUCTS_LIST_REQUEST',
    'GET_PRODUCTS_LIST_SUCCESS',
    'GET_PRODUCTS_LIST_ERROR',

    'GET_SEARCH_PARAMS_REQUEST',
    'GET_SEARCH_PARAMS_SUCCESS',
    'GET_SEARCH_PARAMS_ERROR',

    'SET_SEARCH_PARAMS',
    'CLEAN_SEARCH_PARAMS',
]);

export class GetOptionsRequestAction implements Action {
    readonly type = ListManageActionTypes.GET_OPTIONS_REQUEST;
    constructor(public payload?: any) {}
}
export class GetOptionsSuccessAction implements Action {
    readonly type = ListManageActionTypes.GET_OPTIONS_SUCCESS;
    constructor(public payload?: any) {}
}
export class ClearOptionsAction implements Action {
    readonly type = ListManageActionTypes.CLEAR_OPTIONS;
    constructor(public payload?: any) {}
}

export class GetAllListsProductsRequestAction implements Action {
    readonly type = ListManageActionTypes.GET_ALL_LISTS_PRODUCTS_REQUEST;
    constructor(public payload?: GetProductsListRequestPayload) {}
}

export class ClearListAction implements Action {
    readonly type = ListManageActionTypes.CLEAR_LIST;
    constructor(public payload?: any) {}
}

export class GetProductsListRequestAction implements Action {
  readonly type = ListManageActionTypes.GET_PRODUCTS_LIST_REQUEST;
  constructor(public payload?: GetProductsListRequestPayload) {}
}
export class GetProductsListSuccessAction implements Action {
  readonly type = ListManageActionTypes.GET_PRODUCTS_LIST_SUCCESS;
  constructor(public payload?: any) {}
}
export class GetProductsListErrorAction implements Action {
  readonly type = ListManageActionTypes.GET_PRODUCTS_LIST_ERROR;
  constructor(public payload?: any) {}
}

export class GetSearchParamsRequestAction implements Action {
    readonly type = ListManageActionTypes.GET_SEARCH_PARAMS_REQUEST;
    constructor(public payload?: SearchId) {}
}
export class GetSearchParamsSuccessAction implements Action {
    readonly type = ListManageActionTypes.GET_SEARCH_PARAMS_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetSearchParamsErrorAction implements Action {
    readonly type = ListManageActionTypes.GET_SEARCH_PARAMS_ERROR;
    constructor(public payload?: any) {}
}

export class SetSearchParamsAction implements Action {
    readonly type = ListManageActionTypes.SET_SEARCH_PARAMS;
    constructor(public payload?: ProductsSearchParams) {}
}
export class ClearSearchParamsAction implements Action {
    readonly type = ListManageActionTypes.CLEAN_SEARCH_PARAMS;
    constructor(public payload?: any) {}
}

export type ListManageActions =
  | GetOptionsRequestAction
  | GetOptionsSuccessAction
  | ClearOptionsAction

  | GetAllListsProductsRequestAction
  | ClearListAction

  | GetSearchParamsRequestAction
  | GetSearchParamsSuccessAction
  | GetSearchParamsErrorAction

  | SetSearchParamsAction
  | ClearSearchParamsAction

  | GetProductsListRequestAction
  | GetProductsListSuccessAction
  | GetProductsListErrorAction
;
