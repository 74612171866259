import { type } from '@app/utils';
import { OnboardingLog } from '@core/models';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Auth]';

export const ActionTypes = {
    SIGN_IN_REQUEST: type(`${ACTION_PREFIX} Sign In Request`),
    SIGN_IN_SUCCESS: type(`${ACTION_PREFIX} Sign In Success`),
    SIGN_IN_ERROR: type(`${ACTION_PREFIX} Sign In Error`),
    SIGN_OUT: type(`${ACTION_PREFIX} Sign Out`),
    GET_LOCAL_USER_REQUEST: type(`${ACTION_PREFIX} Get Local User Request`),
    GET_LOCAL_USER_SUCCESS: type(`${ACTION_PREFIX} Get Local User Success`),
    GET_LOCAL_USER_ERROR: type(`${ACTION_PREFIX} Get Local User Error`),
    SAVE_ONBOARDING_LOG_SUCCESS: type(`${ACTION_PREFIX} Save Onboarding Log Success`),
    RESET_PASSWORD_REQUEST: type(`${ACTION_PREFIX} Reset Password Request`),
    NEW_PASSWORD_REQUEST: type(`${ACTION_PREFIX} New Password Request`),
    NEW_PASSWORD_SUCCESS: type(`${ACTION_PREFIX} New Password Success`),
    NEW_PASSWORD_ERROR: type(`${ACTION_PREFIX} New Password Error`),
    ACTIVATE_ACCOUNT_REQUEST: type(`${ACTION_PREFIX} Activate Account Request`),
    ACTIVATE_ACCOUNT_SUCCESS: type(`${ACTION_PREFIX} Activate Account Success`),
    ACTIVATE_ACCOUNT_ERROR: type(`${ACTION_PREFIX} Activate Account Error`),
    CLEAR_SIGN_IN: type(`${ACTION_PREFIX} Clear Sign In`),
    CLEAR_SIGN_OUT: type(`${ACTION_PREFIX} Clear Sign Out`),
    CLEAR_NEW_PASSWORD: type(`${ACTION_PREFIX} Clear New Password`),
    CLEAR_ACTIVATE_ACCOUNT: type(`${ACTION_PREFIX} Clear Activate Account`),
};

/**
 * Create 'Sign In Request' Action
 * @param payload Contains user credentials
 */
export class SignInRequestAction implements Action {
    type = ActionTypes.SIGN_IN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Sign In Succes' Action
 * @param payload Contains object token and user data
 */
export class SignInSuccessAction implements Action {
    type = ActionTypes.SIGN_IN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Sign In Error' Action
 * @param payload Contains object with errors
 */
export class SignInErrorAction implements Action {
    type = ActionTypes.SIGN_IN_ERROR;

    constructor(public payload?: any) {}
}

export class SignOutAction implements Action {
    type = ActionTypes.SIGN_OUT;

    constructor(public redirectToLoginPage: boolean = true) {}
}

export class GetLocalUserRequestAction implements Action {
    type = ActionTypes.GET_LOCAL_USER_REQUEST;
}

/**
 * Create 'Get Local User Success' Action
 * @param payload Contains object with local user data
 */
export class GetLocalUserSuccessAction implements Action {
    type = ActionTypes.GET_LOCAL_USER_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Local User Error' Action
 * @param payload Contains object with errors
 */
export class GetLocalUserErrorAction implements Action {
    type = ActionTypes.GET_LOCAL_USER_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Onboarding Log Success' Action
 * @param payload Contains backend response with OnboardingLogPaylod object
 */
export class SaveOnboardingLogSuccess implements Action {
    type = ActionTypes.SAVE_ONBOARDING_LOG_SUCCESS;

    constructor(public payload: OnboardingLog) {}
}

/**
 * Create 'Reset Password Request' Action
 * @param payload Contains user e-mail
 */
export class ResetPasswordRequestAction implements Action {
    type = ActionTypes.RESET_PASSWORD_REQUEST;

    constructor(public email: string) {}
}

/**
 * Create 'New Password Request' Action
 * @param payload Contains uid, token and new password
 */
export class NewPasswordRequestAction implements Action {
    type = ActionTypes.NEW_PASSWORD_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Password Succes' Action
 */
export class NewPasswordSuccessAction implements Action {
    type = ActionTypes.NEW_PASSWORD_SUCCESS;
}

/**
 * Create 'New Password Error' Action
 * @param payload Contains object with errors
 */
export class NewPasswordErrorAction implements Action {
    type = ActionTypes.NEW_PASSWORD_ERROR;

    constructor(public payload: any) {}
}

/**
 * Create 'Activate Account Request' Action
 * @param payload Contains user e-mail
 */
export class ActivateAccountRequestAction implements Action {
    type = ActionTypes.ACTIVATE_ACCOUNT_REQUEST;

    constructor(public payload: any) {}
}

/**
 * Create 'Activate Account Success' Action
 */
export class ActivateAccountSuccessAction implements Action {
    type = ActionTypes.ACTIVATE_ACCOUNT_SUCCESS;
}

/**
 * Create 'Activate Account Error' Action
 */
export class ActivateAccountErrorAction implements Action {
    type = ActionTypes.ACTIVATE_ACCOUNT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Sign In' Action
 */
export class ClearSignInAction implements Action {
    type = ActionTypes.CLEAR_SIGN_IN;
}

/**
 * Create 'Clear Sign Out' Action
 */
export class ClearSignOutAction implements Action {
    type = ActionTypes.CLEAR_SIGN_OUT;
}

/**
 * Create 'Clear New Password' Action
 */
export class ClearNewPasswordAction implements Action {
    type = ActionTypes.CLEAR_NEW_PASSWORD;
}

/**
 * Create 'Clear Activate Account' Action
 */
export class ClearActivateAccountAction implements Action {
    type = ActionTypes.CLEAR_ACTIVATE_ACCOUNT;
}

export type Actions =
    | SignInRequestAction
    | SignInSuccessAction
    | SignInErrorAction
    | SignOutAction
    | GetLocalUserRequestAction
    | GetLocalUserSuccessAction
    | GetLocalUserErrorAction
    | SaveOnboardingLogSuccess
    | ResetPasswordRequestAction
    | NewPasswordRequestAction
    | NewPasswordErrorAction
    | ActivateAccountRequestAction
    | ActivateAccountErrorAction
    | ClearSignInAction
    | ClearSignOutAction
    | ClearNewPasswordAction
    | ClearActivateAccountAction
;
