import { Params } from '@angular/router';

import {
    CompanyId,
    CompanySubtype,
    CustomDataList,
    DefaultListData,
    DefaultListParams,
    DefaultManage,
    DefaultOptions,
    GtinId,
    Product,
    ProductId,
    ProductWorkflowSummary,
    SearchId,
    WorkflowStatus,
    WorkflowType,
} from '@core/models';
import { FtaPricesInterface } from '@products/components/components-manage-product/product-price-info/product-price.models';

export interface ListedProductsCounters {
    all: number;
    pending: number;
    approved: number;
    rejected: number;
    my: number;
}

export interface ListedProductsUI {
    sharedFurther: boolean;
    activeTab?: ListedProductsListType;
}

/**
 * Slice of a state for listed products counters
 *
 * @export
 * @interface ListedProductsCounters
 */
export interface ListedProductsCountersManage extends DefaultManage<ListedProductsCounters> {}

/**
 * Slice of a state for listed products options
 *
 * @export
 * @interface ListedProductsOptions
 */
export interface ListedProductsOptions extends DefaultOptions<any> {}

/**
 * Slice of a state for single listed product
 *
 * @export
 * @interface ListedProduct
 */
export interface ListedProduct extends Partial<Product> {
    created: string;
    latestWorkflow: ProductWorkflowSummary;
    nextProductId: ProductId;
    originalPublisher?: string;
    previousProductId: ProductId;
    publisher: string;
    publisherProductId: ProductId;
    recipient: string;
    ftaPrices: FtaPricesInterface;
}

export interface SimpleListedProduct {
    frontFaceImage: string;
    frontFaceImageThumbnail: string;
    gtin: GtinId;
    id: ProductId;
    latestWorkflow: ProductWorkflowSummary;
    modified: string;
    publisher: string;
    recipient: string;
    shortBrandName: string;
    shortTradeItemDescription: string;
    status: string;
    statusDisplay: string;
    subtype: CompanySubtype;
    tradeItemDescription: string;
}

/**
 * Slice of a state for listed products manage
 *
 * @export
 * @interface ListedProductsManage
 */
export interface ListedProductsManage extends DefaultManage<ListedProduct> {}

/**
 * Slice of a state for some history data
 *
 * @export
 * @interface ListedProductHistoryData
 */
export interface ListedProductHistoryData extends CustomDataList<any[]> {}

/**
 * Slice of a state for some product reviews data
 *
 * @export
 * @interface ListedProductReviewsData
 */
export interface ListedProductReviewsData extends CustomDataList<any[]> {}

export interface ListedProductSummary {
    latestWorkflow: ProductWorkflowSummary;
    recipient: string;
    modified: string;
    publisher: string;
    frontFaceImageThumbnail: string;
    gtin: GtinId;
    tradeItemDescription: string;
}

/**
 * Slice of a state for some product summary data
 *
 * @export
 * @interface ListedProductSummaryData
 */
export interface ListedProductSummaryData extends CustomDataList<ListedProductSummary> {}

export type ListedProductsListType = 'list' | 'listApproved' | 'listMy' | 'listPending' | 'listRejected';

export interface ListedProductsListFilterParams {
    category?: number[];
    categoryName?: string[];
    company__in?: CompanyId[];
    multipleGtin?: string;
    publisherQ?: string;
    recipientQ?: string;
    searchId?: SearchId;
    simpleQ?: string;
    targetMarketCountryCode?: string[];
    modified__gte?: string;
    modified__lte?: string;
    vatId?: string;
    countryOfOrigin?: string[];
}

export interface ListedProductsSearchParams
    extends Partial<ListedProductsListFilterParams>,
        Partial<DefaultListParams> {
    showOnlyMyProducts: boolean;
    pendingMyApproval: boolean;
    workflowStatus: WorkflowStatus;
    workflowType: WorkflowType;
    sharedFurther: boolean;
}

export interface ListedProductsListData extends DefaultListData<SimpleListedProduct> {
    searchId: SearchId;
    searchParams: ListedProductsSearchParams;
}

export interface ListedSearchParamsManage extends DefaultManage<ListedProductsSearchParams> {}

export interface ListedProductsList extends CustomDataList<ListedProductsListData> {}

export interface InitializeLists {
    allFetched: boolean;
    firstListFetched: boolean;
    firstRun: boolean;
    firstRunEnd: boolean;
    listTypes: ListedProductsListType[];
    listTypesFetched: ListedProductsListType[];
}

export enum SearchParamsActionSource {
    INIT,
    USER,
}

export interface ListedSearchParamsState {
    actionSource: SearchParamsActionSource;
    params: Params;
}

export interface ListedProductCheckboxEvent {
    checked: boolean;
    selectedProducts: SimpleListedProduct[];
}
