import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as ingredientGroups from '../actions/ingredient-groups.actions';

import { IngredientGroupsService } from '../services/ingredient-groups.service';
import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class IngredientGroupsEffects {
    constructor(
        private router: Router,
        private actions$: Actions,
        private ingredientGroupsService: IngredientGroupsService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    @Effect()
    getIngredientGroupsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(ingredientGroups.ActionTypes.GET_INGREDIENT_GROUPS_REQUEST),
        map((action: ingredientGroups.GetIngredientGroupsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.ingredientGroupsService.getIngredientGroups(payload).pipe(
                map((response) => new ingredientGroups.GetIngredientGroupsSuccessAction(response)),
                catchError((error) =>
                    of(new ingredientGroups.GetIngredientGroupsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    getIngredientGroupRequest$: Observable<Action> = this.actions$.pipe(
        ofType(ingredientGroups.ActionTypes.GET_INGREDIENT_GROUP_REQUEST),
        map((action: ingredientGroups.GetIngredientGroupRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.ingredientGroupsService.getIngredientGroup(payload).pipe(
                map((response) => new ingredientGroups.GetIngredientGroupSuccessAction(response)),
                catchError((error) =>
                    of(new ingredientGroups.GetIngredientGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    saveIngredientGroupRequest$: Observable<Action> = this.actions$.pipe(
        ofType(ingredientGroups.ActionTypes.SAVE_INGREDIENT_GROUP_REQUEST),
        map((action: ingredientGroups.SaveIngredientGroupRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.ingredientGroupsService.saveIngredientGroup(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved ingredient group ${response.translations.en.name}`);
                    this.router.navigate(['/choice-lists']);
                }),
                map((response) => new ingredientGroups.SaveIngredientGroupSuccessAction(response)),
                catchError((error) =>
                    of(new ingredientGroups.SaveIngredientGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    createIngredientGroupRequest$: Observable<Action> = this.actions$.pipe(
        ofType(ingredientGroups.ActionTypes.CREATE_INGREDIENT_GROUP_REQUEST),
        map((action: ingredientGroups.CreateIngredientGroupRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.ingredientGroupsService.createIngredientGroup(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Created ingredient group ${response.translations.en.name}`);
                    this.router.navigate(['/choice-lists']);
                }),
                map((response) => new ingredientGroups.CreateIngredientGroupSuccessAction(response)),
                catchError((error) =>
                    of(new ingredientGroups.CreateIngredientGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    deleteIngredientGroupRequest$: Observable<Action> = this.actions$.pipe(
        ofType(ingredientGroups.ActionTypes.DELETE_INGREDIENT_GROUP_REQUEST),
        map((action: ingredientGroups.DeleteIngredientGroupRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.ingredientGroupsService.deleteIngredientGroup(payload.id).pipe(
                tap(
                    () => {
                        this.notificationsService.success(`Deleted ingredient group`);
                        this.router.navigate([payload.redirectTo]);
                    },
                    () => this.modalService.close('delete-ingredient-group-modal'),
                ),
                map(() => new ingredientGroups.DeleteIngredientGroupSuccessAction()),
                catchError((error) =>
                    of(new ingredientGroups.DeleteIngredientGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );
}
