import { Injector } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Permissions } from '@core/models';
import * as fromRoot from '@core/reducers';
import { AuthSelectors } from '@core/selectors/auth.selectors';
import { NotificationsService } from '@core/services/notifications.service';

export class BaseGuard implements CanActivate {
    private router: Router;
    private notificationsService: NotificationsService;
    private permissions$: Observable<Permissions>;
    public path: string[] = [];
    public isNegative: boolean = false;

    constructor(private injector: Injector) {
        const store: Store<fromRoot.State> = injector.get(Store) as Store<fromRoot.State>;
        const authSelectors = injector.get(AuthSelectors);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);

        this.permissions$ = store.select(authSelectors.getIsAuthenticated()).pipe(
            filter((isAuthenticated: boolean) => isAuthenticated),
            mergeMap(() => store.select(authSelectors.getLocalUserPermissions())),
        );
    }

    getPermissionFromPath(userPermissions: Permissions): boolean {
        const permission = this.path.reduce((permissions: any, key: string): boolean => {
            return permissions[key];
        }, userPermissions);

        return this.isNegative ? !permission : permission;
    }

    redirectIfCantActivate(permission: boolean): void {
        if (!permission) {
            this.router.navigate(['/']);
            this.notificationsService.error('You do not have sufficient permissions to access this page');
        }
    }

    canActivate(): Observable<boolean> {
        return this.permissions$.pipe(
            map(this.getPermissionFromPath.bind(this)),
            tap(this.redirectIfCantActivate.bind(this)),
            map(Boolean),
        );
    }
}
