import { ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { Subscription } from 'rxjs';

@Directive({
    selector: '[inputNumber]',
})
export class InputNumberDirective implements OnInit, OnDestroy {
    @Input() inputNumber: FormControl;

    inputKeyUpSubscription: Subscription;

    constructor(private el: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

    /**
     * Subscribe to form control changes
     */
    ngOnInit() {
        this.inputKeyUpSubscription = this.inputNumber.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            let parsedValue = value.replace(/,/g, '.');
            parsedValue = parseFloat(parsedValue);

            if (!!parsedValue) {
                this.inputNumber.setValue(parsedValue);
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    /**
     * Unsubscribe on directive destroy
     */
    ngOnDestroy() {
        this.inputKeyUpSubscription.unsubscribe();
    }
}
