import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { SingleSectionComponent } from './single-section.component';

import { SectionGroupEvent } from '@core/models';
import { SectionGroupService } from '@core/services/section-group.service';

@Component({
    selector: 'section-group',
    template: '',
})
export class SectionGroupComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(SingleSectionComponent) sections: QueryList<SingleSectionComponent>;
    @Input() id: string;
    @Input() type: string;
    @Input() url?: string;
    @Output() sectionGroupChange: EventEmitter<SectionGroupEvent> = new EventEmitter();

    private changeEmitterSubscription: Subscription;
    private activeTimeout: any;
    private sectionsArray: SingleSectionComponent[];

    totalSections: number;
    activeSection: number;
    onSecondRun: boolean = false;

    /**
     * Component constructor function
     */
    constructor(protected activatedRoute: ActivatedRoute, protected sectionGroupService: SectionGroupService) {}

    /**
     * Count total sections, set selected section at first
     * and add instance to section groups list in service
     */
    ngAfterContentInit(): void {
        // Ensure id exists
        if (!this.id) {
            console.error('Section group must have an id!');
            return;
        }

        // Ensure id is unique
        if (!this.sectionGroupService.hasUniqueId(this.id)) {
            console.error('Sections group with this id already exist! Sections group id must be unique.');
            return;
        }

        this.sectionsArray = this.sections.toArray();
        this.totalSections = this.sectionsArray.length;

        this.sectionGroupService.add({
            id: this.id,
            type: this.type,
            totalSections: this.totalSections,
            activeSection: 0,
            url: '',
        });

        this.activeTimeout = setTimeout(() => {
            this.parseUrl();
            this.onSecondRun = true;
        });

        // Subscribe section group changes
        this.changeEmitterSubscription = this.sectionGroupService.getChangeEmitter().subscribe((item) => {
            item.id === this.id ? this.selectSection(item.index) : null;
        });

        // On changes of ContentChildren, update sectionsArray and total sections
        this.sections.changes.subscribe(() => {
            this.sectionsArray = this.sections.toArray();
            this.totalSections = this.sectionsArray.length;

            this.sectionGroupService.update(this.id, {
                totalSections: this.totalSections,
            });

            this.activeTimeout = setTimeout(() => {
                this.parseUrl();
            });
        });

        this.activatedRoute.queryParams.subscribe((params) => {
            if (this.onSecondRun) {
                const section = params.section ? this.findSectionBySlug(params.section) : null;
                if (section && this.activeSection === section.index) {
                    this.sectionGroupService.selectSection(this.id, section.index);
                }
            }
        });
    }

    /**
     * Match url input with section url and set this section active
     */
    parseUrl() {
        const sectionIndex = this.sectionsArray.findIndex((section) => section.url && section.url === this.url);

        this.selectSection(sectionIndex !== -1 ? sectionIndex : 0, sectionIndex !== -1);
    }

    findSectionBySlug(slug: string) {
        return {
            ...this.sectionsArray.find((section: SingleSectionComponent) => section.url === slug),
            index: this.sectionsArray.findIndex((section: SingleSectionComponent) => section.url === slug),
        };
    }

    /**
     * Set selected section active
     * and update instance to section group list in service
     * @param index Selected section index
     * @param notEmit True means that section group change should be ignore, default false
     */
    selectSection(index: number, notEmit: boolean = false): void {
        if (!this.isValidSectionNumber(index)) {
            return;
        }

        const url = this.sectionsArray[index].url;

        // Deactivate all sections besides selected one
        this.activeSection = index;
        this.sectionsArray.forEach((section) => (section.active = false));
        this.sectionsArray[index].active = true;

        this.sectionGroupService.update(this.id, {
            activeSection: index,
            url,
        });

        if (notEmit) {
            return;
        }

        this.sectionGroupChange.emit({
            id: this.id,
            type: this.type,
            totalSections: this.totalSections,
            activeSection: index,
            url,
        });
    }

    /**
     * Check if valid section index
     * @param index Selected section index
     * @returns boolean
     */
    isValidSectionNumber(index: number): boolean {
        return index >= 0 && index < this.totalSections;
    }

    /**
     * Remove section instance from section groups list in service
     * @param sections All sections
     * @returns Total sections number
     */
    ngOnDestroy(): void {
        clearTimeout(this.activeTimeout);
        this.changeEmitterSubscription && this.changeEmitterSubscription.unsubscribe();
        this.sectionGroupService.remove(this.id);
    }
}
