import { Pipe, PipeTransform } from '@angular/core';

export interface TranslationsFilterParams {
    query: string;
    lang?: string;
    field?: string;
}

const initialParams = {
    query: '',
    lang: 'en',
    field: 'name',
};

/**
 * A simple translations object filter.
 */
@Pipe({ name: 'translationsFilter' })
export class TranslationsFilterPipe implements PipeTransform {
    transform(value: any[], params: TranslationsFilterParams) {
        const p: TranslationsFilterParams = {
            ...initialParams,
            ...params,
        };

        if (!p.query || p.query === '') {
            return value;
        }
        return value.filter(
            (item) => -1 < item.translations[p.lang][p.field].toLowerCase().indexOf(p.query.toLowerCase()),
        );
    }
}
