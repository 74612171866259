import { fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { ModalService } from '@core/services/modal.service';

import { InputChanges, TypedOnChanges } from '@core/models';

type DimUnit = 'px' | '%';

interface HighlightTarget {
    target?: string;
    top: number;
    right: number;
    bottom: number;
    left: number;
    scroll: number;
    unit: DimUnit;
}

interface ModalPosition {
    active: boolean;
    x: number;
    y: number;
}

class Inputs {
    id: string;
    modalTitle: string;
    bodyClass: string;
    rootClass: string;
    showCloseButton: boolean;
    highlightedElementSelector: string;
    isAnimated: boolean;
}

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
})
export class ModalComponent implements Inputs, OnInit, OnChanges, TypedOnChanges<Inputs>, OnDestroy, AfterViewInit {
    private ngDestroy: Subject<never> = new Subject();

    @Input() id: string;
    @Input() modalTitle: string;
    @Input() modalDescription: string;
    @Input() bodyClass: string = '';
    @Input() rootClass: string = '';
    @Input() showCloseButton: boolean = true;
    @Input() highlightedElementSelector: string;
    @Input() isAnimated: boolean;
    @Input() minWidth: string;

    @Output() modalClose: EventEmitter<any> = new EventEmitter();
    @Output() modalOpen: EventEmitter<any> = new EventEmitter();

    @ViewChild('modalBody', { static: true }) modalBody: ElementRef;

    private animationTimeout: any = null;
    public highlightTarget: HighlightTarget;
    public isScrolling: boolean = false;
    public indicatorLeft: number = 40;
    public indicatorSet: 'top' | 'bottom';

    public modalPosition: ModalPosition = {
        active: false,
        x: 0,
        y: 0,
    };

    public get getModalPosition() {
        return this.modalPosition.active ? `translate(${this.modalPosition.x}px, ${this.modalPosition.y}px)` : '';
    }

    /**
     * Component constructor function
     */
    constructor(
        private changeDetector: ChangeDetectorRef,
        private componentElement: ElementRef,
        private modalService: ModalService,
    ) {
        (this.modalService.emitter as Observable<any>)
            .pipe(
                filter((item) => item.id === this.id),
                takeUntil(this.ngDestroy),
            )
            .subscribe((item) => {
                switch (item.command) {
                    case 'open': {
                        this.open();
                        break;
                    }
                    case 'close': {
                        this.close();
                        break;
                    }
                }
            });

        observableFromEvent(window, 'resize')
            .pipe(
                debounceTime(200),
                takeUntil(this.ngDestroy),
            )
            .subscribe(this.onResize.bind(this));
    }

    /**
     * If modal has id and this id is unique, set event listener and add modal to the modals list
     */
    ngOnInit() {
        // Ensure id exists
        if (!this.id) {
            console.error('Modal must have an id!');
            return;
        }

        // Ensure id is unique
        if (!this.modalService.hasUniqueId(this.id)) {
            console.error('Modal with this id already exist! Modal id must be unique.');
            return;
        }

        this.modalService.add({
            id: this.id,
            modalTitle: this.modalTitle,
            isOpened: false,
        });

        this.updateTargetElement();
    }

    ngOnChanges(changes: InputChanges<Inputs>): void {
        if (changes.highlightedElementSelector) {
            this.updateTargetElement();
        }
    }

    ngAfterViewInit(): void {
        this.updateTargetElement();
    }

    /**
     * Remove element from the modals list and modal-open class from body
     */
    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();

        document.body.classList.remove('modal-open');
        document.body.style.paddingRight = '0px';

        this.modalService.remove(this.id);
    }

    onBackgroundClick(event) {
        if (event.target.className === 'modal__wrapper') {
            this.modalService.close(this.id);
        }
    }

    updateTargetElement(): void {
        const scroller = document.scrollingElement || document.documentElement;
        const scroll = scroller.scrollTop || 0;

        this.updateHighlightPosition({
            target: this.highlightedElementSelector,
            top: 50,
            right: 50,
            bottom: 50,
            left: 50,
            scroll,
            unit: '%',
        });
    }

    updateHighlightPosition(coords: HighlightTarget): void {
        this.highlightTarget = coords;
        this.changeDetector.detectChanges();

        if (coords.unit !== '%') {
            setTimeout(() => {
                this.updateModalPosition(coords);
            }, 150);
        } else {
            this.indicatorLeft = 20;
            this.indicatorSet = null;
            this.modalPosition = {
                ...this.modalPosition,
                active: false,
                x: 0,
                y: 0,
            };
        }
    }

    updateModalPosition(coords: HighlightTarget): void {
        this.modalPosition = {
            ...this.modalPosition,
            active: true,
            x: 100,
            y: 100,
        };
    }

    onResize() {
        this.updateTargetElement();
    }

    /**
     * Open modal - set isOpened property to true for this modal in modal service,
     * and visible modificator to modal element class, emit open event at the end
     */
    open(): void {
        this.modalService.update(this.id, true);
        this.updateTargetElement();

        const disableAnimations = this.modalService.openedModals().length > 1;
        const classList = this.componentElement.nativeElement.querySelector('.modal').classList;

        disableAnimations ? classList.add('modal--disable-animations') : classList.remove('modal--disable-animations');
        classList.add('modal--visible');

        this.toggleModalOpenClass(!!this.modalService.openedModals().length);

        this.modalOpen.emit();
    }

    /**
     * Close modal - set isOpened property to false for this modal in modal service,
     * remove visible modificator from modal element class, emit close event at the end
     */
    close(): void {
        this.modalService.update(this.id, false);

        const disableAnimations = this.modalService.openedModals().length > 0;
        const classList = this.componentElement.nativeElement.querySelector('.modal').classList;

        disableAnimations ? classList.add('modal--disable-animations') : classList.remove('modal--disable-animations');
        classList.remove('modal--visible');

        this.toggleModalOpenClass(!!this.modalService.openedModals().length);

        this.modalClose.emit();
    }

    /**
     * Add or remove 'modal-open' class to body depends on modals state
     *
     * @param toggleState True if any modal opened, else false
     */
    toggleModalOpenClass(toggleState: boolean): void {
        this.clearAnimationTimeout();

        const classList = document.body.classList;
        const timeout = toggleState ? 0 : 250;

        this.animationTimeout = setTimeout(() => {
            toggleState ? classList.add('modal-open') : classList.remove('modal-open');
        }, timeout);
    }

    /**
     * Clear animation timeout
     */
    clearAnimationTimeout(): void {
        if (this.animationTimeout !== null) {
            clearTimeout(this.animationTimeout);
            this.animationTimeout = null;
        }
    }

  public onCloseButtonClick() {
    this.modalService.close(this.id);
  }
}
