import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'completeness-score',
    styleUrls: ['./completeness-score.component.scss'],
    templateUrl: './completeness-score.component.html',
})
export class CompletenessScoreComponent {
    @Input() completeness: number;
    @Input() readiness: boolean;
    @Input() size?: number = 70;
    @Input() stroke?: number = 3;
}
