import { Component, Input } from '@angular/core';
import { generateUniqueId } from '@app/utils';
import { environment} from '@env/environment';
import { UaePassLinkKeys } from '@app/auth/ngxs/auth.model';
import { Environment } from '@env/index';
import { AuthHttpClientService } from '@core/auth_httpclient/auth.service';

@Component({
  selector: 'uae-pass-button',
  templateUrl: './uae-pass-button.component.html',
  styleUrls: ['./uae-pass-button.component.scss']
})
export class UaePassButtonComponent {
  constructor(private authService: AuthHttpClientService) {}

  buildUaePassUrl(params: Environment['uaePassUrl'], state: string): string {
      const paramsWithState = {
        ...params,
        state: params.state || state
    };

      const queryParams = Object.entries(paramsWithState)
          .filter(([key, value]) => key !== 'url')
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      return `${params.url}&${queryParams}`;
  }

  redirectToUaePass() {
      const state = generateUniqueId();
      window.location.href = this.buildUaePassUrl(environment.uaePassUrl, state);
      this.authService.setItem(UaePassLinkKeys.state, state);
      this.authService.setItem(UaePassLinkKeys.uaePass, 'true');
  }
}
