import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromAuth from '../reducers/auth.reducer';

@Injectable()
export class AuthSelectors {
    getAuthState() {
        return ($state: State): any => $state.auth;
    }

    /**
     * Get signIn from state.signIn
     */
    getSignIn() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getSignIn,
        );
    }

    /**
     * Get signIn.errors from state.signIn
     */
    getSignInErrors() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getSignInErrors,
        );
    }

    /**
     * Get newPassword from state.newPassword
     */
    getNewPassword() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getNewPassword,
        );
    }

    /**
     * Get newPassword.errors from state.newPassword
     */
    getNewPasswordErrors() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getNewPasswordErrors,
        );
    }

    /**
     * Get activateAccount from state.activateAccount
     */
    getActivateAccount() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getActivteAccount,
        );
    }

    /**
     * Get activateAccount.errors from state.activateAccount
     */
    getActivateAccountErrors() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getActivteAccountErrors,
        );
    }

    /**
     * Get activateAccount.isFetching from state.activateAccount
     */
    getActivateAccountIsFetching() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getActivteAccountIsFetching,
        );
    }

    /**
     * Get localUser from state.localUser
     */
    getLocalUser() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getLocalUser,
        );
    }

    /**
     * Get localUser.data from state.localUser
     */
    getLocalUserData() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getLocalUserData,
        );
    }

    /**
     * Get localUser.permissions from state.localUser
     */
    getLocalUserPermissions() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getLocalUserPermissions,
        );
    }

    /**
     * Get localUser.errors from state.localUser
     */
    getLocalUserErrors() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getLocalUserErrors,
        );
    }

    getIsAuthenticated() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getIsAuthenticated,
        );
    }

    get isAuthenticated() {
        return createSelector(
            this.getAuthState(),
            fromAuth.getIsAuthenticated,
        );
    }
}
