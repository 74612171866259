import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

import { ProductId, ProductScoresData, ReadinessReportRequestPayload } from '@core/models';

export const ScorecardsActionTypes = prepareActions('[Product Manage]', [
    'GET_PRODUCT_SCORES_ERROR',
    'GET_PRODUCT_SCORES_REQUEST',
    'GET_PRODUCT_SCORES_SUCCESS',
    'CLEAR_PRODUCT_SCORES',

    'GET_READINESS_REPORT_ERROR',
    'GET_READINESS_REPORT_REQUEST',
    'GET_READINESS_REPORT_SUCCESS',
    'CLEAR_READINESS_REPORT',
]);

export class GetProductScoresRequestAction implements Action {
    readonly type = ScorecardsActionTypes.GET_PRODUCT_SCORES_REQUEST;
    constructor(public payload: ProductId) {}
}
export class GetProductScoresSuccessAction implements Action {
    readonly type = ScorecardsActionTypes.GET_PRODUCT_SCORES_SUCCESS;
    constructor(public payload: ProductScoresData) {}
}
export class GetProductScoresErrorAction implements Action {
    readonly type = ScorecardsActionTypes.GET_PRODUCT_SCORES_ERROR;
    constructor(public payload?: any) {}
}
export class ClearProductScoresAction implements Action {
    readonly type = ScorecardsActionTypes.CLEAR_PRODUCT_SCORES;
    constructor(public payload?: any) {}
}

export class GetReadinessReportRequestAction implements Action {
    readonly type = ScorecardsActionTypes.GET_READINESS_REPORT_REQUEST;
    constructor(public payload: ReadinessReportRequestPayload) {}
}
export class GetReadinessReportSuccessAction implements Action {
    readonly type = ScorecardsActionTypes.GET_READINESS_REPORT_SUCCESS;
    constructor(public payload: any) {}
}
export class GetReadinessReportErrorAction implements Action {
    readonly type = ScorecardsActionTypes.GET_READINESS_REPORT_ERROR;
    constructor(public payload?: any) {}
}
export class ClearReadinessReportAction implements Action {
    readonly type = ScorecardsActionTypes.CLEAR_READINESS_REPORT;
    constructor(public payload?: any) {}
}

export type ScorecardsActions =
    | GetProductScoresRequestAction
    | GetProductScoresErrorAction
    | GetProductScoresSuccessAction
    | ClearProductScoresAction
    | GetReadinessReportRequestAction
    | GetReadinessReportSuccessAction
    | GetReadinessReportErrorAction
    | ClearReadinessReportAction;
