import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as fromCompanyInternaData from '@core/reducers/company-internal-data.reducer';

@Injectable()
export class CompanyInternalDataSelectors {
    /**
     * Get state.internalData
     */
    getInternalDataState() {
        return ($state: State): any => $state.companyInteralData;
    }

    /**
     * Get import template from state.internalData
     */
    getImportTemplate() {
        return createSelector(
            this.getInternalDataState(),
            fromCompanyInternaData.getImportTemplate,
        );
    }
}
