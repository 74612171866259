import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import { PenultimateRoute } from '@core/models';
import * as fromRoot from '@core/reducers';
import { RouterSelectors } from '@core/selectors/router.selectors';

@Injectable()
export class RoutingHistoryService {
    public routerState$: Observable<RouterReducerState>;
    private history: string[] = [];

    constructor(
        private router: ActivatedRoute,
        private store: Store<fromRoot.State>,
        private routerSelectors: RouterSelectors,
    ) {
        this.routerState$ = this.store.select(routerSelectors.getRoutes());
        // TODO: do we really need this?
        this.routerState$.subscribe((snapshot) =>
            this.history.push(snapshot && snapshot.state ? snapshot.state.url : '/'),
        );
    }

    public getPenultimateRoute(): PenultimateRoute | null {
        const route = this.history[this.history.length - 2];

        return route
            ? {
                  url: this.getRouteUrl(route),
                  params: this.getRouteParams(route),
              }
            : null;
    }

    private getRouteUrl(route: string): string {
        return route.indexOf('?') !== -1 ? route.substr(0, route.indexOf('?')) : route;
    }

    private getRouteParams(route: string): any {
        return route.indexOf('?') !== -1
            ? route
                  .substr(route.indexOf('?') + 1)
                  .split('&')
                  .map((param) => param.split('='))
                  .reduce((acc, current) => {
                      const [key, value] = current.map(decodeURIComponent);
                      return {
                          ...acc,
                          [key]: value,
                      };
                  }, {})
            : {};
    }
}
