import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as fromListedProducts from '@core/reducers/listed-products.reducer';

import { ListedProductsListType, ListedProductsUI } from '@core/models';

@Injectable()
export class ListedProductsSelectors {
    /**
     * Get state.workflows
     */
    getListedProductsState() {
        return ($state: State): any => $state.listedProducts;
    }

    /**
     * Get list from state.listedProducts
     */
    getList(listType: ListedProductsListType = 'list') {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getList(listType),
        );
    }

    /**
     * Get counters from state.listedProducts
     */
    getCounters() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getCounters,
        );
    }

    /**
     * Get manage from state.listedProducts
     */
    getManage() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getManage,
        );
    }

    /**
     * Get productOptions from state.listedProducts
     */
    getOptions() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getOptions,
        );
    }

    /**
     * Get productOptions from state.listedProducts
     */
    getProductOptions() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getProductOptions,
        );
    }

    /**
     * Get changesHistory from state.listedProducts
     */
    getChangesHistory() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getChangesHistory,
        );
    }

    /**
     * Get sharesHistory from state.listedProducts
     */
    getSharesHistory() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getSharesHistory,
        );
    }

    /**
     * Get productReviews from state.listedProducts
     */
    getReviews() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getListedProductReviews,
        );
    }

    /**
     * Get summary from state.listedProducts
     */
    getSummary() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getListedProductSummary,
        );
    }

    /**
     * Get internal data preview from state.listedProducts
     */
    getInternalDataPreview() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getInternalDataPreview,
        );
    }

    getInitializeLists() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getInitializeLists,
        );
    }

    getSearchId() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getSearchId,
        );
    }

    getSearchParams() {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getSearchParams,
        );
    }

    getPageUIPart(part: keyof ListedProductsUI) {
        return createSelector(
            this.getListedProductsState(),
            fromListedProducts.getPageUIPart(part),
        );
    }
}
