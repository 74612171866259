<label
  [for]="label"
  class="form__label label"
  [ngClass]="{'label--required': required}">
  {{ label }}
</label>

<div class="form__row">
  <div class="form__group form__group--cols-4">
      <ng-content></ng-content>
      <ul class="errors errors--inside">
        <li
          *ngIf="
            required &&
            !isValid &&
            isTouched
          "
          class="errors__single-error">
          Field is required.
        </li>
        <li
          *ngFor="let error of errors"
          class="errors__single-error">
          {{ error }}
        </li>
      </ul>
  </div>
  <div class="form__group form-select__message form__group--cols-4">
    <p
      *ngIf="
        !errors &&
        isTouched &&
        isValid
      "
      class="form-select__success">
      <span class="form-select__success-icon icon-success"></span>
      <span *ngIf="successMessage.length">
        {{ successMessage }}
      </span>
    </p>
    <span
      *ngIf="
        isTouched &&
        (!isValid || errors)
      "
      class="form-select__error-icon icon-cancel">
    </span>
  </div>
</div>
