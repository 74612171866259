import { CompanyId, PartnerId, ProductCategory } from '@core/models';

export type DataRequirementId = number;
export type BulkDataRequirementActionType = 'create';

export type DataRequirementStatus =
    | 'a' // activated
    | 'd' // deactivated
    | 'c' // created
    | 'u'; // updated

export type DataRequirementStatusName = 'Activated' | 'Deactivated' | 'Created' | 'Updated';

export interface DataRequirement {
    entries?: DataRequirementAttribute[];
    category: number;
    categoryDisplay?: ProductCategory;
    company: CompanyId;
    id?: DataRequirementId;
    isActive: boolean;
    matchingPresetId?: number;
    hasMatchingPreset: boolean;
    name: string;
}

export interface DataRequirementAttribute {
    attributeGroup: number;
    attribute: number;
    value: DataRequirementValue;
}

export enum DataRequirementValue {
    MUST_HAVE,
    NICE_TO_HAVE,
    NOT_REQUIRED,
}

export interface BulkDataRequirementsRequest {
    ids: DataRequirementId[];
    isActive?: boolean;
    params?: {
        company: CompanyId;
        [key: string]: any;
    };
}
export interface DataRequirementsFilter {
    name: string;
    isActive: boolean;
    category: string;
}

export interface PartnerDataRequirementsParams {
    hasMatchingPreset: number;
    company: CompanyId;
    category: number;
    categoryName: string;
}

export interface DataRequirementForm {
    category: Partial<ProductCategory>;
    entries: DataRequirementAttribute[];
    isActive: boolean;
    name: string;
}

export interface DataRequirementsCovering {
    mandatory: DataRequirementCovering;
    optional: DataRequirementCovering;
}

export interface DataRequirementCovering {
    amount: number;
    covered: number;
}

export interface DataRequirementsAttributes {
    mandatory: DataRequirementAttributeWithType[];
    optional: DataRequirementAttributeWithType[];
}

export interface DataRequirementAttributeWithType extends DataRequirementAttribute {
    type:
        | 'textfield'
        | 'textarea'
        | 'number'
        | 'regex'
        | 'gtin'
        | 'date'
        | 'price'
        | 'simple_select'
        | 'multiple_select'
        | 'boolean'
        | 'nutrient'
        | 'allergen'
        | 'certificate'
        | 'metric'
        | 'size_metric'
        | 'image'
        | 'video'
        | 'file'
        | 'choices';
}

/// DATA REQUIREMENTS SUMMARY ///

/**
 * Slice of state for data requirements summary
 */
export interface DataRequirementsSummary {
    coveredCategoriesCount: number;
    totalCategoriesCount: number;
    sharedCategoriesCount: number;
    uncoveredCategoriesCount: number;
}

/**
 * Keep paylaod fields needed to send reqeust for data requirements stats
 *
 * @property company CompanyId - company ID of current user
 */
export interface GetDataRequirementsSummaryPayload {
    company: CompanyId;
}

export interface BulkCreateSharingPresetPayload {
    id: CompanyId;
    data: {
        ids: DataRequirementId[];
    };
    params: any;
    presetParams?: any;
}

export interface PartnerDataRequirementsPayload {
    company: CompanyId;
    partner?: PartnerId;
}

export interface DataRequirementsPayload {
    id: CompanyId;
    params: {
        company: PartnerId;
        [K: string]: any;
    };
}
export interface DataRequirementsHistoryLogsPayload {
    company: CompanyId;
    [key: string]: any;
}

export interface DataRequirementHistoryLog {
    dataRequirement: string;
    user: string;
    status: DataRequirementStatus;
    data: any;
    created: string;
}

export interface DataRequirementHistoryLogValue {
    value: DataRequirementValue;
    status: string;
    attribute: string;
}

export interface DataRequirementParsedHistoryData {
    groupName: string;
    entries: DataRequirementHistoryLogValue[];
}
