<div class="filename-creator">
  <p class="filename-creator__header filename-creator__header--selected label">Image(s) name format:</p>

  <ul
    class="filename-creator__selected-tags"
    [ngClass]="{'filename-creator__selected-tags--invalid': errors}">
    <li
      *ngFor="let tag of selectedTags; let i = index"
      class="filename-creator__tag filename-creator__tag--with-cross">
      {{ tag.displayName }}
      <span
        class="filename-creator__remove-btn"
        (click)="removeTag(i)">
        x
      </span>
    </li>

    <li class="filename-creator__new-tag">
      <input
        type="text"
        placeholder="Add string"
        class="filename-creator__input"
        [(ngModel)]="input"
        (keyup.enter)="addTag({
          displayName: input,
          value: input,
          isPredefined: false
        })">
    </li>
  </ul>

  <ul class="errors errors--inside">
    <li
      *ngFor="let error of errors"
      class="errors__single-error">
      {{ error }}
    </li>
  </ul>

  <ng-template [ngIf]="availableTags.length">
    <p class="filename-creator__header filename-creator__header--availabel label">Available predefined naming elements:</p>

    <ul class="filename-creator__available">
      <li
        *ngFor="let tag of availableTags"
        class="filename-creator__tag"
        (click)="addTag(tag)">
        {{ tag.displayName }}
      </li>
    </ul>
  </ng-template>
</div>
