import * as attributeGroupsActions from '../actions/attribute-groups.actions';
import { AttributeGroupsList, AttributeGroupsManage } from '../models';

export interface State {
    manage: AttributeGroupsManage;
    list: AttributeGroupsList;
}

const initialState: State = {
    manage: {
        data: {
            attributeGroupId: null,
            id: null,
            translations: {},
            attributeAssignments: [],
            modified: null,
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    list: {
        data: {
            count: null,
            limit: null,
            next: null,
            offset: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: attributeGroupsActions.Actions): State {
    switch (action.type) {
        case attributeGroupsActions.ActionTypes.GET_ATTRIBUTE_GROUPS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.GET_ATTRIBUTE_GROUPS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.GET_ATTRIBUTE_GROUPS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.GET_ATTRIBUTE_GROUP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                    isSaving: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.GET_ATTRIBUTE_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.GET_ATTRIBUTE_GROUP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.NEW_ATTRIBUTE_GROUP: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isFetching: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.CREATE_ATTRIBUTE_GROUP_REQUEST:
        case attributeGroupsActions.ActionTypes.SAVE_ATTRIBUTE_GROUP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.CREATE_ATTRIBUTE_GROUP_SUCCESS:
        case attributeGroupsActions.ActionTypes.SAVE_ATTRIBUTE_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.CREATE_ATTRIBUTE_GROUP_ERROR:
        case attributeGroupsActions.ActionTypes.SAVE_ATTRIBUTE_GROUP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.DELETE_ATTRIBUTE_GROUP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.DELETE_ATTRIBUTE_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.DELETE_ATTRIBUTE_GROUP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case attributeGroupsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsFetching = (state: State) => state.manage.isFetching;
export const getManageIsSaving = (state: State) => state.manage.isSaving;
