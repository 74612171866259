/**
 * Keep information about table column
 *
 * @export
 * @interface ColumnOptions
 */
export interface ColumnOptions {
    align?: string;
    displayName?: string;
    ellipsis?: boolean;
    zeroWidth?: boolean;
    isBulkCheckbox?: boolean;
    stickiness?: ColumnStickiness | ColumnStickiness[];
    left?: number;
    width?: string;
    minWidth?: string;
    sortable?: boolean;
    label?: string | null;
    point?: boolean;
    data?: any; // field for storing custom data
}

export type ColumnStickiness = 'top' | 'left';

/**
 * Keep information about table list options
 *
 * @export
 * @interface TableOptions
 */
export interface TableOptions {
    align?: string;
    border?: boolean;
    ellipsis?: boolean;
    hover?: boolean;
    smallText?: boolean;
    sortBy?: string;
    ordering?: Order;
    header?: boolean;
}

export enum Order {
    NONE,
    ASCENDING,
    DESCENDING,
}

export interface SortTableEvent {
    ordering: string;
}
