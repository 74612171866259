<div class="tabs-section-group">
  <div class="tabs-section-group__nav-wrapper">
    <div
      [ngClass]="{'tabs-section-group__nav--sticky': isSticky}"
      class="tabs-section-group__nav">
      <ul
        [ngClass]="{'tabs-section-group__nav-list--no-border': border}"
        class="tabs-section-group__nav-list">
        <li
          *ngFor="let section of sections; let index = index"
          class="tabs-section-group__nav-item"
          [ngClass]="{
            'tabs-section-group__nav-item--active': activeSection === index,
            'tabs-section-group__nav-item--invalid': !section.active && section.invalid,
            'tabs-section-group__nav-item--green': section.style === 'green',
            'tabs-section-group__nav-item--red': section.style === 'red',
            'tabs-section-group__nav-item--violet': section.style === 'violet',
            'tabs-section-group__nav-item--large': section.large,
            'tabs-section-group__nav-item--changes': section.unsavedChanges
          }"
          (click)="selectSection(index)">
          <a class="tabs-section-group__nav-link">
            <span>
              <span class="tabs-section-group__nav-text">{{ section.title | companyTypeTranslations }}</span>
              <ng-container *ngIf="section.counter !== null && section.counter !== undefined">
                <span
                  *ngIf="section.loading === undefined || !section.loading else loading"
                  class="tabs-section-group__nav-counter">
                  {{ section.counter }}
                </span>
                <ng-template #loading>
                  <div class="loader tabs-section-group__loader"><span class="tabs-section-group__nav-counter tabs-section-group__loader-text">{{ section.counter }}</span></div>
                </ng-template>
              </ng-container>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div
      class="tabs-section-group__content-wrapper"
      [ngClass]="{'tabs-section-group__content-wrapper--with-sidebar': withSidebar}"
  >
    <div
      class="tabs-section-group__content"
      [ngClass]="{'tabs-section-group__content--with-sidebar': withSidebar}"
    >
      <div
        class="tabs-section-group__content-inner"
        [ngClass]="{'tabs-section-group__content-inner--border': border}"
      >
        <ng-content></ng-content>
      </div>
    </div>

    <div
      *ngIf="withSidebar"
      class="tabs-section-group__sidebar"
    >
      <div class="tabs-section-group__sidebar-inner">
        <ng-content select="[tabs-sidebar]"></ng-content>
      </div>
    </div>
  </div>
</div>
