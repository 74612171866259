import { ProfileEntry } from '@app/auth/ngxs/auth.model';
import { OnboardingLog, SimpleUser } from '@core/models';

/**
 * Keep information sign in
 *
 * @export
 * @interface SignIn
 */
export interface SignIn {
    errors: any;
    isFetching: boolean;
}

/**
 * Keep information sign out
 *
 * @export
 * @interface SignOut
 */
export interface SignOut {
    errors: any;
    isFetching: boolean;
}

export interface NewPasswordCredentials {
    uid: string;
    token: string;
    newPassword: string;
}

/**
 * Keep information new password
 *
 * @export
 * @interface NewPassword
 */
export interface NewPassword {
    errors: any;
    isFetching: boolean;
}

/**
 * Keep information activate account
 *
 * @export
 * @interface ActivateAccount
 */
export interface ActivateAccount {
    errors: any;
    isFetching: boolean;
}

export interface ActivateAcountCredentials {
    uid: string;
    token: string;
    newPassword: string;
}

/**
 * Keep information about user
 *
 * @export
 * @interface LocalUser
 */
export interface LocalUser {
    data: SimpleUser;
    errors: any;
    isFetching: boolean;
    onboardingLogs: OnboardingLog[];
    permissions: Permissions;
    profiles?: ProfileEntry[];
}

/**
 * Summarized information about permissions of a user
 * This is supposed to be a readonly object, with all calculated absolute user permissions
 * (taking into account all the individual, group/role, company and plan permissions)
 *
 * @export
 * @interface Permissions
 */
export interface Permissions {
    attributeGroups: AttributeGroupPermissions;
    attributes: AttributePermissions;
    companies: CompanyPermissions;
    companyDomains: CompanyDomainPermissions;
    currentUser: CurrentUserPermissions;
    groups: UserGroupsPermissions;
    imagePlatform: ImagePlatformPermissions;
    ingredients: IngredientsPermissions;
    metrics: MetricPermissions;
    news: NewsPermissions;
    nutrients: NutrientPermissions;
    plans: PaidPlanPermissions;
    productCategories: ProductCategoryPermissions;
    products: ProductPermissions;
    productTemplates: ProductTemplatePermissions;
    publicApi: PublicApiPermissions;
    userLogs: UserLogPermissions;
    users: UserPermissions;
    workflows: WorkflowsPermissions;
}

/**
 * Permissions related to `attributes` module
 *
 * @export
 * @interface AttributePermissions
 */
export interface AttributePermissions {
    read: boolean;
    manage: boolean;
    lock: boolean;
}

/**
 * Permissions related to `attribute-groups` module
 *
 * @export
 * @interface AttributeGroupPermissions
 */
export interface AttributeGroupPermissions {
    read: boolean;
    manage: boolean;
}

/**
 * Permissions related to `companies` module
 *
 * @export
 * @interface CompanyPermissions
 */
export interface CompanyPermissions {
    read: boolean;
    manage: boolean;
    readOwn: boolean;
    manageOwn: boolean;
}

/**
 * Permissions related to `auth` module
 *
 * @export
 * @interface CurrentUserPermissions
 */
export interface CurrentUserPermissions {
    displayConfiguration: boolean;
    displayMyListings: boolean;
    runMaintenanceTasks: boolean;
}

/**
 * Permissions related to `metrics` module
 *
 * @export
 * @interface MetricPermissions
 */
export interface MetricPermissions {
    read: boolean;
    manage: boolean;
}

/**
 * Permissions related to `nutrients` module
 *
 * @export
 * @interface NutrientPermissions
 */
export interface NutrientPermissions {
    read: boolean;
    manage: boolean;
}

/**
 * Permissions related to `paid-plan` module
 *
 * @export
 * @interface PaidPlanPermissions
 */
export interface PaidPlanPermissions {
    read: boolean;
    manage: boolean;
}

/**
 * Permissions related to `product-categories` module
 *
 * @export
 * @interface ProductCategoryPermissions
 */
export interface ProductCategoryPermissions {
    read: boolean;
    manage: boolean;
}

/**
 * Permissions related to `product-templates` module
 *
 * @export
 * @interface ProductTemplatePermissions
 */
export interface ProductTemplatePermissions {
    read: boolean;
    manage: boolean;
}

/**
 * Permissions related to `products` module
 *
 * @export
 * @interface ProductPermissions
 */
export interface ProductPermissions {
    annotate: boolean;
    automateAnnotate: boolean;
    deleteOwn: boolean;
    export: boolean;
    import: boolean;
    manage: boolean;
    manageOwn: boolean;
    read: boolean;
    readOwn: boolean;
    share: boolean;
}

/**
 * Permissions related to `publicApi` module
 *
 * @export
 * @interface ProductPermissions
 */
export interface PublicApiPermissions {
    access: boolean;
}

/**
 * Permissions related to `users` module
 *
 * @export
 * @interface UserPermissions
 */
export interface UserPermissions {
    read: boolean;
    manage: boolean;
    readOwn: boolean;
    manageOwn: boolean;
}

/**
 * Permissions related to `user groups`
 * subfunctionality of `company` module
 *
 * @export
 * @interface UserGroupsPermissions
 */
export interface UserGroupsPermissions {
    read: boolean;
    manage: boolean;
    readOwn: boolean;
    manageOwn: boolean;
}

/**
 * Permissions related to `user-logs` module
 *
 * @export
 * @interface UserLogPermissions
 */
export interface UserLogPermissions {
    read: boolean;
}

/**
 * Permissions related to `company domains`
 * subfunctionality of `company` module
 *
 * @export
 * @interface CompanyDomainPermissions
 */
export interface CompanyDomainPermissions {
    read: boolean;
    manage: boolean;
}

/**
 * Permissions related to `user-logs` module
 *
 * @export
 * @interface NewsPermissions
 */
export interface NewsPermissions {
    manage: boolean;
}

/**
 * Permissions related to `image-platform` module
 *
 * @export
 * @interface ImagePlatformPermissions
 */
export interface ImagePlatformPermissions {
    access: boolean;
}

/**
 * Permissions related to `ingredients`
 *
 * @export
 * @interface IngredientsPermissions
 */
export interface IngredientsPermissions {
    read: boolean;
    manage: boolean;
}

/**
 * Permissions related to `workflows` module
 *
 * @export
 * @interface WorkflowsPermissions
 */
export interface WorkflowsPermissions {
    manage: boolean;
}

export interface PasswordVariations {
    length: boolean;
    special: boolean;
    number: boolean;
    lowerCase: boolean;
    upperCase: boolean;
}

export enum PasswordStrengthVariant {
    POOR = 1,
    WEAK,
    FAIR,
    GOOD,
    STRONG,
}
