import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Workflow History Logs]';

export const ActionTypes = {
    GET_LIST_REQUEST: type(`${ACTION_PREFIX} Get List Request`),
    GET_LIST_SUCCESS: type(`${ACTION_PREFIX} Get List Success`),
    GET_LIST_ERROR: type(`${ACTION_PREFIX} Get List Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),

    GET_PRODUCT_WORKFLOW_IDS_REQUEST: type(`${ACTION_PREFIX} Get Product Workflow Ids Request`),
    GET_PRODUCT_WORKFLOW_IDS_SUCCESS: type(`${ACTION_PREFIX} Get Product Workflow Ids Success`),
    GET_PRODUCT_WORKFLOW_IDS_ERROR: type(`${ACTION_PREFIX} Get Product Workflow Ids Error`),
    CLEAR_PRODUCT_WORKFLOW_IDS: type(`${ACTION_PREFIX} Clear Product Workflow Ids`),
};

/**
 * Create 'Get List Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetListRequestAction implements Action {
    type = ActionTypes.GET_LIST_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Succes' Action
 * @param payload Contains object with workflow history logs list and params
 */
export class GetListSuccessAction implements Action {
    type = ActionTypes.GET_LIST_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Error' Action
 * @param payload Contains object with errors
 */
export class GetListErrorAction implements Action {
    type = ActionTypes.GET_LIST_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetProductWorkflowIdsRequestAction implements Action {
    type = ActionTypes.GET_PRODUCT_WORKFLOW_IDS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Succes' Action
 * @param payload Contains object with workflow history logs list and params
 */
export class GetProductWorkflowIdsSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCT_WORKFLOW_IDS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Error' Action
 * @param payload Contains object with errors
 */
export class GetProductWorkflowIdsErrorAction implements Action {
    type = ActionTypes.GET_PRODUCT_WORKFLOW_IDS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearProductWorkflowIdsAction implements Action {
    type = ActionTypes.CLEAR_PRODUCT_WORKFLOW_IDS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetListRequestAction
    | GetListSuccessAction
    | GetListErrorAction
    | ClearListAction
    | GetProductWorkflowIdsRequestAction
    | GetProductWorkflowIdsSuccessAction
    | GetProductWorkflowIdsErrorAction
    | ClearProductWorkflowIdsAction;
