import * as partnersActions from '../actions/partners.actions';

import {
    Company,
    CustomDataList,
    DataRequirement,
    DataRequirementsSummary,
    DefaultList,
    DefaultManage,
    PartnerDataRequirementChanges,
    PartnerOverview,
    PartnersList,
} from '@core/models';

export interface State {
    list: PartnersList;
    partner: DefaultManage<Partial<Company>>;
    requirements: DefaultList<DataRequirement>;
    requirement: DefaultManage<DataRequirement>;
    summary: CustomDataList<DataRequirementsSummary>;
    changesNotifications: DefaultManage<PartnerDataRequirementChanges>;
    overview: DefaultList<PartnerOverview>;
}

const listInitialState: PartnersList = {
    data: [],
    errors: {},
    isFetching: true,
};

const summaryInitialState: CustomDataList<DataRequirementsSummary> = {
    data: {
        coveredCategoriesCount: 0,
        sharedCategoriesCount: 0,
        totalCategoriesCount: 0,
        uncoveredCategoriesCount: 0,
    },
    errors: {},
    isFetching: true,
};

const partnerInitialState: DefaultManage<Partial<Company>> = {
    data: {},
    errors: {},
    isFetching: true,
};

const requirementsInitialState: DefaultList<DataRequirement> = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
    },
    errors: {},
    isFetching: true,
};

const requirementInitialState: DefaultManage<DataRequirement> = {
    data: {
        category: null,
        categoryDisplay: null,
        company: null,
        entries: [],
        hasMatchingPreset: false,
        id: null,
        isActive: null,
        matchingPresetId: null,
        name: '',
    },
    errors: {},
    isFetching: true,
};

const changesNotificationsInitialState: DefaultManage<PartnerDataRequirementChanges> = {
    data: {
        notifications: [],
    },
    errors: {},
    isFetching: true,
};
const overviewInitialState: DefaultList<PartnerOverview> = {
    data: {
        count: null,
        limit: null,
        offset: null,
        next: null,
        previous: null,
        results: [
            {
                approvedProductsCount: null,
                created: null,
                isWorkflowActive: null,
                presetsCount: null,
                requirementsCount: null,
            },
        ],
    },
    errors: {},
    isFetching: true,
};

export const initialState: State = {
    list: listInitialState,
    partner: partnerInitialState,
    requirements: requirementsInitialState,
    requirement: requirementInitialState,
    summary: summaryInitialState,
    changesNotifications: changesNotificationsInitialState,
    overview: overviewInitialState,
};

export function reducer(state: State = initialState, action: partnersActions.Actions): State {
    switch (action.type) {
        case partnersActions.ActionTypes.GET_PARTNERS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case partnersActions.ActionTypes.GET_PARTNERS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case partnersActions.ActionTypes.GET_PARTNERS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case partnersActions.ActionTypes.CLEAR_PARTNERS: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                    isFetching: false,
                },
            };
        }

        case partnersActions.ActionTypes.GET_PARTNER_DATA_REQUEST: {
            return {
                ...state,
                partner: {
                    ...state.partner,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case partnersActions.ActionTypes.GET_PARTNER_DATA_SUCCESS: {
            return {
                ...state,
                partner: {
                    ...state.partner,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case partnersActions.ActionTypes.GET_PARTNER_DATA_ERROR: {
            return {
                ...state,
                partner: {
                    ...state.partner,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQUIREMENTS_REQUEST:
        case partnersActions.ActionTypes.BULK_CREATE_SHARING_PRESETS_REQUEST: {
            return {
                ...state,
                requirements: {
                    ...state.requirements,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQUIREMENTS_SUCCESS: {
            return {
                ...state,
                requirements: {
                    ...state.requirements,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQUIREMENTS_ERROR:
        case partnersActions.ActionTypes.BULK_CREATE_SHARING_PRESETS_ERROR: {
            return {
                ...state,
                requirements: {
                    ...state.requirements,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.BULK_CREATE_SHARING_PRESETS_SUCCESS: {
            return {
                ...state,
                requirements: {
                    ...state.requirements,
                    isFetching: false,
                },
            };
        }

        case partnersActions.ActionTypes.CLEAR_DATA_REQUIREMENTS: {
            return {
                ...state,
                requirements: {
                    ...initialState.requirements,
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQIUREMENT_REQUEST: {
            return {
                ...state,
                requirement: {
                    ...state.requirement,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQIUREMENT_SUCCESS: {
            return {
                ...state,
                requirement: {
                    ...state.requirement,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQIUREMENT_ERROR: {
            return {
                ...state,
                requirement: {
                    ...state.requirement,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.CLEAR_DATA_REQUIREMENT: {
            return {
                ...state,
                requirement: {
                    ...initialState.requirement,
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQUIREMENTS_SUMMARY_REQUEST: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQUIREMENTS_SUMMARY_SUCCESS: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.GET_DATA_REQUIREMENTS_SUMMARY_ERROR: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.CLEAR_DATA_REQUIREMENTS_SUMMARY: {
            return {
                ...state,
                summary: {
                    ...initialState.summary,
                },
            };
        }

        case partnersActions.ActionTypes.GET_CHANGES_NOTIFICATIONS_REQUEST: {
            return {
                ...state,
                changesNotifications: {
                    ...state.changesNotifications,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case partnersActions.ActionTypes.GET_OVERVIEW_REQUEST: {
            return {
                ...state,
                overview: {
                    ...state.overview,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case partnersActions.ActionTypes.GET_CHANGES_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                changesNotifications: {
                    ...state.changesNotifications,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.GET_OVERVIEW_SUCCESS: {
            return {
                ...state,
                overview: {
                    ...state.overview,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.GET_CHANGES_NOTIFICATIONS_ERROR: {
            return {
                ...state,
                changesNotifications: {
                    ...state.changesNotifications,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.GET_OVERVIEW_ERROR: {
            return {
                ...state,
                overview: {
                    ...state.overview,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case partnersActions.ActionTypes.CLEAR_CHANGES_NOTIFICATIONS:
        case partnersActions.ActionTypes.APPLY_CHANGES_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                changesNotifications: {
                    ...initialState.changesNotifications,
                },
            };
        }

        case partnersActions.ActionTypes.CLEAR_OVERVIEW: {
            return {
                ...state,
                overview: {
                    ...initialState.overview,
                },
            };
        }

        default:
            return state;
    }
}

export const getPartners = (state: State) => state.list;
export const getDataRequirements = (state: State) => state.requirements;
export const getDataRequirement = (state: State) => state.requirement;
export const getDataRequirementsSummary = (state: State) => state.summary;
export const getChangesNotifications = (state: State) => state.changesNotifications;
export const getOverview = (state: State) => state.overview;
