import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';

import * as workflows from '../actions/workflows.actions';
import { WorkflowsService } from '../services/workflows.service';

import * as partnerships from '../actions/partnerships.actions';

import { HTTPClientVer } from '@core/utils/request.utils';
import { NotificationsService } from '../services/notifications.service';
import { catchErrorJson } from './catch-error';

@Injectable()
export class WorkflowsEffects {
    constructor(
        private actions$: Actions,
        private workflowsService: WorkflowsService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    @Effect()
    getConfigurationRequest$: Observable<Action> = this.actions$.pipe(
        ofType(workflows.ActionTypes.GET_CONFIGURATION_REQUEST),
        map((action: workflows.GetConfigurationRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.workflowsService.getWorkflowConfiguration(payload).pipe(
                mergeMap((response) =>
                    from([
                        new partnerships.GetPartnershipRequestAction((response as any).companyPartnership),
                        new workflows.GetConfigurationSuccessAction(response),
                    ]),
                ),
                catchError((error) =>
                    of(new workflows.GetConfigurationErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    createConfigurationRequest$: Observable<Action> = this.actions$.pipe(
        ofType(workflows.ActionTypes.CREATE_CONFIGURATION_REQUEST),
        map((action: workflows.CreateConfigurationRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.workflowsService.createWorkflowConfiguration(payload).pipe(
                tap(() => this.notificationsService.success(`Created workflow configuration.`)),
                tap(() => this.router.navigate(['/workflows/configurations'])),
                map((response) => new workflows.CreateConfigurationSuccessAction(response)),
                catchError((error) =>
                    of(new workflows.CreateConfigurationErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    saveConfigurationRequest$: Observable<Action> = this.actions$.pipe(
        ofType(workflows.ActionTypes.SAVE_CONFIGURATION_REQUEST),
        map((action: workflows.SaveConfigurationRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.workflowsService.saveWorkflowConfiguration(payload).pipe(
                tap(() => this.notificationsService.success(`Saved workflow configuration.`)),
                tap(() => this.router.navigate(['/workflows/configurations'])),
                map((response) => new workflows.SaveConfigurationSuccessAction(response)),
                catchError((error) =>
                    of(new workflows.SaveConfigurationErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );
}
