import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromUsers from '../reducers/users.reducer';

@Injectable()
export class UsersSelectors {
    /**
     * Get state.companies
     */
    getUsersState() {
        return ($state: State): any => $state.users;
    }

    /**
     * Get list from state.users
     */
    getList() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getList,
        );
    }

    /**
     * Get list.data from state.users
     */
    getListData() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getListData,
        );
    }

    /**
     * Get list.errors from state.users
     */
    getListErrors() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.users
     */
    getListIsFetching() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getListIsFetching,
        );
    }

    /**
     * Get manage from state.users
     */
    getManage() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getManage,
        );
    }

    /**
     * Get manage.data from state.users
     */
    getManageData() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getManageData,
        );
    }

    /**
     * Get manage.errors from state.users
     */
    getManageErrors() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getManageErrors,
        );
    }

    /**
     * Get manage.isFetching from state.users
     */
    getManageIsFetching() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getManageIsFetching,
        );
    }

    /**
     * Get manage.isSaving from state.users
     */
    getManageIsSaving() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getManageIsSaving,
        );
    }

    /**
     * Get hardLogout from state.users
     */
    getHardLogout() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getHardLogout,
        );
    }

    /**
     * Get hardLogout.errors from state.users
     */
    getHardLogoutErrors() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getHardLogoutErrors,
        );
    }

    /**
     * Get hardLogout.isFetching from state.users
     */
    getHardLogoutIsFetching() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getHardLogoutIsFetching,
        );
    }

    /**
     * Get options from state.users
     */
    getOptions() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getOptions,
        );
    }

    /**
     * Get options.data from state.users
     */
    getOptionsData() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getOptionsData,
        );
    }

    /**
     * Get options.isFetching from state.users
     */
    getOptionsIsFetching() {
        return createSelector(
            this.getUsersState(),
            fromUsers.getOptionsIsFetching,
        );
    }
}
