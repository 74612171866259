/**
 * Auxiliary function to convert json payload.
 * There was use HttpModule which return in request.json() oryginaly named keys from API
 * in the BrandsyncApi is use camelCaseNameConvention
 * After changing the HttpModule to HttpClientModule the second one (which is needed in order
 * to update NG to newest version) convert the response to_hyphens_convention
 * This change force to improve all reducers or convert json's responses
 *
 * @param json
 * @return json
 */
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '@env/environment';

export const HTTPClientVer = 'HTTPClientVersion';

/**
 * Helper function joining all nested nonFieldErrors to a single string
 * if contains this kind of fields.
 */
export function getErrorMessage(httpErrorRes: HttpErrorResponse): string {
    if (httpErrorRes.error && httpErrorRes.error.nonFieldErrors) {
        /**
         * Following return get from auth service from recent M-company ;)
         */
        return httpErrorRes.error.nonFieldErrors.reduce((acc, currentMsg) => {
            return typeof currentMsg === 'string' ? `${acc} ${currentMsg}`.trim() : getErrorMessage(currentMsg);
        }, '');
    }

    if (httpErrorRes.error) {
        return httpErrorRes.error;
    }

    if ('nonFieldErrors' in httpErrorRes) {
        return httpErrorRes['nonFieldErrors'];
    }

    if (!environment.production) {
        console.error("Can't support error in getErrorMessage function");
        console.error(httpErrorRes);
    }
    return '';
}

/**
 * Function which takes leafs of error objects and concat them in one string
 * It's used to present toast to user with error message instead of SWW
 */
export function unpackErrorMessage(errorObj: object): string {
    return Object.values<any>(errorObj).reduce((acc, error) => {
        return `${acc}\n${getValue(error)}`.trim();
    }, '');
}

const concatObject = (sth: object): string => {
    if (!sth) {
        return '';
    }
    let msg = '';
    if (Array.isArray(sth)) {
        return sth.join(',');
    }
    Object.keys(sth).map((key) => {
        if (typeof sth[key] === 'object') {
            msg += concatObject(sth[key]);
        }
        if (typeof sth[key] === 'string') {
            msg += sth[key];
        }
    });
    return msg;
};

const getValue = (sth: any) => {
    switch (typeof sth) {
        case 'string':
            return sth;
        case 'object':
            return concatObject(sth);
        default:
            return sth;
    }
};

export const getErrorFromHttpErrorResponse = (httpErrorRes: HttpErrorResponse, searchedKey: string = '') => {
    if (searchedKey) {
        const errorValue = httpErrorRes.error[searchedKey];
        if (errorValue) {
            return getValue(errorValue);
        }
        return '';
    }

    let msg = '';

    Object.keys(httpErrorRes.error).map((key) => (msg += `"${key}": ${getValue(httpErrorRes.error[key])}`));
    return msg;
};
