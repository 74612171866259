import * as ingredientGroupsActions from '../actions/ingredient-groups.actions';
import { IngredientGroupsList, IngredientGroupsManage } from '../models';

export interface State {
    manage: IngredientGroupsManage;
    list: IngredientGroupsList;
}

const initialState: State = {
    manage: {
        data: {
            id: null,
            translations: {},
            ingredients: [],
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    list: {
        data: {
            results: [],
            previous: null,
            count: null,
            next: null,
            limit: null,
        },
        errors: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: ingredientGroupsActions.Actions): State {
    switch (action.type) {
        case ingredientGroupsActions.ActionTypes.GET_INGREDIENT_GROUPS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.GET_INGREDIENT_GROUPS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.GET_INGREDIENT_GROUPS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.GET_INGREDIENT_GROUP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                    isSaving: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.GET_INGREDIENT_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.GET_INGREDIENT_GROUP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.NEW_INGREDIENT_GROUP: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isFetching: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.CREATE_INGREDIENT_GROUP_REQUEST:
        case ingredientGroupsActions.ActionTypes.SAVE_INGREDIENT_GROUP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.CREATE_INGREDIENT_GROUP_SUCCESS:
        case ingredientGroupsActions.ActionTypes.SAVE_INGREDIENT_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.CREATE_INGREDIENT_GROUP_ERROR:
        case ingredientGroupsActions.ActionTypes.SAVE_INGREDIENT_GROUP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.DELETE_INGREDIENT_GROUP_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.DELETE_INGREDIENT_GROUP_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.DELETE_INGREDIENT_GROUP_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case ingredientGroupsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsFetching = (state: State) => state.manage.isFetching;
export const getManageIsSaving = (state: State) => state.manage.isSaving;
