import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromProductTemplates from '../reducers/product-templates.reducer';

/**
 * Selectors from state.productTemplates
 */
@Injectable()
export class ProductTemplatesSelectors {
    getProductTemplatesState() {
        return ($state: State): fromProductTemplates.State => $state.productTemplates;
    }

    getList() {
        return createSelector(
            this.getProductTemplatesState(),
            fromProductTemplates.getList,
        );
    }

    getManage() {
        return createSelector(
            this.getProductTemplatesState(),
            fromProductTemplates.getManage,
        );
    }
}
