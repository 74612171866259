import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromPartners from '../reducers/partners.reducer';

@Injectable()
export class PartnersSelectors {
    /**
     * Get state
     */
    getPartnersState() {
        return ($state: State): any => $state.partners;
    }

    /**
     * Get list from state
     */
    getPartners() {
        return createSelector(
            this.getPartnersState(),
            fromPartners.getPartners,
        );
    }

    /**
     * Get requirements from state
     */
    getDataRequirements() {
        return createSelector(
            this.getPartnersState(),
            fromPartners.getDataRequirements,
        );
    }

    /**
     * Get requirement from state
     */
    getDataRequirement() {
        return createSelector(
            this.getPartnersState(),
            fromPartners.getDataRequirement,
        );
    }

    /**
     * Get summary from state
     */
    getDataRequirementsSummary() {
        return createSelector(
            this.getPartnersState(),
            fromPartners.getDataRequirementsSummary,
        );
    }

    /**
     * Get changesNotifications from state
     */
    getChangesNotifications() {
        return createSelector(
            this.getPartnersState(),
            fromPartners.getChangesNotifications,
        );
    }

    /**
     * Get overview from state
     */
    getOverview() {
        return createSelector(
            this.getPartnersState(),
            fromPartners.getOverview,
        );
    }
}
