import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SectionGroupService } from '../../../core/services/section-group.service';
import { SectionGroupComponent } from './section-group.component';

@Component({
    selector: 'steps-section-group',
    templateUrl: './steps-section-group.component.html',
    styleUrls: ['./steps-section-group.component.scss'],
})
export class StepsSectionGroupComponent extends SectionGroupComponent {
    @Input() sectionGroupType = 'steps';
    @Input() static: boolean = false;

    constructor(activatedRoute: ActivatedRoute, sectionGroupService: SectionGroupService) {
        super(activatedRoute, sectionGroupService);
    }
}
