<article
  class="channel-score"
>
  <div class="channel-score__field channel-score__field--logo">
    <img
      *ngIf="score.partnerLogo; else noLogo"
      [src]="score.partnerLogo"
      [alt]="score.partnerName"
      class="channel-score__partner-logo"
    >
  </div>

  <p class="channel-score__field channel-score__field--text">{{ score.partnerName }}</p>
  <p class="channel-score__field channel-score__field--text">
    <ng-container *ngIf="score.shared; else notShared">
      shared: {{ score.shared }}
    </ng-container>
  </p>

  <div class="channel-score__field channel-score__field--score">
    <completeness-score
      [completeness]="score.completeness"
      [readiness]="score.readiness"
      size="90"
    ></completeness-score>
  </div>
</article>

<ng-template #noLogo>
  <img
    src="assets/images/img-placeholder.png"
    class="channel-score__partner-logo"
    alt="No image"
  >
</ng-template>

<ng-template #notShared>
  not shared
</ng-template>
