import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProductScore } from '@core/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'channel-score',
    styleUrls: ['./channel-score.component.scss'],
    templateUrl: './channel-score.component.html',
})
export class ChannelScoreComponent {
    @Input() score: ProductScore;
}
