import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

import {
    BulkSaveProductsRequestPayload,
    CompanyId,
    GetProductAttributesDiffRequestPayload,
    GetProductRequestPayload,
    ImportOutputFormat,
    ProductId,
    ProductsChangesHistoryRequestPayload,
    SaveProductRequestPayload,
} from '@core/models';

export const ManageActionTypes = prepareActions('[Product Manage]', [
    'GET_PRODUCT_OPTIONS_REQUEST',
    'GET_PRODUCT_OPTIONS_SUCCESS',
    'CLEAR_PRODUCT_OPTIONS',

    'GET_PRODUCT_REQUEST',
    'GET_PRODUCT_SUCCESS',
    'GET_PRODUCT_ERROR',
    'CLEAR_MANAGE',

    'GET_PRODUCT_ATTRIBUTES_DIFF_REQUEST',
    'GET_PRODUCT_ATTRIBUTES_DIFF_SUCCESS',
    'GET_PRODUCT_ATTRIBUTES_DIFF_ERROR',

    'NEW_PRODUCT',
    'CREATE_PRODUCT_REQUEST',
    'CREATE_PRODUCT_SUCCESS',
    'CREATE_PRODUCT_ERROR',

    'SAVE_PRODUCT_REQUEST',
    'SAVE_PRODUCT_SUCCESS',
    'SAVE_PRODUCT_ERROR',

    'BULK_SAVE_PRODUCTS_REQUEST',
    'BULK_SAVE_PRODUCTS_SUCCESS',
    'BULK_SAVE_PRODUCTS_ERROR',
    'CLEAR_MANAGE_BULK',

    'SAVE_PRODUCT_DETAILS_REQUEST',
    'SAVE_PRODUCT_DETAILS_SUCCESS',
    'SAVE_PRODUCT_DETAILS_ERROR',

    'SAVE_PRODUCT_RELATIONS_REQUEST',
    'SAVE_PRODUCT_RELATIONS_SUCCESS',
    'SAVE_PRODUCT_RELATIONS_ERROR',

    'GET_CHANGES_HISTORY_REQUEST',
    'GET_CHANGES_HISTORY_SUCCESS',
    'GET_CHANGES_HISTORY_ERROR',
    'CLEAR_CHANGES_HISTORY',

    'GET_SHARES_HISTORY_REQUEST',
    'GET_SHARES_HISTORY_SUCCESS',
    'GET_SHARES_HISTORY_ERROR',
    'CLEAR_SHARES_HISTORY',

    'GET_IMPORT_TEMPLATE_REQUEST',
    'GET_IMPORT_TEMPLATE_SUCCESS',
    'GET_IMPORT_TEMPLATE_ERROR',
    'CLEAR_IMPORT_TEMPLATE',

    'SAVE_PRODUCT_INTERNAL_DATA_REQUEST',
    'SAVE_PRODUCT_INTERNAL_DATA_SUCCESS',
    'SAVE_PRODUCT_INTERNAL_DATA_ERROR',

    'UPLOAD_PRODUCT_FILES_REQUEST',
    'UPLOAD_PRODUCT_FILES_SUCCESS',
    'UPLOAD_PRODUCT_FILES_ERROR',
    'UPLOAD_PRODUCT_RECEIPTS_SUCCESS',

    'DELETE_PRODUCT_FILES_REQUEST',
    'DELETE_PRODUCT_FILES_SUCCESS',
    'DELETE_PRODUCT_FILES_ERROR',
]);

export class GetProductOptionsRequestAction implements Action {
    readonly type = ManageActionTypes.GET_PRODUCT_OPTIONS_REQUEST;
    constructor(public payload?: ProductId) {}
}
export class GetProductOptionsSuccessAction implements Action {
    readonly type = ManageActionTypes.GET_PRODUCT_OPTIONS_SUCCESS;
    constructor(public payload?: any) {}
}
export class ClearProductOptionsAction implements Action {
    readonly type = ManageActionTypes.CLEAR_PRODUCT_OPTIONS;
    constructor(public payload?: any) {}
}

export class GetProductRequestAction implements Action {
    readonly type = ManageActionTypes.GET_PRODUCT_REQUEST;
    constructor(public payload: GetProductRequestPayload) {}
}
export class GetProductSuccessAction implements Action {
    readonly type = ManageActionTypes.GET_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetProductErrorAction implements Action {
    readonly type = ManageActionTypes.GET_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}
export class ClearManageAction implements Action {
    readonly type = ManageActionTypes.CLEAR_MANAGE;
    constructor(public payload?: any) {}
}

export class GetProductAttributesDiffRequestAction implements Action {
    readonly type = ManageActionTypes.GET_PRODUCT_ATTRIBUTES_DIFF_REQUEST;
    constructor(public payload: GetProductAttributesDiffRequestPayload) {}
}
export class GetProductAttributesDiffSuccessAction implements Action {
    readonly type = ManageActionTypes.GET_PRODUCT_ATTRIBUTES_DIFF_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetProductAttributesDiffErrorAction implements Action {
    readonly type = ManageActionTypes.GET_PRODUCT_ATTRIBUTES_DIFF_ERROR;
    constructor(public payload?: any) {}
}

export class NewProductAction implements Action {
    readonly type = ManageActionTypes.NEW_PRODUCT;
    constructor(public payload?: any) {}
}
export class CreateProductRequestAction implements Action {
    readonly type = ManageActionTypes.CREATE_PRODUCT_REQUEST;
    constructor(public payload?: any) {}
}
export class CreateProductSuccessAction implements Action {
    readonly type = ManageActionTypes.CREATE_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class CreateProductErrorAction implements Action {
    readonly type = ManageActionTypes.CREATE_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}

export class SaveProductRequestAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_REQUEST;
    constructor(public payload: SaveProductRequestPayload) {}
}
export class SaveProductSuccessAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class SaveProductErrorAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}

export class BulkSaveProductsRequestAction implements Action {
    readonly type = ManageActionTypes.BULK_SAVE_PRODUCTS_REQUEST;
    constructor(public payload: BulkSaveProductsRequestPayload) {}
}
export class BulkSaveProductsSuccessAction implements Action {
    readonly type = ManageActionTypes.BULK_SAVE_PRODUCTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class BulkSaveProductsErrorAction implements Action {
    readonly type = ManageActionTypes.BULK_SAVE_PRODUCTS_ERROR;
    constructor(public payload?: any) {}
}
export class ClearManageBulkAction implements Action {
    readonly type = ManageActionTypes.CLEAR_MANAGE_BULK;
    constructor(public payload?: any) {}
}

export class SaveProductDetailsRequestAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_DETAILS_REQUEST;
    constructor(public payload?: any) {}
}
export class SaveProductDetailsSuccessAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_DETAILS_SUCCESS;
    constructor(public payload?: any) {}
}
export class SaveProductDetailsErrorAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_DETAILS_ERROR;
    constructor(public payload?: any) {}
}

export class SaveProductRelationsRequestAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_RELATIONS_REQUEST;
    constructor(public payload?: any) {}
}
export class SaveProductRelationsSuccessAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_RELATIONS_SUCCESS;
    constructor(public payload?: any) {}
}
export class SaveProductRelationsErrorAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_RELATIONS_ERROR;
    constructor(public payload?: any) {}
}

export class GetChangesHistoryRequestAction implements Action {
    readonly type = ManageActionTypes.GET_CHANGES_HISTORY_REQUEST;
    constructor(public payload?: ProductsChangesHistoryRequestPayload) {}
}
export class GetChangesHistorySuccessAction implements Action {
    readonly type = ManageActionTypes.GET_CHANGES_HISTORY_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetChangesHistoryErrorAction implements Action {
    readonly type = ManageActionTypes.GET_CHANGES_HISTORY_ERROR;
    constructor(public payload?: any) {}
}
export class ClearChangesHistoryAction implements Action {
    readonly type = ManageActionTypes.CLEAR_CHANGES_HISTORY;
    constructor(public payload?: any) {}
}

export class GetSharesHistoryRequestAction implements Action {
    readonly type = ManageActionTypes.GET_SHARES_HISTORY_REQUEST;
    constructor(public payload?: any) {}
}
export class GetSharesHistorySuccessAction implements Action {
    readonly type = ManageActionTypes.GET_SHARES_HISTORY_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetSharesHistoryErrorAction implements Action {
    readonly type = ManageActionTypes.GET_SHARES_HISTORY_ERROR;
    constructor(public payload?: any) {}
}
export class ClearSharesHistoryAction implements Action {
    readonly type = ManageActionTypes.CLEAR_SHARES_HISTORY;
    constructor(public payload?: any) {}
}

export class GetImportTemplateRequestAction implements Action {
    readonly type = ManageActionTypes.GET_IMPORT_TEMPLATE_REQUEST;
    constructor(public payload?: any) {}
}
export class GetImportTemplateSuccessAction implements Action {
    readonly type = ManageActionTypes.GET_IMPORT_TEMPLATE_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetImportTemplateErrorAction implements Action {
    readonly type = ManageActionTypes.GET_IMPORT_TEMPLATE_ERROR;
    constructor(public payload?: any) {}
}
export class ClearImportTemplateAction implements Action {
    readonly type = ManageActionTypes.CLEAR_IMPORT_TEMPLATE;
    constructor(public payload?: any) {}
}

export class SaveProductInternalDataRequestAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_INTERNAL_DATA_REQUEST;
    constructor(public payload?: any) {}
}
export class SaveProductInternalDataSuccessAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_INTERNAL_DATA_SUCCESS;
    constructor(public payload?: any) {}
}
export class SaveProductInternalDataErrorAction implements Action {
    readonly type = ManageActionTypes.SAVE_PRODUCT_INTERNAL_DATA_ERROR;
    constructor(public payload?: any) {}
}

export class UploadProductFilesRequestAction implements Action {
    readonly type = ManageActionTypes.UPLOAD_PRODUCT_FILES_REQUEST;
    constructor(public payload?: any) {}
}
export class UploadProductFilesSuccessAction implements Action {
    readonly type = ManageActionTypes.UPLOAD_PRODUCT_FILES_SUCCESS;
    constructor(public payload?: any) {}
}
export class UploadProductFilesErrorAction implements Action {
    readonly type = ManageActionTypes.UPLOAD_PRODUCT_FILES_ERROR;
    constructor(public payload?: any) {}
}

export class UploadProductReceiptsSuccessAction implements Action {
    readonly type = ManageActionTypes.UPLOAD_PRODUCT_RECEIPTS_SUCCESS;
    constructor(public payload?: any) {}
}

export class DeleteProductFilesRequestAction implements Action {
    readonly type = ManageActionTypes.DELETE_PRODUCT_FILES_REQUEST;
    constructor(public payload?: any) {}
}
export class DeleteProductFilesSuccessAction implements Action {
    readonly type = ManageActionTypes.DELETE_PRODUCT_FILES_SUCCESS;
    constructor(public payload?: any) {}
}
export class DeleteProductFilesErrorAction implements Action {
    readonly type = ManageActionTypes.DELETE_PRODUCT_FILES_ERROR;
    constructor(public payload?: any) {}
}

export type ManageActions =
    | GetProductOptionsRequestAction
    | GetProductOptionsSuccessAction
    | ClearProductOptionsAction
    | GetProductRequestAction
    | GetProductSuccessAction
    | GetProductErrorAction
    | ClearManageAction
    | GetProductAttributesDiffRequestAction
    | GetProductAttributesDiffSuccessAction
    | GetProductAttributesDiffErrorAction
    | NewProductAction
    | CreateProductRequestAction
    | CreateProductSuccessAction
    | CreateProductErrorAction
    | SaveProductRequestAction
    | SaveProductSuccessAction
    | SaveProductErrorAction
    | BulkSaveProductsRequestAction
    | BulkSaveProductsSuccessAction
    | BulkSaveProductsErrorAction
    | SaveProductDetailsRequestAction
    | SaveProductDetailsSuccessAction
    | SaveProductDetailsErrorAction
    | SaveProductRelationsRequestAction
    | SaveProductRelationsSuccessAction
    | SaveProductRelationsErrorAction
    | GetChangesHistoryRequestAction
    | GetChangesHistorySuccessAction
    | GetChangesHistoryErrorAction
    | ClearChangesHistoryAction
    | GetSharesHistoryRequestAction
    | GetSharesHistorySuccessAction
    | GetSharesHistoryErrorAction
    | ClearSharesHistoryAction
    | GetImportTemplateRequestAction
    | GetImportTemplateSuccessAction
    | GetImportTemplateErrorAction
    | ClearImportTemplateAction
    | SaveProductInternalDataRequestAction
    | SaveProductInternalDataSuccessAction
    | SaveProductInternalDataErrorAction
    | UploadProductFilesRequestAction
    | UploadProductFilesSuccessAction
    | UploadProductFilesErrorAction
    | UploadProductReceiptsSuccessAction
    | DeleteProductFilesRequestAction
    | DeleteProductFilesSuccessAction
    | DeleteProductFilesErrorAction;
