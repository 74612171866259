import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

import { ProductsExportPayload } from '@core/models';

export const UtilsActionTypes = prepareActions('[Products Utils]', [
    'GET_EXPORT_STATUS_REQUEST',
    'GET_EXPORT_STATUS_SUCCESS',
    'GET_EXPORT_STATUS_ERROR',
    'CLEAR_EXPORT_STATUS',

    'PREPARE_PRODUCT_MEDIAS_EXPORT_REQUEST',
    'PREPARE_PRODUCT_MEDIAS_EXPORT_SUCCESS',
    'PREPARE_PRODUCT_MEDIAS_EXPORT_ERROR',

    'PREPARE_PRODUCTS_EXPORT_REQUEST',
    'PREPARE_PRODUCTS_EXPORT_SUCCESS',
    'PREPARE_PRODUCTS_EXPORT_ERROR',

    'PREPARE_PRODUCTS_SHARING_HISTORY_EXPORT_REQUEST',
    'PREPARE_PRODUCTS_SHARING_HISTORY_EXPORT_SUCCESS',
    'PREPARE_PRODUCTS_SHARING_HISTORY_EXPORT_ERROR',

    'PREPARE_PRODUCTS_MEDIAS_EXPORT_REQUEST',
    'PREPARE_PRODUCTS_MEDIAS_EXPORT_SUCCESS',
    'PREPARE_PRODUCTS_MEDIAS_EXPORT_ERROR',

    'PREPARE_PRODUCTS_REJECTION_REASONS_EXPORT_REQUEST',
    'PREPARE_PRODUCTS_REJECTION_REASONS_EXPORT_SUCCESS',
    'PREPARE_PRODUCTS_REJECTION_REASONS_EXPORT_ERROR',

    'CLEAR_PREPARE_EXPORT',

    'PREPARE_LOCAL_IMPORT_REQUEST',
    'PREPARE_LOCAL_IMPORT_SUCCESS',
    'PREPARE_LOCAL_IMPORT_ERROR',

    'CLEAR_PREPARE_IMPORT',
]);

export class GetExportStatusRequestAction implements Action {
    readonly type = UtilsActionTypes.GET_EXPORT_STATUS_REQUEST;
    constructor(public payload?: any) {}
}
export class GetExportStatusSuccessAction implements Action {
    readonly type = UtilsActionTypes.GET_EXPORT_STATUS_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetExportStatusErrorAction implements Action {
    readonly type = UtilsActionTypes.GET_EXPORT_STATUS_ERROR;
    constructor(public payload?: any) {}
}
export class ClearExportStatusAction implements Action {
    readonly type = UtilsActionTypes.CLEAR_EXPORT_STATUS;
    constructor(public payload?: any) {}
}

export class PrepareProductMediasExportRequestAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCT_MEDIAS_EXPORT_REQUEST;
    constructor(public payload?: any) {}
}
export class PrepareProductMediasExportSuccessAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCT_MEDIAS_EXPORT_SUCCESS;
    constructor(public payload?: any) {}
}
export class PrepareProductMediasExportErrorAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCT_MEDIAS_EXPORT_ERROR;
    constructor(public payload?: any) {}
}

export class PrepareProductsExportRequestAction implements Action {
  readonly type = UtilsActionTypes.PREPARE_PRODUCTS_EXPORT_REQUEST;
  constructor(public payload?: ProductsExportPayload) {}
}

export class PrepareProductsExportSuccessAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_EXPORT_SUCCESS;
    constructor(public payload?: any) {}
}
export class PrepareProductsExportErrorAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_EXPORT_ERROR;
    constructor(public payload?: any) {}
}

export class PrepareProductsExportSharingHistoryRequestAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_SHARING_HISTORY_EXPORT_REQUEST;
    constructor(public payload?: any) {}
}
export class PrepareProductsExportSharingHistorySuccessAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_SHARING_HISTORY_EXPORT_SUCCESS;
    constructor(public payload?: any) {}
}
export class PrepareProductsExportSharingHistoryErrorAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_SHARING_HISTORY_EXPORT_ERROR;
    constructor(public payload?: any) {}
}

export class PrepareProductsMediasExportRequestAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_MEDIAS_EXPORT_REQUEST;
    constructor(public payload?: any) {}
}
export class PrepareProductsMediasExportSuccessAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_MEDIAS_EXPORT_SUCCESS;
    constructor(public payload?: any) {}
}
export class PrepareProductsMediasExportErrorAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_MEDIAS_EXPORT_ERROR;
    constructor(public payload?: any) {}
}

export class PrepareProductsRejectionReasonsExportRequestAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_REJECTION_REASONS_EXPORT_REQUEST;
    constructor(public payload?: ProductsExportPayload) {}
}
export class PrepareProductsRejectionReasonsExportSuccessAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_REJECTION_REASONS_EXPORT_SUCCESS;
    constructor(public payload?: never) {}
}
export class PrepareProductsRejectionReasonsExportErrorAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_PRODUCTS_REJECTION_REASONS_EXPORT_ERROR;
    constructor(public payload: any) {}
}

export class ClearPrepareExportAction implements Action {
    readonly type = UtilsActionTypes.CLEAR_PREPARE_EXPORT;
    constructor(public payload?: any) {}
}

export class PrepareLocalImportRequestAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_LOCAL_IMPORT_REQUEST;
    constructor(public payload?: any) {}
}
export class PrepareLocalImportSuccessAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_LOCAL_IMPORT_SUCCESS;
    constructor(public payload?: any) {}
}
export class PrepareLocalImportErrorAction implements Action {
    readonly type = UtilsActionTypes.PREPARE_LOCAL_IMPORT_ERROR;
    constructor(public payload?: any) {}
}

export class ClearPrepareImportAction implements Action {
    readonly type = UtilsActionTypes.CLEAR_PREPARE_IMPORT;
    constructor(public payload?: any) {}
}

export type UtilsActions =
    | GetExportStatusRequestAction
    | GetExportStatusSuccessAction
    | GetExportStatusErrorAction
    | ClearExportStatusAction
    | PrepareProductMediasExportRequestAction
    | PrepareProductMediasExportSuccessAction
    | PrepareProductMediasExportErrorAction
    | PrepareProductsExportRequestAction
    | PrepareProductsExportSuccessAction
    | PrepareProductsExportErrorAction
    | PrepareProductsExportSharingHistoryRequestAction
    | PrepareProductsExportSharingHistorySuccessAction
    | PrepareProductsExportSharingHistoryErrorAction
    | PrepareProductsMediasExportRequestAction
    | PrepareProductsMediasExportSuccessAction
    | PrepareProductsMediasExportErrorAction
    | PrepareProductsRejectionReasonsExportRequestAction
    | PrepareProductsRejectionReasonsExportSuccessAction
    | PrepareProductsRejectionReasonsExportErrorAction
    | ClearPrepareExportAction
    | PrepareLocalImportRequestAction
    | PrepareLocalImportSuccessAction
    | PrepareLocalImportErrorAction
    | ClearPrepareImportAction;
