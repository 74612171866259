import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as reducer from '@core/reducers/company-sharing-presets.reducer';

@Injectable()
export class CompanySharingPresetsSelectors {
    /**
     * Get state.companySharingPresets
     */
    getCompanySharingPresetsState() {
        return ($state: State): any => $state.companySharingPresets;
    }

    /**
     * Get list from state.companySharingPresets
     */
    getList() {
        return createSelector(
            this.getCompanySharingPresetsState(),
            reducer.getList,
        );
    }

    /**
     * Get manage from state.companySharingPresets
     */
    getManage() {
        return createSelector(
            this.getCompanySharingPresetsState(),
            reducer.getManage,
        );
    }

    /**
     * Get available attributes from state.companySharingPresets
     */
    getAvailableAttributes() {
        return createSelector(
            this.getCompanySharingPresetsState(),
            reducer.getAvailableAttributes,
        );
    }

    /**
     * Get configuration summary from state.companySharingPresets
     */
    getSummary() {
        return createSelector(
            this.getCompanySharingPresetsState(),
            reducer.getSummary,
        );
    }

    /**
     * Get partner requirements from state.companySharingPresets
     */
    getRequirements() {
        return createSelector(
            this.getCompanySharingPresetsState(),
            reducer.getRequirements,
        );
    }

    /**
     * Get partner changes notificatio s from state.companySharingPresets
     */
    getChangesNotifications() {
        return createSelector(
            this.getCompanySharingPresetsState(),
            reducer.getChangesNotifications,
        );
    }

    /**
     * Get history logs from state.companySharingPresets
     */
    getHistoryLogs() {
        return createSelector(
            this.getCompanySharingPresetsState(),
            reducer.getHistoryLogs,
        );
    }
}
