import { Diacritics } from './diacritics';
import { Option, OptionValue } from './option';

export type OptionListValue = OptionValue[];

export class OptionList {
    private _options: Option[];

    /* Consider using these for performance improvement. */
    // private _selection: Array<Option>;
    // private _filtered: Array<Option>;
    // private _value: OptionListValue;

    private _highlightedOption: Option = null;
    private _hasShown: boolean;

    constructor(options: any[]) {
        if (typeof options === 'undefined' || options === null) {
            options = [];
        }

        this._options = options.map((option) => {
            const o: Option = new Option(
                option.value,
                option.displayName,
                option.children ? true : false,
                option.isChild,
                option.childrenIds,
                option.details,
            );
            if (option.disabled) {
                o.disable();
            }
            return o;
        });

        this._hasShown = this._options.length > 0;
        this.highlight();
    }

    /** Options. **/

    get options(): Option[] {
        return this._options;
    }

    getOptionsByValue(value: OptionValue): Option[] {
        return this.options.filter((option) => {
            return option.value === value;
        });
    }

    /** Value. **/

    get value(): OptionListValue {
        return this.selection.map((selectedOption) => {
            return selectedOption.value;
        });
    }

    set value(v: OptionListValue) {
        v = typeof v === 'undefined' || v === null ? [] : v;

        this.options.forEach((option) => {
            option.selected = v.includes(option.value);
        });
    }

    /** Selection. **/

    get selection(): Option[] {
        return this.options.filter((option) => {
            return option.selected;
        });
    }

    select(option: Option, multiple: boolean) {
        if (!multiple) {
            this.clearSelection();
        }
        option.selected = true;
    }

    deselect(option: Option) {
        option.selected = false;
    }

    clearSelection() {
        this.options.forEach((option) => {
            option.selected = false;
        });
    }

    /** Filter. **/

    get filtered(): Option[] {
        return this.options.filter((option) => {
            return option.shown;
        });
    }

    filter(term: string, filterFromStart: boolean = false): boolean {
        let anyShown: boolean = false;

        if (term.trim() === '') {
            this.resetFilter();
            anyShown = this.options.length > 0;
        } else {
            // Default search mechanism for options without children.
            this.options.forEach((option) => {
                if (option.hasChildren) {
                    return;
                }

                const l: string = Diacritics.strip(option.displayName).toUpperCase();
                const t: string = Diacritics.strip(term).toUpperCase();
                option.shown = filterFromStart ? l.indexOf(t) === 0 : l.indexOf(t) > -1;

                if (option.shown) {
                    anyShown = true;
                }
            });

            // Custom search mechanism for options with children.
            // Hide parrentOption if all children are hidden.
            this.options.forEach((parentOption) => {
                if (!parentOption.hasChildren) {
                    return;
                }

                let anyChildrenShown: boolean = false;

                this.options.forEach((childOption) => {
                    if (!childOption.isChild || parentOption.childrenIds.includes(childOption.value)) {
                        return;
                    }

                    if (childOption.shown) {
                        anyChildrenShown = true;
                    }
                });

                parentOption.shown = anyChildrenShown;
            });
        }

        const toEmpty: boolean = this.hasShown && !anyShown;

        this.highlight();
        this._hasShown = anyShown;

        return toEmpty;
    }

    private resetFilter() {
        this.options.forEach((option) => {
            option.shown = true;
        });
    }

    /** Highlight. **/

    get highlightedOption(): Option {
        return this._highlightedOption;
    }

    highlight() {
        const option: Option = this.hasShownSelected() ? this.getFirstShownSelected() : this.getFirstShown();
        this.highlightOption(option);
    }

    highlightOption(option: Option) {
        this.clearHighlightedOption();

        if (option !== null) {
            option.highlighted = true;
            this._highlightedOption = option;
        }
    }

    highlightNextOption() {
        const shownOptions = this.filtered;
        const index = this.getHighlightedIndexFromList(shownOptions);

        if (index > -1 && index < shownOptions.length - 1) {
            this.highlightOption(shownOptions[index + 1]);
        }
    }

    highlightPreviousOption() {
        const shownOptions = this.filtered;
        const index = this.getHighlightedIndexFromList(shownOptions);

        if (index > 0) {
            this.highlightOption(shownOptions[index - 1]);
        }
    }

    private clearHighlightedOption() {
        if (this.highlightedOption !== null) {
            this.highlightedOption.highlighted = false;
            this._highlightedOption = null;
        }
    }

    private getHighlightedIndexFromList(options: Option[]) {
        for (let i = 0; i < options.length; i++) {
            if (options[i].highlighted) {
                return i;
            }
        }
        return -1;
    }

    getHighlightedIndex() {
        return this.getHighlightedIndexFromList(this.filtered);
    }

    /** Util. **/

    get hasShown(): boolean {
        return this._hasShown;
    }

    hasSelected() {
        return this.options.some((option) => {
            return option.selected;
        });
    }

    hasShownSelected() {
        return this.options.some((option) => {
            return option.shown && option.selected;
        });
    }

    private getFirstShown(): Option {
        for (const option of this.options) {
            if (option.shown) {
                return option;
            }
        }
        return null;
    }

    private getFirstShownSelected(): Option {
        for (const option of this.options) {
            if (option.shown && option.selected) {
                return option;
            }
        }
        return null;
    }

    // tslint:disable:member-ordering
    // v0 and v1 are assumed not to be undefined or null.
    static equalValues(v0: OptionListValue, v1: OptionListValue): boolean {
        if (v0.length !== v1.length) {
            return false;
        }

        const a = v0.slice().sort();
        const b = v1.slice().sort();

        return a.every((v, i) => {
            return v === b[i];
        });
    }
    // tslint:enable
}
