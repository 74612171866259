import { Action } from '@ngrx/store';

import { prepareActions } from '@app/utils';

export const OperationsActionTypes = prepareActions('[Product Manage]', [
    'ACKNOWLEDGE_PRODUCT_UPDATE_REQUEST',
    'ACKNOWLEDGE_PRODUCT_UPDATE_SUCCESS',
    'ACKNOWLEDGE_PRODUCT_UPDATE_ERROR',

    'CLONE_PRODUCT_REQUEST',
    'CLONE_PRODUCT_SUCCESS',
    'CLONE_PRODUCT_ERROR',

    'SOFT_DELETE_PRODUCT_REQUEST',
    'SOFT_DELETE_PRODUCT_SUCCESS',
    'SOFT_DELETE_PRODUCT_ERROR',

    'HARD_DELETE_PRODUCT_REQUEST',
    'HARD_DELETE_PRODUCT_SUCCESS',
    'HARD_DELETE_PRODUCT_ERROR',

    'RECOVER_PRODUCT_REQUEST',
    'RECOVER_PRODUCT_SUCCESS',
    'RECOVER_PRODUCT_ERROR',

    'DISCONTINUE_PRODUCT_REQUEST',
    'DISCONTINUE_PRODUCT_SUCCESS',
    'DISCONTINUE_PRODUCT_ERROR',

    'REVERT_DISCONTINUE_PRODUCT_REQUEST',
    'REVERT_DISCONTINUE_PRODUCT_SUCCESS',
    'REVERT_DISCONTINUE_PRODUCT_ERROR',

    'GET_BARCODE_REQUEST',
    'GET_BARCODE_SUCCESS',
    'GET_BARCODE_ERROR',
    'CLEAR_BARCODE',
]);

export class AcknowledgeProductUpdateRequestAction implements Action {
    readonly type = OperationsActionTypes.ACKNOWLEDGE_PRODUCT_UPDATE_REQUEST;
    constructor(public payload?: any) {}
}
export class AcknowledgeProductUpdateSuccessAction implements Action {
    readonly type = OperationsActionTypes.ACKNOWLEDGE_PRODUCT_UPDATE_SUCCESS;
    constructor(public payload?: any) {}
}
export class AcknowledgeProductUpdateErrorAction implements Action {
    readonly type = OperationsActionTypes.ACKNOWLEDGE_PRODUCT_UPDATE_ERROR;
    constructor(public payload?: any) {}
}

export class CloneProductRequestAction implements Action {
    readonly type = OperationsActionTypes.CLONE_PRODUCT_REQUEST;
    constructor(public payload?: any) {}
}
export class CloneProductSuccessAction implements Action {
    readonly type = OperationsActionTypes.CLONE_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class CloneProductErrorAction implements Action {
    readonly type = OperationsActionTypes.CLONE_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}

export class SoftDeleteProductRequestAction implements Action {
    readonly type = OperationsActionTypes.SOFT_DELETE_PRODUCT_REQUEST;
    constructor(public payload?: any) {}
}
export class SoftDeleteProductSuccessAction implements Action {
    readonly type = OperationsActionTypes.SOFT_DELETE_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class SoftDeleteProductErrorAction implements Action {
    readonly type = OperationsActionTypes.SOFT_DELETE_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}

export class HardDeleteProductRequestAction implements Action {
    readonly type = OperationsActionTypes.HARD_DELETE_PRODUCT_REQUEST;
    constructor(public payload?: any) {}
}
export class HardDeleteProductSuccessAction implements Action {
    readonly type = OperationsActionTypes.HARD_DELETE_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class HardDeleteProductErrorAction implements Action {
    readonly type = OperationsActionTypes.HARD_DELETE_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}

export class RecoverProductRequestAction implements Action {
    readonly type = OperationsActionTypes.RECOVER_PRODUCT_REQUEST;
    constructor(public payload?: any) {}
}
export class RecoverProductSuccessAction implements Action {
    readonly type = OperationsActionTypes.RECOVER_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class RecoverProductErrorAction implements Action {
    readonly type = OperationsActionTypes.RECOVER_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}

export class DiscontinueProductRequestAction implements Action {
    readonly type = OperationsActionTypes.DISCONTINUE_PRODUCT_REQUEST;
    constructor(public payload?: any) {}
}
export class DiscontinueProductSuccessAction implements Action {
    readonly type = OperationsActionTypes.DISCONTINUE_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class DiscontinueProductErrorAction implements Action {
    readonly type = OperationsActionTypes.DISCONTINUE_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}

export class RevertDiscontinueProductRequestAction implements Action {
    readonly type = OperationsActionTypes.REVERT_DISCONTINUE_PRODUCT_REQUEST;
    constructor(public payload?: any) {}
}
export class RevertDiscontinueProductSuccessAction implements Action {
    readonly type = OperationsActionTypes.REVERT_DISCONTINUE_PRODUCT_SUCCESS;
    constructor(public payload?: any) {}
}
export class RevertDiscontinueProductErrorAction implements Action {
    readonly type = OperationsActionTypes.REVERT_DISCONTINUE_PRODUCT_ERROR;
    constructor(public payload?: any) {}
}

export class GetBarcodeRequestAction implements Action {
    readonly type = OperationsActionTypes.GET_BARCODE_REQUEST;
    constructor(public payload?: any) {}
}

export class GetBarcodeSuccessAction implements Action {
    readonly type = OperationsActionTypes.GET_BARCODE_SUCCESS;
    constructor(public payload?: any) {}
}

export class GetBarcodeErrorAction implements Action {
    readonly type = OperationsActionTypes.GET_BARCODE_ERROR;
    constructor(public payload?: any) {}
}

export class ClearBarcodeImagesAction implements Action {
    readonly type = OperationsActionTypes.CLEAR_BARCODE;
    constructor(public payload?: any) {}
}

export type OperationsActions =
    | SoftDeleteProductRequestAction
    | SoftDeleteProductSuccessAction
    | SoftDeleteProductErrorAction
    | HardDeleteProductRequestAction
    | HardDeleteProductSuccessAction
    | HardDeleteProductErrorAction
    | RecoverProductRequestAction
    | RecoverProductSuccessAction
    | RecoverProductErrorAction
    | DiscontinueProductRequestAction
    | DiscontinueProductSuccessAction
    | DiscontinueProductErrorAction
    | RevertDiscontinueProductRequestAction
    | RevertDiscontinueProductSuccessAction
    | RevertDiscontinueProductErrorAction
    | CloneProductRequestAction
    | CloneProductSuccessAction
    | CloneProductErrorAction
    | AcknowledgeProductUpdateRequestAction
    | AcknowledgeProductUpdateSuccessAction
    | AcknowledgeProductUpdateErrorAction
    | GetBarcodeRequestAction
    | GetBarcodeSuccessAction
    | GetBarcodeErrorAction
    | ClearBarcodeImagesAction;
