import * as actions from '@core/actions/product-reviews.actions';
import { BulkAction, DefaultList, DefaultManage, RejectionReasons } from '@core/models';

import { listInitialState, statusInitialState } from '@core/reducers/utils';

export interface State {
    bulkActionStatus: BulkAction;
    productReviews: DefaultManage<any>;
    rejectionReasons: DefaultList<RejectionReasons>;
}

const manageInitialState: DefaultManage<any> = {
    data: {},
    errors: [],
    isFetching: false,
    isSaving: false,
    isSaved: false,
};

export const initialState: State = {
    bulkActionStatus: statusInitialState,
    productReviews: manageInitialState,
    rejectionReasons: { ...listInitialState },
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.ActionTypes.POST_PRODUCT_REVIEW_REQUEST:
        case actions.ActionTypes.POST_BULK_PRODUCTS_REVIEW_REQUEST: {
            return {
                ...state,
                productReviews: {
                    ...state.productReviews,
                    errors: [],
                    isSaving: true,
                    isSaved: false,
                },
            };
        }

        case actions.ActionTypes.POST_PRODUCT_REVIEW_SUCCESS: {
            return {
                ...state,
                productReviews: {
                    ...state.productReviews,
                    data: {
                        ...state.productReviews.data,
                        ...action.payload,
                    },
                    isSaving: false,
                    isSaved: true,
                },
            };
        }

        case actions.ActionTypes.POST_PRODUCT_REVIEW_ERROR: {
            return {
                ...state,
                productReviews: {
                    ...state.productReviews,
                    errors: action.payload,
                    isSaving: false,
                    isSaved: false,
                },
            };
        }

        case actions.ActionTypes.POST_BULK_PRODUCTS_REVIEW_SUCCESS: {
            return {
                ...state,
                productReviews: {
                    ...state.productReviews,
                    isSaving: false,
                    isSaved: true,
                },
                bulkActionStatus: {
                    ...state.bulkActionStatus,
                    ...action.payload,
                },
            };
        }

        case actions.ActionTypes.POST_BULK_PRODUCTS_REVIEW_ERROR: {
            return {
                ...state,
                productReviews: {
                    ...state.productReviews,
                    isSaving: false,
                    isSaved: false,
                },
                bulkActionStatus: {
                    ...initialState.bulkActionStatus,
                },
            };
        }

        case actions.ActionTypes.CLEAR_PRODUCTS_REVIEWS: {
            return {
                ...state,
                productReviews: {
                    ...initialState.productReviews,
                },
            };
        }

        case actions.ActionTypes.CLEAR_BULK_ACTION_STATUS: {
            return {
                ...state,
                bulkActionStatus: {
                    ...initialState.bulkActionStatus,
                },
            };
        }

        case actions.ActionTypes.GET_REJECTION_REASONS_REQUEST: {
            return {
                ...state,
                rejectionReasons: {
                    ...state.rejectionReasons,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case actions.ActionTypes.GET_REJECTION_REASONS_SUCCESS: {
            return {
                ...state,
                rejectionReasons: {
                    ...state.rejectionReasons,
                    data: {
                        ...state.rejectionReasons.data,
                        ...action.payload,
                    },
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_REJECTION_REASONS_ERROR: {
            return {
                ...state,
                rejectionReasons: {
                    ...state.rejectionReasons,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.CLEAR_REJECTION_REASONS: {
            return {
                ...state,
                rejectionReasons: {
                    ...initialState.rejectionReasons,
                },
            };
        }

        default:
            return state;
    }
}

export const getProductReviews = (state: State) => state.productReviews;
export const getRejectionReasons = (state: State) => state.rejectionReasons;
export const getBulkActionStatus = (state: State) => state.bulkActionStatus;
