<div
  class="select__dropdown dropdown"
  [ngClass]="{'select__dropdown--show-above': showAbove}"
  [ngStyle]="{'top.px': top, 'left.px': left, 'width.px': width}">

  <div
    class="select__dropdown-filter filter"
    *ngIf="!multiple && filterEnabled">
    <input
      class="input select__dropdown-input"
      #filterInput
      (click)="onSingleFilterClick($event)"
      (input)="onSingleFilterInput($event)"
      (keydown)="onSingleFilterKeydown($event)">
  </div>

  <div
    class="select__dropdown-container"
    [ngClass]="{'select__dropdown-container--scrollable': canScrollDown}">
    <div
      class="select__dropdown-options options"
      #optionsList>
      <div
        #unorderedList
        class="select__dropdown-list"
        (wheel)="onOptionsWheel($event)">
        <div
          class="select__dropdown-item"
          *ngFor="let option of optionList.filtered"
          [ngClass]="{
            'select__dropdown-item--highlighted': !option.hasChildren && option.highlighted,
            'select__dropdown-item--selected': !option.hasChildren && option.selected,
            'select__dropdown-item--disabled': option.disabled,
            'select__dropdown-item--has-children': option.hasChildren,
            'select__dropdown-item--is-child': option.isChild
          }"
          [ngStyle]="getOptionStyle(option)"
          (click)="!option.hasChildren ? onOptionClick(option) : null"
          (mouseover)="!option.hasChildren ? onOptionMouseover(option) : null">
          <ng-template #defaultOptionTemplate>
            {{option.displayName}}
          </ng-template>

          <ng-template
            [ngTemplateOutlet]="optionTemplate || defaultOptionTemplate"
            [ngTemplateOutletContext]="{ option: option }"
          ></ng-template>
        </div>
        <div
          *ngIf="optionList.hasShown && canLoadMore"
          class="select__dropdown-message">
          <button
            (click)="onLoadMoreClick()"
            class="btn btn--fluid">
            Load more...
          </button>
        </div>
        <div
          *ngIf="!optionList.hasShown"
          class="select__dropdown-message">
          {{ isFetching ? 'Searching...' : notFoundMsg }}
        </div>
      </div>
    </div>
  </div>
</div>
