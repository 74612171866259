import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Attributes]';

export const ActionTypes = {
    GET_ATTRIBUTES_REQUEST: `${ACTION_PREFIX} Get Attributes Request`,
    GET_ATTRIBUTES_SUCCESS: `${ACTION_PREFIX} Get Attributes Success`,
    GET_ATTRIBUTES_ERROR: `${ACTION_PREFIX} Get Attributes Error`,
    GET_ATTRIBUTE_REQUEST: `${ACTION_PREFIX} Get Attribute Request`,
    GET_ATTRIBUTE_SUCCESS: `${ACTION_PREFIX} Get Attribute Success`,
    GET_ATTRIBUTE_ERROR: `${ACTION_PREFIX} Get Attribute Error`,
    NEW_ATTRIBUTE: `${ACTION_PREFIX} New Attribute`,
    CREATE_ATTRIBUTE_REQUEST: `${ACTION_PREFIX} Create Attribute Request`,
    CREATE_ATTRIBUTE_SUCCESS: `${ACTION_PREFIX} Create Attribute Success`,
    CREATE_ATTRIBUTE_ERROR: `${ACTION_PREFIX} Create Attribute Error`,
    SAVE_ATTRIBUTE_REQUEST: `${ACTION_PREFIX} Save Attribute Request`,
    SAVE_ATTRIBUTE_SUCCESS: `${ACTION_PREFIX} Save Attribute Success`,
    SAVE_ATTRIBUTE_ERROR: `${ACTION_PREFIX} Save Attribute Error`,
    SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_REQUEST: `${ACTION_PREFIX} Save Attribute Certificate Image Request`,
    SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_SUCCESS: `${ACTION_PREFIX} Save Attribute Certificate Image Success`,
    SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_ERROR: `${ACTION_PREFIX} Save Attribute Certificate Image Error`,
    DELETE_ATTRIBUTE_REQUEST: `${ACTION_PREFIX} Delete Attribute Request`,
    DELETE_ATTRIBUTE_SUCCESS: `${ACTION_PREFIX} Delete Attribute Success`,
    DELETE_ATTRIBUTE_ERROR: `${ACTION_PREFIX} Delete Attribute Error`,
    DELETE_ATTRIBUTES_REQUEST: `${ACTION_PREFIX} Delete Attributes Request`,
    DELETE_ATTRIBUTES_SUCCESS: `${ACTION_PREFIX} Delete Attributes Success`,
    DELETE_ATTRIBUTES_ERROR: `${ACTION_PREFIX} Delete Attributes Error`,
    GET_IMPORT_TEMPLATE_REQUEST: `${ACTION_PREFIX} Get Import Template Request`,
    GET_IMPORT_TEMPLATE_SUCCESS: `${ACTION_PREFIX} Get Import Template Success`,
    GET_IMPORT_TEMPLATE_ERROR: `${ACTION_PREFIX} Get Import Template Error`,
    PREPARE_IMPORT_REQUEST: `${ACTION_PREFIX} Prepare Import Request`,
    PREPARE_IMPORT_SUCCESS: `${ACTION_PREFIX} Prepare Import Success`,
    PREPARE_IMPORT_ERROR: `${ACTION_PREFIX} Prepare Import Error`,
    GET_IMPORT_STATUS_REQUEST: `${ACTION_PREFIX} Get Import Status Request`,
    GET_IMPORT_STATUS_SUCCESS: `${ACTION_PREFIX} Get Import Status Success`,
    GET_IMPORT_STATUS_ERROR: `${ACTION_PREFIX} Get Import Status Error`,
    GET_OPTIONS_REQUEST: `${ACTION_PREFIX} Get Options Request`,
    GET_OPTIONS_SUCCESS: `${ACTION_PREFIX} Get Options Success`,
    CLEAR_LIST: `${ACTION_PREFIX} Clear List`,
    CLEAR_MANAGE: `${ACTION_PREFIX} Clear Manage`,
    CLEAR_IMPORT_TEMPLATE: `${ACTION_PREFIX} Clear Import Template`,
    CLEAR_PREPARE_IMPORT: `${ACTION_PREFIX} Clear Prepare Import`,
    CLEAR_IMPORT_STATUS: `${ACTION_PREFIX} Clear Import Status`,
    CLEAR_OPTIONS: `${ACTION_PREFIX} Clear Options`,
};

/**
 * Create 'Get Attributes Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetAttributesRequestAction implements Action {
    type = ActionTypes.GET_ATTRIBUTES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attributes Success' Action
 * @param payload Contains object with attributes list and params
 */
export class GetAttributesSuccessAction implements Action {
    type = ActionTypes.GET_ATTRIBUTES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attributes Error' Action
 * @param payload Contains object with errors
 */
export class GetAttributesErrorAction implements Action {
    type = ActionTypes.GET_ATTRIBUTES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Request' Action
 * @param payload Contains attribute id
 */
export class GetAttributeRequestAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Success' Action
 * @param payload Contains object with attribute data
 */
export class GetAttributeSuccessAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Error' Action
 * @param payload Contains object with errors
 */
export class GetAttributeErrorAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Attribute' Action
 * @param payload Contains attribute type
 */
export class NewAttributeAction implements Action {
    type = ActionTypes.NEW_ATTRIBUTE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Attribute Request' Action
 * @param payload Contains object with attribute data
 */
export class CreateAttributeRequestAction implements Action {
    type = ActionTypes.CREATE_ATTRIBUTE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Attribute Success' Action
 * @param payload Contains object with attribute data
 */
export class CreateAttributeSuccessAction implements Action {
    type = ActionTypes.CREATE_ATTRIBUTE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Attribute Error' Action
 * @param payload Contains object with errors
 */
export class CreateAttributeErrorAction implements Action {
    type = ActionTypes.CREATE_ATTRIBUTE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Request' Action
 * @param payload Contains object with attribute data
 */
export class SaveAttributeRequestAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Success' Action
 * @param payload Contains object with attribute data
 */
export class SaveAttributeSuccessAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Error' Action
 * @param payload Contains object with errors
 */
export class SaveAttributeErrorAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Certificate Image Request' Action
 * @param payload Contains object with attribute certificate image
 */
export class SaveAttributeCertificateImageRequestAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Certificate Image Success' Action
 * @param payload Contains object with attribute certificate image
 */
export class SaveAttributeCertificateImageSuccessAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Certificate Image Error' Action
 * @param payload Contains object with errors
 */
export class SaveAttributeCertificateImageErrorAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attribute Request' Action
 * @param payload Contains attribute id
 */
export class DeleteAttributeRequestAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attribute Success' Action
 */
export class DeleteAttributeSuccessAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attribute Error' Action
 * @param payload Contains object with errors
 */
export class DeleteAttributeErrorAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attributes Request' Action
 * @param payload Contains object with products' IDs and deleting mode
 */
export class DeleteAttributesRequestAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attributes Success' Action
 */
export class DeleteAttributesSuccessAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attributes Error' Action
 * @param payload Contains object with errors
 */
export class DeleteAttributesErrorAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Import Template Request' Action
 * @param payload Contains output mode
 */
export class GetImportTemplateRequestAction implements Action {
    type = ActionTypes.GET_IMPORT_TEMPLATE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Import Template Success' Action
 * @param payload Object contains file url
 */
export class GetImportTemplateSuccessAction implements Action {
    type = ActionTypes.GET_IMPORT_TEMPLATE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Import Template Error' Action
 * @param payload Contains object with errors
 */
export class GetImportTemplateErrorAction implements Action {
    type = ActionTypes.GET_IMPORT_TEMPLATE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Prepare Import Request' Action
 * @param payload
 */
export class PrepareImportRequestAction implements Action {
    type = ActionTypes.PREPARE_IMPORT_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Prepare Import Success' Action
 * @param payload Object contains Celery task id
 */
export class PrepareImportSuccessAction implements Action {
    type = ActionTypes.PREPARE_IMPORT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Prepare Import Error' Action
 * @param payload Contains object with errors
 */
export class PrepareImportErrorAction implements Action {
    type = ActionTypes.PREPARE_IMPORT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Import Status Request' Action
 * @param payload Celery task id
 */
export class GetImportStatusRequestAction implements Action {
    type = ActionTypes.GET_IMPORT_STATUS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Import Status Success' Action
 */
export class GetImportStatusSuccessAction implements Action {
    type = ActionTypes.GET_IMPORT_STATUS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Import Status Error' Action
 * @param payload Contains object with errors
 */
export class GetImportStatusErrorAction implements Action {
    type = ActionTypes.GET_IMPORT_STATUS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' Action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' Action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Import Template' Action
 */
export class ClearImportTemplateAction implements Action {
    type = ActionTypes.CLEAR_IMPORT_TEMPLATE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Prepare Import' Action
 */
export class ClearPrepareImportAction implements Action {
    type = ActionTypes.CLEAR_PREPARE_IMPORT;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Import Status' Action
 */
export class ClearImportStatusAction implements Action {
    type = ActionTypes.CLEAR_IMPORT_STATUS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetAttributesRequestAction
    | GetAttributesSuccessAction
    | GetAttributesErrorAction
    | GetAttributeRequestAction
    | GetAttributeSuccessAction
    | GetAttributeErrorAction
    | NewAttributeAction
    | CreateAttributeRequestAction
    | CreateAttributeSuccessAction
    | CreateAttributeErrorAction
    | SaveAttributeRequestAction
    | SaveAttributeSuccessAction
    | SaveAttributeErrorAction
    | SaveAttributeCertificateImageRequestAction
    | SaveAttributeCertificateImageSuccessAction
    | SaveAttributeCertificateImageErrorAction
    | DeleteAttributeRequestAction
    | DeleteAttributeSuccessAction
    | DeleteAttributeErrorAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | ClearListAction
    | ClearManageAction
    | ClearImportTemplateAction
    | ClearPrepareImportAction
    | ClearImportStatusAction
    | ClearOptionsAction;
