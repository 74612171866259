import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable()
export class CustomerNameService {
    constructor(private store: Store<any>) {}

    public getCompanyInfo() {
        return this.store.select((store) => store.auth.localUser.data.company).pipe(take(1));
    }
}
