<table-list
  [tableOptions]="{align: 'center', border: true}"
  [columnsOptions]="columnOptions">
</table-list>
<ng-container *ngFor="let relation of relations; let i = index">
  <product-form-control
    *ngIf="!isEditable"
    [id]="relationrelationType + '-' + relation.id.toString()"
    [selectMode]="selectMode"
    [formControl]="relationsForm.get(relation.id.toString())">
    <ng-container *ngTemplateOutlet="tableList; context: {relation: relation, index: i}"></ng-container>
  </product-form-control>

  <ng-container *ngIf="isEditable">
    <ng-container *ngTemplateOutlet="tableList; context: {relation: relation, index: i}"></ng-container>
  </ng-container>
</ng-container>

<ng-template #tableList let-relation="relation" let-index="index">
  <table-list
    [tableOptions]="{align: 'center', border: true, header: false}"
    [columnsOptions]="columnOptions">
    <tr class="table__row">
      <td *ngIf="relationType === 'variants'" class="table__cell">
        <figure
          class="table__img"
          [ngClass]="{'table__img--empty': !relation.frontFaceImage}">
          <img
            *ngIf="relation.frontFaceImage"
            [attr.src]="relation.frontFaceImage">
        </figure>
      </td>

      <td class="table__cell">
        <ng-template [ngIf]="relationType === 'variants'">
          {{ relation.tradeItemDescription ? relation.tradeItemDescription : '-' }}
        </ng-template>

        <ng-template [ngIf]="relationType === 'cases'">
          {{ relation.parentData.tradeItemDescription ? relation.parentData.tradeItemDescription : '-' }}
        </ng-template>

        <ng-template [ngIf]="relationType === 'caseChildren'">
          {{ relation.childData.tradeItemDescription ? relation.childData.tradeItemDescription : '-' }}
        </ng-template>

        <ul
          *ngIf="getErrors(relationType, index, 'description')?.length"
          class="errors errors--block errors--clear-margin errors--margin-top-8">
          <li
            *ngFor="let error of getErrors(relationType, index, 'description')"
            class="errors__single-error">
            {{ error }}
          </li>
        </ul>
      </td>

      <td class="table__cell">
        <ng-template [ngIf]="relationType === 'variants'">
          {{ relation.gtin ? relation.gtin : '-' }}
        </ng-template>

        <ng-template [ngIf]="relationType === 'cases'">
          {{ relation.parentData.gtin ? relation.parentData.gtin : '-' }}
        </ng-template>

        <ng-template [ngIf]="relationType === 'caseChildren'">
          {{ relation.childData.gtin ? relation.childData.gtin : '-' }}
        </ng-template>

        <ul
          *ngIf="getErrors(relationType, index, 'gtin')?.length"
          class="errors errors--block errors--clear-margin errors--margin-top-8">
          <li
            *ngFor="let error of getErrors(relationType, index, 'gtin')"
            class="errors__single-error">
            {{ error }}
          </li>
        </ul>
      </td>

      <td class="table__cell">
        {{ relation.quantity ? relation.quantity : '-' }}

        <ul
          *ngIf="getErrors(relationType, index, 'quantity')?.length"
          class="errors errors--block errors--clear-margin errors--margin-top-8">
          <li
            *ngFor="let error of getErrors(relationType, index, 'quantity')"
            class="errors__single-error">
            {{ error }}
          </li>
        </ul>
      </td>

      <td *ngIf="isEditable" class="table__cell">
        <button
          (click)="removeFromTable(relationType, index)"
          type="button"
          class="btn btn--alert btn--smaller">
          Remove
        </button>
      </td>
    </tr>
    <tr
      *ngIf="!relations.length"
      class="table__row">
      <td
        class="table__cell table__cell--nodata"
        [attr.colspan]="columnOptions.length">
        No data to display
      </td>
    </tr>
  </table-list>
</ng-template>
