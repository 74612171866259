import { HttpErrorResponse } from '@angular/common/http';

import { getErrorMessage, HTTPClientVer } from '@core/utils/request.utils';

export const INVALID_CREDENTIALS_BE = 'Invalid credentials.';

/**
 * Prevent app crash by catching error from error.json() function if response is not in json format.
 * @param error Error Response object
 * @param version number to specify which version of catching should be use
 * @returns JSON object
 */
export const catchErrorJson = (error: any, version: string = ''): any => {
    let json: any = {};

    if (!version) {
        try {
            json = error.json();
        } catch (e) {
            console.error(error);
        }
    } else if (version === HTTPClientVer) {
        if (error.name === 'HttpErrorResponse') {
            return getErrorMessage(error as HttpErrorResponse);
        }
        try {
            return JSON.parse(error);
        } catch (e) {
            return 'Error during parse error response';
        }
    }

    return json;
};

export const catchErrorHttpClientJson = (error: any) => {
    if (error.name === 'HttpErrorResponse') {
        return getErrorMessage(error);
    }
    try {
        return JSON.parse(error);
    } catch (e) {
        return 'Error during parse error response';
    }
};

export const isInvalidCredentialError = (httpError: HttpErrorResponse) => {
    if (!httpError.error || !httpError.error.nonFieldErrors || !httpError.error.nonFieldErrors.length) {
        return false;
    }

    return httpError.error.nonFieldErrors.indexOf(INVALID_CREDENTIALS_BE) > -1;
};
