import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromNutrients from '../reducers/nutrients.reducer';

@Injectable()
export class NutrientsSelectors {
    /**
     * Get state.nutrients
     */
    getNutrientsState() {
        return ($state: State): any => $state.nutrients;
    }

    /**
     * Get list from state.nutrients
     */
    getList() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getList,
        );
    }

    /**
     * Get list.data from state.nutrients
     */
    getListData() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getListData,
        );
    }

    /**
     *  Get list.errors from state.nutrients
     */
    getListErrors() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.nutrients
     */
    getListIsFetching() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getListIsFetching,
        );
    }

    /**
     * Get manage from state.nutrients
     */
    getManage() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getManage,
        );
    }

    /**
     * Get manage.data from state.nutrients
     */
    getManageData() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getManageData,
        );
    }

    /**
     * Get manage.errors from state.nutrients
     */
    getManageErrors() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getManageErrors,
        );
    }

    /**
     * Get manage.isSaving from state.nutrients
     */
    getManageIsSaving() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getManageIsSaving,
        );
    }

    /**
     * Get manage.isFetching from state.nutrients
     */
    getManageIsFetching() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getManageIsFetching,
        );
    }

    /**
     * Get options from state.nutrients
     */
    getOptions() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getOptions,
        );
    }

    /**
     * Get options.data from state.nutrients
     */
    getOptionsData() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getOptionsData,
        );
    }

    /**
     * Get options.isFetching from state.nutrients
     */
    getOptionsIsFetching() {
        return createSelector(
            this.getNutrientsState(),
            fromNutrients.getOptionsIsFetching,
        );
    }
}
