import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { Subject } from 'rxjs';

@Directive({
    selector: '[commaToDot]',
})
export class CommaToDotDirective implements OnInit, OnDestroy {
    @Input() commaToDot: FormControl;

    private ngUnsubscribe: Subject<any> = new Subject<any>();

    /**
     * Subscribe to form control changes
     */
    ngOnInit() {
        this.commaToDot.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
            let _value = value;
            _value = value ? value.replace(/,/g, '.') : value;

            if (value !== _value) {
                this.commaToDot.setValue(_value);
            }
        });
    }

    /**
     * Unsubscribe on directive destroy
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
