import { type } from '@app/utils';
import { Action } from '@ngrx/store';

import { ProductTemplateId } from '@core/models';

const ACTION_PREFIX = '[Product Templates]';

export const ActionTypes = {
    GET_PRODUCT_TEMPLATES_REQUEST: type(`${ACTION_PREFIX} Get Product Templates Request`),
    GET_PRODUCT_TEMPLATES_SUCCESS: type(`${ACTION_PREFIX} Get Product Templates Success`),
    GET_PRODUCT_TEMPLATES_ERROR: type(`${ACTION_PREFIX} Get Product Templates Error`),
    GET_PRODUCT_TEMPLATE_REQUEST: type(`${ACTION_PREFIX} Get Product Template Request`),
    GET_PRODUCT_TEMPLATE_SUCCESS: type(`${ACTION_PREFIX} Get Product Template Success`),
    GET_PRODUCT_TEMPLATE_ERROR: type(`${ACTION_PREFIX} Get Product Template Error`),
    NEW_PRODUCT_TEMPLATE: type(`${ACTION_PREFIX} New Product Template`),
    CREATE_PRODUCT_TEMPLATE_REQUEST: type(`${ACTION_PREFIX} Create Product Template Request`),
    CREATE_PRODUCT_TEMPLATE_SUCCESS: type(`${ACTION_PREFIX} Create Product Template Success`),
    CREATE_PRODUCT_TEMPLATE_ERROR: type(`${ACTION_PREFIX} Create Product Template Error`),
    SAVE_PRODUCT_TEMPLATE_REQUEST: type(`${ACTION_PREFIX} Save Product Template Request`),
    SAVE_PRODUCT_TEMPLATE_SUCCESS: type(`${ACTION_PREFIX} Save Product Template Success`),
    SAVE_PRODUCT_TEMPLATE_ERROR: type(`${ACTION_PREFIX} Save Product Template Error`),
    DELETE_PRODUCT_TEMPLATE_REQUEST: type(`${ACTION_PREFIX} Delete Product Template Request`),
    DELETE_PRODUCT_TEMPLATE_SUCCESS: type(`${ACTION_PREFIX} Delete Product Template Success`),
    DELETE_PRODUCT_TEMPLATE_ERROR: type(`${ACTION_PREFIX} Delete Product Template Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
};

/**
 * Create 'Get Product Templates Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetProductTemplatesRequestAction implements Action {
    type = ActionTypes.GET_PRODUCT_TEMPLATES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Templates Succes' Action
 * @param payload Contains object with product templates list and params
 */
export class GetProductTemplatesSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCT_TEMPLATES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Templates Error' Action
 * @param payload Contains object with errors
 */
export class GetProductTemplatesErrorAction implements Action {
    type = ActionTypes.GET_PRODUCT_TEMPLATES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Template Request' Action
 * @param payload Contains product template id
 */
export class GetProductTemplateRequestAction implements Action {
    type = ActionTypes.GET_PRODUCT_TEMPLATE_REQUEST;

    constructor(public payload: ProductTemplateId) {}
}

/**
 * Create 'Get Product Template Success' Action
 * @param payload Contains object with product template data
 */
export class GetProductTemplateSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCT_TEMPLATE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Product Template Error' Action
 * @param payload Contains object with errors
 */
export class GetProductTemplateErrorAction implements Action {
    type = ActionTypes.GET_PRODUCT_TEMPLATE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Product Template' Action
 */
export class NewProductTemplateAction implements Action {
    type = ActionTypes.NEW_PRODUCT_TEMPLATE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Product Template Request' Action
 * @param payload Contains object with product template data
 */
export class CreateProductTemplateRequestAction implements Action {
    type = ActionTypes.CREATE_PRODUCT_TEMPLATE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Product Template Succes' Action
 * @param payload Contains object with product template data
 */
export class CreateProductTemplateSuccessAction implements Action {
    type = ActionTypes.CREATE_PRODUCT_TEMPLATE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Product Template Error' Action
 * @param payload Contains object with errors
 */
export class CreateProductTemplateErrorAction implements Action {
    type = ActionTypes.CREATE_PRODUCT_TEMPLATE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Product Template Request' Action
 * @param payload Contains object with product template data
 */
export class SaveProductTemplateRequestAction implements Action {
    type = ActionTypes.SAVE_PRODUCT_TEMPLATE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Product Template Succes' Action
 * @param payload Contains object with product template data
 */
export class SaveProductTemplateSuccessAction implements Action {
    type = ActionTypes.SAVE_PRODUCT_TEMPLATE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Product Template Error' Action
 * @param payload Contains object with errors
 */
export class SaveProductTemplateErrorAction implements Action {
    type = ActionTypes.SAVE_PRODUCT_TEMPLATE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Product Template Request' Action
 * @param payload Contains product template id
 */
export class DeleteProductTemplateRequestAction implements Action {
    type = ActionTypes.DELETE_PRODUCT_TEMPLATE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Product Template Succes' Action
 */
export class DeleteProductTemplateSuccessAction implements Action {
    type = ActionTypes.DELETE_PRODUCT_TEMPLATE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Product Template Error' Action
 * @param payload Contains object with errors
 */
export class DeleteProductTemplateErrorAction implements Action {
    type = ActionTypes.DELETE_PRODUCT_TEMPLATE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetProductTemplatesRequestAction
    | GetProductTemplatesSuccessAction
    | GetProductTemplatesErrorAction
    | GetProductTemplateRequestAction
    | GetProductTemplateSuccessAction
    | GetProductTemplateErrorAction
    | NewProductTemplateAction
    | CreateProductTemplateRequestAction
    | CreateProductTemplateSuccessAction
    | CreateProductTemplateErrorAction
    | SaveProductTemplateRequestAction
    | SaveProductTemplateSuccessAction
    | SaveProductTemplateErrorAction
    | DeleteProductTemplateRequestAction
    | DeleteProductTemplateSuccessAction
    | DeleteProductTemplateErrorAction
    | ClearListAction
    | ClearManageAction;
