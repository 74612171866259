import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { CeleryStatus, CeleryStatusType } from '@core/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'progress-modal',
    styleUrls: ['./progress-modal.component.scss'],
    templateUrl: './progress-modal.component.html',
})
export class ProgressModalComponent implements OnChanges {
    @Input() status: CeleryStatusType;
    @Input() current: number;
    @Input() total: number;

    public progress: number = 0;

    public ngOnChanges() {
        if (this.status === CeleryStatus.IN_PROGRESS) {
            const current = this.current === 0 ? 0 : this.current - 1;
            this.progress = Math.ceil((current / this.total) * 100);
        } else if (this.status === CeleryStatus.SUCCESS) {
            this.progress = 100;
        } else {
            this.progress = 0;
        }
    }
}
