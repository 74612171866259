import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Media Profiles]';

export const ActionTypes = {
    GET_MEDIA_PROFILES_REQUEST: type(`${ACTION_PREFIX} Get Media Profiles Request`),
    GET_MEDIA_PROFILES_SUCCESS: type(`${ACTION_PREFIX} Get Media Profiles Success`),
    GET_MEDIA_PROFILES_ERROR: type(`${ACTION_PREFIX} Get Media Profiles Error`),
    GET_MEDIA_PROFILE_REQUEST: type(`${ACTION_PREFIX} Get Media Profile Request`),
    GET_MEDIA_PROFILE_SUCCESS: type(`${ACTION_PREFIX} Get Media Profile Success`),
    GET_MEDIA_PROFILE_ERROR: type(`${ACTION_PREFIX} Get Media Profile Error`),
    GET_MEDIA_PROFILE_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Media Profile Options Request`),
    GET_MEDIA_PROFILE_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Media Profile Options Success`),
    GET_MEDIA_PROFILE_OPTIONS_ERROR: type(`${ACTION_PREFIX} Get Media Profile Options Error`),
    GET_MEDIA_PROFILE_AVAILABLE_IMAGES_REQUEST: type(`${ACTION_PREFIX} Get Media Profile Available Images Request`),
    GET_MEDIA_PROFILE_AVAILABLE_IMAGES_SUCCESS: type(`${ACTION_PREFIX} Get Media Profile Available Images Success`),
    GET_MEDIA_PROFILE_AVAILABLE_IMAGES_ERROR: type(`${ACTION_PREFIX} Get Media Profile Available Images Error`),
    CREATE_MEDIA_PROFILE_REQUEST: type(`${ACTION_PREFIX} Create Media Profile Request`),
    CREATE_MEDIA_PROFILE_SUCCESS: type(`${ACTION_PREFIX} Create Media Profile Success`),
    CREATE_MEDIA_PROFILE_ERROR: type(`${ACTION_PREFIX} Create Media Profile Error`),
    SAVE_MEDIA_PROFILE_REQUEST: type(`${ACTION_PREFIX} Save Media Profile Request`),
    SAVE_MEDIA_PROFILE_SUCCESS: type(`${ACTION_PREFIX} Save Media Profile Success`),
    SAVE_MEDIA_PROFILE_ERROR: type(`${ACTION_PREFIX} Save Media Profile Error`),
    DELETE_MEDIA_PROFILE_REQUEST: type(`${ACTION_PREFIX} Delete Media Profile Request`),
    DELETE_MEDIA_PROFILE_SUCCESS: type(`${ACTION_PREFIX} Delete Media Profile Success`),
    DELETE_MEDIA_PROFILE_ERROR: type(`${ACTION_PREFIX} Delete Media Profile Error`),
    CLEAR_MEDIA_PROFILES: type(`${ACTION_PREFIX} Clear Media Profiles`),
    CLEAR_MEDIA_PROFILE: type(`${ACTION_PREFIX} Clear Media Profile`),
    CLEAR_MEDIA_PROFILE_OPTIONS: type(`${ACTION_PREFIX} Clear Media Profile Options`),
    POST_SAMPLE_IMAGE_REQUEST: type(`${ACTION_PREFIX} Post Sample Image Request`),
    POST_SAMPLE_IMAGE_SUCCESS: type(`${ACTION_PREFIX} Post Sample Image Success`),
    POST_SAMPLE_IMAGE_ERROR: type(`${ACTION_PREFIX} Post Sample Image Error`),
    CLEAR_SAMPLE_IMAGE: type(`${ACTION_PREFIX} Clear Sample Image`),
};

/**
 * Create 'Get Media Profiles Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetMediaProfilesRequestAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profiles Success' Action
 * @param payload Contains object with media profiles list and params
 */
export class GetMediaProfilesSuccessAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profiles Error' Action
 * @param payload Contains object with errors
 */
export class GetMediaProfilesErrorAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Request' Action
 * @param payload Contains media profile id
 */
export class GetMediaProfileRequestAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Success' Action
 * @param payload Contains object with media profile data
 */
export class GetMediaProfileSuccessAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Error' Action
 * @param payload Contains object with errors
 */
export class GetMediaProfileErrorAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Options Request' Action
 */
export class GetMediaProfileOptionsRequestAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Options Success' Action
 * @param payload Contains object with media profile options data
 */
export class GetMediaProfileOptionsSuccessAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Options Error' Action
 * @param payload Contains object with errors
 */
export class GetMediaProfileOptionsErrorAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_OPTIONS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Available Images Request' Action
 */
export class GetMediaProfileAvailableImagesRequestAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_AVAILABLE_IMAGES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Available Images Success' Action
 * @param payload Contains object with media profile available images data
 */
export class GetMediaProfileAvailableImagesSuccessAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_AVAILABLE_IMAGES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Profile Available Images Error' Action
 * @param payload Contains object with errors
 */
export class GetMediaProfileAvailableImagesErrorAction implements Action {
    type = ActionTypes.GET_MEDIA_PROFILE_AVAILABLE_IMAGES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Media Profile Request' Action
 * @param payload Contains object with media profile data
 */
export class CreateMediaProfileRequestAction implements Action {
    type = ActionTypes.CREATE_MEDIA_PROFILE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Media Profile Success' Action
 * @param payload Contains object with media profile data
 */
export class CreateMediaProfileSuccessAction implements Action {
    type = ActionTypes.CREATE_MEDIA_PROFILE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Media Profile Error' Action
 * @param payload Contains object with errors
 */
export class CreateMediaProfileErrorAction implements Action {
    type = ActionTypes.CREATE_MEDIA_PROFILE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Media Profile Request' Action
 * @param payload Contains object with media profile data
 */
export class SaveMediaProfileRequestAction implements Action {
    type = ActionTypes.SAVE_MEDIA_PROFILE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Media Profile Success' Action
 * @param payload Contains object with media profile data
 */
export class SaveMediaProfileSuccessAction implements Action {
    type = ActionTypes.SAVE_MEDIA_PROFILE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Media Profile Error' Action
 * @param payload Contains object with errors
 */
export class SaveMediaProfileErrorAction implements Action {
    type = ActionTypes.SAVE_MEDIA_PROFILE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Media Profile Request' Action
 * @param payload Contains object with media profile data
 */
export class DeleteMediaProfileRequestAction implements Action {
    type = ActionTypes.DELETE_MEDIA_PROFILE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Media Profile Success' Action
 * @param payload Contains object with media profile data
 */
export class DeleteMediaProfileSuccessAction implements Action {
    type = ActionTypes.DELETE_MEDIA_PROFILE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Media Profile Error' Action
 * @param payload Contains object with errors
 */
export class DeleteMediaProfileErrorAction implements Action {
    type = ActionTypes.DELETE_MEDIA_PROFILE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Media Profile' Action
 * @param payload
 */
export class ClearMediaProfilesAction implements Action {
    type = ActionTypes.CLEAR_MEDIA_PROFILES;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Media Profile' Action
 * @param payload
 */
export class ClearMediaProfileAction implements Action {
    type = ActionTypes.CLEAR_MEDIA_PROFILE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Media Profile' Action
 * @param payload
 */
export class ClearMediaProfileOptionsAction implements Action {
    type = ActionTypes.CLEAR_MEDIA_PROFILE_OPTIONS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Post Sample Image Request' Action
 * @param payload
 */
export class PostSampleImageRequestAction implements Action {
    type = ActionTypes.POST_SAMPLE_IMAGE_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Post Sample Image Success' Action
 * @param payload
 */
export class PostSampleImageSuccessAction implements Action {
    type = ActionTypes.POST_SAMPLE_IMAGE_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Post Sample Image Error' Action
 * @param payload
 */
export class PostSampleImageErrorAction implements Action {
    type = ActionTypes.POST_SAMPLE_IMAGE_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Sample Image' Action
 * @param payload
 */
export class ClearSampleImageAction implements Action {
    type = ActionTypes.CLEAR_SAMPLE_IMAGE;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetMediaProfilesRequestAction
    | GetMediaProfilesSuccessAction
    | GetMediaProfilesErrorAction
    | GetMediaProfileRequestAction
    | GetMediaProfileSuccessAction
    | GetMediaProfileErrorAction
    | GetMediaProfileOptionsRequestAction
    | GetMediaProfileOptionsSuccessAction
    | GetMediaProfileOptionsErrorAction
    | SaveMediaProfileRequestAction
    | SaveMediaProfileSuccessAction
    | SaveMediaProfileErrorAction
    | DeleteMediaProfileRequestAction
    | DeleteMediaProfileSuccessAction
    | DeleteMediaProfileErrorAction
    | ClearMediaProfilesAction
    | ClearMediaProfileAction
    | ClearMediaProfileOptionsAction
    | PostSampleImageRequestAction
    | PostSampleImageSuccessAction
    | PostSampleImageErrorAction
    | ClearSampleImageAction;
