import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromProducts from '../reducers/products.reducer';

/**
 * Selectors connected to state.products
 */
@Injectable()
export class ProductsSelectors {
    getProductsState() {
        return ($state: State): any => $state.products;
    }

    getExportIsFetching() {
        return createSelector(
            this.getProductsState(),
            fromProducts.getExportIsFetching,
        );
    }

    getProductsList() {
        return createSelector(this.getProductsState(), fromProducts.getProductList);
    }

    /**
     * Get list.isFetching from state.products
     */
    getSearchParams() {
        return createSelector(this.getProductsState(), fromProducts.getSearchParams);
    }

    /**
     * Get manage from state.products
     */
    getManage() {
        return createSelector(this.getProductsState(), fromProducts.getManage);
    }

    /**
     * Get manage.data from state.products
     */
    getManageData() {
        return createSelector(this.getProductsState(), fromProducts.getManageData);
    }

    /**
     * Get manage.errors from state.products
     */
    getManageErrors() {
        return createSelector(this.getProductsState(), fromProducts.getManageErrors);
    }

    /**
     * Get manage.isFetching from state.products
     */
    getManageIsFetching() {
        return createSelector(this.getProductsState(), fromProducts.getManageIsFetching);
    }

    /**
     * Get manage.isSaving from state.products
     */
    getManageIsSaving() {
        return createSelector(this.getProductsState(), fromProducts.getManageIsSaving);
    }

    getManageBulk() {
        return createSelector(
            this.getProductsState(),
            fromProducts.getManageBulk,
        );
    }

    getShareProducts() {
        return createSelector(
            this.getProductsState(),
            fromProducts.getShareProducts,
        );
    }

    /**
     * Get changesHistory from state.products
     */
    getChangesHistory() {
        return createSelector(this.getProductsState(), fromProducts.getChangesHistory);
    }

    /**
     * Get sharesHistory from state.products
     */
    getSharesHistory() {
        return createSelector(this.getProductsState(), fromProducts.getSharesHistory);
    }


    /**
     * Get importTemplate from state.products
     */
    getImportTemplate() {
        return createSelector(this.getProductsState(), fromProducts.getImportTemplate);
    }

    /**
     * Get prepareImport from state.products
     */
    getPrepareImport() {
        return createSelector(this.getProductsState(), fromProducts.getPrepareImport);
    }

    /**
     * Get prepareExport from state.products
     */
    getPrepareExport() {
        return createSelector(this.getProductsState(), fromProducts.getPrepareExport);
    }

    /**
     * Get exportStatus from state.products
     */
    getExportStatus() {
        return createSelector(this.getProductsState(), fromProducts.getExportStatus);
    }

    /**
     * Get options from state.products
     */
    getOptions() {
        return createSelector(this.getProductsState(), fromProducts.getOptions);
    }

    /**
     * Get options.data from state.products
     */
    getOptionsData() {
        return createSelector(this.getProductsState(), fromProducts.getOptionsData);
    }

    /**
     * Get options.isFetching from state.products
     */
    getOptionsIsFetching() {
        return createSelector(this.getProductsState(), fromProducts.getOptionsIsFetching);
    }

    /**
     * Get productOptions from state.products
     */
    getProductOptions() {
        return createSelector(this.getProductsState(), fromProducts.getProductOptions);
    }

    /**
     * Get newProductsId from state.products
     */
    getNewProductsId() {
        return createSelector(this.getProductsState(), fromProducts.getNewProductsId);
    }

    /**
     * Get cloneStatus from state.products
     */
    getCloneStatus() {
        return createSelector(this.getProductsState(), fromProducts.getCloneStatus);
    }

    /**
     * Get productScores from state.products
     */
    getScores() {
        return createSelector(this.getProductsState(), fromProducts.getProductScores);
    }

    getBarcode() {
        return createSelector(this.getProductsState(), fromProducts.getBarcodeImage);
    }

    getReadinessReport() {
        return createSelector(
            this.getProductsState(),
            fromProducts.getReadinessReport,
        );
    }

    getAttributeDiff() {
        return createSelector(
            this.getProductsState(),
            fromProducts.getAttributesDiff,
        );
    }
}
