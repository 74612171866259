import * as actions from '@core/actions/metrics.actions';
import { MetricFamilyId, MetricFamilyManage, MetricsFamilyList } from '@core/models';

export interface State {
    list: MetricsFamilyList;
    manage: MetricFamilyManage;
    manageMultiple: Record<string, MetricFamilyManage>;
}

export const initialState: State = {
    list: {
        data: {
            count: null,
            limit: null,
            next: null,
            offset: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            created: null,
            id: null,
            metrics: [],
            modified: null,
            position: null,
            translations: {},
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    manageMultiple: {},
};

export function reducer(state = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.ActionTypes.GET_METRICS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case actions.ActionTypes.GET_METRICS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_METRICS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_METRIC_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case actions.ActionTypes.GET_METRIC_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_METRIC_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.NEW_METRIC: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.CREATE_METRIC_REQUEST:
        case actions.ActionTypes.SAVE_METRIC_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case actions.ActionTypes.CREATE_METRIC_SUCCESS:
        case actions.ActionTypes.SAVE_METRIC_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case actions.ActionTypes.CREATE_METRIC_ERROR:
        case actions.ActionTypes.SAVE_METRIC_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case actions.ActionTypes.DELETE_METRIC_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case actions.ActionTypes.DELETE_METRIC_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case actions.ActionTypes.DELETE_METRIC_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case actions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case actions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case actions.ActionTypes.GET_MULTIPLE_METRICS_REQUEST:
        case actions.ActionTypes.GET_MULTIPLE_METRICS_AT_ONCE_REQUEST: {
            return {
                ...state,
                manageMultiple: {
                    ...state.manageMultiple,
                    ...action.payload.reduce(
                        (acc: Record<string, MetricFamilyManage>, metricFamilyId: MetricFamilyId) => {
                            return {
                                ...acc,
                                [metricFamilyId]: {
                                    ...(state.manageMultiple[metricFamilyId]
                                        ? {
                                              ...state.manageMultiple[metricFamilyId],
                                              errors: {},
                                              isFetching: true,
                                          }
                                        : {
                                              ...initialState.manage,
                                          }),
                                },
                            };
                        },
                        {},
                    ),
                },
            };
        }

        case actions.ActionTypes.GET_MULTIPLE_METRIC_SUCCESS: {
            return {
                ...state,
                manageMultiple: {
                    ...state.manageMultiple,
                    [action.payload.id]: {
                        ...state.manageMultiple[action.payload.id],
                        data: action.payload,
                        isFetching: false,
                    },
                },
            };
        }

        case actions.ActionTypes.GET_MULTIPLE_METRIC_ERROR: {
            return {
                ...state,
                manageMultiple: {
                    ...state.manageMultiple,
                    [action.payload.id]: {
                        ...state.manageMultiple[action.payload.id],
                        errors: action.payload.errors,
                        isFetching: false,
                    },
                },
            };
        }

        case actions.ActionTypes.CLEAR_MULTIPLE_METRICS: {
            return {
                ...state,
                manageMultiple: {
                    ...initialState.manageMultiple,
                },
            };
        }

        case actions.ActionTypes.GET_MULTIPLE_METRICS_AT_ONCE_SUCCESS: {
            return {
                ...state,
                manageMultiple: {
                    ...state.manageMultiple,
                    ...action.payload.reduce((acc, metric) => {
                        return {
                            ...acc,
                            [metric.id]: {
                                ...state.manageMultiple[metric.id],
                                data: metric,
                                isFetching: false,
                            }
                        };
                    }, {}),
                },
            };
        }

        case actions.ActionTypes.GET_MULTIPLE_METRIC_ERROR: {
            return {
                ...state,
                manageMultiple: {
                    ...state.manageMultiple,
                    ...action.payload.reduce((acc, metric) => {
                        return {
                            ...acc,
                            [metric.id]: {
                                ...state.manageMultiple[metric.id],
                                isFetching: false,
                                errors: metric.errors
                            }
                        };
                    }, {}),
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsFetching = (state: State) => state.manage.isFetching;
export const getManageIsSaving = (state: State) => state.manage.isSaving;

export const getManageMultiple = (state: State) => state.manageMultiple;
