import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as productTemplates from '../actions/product-templates.actions';

import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';
import { ProductTemplatesService } from '../services/product-templates.service';

import { ProductTemplateId } from '@core/models';

import { catchErrorHttpClientJson } from './catch-error';

@Injectable()
export class ProductTemplateEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private productTemplatesService: ProductTemplatesService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    @Effect()
    getProductTemplatesRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productTemplates.ActionTypes.GET_PRODUCT_TEMPLATES_REQUEST),
        map((action: productTemplates.GetProductTemplatesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productTemplatesService.getProductTemplates(payload).pipe(
                map((response) => new productTemplates.GetProductTemplatesSuccessAction(response)),
                catchError((error) =>
                    of(new productTemplates.GetProductTemplatesErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getProductTemplateRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productTemplates.ActionTypes.GET_PRODUCT_TEMPLATE_REQUEST),
        map((action: productTemplates.GetProductTemplateRequestAction) => action.payload),
        switchMap((payload: ProductTemplateId) =>
            this.productTemplatesService.getProductTemplate(payload).pipe(
                map((response) => new productTemplates.GetProductTemplateSuccessAction(response)),
                catchError((error) =>
                    of(new productTemplates.GetProductTemplateErrorAction(catchErrorHttpClientJson(error))),
                ),
            ),
        ),
    );

    @Effect()
    saveProductTemplateRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productTemplates.ActionTypes.SAVE_PRODUCT_TEMPLATE_REQUEST),
        map((action: productTemplates.SaveProductTemplateRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productTemplatesService.saveProductTemplate(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved product template ${response.translations.en.name}`);
                    this.router.navigate(['/product-templates']);
                }),
                map((response) => new productTemplates.SaveProductTemplateSuccessAction(response)),
                catchError((error) =>
                    of(new productTemplates.SaveProductTemplateErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    createProductTemplateRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productTemplates.ActionTypes.CREATE_PRODUCT_TEMPLATE_REQUEST),
        map((action: productTemplates.CreateProductTemplateRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productTemplatesService.createProductTemplate(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Created product template ${response.translations.en.name}`);
                    this.router.navigate(['/product-templates']);
                }),
                map((response) => new productTemplates.CreateProductTemplateSuccessAction(response)),
                catchError((error) =>
                    of(new productTemplates.CreateProductTemplateErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    deleteProductTemplateRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productTemplates.ActionTypes.DELETE_PRODUCT_TEMPLATE_REQUEST),
        map((action: productTemplates.DeleteProductTemplateRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productTemplatesService.deleteProductTemplate(payload.id).pipe(
                tap(
                    () => {
                        this.notificationsService.success(`Deleted product template`);
                        this.router.navigate([payload.redirectTo]);
                    },
                    () => {
                        this.modalService.close('delete-product-template-modal');
                    },
                ),
                map(() => new productTemplates.DeleteProductTemplateSuccessAction()),
                catchError((error) =>
                    of(new productTemplates.DeleteProductTemplateErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );
}
