
export interface ProfileEntry {
    id: string;
    email: string;
    companyName: string;
    companyRole: string;
    companyGln: string;
    companyLogo: string;
    userRole: string;
    isCurrentProfile: boolean;
}

export interface SuccessfulRouting {
    next: string;
    samlRequest: string;
}

export interface AuthModel {
    profiles: ProfileEntry[];
    successfulRouting: SuccessfulRouting;
}

export const defaultState = {
    profiles: null,
    successfulRouting: null
};

export enum UaePassLinkKeys {
    state = 'state',
    uaePass = 'uaePass',
    inManualLinkingProcess = 'inManualLinkingProcess'
}
