import * as companiesActions from '@core/actions/companies.actions';
import { CompaniesList, CompaniesManage, CompaniesOptions, Company } from '@core/models';

export interface State {
    list: CompaniesList;
    manage: CompaniesManage;
    options: CompaniesOptions;
}

export const initialState: State = {
    manage: {
        data: {
            address: '',
            canAnnotateInternalDataTemplate: false,
            canAnnotateProducts: false,
            city: '',
            companyNumber: '',
            companyType: null,
            country: '',
            created: '',
            currentPlan: null,
            email: '',
            employmentSize: null,
            faxNumber: '',
            gln: '',
            id: null,
            internalDataTemplateFile: null,
            internalProductTemplate: [],
            isStaff: false,
            logo: '',
            name: '',
            partners: [],
            phoneNumber: '',
            plan: '',
            planExpirationDate: '',
            postalCode: '',
            postOfficeBox: '',
            productRangeSize: null,
            role: null,
            status: '',
            subtype: null,
            vatId: '',
            website: '',
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    list: {
        data: {
            count: null,
            limit: null,
            next: null,
            offset: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
    options: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: companiesActions.Actions): State {
    switch (action.type) {
        case companiesActions.ActionTypes.GET_COMPANIES_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case companiesActions.ActionTypes.GET_COMPANIES_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case companiesActions.ActionTypes.GET_COMPANIES_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case companiesActions.ActionTypes.GET_COMPANY_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case companiesActions.ActionTypes.GET_COMPANY_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case companiesActions.ActionTypes.GET_COMPANY_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case companiesActions.ActionTypes.NEW_COMPANY: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: {},
                },
            };
        }

        case companiesActions.ActionTypes.CREATE_COMPANY_REQUEST:
        case companiesActions.ActionTypes.UPLOAD_COMPANY_LOGO_REQUEST:
        case companiesActions.ActionTypes.SAVE_COMPANY_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case companiesActions.ActionTypes.CREATE_COMPANY_SUCCESS:
        case companiesActions.ActionTypes.UPLOAD_COMPANY_LOGO_SUCCESS:
        case companiesActions.ActionTypes.SAVE_COMPANY_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case companiesActions.ActionTypes.CREATE_COMPANY_ERROR:
        case companiesActions.ActionTypes.UPLOAD_COMPANY_LOGO_ERROR:
        case companiesActions.ActionTypes.SAVE_COMPANY_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case companiesActions.ActionTypes.DELETE_COMPANY_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case companiesActions.ActionTypes.DELETE_COMPANY_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case companiesActions.ActionTypes.DELETE_COMPANY_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case companiesActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: {},
                    isFetching: true,
                },
            };
        }

        case companiesActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case companiesActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case companiesActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case companiesActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getList = (state: State) => state.list;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;

export const getOptions = (state: State) => state.options;
