import { Pipe, PipeTransform } from '@angular/core';

import { CustomerNameService } from '../../services/customer.service';

const customTranslations = {
    'Sharing History': { fta: 'Submission History' },
    Attributes: { fta: 'Detailed Product Information' },
    'Share Selected Products with:': {
        fta: 'Submit Selected Products To:',
    },
    'Country of origin': {
        sharjah: 'Batch release country',
        healthcare: 'Batch Release Country / Country of Legal Manufacturer',
    },
    'Approved': {
        fta: {
            p: 'Submitted to FTA',
            r: 'Submitted',
            d: 'Submitted'
        }
    },
    'Auto approved': {
        fta: {
            p: 'Submitted to FTA',
            r: 'Submitted',
            d: 'Submitted'
        }
    },
    'Pending approval': {
        fta: {
            p: 'Under process',
            r: 'Under process',
            d: 'Under process'
        },
    },
    'Rejected': {
        fta: {
            p: 'Resubmit',
            r: 'Resubmit',
            d: 'Resubmit'
        },
    },
    'rejection': {
        fta: {
            p: 'resubmission',
        }
    },
};

/**
 * Pipe for changing custom name, labels etc
 * In order to change the particular names for specific user type.
 *
 * This pipe can be used in three ways:
 * {{ 'some string' | companyTypeTranslations }}
 * {{ 'some string' | companyTypeTranslations: 'customCompanyName' }}
 * {{ 'some string' | companyTypeTranslations: 'customCompanyName' : 'p' }}
 *
 * in the first scenario the name is taken using company data of logged user.
 * Function will be looking for value in object by path ['string value', 'company subtype', 'company role']
 *
 * For not declared role function will return input value.
 * for config like this:
 *  'some string' : {
 *      'fta': {
 *         p: 'fta some string'
 *       }
 * }
 * If User is recipient from fta function will return input value
 *
 * If you want to declare value for whole company subtype you cant do this in that way:
 *  'some string' : {
 *      'fta': 'fta some string'
 * }
 *
 *
 * For "some string" has to be added entry in customTranslations:
 * 'some string': {
 *     'fta': 'fta some string',
 *     'healthcare': 'different string'
 * }
 * and it means that fta and healthcare users will see different names for the same place.
 *
 * real example:
 *      section-title="{{ 'Attributes' | companyTypeTranslations }}"
 * for logged fta's company-type user will be displayed: 'Detailed Product Information'
 */
@Pipe({ name: 'companyTypeTranslations' })
export class CompanyTypeTranslationsPipe implements PipeTransform {
    asyncValue: string = '';

    constructor(private customerService: CustomerNameService) {}

    private getNameByLoggedUserType(value: string): string {
        this.customerService.getCompanyInfo().subscribe((companyData) => {
            this.asyncValue = this.getMostNestedValue(customTranslations, [value, companyData.subtype, companyData.role]);
        });
        return typeof(this.asyncValue) === 'string' ? this.asyncValue : value;
    }

    private getMostNestedValue = (nestedObj, pathArr) => {
        return pathArr.reduce((obj, key) => (typeof(obj) === 'object' && obj[key] !== 'undefined' ? obj[key] : obj), nestedObj);
    }

    transform(value: string, customer: string | null = '', role?: string): string {
        if (!customer) {
            return this.getNameByLoggedUserType(value);
        }
        if (role && customTranslations[value][customer]) {
            return customTranslations[value] ? customTranslations[value][customer][role] || value : value;
        }
        return customTranslations[value] ? customTranslations[value][customer] || value : value;
    }
}
