import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class ProductCategoriesReadGuard extends BaseGuard {
    path = ['productCategories', 'read'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class ProductCategoriesManageGuard extends BaseGuard {
    path = ['productCategories', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}
