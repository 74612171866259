<div class="barcode-choice-modal">
    <form
      novalidate
      [formGroup]="barcodeChoiceFrom"
      class="form">
      <section class="form__section">
        <label class="form__section-title form__section-title--margin-top-32 section-title">Chose barcode type</label>
        <div class="form__row">
          <div class="form__group form__group--cols-6">
            <label class="form__label label label--required">Select output</label>
            <ng-select
              [options]="typeOptions"
              noFilter="true"
              formControlName="type"
            >
            </ng-select>
          </div>
        </div>
      </section>

      <footer
        class="form__footer"
        style="justify-content: center;"
        *ngIf="!bulkVersion">
        <div class="form__buttons">
          <button
            type="button"
            class="btn btn--secondary"
            (click)="onSubmit(barcodeChoiceFrom.value.type)"
          >
            Download Barcode
          </button>
        </div>
      </footer>
    </form>
  </div>
