import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[ng-option-tmp]', // tslint:disable-line:directive-selector
})
export class NgOptionTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[ng-label-tmp]', // tslint:disable-line:directive-selector
})
export class NgLabelTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[ng-placeholder-tmp]', // tslint:disable-line:directive-selector
})
export class NgPlaceholderTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}
