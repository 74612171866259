import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromPaidPlans from '../reducers/paid-plans.reducer';

@Injectable()
export class PaidPlansSelectors {
    /**
     * Get state.paidPlans
     */
    getPaidPlansState() {
        return ($state: State): any => $state.paidPlans;
    }

    /**
     * Get list from state.paidPlans
     */
    getList() {
        return createSelector(
            this.getPaidPlansState(),
            fromPaidPlans.getList,
        );
    }

    /**
     * Get manage from state.paidPlans
     */
    getManage() {
        return createSelector(
            this.getPaidPlansState(),
            fromPaidPlans.getManage,
        );
    }

    /**
     * Get options from state.paidPlans
     */
    getOptions() {
        return createSelector(
            this.getPaidPlansState(),
            fromPaidPlans.getOptions,
        );
    }
}
