import { prepareActions } from '@app/utils';
import { MetricFamily, MetricFamilyId } from '@core/models';
import { Action } from '@ngrx/store';

export const ActionTypes = prepareActions('[Metrics]', [
    'GET_METRICS_REQUEST',
    'GET_METRICS_SUCCESS',
    'GET_METRICS_ERROR',
    'CLEAR_LIST',

    'NEW_METRIC',

    'GET_METRIC_REQUEST',
    'GET_METRIC_SUCCESS',
    'GET_METRIC_ERROR',
    'CLEAR_MANAGE',

    'CREATE_METRIC_REQUEST',
    'CREATE_METRIC_SUCCESS',
    'CREATE_METRIC_ERROR',

    'SAVE_METRIC_REQUEST',
    'SAVE_METRIC_SUCCESS',
    'SAVE_METRIC_ERROR',

    'DELETE_METRIC_REQUEST',
    'DELETE_METRIC_SUCCESS',
    'DELETE_METRIC_ERROR',

    'GET_MULTIPLE_METRICS_REQUEST',
    'GET_MULTIPLE_METRIC_SUCCESS',
    'GET_MULTIPLE_METRIC_ERROR',
    'CLEAR_MULTIPLE_METRICS',

    'GET_MULTIPLE_METRICS_AT_ONCE_REQUEST',
    'GET_MULTIPLE_METRICS_AT_ONCE_SUCCESS',
    'GET_MULTIPLE_METRICS_AT_ONCE_ERROR',
]);

/**
 * Create 'Get Metrics Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetMetricsRequestAction implements Action {
    type = ActionTypes.GET_METRICS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Metrics Success' Action
 * @param payload Contains object with metrics list and params
 */
export class GetMetricsSuccessAction implements Action {
    type = ActionTypes.GET_METRICS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Metrics Error' Action
 * @param payload Contains object with errors
 */
export class GetMetricsErrorAction implements Action {
    type = ActionTypes.GET_METRICS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Metric Request' Action
 * @param payload Contains metric id
 */
export class GetMetricRequestAction implements Action {
    type = ActionTypes.GET_METRIC_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Metric Success' Action
 * @param payload Contains object with metric data
 */
export class GetMetricSuccessAction implements Action {
    type = ActionTypes.GET_METRIC_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Metric Error' Action
 * @param payload Contains object with errors
 */
export class GetMetricErrorAction implements Action {
    type = ActionTypes.GET_METRIC_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Metric' Action
 */
export class NewMetricAction implements Action {
    type = ActionTypes.NEW_METRIC;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Metric Request' Action
 * @param payload Contains object with metric data
 */
export class CreateMetricRequestAction implements Action {
    type = ActionTypes.CREATE_METRIC_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Metric Success' Action
 * @param payload Contains object with metric data
 */
export class CreateMetricSuccessAction implements Action {
    type = ActionTypes.CREATE_METRIC_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Metric Error' Action
 * @param payload Contains object with errors
 */
export class CreateMetricErrorAction implements Action {
    type = ActionTypes.CREATE_METRIC_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Metric Request' Action
 * @param payload Contains object with metric data
 */
export class SaveMetricRequestAction implements Action {
    type = ActionTypes.SAVE_METRIC_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Metric Success' Action
 * @param payload Contains object with metric data
 */
export class SaveMetricSuccessAction implements Action {
    type = ActionTypes.SAVE_METRIC_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Metric Error' Action
 * @param payload Contains object with errors
 */
export class SaveMetricErrorAction implements Action {
    type = ActionTypes.SAVE_METRIC_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Metric Request' Action
 */
export class DeleteMetricRequestAction implements Action {
    type = ActionTypes.DELETE_METRIC_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Metric Succes' Action
 */
export class DeleteMetricSuccessAction implements Action {
    type = ActionTypes.DELETE_METRIC_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Metric Error' Action
 * @param payload Contains object with errors
 */
export class DeleteMetricErrorAction implements Action {
    type = ActionTypes.DELETE_METRIC_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Multiple Metrics Request' Action
 * @param payload Contains array of metric id
 */
export class GetMultipleMetricsRequestAction implements Action {
    type = ActionTypes.GET_MULTIPLE_METRICS_REQUEST;

    constructor(public payload: MetricFamilyId[]) {}
}

/**
 * Create 'Get Multiple Metric Success' Action
 * @param payload Contains object with multiple metric families
 */
export class GetMultipleMetricSuccessAction implements Action {
    type = ActionTypes.GET_MULTIPLE_METRIC_SUCCESS;

    constructor(public payload: MetricFamily) {}
}

/**
 * Create 'Get Multiple Metric Error' Action
 * @param payload Contains object with errors
 */
export class GetMultipleMetricErrorAction implements Action {
    type = ActionTypes.GET_MULTIPLE_METRIC_ERROR;

    constructor(
        public payload?: {
            id: MetricFamilyId;
            errors: any;
        },
    ) {}
}

/**
 * Create 'Clear Multiple Metrics' Action
 */
export class ClearMultipleMetricsAction implements Action {
    type = ActionTypes.CLEAR_MULTIPLE_METRICS;

    constructor(public payload?: any) {}
}

export class GetMultipleMetricsAtOnceRequestAction implements Action {
    type = ActionTypes.GET_MULTIPLE_METRICS_AT_ONCE_REQUEST;

    constructor(public payload: any) {}
}

export class GetMultipleMetricsAtOnceSuccessAction implements Action {
    type = ActionTypes.GET_MULTIPLE_METRICS_AT_ONCE_SUCCESS;

    constructor(public payload: any) {}
}

export class GetMultipleMetricsAtOnceErrorAction implements Action {
    type = ActionTypes.GET_MULTIPLE_METRICS_AT_ONCE_ERROR;

    constructor(public payload: any) {}
}

export type Actions =
    | GetMetricsRequestAction
    | GetMetricsSuccessAction
    | GetMetricsErrorAction
    | GetMetricRequestAction
    | GetMetricSuccessAction
    | GetMetricErrorAction
    | NewMetricAction
    | CreateMetricRequestAction
    | CreateMetricSuccessAction
    | CreateMetricErrorAction
    | SaveMetricRequestAction
    | SaveMetricSuccessAction
    | SaveMetricErrorAction
    | DeleteMetricRequestAction
    | DeleteMetricSuccessAction
    | DeleteMetricErrorAction
    | ClearListAction
    | ClearManageAction
    | GetMultipleMetricsRequestAction
    | GetMultipleMetricSuccessAction
    | GetMultipleMetricErrorAction
    | ClearMultipleMetricsAction
    | GetMultipleMetricsAtOnceRequestAction
    | GetMultipleMetricsAtOnceSuccessAction
    | GetMultipleMetricsAtOnceErrorAction;
