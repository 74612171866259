import * as companyInternalDataActions from '@core/actions/company-internal-data.actions';
import { CustomDataList, ImportInternalTemplate } from '@core/models';

export interface State {
    importTemplate: CustomDataList<ImportInternalTemplate>;
}

const importTemplateInitialState: CustomDataList<ImportInternalTemplate> = {
    data: {
        template: null,
    },
    errors: {},
    isFetching: false,
};

export const initialState: State = {
    importTemplate: importTemplateInitialState,
};

export function reducer(state: State = initialState, action: companyInternalDataActions.Actions): State {
    switch (action.type) {
        case companyInternalDataActions.ActionTypes.GET_IMPORT_TEMPLATE_REQUEST: {
            return {
                ...state,
                importTemplate: {
                    ...state.importTemplate,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case companyInternalDataActions.ActionTypes.GET_IMPORT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                importTemplate: {
                    ...state.importTemplate,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case companyInternalDataActions.ActionTypes.GET_IMPORT_TEMPLATE_ERROR: {
            return {
                ...state,
                importTemplate: {
                    ...state.importTemplate,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case companyInternalDataActions.ActionTypes.CLEAR_IMPORT_TEMPLATE: {
            return {
                ...state,
                importTemplate: {
                    ...initialState.importTemplate,
                },
            };
        }

        default:
            return state;
    }
}

export const getImportTemplate = (state: State) => state.importTemplate;
