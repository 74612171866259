<section
  class="completeness-score"
  [ngStyle]="{
    'width.px': size,
    'height.px': size
  }"
>
  <circle-score
    *ngIf="completeness !== null; else noRequirements"
    [value]="completeness"
    [color]="readiness ? 'green' : 'red'"
    [size]="size"
    [stroke]="stroke"
  ></circle-score>
</section>

<ng-template #noRequirements>
  <p
    class="completeness-score__no-requirements"
    [style.border-width.px]="stroke"
  >
    N/A
  </p>
</ng-template>
