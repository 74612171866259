import { prepareActions } from '@app/utils';
import {
    CompanyWhitelistedIp,
    CompanyWhitelistedIpsList,
    CompanyWhitelistedIpsListPayload,
    WhitelistedIpCreatePayload,
    WhitelistedIpDeletePayload,
    WhitelistedIpId,
    WhitelistedIpPostPayload,
} from '@core/models';
import { Action } from '@ngrx/store';

export namespace CompanyIpsActions {
    export const ActionTypes = prepareActions('[Company Whitelisted Ips]', [
        'CLEAR_LIST',
        'CLEAR_MANAGE',
        'CREATE_ENTITY_ERROR',
        'CREATE_ENTITY_REQUEST',
        'CREATE_ENTITY_SUCCESS',
        'DELETE_ENTITY_ERROR',
        'DELETE_ENTITY_REQUEST',
        'DELETE_ENTITY_SUCCESS',
        'GET_ENTITY_ERROR',
        'GET_ENTITY_REQUEST',
        'GET_ENTITY_SUCCESS',
        'GET_LIST_ERROR',
        'GET_LIST_REQUEST',
        'GET_LIST_SUCCESS',
        'NEW_ENTITY',
        'SAVE_ENTITY_ERROR',
        'SAVE_ENTITY_REQUEST',
        'SAVE_ENTITY_SUCCESS',
    ]);

    /**
     * Create 'Clear List' Action
     */
    export class ClearList implements Action {
        type = ActionTypes.CLEAR_LIST;
        constructor(public payload?: null) {}
    }

    /**
     * Create 'Clear Manage' Action
     */
    export class ClearManage implements Action {
        type = ActionTypes.CLEAR_MANAGE;
        constructor(public payload?: null) {}
    }

    /**
     * Create 'Create Company Whitelisted Ip Error' Action
     * @param payload Contains object with errors
     */
    export class CreateEntityError implements Action {
        type = ActionTypes.CREATE_ENTITY_ERROR;
        constructor(public payload: any) {}
    }

    /**
     * Create 'Create Company Whitelisted Ip Request' Action
     * @param payload Contains object with company whitelisted ip data
     */
    export class CreateEntityRequest implements Action {
        type = ActionTypes.CREATE_ENTITY_REQUEST;
        constructor(public payload: WhitelistedIpCreatePayload) {}
    }

    /**
     * Create 'Create Company Whitelisted Ip Succes' Action
     * @param payload Contains object with company whitelisted ip
     */
    export class CreateEntitySuccess implements Action {
        type = ActionTypes.CREATE_ENTITY_SUCCESS;
        constructor(public payload: CompanyWhitelistedIp) {}
    }

    /**
     * Create 'Delete Company Whitelisted Ip Error' Action
     * @param payload Contains object with errors
     */
    export class DeleteEntityError implements Action {
        type = ActionTypes.DELETE_ENTITY_ERROR;
        constructor(public payload: any) {}
    }

    /**
     * Create 'Delete Company Whitelisted Ip Request' Action
     * @param payload Contains company domain id
     */
    export class DeleteEntityRequest implements Action {
        type = ActionTypes.DELETE_ENTITY_REQUEST;
        constructor(public payload: WhitelistedIpDeletePayload) {}
    }

    /**
     * Create 'Delete Company Whitelisted Ip Succes' Action
     */
    export class DeleteEntitySuccess implements Action {
        type = ActionTypes.DELETE_ENTITY_SUCCESS;
        constructor(public payload?: null) {}
    }

    /**
     * Create 'Get Company Whitelisted Ip Error' Action
     * @param payload Contains object with errors
     */
    export class GetEntityError implements Action {
        type = ActionTypes.GET_ENTITY_ERROR;
        constructor(public payload: any) {}
    }

    /**
     * Create 'Get Company Whitelisted Ip Request' Action
     * @param payload Contains company domain id
     */
    export class GetEntityRequest implements Action {
        type = ActionTypes.GET_ENTITY_REQUEST;
        constructor(public payload: WhitelistedIpId) {}
    }

    /**
     * Create 'Get Company Whitelisted Ip Succes' Action
     * @param payload Contains object with company whitelisted ip data
     */
    export class GetEntitySuccess implements Action {
        type = ActionTypes.GET_ENTITY_SUCCESS;
        constructor(public payload: CompanyWhitelistedIp) {}
    }

    /**
     * Create 'Get Company Whitelisted Ips Error' Action
     * @param payload Contains object with errors
     */
    export class GetListError implements Action {
        type = ActionTypes.GET_LIST_ERROR;
        constructor(public payload: any) {}
    }

    /**
     * Create 'Get Company Whitelisted Ips Request' Action
     * @param payload Contains object with list query params (offset and limit)
     */
    export class GetListRequest implements Action {
        type = ActionTypes.GET_LIST_REQUEST;
        constructor(public payload: CompanyWhitelistedIpsListPayload) {}
    }

    /**
     * Create 'Get Company Whitelisted Ips Success' Action
     * @param payload Contains object with company whitelisted ips list and params
     */
    export class GetListSuccess implements Action {
        type = ActionTypes.GET_LIST_SUCCESS;

        constructor(public payload: CompanyWhitelistedIpsList) {}
    }

    /**
     * Create 'New Company Whitelisted Ip' Action
     * @param payload Contains company domain object
     */
    export class NewEntity implements Action {
        type = ActionTypes.NEW_ENTITY;
        constructor(public payload?: null) {}
    }

    /**
     * Create 'Save Company Whitelisted Ip Error' Action
     * @param payload Contains object with errors
     */
    export class SaveEntityError implements Action {
        type = ActionTypes.SAVE_ENTITY_ERROR;
        constructor(public payload: any) {}
    }

    /**
     * Create 'Save Company Whitelisted Ip Request' Action
     * @param payload Contains object with company whitelisted ip data
     */
    export class SaveEntityRequest implements Action {
        type = ActionTypes.SAVE_ENTITY_REQUEST;
        constructor(public payload: WhitelistedIpPostPayload) {}
    }

    /**
     * Create 'Save Company Whitelisted Ip Succes' Action
     * @param payload Contains object with company domain data
     */
    export class SaveEntitySuccess implements Action {
        type = ActionTypes.SAVE_ENTITY_SUCCESS;
        constructor(public payload: CompanyWhitelistedIp) {}
    }

    export type Actions =
        | ClearList
        | ClearManage
        | CreateEntityError
        | CreateEntityRequest
        | CreateEntitySuccess
        | DeleteEntityError
        | DeleteEntityRequest
        | DeleteEntitySuccess
        | GetEntityError
        | GetEntityRequest
        | GetEntitySuccess
        | GetListError
        | GetListRequest
        | GetListSuccess
        | NewEntity
        | SaveEntityError
        | SaveEntityRequest
        | SaveEntitySuccess;
}
