import { ProductCategory, ProductReview } from '@core/models';

export type WorkflowStatus = 'i' | 't' | 'u' | 'a' | 'r';

// TODO: String Enum (TypeScript 2.6)
export const WorkflowStatusType = {
    PENDING: 'i',
    REJECTED: 'r',
    APPROVED: 'a',
};

export type WorkflowType = 'external' | 'internal';

export interface LevelSupervisor {
    id: number;
    user: string;
}

export interface WorkflowLevel {
    id: number;
    supervisorCompany: string;
    supervisors: LevelSupervisor[];
}

export interface Workflow {
    id: number;
    isDefault: boolean;
    productCategories: ProductCategory[];
    workflowLevels: WorkflowLevel[];
}

export interface WorkflowConfiguration {
    id: number;
    // companyId: string; // only for internal workflow
    companyPartnershipId: number; // only for external workflow
    isActive: boolean;
    workflows: Workflow[];
}

export interface WorkflowConfigurationManage {
    data: WorkflowConfiguration;
    isFetching: boolean;
    isSaving: boolean;
    errors: any;
}

export interface WorkflowsConfigurationsList {
    data: {
        results: WorkflowConfiguration[];
        previous: string;
        next: string;
        count: number;
    };
    errors: any;
    isFetching: boolean;
}

export interface WorkflowConfigurationModel {
    viewMode: 'create' | 'edit';
    partnershipId: number;
    workflowConfigurationId: number;
    recipientName: string;
    publisherName: string;
    companiesOptions: any[];
    workflowConfigurationManage: WorkflowConfigurationManage;
    isFetching: boolean;
}

export interface WorkflowStep extends ProductReview {}
