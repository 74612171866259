import { ProductTemplatesList, ProductTemplatesManage } from '../models';

import * as productTemplatesActions from '../actions/product-templates.actions';

export interface State {
    manage: ProductTemplatesManage;
    list: ProductTemplatesList;
}

const initialState: State = {
    manage: {
        data: {
            id: null,
            translations: {},
            attributeGroupAssignments: [],
            productCategories: [],
            lockedCoreAttr: [],
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    list: {
        data: {
            count: null,
            limit: null,
            next: null,
            offset: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: productTemplatesActions.Actions) {
    switch (action.type) {
        case productTemplatesActions.ActionTypes.GET_PRODUCT_TEMPLATES_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productTemplatesActions.ActionTypes.GET_PRODUCT_TEMPLATES_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.GET_PRODUCT_TEMPLATES_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.GET_PRODUCT_TEMPLATE_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                    isSaving: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.GET_PRODUCT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.GET_PRODUCT_TEMPLATE_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.NEW_PRODUCT_TEMPLATE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isFetching: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.CREATE_PRODUCT_TEMPLATE_REQUEST:
        case productTemplatesActions.ActionTypes.SAVE_PRODUCT_TEMPLATE_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case productTemplatesActions.ActionTypes.CREATE_PRODUCT_TEMPLATE_SUCCESS:
        case productTemplatesActions.ActionTypes.SAVE_PRODUCT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.CREATE_PRODUCT_TEMPLATE_ERROR:
        case productTemplatesActions.ActionTypes.SAVE_PRODUCT_TEMPLATE_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.DELETE_PRODUCT_TEMPLATE_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case productTemplatesActions.ActionTypes.DELETE_PRODUCT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.DELETE_PRODUCT_TEMPLATE_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case productTemplatesActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case productTemplatesActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getManage = (state: State) => state.manage;
