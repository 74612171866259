<div
  class="file-upload"
  [ngClass]="{
    'file-upload--error': errors?.length,
    'file-upload--readonly': isDisabled,
    'input--readonly': isDisabled
  }">
  <div class="file-upload__box">
    <div class="file-upload__box-content">
      <label [attr.for]="inputId" class="file-upload__placeholder">
        <input
          type="file"
          [attr.name]="name"
          [attr.id]="inputId"
          [accept]="formatsString"
          class="file-upload__input"
          [disabled]="isDisabled"
          (change)="addFile($event)">
        <img class="file-upload__placeholder-image" src="assets/images/upload-placeholder.svg">
        <span class="file-upload__icon icon-arrow-long"></span>
      </label>

      <div
        class="file-upload__droparea"
        [ngClass]="{'file-upload__droparea--show': filesToDrop}">
        <span class="file-upload__droparea-text">Drop your file here</span>
      </div>

      <div
        class="file-upload__preview"
        [attr.data-preview]="fileName"
        [ngClass]="{'file-upload__preview--show': fileSrc}">
        <img *ngIf="fileSrc && type === 'image'" class="file-upload__preview-image" [attr.src]="fileSrc">
        <button
          type="button"
          [disabled]="isDisabled"
          (click)="onRemoveFile(name)"
          class="file-upload__remove icon-remove">
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="position !== undefined || label !== undefined"
    [ngClass]="{ 'file-upload__label--required': isRequired }"
    class="file-upload__label">
    <strong *ngIf="isPositionIndicatorVisible && position > -1">.{{ position }}</strong> {{ label }}
  </div>
</div>
