import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as fromProductReviews from '@core/reducers/product-reviews.reducer';

/**
 * Selectors from state.productReviews
 */
@Injectable()
export class ProductReviewsSelectors {
    getProductReviewsState() {
        return ($state: State): any => $state.productReviews;
    }

    getProductReviews() {
        return createSelector(
            this.getProductReviewsState(),
            fromProductReviews.getProductReviews,
        );
    }

    getRejectionReasons() {
        return createSelector(
            this.getProductReviewsState(),
            fromProductReviews.getRejectionReasons,
        );
    }

    getBulkActionStatus() {
        return createSelector(
            this.getProductReviewsState(),
            fromProductReviews.getBulkActionStatus,
        );
    }
}
