import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromCompanies from '../reducers/companies.reducer';

@Injectable()
export class CompaniesSelectors {
    /**
     * Get state.companies
     */
    getCompaniesState() {
        return ($state: State): any => $state.companies;
    }

    /**
     * Get list from state.companies
     */
    getList() {
        return createSelector(
            this.getCompaniesState(),
            fromCompanies.getList,
        );
    }

    /**
     * Get manage from state.companies
     */
    getManage() {
        return createSelector(
            this.getCompaniesState(),
            fromCompanies.getManage,
        );
    }

    getManageData() {
        return createSelector(this.getCompaniesState(), fromCompanies.getManageData);
    }


    /**
     * Get options from state.companies
     */
    getOptions() {
        return createSelector(
            this.getCompaniesState(),
            fromCompanies.getOptions,
        );
    }
}
