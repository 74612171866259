import { Component, EventEmitter, Input, Output } from '@angular/core';
import { slideHeight } from '@core/animations';
import { Product, ProductDataDiff } from '@core/models';
import { isEmptyObject } from '@app/utils';

@Component({
    animations: [slideHeight],
    selector: 'products-attributes-diff-modal',
    templateUrl: './products-attributes-diff-modal.component.html',
    styleUrls: ['./products-attributes-diff-modal.component.scss'],
})
export class ProductsAttributesDiffModalComponent {
    @Input() isFtaCompany: boolean = false;
    @Input() product: Product;
    @Input() attributesDiff: ProductDataDiff;
    @Input() errors: string[] = [];
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() confirm: EventEmitter<any> = new EventEmitter();

    private sections: Record<number, boolean> = {};
    isEmptyObject = isEmptyObject;

    isLive(): boolean {
        return this.product.status === 'l';
    }


    cancelClick() {
        this.cancel.emit();
    }

    confirmClick() {
        this.confirm.emit();
    }
}
