import {
    fromEventPattern as observableFromEventPattern,
    merge as observableMerge,
    Observable,
    Subscription,
} from 'rxjs';

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, mergeAll } from 'rxjs/operators';

declare const $: any;
declare const intlTelInputUtils: any;

@Component({
    selector: 'intl-tel',
    templateUrl: './intl-tel.component.html',
})
export class IntlTelComponent implements OnInit, OnDestroy {
    @Input() control: any = null;
    @Input() readonly: boolean;
    @Input() invalid: boolean = false;
    @Output() onNumberChange: EventEmitter<any> = new EventEmitter();

    private oldNumber: any;

    countryChanges$: any;

    changesSubscription: Subscription;

    constructor(private el: ElementRef) {}

    /**
     * Init plugin, set init value, handle changes
     */
    ngOnInit(): void {
        const input: any = this.el.nativeElement.querySelector('input');

        // Init plugin
        $(input)
            .intlTelInput({
                placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
                preferredCountries: ['us', 'gb', 'ae'],
                utilsScript: 'assets/libraries/intl-tel-utils.js',
            })
            .done(() => {
                if (typeof intlTelInputUtils !== 'undefined') {
                    this.oldNumber = intlTelInputUtils.formatNumber(this.control.value);
                }
            });

        // Create source from custom event
        this.countryChanges$ = observableFromEventPattern(
            function add(h) {
                $(input).bind('countrychange', h);
            },
            function remove(h) {
                $(input).unbind('countrychange', h);
            },
        );
        // Merge two sources - value changes and country changes
        this.changesSubscription = observableMerge([this.countryChanges$, this.control.valueChanges])
            .pipe(mergeAll(), debounceTime(500))
            .subscribe(() => {
                const newNumber = $(input).intlTelInput('getNumber');

                if (newNumber !== this.oldNumber) {
                    this.oldNumber = newNumber;
                    this.control.setValue(newNumber);
                    $(input).intlTelInput('setNumber', newNumber);

                    this.invalid = !$(input).intlTelInput('isValidNumber');
                    this.onNumberChange.emit({
                        value: newNumber,
                        error: this.invalid ? 'Not a valid telephone number' : null,
                    });
                }
            });

        // Set initial value
        setTimeout(() => {
            if (this.control.value !== null) {
                $(input).intlTelInput('setNumber', this.control.value);
                this.invalid = !$(input).intlTelInput('isValidNumber');
            }
        });
        // it takes all telephone input on the site and iterate over them form behind increasing the z-index
        // to make sure that dropdown menu will be always on top of lower telephone inputs
        const telInputs = Array.from(document.querySelectorAll('.intl-tel-input')).reverse();
        let currentZIndex = Number(getComputedStyle(telInputs[0]).zIndex);
        telInputs.forEach(elem => {
            (elem as HTMLElement).style.setProperty('z-index', String(currentZIndex++));
        });
    }

    /**
     * Unsubscribe observable on destroy
     */
    ngOnDestroy() {
        this.changesSubscription.unsubscribe();
    }
}
