<section class="common-section">
  <h1 class="common-section__header header header--size-1">Headings</h1>
  <div class="common-section__content">
    <h1 class="header header--size-1">How to protect your computer very useful</h1>
    <h2 class="header header--size-2">Headset no longer wired for sound</h2>
    <h3 class="header header--size-3">Compare prices find the best computer</h3>
    <h4 class="header header--size-4">5 reasons to choose a notebook over a</h4>
    <h5 class="header header--size-5">5 reasons to choose a notebook</h5>
    <h6 class="header header--size-6">Myspace layouts the missing</h6>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Wysiwyg</h1>
  <div class="common-section__content wysiwyg">
    <p>Lorem ipsum dolor sit amet, <strong>consectetur</strong> adipisicing elit. Quos aliquam adipisci perferendis, necessitatibus voluptate odit sit reiciendis ducimus eaque consequuntur corrupti, autem nisi commodi ab dolores <em>similique placeat</em> architecto eos? Consequuntur fugiat quae iure officiis, tenetur dolore repudiandae placeat alias earum tempore! Fuga itaque dolorum sunt fugiat mollitia, nam, blanditiis natus aut!</p>
    <p><a href="#">Lorem ipsum dolor sit amet</a></p>
    <ul>
      <li>list item</li>
      <li>list item</li>
      <li>list item
        <ul>
          <li>list item</li>
          <li>list item</li>
          <li>list item</li>
        </ul>
      </li>
      <li>list item</li>
      <li>list item</li>
    </ul>
    <p>Lorem ipsum dolor sit amet, <strong>consectetur</strong> adipisicing elit. Quos aliquam adipisci perferendis, necessitatibus voluptate odit sit reiciendis ducimus eaque consequuntur corrupti, autem nisi commodi ab dolores <em>similique placeat</em> architecto eos? Consequuntur fugiat quae iure officiis, tenetur dolore repudiandae placeat alias earum tempore! Fuga itaque dolorum sunt fugiat mollitia, nam, blanditiis natus aut!</p>
    <ol>
      <li>list item</li>
      <li>list item</li>
      <li>list item
        <ol>
          <li>list item</li>
          <li>list item</li>
          <li>list item
            <ol>
              <li>list item</li>
              <li>list item</li>
              <li>list item</li>
              <li>list item</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>list item</li>
      <li>list item</li>
    </ol>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Links</h1>
  <div class="common-section__content">
    <p><a href="#" class="text-link">Lorem ipsum dolor sit amet</a></p>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Unordered Lists</h1>
  <div class="common-section__content">
    <ul class="list">
      <li class="list__item">list item</li>
      <li class="list__item">list item</li>
      <li class="list__item">list item
        <ul class="list">
          <li class="list__item">list item</li>
          <li class="list__item">list item</li>
          <li class="list__item">list item</li>
        </ul>
      </li>
      <li class="list__item">list item</li>
      <li class="list__item">list item</li>
    </ul>
  </div>
</section>


<section class="common-section">
  <h1 class="common-section__header header header--size-1">Ordered Lists</h1>
  <div class="common-section__content">
    <ol class="list list--ordered">
      <li class="list__item">list item</li>
      <li class="list__item">list item</li>
      <li class="list__item">list item
        <ol class="list list--ordered">
          <li class="list__item">list item</li>
          <li class="list__item">list item</li>
          <li class="list__item">list item
            <ol class="list list--ordered">
              <li class="list__item">list item</li>
              <li class="list__item">list item</li>
              <li class="list__item">list item</li>
              <li class="list__item">list item</li>
            </ol>
          </li>
        </ol>
      </li>
      <li class="list__item">list item</li>
      <li class="list__item">list item</li>
    </ol>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Block quote</h1>
  <div class="common-section__content">
    <blockquote class="blockquote">
      Today, many people rely on computers to do homework, work, and create or store useful information. Therefore, it is important for the information on the computer to be stored and kept properly. It is also extremely
    </blockquote>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Buttons</h1>
  <div class="common-section__content">
    <p>
      <button class="btn">Standard button</button>
      <button class="btn btn--primary">Primary button</button>
      <button class="btn btn--secondary">Secondary button</button>
      <button class="btn btn--accent">Accent button</button>
      <button class="btn btn--alert">Alert button</button>
    </p>
    <p>
      <button class="btn btn--icon-left btn--icon-rotate-90 icon-chevron">Standard button</button>
      <button class="btn btn--primary btn--icon-right btn--icon-rotate-270 icon-chevron">Primary button</button>
      <button class="btn btn--small btn--icon-left btn--icon-rotate-90 icon-chevron">Standard button</button>
      <button class="btn btn--primary btn--small btn--icon-right btn--icon-rotate-270 icon-chevron">Primary button</button>
      <button class="btn btn--smaller btn--icon-left btn--icon-rotate-90 icon-chevron">Standard button</button>
      <button class="btn btn--primary btn--smaller btn--icon-right btn--icon-rotate-270 icon-chevron">Primary button</button>
    </p>
    <p>
      <button class="btn btn--disabled">Standard button</button>
      <button class="btn btn--primary btn--disabled">Primary button</button>
      <button class="btn btn--secondary btn--disabled">Secondary button</button>
      <button class="btn btn--accent btn--disabled">Accent button</button>
      <button class="btn btn--alert btn--disabled">Alert button</button>
    </p>
    <p>
      <button class="btn btn--small">Standard small button</button>
      <button class="btn btn--primary btn--small">Primary small button</button>
      <button class="btn btn--secondary btn--small">Secondary small button</button>
      <button class="btn btn--accent btn--small">Accent small button</button>
      <button class="btn btn--alert btn--small">Alert small button</button>
    </p>
    <p>
      <button class="btn btn--smaller">Standard smaller button</button>
      <button class="btn btn--primary btn--smaller">Primary smaller button</button>
      <button class="btn btn--secondary btn--smaller">Secondary smaller button</button>
      <button class="btn btn--accent btn--smaller">Accent smaller button</button>
      <button class="btn btn--alert btn--smaller">Alert smaller button</button>
    </p>
    <p>
      <button class="btn btn--fluid">Standard fluid button</button>
    </p>
    <p>
      <button class="btn btn--loading">Standard loading button</button>
      <button class="btn btn--small btn--loading">Standard loading button</button>
      <button class="btn btn--smaller btn--loading">Standard loading button</button>
    </p>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Inputs</h1>
  <div class="common-section__content">
    <div class="common-section__row">
      <input type="text" placeholder="Input" class="input">
    </div>
    <div class="common-section__row">
      <input type="number" placeholder="Input" class="input">
    </div>
    <div class="common-section__row">
      <input type="text" placeholder="Input fluid" class="input input--fluid">
    </div>
    <div class="common-section__row">
      <textarea class="input input--textarea">Textarea</textarea>
      <textarea rows="1" class="input input--textarea">Textarea</textarea>
      <textarea rows="2" class="input input--textarea">Textarea</textarea>
    </div>
    <div class="common-section__row">
      <textarea class="input input--textarea input--fluid">Textarea fluid</textarea>
    </div>
    <div class="common-section__row">
      <input type="text" placeholder="Disabled" class="input input--disabled" value="qweqwe">
    </div>
    <div class="common-section__row">
      <input type="text" placeholder="Readonly" class="input input--readonly" value="asd">
    </div>
    <div class="common-section__row">
      <ckeditor
        [(ngModel)]="editorContent"
        [config]="editorConfig"
        [charsCounter]="100"
        debounce="500">
      </ckeditor>
    </div>
    <div class="common-section__row">
      <input type="text" placeholder="Input fluid" class="input input--fluid" [charsCounter]="100">
    </div>
    <div class="common-section__row">
      <textarea class="input input--textarea input--fluid" [charsCounter]="100">Textarea</textarea>
    </div>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Radio inputs</h1>
  <div class="common-section__content">
    <h2 class="header header--size-2">Inline</h2>
    <div class="common-section__row">
      <label class="radio">
        <input type="radio" name="yesno1" class="radio__input" value="1" checked>
        <span class="radio__label">Yes</span>
      </label>
      <label class="radio">
        <input type="radio" name="yesno1" class="radio__input" value="0">
        <span class="radio__label">No</span>
      </label>
    </div>

    <h2 class="header header--size-2">Vertical</h2>
    <div class="common-section__row">
      <label class="radio radio--vertical">
        <input type="radio" name="yesno2" class="radio__input" value="1">
        <span class="radio__label">5 Tips For Offshore Software</span>
      </label>
      <label class="radio radio--vertical">
        <input type="radio" name="yesno2" class="radio__input" value="0" checked>
        <span class="radio__label">Computer Hardware Desktops And Notebooks</span>
      </label>
    </div>

    <h2 class="header header--size-2">Radio without text</h2>
    <div class="common-section__row">
      <label class="radio radio--without-text">
        <input type="radio" name="yesno3" class="radio__input" value="1" checked>
        <span class="radio__label"></span>
      </label>
      <label class="radio radio--without-text">
        <input type="radio" name="yesno3" class="radio__input" value="0">
        <span class="radio__label"></span>
      </label>
    </div>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Checkbox inputs</h1>
  <div class="common-section__content">
    <h2 class="header header--size-2">Inline</h2>
    <div class="common-section__row">
      <label class="checkbox">
        <input type="checkbox" class="checkbox__input" value="1" checked>
        <span class="checkbox__label"><span class="checkbox__text">Yes</span></span>
      </label>
      <label class="checkbox">
        <input type="checkbox" class="checkbox__input" value="0">
        <span class="checkbox__label"><span class="checkbox__text">No</span></span>
      </label>
    </div>

    <h2 class="header header--size-2">Vertical</h2>
    <div class="common-section__row">
      <label class="checkbox checkbox--vertical">
        <input type="checkbox" class="checkbox__input" value="1">
        <span class="checkbox__label"><span class="checkbox__text">5 Tips For Offshore Software</span></span>
      </label>
      <label class="checkbox checkbox--vertical">
        <input type="checkbox" class="checkbox__input" value="0" checked>
        <span class="checkbox__label"><span class="checkbox__text">Computer Hardware Desktops And Notebooks</span></span>
      </label>
    </div>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Select</h1>
  <div class="common-section__content">
    <form [formGroup]="form">
      <div class="common-section__row form__row">
        <div class="form__group form__group--cols-4">
          <ng-select
            [options]="options"
            [multiple]="multiple0"
            placeholder="Select one"
            formControlName="selectSingle"
            [allowClear]="true"
            (opened)="onSingleOpened()"
            (closed)="onSingleClosed()"
            (selected)="onSingleSelected($event)"
            (deselected)="onSingleDeselected($event)">
          </ng-select>
          <p>Selected option id: {{form.value['selectSingle']}}</p>
        </div>
        <div class="form__group form__group--cols-4">
          <ng-select
            [options]="options"
            [multiple]="multiple1"
            placeholder="Select multiple"
            formControlName="selectMultiple"
            [allowClear]="true"
            (opened)="onSingleOpened()"
            (closed)="onSingleClosed()"
            (selected)="onSingleSelected($event)"
            (deselected)="onSingleDeselected($event)">
          </ng-select>
          <p>Selected option id: {{form.value['selectMultiple']}}</p>
        </div>
      </div>
    </form>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Inputs group</h1>
  <div class="common-section__content">
    <div class="common-section__row">
      <div class="input-group">
        <span class="input-group__addon input-addon">http://</span>
        <input type="text" placeholder="mysite.com" class="input-group__input input">
      </div>
      <div class="input-group">
        <span class="input-group__addon input-addon">First</span>
        <input type="text" placeholder="mysite.com" class="input-group__input input">
        <a class="input-group__addon btn btn--secondary">Save</a>
      </div>
    </div>
    <div class="common-section__row">
      <div class="input-group">
        <input type="text" placeholder="mysite.com" class="input-group__input input">
        <span class="input-group__addon input-addon">First</span>
        <span class="input-group__addon input-addon">Second</span>
        <span class="input-group__addon input-addon">Third</span>
      </div>
      <div class="input-group">
        <input type="text" placeholder="Enter weight" class="input-group__input input">
        <span class="input-group__addon input-addon">.00</span>
      </div>
    </div>
    <div class="common-section__row">
      <div class="input-group">
        <span class="input-group__addon input-addon">$</span>
        <input type="text" placeholder="Amount" class="input-group__input input">
        <span class="input-group__addon input-addon">.00</span>
      </div>
      <div class="input-group">
        <span class="input-group__addon input-addon">First</span>
        <span class="input-group__addon input-addon">Second</span>
        <span class="input-group__addon input-addon">Third</span>
        <input type="text" placeholder="mysite.com" class="input-group__input input">
      </div>
    </div>

    <div class="common-section__row">
      <div class="input-group input-group--fluid">
        <span class="input-group__addon input-addon">$</span>
        <input type="text" placeholder="Amount" class="input-group__input input">
        <span class="input-group__addon input-addon">.00</span>
      </div>
    </div>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Form grid</h1>
  <div class="common-section__content">
    <form class="form">
      <header class="form__header">
        <h1 class="form__header-title header header--size-1">Create attributes</h1>
        <span class="form__flex"></span>
        <button class="btn btn--secondary">Save</button>
      </header>

      <section class="form__section">
        <h3 class="form__section-title section-title">Basic information</h3>

        <ul class="errors errors--block">
          <li class="errors__single-error">This section is required.</li>
        </ul>

        <div class="form__group">
          <label for="" class="form__label label label--required">Field 1</label>
          <input type="text" placeholder="" class="input input--fluid input--error" [charsCounter]="100">
          <ul class="errors errors--inside">
            <li class="errors__single-error">This filed is required.</li>
          </ul>
        </div>

        <div class="form__group">
          <div class="form__instruction instruction">
            You can use autocompletion for filling the address (just start typing and select one of the suggestions)
            or type address step by step. We recommend to verify your address manually after using autocomplete feature.
          </div>
          <label for="" class="form__label label">Field 2</label>
          <input type="text" placeholder="" class="input input--fluid">
        </div>
      </section>

      <section class="form__section">
        <h3 class="form__section-title section-title">Custom validators</h3>

        <div class="form__group">
          <label for="" class="form__label label">Field 3</label>
          <input type="text" placeholder="" class="input input--fluid">
          <div class="form__microcopy microcopy microcopy--align-right">
            Please upload PNG, JPG or JPEG files only
          </div>
        </div>

        <div class="form__row">
          <div class="form__group form__group--cols-3">
            <label for="" class="form__label label label--required">Field 4</label>
            <input type="text" placeholder="" class="input input--fluid">
          </div>
          <div class="form__group form__group--cols-6 form__group--prefix-1">
            <label for="" class="form__label label label--required">Field 5</label>
            <div class="input-group input-group--fluid">
              <span class="input-group__addon input-addon">$</span>
              <input type="text" placeholder="Amount" class="input-group__input input">
              <span class="input-group__addon input-addon">.00</span>
            </div>
          </div>
        </div>

        <div class="form__row">
          <div class="form__group form__group--cols-3">
            <label for="" class="form__label label label--required">Field 6</label>
            <input type="text" placeholder="" class="input input--fluid">
          </div>
          <div class="form__group form__group--cols-6 form__group--prefix-1">
            <label for="" class="form__label label label--required">Field 7</label>
            <textarea placeholder="" class="input input--textarea input--fluid"></textarea>
          </div>
        </div>

        <div class="form__row form__row--inline">
          <div class="form__group">
            <label for="" class="form__label label label--required">Field 8</label>
            <input type="text" placeholder="" class="input">
          </div>
          <div class="form__group">
            <label for="" class="form__label label label--required">Field 9</label>
            <div class="input-group">
              <span class="input-group__addon input-addon">$</span>
              <input type="text" placeholder="Amount" class="input-group__input input">
              <span class="input-group__addon input-addon">.00</span>
            </div>
          </div>
          <div class="form__group">
            <label for="" class="form__label label label--required">Field 10</label>
            <textarea placeholder="" class="input input--textarea"></textarea>
          </div>
          <div class="form__group">
            <label for="" class="form__label label label--required">Field 11</label>
            <label class="radio">
              <input type="radio" name="yesno2" class="radio__input" value="1">
              <span class="radio__label">Include</span>
            </label>
          </div>
        </div>

        <div class="form__group">
          <label for="" class="form__label label">Field 12</label>
          <input type="text" placeholder="" class="input input--fluid">
        </div>
      </section>

      <footer class="form__footer">
        <span class="form__flex"></span>
        <button class="btn btn--secondary">Save</button>
      </footer>
    </form>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Tabs</h1>
  <div class="common-section__content">
    <tabs-section-group id="section-one">
      <single-section section-title="Single attributes">
        Tab 1
      </single-section>

      <single-section section-title="Group attributes">
        Tab 2
      </single-section>
    </tabs-section-group>
    <button (click)="goToSection('section-one', 0)">First tab</button>
    <button (click)="goToSection('section-one', 1)">Second tab</button>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Tabs</h1>
  <div class="common-section__content">
    <steps-section-group id="section-two">
      <single-section section-title="Single attributes">
        Tab 1
      </single-section>

      <single-section section-title="Group attributes">
        Tab 2
      </single-section>

      <single-section section-title="Other attributes">
        Tab 3
      </single-section>

      <single-section section-title="Last attributes">
        Tab 4
      </single-section>
    </steps-section-group>
    <button (click)="goToSection('section-two', 0)">First tab</button>
    <button (click)="goToSection('section-two', 1)">Second tab</button>
    <button (click)="goToSection('section-two', 2)">Thrid tab</button>
    <button (click)="goToSection('section-two', 3)">Fourth tab</button>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Tables</h1>
  <div class="common-section__content">
    <h2 class="common-section__header header header--size-2">Basic example</h2>

    <table class="table">
      <tr class="table__row">
        <th class="table__cell-header">First name</th>
        <th class="table__cell-header">Last name</th>
        <th class="table__cell-header">Username</th>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Tom</td>
        <td class="table__cell">Doe</td>
        <td class="table__cell">Tom</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Pau</td>
        <td class="table__cell">Gasol</td>
        <td class="table__cell">Gasol</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Chris</td>
        <td class="table__cell">Paul</td>
        <td class="table__cell">Paul</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Bill</td>
        <td class="table__cell">Doe</td>
        <td class="table__cell">Doe</td>
      </tr>
    </table>

    <h2 class="common-section__header header header--size-2">Hover row</h2>

    <table class="table table--hover">
      <tr class="table__row">
        <th class="table__cell-header">First name</th>
        <th class="table__cell-header">Last name</th>
        <th class="table__cell-header">Username</th>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Tom</td>
        <td class="table__cell">Doe</td>
        <td class="table__cell">Tom</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Pau</td>
        <td class="table__cell">Gasol</td>
        <td class="table__cell">Gasol</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Chris</td>
        <td class="table__cell">Paul</td>
        <td class="table__cell">Paul</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Bill</td>
        <td class="table__cell">Doe</td>
        <td class="table__cell">Doe</td>
      </tr>
    </table>

    <h2 class="common-section__header header header--size-2">Bordered table</h2>

    <table class="table table--border">
      <tr class="table__row">
        <th class="table__cell-header">First name</th>
        <th class="table__cell-header">Last name</th>
        <th class="table__cell-header">Username</th>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Tom</td>
        <td class="table__cell">Doe</td>
        <td class="table__cell">Tom</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Pau</td>
        <td class="table__cell">Gasol</td>
        <td class="table__cell">Gasol</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Chris</td>
        <td class="table__cell">Paul</td>
        <td class="table__cell">Paul</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Bill</td>
        <td class="table__cell">Doe</td>
        <td class="table__cell">Doe</td>
      </tr>
    </table>

    <h2 class="common-section__header header header--size-2">Hover and bordered table</h2>

    <table class="table table--hover table--border">
      <tr class="table__row">
        <th class="table__cell-header">First name</th>
        <th class="table__cell-header">Last name</th>
        <th class="table__cell-header">Username</th>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Tom</td>
        <td class="table__cell">Doe</td>
        <td class="table__cell">Tom</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Pau</td>
        <td class="table__cell">Gasol</td>
        <td class="table__cell">Gasol</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Chris</td>
        <td class="table__cell">Paul</td>
        <td class="table__cell">Paul</td>
      </tr>
      <tr class="table__row">
        <td class="table__cell">Bill</td>
        <td class="table__cell">Doe</td>
        <td class="table__cell">Doe</td>
      </tr>
    </table>
  </div>
</section>

<section class="common-section">
  <h1 class="common-section__header header header--size-1">Tooltip</h1>
  <div class="common-section__content">
    <form>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Top Center" tooltipPlacement="top">Top Center Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="top">Top Center Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Top Left" tooltipPlacement="top-left">Top Left Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="top-left">Top Left Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Top Right" tooltipPlacement="top-right">Top Right Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="top-right">Top Right Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Bottom Center" tooltipPlacement="bottom">Bottom Center Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="bottom">Bottom Center Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Bottom Left" tooltipPlacement="bottom-left">Bottom Left Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="bottom-left">Bottom Left Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Bottom Right" tooltipPlacement="bottom-right">Bottom Right Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="bottom-right">Bottom Right Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Left Center" tooltipPlacement="left">Left Center Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="left">Left Center Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Left Top" tooltipPlacement="left-top">Left Top Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="left-top">Left Top Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Left Bottom" tooltipPlacement="left-bottom">Left Bottom Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="left-bottom">Left Bottom Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Right Center" tooltipPlacement="right">Right Center Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="right">Right Center Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Right Top" tooltipPlacement="right-top">Right Top Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="right-top">Right Top Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Right Bottom" tooltipPlacement="right-bottom">Right Bottom Tooltip Component</label>
        </div>
        <div class="form__group form__group--cols-6">
          <label tooltipContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." tooltipPlacement="right-bottom">Right Bottom Tooltip Component</label>
        </div>
      </div>
      <div class="form__row">
        <div class="form__group form__group--cols-6">
          <tooltip-content #myTooltip [animation]="true" placement="left">
            <b>Very</b> <span style="color: #C21F39">Dynamic</span> <span style="color: #00b3ee">Reusable</span>
            <b><i><span style="color: #ffc520">Tooltip With</span></i></b> <small>Html support</small>.
          </tooltip-content>

          <button [tooltipContent]="myTooltip">element on which this tooltip is applied.</button>
        </div>
      </div>
    </form>
  </div>
</section>
