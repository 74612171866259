import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { from, Observable, of } from 'rxjs';

import * as brands from '@core/actions/brands.actions';
import { HTTPClientVer } from '@core/utils/request.utils';

import { BrandService } from '@core/services/brands.service';
import { ModalService } from '@core/services/modal.service';
import { NotificationsService } from '@core/services/notifications.service';

import { catchErrorJson } from './catch-error';

import { BulkAction, CeleryId } from '@core/models';

@Injectable()
export class BrandsEffects {
    private importCeleryId: CeleryId;

    constructor(
        private actions$: Actions,
        private brandsService: BrandService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    @Effect()
    getBrandsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(brands.ActionTypes.GET_BRANDS_REQUEST),
        map((action: brands.GetBrandsRequestAction) => action.payload),
        switchMap((payload) => {
            return this.brandsService.getBrands(payload).pipe(
                map((response) => new brands.GetBrandsSuccessAction(response)),
                catchError((error) => of(new brands.GetBrandsErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    createBrandRequest$: Observable<Action> = this.actions$.pipe(
        ofType(brands.ActionTypes.CREATE_BRAND_REQUEST),
        map((action: brands.CreateBrandRequestAction) => action.payload),
        switchMap((payload) => {
            return this.brandsService.createBrand(payload).pipe(
                tap(() => {
                    this.notificationsService.success(`Created new brand`);
                    this.router.navigate([payload.redirectTo]);
                }),
                map((response) => new brands.CreateBrandSuccessAction(response)),
                catchError((error) => of(new brands.CreateBrandErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    getBrandRequest$: Observable<Action> = this.actions$.pipe(
        ofType(brands.ActionTypes.GET_BRAND_REQUEST),
        map((action: brands.GetBrandRequestAction) => action.payload),
        switchMap((payload) => {
            return this.brandsService.getBrand(payload).pipe(
                map((response) => new brands.GetBrandSuccessAction(response)),
                catchError((error) => of(new brands.GetBrandErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    saveBrandRequest$: Observable<Action> = this.actions$.pipe(
        ofType(brands.ActionTypes.SAVE_BRAND_REQUEST),
        map((action: brands.SaveBrandRequestAction) => action.payload),
        switchMap((payload) => {
            const { redirectTo, ...data } = payload;

            return this.brandsService.saveBrand(data).pipe(
                tap(() => {
                    this.notificationsService.success(`Updated Brand`);
                    this.router.navigate([redirectTo]);
                }),
                map((response) => new brands.SaveBrandSuccessAction(response)),
                catchError((error) => of(new brands.SaveBrandErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    deleteBrandRequest$: Observable<Action> = this.actions$.pipe(
        ofType(brands.ActionTypes.DELETE_BRAND_REQUEST),
        map((action: brands.DeleteBrandRequestAction) => action.payload),
        switchMap((payload) => {
            const { id, redirectTo } = payload;

            return this.brandsService.deleteBrand(id).pipe(
                tap(() => {
                    this.notificationsService.success(`Deleted Brand`);
                    this.router.navigate([redirectTo]);
                }),
                map((response) => new brands.DeleteBrandSuccessAction(response)),
                catchError((error) => of(new brands.DeleteBrandErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    prepareImportRequest$: Observable<Action> = this.actions$.pipe(
        ofType(brands.ActionTypes.PREPARE_BRANDS_IMPORT_REQUEST),
        map((action: brands.PrepareBrandsImportRequestAction) => action.payload),
        switchMap((payload) => {
            return this.brandsService.prepareImport(payload).pipe(
                tap((response: any) => {
                    this.importCeleryId = response.id;
                    this.modalService.open('import-brands-modal');
                }),
                mergeMap((response) =>
                    from([
                        new brands.PrepareBrandsImportSuccessAction(response),
                        new brands.GetBrandsImportStatusRequestAction(response.id),
                    ]),
                ),
                catchError((error) =>
                    of(new brands.PrepareBrandsImportErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    importStatusRequest$: Observable<Action> = this.actions$.pipe(
        ofType(brands.ActionTypes.GET_BRANDS_IMPORT_STATUS_REQUEST),
        map((action: brands.GetBrandsImportStatusRequestAction) => action.payload),
        switchMap((payload) => {
            return this.brandsService.getImportStatus(payload).pipe(
                tap(
                    (response: object) => {
                        const { status } = response as BulkAction;
                        if (status === 'SUCCESS') {
                            this.modalService.close('import-brands-modal');
                            this.notificationsService.success(`Import success.`);
                        } else if (status === 'FAILURE') {
                            this.modalService.close('import-brands-modal');
                            this.notificationsService.error('Brands import fail. Please try again later.');
                        }
                    },
                    (error) => {
                        this.modalService.close('import-brands-modal');
                    },
                ),
                map((response) => new brands.GetBrandsImportStatusSuccessAction(response as any)),
                catchError((error) =>
                    of(new brands.GetBrandsImportStatusErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    importStatusSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(brands.ActionTypes.GET_BRANDS_IMPORT_STATUS_SUCCESS),
        map((action: brands.GetBrandsImportStatusSuccessAction) => action.payload),
        delay(1000),
        filter((payload) => payload.status === 'PENDING' || payload.status === 'IN_PROGRESS'),
        map(() => new brands.GetBrandsImportStatusRequestAction(this.importCeleryId)),
    );
}
