<div class="target-files-modal">
  <div class="target-files-modal__text">
    {{ text }}
  </div>

  <div class="target-files-modal__actions">
    <a
      class="btn"
      [ngClass]="{
        'btn--disabled': isFetching
      }"
      [attr.disabled]="isFetching"
      (click)="sendLinkClick()"
    >Send to my e-mail</a>
    <a *ngIf="directDownload"
      class="btn"
      [ngClass]="{
        'btn--loading': isFetching
      }"
      [attr.disabled]="isFetching"
      (click)="downloadFilesClick()"
    >Direct download</a>
  </div>
</div>
