import { TokenDeactivatePayload, TokenGeneratePayload, TokenGenerateResponse } from '@app/core/models';
import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

export const ActionTypes = prepareActions('[Users]', [
    'CLEAR_HARD_LOGOUT',
    'CLEAR_LIST',
    'CLEAR_MANAGE',
    'CLEAR_OPTIONS',
    'CREATE_USER_ERROR',
    'CREATE_USER_REQUEST',
    'CREATE_USER_SUCCESS',
    'DEACTIVATE_API_TOKEN_ERROR',
    'DEACTIVATE_API_TOKEN_REQUEST',
    'DEACTIVATE_API_TOKEN_SUCCESS',
    'GENERATE_API_TOKEN_ERROR',
    'GENERATE_API_TOKEN_REQUEST',
    'GENERATE_API_TOKEN_SUCCESS',
    'GET_OPTIONS_REQUEST',
    'GET_OPTIONS_SUCCESS',
    'GET_USER_ERROR',
    'GET_USER_REQUEST',
    'GET_USER_SUCCESS',
    'GET_USERS_ERROR',
    'GET_USERS_REQUEST',
    'GET_USERS_SUCCESS',
    'HARD_LOGOUT_ERROR',
    'HARD_LOGOUT_REQUEST',
    'HARD_LOGOUT_SUCCESS',
    'NEW_USER',
    'RESEND_REGISTRATION_EMAIL_ERROR',
    'RESEND_REGISTRATION_EMAIL_REQUEST',
    'RESEND_REGISTRATION_EMAIL_SUCCESS',
    'SAVE_USER_ERROR',
    'SAVE_USER_REQUEST',
    'SAVE_USER_SUCCESS',
    'VALIDATE_USER_EMAIL_REQUEST',
    'VALIDATE_USER_EMAIL_SUCCESS',
    'VALIDATE_USER_EMAIL_ERROR',
]);

/**
 * Create 'Clear Hard Logout' Action
 */
export class ClearHardLogoutAction implements Action {
    type = ActionTypes.CLEAR_HARD_LOGOUT;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create User Error' Action
 * @param payload Contains object with errors
 */
export class CreateUserErrorAction implements Action {
    type = ActionTypes.CREATE_USER_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create User Request' Action
 * @param payload Contains object with user data
 */
export class CreateUserRequestAction implements Action {
    type = ActionTypes.CREATE_USER_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create User Success' Action
 * @param payload Contains object with user data
 */
export class CreateUserSuccessAction implements Action {
    type = ActionTypes.CREATE_USER_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Deactivate Api Token Error' Action
 * @param payload Contains object with errors
 */
export class DeactivateApiTokenErrorAction implements Action {
    type = ActionTypes.DEACTIVATE_API_TOKEN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Deactivate Api Token Request' Action
 * @param payload Contains object with user id
 */
export class DeactivateApiTokenRequestAction implements Action {
    type = ActionTypes.DEACTIVATE_API_TOKEN_REQUEST;

    constructor(public payload: TokenDeactivatePayload) {}
}

/**
 * Create 'Deactivate Api Token Success' Action
 * 204 No content response
 */
export class DeactivateApiTokenSuccessAction implements Action {
    type = ActionTypes.DEACTIVATE_API_TOKEN_SUCCESS;

    constructor() {}
}

/**
 * Create 'Generate Api Token Error' Action
 * @param payload Contains object with errors
 */
export class GenerateApiTokenErrorAction implements Action {
    type = ActionTypes.GENERATE_API_TOKEN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Generate Api Token Request' Action
 * @param payload Contains object with user id
 */
export class GenerateApiTokenRequestAction implements Action {
    type = ActionTypes.GENERATE_API_TOKEN_REQUEST;

    constructor(public payload: TokenGeneratePayload) {}
}

/**
 * Create 'Generate Api Token Success' Action
 * @param payload: Object with API token and user ID
 */
export class GenerateApiTokenSuccessAction implements Action {
    type = ActionTypes.GENERATE_API_TOKEN_SUCCESS;

    constructor(public payload: TokenGenerateResponse) {}
}

/**
 * Create 'Get Options Request' Action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' Action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Error' Action
 * @param payload Contains object with errors
 */
export class GetUserErrorAction implements Action {
    type = ActionTypes.GET_USER_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Request' Action
 * @param payload Contains user id
 */
export class GetUserRequestAction implements Action {
    type = ActionTypes.GET_USER_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Success' Action
 * @param payload Contains object with User data
 */
export class GetUserSuccessAction implements Action {
    type = ActionTypes.GET_USER_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Users Error' Action
 * @param payload Contains object with errors
 */
export class GetUsersErrorAction implements Action {
    type = ActionTypes.GET_USERS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Users Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetUsersRequestAction implements Action {
    type = ActionTypes.GET_USERS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Users Success' Action
 * @param payload Contains object with Users list and params
 */
export class GetUsersSuccessAction implements Action {
    type = ActionTypes.GET_USERS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Hard Logout Error' Action
 * @param payload Contains object with errors
 */
export class HardLogoutErrorAction implements Action {
    type = ActionTypes.HARD_LOGOUT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Hard Logout Request' Action
 * @param payload Contains user id
 */
export class HardLogoutRequestAction implements Action {
    type = ActionTypes.HARD_LOGOUT_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Hard Logout Success' Action
 */
export class HardLogoutSuccessAction implements Action {
    type = ActionTypes.HARD_LOGOUT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'New User' Action
 * @param payload Contains User type
 */
export class NewUserAction implements Action {
    type = ActionTypes.NEW_USER;

    constructor(public payload?: any) {}
}

/**
 * Create 'Resend Registration E-mail Error' Action
 * @param payload Contains object with errors
 */
export class ResendRegistrationEmailErrorAction implements Action {
    type = ActionTypes.RESEND_REGISTRATION_EMAIL_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Resend Registration E-mail Request' Action
 * @param payload Contains user id
 */
export class ResendRegistrationEmailRequestAction implements Action {
    type = ActionTypes.RESEND_REGISTRATION_EMAIL_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Resend Registration E-mail Success' Action
 * @param payload Contains object with User data
 */
export class ResendRegistrationEmailSuccessAction implements Action {
    type = ActionTypes.RESEND_REGISTRATION_EMAIL_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save User Error' Action
 * @param payload Contains object with errors
 */
export class SaveUserErrorAction implements Action {
    type = ActionTypes.SAVE_USER_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save User Request' Action
 * @param payload Contains object with User data
 */
export class SaveUserRequestAction implements Action {
    type = ActionTypes.SAVE_USER_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save User Success' Action
 * @param payload Contains object with User data
 */
export class SaveUserSuccessAction implements Action {
    type = ActionTypes.SAVE_USER_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Validate User Email Request' Action
 */
export class ValidateUserEmailRequestAction implements Action {
    type = ActionTypes.VALIDATE_USER_EMAIL_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Validate User Email Success' Action
 */
export class ValidateUserEmailSuccessAction implements Action {
    type = ActionTypes.VALIDATE_USER_EMAIL_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Validate User Email Error' Action
 */
export class ValidateUserEmailErrorAction implements Action {
    type = ActionTypes.VALIDATE_USER_EMAIL_ERROR;

    constructor(public payload?: any) {}
}

export type Actions =
    | ClearHardLogoutAction
    | ClearListAction
    | ClearManageAction
    | ClearOptionsAction
    | CreateUserErrorAction
    | CreateUserRequestAction
    | CreateUserSuccessAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | GetUserErrorAction
    | GetUserRequestAction
    | GetUsersErrorAction
    | GetUsersRequestAction
    | GetUsersSuccessAction
    | GetUserSuccessAction
    | HardLogoutErrorAction
    | HardLogoutRequestAction
    | HardLogoutSuccessAction
    | NewUserAction
    | ResendRegistrationEmailErrorAction
    | ResendRegistrationEmailRequestAction
    | ResendRegistrationEmailSuccessAction
    | SaveUserErrorAction
    | SaveUserRequestAction
    | SaveUserSuccessAction
    | ValidateUserEmailRequestAction
    | ValidateUserEmailSuccessAction
    | ValidateUserEmailErrorAction;
