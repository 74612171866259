import { fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { Deferred } from '@app/utils';
import { ModalInstance } from '@core/models';

interface EmitEvent {
    command: 'open' | 'close';
    id: string;
    value?: boolean;
}

@Injectable()
export class ModalService {
    public emitter: Subject<EmitEvent> = new Subject();
    private keyborad$: Observable<Event> = observableFromEvent(document, 'keyup');
    public modals: ModalInstance[] = [];

    constructor() {
        this.keyborad$
            .pipe(
                filter((event: KeyboardEvent) => !!this.openedModals().length && event.keyCode === 27),
                map(() => this.openedModal()),
                filter((modal) => Boolean(modal)),
            )
            .subscribe(({ id }) => this.close(id));
    }

    /**
     * Get modal instance
     *
     * @param {string} id modal id
     */
    getModal(id: string): ModalInstance {
        return this.modals.find((modal) => modal.id === id);
    }

    /**
     * Returns event emitter
     *
     * @returns Event emitter
     */
    getChangeEmitter(): Observable<EmitEvent> {
        return this.emitter.asObservable();
    }

    /**
     * Add modal instance to the modals list
     * @param modal Modal instance
     */
    add(modal: ModalInstance): void {
        this.modals.push(modal);
    }

    /**
     * Remove modal instance from the modals list
     * @param id Modal id
     */
    remove(id: string): void {
        this.modals = this.modals.filter((modal) => modal.id !== id);
    }

    /**
     * Update modal instance isOpened property
     * @param id Modal id
     * @param state Modal state
     */
    update(id: string, isOpened: boolean): void {
        this.modals.find((modal) => modal.id === id).isOpened = isOpened;
    }

    /**
     * Open modal instance with given id and close previous instance (if it exist)
     * @param id Modal id
     */
    open(id: string): Promise<boolean> {
        const modal = this.getModal(id);

        if (!modal) {
            return Promise.resolve(false);
        }

        this.emitter.next({ command: 'open', id });

        const previousModal = this.modals.find((modalInstance) => modalInstance.isOpened && modalInstance.id !== id);
        previousModal && this.close(previousModal.id);

        modal.deferred = new Deferred();

        return modal.deferred.promise;
    }

    /**
     * Close modal instance with given id
     * @param id Modal id
     */
    close(id: string, value: boolean = false): void {
        const modal = this.getModal(id);

        if (!modal) {
            return;
        }

        modal.deferred.resolve(value);

        this.emitter.next({ command: 'close', id, value });
    }

    /**
     * Check if modal with this id is open
     * @param id Modal id
     */
    isOpened(id: string): boolean {
        return this.openedModals().some((modal) => modal.id === id);
    }

    /**
     * Return list of opened modals
     */
    openedModals(): ModalInstance[] {
        return this.modals.filter((modal) => modal.isOpened);
    }

    openedModal(): ModalInstance | undefined {
        return this.modals.find((modal) => modal.isOpened);
    }

    /**
     * Check if modal id is unique
     * @param id Modal id
     * @returns Has unique id
     */
    hasUniqueId(id: string): boolean {
        return !this.modals.find((modal) => modal.id === id);
    }
}
