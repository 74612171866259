<div class="confirmation-modal">
  <div class="confirmation-modal__text">
    {{ text }}
  </div>

  <div class="confirmation-modal__actions">
    <a (click)="cancelClick()" class="btn">No</a>
    <a (click)="confirmClick()" class="btn btn--alert">Yes</a>
  </div>
</div>
