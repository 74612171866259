import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
    transform(value: string | number, format: string = 'DD/MM/YYYY'): string {
        if (!value || value === '') {
            return '';
        }
        return moment(value).format(format);
    }
}
