import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SectionGroupService } from '@core/services/section-group.service';

import { SectionGroupComponent } from './section-group.component';

@Component({
    selector: 'tabs-section-group',
    styleUrls: ['./tabs-section-group.component.scss'],
    templateUrl: './tabs-section-group.component.html',
})
export class TabsSectionGroupComponent extends SectionGroupComponent {
    @Input() border: boolean = false;
    @Input() sectionGroupType = 'tabs';
    @Input() stickyTabs: boolean;
    @Input() withSidebar?: boolean = false;
    @Input() isFetching: boolean = false;

    public isSticky: boolean = false;

    constructor(public activatedRoute: ActivatedRoute, public sectionGroupService: SectionGroupService) {
        super(activatedRoute, sectionGroupService);
    }
}
