import * as productCategoriesActions from '../actions/product-categories.actions';
import { ProductCategoriesList } from '../models';

export interface State {
    list: ProductCategoriesList;
    filtered: ProductCategoriesList;
}

const initialState: State = {
    list: {
        data: {
            count: null,
            limit: null,
            next: null,
            offset: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
    filtered: {
        data: {
            count: null,
            limit: null,
            next: null,
            offset: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: false,
    },
};

export function reducer(state = initialState, action: productCategoriesActions.Actions) {
    switch (action.type) {
        case productCategoriesActions.ActionTypes.GET_PRODUCT_CATEGORIES_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productCategoriesActions.ActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productCategoriesActions.ActionTypes.GET_PRODUCT_CATEGORIES_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productCategoriesActions.ActionTypes.FIND_PRODUCT_CATEGORIES_REQUEST: {
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productCategoriesActions.ActionTypes.FIND_PRODUCT_CATEGORIES_SUCCESS: {
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productCategoriesActions.ActionTypes.FIND_PRODUCT_CATEGORIES_ERROR: {
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productCategoriesActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case productCategoriesActions.ActionTypes.CLEAR_FILTERED: {
            return {
                ...state,
                filtered: {
                    ...initialState.filtered,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getFiltered = (state: State) => state.filtered;
