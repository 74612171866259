import { Action } from '@ngrx/store';

import { prepareActions } from '@app/utils';
import {
    CommonPartnerPayload,
    DefaultSharingPresetsPayload,
    GetPartnerRequirementsPayload,
    HistoryLogsPayload,
    SharingPresetsSummary,
} from '@core/models';

export const ActionTypes = prepareActions('[Company Sharing Presets]', [
    'GET_PRESETS_AVAILABLE_ATTRIBUTES_REQUEST',
    'GET_PRESETS_AVAILABLE_ATTRIBUTES_SUCCESS',
    'GET_PRESETS_AVAILABLE_ATTRIBUTES_ERROR',
    'CLEAR_PRESETS_AVAILABLE_ATTRIBUTES',

    'GET_SHARING_PRESETS_REQUEST',
    'GET_SHARING_PRESETS_SUCCESS',
    'GET_SHARING_PRESETS_ERROR',
    'CLEAR_SHARING_PRESETS',

    'CREATE_SHARING_PRESETS_REQUEST',
    'CREATE_SHARING_PRESETS_SUCCESS',
    'CREATE_SHARING_PRESETS_ERROR',

    'GET_SHARING_PRESET_REQUEST',
    'GET_SHARING_PRESET_SUCCESS',
    'GET_SHARING_PRESET_ERROR',
    'CLEAR_SHARING_PRESET',

    'UPDATE_SHARING_PRESET_REQUEST',
    'UPDATE_SHARING_PRESET_SUCCESS',
    'UPDATE_SHARING_PRESET_ERROR',

    'DELETE_SHARING_PRESET_REQUEST',
    'DELETE_SHARING_PRESET_SUCCESS',
    'DELETE_SHARING_PRESET_ERROR',

    'BULK_UPDATE_SHARING_PRESET_STATUS_REQUEST',
    'BULK_UPDATE_SHARING_PRESET_STATUS_SUCCESS',
    'BULK_UPDATE_SHARING_PRESET_STATUS_ERROR',

    'BULK_DELETE_SHARING_PRESETS_REQUEST',
    'BULK_DELETE_SHARING_PRESETS_SUCCESS',
    'BULK_DELETE_SHARING_PRESETS_ERROR',

    'GET_SHARING_PRESETS_SUMMARY_REQUEST',
    'GET_SHARING_PRESETS_SUMMARY_SUCCESS',
    'GET_SHARING_PRESETS_SUMMARY_ERROR',
    'CLEAR_SHARING_PRESETS_SUMMARY',

    'GET_PARTNER_REQUIREMENTS_REQUEST',
    'GET_PARTNER_REQUIREMENTS_SUCCESS',
    'GET_PARTNER_REQUIREMENTS_ERROR',
    'CLEAR_PARTNER_REQUIREMENTS',

    'GET_CHANGES_NOTIFICATIONS_REQUEST',
    'GET_CHANGES_NOTIFICATIONS_SUCCESS',
    'GET_CHANGES_NOTIFICATIONS_ERROR',
    'CLEAR_CHANGES_NOTIFICATIONS',

    'APPLY_CHANGES_NOTIFICATIONS_REQUEST',
    'APPLY_CHANGES_NOTIFICATIONS_SUCCESS',
    'APPLY_CHANGES_NOTIFICATIONS_ERROR',

    'GET_HISTORY_LOGS_REQUEST',
    'GET_HISTORY_LOGS_SUCCESS',
    'GET_HISTORY_LOGS_ERROR',
    'CLEAR_HISTORY_LOGS',
]);

// TODO: define payload interfaces
/**
 * Create 'Get Available Attributes Request' Action
 */
export class GetPresetsAvailableAttributesRequestAction implements Action {
    type = ActionTypes.GET_PRESETS_AVAILABLE_ATTRIBUTES_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Available Attributes Success' Action
 */
export class GetPresetsAvailableAttributesSuccessAction implements Action {
    type = ActionTypes.GET_PRESETS_AVAILABLE_ATTRIBUTES_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Available Attributes Success' Action
 */
export class GetPresetsAvailableAttributesErrorAction implements Action {
    type = ActionTypes.GET_PRESETS_AVAILABLE_ATTRIBUTES_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Available Attributes' Action
 */
export class ClearPresetsAvailableAttributesAction implements Action {
    type = ActionTypes.CLEAR_PRESETS_AVAILABLE_ATTRIBUTES;
    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Sharing Presets Request' Action
 */
export class CreateCompanySharingPresetsRequestAction implements Action {
    type = ActionTypes.CREATE_SHARING_PRESETS_REQUEST;
    constructor(public payload?: CommonPartnerPayload) {}
}

/**
 * Create 'Create Company Sharing Presets Success' Action
 */
export class CreateCompanySharingPresetsSuccessAction implements Action {
    type = ActionTypes.CREATE_SHARING_PRESETS_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Sharing Presets Error' Action
 */
export class CreateCompanySharingPresetsErrorAction implements Action {
    type = ActionTypes.CREATE_SHARING_PRESETS_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Sharing Presets Request' Action
 */
export class GetCompanySharingPresetsRequestAction implements Action {
    type = ActionTypes.GET_SHARING_PRESETS_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Sharing Presets Success' Action
 */
export class GetCompanySharingPresetsSuccessAction implements Action {
    type = ActionTypes.GET_SHARING_PRESETS_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Sharing Presets Error' Action
 */
export class GetCompanySharingPresetsErrorAction implements Action {
    type = ActionTypes.GET_SHARING_PRESETS_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Sharing Presets' Action
 */
export class ClearCompanySharingPresetsAction implements Action {
    type = ActionTypes.CLEAR_SHARING_PRESETS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Sharing Preset Request' Action
 */
export class GetCompanySharingPresetRequestAction implements Action {
    type = ActionTypes.GET_SHARING_PRESET_REQUEST;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Sharing Preset Success' Action
 */
export class GetCompanySharingPresetSuccessAction implements Action {
    type = ActionTypes.GET_SHARING_PRESET_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Sharing Preset Error' Action
 */
export class GetCompanySharingPresetErrorAction implements Action {
    type = ActionTypes.GET_SHARING_PRESET_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Sharing Preset' Action
 */
export class ClearCompanySharingPresetAction implements Action {
    type = ActionTypes.CLEAR_SHARING_PRESET;
    constructor(public payload?: any) {}
}

/**
 * Create 'Update Company Sharing Preset Request' Action
 */
export class UpdateCompanySharingPresetRequestAction implements Action {
    type = ActionTypes.UPDATE_SHARING_PRESET_REQUEST;
    constructor(public payload?: CommonPartnerPayload) {}
}

/**
 * Create 'Update Company Sharing Preset Success' Action
 */
export class UpdateCompanySharingPresetSuccessAction implements Action {
    type = ActionTypes.UPDATE_SHARING_PRESET_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Update Company Sharing Preset Error' Action
 */
export class UpdateCompanySharingPresetErrorAction implements Action {
    type = ActionTypes.UPDATE_SHARING_PRESET_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Sharing Preset Request' Action
 */
export class DeleteCompanySharingPresetRequestAction implements Action {
    type = ActionTypes.DELETE_SHARING_PRESET_REQUEST;
    constructor(public payload?: CommonPartnerPayload) {}
}

/**
 * Create 'Delete Company Sharing Preset Success' Action
 */
export class DeleteCompanySharingPresetSuccessAction implements Action {
    type = ActionTypes.DELETE_SHARING_PRESET_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Sharing Preset Error' Action
 */
export class DeleteCompanySharingPresetErrorAction implements Action {
    type = ActionTypes.DELETE_SHARING_PRESET_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Update Company Sharing Preset Status Request' Action
 */
export class BulkUpdateCompanySharingPresetStatusRequestAction implements Action {
    type = ActionTypes.BULK_UPDATE_SHARING_PRESET_STATUS_REQUEST;
    constructor(public payload?: CommonPartnerPayload) {}
}

/**
 * Create 'Bulk Update Company Sharing Preset Status Success' Action
 */
export class BulkUpdateCompanySharingPresetStatusSuccessAction implements Action {
    type = ActionTypes.BULK_UPDATE_SHARING_PRESET_STATUS_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Update Company Sharing Preset Status Error' Action
 */
export class BulkUpdateCompanySharingPresetStatusErrorAction implements Action {
    type = ActionTypes.BULK_UPDATE_SHARING_PRESET_STATUS_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Delete Company Sharing Preset Request' Action
 */
export class BulkDeleteCompanySharingPresetRequestAction implements Action {
    type = ActionTypes.BULK_DELETE_SHARING_PRESETS_REQUEST;
    constructor(public payload?: CommonPartnerPayload) {}
}

/**
 * Create 'Bulk Delete Company Sharing Preset Success' Action
 */
export class BulkDeleteCompanySharingPresetSuccessAction implements Action {
    type = ActionTypes.BULK_DELETE_SHARING_PRESETS_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Delete Company Sharing Preset Error' Action
 */
export class BulkDeleteCompanySharingPresetErrorAction implements Action {
    type = ActionTypes.BULK_DELETE_SHARING_PRESETS_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Sharing Presets Summary Request' Action
 * @param payload Contains fields (company, partner) needed to send reqeust for partner stats
 */
export class GetSharingPresetsSummaryRequest implements Action {
    type = ActionTypes.GET_SHARING_PRESETS_SUMMARY_REQUEST;
    constructor(public payload: DefaultSharingPresetsPayload) {}
}

/**
 * Create 'Get Sharing Presets Summary Success' Action
 * @param payload Contains SharingPresetsSummary object with stats for specific partner
 */
export class GetSharingPresetsSummarySuccess implements Action {
    type = ActionTypes.GET_SHARING_PRESETS_SUMMARY_SUCCESS;
    constructor(public payload: SharingPresetsSummary) {}
}

/**
 * Create 'Get Sharing Presets Summary Error' Action
 * @param payload Contains errors object
 */
export class GetSharingPresetsSummaryError implements Action {
    type = ActionTypes.GET_SHARING_PRESETS_SUMMARY_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Clear Sharing Presets Summary' Action
 * @param payload Unnecessary
 */
export class ClearSharingPresetsSummary implements Action {
    type = ActionTypes.CLEAR_SHARING_PRESETS_SUMMARY;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Requirements Request' Action
 * @param payload Contains fields (company, partner, category) needed to send reqeust for partner requirements
 */
export class GetPartnerRequirementsRequestAction implements Action {
    type = ActionTypes.GET_PARTNER_REQUIREMENTS_REQUEST;
    constructor(public payload: GetPartnerRequirementsPayload) {}
}

/**
 * Create 'Get Partner Requirements Success' Action
 * @param payload Contains SharingPresetsSummary object with stats for specific partner
 */
export class GetPartnerRequirementsSuccessAction implements Action {
    type = ActionTypes.GET_PARTNER_REQUIREMENTS_SUCCESS;
    constructor(public payload: any) {}
}

/**
 * Create 'Get Partner Requirements Error' Action
 * @param payload Contains errors object
 */
export class GetPartnerRequirementsErrorAction implements Action {
    type = ActionTypes.GET_PARTNER_REQUIREMENTS_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Clear Partner Requirements' Action
 * @param payload Unnecessary
 */
export class ClearPartnerRequirements implements Action {
    type = ActionTypes.CLEAR_PARTNER_REQUIREMENTS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Changes Notifications Request' Action
 * @param payload Contains fields (company, partner) needed to send reqeust for partner requirements
 */
export class GetChangesNotificationsRequestAction implements Action {
    type = ActionTypes.GET_CHANGES_NOTIFICATIONS_REQUEST;
    constructor(public payload: DefaultSharingPresetsPayload) {}
}

/**
 * Create 'Get Changes Notifications Success' Action
 * @param payload Contains SharingPresetsChangesNotifications object for specific partner
 */
export class GetChangesNotificationsSuccessAction implements Action {
    type = ActionTypes.GET_CHANGES_NOTIFICATIONS_SUCCESS;
    constructor(public payload: any) {}
}

/**
 * Create 'Get History Logs Request' Action
 * @param payload Contains params (company, partner) needed to send reqeust for partner requirements
 */
export class GetHistoryLogsRequestAction implements Action {
    type = ActionTypes.GET_HISTORY_LOGS_REQUEST;
    constructor(public payload: HistoryLogsPayload) {}
}

/**
 * Create 'Get History Logs Success' Action
 * @param payload Contains object with history logs for specific partner
 */
export class GetHistoryLogsSuccessAction implements Action {
    type = ActionTypes.GET_HISTORY_LOGS_SUCCESS;
    constructor(public payload: any) {}
}

/**
 * Create 'Get Changes Notifications Error' Action
 * @param payload Contains errors object
 */
export class GetChangesNotoficationsErrorAction implements Action {
    type = ActionTypes.GET_CHANGES_NOTIFICATIONS_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Get History Logs Error' Action
 * @param payload Contains errors object
 */
export class GetHistoryLogsErrorAction implements Action {
    type = ActionTypes.GET_HISTORY_LOGS_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Clear Partner Requirements' Action
 * @param payload Unnecessary
 */
export class ClearChangesNotificationsAction implements Action {
    type = ActionTypes.CLEAR_CHANGES_NOTIFICATIONS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Apply Changes Notifications Request' Action
 * @param payload Contains fields (company, partner) needed to send reqeust for partner requirements
 */
export class ApplyChangesNotificationsRequestAction implements Action {
    type = ActionTypes.APPLY_CHANGES_NOTIFICATIONS_REQUEST;
    constructor(public payload: DefaultSharingPresetsPayload) {}
}

/**
 * Create 'Apply Changes Notifications Success' Action
 * @param payload Unnecessary
 */
export class ApplyChangesNotificationsSuccessAction implements Action {
    type = ActionTypes.APPLY_CHANGES_NOTIFICATIONS_SUCCESS;
    constructor(public payload: any) {}
}

/**
 * Create 'Apply Changes Notifications Error' Action
 * @param payload Contains errors object
 */
export class ApplyChangesNotoficationsErrorAction implements Action {
    type = ActionTypes.APPLY_CHANGES_NOTIFICATIONS_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Clear History Logs' Action
 * @param payload Unnecessary
 */
export class ClearHistoryLogs implements Action {
    type = ActionTypes.CLEAR_HISTORY_LOGS;
    constructor(public payload?: any) {}
}

export type Actions =
    | GetPresetsAvailableAttributesRequestAction
    | GetPresetsAvailableAttributesSuccessAction
    | GetPresetsAvailableAttributesErrorAction
    | ClearPresetsAvailableAttributesAction
    | CreateCompanySharingPresetsRequestAction
    | CreateCompanySharingPresetsSuccessAction
    | CreateCompanySharingPresetsErrorAction
    | GetCompanySharingPresetsRequestAction
    | GetCompanySharingPresetsSuccessAction
    | GetCompanySharingPresetsErrorAction
    | ClearCompanySharingPresetsAction
    | GetCompanySharingPresetRequestAction
    | GetCompanySharingPresetSuccessAction
    | GetCompanySharingPresetErrorAction
    | ClearCompanySharingPresetAction
    | UpdateCompanySharingPresetRequestAction
    | UpdateCompanySharingPresetSuccessAction
    | UpdateCompanySharingPresetErrorAction
    | DeleteCompanySharingPresetRequestAction
    | DeleteCompanySharingPresetSuccessAction
    | DeleteCompanySharingPresetErrorAction
    | BulkUpdateCompanySharingPresetStatusRequestAction
    | BulkUpdateCompanySharingPresetStatusSuccessAction
    | BulkUpdateCompanySharingPresetStatusErrorAction
    | BulkDeleteCompanySharingPresetRequestAction
    | BulkDeleteCompanySharingPresetSuccessAction
    | BulkDeleteCompanySharingPresetErrorAction
    | GetSharingPresetsSummaryRequest
    | GetSharingPresetsSummarySuccess
    | GetSharingPresetsSummaryError
    | ClearSharingPresetsSummary
    | GetPartnerRequirementsRequestAction
    | GetPartnerRequirementsSuccessAction
    | GetPartnerRequirementsErrorAction
    | ClearPartnerRequirements
    | GetChangesNotificationsRequestAction
    | GetChangesNotificationsSuccessAction
    | GetChangesNotoficationsErrorAction
    | ClearChangesNotificationsAction
    | ApplyChangesNotificationsRequestAction
    | ApplyChangesNotificationsSuccessAction
    | ApplyChangesNotoficationsErrorAction
    | GetHistoryLogsRequestAction
    | GetHistoryLogsSuccessAction
    | GetHistoryLogsErrorAction
    | ClearHistoryLogs;
