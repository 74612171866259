import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromBrands from '../reducers/brands.reducer';

@Injectable()
export class BrandsSelectors {
    /**
     * Get state.brands
     */
    getBrandsState() {
        return ($state: State): any => $state.brands;
    }

    /**
     * Get list from state.brands
     */
    getList() {
        return createSelector(
            this.getBrandsState(),
            fromBrands.getList,
        );
    }

    /**
     * Get manage from state.brands
     */
    getManage() {
        return createSelector(
            this.getBrandsState(),
            fromBrands.getManage,
        );
    }

    /**
     * Get prepareImport from state.brands
     */
    prepareImport() {
        return createSelector(
            this.getBrandsState(),
            fromBrands.prepareImport,
        );
    }

    /**
     * Get importStatus from state.brands
     */
    getImportStatus() {
        return createSelector(
            this.getBrandsState(),
            fromBrands.getImportStatus,
        );
    }
}
