import * as mediaProfilesActions from '@core/actions/media-profiles.actions';

import { DefaultList, DefaultManage, DefaultOptions, SampleImage } from '@core/models';

export interface State {
    availableImages: DefaultManage<any>;
    list: DefaultList<any>;
    manage: DefaultManage<any>;
    options: DefaultOptions<any>;
    sampleImagePreview: DefaultManage<SampleImage>;
}

const initialState: State = {
    availableImages: {
        data: {},
        errors: {},
        isFetching: true,
    },
    list: {
        data: {
            count: null,
            limit: null,
            next: null,
            offset: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {},
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    options: {
        data: {},
        isFetching: true,
    },
    sampleImagePreview: {
        data: {
            url: null,
        },
        errors: {},
        isFetching: false,
    },
};

export function reducer(state: State = initialState, action: mediaProfilesActions.Actions): State {
    switch (action.type) {
        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILES_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILES_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILES_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: true,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_OPTIONS_ERROR: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_AVAILABLE_IMAGES_REQUEST: {
            return {
                ...state,
                availableImages: {
                    ...state.availableImages,
                    data: {},
                    isFetching: true,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_AVAILABLE_IMAGES_SUCCESS: {
            return {
                ...state,
                availableImages: {
                    ...state.availableImages,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.GET_MEDIA_PROFILE_AVAILABLE_IMAGES_ERROR: {
            return {
                ...state,
                availableImages: {
                    ...state.availableImages,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.SAVE_MEDIA_PROFILE_REQUEST:
        case mediaProfilesActions.ActionTypes.CREATE_MEDIA_PROFILE_REQUEST:
        case mediaProfilesActions.ActionTypes.DELETE_MEDIA_PROFILE_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                    errors: {},
                },
            };
        }

        case mediaProfilesActions.ActionTypes.SAVE_MEDIA_PROFILE_SUCCESS:
        case mediaProfilesActions.ActionTypes.CREATE_MEDIA_PROFILE_SUCCESS:
        case mediaProfilesActions.ActionTypes.DELETE_MEDIA_PROFILE_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.SAVE_MEDIA_PROFILE_ERROR:
        case mediaProfilesActions.ActionTypes.CREATE_MEDIA_PROFILE_ERROR:
        case mediaProfilesActions.ActionTypes.DELETE_MEDIA_PROFILE_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.CLEAR_MEDIA_PROFILE_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.CLEAR_MEDIA_PROFILE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.CLEAR_MEDIA_PROFILES: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.POST_SAMPLE_IMAGE_REQUEST: {
            return {
                ...state,
                sampleImagePreview: {
                    ...state.sampleImagePreview,
                    data: {
                        ...initialState.sampleImagePreview.data,
                    },
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.POST_SAMPLE_IMAGE_SUCCESS: {
            return {
                ...state,
                sampleImagePreview: {
                    ...state.sampleImagePreview,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.POST_SAMPLE_IMAGE_ERROR: {
            return {
                ...state,
                sampleImagePreview: {
                    ...state.sampleImagePreview,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case mediaProfilesActions.ActionTypes.CLEAR_SAMPLE_IMAGE: {
            return {
                ...state,
                sampleImagePreview: {
                    ...initialState.sampleImagePreview,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getList = (state: State) => state.list;
export const getManage = (state: State) => state.manage;
export const getOptions = (state: State) => state.options;
export const getAvailableImages = (state: State) => state.availableImages;
export const getSampleImagePreview = (state: State) => state.sampleImagePreview;
