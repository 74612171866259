
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Company, LocalUser } from '@core/models';
import * as fromRoot from '@core/reducers';
import { CompaniesSelectors } from '@core/selectors/companies.selectors';

import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';

@Injectable()
export class KnowledgeBaseService {
    constructor(private store: Store<fromRoot.State>, private companiesSelectors: CompaniesSelectors) {}

    getKnowledgeBaseLink(localUser?: LocalUser) {
        return this.store.pipe(
            select(this.companiesSelectors.getManageData()),
            map((company: Company) => {
                const localUserCompany = localUser ? localUser.data.company : company;
                if (localUserCompany.id === environment.dhaCompanyId) {
                    return environment.knowledgeBaseUrls.dha;
                }
                if (localUserCompany.subtype === 'fta') {
                    return environment.knowledgeBaseUrls.fta;
                }
                if (localUserCompany.subtype === 'healthcare') {
                    return environment.knowledgeBaseUrls.healthcare;
                }
                return environment.knowledgeBaseUrls.retail;
            }));
    }

    get contactSupport(): string {
        return environment.knowledgeBaseUrls.ftaProvider.contactSupport;
    }
}
