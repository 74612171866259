import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

import * as moment from 'moment';

import { FormBuilder, FormGroup } from '@angular/forms';
import { LocalUser } from '@app/core/models';

@Component({
    selector: 'history-logs-filters',
    templateUrl: './history-logs-filters.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryLogsFiltersComponent implements OnInit {
    @Input() localUser: LocalUser;
    @Output() changes: EventEmitter<any> = new EventEmitter();

    public filtersForm: FormGroup;
    public filtersOverflow: boolean = true;

    private defaultFormValue: any = {
        company: null,
        created__gte: null,
        created__lte: null,
        user: null,
    };

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.filtersForm = this.fb.group(this.defaultFormValue);

        this.filtersForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            this.changes.emit(value);
        });
    }

    public onCalendarToggle(state: number): void {
        state !== 2 ? this.disableFiltersOverflow() : this.enableFiltersOverflow();
    }

    /**
     * Helper function for update date value
     * @param date date string
     * @param direction add or substrack part of date
     * @param value change value
     * @param type type of part of date (days, hours, months...)
     */
    public updateDate(date: string, direction: string, value: any, type: string): any {
        if (!date || (date && !date.length)) {
            return null;
        }

        if (direction === 'add') {
            return moment(date)
                .add(value, type)
                .format('YYYY/MM/DD');
        } else if (direction === 'subtract') {
            return moment(date)
                .subtract(value, type)
                .format('YYYY/MM/DD');
        } else {
            return null;
        }
    }

    private enableFiltersOverflow(): void {
        this.filtersOverflow = true;
    }

    private disableFiltersOverflow(): void {
        this.filtersOverflow = false;
    }
}
