import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromUtils from '../reducers/utils.reducer';

@Injectable()
export class UtilsSelectors {
    /**
     * Get state.utils
     */
    getUtilsState() {
        return ($state: State): any => $state.utils;
    }

    /**
     * Get countries list from state.utils.
     */
    getCountriesList() {
        return createSelector(
            this.getUtilsState(),
            fromUtils.getCountries,
        );
    }
}
