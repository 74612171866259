import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class CompaniesReadOwnGuard extends BaseGuard {
    path = ['companies', 'readOwn'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class CompaniesManageGuard extends BaseGuard {
    path = ['companies', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class CompaniesManageOwnGuard extends BaseGuard {
    path = ['companies', 'manageOwn'];
    constructor(injector: Injector) {
        super(injector);
    }
}
