import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { from, Observable, of } from 'rxjs';

import * as listedProductsActions from '@core/actions/listed-products.actions';
import * as productReviewActions from '@core/actions/product-reviews.actions';

import { ModalService } from '@core/services/modal.service';
import { NotificationsService } from '@core/services/notifications.service';
import { ProductReviewsService } from '@core/services/product-reviews.service';

import { catchErrorHttpClientJson } from './catch-error';

import { BulkProductReview, BulkProductStatus, CeleryStatusType } from '@core/models';

@Injectable()
export class ProductReviewsEffects {
    constructor(
        private actions$: Actions,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private productReviewsService: ProductReviewsService,
        private router: Router,
    ) {}

    @Effect()
    postProductReviewActionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productReviewActions.ActionTypes.POST_PRODUCT_REVIEW_REQUEST),
        map((action: productReviewActions.PostProductReviewRequestAction) => action.payload),
        switchMap((payload) => {
            return this.productReviewsService.postProductReview(payload.review).pipe(
                tap(
                    () => {
                        this.notificationsService.success('Product reviewed');
                        if (payload.redirectTo) {
                            this.router.navigate([payload.redirectTo], { queryParams: payload.params });
                        }
                    },
                    () => {
                        this.modalService.close('reject-request');
                    },
                ),
                map((response) => new productReviewActions.PostProductReviewSuccessAction(response)),
                catchError((error) =>
                    of(new productReviewActions.PostProductReviewErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    postBulkProductsReviewActionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productReviewActions.ActionTypes.POST_BULK_PRODUCTS_REVIEW_REQUEST),
        map((action: productReviewActions.PostBulkProductsReviewRequestAction) => action.payload),
        switchMap((payload: BulkProductReview) => {
            return this.productReviewsService.postBulkProductsReview(payload.reviews).pipe(
                map((response) => response.id),
                mergeMap((celeryId) => {
                    if (payload.modalIdClose) {
                        this.modalService.close(payload.modalIdClose);
                    }

                    if (payload.modalIdOpen) {
                        this.modalService.open(payload.modalIdOpen);
                    }

                    return of(
                        new productReviewActions.GetBulkReviewStatusRequestAction({
                            celeryId,
                            modalId: payload.modalIdOpen,
                            params: payload.params,
                            listsParams: payload.listsParams,
                        }),
                    );
                }),
                catchError((error) =>
                    of(new productReviewActions.PostBulkProductsReviewErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    bulkProductsReviewStatusRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productReviewActions.ActionTypes.GET_BULK_REVIEW_STATUS_REQUEST),
        map((action: productReviewActions.GetBulkReviewStatusRequestAction) => action.payload),
        switchMap((payload: BulkProductStatus) => {
            return this.productReviewsService.getBulkReviewStatus(payload.celeryId).pipe(
                tap(
                    (response) => {
                        const status: CeleryStatusType = response.status;

                        if (status === 'SUCCESS') {
                            this.modalService.close(payload.modalId);
                            this.notificationsService.success(`Products have been reviewed.`);
                        } else if (status === 'FAILURE') {
                            this.modalService.close(payload.modalId);
                            this.notificationsService.error('Products review fail. Please try again later.');
                        }
                    },
                    () => {
                        this.modalService.close(payload.modalId);
                    },
                ),
                mergeMap((response) => {
                    const status: CeleryStatusType = response.status;
                    const actions: Action[] = [new productReviewActions.PostBulkProductsReviewSuccessAction(response)];

                    if (status === 'SUCCESS' || status === 'FAILURE') {
                        if (payload.listsParams) {
                            actions.push(
                                ...payload.listsParams.map(
                                    ({ listType, request }) =>
                                        new listedProductsActions.GetListedProductsRequestAction({ listType, request }),
                                ),
                            );
                        } else {
                            actions.push(
                                new listedProductsActions.GetListedProductsRequestAction({
                                    listType: 'list',
                                    request: payload.params,
                                }),
                            );
                        }
                    } else {
                        actions.push(
                            new productReviewActions.GetBulkReviewStatusSuccessAction({
                                ...payload,
                                response,
                            }),
                        );
                    }

                    return from(actions);
                }),
                catchError((error) =>
                    of(new productReviewActions.GetBulkReviewStatusErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    bulkProductsReviewStatusSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(productReviewActions.ActionTypes.GET_BULK_REVIEW_STATUS_SUCCESS),
        map((action: productReviewActions.GetBulkReviewStatusSuccessAction) => action.payload),
        delay(1000),
        filter((payload) => payload.response.status === 'PENDING' || payload.response.status === 'IN_PROGRESS'),
        map(({ celeryId, modalId, params, listsParams }) => {
            return new productReviewActions.GetBulkReviewStatusRequestAction({
                celeryId,
                modalId,
                params,
                listsParams,
            });
        }),
    );

    @Effect()
    getRejectionReasonsActionsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(productReviewActions.ActionTypes.GET_REJECTION_REASONS_REQUEST),
        map((action: productReviewActions.GetRejectionReasonsRequest) => action.payload),
        switchMap((payload) => {
            return this.productReviewsService.getRejectionReasons(payload).pipe(
                map((response) => new productReviewActions.GetRejectionReasonsSuccess(response)),
                catchError((error) =>
                    of(new productReviewActions.GetRejectionReasonsError(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );

    @Effect()
    getProductReviewsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(listedProductsActions.ActionTypes.GET_LISTED_PRODUCT_REVIEWS_REQUEST),
        map((action: listedProductsActions.GetListedProductReviewsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.productReviewsService.getProductReviews(payload).pipe(
                map((response) => new listedProductsActions.GetListedProductReviewsSuccessAction(response)),
                catchError((error) =>
                    of(new listedProductsActions.GetListedProductReviewsErrorAction(catchErrorHttpClientJson(error))),
                ),
            );
        }),
    );
}
