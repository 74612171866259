import * as workflowsActions from '../actions/workflows.actions';
import { WorkflowConfigurationManage, WorkflowsConfigurationsList } from '../models';

export interface State {
    list: WorkflowsConfigurationsList;
    manage: WorkflowConfigurationManage;
}

const initialState: State = {
    list: {
        data: {
            results: [],
            next: null,
            previous: null,
            count: null,
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            id: null,
            companyPartnershipId: null,
            isActive: false,
            workflows: [],
        },
        isFetching: true,
        isSaving: false,
        errors: {},
    },
};

export function reducer(state: State = initialState, action: workflowsActions.Actions): State {
    switch (action.type) {
        case workflowsActions.ActionTypes.NEW_CONFIGURATION: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    data: {
                        ...initialState.manage.data,
                    },
                    isFetching: false,
                },
            };
        }

        case workflowsActions.ActionTypes.CREATE_CONFIGURATION_REQUEST:
        case workflowsActions.ActionTypes.SAVE_CONFIGURATION_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case workflowsActions.ActionTypes.CREATE_CONFIGURATION_SUCCESS:
        case workflowsActions.ActionTypes.SAVE_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case workflowsActions.ActionTypes.CREATE_CONFIGURATION_ERROR:
        case workflowsActions.ActionTypes.SAVE_CONFIGURATION_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case workflowsActions.ActionTypes.GET_CONFIGURATION_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                    isSaving: false,
                },
            };
        }

        case workflowsActions.ActionTypes.GET_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case workflowsActions.ActionTypes.GET_CONFIGURATION_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case workflowsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        default:
            return state;
    }
}

export const getManage = (state: State) => state.manage;
