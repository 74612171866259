import { forkJoin as observableForkJoin, from, Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as news from '../actions/news.actions';

import { NewsService } from '../services/news.service';
import { NotificationsService } from '../services/notifications.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class NewsEffects {
    constructor(
        private actions$: Actions,
        private newsService: NewsService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    @Effect()
    getNewsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(news.ActionTypes.GET_NEWS_REQUEST),
        map((action: news.GetNewsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.newsService.getNews(payload).pipe(
                map((response) => new news.GetNewsSuccessAction(response)),
                catchError((error) => of(new news.GetNewsErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    updateNewsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(news.ActionTypes.UPDATE_NEWS),
        map((action: news.UpdateNewsAction) => action.payload),
        mergeMap((payload) => {
            if (!payload.images.length) {
                return of(new news.SaveNewsRequestAction(payload));
            } else {
                return of(new news.UploadNewsImagesRequestAction(payload));
            }
        }),
    );

    @Effect()
    saveNewsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(news.ActionTypes.SAVE_NEWS_REQUEST),
        map((action: news.SaveNewsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.newsService.saveNews(payload.form).pipe(
                tap(() => this.notificationsService.success(`News details saved`)),
                map((response) => new news.SaveNewsSuccessAction(response)),
                catchError((error) => of(new news.SaveNewsErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    );

    @Effect()
    uploadNewsImageRequest$: Observable<Action> = this.actions$.pipe(
        ofType(news.ActionTypes.UPLOAD_NEWS_IMAGES_REQUEST),
        map((action: news.UploadNewsImagesRequestAction) => action.payload),
        mergeMap((payload: any) => {
            return observableForkJoin(
                payload.images.map((image) => this.newsService.uploadNewsImage({ id: image.id, data: image.file })),
            ).pipe(
                tap(() => this.notificationsService.success(`News images saved`)),
                mergeMap(() =>
                    from([
                        new news.UploadNewsImagesSuccessAction(),
                        new news.UpdateNewsAction({ ...payload, images: [] }),
                    ]),
                ),
                catchError((error) =>
                    of(new news.UploadNewsImagesErrorAction(catchErrorJson({ error, id: payload.id }, HTTPClientVer))),
                ),
            );
        }),
    );
}
