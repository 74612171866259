<label
  class="product-form-control"
  [for]="id"
  [ngClass]="{'product-form-control--selectable': selectMode}">

  <ng-content></ng-content>

  <input
    #input
    [id]="id"
    [name]="id"
    type="checkbox"
    class="checkbox__input"
    [(ngModel)]="value"
  >

  <span
    *ngIf="selectMode"
    class="checkbox__label product-form-control__checkbox">
    <span class="checkbox__text"></span>
  </span>
</label>
