import { Action } from '@ngrx/store';

import { type } from '@app/utils';

const ACTION_PREFIX = '[Company Paid Plans]';

export const ActionTypes = {
    GET_COMPANY_PAID_PLANS_REQUEST: type(`${ACTION_PREFIX} Get Company Paid Plans Request`),
    GET_COMPANY_PAID_PLANS_SUCCESS: type(`${ACTION_PREFIX} Get Company Paid Plans Success`),
    GET_COMPANY_PAID_PLANS_ERROR: type(`${ACTION_PREFIX} Get Company Paid Plans Error`),
    GET_COMPANY_PAID_PLAN_REQUEST: type(`${ACTION_PREFIX} Get Company Paid Plan Request`),
    GET_COMPANY_PAID_PLAN_SUCCESS: type(`${ACTION_PREFIX} Get Company Paid Plan Success`),
    GET_COMPANY_PAID_PLAN_ERROR: type(`${ACTION_PREFIX} Get Company Paid Plan Error`),
    NEW_COMPANY_PAID_PLAN: type(`${ACTION_PREFIX} New Company Paid Plan`),
    CREATE_COMPANY_PAID_PLAN_REQUEST: type(`${ACTION_PREFIX} Create Company Paid Plan Request`),
    CREATE_COMPANY_PAID_PLAN_SUCCESS: type(`${ACTION_PREFIX} Create Company Paid Plan Success`),
    CREATE_COMPANY_PAID_PLAN_ERROR: type(`${ACTION_PREFIX} Create Company Paid Plan Error`),
    SAVE_COMPANY_PAID_PLAN_REQUEST: type(`${ACTION_PREFIX} Save Company Paid Plan Request`),
    SAVE_COMPANY_PAID_PLAN_SUCCESS: type(`${ACTION_PREFIX} Save Company Paid Plan Success`),
    SAVE_COMPANY_PAID_PLAN_ERROR: type(`${ACTION_PREFIX} Save Company Paid Plan Error`),
    DELETE_COMPANY_PAID_PLAN_REQUEST: type(`${ACTION_PREFIX} Delete Company Paid Plan Request`),
    DELETE_COMPANY_PAID_PLAN_SUCCESS: type(`${ACTION_PREFIX} Delete Company Paid Plan Success`),
    DELETE_COMPANY_PAID_PLAN_ERROR: type(`${ACTION_PREFIX} Delete Company Paid Plan Error`),
    GET_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Options Request`),
    GET_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Options Success`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
    CLEAR_OPTIONS: type(`${ACTION_PREFIX} Clear Options`),
};

/**
 * Create 'Get Company Paid Plans Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetCompanyPaidPlansRequestAction implements Action {
    type = ActionTypes.GET_COMPANY_PAID_PLANS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Paid Plans Succes' Action
 * @param payload Contains object with company paid plans list and params
 */
export class GetCompanyPaidPlansSuccessAction implements Action {
    type = ActionTypes.GET_COMPANY_PAID_PLANS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Paid Plans Error' Action
 * @param payload Contains object with errors
 */
export class GetCompanyPaidPlansErrorAction implements Action {
    type = ActionTypes.GET_COMPANY_PAID_PLANS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Paid Plan Request' action
 * @param payload Contains company paid plan id
 */
export class GetCompanyPaidPlanRequestAction implements Action {
    type = ActionTypes.GET_COMPANY_PAID_PLAN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Paid Plan Success' action
 * @param payload Contains object with company paid plans data
 */
export class GetCompanyPaidPlanSuccessAction implements Action {
    type = ActionTypes.GET_COMPANY_PAID_PLAN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Company Paid Plan Error' action
 * @param payload Contains object with errors
 */
export class GetCompanyPaidPlanErrorAction implements Action {
    type = ActionTypes.GET_COMPANY_PAID_PLAN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Company Paid Plan' action
 */
export class NewCompanyPaidPlanAction implements Action {
    type = ActionTypes.NEW_COMPANY_PAID_PLAN;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Paid Plan Request' action
 * @param payload Contains object with company paid plans data
 */
export class CreateCompanyPaidPlanRequestAction implements Action {
    type = ActionTypes.CREATE_COMPANY_PAID_PLAN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Paid Plan Success' action
 * @param payload Contains object with company paid plans data
 */
export class CreateCompanyPaidPlanSuccessAction implements Action {
    type = ActionTypes.CREATE_COMPANY_PAID_PLAN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Company Paid Plan Error' action
 * @param payload Contains object with errors
 */
export class CreateCompanyPaidPlanErrorAction implements Action {
    type = ActionTypes.CREATE_COMPANY_PAID_PLAN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Paid Plan Request' action
 * @param payload Contais object with company paid plans data
 */
export class SaveCompanyPaidPlanRequestAction implements Action {
    type = ActionTypes.SAVE_COMPANY_PAID_PLAN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Paid Plan Success' action
 * @param payload Contais object with company paid plans data
 */
export class SaveCompanyPaidPlanSuccessAction implements Action {
    type = ActionTypes.SAVE_COMPANY_PAID_PLAN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Company Paid Plan Error' action
 * @param payload Contains object with errors
 */
export class SaveCompanyPaidPlanErrorAction implements Action {
    type = ActionTypes.SAVE_COMPANY_PAID_PLAN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Paid Plan Request' action
 * Contains company paid plans id
 */
export class DeleteCompanyPaidPlanRequestAction implements Action {
    type = ActionTypes.DELETE_COMPANY_PAID_PLAN_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Paid Plan Success' action
 */
export class DeleteCompanyPaidPlanSuccessAction implements Action {
    type = ActionTypes.DELETE_COMPANY_PAID_PLAN_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Company Paid Plan Error' action
 * @param payload Contains object with errors
 */
export class DeleteCompanyPaidPlanErrorAction implements Action {
    type = ActionTypes.DELETE_COMPANY_PAID_PLAN_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetCompanyPaidPlansRequestAction
    | GetCompanyPaidPlansSuccessAction
    | GetCompanyPaidPlansErrorAction
    | GetCompanyPaidPlanRequestAction
    | GetCompanyPaidPlanSuccessAction
    | GetCompanyPaidPlanErrorAction
    | NewCompanyPaidPlanAction
    | CreateCompanyPaidPlanRequestAction
    | CreateCompanyPaidPlanSuccessAction
    | CreateCompanyPaidPlanErrorAction
    | SaveCompanyPaidPlanRequestAction
    | SaveCompanyPaidPlanSuccessAction
    | SaveCompanyPaidPlanErrorAction
    | DeleteCompanyPaidPlanRequestAction
    | DeleteCompanyPaidPlanSuccessAction
    | DeleteCompanyPaidPlanErrorAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | ClearListAction
    | ClearManageAction
    | ClearOptionsAction;
