import { Pipe, PipeTransform } from '@angular/core';
import { getFilenameFromURL } from '@app/utils';

@Pipe({
    name: 'readFilename',
})
export class ReadFilenamePipe implements PipeTransform {
    transform(value: string): string {
        if (!value || value === '') {
            return '';
        }
        return getFilenameFromURL(value);
    }
}
