import { Component, Input } from '@angular/core';

@Component({
    selector: 'form-select',
    templateUrl: './form-select.component.html',
    styleUrls: ['form-select.component.scss'],
})
export class FormSelectComponent {
    @Input() label: string = '';
    @Input() required: boolean = false;
    @Input() isValid: boolean = false;
    @Input() isTouched: boolean = false;
    @Input() successMessage: string = '';
    @Input() errorMessage: string = '';
    @Input() errors: string[] = [];

    constructor() {}
}
