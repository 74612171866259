import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as exportHistoryLogsActions from '@core/actions/export-history-logs.actions';
import { ExportHistoryLogsService } from '@core/services/export-history-logs.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class ExportHistoryLogsEffects {
    constructor(private actions$: Actions, private exportHistoryLogsService: ExportHistoryLogsService) {}

    @Effect()
    getExportHistoryLogsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(exportHistoryLogsActions.ActionTypes.GET_LIST_REQUEST),
        map((action: exportHistoryLogsActions.GetListRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.exportHistoryLogsService.getExportHistoryLogs(payload).pipe(
                map((response) => new exportHistoryLogsActions.GetListSuccessAction(response)),
                catchError((error) =>
                    of(new exportHistoryLogsActions.GetListErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    getImagePlatformExportHistoryLogRequest$: Observable<Action> = this.actions$.pipe(
        ofType(exportHistoryLogsActions.ActionTypes.GET_IMAGE_PLATFORM_LOG_REQUEST),
        map((action: exportHistoryLogsActions.GetImagePlatformLogRequestAction) => action.payload),
        switchMap((payload: number) => {
            return this.exportHistoryLogsService.getImagePlatformExportHistoryLog(payload).pipe(
                map((response) => new exportHistoryLogsActions.GetImagePlatformLogSuccessAction(response)),
                catchError((error) =>
                    of(
                        new exportHistoryLogsActions.GetImagePlatformLogErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    getMediaExportHistoryLogRequest$: Observable<Action> = this.actions$.pipe(
        ofType(exportHistoryLogsActions.ActionTypes.GET_MEDIA_LOG_REQUEST),
        map((action: exportHistoryLogsActions.GetMediaLogRequestAction) => action.payload),
        switchMap((payload: number) => {
            return this.exportHistoryLogsService.getProductMediaExportHistoryLog(payload).pipe(
                map((response) => new exportHistoryLogsActions.GetMediaLogSuccessAction(response)),
                catchError((error) =>
                    of(new exportHistoryLogsActions.GetMediaLogErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    getDataExportHistoryLogRequest$: Observable<Action> = this.actions$.pipe(
        ofType(exportHistoryLogsActions.ActionTypes.GET_DATA_LOG_REQUEST),
        map((action: exportHistoryLogsActions.GetDataLogRequestAction) => action.payload),
        switchMap((payload: number) => {
            return this.exportHistoryLogsService.getProductDataExportHistoryLog(payload).pipe(
                map((response) => new exportHistoryLogsActions.GetDataLogSuccessAction(response)),
                catchError((error) =>
                    of(new exportHistoryLogsActions.GetDataLogErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    );

    @Effect()
    getSharingHistoryExportHistoryLogRequest$: Observable<Action> = this.actions$.pipe(
        ofType(exportHistoryLogsActions.ActionTypes.GET_SHARING_HISTORY_LOG_REQUEST),
        map((action: exportHistoryLogsActions.GetSharingHistoryLogRequestAction) => action.payload),
        switchMap((payload: number) => {
            return this.exportHistoryLogsService.getProductSharingHistoryExportHistoryLog(payload).pipe(
                map((response) => new exportHistoryLogsActions.GetSharingHistoryLogSuccessAction(response)),
                catchError((error) =>
                    of(new exportHistoryLogsActions.GetSharingHistoryLogErrorAction(catchErrorJson(error, HTTPClientVer)))
                ),
            );
        }),
    );
}
