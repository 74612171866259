import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

import {
    CommonPartnerPayload,
    GetPartnersPayload,
    PartnerDataRequirementChanges,
    PartnerDataRequirementChangesPayload,
    PartnerDataRequirementPayload,
    PartnerDataRequirementsPayload,
} from '@core/models';

const ACTION_PREFIX = '[Partners]';

export const ActionTypes = prepareActions(ACTION_PREFIX, [
    'GET_PARTNERS_REQUEST',
    'GET_PARTNERS_SUCCESS',
    'GET_PARTNERS_ERROR',
    'CLEAR_PARTNERS',

    'GET_PARTNER_DATA_REQUEST',
    'GET_PARTNER_DATA_SUCCESS',
    'GET_PARTNER_DATA_ERROR',
    'CLEAR_PARTNER_DATA',

    'GET_DATA_REQUIREMENTS_REQUEST',
    'GET_DATA_REQUIREMENTS_SUCCESS',
    'GET_DATA_REQUIREMENTS_ERROR',
    'CLEAR_DATA_REQUIREMENTS',

    'GET_DATA_REQIUREMENT_REQUEST',
    'GET_DATA_REQIUREMENT_SUCCESS',
    'GET_DATA_REQIUREMENT_ERROR',
    'CLEAR_DATA_REQUIREMENT',

    'BULK_CREATE_SHARING_PRESETS_REQUEST',
    'BULK_CREATE_SHARING_PRESETS_SUCCESS',
    'BULK_CREATE_SHARING_PRESETS_ERROR',

    'GET_DATA_REQUIREMENTS_SUMMARY_REQUEST',
    'GET_DATA_REQUIREMENTS_SUMMARY_SUCCESS',
    'GET_DATA_REQUIREMENTS_SUMMARY_ERROR',
    'CLEAR_DATA_REQUIREMENTS_SUMMARY',

    'GET_CHANGES_NOTIFICATIONS_REQUEST',
    'GET_CHANGES_NOTIFICATIONS_SUCCESS',
    'GET_CHANGES_NOTIFICATIONS_ERROR',
    'CLEAR_CHANGES_NOTIFICATIONS',

    'APPLY_CHANGES_NOTIFICATIONS_REQUEST',
    'APPLY_CHANGES_NOTIFICATIONS_SUCCESS',
    'APPLY_CHANGES_NOTIFICATIONS_ERROR',

    'GET_OVERVIEW_REQUEST',
    'GET_OVERVIEW_SUCCESS',
    'GET_OVERVIEW_ERROR',
    'CLEAR_OVERVIEW',
]);

/**
 * Create 'Get Partners Request' Action
 * @param payload Contains Partners type
 */
export class GetPartnersRequest implements Action {
    type = ActionTypes.GET_PARTNERS_REQUEST;

    constructor(public payload?: GetPartnersPayload) {}
}

/**
 * Create 'Get Partners Success' Action
 * @param payload Contains object with Partners data
 */
export class GetPartnersSuccess implements Action {
    type = ActionTypes.GET_PARTNERS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partners Error' Action
 * @param payload Contains object with errors
 */
export class GetPartnersError implements Action {
    type = ActionTypes.GET_PARTNERS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Partners' Action
 */
export class ClearPartners implements Action {
    type = ActionTypes.CLEAR_PARTNERS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Data Request' Action
 * @param payload Contains partner id
 */
export class GetPartnerDataRequest implements Action {
    type = ActionTypes.GET_PARTNER_DATA_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Data Success' Action
 * @param payload Contains object with partner data
 */
export class GetPartnerDataSuccess implements Action {
    type = ActionTypes.GET_PARTNER_DATA_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Data Error' Action
 * @param payload Contains object with errors
 */
export class GetPartnerDataError implements Action {
    type = ActionTypes.GET_PARTNER_DATA_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Partner Data' Action
 */
export class ClearPartnerData implements Action {
    type = ActionTypes.CLEAR_PARTNER_DATA;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Data Requirements Request' Action
 * @param payload Contains partner id and params
 */
export class GetDataRequirementsRequest implements Action {
    type = ActionTypes.GET_DATA_REQUIREMENTS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Data Requirements Success' Action
 * @param payload Contains object with partner data
 */
export class GetDataRequirementsSuccess implements Action {
    type = ActionTypes.GET_DATA_REQUIREMENTS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Data Requirements Error' Action
 * @param payload Contains object with errors
 */
export class GetDataRequirementsError implements Action {
    type = ActionTypes.GET_DATA_REQUIREMENTS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Partner Data Requirements' Action
 */
export class ClearDataRequirements implements Action {
    type = ActionTypes.CLEAR_DATA_REQUIREMENTS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Data Requirement Request' Action
 * @param payload Contains partner id
 */
export class GetDataRequirementRequest implements Action {
    type = ActionTypes.GET_DATA_REQIUREMENT_REQUEST;

    constructor(public payload?: PartnerDataRequirementPayload) {}
}

/**
 * Create 'Get Partner Data Requirement Success' Action
 * @param payload Contains object with partner data
 */
export class GetDataRequirementSuccess implements Action {
    type = ActionTypes.GET_DATA_REQIUREMENT_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Partner Data Requirements Error' Action
 * @param payload Contains object with errors
 */
export class GetDataRequirementError implements Action {
    type = ActionTypes.GET_DATA_REQIUREMENT_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Partner Data Requirements' Action
 */
export class ClearDataRequirement implements Action {
    type = ActionTypes.CLEAR_DATA_REQUIREMENT;

    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Create Partner Sharing Presets Request' Action
 * @param payload Contains requirements ids
 */
export class BulkCreateSharingPresetsRequest implements Action {
    type = ActionTypes.BULK_CREATE_SHARING_PRESETS_REQUEST;

    constructor(public payload?: CommonPartnerPayload) {}
}

/**
 * Create 'Bulk Create Partner Sharing Presets Success' Action
 * @param payload Contains object with partner data
 */
export class BulkCreateSharingPresetsSuccess implements Action {
    type = ActionTypes.BULK_CREATE_SHARING_PRESETS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Bulk Create Partner Sharing Presets Error' Action
 * @param payload Contains object with errors
 */
export class BulkCreateSharingPresetsError implements Action {
    type = ActionTypes.BULK_CREATE_SHARING_PRESETS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Data Requirements Summary Request' Action
 * @param payload Contains requirements
 */
export class GetDataRequirementsSummaryRequest implements Action {
    type = ActionTypes.GET_DATA_REQUIREMENTS_SUMMARY_REQUEST;

    constructor(public payload?: PartnerDataRequirementsPayload) {}
}

/**
 * Create 'Get Data Requirements Summary Success' Action
 * @param payload Contains object with Summary data
 */
export class GetDataRequirementsSummarySuccess implements Action {
    type = ActionTypes.GET_DATA_REQUIREMENTS_SUMMARY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Data Requirements Summary Error' Action
 * @param payload Contains object with errors
 */
export class GetDataRequirementsSummaryError implements Action {
    type = ActionTypes.GET_DATA_REQUIREMENTS_SUMMARY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Data Requirements Summary' Action
 */
export class ClearDataRequirementsSummary implements Action {
    type = ActionTypes.CLEAR_DATA_REQUIREMENTS_SUMMARY;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Changes Notifications Request' Action
 * @param payload Contains fields (company, partner) needed to send reqeust for partner requirements
 */
export class GetChangesNotificationsRequestAction implements Action {
    type = ActionTypes.GET_CHANGES_NOTIFICATIONS_REQUEST;
    constructor(public payload: PartnerDataRequirementChangesPayload) {}
}

/**
 * Create 'Get Changes Notifications Success' Action
 * @param payload Contains SharingPresetsChangesNotifications object for specific partner
 */
export class GetChangesNotificationsSuccessAction implements Action {
    type = ActionTypes.GET_CHANGES_NOTIFICATIONS_SUCCESS;
    constructor(public payload: PartnerDataRequirementChanges) {}
}

/**
 * Create 'Get Changes Notifications Error' Action
 * @param payload Contains errors object
 */
export class GetChangesNotoficationsErrorAction implements Action {
    type = ActionTypes.GET_CHANGES_NOTIFICATIONS_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Clear Partner Requirements' Action
 * @param payload Unnecessary
 */
export class ClearChangesNotificationsAction implements Action {
    type = ActionTypes.CLEAR_CHANGES_NOTIFICATIONS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Apply Changes Notifications Request' Action
 * @param payload Contains fields (company, partner) needed to send reqeust for partner requirements
 */
export class ApplyChangesNotificationsRequestAction implements Action {
    type = ActionTypes.APPLY_CHANGES_NOTIFICATIONS_REQUEST;
    constructor(public payload: any) {}
}

/**
 * Create 'Apply Changes Notifications Success' Action
 * @param payload Unnecessary
 */
export class ApplyChangesNotificationsSuccessAction implements Action {
    type = ActionTypes.APPLY_CHANGES_NOTIFICATIONS_SUCCESS;
    constructor(public payload?: any) {}
}

/**
 * Create 'Apply Changes Notifications Error' Action
 * @param payload Contains errors object
 */
export class ApplyChangesNotoficationsErrorAction implements Action {
    type = ActionTypes.APPLY_CHANGES_NOTIFICATIONS_ERROR;
    constructor(public payload: any) {}
}

/**
 * Create 'Get Overview Request' Action
 * @param payload Contains requirements
 */
export class GetOverviewRequestAction implements Action {
    type = ActionTypes.GET_OVERVIEW_REQUEST;

    constructor(public payload?: PartnerDataRequirementsPayload) {}
}

/**
 * Create 'Get Overview Success' Action
 * @param payload Contains object with Overview data
 */
export class GetOverviewSuccessAction implements Action {
    type = ActionTypes.GET_OVERVIEW_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Overview Error' Action
 * @param payload Contains object with errors
 */
export class GetOverviewErrorAction implements Action {
    type = ActionTypes.GET_OVERVIEW_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Overview' Action
 */
export class ClearOverviewAction implements Action {
    type = ActionTypes.CLEAR_OVERVIEW;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetPartnersRequest
    | GetPartnersSuccess
    | GetPartnersError
    | ClearPartners
    | GetPartnerDataRequest
    | GetPartnerDataSuccess
    | GetPartnerDataError
    | ClearPartnerData
    | GetDataRequirementsRequest
    | GetDataRequirementsSuccess
    | GetDataRequirementsError
    | ClearDataRequirements
    | GetDataRequirementRequest
    | GetDataRequirementSuccess
    | GetDataRequirementError
    | ClearDataRequirement
    | BulkCreateSharingPresetsRequest
    | BulkCreateSharingPresetsSuccess
    | BulkCreateSharingPresetsError
    | GetDataRequirementsSummaryRequest
    | GetDataRequirementsSummarySuccess
    | GetDataRequirementsSummaryError
    | ClearDataRequirementsSummary
    | GetChangesNotificationsRequestAction
    | GetChangesNotificationsSuccessAction
    | GetChangesNotoficationsErrorAction
    | ClearChangesNotificationsAction
    | ApplyChangesNotificationsRequestAction
    | ApplyChangesNotificationsSuccessAction
    | ApplyChangesNotoficationsErrorAction
    | GetOverviewRequestAction
    | GetOverviewSuccessAction
    | GetOverviewErrorAction
    | ClearOverviewAction;
