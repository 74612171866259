import { Pipe, PipeTransform } from '@angular/core';

import { naturalCompare } from '@app/utils';

@Pipe({
    name: 'sort',
})
export class SortPipe implements PipeTransform {
    transform(list: any[], orderBy: string = ''): any[] {
        return orderBy.charAt(0) !== '-'
            ? list.sort((a, b) => naturalCompare(a[orderBy], b[orderBy]))
            : list.sort((a, b) => naturalCompare(b[orderBy.substr(1)], a[orderBy.substr(1)]));
    }
}
