import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { from, Observable, of } from 'rxjs';

import * as companySharingPresetsActions from '@core/actions/company-sharing-presets.actions';
import * as partnersActions from '@core/actions/partners.actions';

import { CompanySharingPresetsService } from '@core/services/company-sharing-presets.service';
import { ModalService } from '@core/services/modal.service';
import { NotificationsService } from '@core/services/notifications.service';

import { catchErrorJson } from './catch-error';

import { CommonPartnerPayload } from '@core/models';
import { HTTPClientVer } from '@core/utils/request.utils';

const REDIRECT_SECTION = 'presets';

@Injectable()
export class CompanySharingPresetsEffects {
    constructor(
        private actions$: Actions,
        private activatedRoute: ActivatedRoute,
        private companySharingPresetsService: CompanySharingPresetsService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    @Effect()
    getAvailableAttributesRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.GET_PRESETS_AVAILABLE_ATTRIBUTES_REQUEST),
        map((action: companySharingPresetsActions.GetPresetsAvailableAttributesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companySharingPresetsService.getCompanyAvailableAttributes(payload).pipe(
                map(
                    (response) => new companySharingPresetsActions.GetPresetsAvailableAttributesSuccessAction(response),
                ),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.GetPresetsAvailableAttributesErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    createCompanySharingPresetRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.CREATE_SHARING_PRESETS_REQUEST),
        map((action: companySharingPresetsActions.CreateCompanySharingPresetsRequestAction) => action.payload),
        switchMap((payload: CommonPartnerPayload) => {
            const params = this.activatedRoute.snapshot.queryParams;
            return this.companySharingPresetsService.createCompanySharingPreset(payload.sharingPreset.data).pipe(
                tap(() => {
                    this.notificationsService.success('Sharing Preset has been created');
                    this.router.navigate([payload.redirectTo], {
                        queryParams: {
                            section: params.category ? 'requirements' : REDIRECT_SECTION,
                        },
                    });
                }),
                map((response) => new companySharingPresetsActions.CreateCompanySharingPresetsSuccessAction(response)),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.CreateCompanySharingPresetsErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    editCompanySharingPresetRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.UPDATE_SHARING_PRESET_REQUEST),
        map((action: companySharingPresetsActions.UpdateCompanySharingPresetRequestAction) => action.payload),
        switchMap((payload: CommonPartnerPayload) => {
            return this.companySharingPresetsService
                .updateCompanySharingPreset(payload.sharingPreset.id, payload.sharingPreset.data)
                .pipe(
                    tap(() => {
                        this.notificationsService.success('Sharing Preset has been edited');

                        if (payload.redirectTo) {
                            this.router.navigate([payload.redirectTo], {
                                queryParams: {
                                    section: REDIRECT_SECTION,
                                },
                            });
                        }
                    }),
                    mergeMap((response: any) => {
                        const { company, partner } = response;
                        const actions = [];

                        if (payload.sharingPreset.id) {
                            actions.push(
                                new companySharingPresetsActions.GetCompanySharingPresetsRequestAction({
                                    ...payload.sharingPreset.params,
                                    company,
                                    partner,
                                }),
                            );
                            actions.push(
                                new companySharingPresetsActions.GetHistoryLogsRequestAction({
                                    partner,
                                    offset: 0,
                                }),
                            );
                            actions.push(
                                new partnersActions.GetOverviewRequestAction({
                                    company,
                                    partner,
                                }),
                            );

                            if (payload.dataRequirements.hasDataReqAccess) {
                                actions.push(new partnersActions.GetDataRequirementsRequest(payload.dataRequirements));
                                actions.push(
                                    new partnersActions.GetDataRequirementsSummaryRequest({
                                        company,
                                        partner,
                                    }),
                                );
                            }
                        }

                        actions.push(
                            new companySharingPresetsActions.UpdateCompanySharingPresetSuccessAction(response),
                        );

                        return from(actions);
                    }),
                    catchError((error) =>
                        of(
                            new companySharingPresetsActions.UpdateCompanySharingPresetErrorAction(
                                catchErrorJson(error, HTTPClientVer),
                            ),
                        ),
                    ),
                );
        }),
    );

    @Effect()
    getCompanySharingPresetsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.GET_SHARING_PRESETS_REQUEST),
        map((action: companySharingPresetsActions.GetCompanySharingPresetsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companySharingPresetsService.getCompanySharingPresets(payload).pipe(
                map((response) => new companySharingPresetsActions.GetCompanySharingPresetsSuccessAction(response)),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.GetCompanySharingPresetsErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    getCompanySharingPresetRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.GET_SHARING_PRESET_REQUEST),
        map((action: companySharingPresetsActions.GetCompanySharingPresetRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companySharingPresetsService.getCompanySharingPreset(payload).pipe(
                mergeMap((response) => {
                    this.modalService.open('preset-preview-modal');

                    const { company, category } = response as any;
                    return from([
                        new companySharingPresetsActions.GetCompanySharingPresetSuccessAction(response),
                        new companySharingPresetsActions.GetPresetsAvailableAttributesRequestAction({
                            company,
                            category,
                        }),
                    ]);
                }),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.GetCompanySharingPresetErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    deleteCompanySharingPresetRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.DELETE_SHARING_PRESET_REQUEST),
        map((action: companySharingPresetsActions.DeleteCompanySharingPresetRequestAction) => action.payload),
        switchMap((payload: CommonPartnerPayload) => {
            return this.companySharingPresetsService.deleteCompanySharingPreset(payload.sharingPreset.id).pipe(
                tap(() => {
                    this.notificationsService.success('Sharing Preset has been deleted');
                }),
                mergeMap((response: any) => {
                    const { partner, company, params } = payload.sharingPreset;

                    this.modalService.close('delete-preset-modal');

                    let actions = [
                        new companySharingPresetsActions.ClearCompanySharingPresetAction(),
                        new companySharingPresetsActions.DeleteCompanySharingPresetSuccessAction(response),
                        new companySharingPresetsActions.GetCompanySharingPresetsRequestAction({
                            ...params,
                            partner,
                            company,
                        }),
                        new companySharingPresetsActions.GetSharingPresetsSummaryRequest({
                            company,
                            partner,
                        }),
                        new companySharingPresetsActions.GetHistoryLogsRequestAction({
                            partner,
                            offset: 0,
                        }),
                        new partnersActions.GetOverviewRequestAction({
                            company,
                            partner,
                        }),
                    ];

                    if (payload.dataRequirements.hasDataReqAccess) {
                        actions = [
                            ...actions,
                            new partnersActions.GetDataRequirementsRequest(payload.dataRequirements),
                            new partnersActions.GetDataRequirementsSummaryRequest({
                                company,
                                partner,
                            }),
                        ];
                    }

                    return from(actions);
                }),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.DeleteCompanySharingPresetErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    bulkUpdateCompanySharingPresetRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.BULK_UPDATE_SHARING_PRESET_STATUS_REQUEST),
        map((action: companySharingPresetsActions.BulkUpdateCompanySharingPresetStatusRequestAction) => action.payload),
        switchMap((payload: CommonPartnerPayload) => {
            return this.companySharingPresetsService.bulkUpdateCompanySharingPresets(payload.sharingPreset.data).pipe(
                tap(() => {
                    this.notificationsService.success('Sharing Presets has been updated');
                }),
                mergeMap((response: any) => {
                    const { company, partner } = payload.sharingPreset.params;

                    let actions = [
                        new companySharingPresetsActions.BulkUpdateCompanySharingPresetStatusSuccessAction(response),
                        new companySharingPresetsActions.GetCompanySharingPresetsRequestAction(
                            payload.sharingPreset.params,
                        ),
                        new companySharingPresetsActions.GetSharingPresetsSummaryRequest({
                            company,
                            partner,
                        }),
                        new companySharingPresetsActions.GetHistoryLogsRequestAction({
                            partner,
                            offset: 0,
                        }),
                        new partnersActions.GetOverviewRequestAction({
                            company,
                            partner,
                        }),
                    ];

                    if (payload.dataRequirements.hasDataReqAccess) {
                        actions = [
                            ...actions,
                            new partnersActions.GetDataRequirementsRequest(payload.dataRequirements),
                            new partnersActions.GetDataRequirementsSummaryRequest({
                                company,
                                partner,
                            }),
                        ];
                    }

                    return from(actions);
                }),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.BulkUpdateCompanySharingPresetStatusErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    bulkDeleteCompanySharingPresetsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.BULK_DELETE_SHARING_PRESETS_REQUEST),
        map((action: companySharingPresetsActions.BulkDeleteCompanySharingPresetRequestAction) => action.payload),
        switchMap((payload: CommonPartnerPayload) => {
            return this.companySharingPresetsService.bulkDeleteCompanySharingPresets(payload.sharingPreset.data).pipe(
                tap(() => {
                    this.notificationsService.success('Sharing Presets has been deleted');
                }),
                mergeMap((response: any) => {
                    const { company, partner } = payload.sharingPreset.params;

                    let actions = [
                        new companySharingPresetsActions.ClearCompanySharingPresetAction(),
                        new companySharingPresetsActions.BulkDeleteCompanySharingPresetSuccessAction(response),
                        new companySharingPresetsActions.GetSharingPresetsSummaryRequest({
                            company,
                            partner,
                        }),
                        new companySharingPresetsActions.GetCompanySharingPresetsRequestAction({
                            ...payload.sharingPreset.params,
                            offset: 0,
                        }),
                        new companySharingPresetsActions.GetHistoryLogsRequestAction({
                            partner,
                            offset: 0,
                        }),
                        new partnersActions.GetOverviewRequestAction({
                            company,
                            partner,
                        }),
                    ];

                    if (payload.dataRequirements.hasDataReqAccess) {
                        actions = [
                            ...actions,
                            new partnersActions.GetDataRequirementsRequest(payload.dataRequirements),
                            new partnersActions.GetDataRequirementsSummaryRequest({
                                company,
                                partner,
                            }),
                        ];
                    }

                    return from(actions);
                }),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.BulkDeleteCompanySharingPresetErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    getCompanySharingPresetsSummaryRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.GET_SHARING_PRESETS_SUMMARY_REQUEST),
        map((action: companySharingPresetsActions.GetSharingPresetsSummaryRequest) => action.payload),
        switchMap((payload) => {
            return this.companySharingPresetsService.getCompanySharingPresetsSummary(payload).pipe(
                map((response: any) => new companySharingPresetsActions.GetSharingPresetsSummarySuccess(response)),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.GetSharingPresetsSummaryError(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    getChangesNotificationsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.GET_CHANGES_NOTIFICATIONS_REQUEST),
        map((action: companySharingPresetsActions.GetChangesNotificationsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companySharingPresetsService.getChangesNotificationsRequirements(payload).pipe(
                map((response) => new companySharingPresetsActions.GetChangesNotificationsSuccessAction(response)),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.GetChangesNotoficationsErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    applyChangesNotificationsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.APPLY_CHANGES_NOTIFICATIONS_REQUEST),
        map((action: companySharingPresetsActions.ApplyChangesNotificationsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companySharingPresetsService.applyChangesNotificationsRequirements(payload).pipe(
                map((response) => new companySharingPresetsActions.ApplyChangesNotificationsSuccessAction(response)),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.ApplyChangesNotoficationsErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    getHistoryLogsRequestt$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.GET_HISTORY_LOGS_REQUEST),
        map((action: companySharingPresetsActions.GetHistoryLogsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companySharingPresetsService.getHistoryLogs(payload).pipe(
                map((response) => new companySharingPresetsActions.GetHistoryLogsSuccessAction(response)),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.GetHistoryLogsErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );

    @Effect()
    getPartnerRequirementsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(companySharingPresetsActions.ActionTypes.GET_PARTNER_REQUIREMENTS_REQUEST),
        map((action: companySharingPresetsActions.GetPartnerRequirementsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companySharingPresetsService.getCompanyPartnerRequirements(payload).pipe(
                map((response) => new companySharingPresetsActions.GetPartnerRequirementsSuccessAction(response)),
                catchError((error) =>
                    of(
                        new companySharingPresetsActions.GetPartnerRequirementsErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    );
}
