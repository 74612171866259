<div class="data-summary">
  <div class="data-summary__container">
    <h3 class="data-summary__heading header header--size-3">{{ data.title }}</h3>
    <div class="data-summary__table">
      <div
        *ngFor="let item of data.items"
        [ngClass]="{
          'data-summary__row--titled': item.isHeading,
          'data-summary__row--bold': item.isBold
        }"
        class="data-summary__row"
      >
        <div
          *ngFor="let cell of item.cells"
          class="data-summary__cell"
        >
          <ng-container *ngIf="cell.isCountUp else value">
            <p [countUp]="convertToInt(cell.value)" *ngIf="data.isAnimatedReady else zero"></p>
          </ng-container>
          <ng-template #zero>0</ng-template>
          <ng-template #value>{{ cell.value }}</ng-template>
          <p class="data-summary__info" *ngIf="cell.info">{{ cell.info }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
