<input
  type="text"
  class="input input--fluid"
  [ngClass]="{
    'input--readonly': readonly,
    'input--error': invalid && control.value && control.touched && !readonly,
    'input--valid': !invalid && control.value && control.touched && !readonly
  }"
  [formControl]="control"
  [readonly]="readonly">
