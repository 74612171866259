import * as actions from '@core/actions/company-sharing-presets.actions';
import {
    CustomDataList,
    DefaultList,
    DefaultManage,
    SharingPresetChangesNotifications,
    SharingPresetHistoryLog,
    SharingPresetRequirements,
    SharingPresetsSummary,
} from '@core/models';

export interface State {
    // TODO: define AvailableAttribute model instead of any
    availableAttributes: CustomDataList<any>;
    changesNotifications: DefaultManage<SharingPresetChangesNotifications>;
    historyLogs: DefaultList<SharingPresetHistoryLog>;
    // TODO: define list model instead of any
    list: DefaultList<any>;
    // TODO: define manage model instead of any
    manage: DefaultManage<any>;
    requirements: DefaultManage<SharingPresetRequirements>;
    summary: CustomDataList<SharingPresetsSummary>;
}

const summaryInitialState: State['summary'] = {
    data: {
        coveredCategoriesCount: 0,
        partnerPresetsCount: 0,
        sharedCategoriesCount: 0,
        sharedProductsCount: 0,
        uncoveredCategoriesCount: 0,
    },
    errors: {},
    isFetching: true,
};

const availableAttributesInitialState: State['availableAttributes'] = {
    data: {},
    errors: {},
    isFetching: true,
};

const listInitialState: State['list'] = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
    },
    errors: {},
    isFetching: true,
};

const manageInitialState: State['manage'] = {
    data: {},
    errors: {},
    isFetching: true,
    isSaving: false,
    isSaved: false,
};

const requirementsInitialState: State['requirements'] = {
    data: {
        dataRequirement: {
            entries: [],
        },
    },
    errors: {},
    isFetching: true,
};

const changesNotificationsInitialState: State['changesNotifications'] = {
    data: {
        notifications: {
            categoryTrees: null,
            newAttributes: null,
        },
    },
    errors: {},
    isFetching: true,
};

const historyLogsInitialState: State['historyLogs'] = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
    },
    errors: {},
    isFetching: true,
};

export const initialState: State = {
    availableAttributes: availableAttributesInitialState,
    changesNotifications: changesNotificationsInitialState,
    historyLogs: historyLogsInitialState,
    list: listInitialState,
    manage: manageInitialState,
    requirements: requirementsInitialState,
    summary: summaryInitialState,
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.ActionTypes.GET_PRESETS_AVAILABLE_ATTRIBUTES_REQUEST: {
            return {
                ...state,
                availableAttributes: {
                    ...state.availableAttributes,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_PRESETS_AVAILABLE_ATTRIBUTES_SUCCESS: {
            return {
                ...state,
                availableAttributes: {
                    ...state.availableAttributes,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_PRESETS_AVAILABLE_ATTRIBUTES_ERROR: {
            return {
                ...state,
                availableAttributes: {
                    ...state.availableAttributes,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.CLEAR_PRESETS_AVAILABLE_ATTRIBUTES: {
            return {
                ...state,
                availableAttributes: {
                    ...initialState.availableAttributes,
                },
            };
        }

        case actions.ActionTypes.CREATE_SHARING_PRESETS_REQUEST:
        case actions.ActionTypes.UPDATE_SHARING_PRESET_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case actions.ActionTypes.CREATE_SHARING_PRESETS_SUCCESS:
        case actions.ActionTypes.UPDATE_SHARING_PRESET_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.CREATE_SHARING_PRESETS_ERROR:
        case actions.ActionTypes.UPDATE_SHARING_PRESET_ERROR:
        case actions.ActionTypes.DELETE_SHARING_PRESET_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.DELETE_SHARING_PRESET_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                },
            };
        }

        case actions.ActionTypes.DELETE_SHARING_PRESET_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: {},
                    isSaving: false,
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESETS_REQUEST:
        case actions.ActionTypes.BULK_UPDATE_SHARING_PRESET_STATUS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESETS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESETS_ERROR:
        case actions.ActionTypes.BULK_UPDATE_SHARING_PRESET_STATUS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.CLEAR_SHARING_PRESETS: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESET_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESET_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESET_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.CLEAR_SHARING_PRESET: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESETS_SUMMARY_REQUEST: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESETS_SUMMARY_SUCCESS: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_SHARING_PRESETS_SUMMARY_ERROR: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.CLEAR_SHARING_PRESETS_SUMMARY: {
            return {
                ...state,
                summary: {
                    ...initialState.summary,
                },
            };
        }

        case actions.ActionTypes.GET_PARTNER_REQUIREMENTS_REQUEST: {
            return {
                ...state,
                requirements: {
                    ...state.requirements,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_PARTNER_REQUIREMENTS_SUCCESS: {
            return {
                ...state,
                requirements: {
                    ...state.requirements,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_PARTNER_REQUIREMENTS_ERROR: {
            return {
                ...state,
                requirements: {
                    ...state.requirements,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.CLEAR_PARTNER_REQUIREMENTS: {
            return {
                ...state,
                requirements: {
                    ...initialState.requirements,
                },
            };
        }

        case actions.ActionTypes.GET_CHANGES_NOTIFICATIONS_REQUEST: {
            return {
                ...state,
                changesNotifications: {
                    ...state.changesNotifications,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_HISTORY_LOGS_REQUEST: {
            return {
                ...state,
                historyLogs: {
                    ...state.historyLogs,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_CHANGES_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                changesNotifications: {
                    ...state.changesNotifications,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_HISTORY_LOGS_SUCCESS: {
            return {
                ...state,
                historyLogs: {
                    ...state.historyLogs,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_CHANGES_NOTIFICATIONS_ERROR: {
            return {
                ...state,
                changesNotifications: {
                    ...state.changesNotifications,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_HISTORY_LOGS_ERROR: {
            return {
                ...state,
                historyLogs: {
                    ...state.historyLogs,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.CLEAR_CHANGES_NOTIFICATIONS: {
            return {
                ...state,
                changesNotifications: {
                    ...initialState.changesNotifications,
                },
            };
        }

        case actions.ActionTypes.CLEAR_HISTORY_LOGS: {
            return {
                ...state,
                historyLogs: {
                    ...initialState.historyLogs,
                },
            };
        }

        default:
            return state;
    }
}

export const getAvailableAttributes = (state: State) => state.availableAttributes;
export const getList = (state: State) => state.list;
export const getManage = (state: State) => state.manage;
export const getSummary = (state: State) => state.summary;
export const getRequirements = (state: State) => state.requirements;
export const getChangesNotifications = (state: State) => state.changesNotifications;
export const getHistoryLogs = (state: State) => state.historyLogs;
