import { Action } from '@ngrx/store';

import { type } from '@app/utils';

const ACTION_PREFIX = '[News]';

export const ActionTypes = {
    GET_NEWS_REQUEST: type(`${ACTION_PREFIX} Get News Request`),
    GET_NEWS_SUCCESS: type(`${ACTION_PREFIX} Get News Success`),
    GET_NEWS_ERROR: type(`${ACTION_PREFIX} Get News Error`),
    SAVE_NEWS_REQUEST: type(`${ACTION_PREFIX} Save News Request`),
    SAVE_NEWS_SUCCESS: type(`${ACTION_PREFIX} Save News Success`),
    SAVE_NEWS_ERROR: type(`${ACTION_PREFIX} Save News Error`),
    UPLOAD_NEWS_IMAGES_REQUEST: type(`${ACTION_PREFIX} Upload News Image Request`),
    UPLOAD_NEWS_IMAGES_SUCCESS: type(`${ACTION_PREFIX} Upload News Image Success`),
    UPLOAD_NEWS_IMAGES_ERROR: type(`${ACTION_PREFIX} Upload News Image Error`),
    UPDATE_NEWS: type(`${ACTION_PREFIX} Update News`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
};

/**
 * Create 'Get News Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetNewsRequestAction implements Action {
    type = ActionTypes.GET_NEWS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get News Succes' Action
 * @param payload Contains object with news list and params
 */
export class GetNewsSuccessAction implements Action {
    type = ActionTypes.GET_NEWS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get News Error' Action
 * @param payload Contains object with errors
 */
export class GetNewsErrorAction implements Action {
    type = ActionTypes.GET_NEWS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save News Request' action
 * @param payload Contains object with news data
 */
export class SaveNewsRequestAction implements Action {
    type = ActionTypes.SAVE_NEWS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save News Success' action
 * @param payload Contains object with news data
 */
export class SaveNewsSuccessAction implements Action {
    type = ActionTypes.SAVE_NEWS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save News Error' action
 * @param payload Contains object with errors
 */
export class SaveNewsErrorAction implements Action {
    type = ActionTypes.SAVE_NEWS_ERROR;

    constructor(public payload?: any) {}
}
/**
 * Create 'Upload News Images Request' Action
 * @param payload Contains object with images data
 */
export class UploadNewsImagesRequestAction implements Action {
    type = ActionTypes.UPLOAD_NEWS_IMAGES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Upload News Images Succes' Action
 * @param payload Contains object with news data
 */
export class UploadNewsImagesSuccessAction implements Action {
    type = ActionTypes.UPLOAD_NEWS_IMAGES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Upload News Images Error' Action
 * @param payload Contains object with errors
 */
export class UploadNewsImagesErrorAction implements Action {
    type = ActionTypes.UPLOAD_NEWS_IMAGES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Update News' Action
 * @param payload Contains object with news update data
 */
export class UpdateNewsAction implements Action {
    type = ActionTypes.UPDATE_NEWS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetNewsRequestAction
    | GetNewsSuccessAction
    | GetNewsErrorAction
    | SaveNewsRequestAction
    | SaveNewsSuccessAction
    | SaveNewsErrorAction
    | UploadNewsImagesRequestAction
    | UploadNewsImagesSuccessAction
    | UploadNewsImagesErrorAction
    | UpdateNewsAction
    | ClearListAction;
