import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromMetrics from '../reducers/metrics.reducer';

@Injectable()
export class MetricsSelectors {
    /**
     * Get state.metrics
     */
    getMetricsState() {
        return ($state: State): any => $state.metrics;
    }

    /**
     * Get list from state.metrics
     */
    getList() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getList,
        );
    }

    /**
     * Get list.data from state.metrics
     */
    getListData() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getListData,
        );
    }

    /**
     * Get list.errors from state.metrics
     */
    getListErrors() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.metrics
     */
    getListIsFetching() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getListIsFetching,
        );
    }

    /**
     * Get manage from state.metrics
     */
    getManage() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getManage,
        );
    }

    /**
     * Get manage.data from state.metrics
     */
    getManageData() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getManageData,
        );
    }

    /**
     * Get manage.errors from state.metrics
     */
    getManageErrors() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getManageErrors,
        );
    }

    /**
     * Get manage.isFetching from state.metrics
     */
    getManageIsFetching() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getManageIsFetching,
        );
    }
    /**
     * Get manage.isSaving from state.metrics
     */
    getManageIsSaving() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getManageIsSaving,
        );
    }

    /**
     * Get multple manage from state.metrics
     */
    getMultipleManage() {
        return createSelector(
            this.getMetricsState(),
            fromMetrics.getManageMultiple,
        );
    }
}
