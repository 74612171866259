import * as actions from '@core/actions/listed-products.actions';
import { productInitialState } from '@core/reducers/products.reducer';

import {
    CustomDataList,
    DefaultManage,
    InitializeLists,
    ListedProduct,
    ListedProductsCountersManage,
    ListedProductsList,
    ListedProductsListType,
    ListedProductsOptions,
    ListedProductsUI,
    ListedProductSummary,
    ListedSearchParamsState,
    ProductHistoryList,
    SearchParamsActionSource,
} from '@core/models';

import {
    arrayDataInitialState,
    listInitialState,
    loadableState,
    objectDataInitialState,
    optionsInitialState,
    pipeHigherOrderReducers,
    resetState,
} from '@core/reducers/utils';

import { removeFieldsFromObject } from '@app/utils';

export interface State {
    changesHistory: ProductHistoryList;
    counters: ListedProductsCountersManage;
    initializeLists: InitializeLists;
    internalDataPreview: CustomDataList<any>;
    list: ListedProductsList;
    listApproved: ListedProductsList;
    listMy: ListedProductsList;
    listPending: ListedProductsList;
    listRejected: ListedProductsList;
    manage: DefaultManage<ListedProduct>;
    options: ListedProductsOptions;
    pageUI: ListedProductsUI;
    productOptions: ListedProductsOptions;
    productReviews: DefaultManage<any[]>;
    searchId: string;
    searchParams: ListedSearchParamsState;
    sharesHistory: CustomDataList<any[]>;
    summary: DefaultManage<ListedProductSummary>;
}

const manageInitialState: DefaultManage<ListedProduct> = {
    data: {
        ...productInitialState,
        created: null,
        latestWorkflow: null,
        nextProductId: null,
        previousProductId: null,
        publisher: null,
        publisherProductId: null,
        recipient: null,
    },
    errors: {},
    isFetching: true,
    isSaved: false,
    isSaving: false,
};

const countersInitialState: ListedProductsCountersManage = {
    data: {
        all: 0,
        pending: 0,
        rejected: 0,
        approved: 0,
        my: 0,
    },
    errors: {},
    isFetching: true,
};

const productsListInitialState: ListedProductsList = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
        searchId: null,
        searchParams: null,
    },
    errors: {},
    isFetching: true,
};

const summaryInitialState: DefaultManage<ListedProductSummary> = {
    data: {
        latestWorkflow: null,
        recipient: '',
        modified: '',
        publisher: '',
        frontFaceImageThumbnail: '',
        gtin: null,
        tradeItemDescription: '',
    },
    errors: {},
    isFetching: false,
};

const initializeListstInitialState: InitializeLists = {
    allFetched: false,
    firstListFetched: false,
    firstRun: false,
    firstRunEnd: false,
    listTypes: [],
    listTypesFetched: [],
};

const searchParamsInitalState: ListedSearchParamsState = {
    actionSource: SearchParamsActionSource.INIT,
    params: {},
};

const pageUiInitialState: ListedProductsUI = {
    sharedFurther: false,
};

const initialState: State = {
    changesHistory: listInitialState,
    counters: countersInitialState,
    initializeLists: initializeListstInitialState,
    internalDataPreview: objectDataInitialState,
    list: productsListInitialState,
    listApproved: productsListInitialState,
    listMy: productsListInitialState,
    listPending: productsListInitialState,
    listRejected: productsListInitialState,
    manage: manageInitialState,
    options: optionsInitialState,
    pageUI: pageUiInitialState,
    productOptions: optionsInitialState,
    productReviews: arrayDataInitialState,
    searchId: null,
    searchParams: searchParamsInitalState,
    sharesHistory: arrayDataInitialState,
    summary: summaryInitialState,
};

function baseReducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.ActionTypes.INITIALIZE_LISTS_VIEW_START: {
            return {
                ...state,
                initializeLists: {
                    ...state.initializeLists,
                    firstRun: true,
                    firstRunEnd: false,
                },
            };
        }

        case actions.ActionTypes.INITIALIZE_LISTS_VIEW_UPDATE: {
            return {
                ...state,
                initializeLists: {
                    ...state.initializeLists,
                    listTypesFetched: [...state.initializeLists.listTypesFetched, action.payload],
                },
            };
        }

        case actions.ActionTypes.INITIALIZE_LISTS_VIEW_END: {
            return {
                ...state,
                initializeLists: {
                    ...state.initializeLists,
                    allFetched: true,
                    firstRunEnd: true,
                },
            };
        }

        case actions.ActionTypes.UPDATE_SHARED_FURTHER_STATE: {
            return {
                ...state,
                pageUI: {
                    ...state.pageUI,
                    sharedFurther: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_ALL_LISTED_PRODUCTS: {
            return {
                ...state,
                initializeLists: {
                    ...state.initializeLists,
                    allFetched: false,
                    firstListFetched: false,
                    listTypes: action.payload.map((payload) => payload.listType),
                    listTypesFetched: [],
                },
            };
        }

        case actions.ActionTypes.SET_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: {
                    ...action.payload,
                },
            };
        }
        case actions.ActionTypes.REMOVE_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    params: {
                        ...removeFieldsFromObject(action.payload, { ...state.searchParams.params }),
                    },
                },
            };
        }
        case actions.ActionTypes.CLEAR_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: {
                    ...initialState.searchParams,
                },
            };
        }

        case actions.ActionTypes.SET_SEARCH_ID: {
            return {
                ...state,
                searchId: action.payload,
            };
        }

        case actions.ActionTypes.CLEAR_SEARCH_ID: {
            return {
                ...state,
                searchId: null,
            };
        }

        case actions.ActionTypes.GET_LISTED_PRODUCTS_REQUEST: {
            return {
                ...state,
                [action.payload.listType]: {
                    ...state[action.payload.listType],
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case actions.ActionTypes.GET_LISTED_PRODUCTS_SUCCESS: {
            return {
                ...state,
                [action.payload.listType]: {
                    ...state[action.payload.listType],
                    data: action.payload.response,
                    isFetching: false,
                },
                initializeLists: {
                    ...state.initializeLists,
                    firstListFetched: true,
                },
            };
        }

        case actions.ActionTypes.GET_LISTED_PRODUCTS_ERROR: {
            return {
                ...state,
                [action.payload.listType]: {
                    ...state[action.payload.listType],
                    errors: action.payload.response,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                [action.payload]: {
                    ...initialState[action.payload],
                },
            };
        }

        default:
            return state;
    }
}

export function reducer(state: State = initialState, action: actions.Actions) {
    return pipeHigherOrderReducers(
        loadableState<State, actions.Actions>(initialState, 'manage', [
            actions.ActionTypes.GET_LISTED_PRODUCT_REQUEST,
            actions.ActionTypes.GET_LISTED_PRODUCT_SUCCESS,
            actions.ActionTypes.GET_LISTED_PRODUCT_ERROR,
        ]),
        loadableState<State, actions.Actions>(initialState, 'options', [
            actions.ActionTypes.GET_OPTIONS_REQUEST,
            actions.ActionTypes.GET_OPTIONS_SUCCESS,
        ]),
        loadableState<State, actions.Actions>(initialState, 'productOptions', [
            actions.ActionTypes.GET_PRODUCT_OPTIONS_REQUEST,
            actions.ActionTypes.GET_PRODUCT_OPTIONS_SUCCESS,
        ]),
        loadableState<State, actions.Actions>(initialState, 'changesHistory', [
            actions.ActionTypes.GET_LISTED_PRODUCT_CHANGES_HISTORY_REQUEST,
            actions.ActionTypes.GET_LISTED_PRODUCT_CHANGES_HISTORY_SUCCESS,
            actions.ActionTypes.GET_LISTED_PRODUCT_CHANGES_HISTORY_ERROR,
        ]),
        loadableState<State, actions.Actions>(initialState, 'sharesHistory', [
            actions.ActionTypes.GET_SHARES_HISTORY_REQUEST,
            actions.ActionTypes.GET_SHARES_HISTORY_SUCCESS,
            actions.ActionTypes.GET_SHARES_HISTORY_ERROR,
        ]),
        loadableState<State, actions.Actions>(initialState, 'productReviews', [
            actions.ActionTypes.GET_LISTED_PRODUCT_REVIEWS_REQUEST,
            actions.ActionTypes.GET_LISTED_PRODUCT_REVIEWS_SUCCESS,
            actions.ActionTypes.GET_LISTED_PRODUCT_REVIEWS_ERROR,
        ]),
        loadableState<State, actions.Actions>(initialState, 'summary', [
            actions.ActionTypes.GET_LISTED_PRODUCT_SUMMARY_REQUEST,
            actions.ActionTypes.GET_LISTED_PRODUCT_SUMMARY_SUCCESS,
            actions.ActionTypes.GET_LISTED_PRODUCT_SUMMARY_ERROR,
        ]),
        loadableState<State, actions.Actions>(initialState, 'internalDataPreview', [
            actions.ActionTypes.GET_PRODUCT_INTERNAL_DATA_REQUEST,
            actions.ActionTypes.GET_PRODUCT_INTERNAL_DATA_SUCCESS,
            actions.ActionTypes.GET_PRODUCT_INTERNAL_DATA_ERROR,
        ]),
        loadableState<State, actions.Actions>(initialState, 'counters', [
            actions.ActionTypes.GET_LISTED_PRODUCTS_COUNT_REQUEST,
            actions.ActionTypes.GET_LISTED_PRODUCTS_COUNT_SUCCESS,
            actions.ActionTypes.GET_LISTED_PRODUCTS_COUNT_ERROR,
        ]),
        resetState<State>(initialState, [
            ['manage', actions.ActionTypes.CLEAR_MANAGE],
            ['options', actions.ActionTypes.CLEAR_OPTIONS],
            ['productOptions', actions.ActionTypes.CLEAR_PRODUCT_OPTIONS],
            ['changesHistory', actions.ActionTypes.CLEAR_CHANGES_HISTORY],
            ['sharesHistory', actions.ActionTypes.CLEAR_SHARES_HISTORY],
            ['productReviews', actions.ActionTypes.CLEAR_LISTED_PRODUCT_REVIEWS],
            ['summary', actions.ActionTypes.CLEAR_LISTED_PRODUCT_SUMMARY],
            ['internalDataPreview', actions.ActionTypes.CLEAR_PRODUCT_INTERNAL_DATA],
            ['counters', actions.ActionTypes.CLEAR_COUNTERS],
        ]),
    )(baseReducer)(state, action);
}

export const getChangesHistory = (state: State) => state.changesHistory;
export const getCounters = (state: State) => state.counters;
export const getInitializeLists = (state: State) => state.initializeLists;
export const getInternalDataPreview = (state: State) => state.internalDataPreview;
export const getList = (listType: ListedProductsListType = 'list') => (state: State) => state[listType];
export const getListedProductReviews = (state: State) => state.productReviews;
export const getListedProductSummary = (state: State) => state.summary;
export const getManage = (state: State) => state.manage;
export const getOptions = (state: State) => state.options;
export const getPageUIPart = (part: keyof ListedProductsUI) => (state: State) => state.pageUI[part];
export const getProductOptions = (state: State) => state.productOptions;
export const getSearchId = (state: State) => state.searchId;
export const getSearchParams = (state: State) => state.searchParams;
export const getSharesHistory = (state: State) => state.sharesHistory;
