import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class WorkflowsManageGuard extends BaseGuard {
    path = ['workflows', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class CanDisplayMyListingsGuard extends BaseGuard {
    path = ['currentUser', 'displayMyListings'];
    constructor(injector: Injector) {
        super(injector);
    }
}
