import { Component, Input } from '@angular/core';

@Component({
    selector: 'display-country',
    templateUrl: './display-country.component.html',
    styleUrls: ['./display-country.component.scss'],
})
export class DisplayCountryComponent {
    @Input() countryCode: string;
    @Input() countryName: string;
}
