<h3
  *ngIf="sectionTitle"
  class="form__section-title section-title"
>
  {{ sectionTitle }}
</h3>

<div
  *ngIf="errors?.length"
  class="form__row"
>
  <div class="form__group form__group--cols-{{errorsCols}}">
    <ul class="errors errors--block errors--no-margin" >
      <li
        *ngFor="let error of errors"
        class="errors__single-error"
      >
        {{ error }}
      </li>
    </ul>
  </div>
</div>

<ng-content></ng-content>
