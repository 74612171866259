import { animate, animateChild, query, stagger, state, style, transition, trigger } from '@angular/animations';

/**
 * Return trigger function with animations for router in the wrapper.
 * @returns trigger function from `@angular/animations`
 */
export const routerTransition: any = trigger('routerTransition', [
    state('void', style({ opacity: 0, transform: 'translateY(80px)' })),
    state('0', style({ opacity: 0, transform: 'translateY(80px)' })),
    transition('* => 1', [animate('300ms ease-in-out'), style({ opacity: 1, transform: 'translateY(0)' })]),
    transition('1 => 0', [animate('300ms ease-in-out', style({ opacity: 0, transform: 'translateY(80px)' }))]),
]);

export const fadeIn: any = trigger('fadeIn', [
    state('void', style({ opacity: 0 })),
    transition(':enter', [animate('0.4s ease'), style({ opacity: 1 })]),
]);

export const fadeOut: any = trigger('fadeOut', [transition(':leave', [animate('0.4s ease'), style({ opacity: 0 })])]);

export const fadeInOut: any = trigger('fadeInOut', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition('* => *', [animate('0.4s ease')]),
]);

/**
 * Return trigger function with animations for the wrapper header.
 * @returns trigger function from `@angular/animations`
 */
export const headerTransition: any = trigger('headerTransition', [
    state('void', style({ transform: 'translateY(-100%)' })),
    state('0', style({ transform: 'translateY(-100%)' })),
    transition('* => 1', [animate('300ms ease-in-out'), style({ transform: 'translateY(0)' })]),
    transition('1 => 0', [animate('300ms ease-in-out', style({ transform: 'translateY(-100%)' }))]),
]);

/**
 * Return trigger function with animations for the wrapper footer.
 * @returns trigger function from `@angular/animations`
 */
export const footerTransition: any = trigger('footerTransition', [
    state('void', style({ transform: 'translateY(100%)' })),
    state('0', style({ transform: 'translateY(100%)' })),
    transition('* => 1', [animate('300ms ease-in-out'), style({ transform: 'translateY(0)' })]),
    transition('1 => 0', [animate('300ms ease-in-out', style({ transform: 'translateY(100%)' }))]),
]);

/**
 * Return trigger function with animations for the login page.
 * @returns trigger function from `@angular/animations`
 */
export const loginTransition: any = trigger('loginTransition', [
    state('void', style({ transform: 'rotateY(-180deg)' })),
    transition('void => 1', [animate('600ms ease-in-out'), style({ transform: 'rotateY(0deg)' })]),
    transition('* => void', [animate('600ms ease-in-out', style({ transform: 'rotateY(180deg)' }))]),
    state('noRotate', style({ transform: 'rotateY(0deg)' })),
]);

/**
 * Return trigger function with animations for the row element.
 * @returns trigger function from `@angular/animations`
 */
export const rowTransition: any = trigger('rowTransition', [
    transition('void => 1', [
        style({
            opacity: 0,
            transform: 'translateY(-85%)',
        }),
        animate('300ms 0s ease'),
    ]),
]);

/**
 * Return trigger function with animations for the row element.
 * @returns trigger function from `@angular/animations`
 */
export const rowFullTransition: any = trigger('rowFullTransition', [
    state('void', style({ opacity: 0, transform: 'translateY(-35%)' })),
    transition('* => *', animate('300ms 0s ease')),
]);

export const list: any = trigger('list', [
    transition(':enter', [query('@items', stagger(300, animateChild()), { optional: true })]),
]);

export const items: any = trigger('items', [
    transition(':enter', [
        style({ transformOrigin: '0 0', transform: 'scale(0.5, 0.5)', opacity: 0, height: '0px' }),
        animate('0.3s ease-out', style({ transformOrigin: '0 0', transform: 'scale(1, 1)', opacity: 1, height: '*' })),
    ]),
    transition(':leave', [
        style({ transformOrigin: '0 0', transform: 'scale(1, 1)', opacity: 1, height: '*' }),
        animate(
            '0.3s ease-out',
            style({ transformOrigin: '0 0', transform: 'scale(0.5, 0.5)', opacity: 0, height: '0px' }),
        ),
    ]),
]);

const itemsFullOut = {
    height: '0px',
    width: '0px',
    marginTop: '0px',
    marginRight: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    paddingTop: '0px',
    paddingRight: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
};

const itemsFullIn = {
    height: '*',
    width: '*',
    marginTop: '*',
    marginRight: '*',
    marginBottom: '*',
    marginLeft: '*',
    paddingTop: '*',
    paddingRight: '*',
    paddingBottom: '*',
    paddingLeft: '*',
};

export const listFull: any = trigger('listFull', [
    transition(':enter', [query('@itemsFull', stagger(200, animateChild()), { optional: true })]),
]);

export const itemsFull: any = trigger('itemsFull', [
    transition(':enter', [style(itemsFullOut), animate('0.3s ease-out', style(itemsFullIn))]),
    transition(':leave', [style(itemsFullIn), animate('0.3s ease-out', style(itemsFullOut))]),
]);

const slideHeightOut = {
    height: '0px',
    paddingTop: '0px',
};

const slideHeightIn = {
    height: '*',
    paddingTop: '*',
};

export const slideHeightByState: any = trigger('slideHeightByState', [
    state('hide', style(slideHeightOut)),
    state('show', style(slideHeightIn)),
    transition('* => *', [animate('350ms cubic-bezier(0.35, 0, 0.25, 1)')]),
]);

const slideToggleOutStyles = {
    height: '0px',
    margin: '0px',
};

const slideToggleInStyles = {
    height: '*',
    margin: '*',
};

export const slideToggleAnimation: any = trigger('slideToggleAnimation', [
    state('hide', style(slideToggleOutStyles)),
    state('show', style(slideToggleInStyles)),
    transition('* => *', [animate('350ms cubic-bezier(0.35, 0, 0.25, 1)')]),
]);

export const slideHeight: any = trigger('slideHeight', [
    transition(':enter', [style(slideHeightOut), animate('0.3s ease', style(slideHeightIn))]),
    transition(':leave', [style(slideHeightIn), animate('0.3s ease', style(slideHeightOut))]),
]);

export const slideHeightUp: any = trigger('slideHeightUp', [
    transition(':leave', [style(slideHeightIn), animate('0.3s ease', style(slideHeightOut))]),
]);

const slideDownOutStyles = {
    transformOrigin: '0 0',
    transform: 'scale(1, 0)',
    opacity: 0.4,
    height: '0px',
    marginTop: '0px',
    marginRight: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
};

const slideDownInStyles = {
    transformOrigin: '0 0',
    transform: 'scale(1, 1)',
    opacity: 1,
    height: '*',
    marginTop: '*',
    marginRight: '*',
    marginBottom: '*',
    marginLeft: '*',
};

export const slideDown: any = trigger('slideDown', [
    transition(':enter', [style(slideDownOutStyles), animate('0.3s ease', style(slideDownInStyles))]),
    transition(':leave', [style(slideDownInStyles), animate('0.3s ease', style(slideDownOutStyles))]),
]);

export const slideDown07: any = trigger('slideDown07', [
    transition(':enter', [style(slideDownOutStyles), animate('0.7s ease', style(slideDownInStyles))]),
    transition(':leave', [style(slideDownInStyles), animate('0.7s ease', style(slideDownOutStyles))]),
]);

export const staggeredList: any = trigger('stagerredList', [
    transition('* => *', [query('@stagerredItem', stagger(100, animateChild()), { optional: true })]),
]);

export const staggeredItem: any = trigger('stagerredItem', [
    transition(':leave', [style({ opacity: 0 })]),
    transition('* => *', [
        style({ opacity: 0, transform: 'translateY(20%)' }),
        animate(
            '0.4s cubic-bezier(.08,.46,.22,1.67)',
            style({
                opacity: 1,
                transform: 'translateY(0%)',
            }),
        ),
    ]),
]);
