import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MessageComponentType } from '@core/models';
import { Observable } from 'rxjs';

type SizeComponent = 'small';

export interface MessageActionEntry {
   type: string;
   text: string;
   width: string;
   trigger: () => void;
   disabled?: boolean;
   asyncDisabled?: Observable<boolean>;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'message',
    styleUrls: ['./message.component.scss'],
    templateUrl: './message.component.html',
})
export class MessageComponent {
    @Input() type?: MessageComponentType = 'info';
    @Input() icon?: string;
    @Input() size?: SizeComponent;
    @Input() iconBack?: boolean;
    @Input() notAvailable?: boolean;
    @Input() title?: string;
    @Input() actionsBasis?: string;
    @Input() actionsList?: MessageActionEntry[];
    @Input() tooltip?: string;
}
