<div class="chips">
  <ul
    class="chips__selected-tags">
    <li
      *ngFor="let tag of selectedTags; let i = index"
      class="chips__tag chips__tag--with-cross">
      {{ tag }}
      <span
        class="chips__remove-btn"
        (click)="removeTag(i)">
        x
      </span>
    </li>

    <li class="chips__new-tag">
      <input
        type="text"
        placeholder="Add string"
        class="chips__input"
        [(ngModel)]="input"
        (keyup.enter)="addTag(input)">
    </li>
  </ul>
</div>
