<ng-template #content>
  <span
    [ngClass]="{
        'file-display__icon--small': iconSize === 'small',
        'file-display__icon--medium': iconSize === 'medium',
        'file-display__icon--large': iconSize === 'large',
        'file-display__icon--primary': iconColor === 'primary',
        'file-display__icon--secondary': iconColor === 'secondary',
        'file-display__icon--alert': iconColor === 'alert',
        'file-display__icon--error': iconColor === 'error'
    }"
    class="file-display__icon icon-file"
  ></span>
  <span
    [ngClass]="{
      'file-display__label--primary': labelColor === 'primary',
      'file-display__label--secondary': labelColor === 'secondary',
      'file-display__label--alert': labelColor === 'alert',
      'file-display__label--error': labelColor === 'error'
    }"
    class="file-display__label"
  >
    {{ fileUrl ? (showFullUrl ? fileUrl : fileUrl | readFilename) : noFileLabel }}
  </span>
</ng-template>

<ng-template #hyperlink>
  <a
    [attr.href]="fileUrl"
    [attr.target]="openExternal ? '_blank' : '_top'"
    class="file-display file-display--link"
    [ngClass]="{
      'file-display--disbaled': isDisabled || !fileUrl
    }"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>

<span
  *ngIf="!showLink || !fileUrl else hyperlink"
  class="file-display"
  [ngClass]="{
    'file-display--disbaled': isDisabled || !fileUrl
  }"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>
