import * as nutrientsActions from '../actions/nutrients.actions';
import { NutrientManage, NutrientsList, NutrientsOptions } from '../models';

export interface State {
    list: NutrientsList;
    manage: NutrientManage;
    options: NutrientsOptions;
}

const initialState: State = {
    list: {
        data: {
            results: [],
            next: null,
            previous: null,
            count: null,
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            group: '',
            highPortionThreshold: null,
            highUnitThreshold: null,
            id: null,
            isBase: false,
            mediumUnitThreshold: null,
            translations: {},
            unit: '',
            averageAdultDailyIntake: null,
        },
        isFetching: true,
        isSaving: false,
        errors: {},
    },
    options: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state: State = initialState, action: nutrientsActions.Actions): State {
    switch (action.type) {
        case nutrientsActions.ActionTypes.GET_NUTRIENTS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case nutrientsActions.ActionTypes.GET_NUTRIENTS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case nutrientsActions.ActionTypes.GET_NUTRIENTS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case nutrientsActions.ActionTypes.GET_NUTRIENT_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case nutrientsActions.ActionTypes.GET_NUTRIENT_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case nutrientsActions.ActionTypes.GET_NUTRIENT_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case nutrientsActions.ActionTypes.NEW_NUTRIENT: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isFetching: false,
                },
            };
        }

        case nutrientsActions.ActionTypes.SAVE_NUTRIENT_REQUEST:
        case nutrientsActions.ActionTypes.CREATE_NUTRIENT_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                    errors: {},
                },
            };
        }

        case nutrientsActions.ActionTypes.SAVE_NUTRIENT_SUCCESS:
        case nutrientsActions.ActionTypes.CREATE_NUTRIENT_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    data: action.payload,
                },
            };
        }

        case nutrientsActions.ActionTypes.SAVE_NUTRIENT_ERROR:
        case nutrientsActions.ActionTypes.CREATE_NUTRIENT_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }
        case nutrientsActions.ActionTypes.DELETE_NUTRIENT_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case nutrientsActions.ActionTypes.DELETE_NUTRIENT_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case nutrientsActions.ActionTypes.DELETE_NUTRIENT_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case nutrientsActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: true,
                },
            };
        }

        case nutrientsActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case nutrientsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case nutrientsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case nutrientsActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsSaving = (state: State) => state.manage.isSaving;
export const getManageIsFetching = (state: State) => state.manage.isFetching;

export const getOptions = (state: State) => state.options;
export const getOptionsData = (state: State) => state.options.data;
export const getOptionsIsFetching = (state: State) => state.options.isFetching;
