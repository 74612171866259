import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromWorkflows from '../reducers/workflows.reducer';

@Injectable()
export class WorkflowsSelectors {
    /**
     * Get state.workflows
     */
    getWorkflowState() {
        return ($state: State): any => $state.workflows;
    }

    /**
     * Get manage from state.attributes
     */
    getManage() {
        return createSelector(
            this.getWorkflowState(),
            fromWorkflows.getManage,
        );
    }
}
