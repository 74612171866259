import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';

@Injectable()
export class StateSelectors {
    /**
     * Get state
     */
    getState() {
        return ($state: State): any => $state;
    }

    /**
     * Get fetchings for manage-attribute component
     */
    isManageAttributeReady(attributeType: string) {
        return createSelector(
            this.getState(),
            (state: State) => {
                return !(
                    state.attributes.manage.isFetching ||
                    state.attributes.options.isFetching ||
                    (attributeType === 'metric' && state.metrics.list.isFetching) ||
                    (attributeType === 'choices' &&
                        (state.metrics.list.isFetching || state.ingredientGroups.list.isFetching)) ||
                    (attributeType === 'nutrient' && state.nutrients.list.isFetching)
                );
            },
        );
    }
}
