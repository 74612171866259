<div
  class="dnd-select"
  [ngClass]="{'dnd-select--readonly': readonly}">
  <div class="dnd-select__row">
    <p
      class="dnd-select__instruction"
      [innerHTML]="txt?.instructions | safeHtml">
    </p>
  </div>

  <div class="dnd-select__row">
    <div class="dnd-select__available-items">
      <div class="dnd-select__bar">{{ txt?.availableBar }}</div>

      <div
        class="dnd-select__available-list"
        [dragula]="bagName"
        [dragulaModel]="availableItems">
        <div
          *ngFor="let item of availableItems; trackBy: trackByFn"
          class="dnd-select__parent-item icon-drag"
          [ngClass]="{'dnd-select__parent-item--has-children': item.children.length}">
          <span class="dnd-select__parent-name">{{ item.name }}</span>

          <span
            class="dnd-select__show-children icon-chevron"
            [ngClass]="{'dnd-select__show-children--opened': item.opened}"
            (click)="item.opened = !item.opened">
          </span>

          <div
            class="dnd-select__children-list"
            [ngClass]="{'dnd-select__children-list--visible': item.opened}">
            <div
              *ngFor="let child of item.children"
              class="dnd-select__child-item">
              <span class="dnd-select__child-name">{{ child }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="dnd-select__readonly-overlay"></div>
    </div>

    <div class="dnd-select__selected-items">
      <div class="dnd-select__bar">{{ txt?.selectedBar }}</div>

      <div
        class="dnd-select__selected-list"
        [ngClass]="{
          'dnd-select__selected-list--empty-list': !selectedItems.length,
          'dnd-select__selected-list--error': errors?.length
        }"
        [attr.data-is-empty]="txt?.emptyList"
        [dragula]="bagName"
        [dragulaModel]="selectedItems">
        <div
        *ngFor="let item of selectedItems; trackBy: trackByFn"
        class="dnd-select__parent-item icon-drag"
        [ngClass]="{'dnd-select__parent-item--has-children': item.children.length}">
          <span class="dnd-select__parent-name">{{ item.name }}</span>

          <span
            class="dnd-select__show-children icon-chevron"
            [ngClass]="{'dnd-select__show-children--opened': item.opened}"
            (click)="item.opened = !item.opened">
          </span>

          <div
            class="dnd-select__children-list"
            [ngClass]="{'dnd-select__children-list--visible': item.opened}">
            <div
              *ngFor="let child of item.children"
              class="dnd-select__child-item">
              <span class="dnd-select__child-name">{{ child }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="dnd-select__readonly-overlay"></div>

      <ul
        *ngIf="errors"
        class="dnd-select__errors errors errors--inside">
        <li
          *ngFor="let error of parseErrors(errors)"
          class="errors__single-error" [innerHTML]="error | safeHtml">
        </li>
      </ul>

      <a
        class="dnd-select__clear-all-btn btn btn--alert btn--smaller"
        [ngClass]="{'btn--disabled': !selectedItems.length || readonly}"
        [attr.tabindex]=" !selectedItems.length || readonly ? -1 : 0"
        (click)="clearAll()">
        Clear all
      </a>
    </div>
  </div>
</div>
