import * as productsActions from '@core/actions/products.actions';

import {
    AcknowledgeUpdate,
    AttributesDiffList,
    BulkCloneAction,
    CustomDataList,
    Product,
    ProductBarcode,
    ProductId,
    ProductsChangesHistory,
    ProductScores,
    ProductsExportStatus,
    ProductsImportTemplate,
    ProductsList,
    ProductsManage,
    ProductsManageBulk,
    ProductsOptions,
    ProductsPrepareExport,
    ProductsPrepareImport,
    ProductsSharesHistory,
    ReadinessReport,
    SearchParamsManage,
    ShareProducts,
} from '@core/models';
import { FtaPricesInterface } from '@products/components/components-manage-product/product-price-info/product-price.models';

export interface State {
    acknowledgeUpdate: AcknowledgeUpdate;
    changesHistory: ProductsChangesHistory;
    cloneStatus: CustomDataList<BulkCloneAction>;
    exportStatus: ProductsExportStatus;
    importTemplate: ProductsImportTemplate;
    productList: ProductsList;
    manage: ProductsManage;
    manageBulk: ProductsManageBulk;
    newProductsId: ProductId[];
    options: ProductsOptions;
    prepareExport: ProductsPrepareExport;
    prepareImport: ProductsPrepareImport;
    productOptions: ProductsOptions;
    productScores: ProductScores;
    readinessReport: CustomDataList<ReadinessReport>;
    searchParams: SearchParamsManage;
    shareProducts: ShareProducts;
    sharesHistory: ProductsSharesHistory;
    barcodeImages: CustomDataList<any>;
    barcode: ProductBarcode;
    attributesDiff: AttributesDiffList;
}

export const productInitialState: Product = {
    backImage: '',
    backImageLarge: '',
    backImageThumbnail: '',
    barcodeTypeDisplay: '',
    bottomImage: '',
    bottomImageLarge: '',
    bottomImageThumbnail: '',
    brandName: '',
    caseChildren: [],
    cases: [],
    category: null,
    categoryDisplay: null,
    company: null,
    companyData: null,
    companyGln: '',
    companyName: '',
    completeness: null,
    countryOfOrigin: '',
    countryOfOriginDisplay: [],
    created: '',
    dataCarrierTypeCode: null,
    frontFaceImage: '',
    frontFaceImageLarge: '',
    frontFaceImageThumbnail: '',
    ftaPrices: {} as FtaPricesInterface,
    gtin: null,
    hasBeenPublished: false,
    id: null,
    internalData: {},
    isNew: false,
    isSoftDeleted: false,
    isUpdated: false,
    isFrozen: false,
    latestWorkflow: null,
    leftOfFrontImage: '',
    leftOfFrontImageLarge: '',
    leftOfFrontImageThumbnail: '',
    modified: '',
    nextProductId: '',
    originalDataProvider: null,
    originalDataProviderGln: null,
    previousProductId: '',
    productHistory: [],
    productTemplate: null,
    publisher: '',
    readiness: false,
    rightOfFrontImage: '',
    rightOfFrontImageLarge: '',
    rightOfFrontImageThumbnail: '',
    shortBrandName: '',
    shortTradeItemDescription: '',
    status: null,
    statusDisplay: null,
    targetMarketCountryCode: [],
    targetMarketsDisplay: [],
    topImage: '',
    topImageLarge: '',
    topImageThumbnail: '',
    tradeItemDescription: '',
    updatedFields: {},
    values: {},
    variants: [],
    variantsDisplay: [],
    companySubtype: '',
    wasFrozen: false,
};

const manageInitialState: ProductsManage = {
    data: productInitialState,
    errors: {},
    isFetching: true,
    isSaving: false,
};

const manageBulkInitialState: ProductsManageBulk = {
    errors: {},
    isSaving: false,
};

const noDataInitialState = {
    isFetching: false,
    errors: {},
};

const optionsGetBarcodeInitialState: ProductBarcode = {
    data: {
        barcode: '',
    },
    errors: {},
    isFetching: false,
};

const arrayDataInitialState: CustomDataList<any[]> = {
    data: [],
    errors: {},
    isFetching: false,
};

const objectDataInitialState: ProductsExportStatus = {
    data: {
        info: {
            url: '',
            currentProduct: null,
            productCount: null,
        },
        status: null,
    },
    errors: {},
    isFetching: false,
};

const listInitialState: ProductsList = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
        searchId: null,
        searchParams: null,
    },
    errors: {},
    isFetching: true,
};

const optionsInitialState = {
    data: {},
    isFetching: true,
};

const searchParamsInitalState = {
    data: {},
    errors: {},
    isFetching: true,
    isSaving: false,
};

const cloneStatusInitialState = {
    ...objectDataInitialState,
    data: {
        status: null,
        info: {},
    },
};

const scoresInitialState: ProductScores = {
    data: [],
    errors: {},
    isFetching: true,
};

const readinessReportInitialState: CustomDataList<ReadinessReport> = {
    data: {
        products: [],
        attributes: [],
        preset: null,
    },
    errors: {},
    isFetching: true,
};

const barcodeImagesInitialState: CustomDataList<any> = {
    data: {},
    errors: {},
    isFetching: false,
};

const attributesDiffInitialState: CustomDataList<any> = {
    data: {},
    errors: {},
    isFetching: false,
};

export const initialState: State = {
    acknowledgeUpdate: noDataInitialState,
    changesHistory: arrayDataInitialState,
    cloneStatus: cloneStatusInitialState,
    exportStatus: objectDataInitialState,
    importTemplate: objectDataInitialState,
    productList: listInitialState,
    manage: manageInitialState,
    manageBulk: manageBulkInitialState,
    newProductsId: [],
    options: optionsInitialState,
    prepareExport: objectDataInitialState,
    prepareImport: objectDataInitialState,
    productOptions: optionsInitialState,
    productScores: scoresInitialState,
    readinessReport: readinessReportInitialState,
    searchParams: searchParamsInitalState,
    shareProducts: objectDataInitialState,
    sharesHistory: arrayDataInitialState,
    barcodeImages: barcodeImagesInitialState,
    barcode: optionsGetBarcodeInitialState,
    attributesDiff: attributesDiffInitialState,
};

export function reducer(state = initialState, action: productsActions.Actions): State {
    switch (action.type) {
        case productsActions.ActionTypes.GET_PRODUCTS_LIST_REQUEST: {
            return {
                ...state,
                productList: {
                    ...state.productList,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCTS_LIST_SUCCESS: {
            return {
                ...state,
                productList: {
                    ...state.productList,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCTS_LIST_ERROR: {
            return {
                ...state,
                productList: {
                    ...state.productList,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_SEARCH_PARAMS_REQUEST: {
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case productsActions.ActionTypes.GET_SEARCH_PARAMS_SUCCESS:
        case productsActions.ActionTypes.SET_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.GET_SEARCH_PARAMS_ERROR: {
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_ATTRIBUTES_DIFF_REQUEST: {
            return {
                ...state,
                attributesDiff: {
                    ...initialState.attributesDiff,
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_ATTRIBUTES_DIFF_SUCCESS: {
            return {
                ...state,
                attributesDiff: {
                    ...state.attributesDiff,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_ATTRIBUTES_DIFF_ERROR: {
            return {
                ...state,
                attributesDiff: {
                    ...state.attributesDiff,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.NEW_PRODUCT: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.CREATE_PRODUCT_REQUEST:
        case productsActions.ActionTypes.SAVE_PRODUCT_REQUEST:
        case productsActions.ActionTypes.SAVE_PRODUCT_DETAILS_REQUEST:
        case productsActions.ActionTypes.SAVE_PRODUCT_RELATIONS_REQUEST:
        case productsActions.ActionTypes.SAVE_PRODUCT_INTERNAL_DATA_REQUEST:
        case productsActions.ActionTypes.DISCONTINUE_PRODUCT_REQUEST:
        case productsActions.ActionTypes.REVERT_DISCONTINUE_PRODUCT_REQUEST:
        case productsActions.ActionTypes.UPLOAD_PRODUCT_FILES_REQUEST:
        case productsActions.ActionTypes.DELETE_PRODUCT_FILES_REQUEST:
        case productsActions.ActionTypes.SOFT_DELETE_PRODUCT_REQUEST:
        case productsActions.ActionTypes.HARD_DELETE_PRODUCT_REQUEST:
        case productsActions.ActionTypes.RECOVER_PRODUCT_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case productsActions.ActionTypes.CREATE_PRODUCT_SUCCESS:
        case productsActions.ActionTypes.SAVE_PRODUCT_SUCCESS:
        case productsActions.ActionTypes.SAVE_PRODUCT_DETAILS_SUCCESS:
        case productsActions.ActionTypes.SAVE_PRODUCT_RELATIONS_SUCCESS:
        case productsActions.ActionTypes.SAVE_PRODUCT_INTERNAL_DATA_SUCCESS:
        case productsActions.ActionTypes.DISCONTINUE_PRODUCT_SUCCESS:
        case productsActions.ActionTypes.REVERT_DISCONTINUE_PRODUCT_SUCCESS:
        case productsActions.ActionTypes.UPLOAD_PRODUCT_FILES_SUCCESS:
        case productsActions.ActionTypes.DELETE_PRODUCT_FILES_SUCCESS:
        case productsActions.ActionTypes.SOFT_DELETE_PRODUCT_SUCCESS:
        case productsActions.ActionTypes.RECOVER_PRODUCT_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case productsActions.ActionTypes.UPLOAD_PRODUCT_RECEIPTS_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                },
            };
        }

        case productsActions.ActionTypes.CREATE_PRODUCT_ERROR:
        case productsActions.ActionTypes.SAVE_PRODUCT_ERROR:
        case productsActions.ActionTypes.SAVE_PRODUCT_DETAILS_ERROR:
        case productsActions.ActionTypes.SAVE_PRODUCT_RELATIONS_ERROR:
        case productsActions.ActionTypes.SAVE_PRODUCT_INTERNAL_DATA_ERROR:
        case productsActions.ActionTypes.DISCONTINUE_PRODUCT_ERROR:
        case productsActions.ActionTypes.REVERT_DISCONTINUE_PRODUCT_ERROR:
        case productsActions.ActionTypes.UPLOAD_PRODUCT_FILES_ERROR:
        case productsActions.ActionTypes.DELETE_PRODUCT_FILES_ERROR:
        case productsActions.ActionTypes.SOFT_DELETE_PRODUCT_ERROR:
        case productsActions.ActionTypes.HARD_DELETE_PRODUCT_ERROR:
        case productsActions.ActionTypes.RECOVER_PRODUCT_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case productsActions.ActionTypes.BULK_SAVE_PRODUCTS_REQUEST: {
            return {
                ...state,
                manageBulk: {
                    ...state.manageBulk,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case productsActions.ActionTypes.BULK_SAVE_PRODUCTS_SUCCESS:
        case productsActions.ActionTypes.BULK_SAVE_PRODUCTS_ERROR: {
            const errors = (action.payload || []).reduce((acc, response) => {
                if (response.errors) {
                    acc[response.productId] = response.errors;
                }

                return acc;
            }, {});

            return {
                ...state,
                manageBulk: {
                    ...state.manageBulk,
                    errors,
                    isSaving: false,
                },
            };
        }

        case productsActions.ActionTypes.HARD_DELETE_PRODUCT_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case productsActions.ActionTypes.SET_PRODUCTS_TO_LIVE_REQUEST:
        case productsActions.ActionTypes.DISCONTINUE_PRODUCTS_REQUEST:
        case productsActions.ActionTypes.REVERT_DISCONTINUE_PRODUCTS_REQUEST:
        case productsActions.ActionTypes.SOFT_DELETE_PRODUCTS_REQUEST:
        case productsActions.ActionTypes.HARD_DELETE_PRODUCTS_REQUEST:
        case productsActions.ActionTypes.RECOVER_PRODUCTS_REQUEST: {
            return {
                ...state,
                productList: {
                    ...state.productList,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.SET_PRODUCTS_TO_LIVE_SUCCESS:
        case productsActions.ActionTypes.DISCONTINUE_PRODUCTS_SUCCESS:
        case productsActions.ActionTypes.REVERT_DISCONTINUE_PRODUCTS_SUCCESS:
        case productsActions.ActionTypes.SOFT_DELETE_PRODUCTS_SUCCESS:
        case productsActions.ActionTypes.HARD_DELETE_PRODUCTS_SUCCESS:
        case productsActions.ActionTypes.RECOVER_PRODUCTS_SUCCESS: {
            return {
                ...state,
                productList: {
                    ...state.productList,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.SET_PRODUCTS_TO_LIVE_PARTIAL_SUCCESS:
        case productsActions.ActionTypes.SET_PRODUCTS_TO_LIVE_ERROR:
        case productsActions.ActionTypes.DISCONTINUE_PRODUCTS_ERROR:
        case productsActions.ActionTypes.REVERT_DISCONTINUE_PRODUCTS_ERROR:
        case productsActions.ActionTypes.SOFT_DELETE_PRODUCTS_ERROR:
        case productsActions.ActionTypes.HARD_DELETE_PRODUCTS_ERROR:
        case productsActions.ActionTypes.RECOVER_PRODUCTS_ERROR: {
            return {
                ...state,
                productList: {
                    ...state.productList,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_CHANGES_HISTORY_REQUEST: {
            return {
                ...state,
                changesHistory: {
                    ...state.changesHistory,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.GET_CHANGES_HISTORY_SUCCESS: {
            return {
                ...state,
                changesHistory: {
                    ...state.changesHistory,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_CHANGES_HISTORY_ERROR: {
            return {
                ...state,
                changesHistory: {
                    ...state.changesHistory,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_SHARES_HISTORY_REQUEST: {
            return {
                ...state,
                sharesHistory: {
                    ...state.sharesHistory,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.GET_SHARES_HISTORY_SUCCESS: {
            return {
                ...state,
                sharesHistory: {
                    ...state.sharesHistory,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_SHARES_HISTORY_ERROR: {
            return {
                ...state,
                sharesHistory: {
                    ...state.sharesHistory,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_IMPORT_TEMPLATE_REQUEST: {
            return {
                ...state,
                importTemplate: {
                    ...state.importTemplate,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.GET_IMPORT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                importTemplate: {
                    ...state.importTemplate,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_IMPORT_TEMPLATE_ERROR: {
            return {
                ...state,
                importTemplate: {
                    ...state.importTemplate,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.PREPARE_LOCAL_IMPORT_REQUEST: {
            return {
                ...state,
                prepareImport: {
                    ...state.prepareImport,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.PREPARE_LOCAL_IMPORT_SUCCESS: {
            return {
                ...state,
                prepareImport: {
                    ...state.prepareImport,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.PREPARE_LOCAL_IMPORT_ERROR: {
            return {
                ...state,
                prepareImport: {
                    ...state.prepareImport,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.PREPARE_PRODUCTS_EXPORT_REQUEST:
        case productsActions.ActionTypes.PREPARE_PRODUCT_MEDIAS_EXPORT_REQUEST:
        case productsActions.ActionTypes.PREPARE_PRODUCTS_MEDIAS_EXPORT_REQUEST: {
            return {
                ...state,
                prepareExport: {
                    ...state.prepareExport,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.PREPARE_PRODUCTS_EXPORT_SUCCESS:
        case productsActions.ActionTypes.PREPARE_PRODUCT_MEDIAS_EXPORT_SUCCESS:
        case productsActions.ActionTypes.PREPARE_PRODUCTS_MEDIAS_EXPORT_SUCCESS: {
            return {
                ...state,
                prepareExport: {
                    ...state.prepareExport,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.PREPARE_PRODUCTS_EXPORT_ERROR:
        case productsActions.ActionTypes.PREPARE_PRODUCT_MEDIAS_EXPORT_ERROR:
        case productsActions.ActionTypes.PREPARE_PRODUCTS_MEDIAS_EXPORT_ERROR: {
            return {
                ...state,
                prepareExport: {
                    ...state.prepareExport,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_EXPORT_STATUS_REQUEST: {
            return {
                ...state,
                exportStatus: {
                    ...state.exportStatus,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.GET_EXPORT_STATUS_SUCCESS: {
            return {
                ...state,
                exportStatus: {
                    ...state.exportStatus,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_EXPORT_STATUS_ERROR: {
            return {
                ...state,
                exportStatus: {
                    ...state.exportStatus,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.SHARE_PRODUCTS_REQUEST: {
            return {
                ...state,
                shareProducts: {
                    ...state.shareProducts,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.SHARE_PRODUCTS_SUCCESS: {
            return {
                ...state,
                shareProducts: {
                    ...state.shareProducts,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.SHARE_PRODUCTS_ERROR: {
            return {
                ...state,
                shareProducts: {
                    ...state.shareProducts,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.CLONE_PRODUCT_REQUEST: {
            if (action.payload.view === 'list') {
                return {
                    ...state,
                    productList: {
                        ...state.productList,
                        isFetching: true,
                        errors: {},
                    },
                };
            } else if (action.payload.view === 'preview') {
                return {
                    ...state,
                    manage: {
                        ...state.manage,
                        isSaving: true,
                        errors: {},
                    },
                };
            }
            return { ...state };
        }

        case productsActions.ActionTypes.CLONE_PRODUCT_SUCCESS: {
            if (action.payload.view === 'list') {
                return {
                    ...state,
                    productList: {
                        ...state.productList,
                        isFetching: false,
                    },
                };
            } else if (action.payload.view === 'preview') {
                return {
                    ...state,
                    manage: {
                        ...state.manage,
                        isSaving: false,
                    },
                };
            }
            return { ...state };
        }

        case productsActions.ActionTypes.CLONE_PRODUCT_ERROR: {
            if (action.payload.view === 'list') {
                return {
                    ...state,
                    productList: {
                        ...state.productList,
                        isFetching: false,
                        errors: action.payload,
                    },
                };
            } else if (action.payload.view === 'preview') {
                return {
                    ...state,
                    manage: {
                        ...state.manage,
                        isSaving: false,
                        errors: action.payload,
                    },
                };
            }
            return { ...state };
        }

        case productsActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_OPTIONS_REQUEST: {
            return {
                ...state,
                productOptions: {
                    ...state.productOptions,
                    data: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_OPTIONS_SUCCESS: {
            return {
                ...state,
                productOptions: {
                    ...state.productOptions,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                productList: {
                    ...initialState.productList,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_MANAGE_BULK: {
            return {
                ...state,
                manageBulk: {
                    ...initialState.manageBulk,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_SHARE_PRODUCTS: {
            return {
                ...state,
                shareProducts: {
                    ...initialState.shareProducts,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_CHANGES_HISTORY: {
            return {
                ...state,
                changesHistory: {
                    ...initialState.changesHistory,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_SHARES_HISTORY: {
            return {
                ...state,
                sharesHistory: {
                    ...initialState.sharesHistory,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_IMPORT_TEMPLATE: {
            return {
                ...state,
                importTemplate: {
                    ...initialState.importTemplate,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_PREPARE_IMPORT: {
            return {
                ...state,
                prepareImport: {
                    ...initialState.prepareImport,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_PREPARE_EXPORT: {
            return {
                ...state,
                prepareExport: {
                    ...initialState.prepareExport,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_EXPORT_STATUS: {
            return {
                ...state,
                exportStatus: {
                    ...initialState.exportStatus,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_PRODUCT_OPTIONS: {
            return {
                ...state,
                productOptions: {
                    ...initialState.productOptions,
                },
            };
        }

        case productsActions.ActionTypes.CLEAN_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: {
                    ...initialState.searchParams,
                },
            };
        }

        case productsActions.ActionTypes.ACKNOWLEDGE_PRODUCT_UPDATE_REQUEST:
        case productsActions.ActionTypes.ACKNOWLEDGE_PRODUCTS_UPDATE_REQUEST: {
            return {
                ...state,
                acknowledgeUpdate: {
                    ...state.acknowledgeUpdate,
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.ACKNOWLEDGE_PRODUCT_UPDATE_SUCCESS:
        case productsActions.ActionTypes.ACKNOWLEDGE_PRODUCTS_UPDATE_SUCCESS: {
            return {
                ...state,
                acknowledgeUpdate: {
                    ...state.acknowledgeUpdate,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.ACKNOWLEDGE_PRODUCT_UPDATE_ERROR:
        case productsActions.ActionTypes.ACKNOWLEDGE_PRODUCTS_UPDATE_ERROR: {
            return {
                ...state,
                acknowledgeUpdate: {
                    ...state.acknowledgeUpdate,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.SET_NEW_PRODUCTS_ID: {
            return {
                ...state,
                newProductsId: [...state.newProductsId, ...action.payload],
            };
        }

        case productsActions.ActionTypes.CLEAR_NEW_PRODUCTS_ID: {
            return {
                ...state,
                newProductsId: [],
            };
        }

        case productsActions.ActionTypes.GET_BULK_CLONE_STATUS_REQUEST: {
            return {
                ...state,
                cloneStatus: {
                    ...state.cloneStatus,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case productsActions.ActionTypes.GET_BULK_CLONE_STATUS_SUCCESS: {
            return {
                ...state,
                cloneStatus: {
                    ...state.cloneStatus,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_BULK_CLONE_STATUS_ERROR: {
            return {
                ...state,
                cloneStatus: {
                    ...state.cloneStatus,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_BULK_CLONE_STATUS: {
            return {
                ...state,
                cloneStatus: {
                    ...initialState.cloneStatus,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_SCORES_REQUEST: {
            return {
                ...state,
                productScores: {
                    ...state.productScores,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_SCORES_SUCCESS: {
            return {
                ...state,
                productScores: {
                    ...state.productScores,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_PRODUCT_SCORES_ERROR: {
            return {
                ...state,
                productScores: {
                    ...state.productScores,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_PRODUCT_SCORES: {
            return {
                ...state,
                productScores: {
                    ...initialState.productScores,
                },
            };
        }

        case productsActions.ActionTypes.GET_READINESS_REPORT_REQUEST: {
            return {
                ...state,
                readinessReport: {
                    ...state.readinessReport,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case productsActions.ActionTypes.GET_READINESS_REPORT_SUCCESS: {
            return {
                ...state,
                readinessReport: {
                    ...state.readinessReport,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_READINESS_REPORT_ERROR: {
            return {
                ...state,
                readinessReport: {
                    ...state.readinessReport,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case productsActions.ActionTypes.CLEAR_READINESS_REPORT: {
            return {
                ...state,
                readinessReport: {
                    ...initialState.readinessReport,
                },
            };
        }

        case productsActions.ActionTypes.DOWNLOAD_BARCODE_IMAGES_REQUEST: {
            return {
                ...state,
                barcodeImages: {
                    data: initialState.barcodeImages.data,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case productsActions.ActionTypes.DOWNLOAD_BARCODE_IMAGES_SUCCESS: {
            return {
                ...state,
                barcodeImages: {
                    data: action.payload,
                    errors: {},
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.DOWNLOAD_BARCODE_IMAGES_ERROR: {
            return {
                ...state,
                barcodeImages: {
                    data: initialState.barcodeImages.data,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case productsActions.ActionTypes.GET_BARCODE_REQUEST: {
            return {
                ...state,
                barcode: {
                    data: initialState.barcode.data,
                    errors: initialState.barcode.errors,
                    isFetching: false,
                },
            };
        }
        case productsActions.ActionTypes.GET_BARCODE_SUCCESS: {
            return {
                ...state,
                barcode: {
                    data: action.payload,
                    isFetching: false,
                    errors: initialState.barcode.errors,
                },
            };
        }
        case productsActions.ActionTypes.GET_BARCODE_ERROR: {
            return {
                ...state,
                barcode: {
                    data: initialState.barcode.data,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }
        case productsActions.ActionTypes.CLEAR_BARCODE: {
            return {
                ...state,
                barcode: {
                    ...initialState.barcode,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getProductList = (state: State) => state.productList;
export const getSearchParams = (state: State) => state.searchParams;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsFetching = (state: State) => state.manage.isFetching;
export const getManageIsSaving = (state: State) => state.manage.isSaving;

export const getManageBulk = (state: State) => state.manageBulk;
export const getShareProducts = (state: State) => state.shareProducts;

export const getChangesHistory = (state: State) => state.changesHistory;
export const getSharesHistory = (state: State) => state.sharesHistory;

export const getImportTemplate = (state: State) => state.importTemplate;

export const getPrepareImport = (state: State) => state.prepareImport;

export const getPrepareExport = (state: State) => state.prepareExport;

export const getExportStatus = (state: State) => state.exportStatus;
export const getExportIsFetching = (state: State) => state.prepareExport.isFetching;

export const getOptions = (state: State) => state.options;
export const getOptionsData = (state: State) => state.options.data;
export const getOptionsIsFetching = (state: State) => state.options.isFetching;
export const getProductOptions = (state: State) => state.productOptions;

export const getNewProductsId = (state: State) => state.newProductsId;
export const getCloneStatus = (state: State) => state.cloneStatus;
export const getProductScores = (state: State) => state.productScores;
export const getReadinessReport = (state: State) => state.readinessReport;

export const getBarcodeImage = (state: State) => state.barcode;

export const getAttributesDiff = (state: State) => state.attributesDiff;
