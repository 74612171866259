import { type } from '@app/utils';

const ACTION_PREFIX = '[Auxiliary]';

export const ActionTypes = {
    SET_ATTRIBUTE_CHANGE_DETECTION: type(`${ ACTION_PREFIX } Set Attribute Change Detection`),
    CLEAR_ATTRIBUTE_CHANGE_DETECTION: type(`${ ACTION_PREFIX } Clear Attribute Change Detection`),

    GET_MAINTENANCE_NOTIFICATION_REQUEST: type(`${ ACTION_PREFIX } Get Maintenance Notification Request`),
    GET_MAINTENANCE_NOTIFICATION_SUCCESS: type(`${ ACTION_PREFIX } Get Maintenance Notification Success`),

    UNFROZEN_PRODUCT_REQUEST: type(`${ ACTION_PREFIX } Unfrozen Product Request`),
    UNFROZEN_PRODUCT_SUCCESS: type(`${ ACTION_PREFIX } Unfrozen Product Success`),
    UNFROZEN_PRODUCT_ERRORS: type(`${ ACTION_PREFIX } Unfrozen Product Errors`),

    GET_MOHAP_PRODUCT_UPDATE_PREVIEW_REQUEST: type(`${ ACTION_PREFIX } Get Mohap Product Update Preview Request`),
    GET_MOHAP_PRODUCT_UPDATE_PREVIEW_SUCCESS: type(`${ ACTION_PREFIX } Get Mohap Product Update Preview Success`),

    ROLLBACK_APPROVAL_REQUEST: type(`${ ACTION_PREFIX } Rollback Approval Request`),
    ROLLBACK_APPROVAL_SUCCESS: type(`${ ACTION_PREFIX } Rollback Approval Success`),
    ROLLBACK_APPROVAL_ERROR: type(`${ACTION_PREFIX} Rollback Approval Error`),

    DOWNLOAD_FILE_LINK_REQUEST: type(`${ACTION_PREFIX} Download File Link Request`),
    DOWNLOAD_FILE_LINK_SUCCESS: type(`${ ACTION_PREFIX } Download File Link Success`),

    UAE_PASS_LOGIN_ACTION: type(`${ ACTION_PREFIX } Uae Pass Login Action`),
    UAE_PASS_LINKING_ACTION: type(`${ ACTION_PREFIX } Uae Pass Linking Action`),
    SIGN_OUT_UAE_PASS: type(`${ ACTION_PREFIX } Sign Out Uae Pass`),

    GET_COMPANIES_TO_PRODUCT_TRANSFER_REQUEST: type(`${ ACTION_PREFIX } Get Companies To Product Transfer Request`),
    GET_COMPANIES_TO_PRODUCT_TRANSFER_SUCCESS: type(`${ ACTION_PREFIX } Get Companies To Product Transfer Success`),

    TRANSFER_PRODUCT_TO_COMPANY_REQUEST: type(`${ ACTION_PREFIX } Transfer Product To Company Request`),
};

export class SetAttributeChangeDetection {
    static readonly type = ActionTypes.SET_ATTRIBUTE_CHANGE_DETECTION;
    constructor(public attributeId: string) {}
}

export class ClearAttribiuteChangeDetection {
    static readonly type = ActionTypes.CLEAR_ATTRIBUTE_CHANGE_DETECTION;
}

export class GetMaintenanceNotificationRequest {
    static readonly type = ActionTypes.GET_MAINTENANCE_NOTIFICATION_REQUEST;
}

export class GetMaintenanceNotificationSuccess {
    static readonly type = ActionTypes.GET_MAINTENANCE_NOTIFICATION_SUCCESS;
    constructor(public messages: string[]) {}
}

export class UnfrozenProductRequest {
    static readonly type = ActionTypes.UNFROZEN_PRODUCT_REQUEST;
    constructor(public productId: string, public decision: string) {}
}

export class UnfrozenProductSuccess {
    static readonly type = ActionTypes.UNFROZEN_PRODUCT_SUCCESS;
    constructor(public decision: string, public productId?: string) {}
}

export class UnfrozenProductErrors {
    static readonly type = ActionTypes.UNFROZEN_PRODUCT_ERRORS;
}

export class GetMohapProductUpdatePreviewRequest {
    static readonly type = ActionTypes.GET_MOHAP_PRODUCT_UPDATE_PREVIEW_REQUEST;
    constructor(public id: string) {}
}

export class GetMohapProductUpdatePreviewSuccess {
    static readonly type = ActionTypes.GET_MOHAP_PRODUCT_UPDATE_PREVIEW_SUCCESS;
    constructor(public data: any) {}
}

export class RollbackApprovalReguest {
    static readonly type = ActionTypes.ROLLBACK_APPROVAL_REQUEST;
    constructor(public productId: string) {}
}

export class RollbackApprovalSuccess {
    static readonly type = ActionTypes.ROLLBACK_APPROVAL_SUCCESS;
    constructor(public productId: string, public decision: string) {}
}

export class RollbackApprovalError {
    static readonly type = ActionTypes.ROLLBACK_APPROVAL_ERROR;
}

export class DownloadFileLinkRequest {
    static readonly type = ActionTypes.DOWNLOAD_FILE_LINK_REQUEST;
    constructor(public uuid: string) {}
}

export class DownloadFileLinkSuccess {
    static readonly type = ActionTypes.DOWNLOAD_FILE_LINK_SUCCESS;
    constructor(public link: string) {}
}

export class UaePassLoginAction {
    static readonly type = ActionTypes.UAE_PASS_LOGIN_ACTION;
    constructor(public state: string, public code: string) {}
}

export class UaePassLinkingAction {
    static readonly type = ActionTypes.UAE_PASS_LINKING_ACTION;
    constructor(public state: string, public code: string) {}
}

export class SignOutUaePassAction {
    static readonly type = ActionTypes.SIGN_OUT_UAE_PASS;
}

export class GetCompaniesToProductTransferRequest {
    static readonly type = ActionTypes.GET_COMPANIES_TO_PRODUCT_TRANSFER_REQUEST;
    constructor(public productId: string) {}
}

export class GetCompaniesToProductTransferSuccess {
    static readonly type = ActionTypes.GET_COMPANIES_TO_PRODUCT_TRANSFER_SUCCESS;
    constructor(public data: any) {}
}

export class TransferProductToCompanyRequest {
  static readonly type = ActionTypes.TRANSFER_PRODUCT_TO_COMPANY_REQUEST;
  constructor(public productId: string, public companyId: string) {};
}

export type Actions =
    | SetAttributeChangeDetection
    | SignOutUaePassAction
    | UaePassLinkingAction
    | ClearAttribiuteChangeDetection
    | GetMaintenanceNotificationRequest
    | GetMaintenanceNotificationSuccess
    | UnfrozenProductRequest
    | UnfrozenProductSuccess
    | UnfrozenProductErrors
    | GetMohapProductUpdatePreviewRequest
    | GetMohapProductUpdatePreviewSuccess
    | DownloadFileLinkRequest
    | DownloadFileLinkSuccess
    | UaePassLoginAction
;
