export type OptionValue = string | number | boolean;

export class Option {
    value: OptionValue;
    displayName: string;
    hasChildren: boolean;
    isChild: boolean;
    childrenIds: OptionValue[];
    details: any;

    disabled: boolean;
    highlighted: boolean;
    selected: boolean;
    shown: boolean;

    constructor(
        value: OptionValue,
        displayName: string,
        hasChildren: boolean,
        isChild: boolean,
        childrenIds: string[],
        details: any,
    ) {
        this.value = value;
        this.displayName = displayName;
        this.hasChildren = hasChildren;
        this.isChild = isChild;
        this.childrenIds = childrenIds;
        this.details = details;

        this.disabled = false;
        this.highlighted = false;
        this.selected = false;
        this.shown = true;
    }

    show() {
        this.shown = true;
    }

    hide() {
        this.shown = false;
    }

    disable() {
        this.disabled = true;
    }

    enable() {
        this.disabled = false;
    }

    undecoratedCopy() {
        return {
            displayName: this.displayName,
            value: this.value,
            details: this.details,
        };
    }
}
