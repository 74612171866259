<div
  class="tooltip tooltip--{{ placement }} tooltip--{{ theme }}-theme"
  [style.top]="top + 'px'"
  [style.left]="left + 'px'"
  [ngClass]="{
    'in': isIn,
    'fade': isFade,
    'wide': isWide,
    'positioning': isPositioning
  }"
  role="tooltip">
  <div class="tooltip__arrow"></div>
  <div class="tooltip__inner">
    <ng-content></ng-content>
    <div class="tooltip__content" [innerHTML]="content"></div>
  </div>
</div>
