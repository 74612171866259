<div
  class="bulk-actions"
  [ngClass]="{'bulk-actions--list': type === 'list'}">
  <ng-template [ngIf]="type === 'select'">
    <ng-select
      [(ngModel)]="value"
      [options]="actions"
      [noFilter]="'Infinite'"
      [allowClear]="true"
      (selected)="shouldDisableButton()"
      (deselected)="shouldDisableButton()"
      placeholder="Actions"
      class="bulk-actions__select">
    </ng-select>
    <button
      class="bulk-actions__btn btn btn--primary"
      [ngClass]="{
        'btn--disabled': bulkActionBtnDisabled,
        'btn--loading': buttonLoading
      }"
      [attr.tabindex]="bulkActionBtnDisabled ? -1 : 0"
      (click)="executeBulkAction()">
      Go ({{ selectedItems.length }})
    </button>
  </ng-template>
  <ng-template [ngIf]="type === 'list'">
      <div
        [ngClass]="{'bulk-actions__label--open': isOpen}"
        (click)="toggleList()"
        class="bulk-actions__label">
        <span class="bulk-actions__label-text">Actions</span>
      </div>
      <div #list class="bulk-actions__box">
        <ul class="bulk-actions__list">
          <li
            *ngFor="let action of actions"
            class="bulk-actions__list-item">
            <a (click)="onMenuSelect(action.value)" class="bulk-actions__list-link {{ action.details ? action.details.icon : 'icon-info' }}">{{ action.displayName }}</a>
          </li>
        </ul>
      </div>
  </ng-template>
</div>
