import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable } from 'rxjs';

import { CountriesGetPayload } from '@core/models';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class UtilsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get countries list
     * @returns Backend API response
     */
    getCountries(params?: CountriesGetPayload): Observable<any> {
        return this.httpClient.get<any>(PROTECTED_URLS.UTILS_COUNTRIES, { params } as Params);
    }
}
