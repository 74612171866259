/**
 * Config file used to:
 * - hide or mark attribute form control as required,
 * - define which prices should be hidden for particular category,
 */
import { CompaniesManage, DefaultManage, ListedProduct, LocalUser, Product } from '@core/models';

interface FTAttributeConditionalConfig {
    attribute: string;
    value: string;
    hide: string[];
    required: string[];
}

export const FTA_COMPANY_SUBTYPE = 'fta';

export const isFtaCompanyInCompanyState = (companyData: CompaniesManage) => {
    return companyData && companyData.data && companyData.data.subtype === FTA_COMPANY_SUBTYPE;
};

export const isFtaProductSetInState = (
    productData: DefaultManage<ListedProduct> | Product,
    givenOnlyData: boolean = false,
) => {
    const _data: any = givenOnlyData ? productData : (productData as DefaultManage<ListedProduct>).data;
    return (_data as Product) && _data.companyData && _data.companyData.subtype === FTA_COMPANY_SUBTYPE;
};

export const isFtaCompanySetInUser = (userData: LocalUser) => {
    return userData && userData.data && userData.data.company && userData.data.company.subtype === FTA_COMPANY_SUBTYPE;
};

export const isFtaPublisher = (userData: LocalUser) => {
    return isFtaCompanySetInUser(userData) && userData.data.company.role === 'p';
};

export const productUAE = (data: Product): boolean => {
  if (data.categoryDisplay) {
    return data.categoryDisplay.parents.filter((elem) => uaeCategories.includes(elem.translations.en.name)).length !== 0;
  }
  return false;
};

/**
 * Hide or mark attribute form control as required
 *    {
 *      attribute: 'conditional attribute id',
 *      value : 'string value for attribute condition',
 *      hide: [attribute ids list to hide if value for attribute is correct],
 *      required: [attribute ids list to mark as required if value for attribute is correct],
 *   }
 */
export const ftaAttributeConfig: FTAttributeConditionalConfig[] = [
    {
        attribute: 'isThisProductAPack',
        value: 'No',
        hide: ['childTradeItemgtin', 'numberOfUnitsPerPack', 'netContentOfIndividualUnit'],
        required: ['childTradeItemgtin', 'numberOfUnitsPerPack', 'netContentOfIndividualUnit'],
    },
    {
        attribute: 'artificiallySweetened',
        value: 'No',
        hide: ['artificiallySweetenedIngredients'],
        required: ['artificiallySweetenedIngredients'],
    },
    {
        attribute: 'productContainsStimulantSubstances',
        value: 'No',
        hide: ['stimulantSubstancesIngredient'],
        required: [],
    },
    {
        attribute: 'containsSugar',
        value: 'No',
        hide: ['sugarContent', 'sugarPercentage'],
        required: ['sugarContent', 'sugarPercentage'],
    },
    {
        attribute: 'isDairyProduct',
        value: 'No',
        hide: [
            'percentageofMilkSubstituteContained',
            'milkPercentage',
            'typeOfMilkSubstitute',
            'calciumContent',
            'calciumContentPercentage',
        ],
        required: [
            'percentageofMilkSubstituteContained',
            'milkPercentage',
            'typeOfMilkSubstitute',
            'calciumContent',
            'calciumContentPercentage',
        ],
    },
  ];

export const uaeCategories = [
  'Excisable Product for Sale in the UAE',
  'Non-Excisable Product for Sale in the UAE',
  'Non-Excisable Product for Re-Export',
  'Excisable Product for Re-Export',
];

export interface FtaCategoryRatioInterface {
   category: string;
   TaxRatio: string;
}

export const consentFormParts = [
    {
        text:
            'All information provided by you is true, accurate and complete; ' +
            'and that you have provided all supporting information or documentation ' +
            'which has been requested of you, or which you think may be relevant;' +
            ' <br><br>' +
            'You have read, and are compliant with, the relevant requirements as ' +
            'set out in Federal Decree Law No. (7) of 2017 on Excise Tax; and ' +
            'Federal Law No. (7) of 2017 on Tax Procedures (as applicable); ' +
            'you have read, and are compliant with, the Registration User Guides, ' +
            'which can be found in the downloads section; and that you understand your ' +
            'obligations and rights in relation to your use of the website;' +
            '<br><br>' +
            'If registering or providing on behalf of an organisation, you are ' +
            'authorised by that organisation to do so; and' +
            '<br><br>' +
            'The FTA may share any information you provide through the website ' +
            'to any relevant third-parties in accordance with ' +
            'the federal laws of the UAE or policies of the UAE government as ' +
            'updated from time to time.',
        selected: false,
        id: 1
    },
];

export const disableReceiptCopyForCategory = 'Excisable Product for Re-Export';

export const excludedFTACountryOfManufacturing = ['Europe', 'World', 'Asia', 'North America', 'Africa', 'South America'];

const checkExisting = (listOfCategories: string[], categoryName: string): boolean => {
  return !!listOfCategories.filter(category => categoryName.startsWith(category)).length;
};

export const isCategoryWithoutReceiptCopy = (categoryName: string): boolean => {
  return checkExisting([
    'Excisable Product for Re-Export',
    'Non-Excisable Product for Re-Export',
  ], categoryName);
};

export const isCategoryWithoutRetailerName = (categoryName: string): boolean => {
  return checkExisting([
    'Non-Excisable Product for Re-Export',
  ], categoryName);
};

export const isCategoryWithoutIsProductNew = (categoryName: string): boolean => {
  return checkExisting([
    'Excisable Product for Re-Export',
    'Non-Excisable Product for Re-Export',
  ], categoryName);
};

export const isCategoryWithoutIsProductNewButHasDeclarationLetter = (categoryName: string): boolean => {
  return checkExisting([
    'Non-Excisable Product for Re-Export',
  ], categoryName);
};
