import { Environment } from '.';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: Environment = {
  apiUrl: 'https://staging.brand-sync.com/api/v1/',
  GACode: 'UA-88381722-2',
  hmr: false,
  production: true,
  publicApiUrl: 'https://staging.brand-sync.com/api/public/v1/',
  storeDevtools: false,
//  sessionExpireTime: 1000 * 60 * 20,
  /* zendesk: 'http://stg-help.brand-sync.com',
  zendeskFta: 'http://stgfta.brand-sync.com',
  zendeskHost: 'https://brandsync1557821957.zendesk.com',
  zendeskFtaRecipient: 'https://stg-ftauae.brand-sync.com',
  zendeskDha: 'https://stg-dha.brand-sync.com',
  emailDhaCompany: 'healthcare+dha@gs1ae.org',
  */
  // for branch dev on stage instance	
  dhaCompanyId: 'lxWOpa6zWTM',
  //
  tatmeenCompanyId: 'l5g6blypUr5',
  sentryDSN: 'https://c9325eb062844c3dadaad4c7ac594c2b@sentry.gs1ae.org/5',
  sentryENV: 'staging',
  sentryTraceRate: 0.1,
  fta: {
    enabledHiddenAttributes: true,
    attributesToHide: [
      'isThisProductAPack',
      'numberOfUnitsPerPack',
      'childTradeItemgtin',
      'netContentOfIndividualUnit',
    ],
    defaultValuesForAttrs: {
      isThisProductAPack: 'No',
    }
  },
  enabledDirectDownload: false,
  knowledgeBaseUrls: {
        healthcare: 'https://gs1uae--stage.sandbox.my.site.com/healthcare',
        retail: 'https://gs1uae--stage.sandbox.my.site.com/retail',
        fta: 'https://gs1uae--stage.sandbox.my.site.com/FTA',
        dha: 'https://gs1uae--stage.sandbox.my.site.com/DHA',
    ftaProvider: {
        productImageRequirements: 'https://gs1uae--stage.sandbox.my.site.com/FTA/s/article/What-are-the-product-image-requirements',
        goodAndBadImageExamples: 'https://gs1uae--stage.sandbox.my.site.com/FTA/s/article/What-are-examples-of-good-and-bad-product-images',
        contactSupport: 'https://gs1uae--stage.sandbox.my.site.com/FTA/s/contactsupport',
    },
  },
  uaePassUrl: {
    url: 'https://stg-id.uaepass.ae/idshub/authorize?response_type=code',
    client_id: 'brandsync_web_stage',
    scope: 'scope=urn:uae:digitalid:profile:general',
    state: null,
    redirect_uri: 'https://staging.brand-sync.com/login',
    acr_values: 'urn:safelayer:tws:policies:authentication:level:low'
  },
  uaePassLogoutUrl: 'https://stg-id.uaepass.ae/idshub/logout?redirect_uri=https://staging.brand-sync.com/login',
};
