import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Attribute Groups]';

export const ActionTypes = {
    GET_SIMPLE_ATTRIBUTE_GROUPS_REQUEST: type(`${ACTION_PREFIX} Get Simple Attribute Groups Request`),
    GET_ATTRIBUTE_GROUPS_REQUEST: type(`${ACTION_PREFIX} Get Attribute Groups Request`),
    GET_ATTRIBUTE_GROUPS_SUCCESS: type(`${ACTION_PREFIX} Get Attribute Groups Success`),
    GET_ATTRIBUTE_GROUPS_ERROR: type(`${ACTION_PREFIX} Get Attribute Groups Error`),
    GET_ATTRIBUTE_GROUP_REQUEST: type(`${ACTION_PREFIX} Get Attribute Group Request`),
    GET_ATTRIBUTE_GROUP_SUCCESS: type(`${ACTION_PREFIX} Get Attribute Group Success`),
    GET_ATTRIBUTE_GROUP_ERROR: type(`${ACTION_PREFIX} Get Attribute Group Error`),
    NEW_ATTRIBUTE_GROUP: type(`${ACTION_PREFIX} New Attribute Group`),
    CREATE_ATTRIBUTE_GROUP_REQUEST: type(`${ACTION_PREFIX} Create Attribute Group Request`),
    CREATE_ATTRIBUTE_GROUP_SUCCESS: type(`${ACTION_PREFIX} Create Attribute Group Success`),
    CREATE_ATTRIBUTE_GROUP_ERROR: type(`${ACTION_PREFIX} Create Attribute Group Error`),
    SAVE_ATTRIBUTE_GROUP_REQUEST: type(`${ACTION_PREFIX} Save Attribute Group Request`),
    SAVE_ATTRIBUTE_GROUP_SUCCESS: type(`${ACTION_PREFIX} Save Attribute Group Success`),
    SAVE_ATTRIBUTE_GROUP_ERROR: type(`${ACTION_PREFIX} Save Attribute Group Error`),
    DELETE_ATTRIBUTE_GROUP_REQUEST: type(`${ACTION_PREFIX} Delete Attribute Group Request`),
    DELETE_ATTRIBUTE_GROUP_SUCCESS: type(`${ACTION_PREFIX} Delete Attribute Group Success`),
    DELETE_ATTRIBUTE_GROUP_ERROR: type(`${ACTION_PREFIX} Delete Attribute Group Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
};

/**
 * Create 'Get Simple Attribute Groups Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetSimpleAttributeGroupsRequestAction implements Action {
    type = ActionTypes.GET_SIMPLE_ATTRIBUTE_GROUPS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Groups Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetAttributeGroupsRequestAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_GROUPS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Groups Succes' Action
 * @param payload Contains object with attribute groups list and params
 */
export class GetAttributeGroupsSuccessAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_GROUPS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Groups Error' Action
 * @param payload Contains object with errors
 */
export class GetAttributeGroupsErrorAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_GROUPS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Group Request' Action
 * @param payload Contains attribute group id
 */
export class GetAttributeGroupRequestAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Group Success' Action
 * @param payload Contains object with attribute group data
 */
export class GetAttributeGroupSuccessAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Attribute Group Error' Action
 * @param payload Contains object with errors
 */
export class GetAttributeGroupErrorAction implements Action {
    type = ActionTypes.GET_ATTRIBUTE_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New Attribute Group' Action
 */
export class NewAttributeGroupAction implements Action {
    type = ActionTypes.NEW_ATTRIBUTE_GROUP;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Attribute Group Request' Action
 * @param payload Contains object with attribute data
 */
export class CreateAttributeGroupRequestAction implements Action {
    type = ActionTypes.CREATE_ATTRIBUTE_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Attribute Group Succes' Action
 * @param payload Contains object with attribute data
 */
export class CreateAttributeGroupSuccessAction implements Action {
    type = ActionTypes.CREATE_ATTRIBUTE_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Attribute Group Error' Action
 * @param payload Contains object with errors
 */
export class CreateAttributeGroupErrorAction implements Action {
    type = ActionTypes.CREATE_ATTRIBUTE_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Group Request' Action
 * @param payload Contains object with attribute group data
 */
export class SaveAttributeGroupRequestAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Group Succes' Action
 * @param payload Contains object with attribute data
 */
export class SaveAttributeGroupSuccessAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save Attribute Error' Action
 * @param payload Contains object with errors
 */
export class SaveAttributeGroupErrorAction implements Action {
    type = ActionTypes.SAVE_ATTRIBUTE_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attribute Group Request' Action
 * @param payload Contains attribute group id
 */
export class DeleteAttributeGroupRequestAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTE_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attribute Group Succes' Action
 */
export class DeleteAttributeGroupSuccessAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTE_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Attribute Group Error' Action
 * @param payload Contains object with errors
 */
export class DeleteAttributeGroupErrorAction implements Action {
    type = ActionTypes.DELETE_ATTRIBUTE_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetSimpleAttributeGroupsRequestAction
    | GetAttributeGroupsRequestAction
    | GetAttributeGroupsSuccessAction
    | GetAttributeGroupsErrorAction
    | GetAttributeGroupRequestAction
    | GetAttributeGroupSuccessAction
    | GetAttributeGroupErrorAction
    | NewAttributeGroupAction
    | CreateAttributeGroupRequestAction
    | CreateAttributeGroupSuccessAction
    | CreateAttributeGroupErrorAction
    | SaveAttributeGroupRequestAction
    | SaveAttributeGroupSuccessAction
    | SaveAttributeGroupErrorAction
    | DeleteAttributeGroupRequestAction
    | DeleteAttributeGroupSuccessAction
    | DeleteAttributeGroupErrorAction
    | ClearListAction
    | ClearManageAction;
