import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { debounceTime, filter } from 'rxjs/operators';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';

@Component({
    selector: 'form-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['form-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormInputComponent),
            multi: true,
        },
    ],
})
export class FormInputComponent implements ControlValueAccessor, OnDestroy {
    private onModelChange: any;
    private onTouch: any;

    private inputChange: Subject<any> = new Subject();

    public value: any = '';

    registerOnChange(fn) {
        this.onModelChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouch = fn;
    }

    writeValue(val: any) {
        if (val) {
            this.value = val;
        }
    }

    @Input() label: string = '';
    @Input() required: boolean = false;
    @Input() isValid: boolean = false;
    @Input() isTouched: boolean = false;
    @Input() type: 'text' | 'telephone' = 'text';
    @Input() control?: any;
    @Input() errors: string[] = [];
    @Input() successMessage: string = '';
    @Input() errorMessage: string = '';
    @Input() readonly: boolean = false;
    @Input() placeholder: string = '';
    @Input() columnWidth: number = 4;

    @Output() changes: EventEmitter<any> = new EventEmitter();

    constructor() {
        this.inputChange
            .pipe(
                debounceTime(500),
                filter(() => this.isValid),
            )
            .subscribe((val) => this.changes.emit(this.value));
    }

    onInputChange(event: any): void {
        this.value = event.target.value || '';
        this.onModelChange(this.value);
        this.onTouch();

        this.inputChange.next(this.value);
    }

    onTelephoneChange({ value, error }) {
        this.value = value || '';
        this.isValid = !error;
    }

    ngOnDestroy(): void {
        this.inputChange.unsubscribe();
    }
}
