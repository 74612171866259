import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as reducer from '@core/reducers/company-data-requirements.reducer';

@Injectable()
export class CompanyDataRequirementsSelectors {
    /**
     * Get state.companyDataRequirements
     */
    getCompanyDataRequirementsState() {
        return ($state: State): any => $state.companyDataRequirements;
    }

    /**
     * Get list from state.companyDataRequirements
     */
    getList() {
        return createSelector(
            this.getCompanyDataRequirementsState(),
            reducer.getList,
        );
    }

    /**
     * Get manage from state.companyDataRequirements
     */
    getManage() {
        return createSelector(
            this.getCompanyDataRequirementsState(),
            reducer.getManage,
        );
    }

    /**
     * Get available attributes from state.companyDataRequirements
     */
    getAvailableAttributes() {
        return createSelector(
            this.getCompanyDataRequirementsState(),
            reducer.getAvailableAttributes,
        );
    }

    /**
     * Get summary from state.companyDataRequirements
     */
    getSummary() {
        return createSelector(
            this.getCompanyDataRequirementsState(),
            reducer.getSummary,
        );
    }

    /**
     * Get history logs from state.companyDataRequirements
     */
    getHistoryLogs() {
        return createSelector(
            this.getCompanyDataRequirementsState(),
            reducer.getHistoryLogs,
        );
    }
}
