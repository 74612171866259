import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromPartnerships from '../reducers/partnerships.reducer';

@Injectable()
export class PartnershipsSelectors {
    /**
     * Get state.workflows
     */
    getPartnershipState() {
        return ($state: State): any => $state.partnerships;
    }

    getList() {
        return createSelector(
            this.getPartnershipState(),
            fromPartnerships.getList,
        );
    }

    getManage() {
        return createSelector(
            this.getPartnershipState(),
            fromPartnerships.getManage,
        );
    }
}
