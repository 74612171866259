import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';

import { AppTitleService } from '@core/services/app-title.service';

import { PaginationEvent, SelectOption } from '@core/models';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {
    @Input() offset: number;
    @Input() limit: number;
    @Input() count: number;
    @Input() isFetching?: boolean = false;
    @Output() paginationChange: EventEmitter<PaginationEvent> = new EventEmitter();

    pageInputKeySubscription: Subscription;

    currentPage: number = 1;
    totalPages: number = 1;
    pageInput: number = 1;

    availableLimits = [
        {
            value: 10,
            displayName: '10',
        },
        {
            value: 20,
            displayName: '20',
        },
        {
            value: 50,
            displayName: '50',
        },
        {
            value: 100,
            displayName: '100',
        },
        {
            value: 250,
            displayName: '250',
        },
    ];

    /**
     * Component constructor function
     */
    constructor(private el: ElementRef, private titleService: AppTitleService) {}

    /**
     * Create pageInputKeyUp stream from key up event and subscribe to it
     */
    ngOnInit() {
        this.pageInputKeySubscription = observableFromEvent(this.el.nativeElement.querySelector('.input'), 'keyup')
            .pipe(debounceTime(250))
            .subscribe((event) => this.onPageInputKeyUp(event));
    }

    /**
     * Validate inputs - if params are invalid, emit updated values
     * @param offset List offset
     * @param limit List limit
     * @param count List count
     * @returns Return true if query params are valid, otherwise return false
     */
    validateInputs(offset: number, limit: number, count: number): boolean {
        // Return false if at least one of the arguments is null
        if (this.offset === null || this.limit === null || this.count === null) {
            return;
        }

        if (
            offset < 0 ||
            offset > count ||
            !this.availableLimits.filter((standardLimit) => standardLimit.value === limit).length
        ) {
            this.paginationChange.emit({ offset: 0, limit: 20 });
            return false;
        } else {
            return true;
        }
    }

    /**
     * Get current page, total pages and page input
     * @param offset List offset
     * @param limit List limit
     * @param count List count
     */
    getPages(offset: number, limit: number, count: number): void {
        this.currentPage = this.getCurrentPage(offset, limit);
        this.totalPages = this.getTotalPages(count, limit);
        this.pageInput = this.currentPage;

        this.titleService.setPageNumber(this.currentPage);
    }

    /**
     * Get current page
     * @param offset List offset
     * @param limit List limit
     * @returns Current page number
     */
    getCurrentPage(offset: number, limit: number): number {
        return Math.floor(offset / limit) + 1;
    }

    /**
     * Get total pages
     * @param count List count
     * @param limit List limit
     * @returns Total pages number
     */
    getTotalPages(count: number, limit: number): number {
        return Math.max(Math.ceil(count / limit), 1);
    }

    /**
     * Change page - if page is valid, emit updated values
     * @param page Selected page number
     */
    selectPage(page: number): void {
        if (!this.isValidPageNumber(page, this.totalPages)) {
            return;
        }

        this.paginationChange.emit({ offset: (page - 1) * this.limit, limit: this.limit });
    }

    /**
     * Handle key up event on page input - if page is number and is valid, emit updated values
     * @param page Selected page number
     */
    onPageInputKeyUp(event: any): void {
        if (!event.target.value.length) {
            return;
        } else if (!this.isNumber(event.target.value) || !this.isValidPageNumber(event.target.value, this.totalPages)) {
            this.pageInput = this.currentPage;
            return;
        }

        this.paginationChange.emit({ offset: (event.target.value - 1) * this.limit, limit: this.limit });
    }

    /**
     * Handle change event on limit select - emit updated values
     * @param page Selected page number
     */
    onLimitSelectChange(selectedLimit: SelectOption): void {
        this.paginationChange.emit({ offset: 0, limit: selectedLimit.value as number });
    }

    /**
     * Check if valid page
     * @param number
     * @returns boolean
     */
    isValidPageNumber(page: number, totalPages: number): boolean {
        return page > 0 && page <= totalPages;
    }

    /**
     * Check if number
     * @param number
     * @returns boolean
     */
    isNumber(number: any): boolean {
        return !isNaN(parseFloat(number)) && isFinite(number);
    }

    /**
     * Handle init and change query params
     */
    ngOnChanges(): void {
        // Calculate pages only if provided inputs are valid
        if (this.validateInputs(this.offset, this.limit, this.count)) {
            this.getPages(this.offset, this.limit, this.count);
        }
    }

    /**
     * Unsubscribe from pageInputKeyUp stream
     */
    ngOnDestroy(): void {
        this.pageInputKeySubscription.unsubscribe();
    }
}
