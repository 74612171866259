import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

import { BulkProductReview } from '@core/models';

export const ActionTypes = prepareActions('[Product Reviews]', [
    'POST_PRODUCT_REVIEW_REQUEST',
    'POST_PRODUCT_REVIEW_SUCCESS',
    'POST_PRODUCT_REVIEW_ERROR',

    'POST_BULK_PRODUCTS_REVIEW_REQUEST',
    'POST_BULK_PRODUCTS_REVIEW_SUCCESS',
    'POST_BULK_PRODUCTS_REVIEW_ERROR',

    'CLEAR_PRODUCTS_REVIEWS',

    'GET_BULK_REVIEW_STATUS_REQUEST',
    'GET_BULK_REVIEW_STATUS_SUCCESS',
    'GET_BULK_REVIEW_STATUS_ERROR',

    'CLEAR_BULK_ACTION_STATUS',

    'GET_REJECTION_REASONS_REQUEST',
    'GET_REJECTION_REASONS_SUCCESS',
    'GET_REJECTION_REASONS_ERROR',
    'CLEAR_REJECTION_REASONS',
]);

export class PostProductReviewRequestAction implements Action {
    readonly type = ActionTypes.POST_PRODUCT_REVIEW_REQUEST;
    constructor(public payload?: any) {}
}
export class PostProductReviewSuccessAction implements Action {
    readonly type = ActionTypes.POST_PRODUCT_REVIEW_SUCCESS;
    constructor(public payload?: any) {}
}
export class PostProductReviewErrorAction implements Action {
    readonly type = ActionTypes.POST_PRODUCT_REVIEW_ERROR;
    constructor(public payload?: any) {}
}

export class PostBulkProductsReviewRequestAction implements Action {
    readonly type = ActionTypes.POST_BULK_PRODUCTS_REVIEW_REQUEST;
    constructor(public payload: BulkProductReview) {}
}
export class PostBulkProductsReviewSuccessAction implements Action {
    readonly type = ActionTypes.POST_BULK_PRODUCTS_REVIEW_SUCCESS;
    constructor(public payload?: any) {}
}
export class PostBulkProductsReviewErrorAction implements Action {
    readonly type = ActionTypes.POST_BULK_PRODUCTS_REVIEW_ERROR;
    constructor(public payload?: any) {}
}

export class ClearProductsReviewsAction implements Action {
    readonly type = ActionTypes.CLEAR_PRODUCTS_REVIEWS;
    constructor(public payload?: any) {}
}

export class GetBulkReviewStatusRequestAction implements Action {
    readonly type = ActionTypes.GET_BULK_REVIEW_STATUS_REQUEST;
    constructor(public payload?: any) {}
}
export class GetBulkReviewStatusSuccessAction implements Action {
    readonly type = ActionTypes.GET_BULK_REVIEW_STATUS_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetBulkReviewStatusErrorAction implements Action {
    readonly type = ActionTypes.GET_BULK_REVIEW_STATUS_ERROR;
    constructor(public payload?: any) {}
}

export class ClearBulkActionStatus implements Action {
    readonly type = ActionTypes.CLEAR_BULK_ACTION_STATUS;
    constructor(public payload?: never) {}
}

export class GetRejectionReasonsRequest implements Action {
    readonly type = ActionTypes.GET_REJECTION_REASONS_REQUEST;
    constructor(public payload?: any) {}
}
export class GetRejectionReasonsSuccess implements Action {
    readonly type = ActionTypes.GET_REJECTION_REASONS_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetRejectionReasonsError implements Action {
    readonly type = ActionTypes.GET_REJECTION_REASONS_ERROR;
    constructor(public payload?: any) {}
}
export class ClearRejectionReasons implements Action {
    readonly type = ActionTypes.CLEAR_REJECTION_REASONS;
    constructor(public payload?: never) {}
}

export type Actions =
    | PostProductReviewRequestAction
    | PostProductReviewSuccessAction
    | PostProductReviewErrorAction
    | PostBulkProductsReviewRequestAction
    | PostBulkProductsReviewSuccessAction
    | PostBulkProductsReviewErrorAction
    | ClearProductsReviewsAction
    | GetBulkReviewStatusRequestAction
    | GetBulkReviewStatusSuccessAction
    | GetBulkReviewStatusErrorAction
    | ClearBulkActionStatus
    | GetRejectionReasonsRequest
    | GetRejectionReasonsSuccess
    | GetRejectionReasonsError
    | ClearRejectionReasons;
