import { Component } from '@angular/core';

@Component({
    selector: 'inactivity-logout-modal',
    templateUrl: './inactivity-logout-modal.component.html',
    styles: [
        `
            p {
                text-align: center;
            }
        `,
    ],
})
export class InactivityLogoutModalComponent {}
