<div *ngIf="!isEmptyObject(attributesDiff) || !!errors.length; else loading" class="products-attributes-diff-modal">
    <div *ngIf="!errors.length" class="products-attributes-diff-modal__text">
      You are about to change Product's {{ isFtaCompany ? 'Registration Type' : 'Category' }}.
      Some of attributes data would be lost due to inconsistency between changed categories templates.
      <ng-container *ngIf="isLive()" >Also product status will be changed to draft.</ng-container>
      Are you sure you want proceed?
    </div>

    <ng-container *ngIf="!errors.length; else listErrors">
        <div *ngIf="attributesDiff.attributeGroups?.length; else noDifferences">
            <div class="products-attributes-diff-modal__attribute-list-title">Lost attributes data:</div>
            <div *ngFor="let attributeGroup of attributesDiff.attributeGroups">
                <h3
                    class="form__section-title--margin-top-32 section-title section-title--expandable"
                    [ngClass]="{'section-title--is-visible': sections[attributeGroup.id]}"
                    (click)="sections[attributeGroup.id] = !sections[attributeGroup.id]"
                >
                    {{ attributeGroup.name }}
                    <span class="section-title__meta">
                        {{ sections[attributeGroup.id] ? 'Click to collapse' : 'Click to expand' }}
                    </span>
                </h3>
                <ng-container
                    *ngIf="sections[attributeGroup.id]"
                    class="container"
                    @slideHeight
                >
                    <div
                        *ngFor="let attribute of attributeGroup.attributes"
                        class="display-attribute__name products-attributes-diff-modal__attribute-name"
                    >
                        {{ attribute.name }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div
            *ngIf="isFtaCompany && attributesDiff.pricesLost"
            class="products-attributes-diff-modal__attribute-list-title"
        >
            Product Price Information will be lost:
            <div
                class="display-attribute__name products-attributes-diff-modal__attribute-name"
            >
                Due to category change all information in price tab will be cleared out
            </div>
        </div>
    </ng-container>
    <ng-template #noDifferences>
        <div class="products-attributes-diff-modal__no-differences">
            None of the filled in attributes will be lost.
        </div>
    </ng-template>
    <ng-template #listErrors>
        <div *ngFor="let error of errors">
            {{ error }}
        </div>
    </ng-template>

    <div *ngIf="!errors.length" class="products-attributes-diff-modal__actions">
        <a (click)="cancelClick()" class="btn">No</a>
        <a (click)="confirmClick()" class="btn btn--alert">Yes</a>
    </div>
</div>
<ng-template #loading>
    <div class="modal__loader-container">
        <div class="loading"></div>
    </div>
</ng-template>
