import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[User Groups]';

export const ActionTypes = {
    GET_USER_GROUPS_REQUEST: type(`${ACTION_PREFIX} Get User Groups Request`),
    GET_USER_GROUPS_SUCCESS: type(`${ACTION_PREFIX} Get User Groups Success`),
    GET_USER_GROUPS_ERROR: type(`${ACTION_PREFIX} Get User Groups Error`),
    GET_USER_GROUP_REQUEST: type(`${ACTION_PREFIX} Get User Group Request`),
    GET_USER_GROUP_SUCCESS: type(`${ACTION_PREFIX} Get User Group Success`),
    GET_USER_GROUP_ERROR: type(`${ACTION_PREFIX} Get User Group Error`),
    NEW_USER_GROUP: type(`${ACTION_PREFIX} New User Group`),
    CREATE_USER_GROUP_REQUEST: type(`${ACTION_PREFIX} Create User Group Request`),
    CREATE_USER_GROUP_SUCCESS: type(`${ACTION_PREFIX} Create User Group Success`),
    CREATE_USER_GROUP_ERROR: type(`${ACTION_PREFIX} Create User Group Error`),
    SAVE_USER_GROUP_REQUEST: type(`${ACTION_PREFIX} Save User Group Request`),
    SAVE_USER_GROUP_SUCCESS: type(`${ACTION_PREFIX} Save User Group Success`),
    SAVE_USER_GROUP_ERROR: type(`${ACTION_PREFIX} Save User Group Error`),
    DELETE_USER_GROUP_REQUEST: type(`${ACTION_PREFIX} Delete User Group Request`),
    DELETE_USER_GROUP_SUCCESS: type(`${ACTION_PREFIX} Delete User Group Success`),
    DELETE_USER_GROUP_ERROR: type(`${ACTION_PREFIX} Delete User Group Error`),
    GET_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Options Request`),
    GET_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Options Success`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
    CLEAR_OPTIONS: type(`${ACTION_PREFIX} Clear Options`),
};

/**
 * Create 'Get User Groups Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetUserGroupsRequestAction implements Action {
    type = ActionTypes.GET_USER_GROUPS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Groups Success' Action
 * @param payload Contains object with user groups list and params
 */
export class GetUserGroupsSuccessAction implements Action {
    type = ActionTypes.GET_USER_GROUPS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Groups Error' Action
 * @param payload Contains object with errors
 */
export class GetUserGroupsErrorAction implements Action {
    type = ActionTypes.GET_USER_GROUPS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Group Request' Action
 * @param payload Contains user group id
 */
export class GetUserGroupRequestAction implements Action {
    type = ActionTypes.GET_USER_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Group Success' Action
 * @param payload Contains object with user group data
 */
export class GetUserGroupSuccessAction implements Action {
    type = ActionTypes.GET_USER_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Group Error' Action
 * @param payload Contains object with errors
 */
export class GetUserGroupErrorAction implements Action {
    type = ActionTypes.GET_USER_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'New User Group' Action
 */
export class NewUserGroupAction implements Action {
    type = ActionTypes.NEW_USER_GROUP;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create User Group Request' Action
 * @param payload Contains object with user group data
 */
export class CreateUserGroupRequestAction implements Action {
    type = ActionTypes.CREATE_USER_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create User Group Success' Action
 * @param payload Contains object with user group data
 */
export class CreateUserGroupSuccessAction implements Action {
    type = ActionTypes.CREATE_USER_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create User Group Error' Action
 * @param payload Contains object with errors
 */
export class CreateUserGroupErrorAction implements Action {
    type = ActionTypes.CREATE_USER_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save User Group Request' Action
 * @param payload Contains object with user group data
 */
export class SaveUserGroupRequestAction implements Action {
    type = ActionTypes.SAVE_USER_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save User Group Success' Action
 * @param payload Contains object with user group data
 */
export class SaveUserGroupSuccessAction implements Action {
    type = ActionTypes.SAVE_USER_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Save User Group Error' Action
 * @param payload Contains object with errors
 */
export class SaveUserGroupErrorAction implements Action {
    type = ActionTypes.SAVE_USER_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete User Group Request' Action
 * @param payload Contains user group id
 */
export class DeleteUserGroupRequestAction implements Action {
    type = ActionTypes.DELETE_USER_GROUP_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete User Group Success' Action
 */
export class DeleteUserGroupSuccessAction implements Action {
    type = ActionTypes.DELETE_USER_GROUP_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete User Group Error' Action
 * @param payload Contains object with errors
 */
export class DeleteUserGroupErrorAction implements Action {
    type = ActionTypes.DELETE_USER_GROUP_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' Action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' Action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetUserGroupsRequestAction
    | GetUserGroupsSuccessAction
    | GetUserGroupsErrorAction
    | GetUserGroupRequestAction
    | GetUserGroupSuccessAction
    | GetUserGroupErrorAction
    | NewUserGroupAction
    | CreateUserGroupRequestAction
    | CreateUserGroupSuccessAction
    | CreateUserGroupErrorAction
    | SaveUserGroupRequestAction
    | SaveUserGroupSuccessAction
    | SaveUserGroupErrorAction
    | DeleteUserGroupRequestAction
    | DeleteUserGroupSuccessAction
    | DeleteUserGroupErrorAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | ClearListAction
    | ClearManageAction
    | ClearOptionsAction;
