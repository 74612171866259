import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';

import { MapsAPILoader } from '@agm/core';
import { AddressAutocompleteEvent } from '../../../core/models';

declare var google: any;

@Component({
    selector: 'address-autocomplete',
    templateUrl: './address-autocomplete.component.html',
})
export class AddressAutocompleteComponent implements OnInit {
    @Input() readonly: boolean;
    @Output() placeChange: EventEmitter<AddressAutocompleteEvent> = new EventEmitter();
    @ViewChild('search', { static: true }) public searchElement: ElementRef;

    autocomplete: any;

    /**
     * Component constructor function
     */
    constructor(private mapsAPILoader: MapsAPILoader, private zone: NgZone) {}

    /**
     * Wait until Google Maps API is loaded,
     * init autocomplete and add listener for prepare output
     */
    ngOnInit(): void {
        this.mapsAPILoader.load().then(() => {
            this.autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
                types: ['address'],
            });

            this.autocomplete.addListener('place_changed', () => this.prepareOutput());
        });
    }

    /**
     * Prepare output based on API response
     */
    prepareOutput(): void {
        this.zone.run(() => {
            const place = this.autocomplete.getPlace();

            // Initial addressValue
            let addressValue: AddressAutocompleteEvent = {
                address: '',
                city: '',
                country: '',
                postalCode: '',
            };

            if (place.address_components) {
                addressValue = place.address_components.reduce((address, addressComponent) => {
                    return this.setAddressValue({ ...address }, addressComponent);
                }, addressValue);
            }

            this.placeChange.emit(addressValue);
        });
    }

    /**
     * Set address value
     *
     * @param address Object that contains address information
     * @param addressComponent Object that contains portion of address information
     * @returns Address autocomplete event
     */
    setAddressValue(address: AddressAutocompleteEvent, addressComponent: any): AddressAutocompleteEvent {
        if (addressComponent.types.includes('street_number')) {
            address.address += addressComponent.long_name;
        }
        if (addressComponent.types.includes('route')) {
            address.address = addressComponent.long_name + ' ' + address.address;
        }
        if (addressComponent.types.includes('locality')) {
            address.city = addressComponent.long_name;
        }
        if (addressComponent.types.includes('country')) {
            address.country = addressComponent.short_name;
        }
        if (addressComponent.types.includes('postal_code')) {
            address.postalCode = addressComponent.long_name;
        }

        return address;
    }
}
