import { Component, Input } from '@angular/core';

export interface DataSummaryBoxCell {
    value: string | number;
    info?: string;
    isCountUp?: boolean;
}

export interface DataSummaryBoxItem {
    isBold?: boolean;
    isHeading?: boolean;
    cells: DataSummaryBoxCell[];
}

export interface DataSummaryBox {
    isAnimatedReady: boolean;
    items: DataSummaryBoxItem[];
    title: string;
}

@Component({
    selector: 'data-summary-box',
    styleUrls: ['data-summary-box.component.scss'],
    templateUrl: 'data-summary-box.component.html',
})
export class DataSummaryBoxComponent {
    @Input() data: DataSummaryBox;

    convertToInt(value: any): number {
        return parseInt(value) || 0;
    }
}
