import * as ingredientsActions from '@core/actions/ingredients.actions';

import { IngredientsList } from '@core/models';

import { listInitialState } from '@core/reducers/utils';

export interface State {
    list: IngredientsList;
}

const initialState: State = {
    list: listInitialState,
};

export function reducer(state = initialState, action: ingredientsActions.Actions): State {
    switch (action.type) {
        case ingredientsActions.ActionTypes.GET_INGREDIENTS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case ingredientsActions.ActionTypes.GET_INGREDIENTS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case ingredientsActions.ActionTypes.GET_INGREDIENTS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case ingredientsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;
