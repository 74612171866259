<div class="password-strength-indicator">
  <div class="password-strength-indicator__bars-container">
    <div
      *ngFor="let bar of cases; let i = index"
      class="password-strength-indicator__bar"
      [ngClass]="{
        'password-strength-indicator__bar--short': score > i && score === passwordStrengthVariant.POOR,
        'password-strength-indicator__bar--weak': score > i && score === passwordStrengthVariant.WEAK,
        'password-strength-indicator__bar--fair': score > i && score === passwordStrengthVariant.FAIR,
        'password-strength-indicator__bar--good': score > i && score === passwordStrengthVariant.GOOD,
        'password-strength-indicator__bar--strong': score > i && score === passwordStrengthVariant.STRONG
      }"
    ></div>
  </div>
  <ng-content></ng-content>
</div>
