<table
  class="table"
  [ngClass]="{
    'table--border': table.border,
    'table--ellipsis': table.ellipsis,
    'table--hover': table.hover,
    'table--center': table.align === 'center',
    'table--right': table.align === 'right',
    'table--small-text': table.smallText,
    'table--loading': isFetching
  }">
  <thead class="table__head">
    <tr class="table__row">
      <th
        *ngFor="let column of columns"
        class="table__cell-header"
        [ngClass]="{
          'table__cell-header--left': column.align === 'left',
          'table__cell-header--center': column.align === 'center',
          'table__cell-header--right': column.align === 'right',
          'table__cell-header--ellipsis': !table.ellipsis && column.ellipsis,
          'table__cell-header--clear-ellipsis': table.ellipsis && !column.ellipsis,
          'table__cell-header--sortable': column.sortable,
          'table__cell-header--no-padding table__cell-header--zero-width': column.zeroWidth,
          'table__cell-header--hidden': !table.header,
          'table__cell-header--sticky-top': column.stickiness && column.stickiness.includes('top'),
          'table__cell-header--sticky-left': column.stickiness && column.stickiness.includes('left')
        }"
        (click)="onTableSortingChange(column)"
        [style.width]="column.width"
        [style.min-width]="column.minWidth"
        [style.left.px]="column.left"
      >
        <span
          *ngIf="table.header"
          class="table__cell-title"
          [ngClass]="{
            'table__cell-title--sortable': column.sortable,
            'table__cell-title--ascending': column.label === sorting.sortBy &&
              sorting.ordering === ORDER.ASCENDING,
            'table__cell-title--descending': column.label === sorting.sortBy &&
              sorting.ordering === ORDER.DESCENDING
          }">
          {{ setFtaHeader(column.displayName) }}
        </span>

        <span
          *ngIf="column.point"
          class="table__pointer"
        ></span>

        <label
          *ngIf="column.isBulkCheckbox"
          class="checkbox checkbox--without-text">
          <input
            type="checkbox"
            class="checkbox__input"
            [checked]="bulkCheckboxChecked"
            (click)="clickBulkCheckbox($event)">
          <span class="checkbox__label">
            <span class="checkbox__text"></span>
          </span>
        </label>
      </th>
    </tr>

    <tr class="table__row">
      <div
        class="table__loading"
        [ngClass]="{'table__loading--show': isFetching}">
      </div>
    </tr>
  </thead>

  <ng-content></ng-content>
</table>
