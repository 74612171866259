import * as companyDomainsActions from '../actions/company-domains.actions';
import { CompanyDomainsList, CompanyDomainsManage, CompanyDomainsOptions } from '../models';

export interface State {
    list: CompanyDomainsList;
    manage: CompanyDomainsManage;
    options: CompanyDomainsOptions;
}

const initialState: State = {
    list: {
        data: {
            results: [],
            next: null,
            previous: null,
            count: null,
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            id: null,
            company: null,
            name: '',
            position: null,
        },
        errors: {},
        isFetching: true,
        isSaving: false,
    },
    options: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state: State = initialState, action: companyDomainsActions.Actions): State {
    switch (action.type) {
        case companyDomainsActions.ActionTypes.GET_COMPANY_DOMAINS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case companyDomainsActions.ActionTypes.GET_COMPANY_DOMAINS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.GET_COMPANY_DOMAINS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.GET_COMPANY_DOMAIN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: true,
                    isSaving: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.GET_COMPANY_DOMAIN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.GET_COMPANY_DOMAIN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.NEW_COMPANY_DOMAIN: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isFetching: false,
                    isSaving: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.CREATE_COMPANY_DOMAIN_REQUEST:
        case companyDomainsActions.ActionTypes.DELETE_COMPANY_DOMAIN_REQUEST:
        case companyDomainsActions.ActionTypes.SAVE_COMPANY_DOMAIN_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case companyDomainsActions.ActionTypes.CREATE_COMPANY_DOMAIN_SUCCESS:
        case companyDomainsActions.ActionTypes.SAVE_COMPANY_DOMAIN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.CREATE_COMPANY_DOMAIN_ERROR:
        case companyDomainsActions.ActionTypes.DELETE_COMPANY_DOMAIN_ERROR:
        case companyDomainsActions.ActionTypes.SAVE_COMPANY_DOMAIN_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.DELETE_COMPANY_DOMAIN_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case companyDomainsActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: true,
                },
            };
        }

        case companyDomainsActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case companyDomainsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case companyDomainsActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        case companyDomainsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;

export const getManage = (state: State) => state.manage;

export const getOptions = (state: State) => state.options;
