<div class="filters" [formGroup]="filtersForm">
  <div class="filters__content-wrapper">
    <div class="filters__content">
      <div class="form__row">
        <div class="form__group form__group--cols-4">
          <label class="form__label label label--no-capitalize" for="">Search by date range:</label>
          <div class="table__date-range">
            <my-date-picker
              [options]="{
                openSelectorOnInputClick: true,
                editableDateField: false,
                showClearDateBtn: true,
                showSelectorArrow: false,
                editableMonthAndYear: false,
                fillWidth: true,
                textDateFormat: 'yyyy/mm/dd',
                disableSince: updateDate(filtersForm.value.modified__lte, 'add', 1, 'days')
              }"
              [placeholder]="'Start date'"
              (calendarToggle)="onCalendarToggle($event)"
              formControlName="created__gte">
            </my-date-picker>
            <span class="table__spacer">-</span>
            <my-date-picker
              [options]="{
                openSelectorOnInputClick: true,
                editableDateField: false,
                showClearDateBtn: true,
                showSelectorArrow: false,
                editableMonthAndYear: false,
                fillWidth: true,
                textDateFormat: 'yyyy/mm/dd',
                disableUntil: updateDate(filtersForm.value.modified__gte, 'subtract', 1, 'days')
              }"
              [placeholder]="'End date'"
              (calendarToggle)="onCalendarToggle($event)"
              formControlName="created__lte">
            </my-date-picker>
          </div>
        </div>
        <div
          *ngIf="localUser.data.company.isStaff"
          class="form__group form__group--cols-4">
          <label class="form__label label label--no-capitalize" for="">Search by Company name:</label>
          <input formControlName="company" type="text" placeholder="Enter value" class="input input--fluid">
        </div>
        <div
          *ngIf="localUser.data.company.isStaff"
          class="form__group form__group--cols-4">
          <label class="form__label label label--no-capitalize" for="">Search by User email:</label>
          <input formControlName="user" type="text" placeholder="Enter value" class="input input--fluid">
        </div>
      </div>
    </div>
  </div>
</div>
