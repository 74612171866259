import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SharedProductsRaportResponse } from '@companies/companies.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PROTECTED_URLS } from '../../../urls.conf';

import {
    CompanyId,
    DataRequirementId,
    PartnerDataRequirementChangesPayload,
    PartnerDataRequirementsParams,
    PartnerDataRequirementsPayload,
} from '@core/models';

const BASE_URL: string = 'companies';

@Injectable()
export class CompaniesService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get companies list
     * @param params Object with list params
     * @returns Backend API response
     */
    getCompanies(params: any = {}): Observable<object> {
        return this.httpClient.get<any>(`${BASE_URL}/`, { params });
    }

    /**
     * Get company
     * @param id Company ID
     * @returns Backend API response
     */
    getCompany(id: string): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/${id}/`);
    }

    /**
     * Create company
     * @param atribiute Object with company data needed to create company
     * @returns Backend API response
     */
    createCompany(company: any): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/`, { ...company.data.form });
    }

    /**
     * Save company
     * @param company Object with company ID and company data needed to save company
     * @returns Backend API response
     */
    saveCompany(company: any): Observable<Object> {
        return this.httpClient.patch<any>(`${BASE_URL}/${company.id}/`, { ...company.data.form });
    }

    /**
     * Upload company logo
     * @param atribiute Object with company data needed to create company
     * @returns Backend API response
     */
    uploadCompanyLogo(company: any): Observable<any> {
        return this.httpClient.patch<any>(`${BASE_URL}/${company.id}/`, company.data.logo);
    }

    /**
     * Delete company
     * @param id Company ID
     * @returns Backend API response
     */
    deleteCompany(id: number): Observable<Object> {
        return this.httpClient.delete<any>(`${BASE_URL}/${id}/`);
    }

    /**
     * Get options companies request
     * @returns Backend API response
     */
    getOptions(): Observable<Object> {
        return this.httpClient.options<any>(`${BASE_URL}/`);
    }

    /**
     * Get company partner data requirements
     * @returns Backend API response
     */
    getPartnerDataRequirements(
        companyId: number,
        params: Partial<PartnerDataRequirementsParams> = {},
    ): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/${companyId}/${PROTECTED_URLS.PARTNER_DATA_REQUIREMENTS}`, {
            params,
        } as Params);
    }

    /**
     * Get company partner data requirement
     * @returns Backend API response
     */
    getPartnerDataRequirement(companyId: number, requirementId: DataRequirementId): Observable<Object> {
        return this.httpClient.get<any>(
            `${BASE_URL}/${companyId}/${PROTECTED_URLS.PARTNER_DATA_REQUIREMENTS}${requirementId}/`,
        );
    }

    /**
     * Bulk create company partner sharing presets
     * @param payload Object with requirements ids
     * @returns Backend API response
     */
    bulkCreatePartnerSharingPresets(companyId: CompanyId, payload: any): Observable<Object> {
        return this.httpClient.post<any>(
            `${BASE_URL}/${companyId}/${PROTECTED_URLS.PARTNER_DATA_REQUIREMENTS_CREATE}`,
            { payload },
        );
    }

    /**
     * Get partner data requirements summary
     * @param payload Object with company id and partner id
     * @returns Backend API response
     */
    getPartnerDataRequirementsSummary(payload: PartnerDataRequirementsPayload): Observable<Object> {
        return this.httpClient.post<any>(
            `${BASE_URL}/${payload.company}/${PROTECTED_URLS.PARTNER_DATA_REQUIREMENTS_STATS}`,
            { company: payload.partner },
        );
    }

    /**
     * Get partner data requirements changes notifications
     * @param payload Object with company id and partner id
     * @returns Backend API response
     */
    getPartnerDataRequirementsChangesNotifications(payload: PartnerDataRequirementsPayload): Observable<Object> {
        return this.httpClient.post<any>(
            `${BASE_URL}/${payload.company}/${PROTECTED_URLS.PARTNER_DATA_REQUIREMENTS_GET_NOTIFICATIONS}`,
            { company: payload.partner },
        );
    }

    /**
     * Apply partner data requirements changes notifications
     * @param payload Object with company id and partner id
     * @returns Backend API response
     */
    applyPartnerDataRequirementsChangesNotifications(
        payload: PartnerDataRequirementChangesPayload,
    ): Observable<Object> {
        return this.httpClient.post<any>(
            `${BASE_URL}/${payload.company}/${PROTECTED_URLS.PARTNER_DATA_REQUIREMENTS_CLEAR_NOTIFICATIONS}`,
            { company: payload.partner },
        );
    }

    /**
     * Get partner data overview
     * @param payload Object with company id and partner id
     * @returns Backend API response
     */
    getPartnerOverview(payload: PartnerDataRequirementsPayload): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/${payload.company}/${PROTECTED_URLS.PARTNER_OVERVIEWS}`, {
            payload,
        } as Params);
    }

    getPartnersSharedProductsRaport(companyId: CompanyId): Observable<SharedProductsRaportResponse> {
        return this.httpClient.get(
            `${BASE_URL}/${companyId}/${PROTECTED_URLS.COMPANY_PARTNERS_SHARED_PRODUCTS_RAPORT}`,
            {
                observe: 'response',
                responseType: 'arraybuffer',
            }
        ).pipe(
            map(resp => {
                return {
                    data: resp.body,
                    type: resp.headers.get('content-type'),
                    filename: resp.headers.get('content-disposition').split('filename=')[1]
                };
            })
        );
    }
}
