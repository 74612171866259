<div
  class="steps-section-group"
  [ngClass]="{
    'steps-section-group--static': static
  }">
  <ul class="steps-section-group__nav">
    <li
      *ngFor="let section of sections; let index = index"
      class="steps-section-group__nav-item"
      [ngClass]="{
        'steps-section-group__nav-item--active': activeSection === index,
        'steps-section-group__nav-item--done': activeSection > index
      }"
      (click)="selectSection(index)">
      <a
        class="steps-section-group__nav-link">
        <span class="steps-section-group__nav-text">{{ section.title }}</span>
      </a>
    </li>
  </ul>

  <span
    class="steps-section-group__progress"
    [ngStyle]="{ 'width': (activeSection + 1) / totalSections * 100 + '%' }">
  </span>

  <div class="steps-section-group__content">
    <ng-content></ng-content>
  </div>
</div>
