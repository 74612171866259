import * as workflowHistoryLogsActions from '@core/actions/workflow-history-logs.actions';
import { ProductWorkflowIdsList, WorkflowHistoryLogsList } from '@core/models';

export interface State {
    list: WorkflowHistoryLogsList;
    workflowIds: ProductWorkflowIdsList;
}

const initialState: State = {
    list: {
        data: {
            count: null,
            limit: null,
            offset: null,
            next: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
    workflowIds: {
        data: [],
        errors: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: workflowHistoryLogsActions.Actions) {
    switch (action.type) {
        case workflowHistoryLogsActions.ActionTypes.GET_LIST_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case workflowHistoryLogsActions.ActionTypes.GET_LIST_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case workflowHistoryLogsActions.ActionTypes.GET_LIST_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case workflowHistoryLogsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case workflowHistoryLogsActions.ActionTypes.GET_PRODUCT_WORKFLOW_IDS_REQUEST: {
            return {
                ...state,
                workflowIds: {
                    ...state.workflowIds,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case workflowHistoryLogsActions.ActionTypes.GET_PRODUCT_WORKFLOW_IDS_SUCCESS: {
            return {
                ...state,
                workflowIds: {
                    ...state.workflowIds,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case workflowHistoryLogsActions.ActionTypes.GET_PRODUCT_WORKFLOW_IDS_ERROR: {
            return {
                ...state,
                workflowIds: {
                    ...state.workflowIds,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case workflowHistoryLogsActions.ActionTypes.CLEAR_PRODUCT_WORKFLOW_IDS: {
            return {
                ...state,
                workflowIds: {
                    ...initialState.workflowIds,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getWorkflowIds = (state: State) => state.workflowIds;
