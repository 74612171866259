import * as actions from '@core/actions/company-data-requirements.actions';

import {
    CustomDataList,
    DataRequirement,
    DataRequirementHistoryLog,
    DataRequirementsSummary,
    DefaultList,
    DefaultManage,
} from '@core/models';

export interface State {
    availableAttributes: CustomDataList<any>;
    historyLogs: DefaultList<DataRequirementHistoryLog>;
    list: DefaultList<DataRequirement>;
    manage: DefaultManage<DataRequirement>;
    summary: CustomDataList<DataRequirementsSummary>;
}

const listInitialState: State['list'] = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
    },
    errors: {},
    isFetching: true,
};

const customListInitialState: CustomDataList<any> = {
    data: {},
    errors: {},
    isFetching: true,
};

const manageInitialState: State['manage'] = {
    data: {
        category: null,
        categoryDisplay: null,
        company: null,
        entries: [],
        hasMatchingPreset: false,
        id: null,
        isActive: false,
        matchingPresetId: null,
        name: null,
    },
    errors: {},
    isFetching: true,
    isSaved: false,
    isSaving: false,
};

const summaryInitalState: State['summary'] = {
    data: {
        coveredCategoriesCount: 0,
        sharedCategoriesCount: 0,
        uncoveredCategoriesCount: 0,
        totalCategoriesCount: 0,
    },
    errors: {},
    isFetching: true,
};

const historyLogsInitialState: State['historyLogs'] = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
    },
    errors: {},
    isFetching: true,
};

export const initialState: State = {
    availableAttributes: customListInitialState,
    historyLogs: historyLogsInitialState,
    list: listInitialState,
    manage: manageInitialState,
    summary: summaryInitalState,
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.ActionTypes.GET_AVAILABLE_ATTRIBUTES_REQUEST: {
            return {
                ...state,
                availableAttributes: {
                    ...state.availableAttributes,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_AVAILABLE_ATTRIBUTES_SUCCESS: {
            return {
                ...state,
                availableAttributes: {
                    ...state.availableAttributes,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_AVAILABLE_ATTRIBUTES_ERROR: {
            return {
                ...state,
                availableAttributes: {
                    ...state.availableAttributes,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.CLEAR_AVAILABLE_ATTRIBUTES: {
            return {
                ...state,
                availableAttributes: {
                    ...initialState.availableAttributes,
                },
            };
        }

        case actions.ActionTypes.CREATE_REQUEST:
        case actions.ActionTypes.UPDATE_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case actions.ActionTypes.CREATE_SUCCESS:
        case actions.ActionTypes.UPDATE_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.DELETE_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                },
            };
        }

        case actions.ActionTypes.CREATE_ERROR:
        case actions.ActionTypes.UPDATE_ERROR:
        case actions.ActionTypes.DELETE_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.DELETE_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                    isSaving: false,
                },
            };
        }

        case actions.ActionTypes.GET_LIST_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_LIST_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_LIST_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case actions.ActionTypes.GET_REQUIREMENT_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_REQUIREMENT_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_REQUIREMENT_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.CLEAR_REQUIREMENT: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case actions.ActionTypes.GET_SUMMARY_REQUEST: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case actions.ActionTypes.GET_SUMMARY_SUCCESS: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.GET_SUMMARY_ERROR: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case actions.ActionTypes.CLEAR_SUMMARY: {
            return {
                ...state,
                summary: {
                    ...initialState.summary,
                },
            };
        }

        case actions.ActionTypes.GET_HISTORY_LOGS_REQUEST: {
            return {
                ...state,
                historyLogs: {
                    ...state.historyLogs,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case actions.ActionTypes.GET_HISTORY_LOGS_SUCCESS: {
            return {
                ...state,
                historyLogs: {
                    ...state.historyLogs,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case actions.ActionTypes.GET_HISTORY_LOGS_ERROR: {
            return {
                ...state,
                historyLogs: {
                    ...state.historyLogs,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case actions.ActionTypes.CLEAR_HISTORY_LOGS: {
            return {
                ...state,
                historyLogs: {
                    ...initialState.historyLogs,
                },
            };
        }

        default:
            return state;
    }
}

export const getAvailableAttributes = (state: State) => state.availableAttributes;
export const getList = (state: State) => state.list;
export const getManage = (state: State) => state.manage;
export const getSummary = (state: State) => state.summary;
export const getHistoryLogs = (state: State) => state.historyLogs;
