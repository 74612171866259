import { from as observableFrom, Observable, of } from 'rxjs';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as metrics from '@core/actions/metrics.actions';

import { MetricsService } from '@core/services/metrics.service';
import { ModalService } from '@core/services/modal.service';
import { NotificationsService } from '@core/services/notifications.service';

import { MetricFamilyId } from '@core/models';
import { catchErrorJson } from './catch-error';

@Injectable()
export class MetricsEffects {
    constructor(
        private actions$: Actions,
        private metricsService: MetricsService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    @Effect()
    getMetricsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(metrics.ActionTypes.GET_METRICS_REQUEST),
        map((action: metrics.GetMetricsRequestAction) => action.payload),
        switchMap((payload) => {
            return this.metricsService.getMetrics(payload).pipe(
                map((response) => new metrics.GetMetricsSuccessAction(response)),
                catchError((error) => of(new metrics.GetMetricsErrorAction(catchErrorJson(error)))),
            );
        }),
    );

    @Effect()
    getMetricRequest$: Observable<Action> = this.actions$.pipe(
        ofType(metrics.ActionTypes.GET_METRIC_REQUEST),
        map((action: metrics.GetMetricRequestAction) => action.payload),
        switchMap((payload) => {
            return this.metricsService.getMetric(payload).pipe(
                map((response) => new metrics.GetMetricSuccessAction(response)),
                catchError((error) => of(new metrics.GetMetricErrorAction(catchErrorJson(error)))),
            );
        }),
    );

    @Effect()
    saveMetricRequest$: Observable<Action> = this.actions$.pipe(
        ofType(metrics.ActionTypes.SAVE_METRIC_REQUEST),
        map((action: metrics.SaveMetricRequestAction) => action.payload),
        switchMap((payload) => {
            return this.metricsService.saveMetric(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved metric family ${response.translations.en.name}`);
                    this.router.navigate(['/metrics']);
                }),
                map((response) => new metrics.SaveMetricSuccessAction(response)),
                catchError((error) => of(new metrics.SaveMetricErrorAction(catchErrorJson(error)))),
            );
        }),
    );

    @Effect()
    createMetricRequest$: Observable<Action> = this.actions$.pipe(
        ofType(metrics.ActionTypes.CREATE_METRIC_REQUEST),
        map((action: metrics.CreateMetricRequestAction) => action.payload),
        switchMap((payload) => {
            return this.metricsService.createMetric(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Created metric family ${response.translations.en.name}`);
                    this.router.navigate(['/metrics']);
                }),
                map((response) => new metrics.CreateMetricSuccessAction(response)),
                catchError((error) => of(new metrics.CreateMetricErrorAction(catchErrorJson(error)))),
            );
        }),
    );

    @Effect()
    deleteMetricRequest$: Observable<Action> = this.actions$.pipe(
        ofType(metrics.ActionTypes.DELETE_METRIC_REQUEST),
        map((action: metrics.DeleteMetricRequestAction) => action.payload),
        switchMap((payload) => {
            return this.metricsService.deleteMetric(payload.id).pipe(
                tap(
                    (response) => {
                        this.notificationsService.success(`Deleted metric`);
                        this.router.navigate([payload.redirectTo]);
                    },
                    (error) => {
                        this.modalService.close('delete-metric-modal');
                    },
                ),
                map(() => new metrics.DeleteMetricSuccessAction()),
                catchError((error) => of(new metrics.DeleteMetricErrorAction(catchErrorJson(error)))),
            );
        }),
    );

    @Effect()
    getMultipleMetricsRequest$: Observable<Action> = this.actions$.pipe(
        ofType(metrics.ActionTypes.GET_MULTIPLE_METRICS_REQUEST),
        map((action: metrics.GetMultipleMetricsRequestAction) => action.payload),
        mergeMap((ids) => {
            return observableFrom(ids).pipe(
                mergeMap((id: MetricFamilyId) => {
                    return this.metricsService.getMetric(id).pipe(
                        map((response) => new metrics.GetMultipleMetricSuccessAction(response)),
                        catchError((error) => of(new metrics.GetMultipleMetricErrorAction(catchErrorJson(error)))),
                    );
                }),
            );
        }),
    );

    @Effect()
    getMultipleMetricsAtOnceRequest$: Observable<Action> = this.actions$.pipe(
        ofType(metrics.ActionTypes.GET_MULTIPLE_METRICS_AT_ONCE_REQUEST),
        map((action: metrics.GetMultipleMetricsAtOnceRequestAction) => action.payload),
        switchMap((payload) => {
            const params = new HttpParams().append('ids', JSON.stringify(payload));
            return this.metricsService.getMetrics(params).pipe(
                map((response) => new metrics.GetMultipleMetricsAtOnceSuccessAction(response)),
                catchError((error) => of(new metrics.GetMultipleMetricsAtOnceErrorAction(catchErrorJson(error))))
            );
        })
    );
}
