import * as refreshActions from '../actions/refresh.actions';

export interface State {
  requireRefresh: boolean;
}

const initialState: State = {
  requireRefresh: false,
};

export function reducer(state = initialState, action: refreshActions.Actions) {
  switch (action.type) {
    case refreshActions.ActionTypes.SET_REQUIRE_REFRESH_TRUE: {
      return {
        ...state,
        requireRefresh: true,
      };
    }

    case refreshActions.ActionTypes.SET_REQUIRE_REFRESH_FALSE: {
      return {
        ...state,
        requireRefresh: false,
      };
    }

    default:
      return state;
  }
}

export const getIsRequireRefresh = (state: State) => state.requireRefresh;
