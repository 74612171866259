<form [formGroup]='form' class="form">
    <section
        class="form__section form__section-title form__section--margin-bottom-32"
    >
        <div
            class="form__expandable-content section-title"
        >
            <div
                class="form__row form__row--indent-left-40">
                <div
                    class="form__group form__group--cols-10 form__group--transition-bg"
                >
              <span>
                <ul>
                  <div class="form__group form__footer--confirm-checkbox" *ngFor="let singlePart of consentCheck.controls; let i=index">
                  <label class="checkbox checkbox--vertical">
                    <input
                        type="checkbox"
                        class="checkbox__input"
                        [formControl] = singlePart
                        (change)="clickCheckbox(i)"
                        [checked]="consentParts[i].selected"
                        required
                    >
                    <span class="checkbox__label">
                      <span class="checkbox__text consent-form"
                            [ngClass]="{'section-title--is-invalid': !consentParts[i].selected}"
                            [innerHTML]="consentParts[i].text"
                      ></span>
                    </span>
                  </label>
                </div>
                </ul>
              </span>
                </div>
            </div>
        </div>
    </section>
</form>
