import { Injectable, Injector } from '@angular/core';

import { BaseGuard } from './base.guards';

@Injectable()
export class NutrientsReadGuard extends BaseGuard {
    path = ['nutrients', 'read'];
    constructor(injector: Injector) {
        super(injector);
    }
}

@Injectable()
export class NutrientsManageGuard extends BaseGuard {
    path = ['nutrients', 'manage'];
    constructor(injector: Injector) {
        super(injector);
    }
}
